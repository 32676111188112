import React from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import { DollarCircleFilled, EuroCircleFilled, PoundCircleFilled , EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Avatar, List, Button, Popconfirm, Card , Table, Tag } from 'antd';
import moment from 'moment';

import {ccyIsoCodeToSymbolMap} from '../../utils/CurrencyUtils';
import { capitalize } from '../../utils/ProductSwitcher';
import { WithdrawalProgressListView } from '../client/PayoutProgressListView';

const ccy = ccyIsoCodeToSymbolMap;

const ccy_icons = {
    'EUR': <EuroCircleFilled style={{ fontSize: '30px', color: '#00c' }} />,
    'USD': <DollarCircleFilled style={{ fontSize: '30px', color: '#08c' }} />,
    'GBP': <PoundCircleFilled style={{ fontSize: '30px', color: '#70c' }} />,
};

function iconForCCY(ccy) {
    let icon = ccy_icons[ccy];
    return (icon) ? icon : <DollarCircleFilled style={{ fontSize: '30px', color: '#08c' }} />;
}

export const AdminPayoutCard = ({ isAdmin, loading, type, payout, clientId, payoutId, locStrings }) => {
    let nameAndAddress = "";
    nameAndAddress = `${nameAndAddress} ${payout.holderName}`;

    let cardLink = `${clientId}/bank`;
    cardLink = isAdmin ? `/admin/clients/${cardLink}` : `/client/manage/${cardLink}`;

    if (payout.address) {
        nameAndAddress = `${nameAndAddress},  ${payout.address["address-line-one"]}`;
        const al2 = payout.address["address-line-two"] || "";
        if (al2.length > 0) nameAndAddress = `${nameAndAddress},  ${al2}`;
        nameAndAddress = `${nameAndAddress},  ${payout.address["town"]} `;
        nameAndAddress = `${nameAndAddress},  ${payout.address["county"]} `;
        nameAndAddress = `${nameAndAddress},  ${payout.address["country"]} `;
    }

    let bankAndCountry = "";
    bankAndCountry = `${bankAndCountry}${payout.bankName || ''}`;
    bankAndCountry = `${bankAndCountry} (${payout.country})`;
    bankAndCountry = `${bankAndCountry}:  ${payout.iban} `;

    return (
        <Card
            loading={loading}
            style={{ marginTop: 16,  backgroundColor: '#00ff000f', fontWeight: 'bold' }}
            type={type || "inner"}
            title="Payout Details"
            extra={<a href={cardLink}>View</a>}
        >
            <div>
                <p style={{fontWeight: 'bold', fontSize: '30px' }}>{`${payout.displayName}`}</p>
                <p>{`${nameAndAddress}`}</p>
                <p>{`${bankAndCountry}`}</p>
            </div>
        </Card>
    );
};

export const AdminClientCard = ({ isAdmin, loading, type, client, clientId, locStrings }) => {
    let cardLink = `${clientId}`;
    cardLink = isAdmin ? `/admin/clients/${cardLink}` : `/client/manage/${cardLink}`;

    return (
        <Card
            loading={loading}
            style={{ marginTop: 16, backgroundColor: '#ff00980f' }}
            type={type || "inner"}
            title={<p style={ {fontWeight: 'bold' } }>{"Client Details"}</p>}
            extra={isAdmin ? <a href={cardLink}>View</a> : null}
        >
            <Card.Meta 
                  avatar={<Avatar src={client.avatar} />}
                  title={client.displayName}
                  description={client.phoneNumber || client.email || client.profileBio}
            />
        </Card>
    );

};

export const AdminAccountCard = ({ isAdmin, loading, type, account, clientId, payoutId, locStrings }) => {
    let cardLink = `${clientId}/bank/${payoutId}/payouts`;
    cardLink = isAdmin ? `/admin/clients/${cardLink}` : `/client/manage/${cardLink}`;

    return (
        <Card
            loading={loading}
            style={{ marginTop: 16, backgroundColor: '#0000ff0f' }}
            type={type || "inner"}
            title={<p style={ {fontWeight: 'bold' } }>{"Account Details"}</p>}
            extra={<a href={cardLink}>View</a>}
        >
            <Card.Meta 
                  avatar={iconForCCY(account.currency)}
                  title={capitalize(account.product)}
                  description={capitalize(account.type)}
            />
        </Card>
    );

};

export const AdminWithdrawalTitle = ({ isAdmin, withdrawal, locStrings }) => {

    const amount = (withdrawal.payoutAmountCents / 100).toFixed(2);
    const displayAmount = `${ccy[withdrawal.currency]}${amount}`;

    return (
        <div>
            <p style={{ fontSize: "20px", fontWeight: 'bolder' }}>{`${withdrawal.client.displayName} requested ${displayAmount}`}</p>
        </div>
    );

};


export const AdminWithdrawalContent = ({ isAdmin, loading, withdrawal, locStrings }) => {

    if(loading) return null;

    const amountPayout = withdrawal.amount;
    const amount = (amountPayout / 100).toFixed(2);
    const displayAmount = `${ccy[withdrawal.currency]}${amount}`;
    const id = withdrawal.withdrawalId || withdrawal.uid || withdrawal.id || 'N/A';
    const clientId = withdrawal.clientId;

    return (
        <div>
            <p style={{ fontSize: '40px', color: '#ff0098f0', margin: '30px', marginBottom: '0px' }}>{`${displayAmount}`}</p>
            <p style={{ fontSize: '14px', color: '#27AE60' }}>{`${id}`}</p>
            <div style={{ textAlign: 'start' }}>
                <WithdrawalProgressListView
                    clientId={clientId}
                    withdrawalId={id}
                />
            </div>
        </div>
    );

};


const AdminWithdrawalCard = ({ isAdmin, loading, withdrawal, locStrings, onMarkPaid, contentCard: ContentCard, payoutCard: PayoutCard, clientCard: ClientCard, accountCard: AccountCard }) => {
    const actions = [];

    if(isAdmin) {
        actions.push(
            (<Button style={{ margin: '10px' }} type='primary' size='small' disabled={loading} onClick={((e) => (onMarkPaid && onMarkPaid(withdrawal)))} >Mark Paid</Button>)
        );
    }

    return (
        <Card
            style={{  }}
            title={<AdminWithdrawalTitle isAdmin={isAdmin} withdrawal={withdrawal} locStrings={locStrings} />}
            actions={actions}
        >
            <ClientCard isAdmin={isAdmin} loading={loading}  type="inner" client={withdrawal.client} clientId={withdrawal.clientId} locStrings={locStrings} />
            <PayoutCard isAdmin={isAdmin} loading={loading} type="inner" payout={withdrawal.payout} clientId={withdrawal.clientId} payoutId={withdrawal.payoutId} locStrings={locStrings} />
            <AccountCard isAdmin={isAdmin} loading={loading} type="inner" account={withdrawal.account} clientId={withdrawal.clientId} payoutId={withdrawal.payoutId} locStrings={locStrings} />
            <ContentCard isAdmin={isAdmin} loading={loading} type="inner" withdrawal={withdrawal} clientId={withdrawal.clientId} locStrings={locStrings} />
        </Card>
    );

};

const AdminWithdrawalIdDetails = ({ isAdmin, withdrawal, locStrings }) => {

    const withdrawalId = withdrawal.withdrawalId ? (<Tag>{withdrawal.withdrawalId}</Tag>) : null;
    const payoutId = withdrawal.payoutId ? (<Tag>{withdrawal.payoutId}</Tag>) : null;

    return (
        <div>
            <p>{`Withdrawals are processed via Revolut for Business`}</p>
            <p>
                {withdrawalId}
                {payoutId}
            </p>
        </div>
    );

};


export const AdminWithdrawalDetail = ({ loading, locStrings, withdrawal, onMarkPaid, isAdmin, ...rest }) => {

    return (
        <AdminWithdrawalCard
            loading={loading}
            withdrawal={withdrawal}
            locStrings={locStrings}
            payoutCard={AdminPayoutCard}
            clientCard={AdminClientCard}
            accountCard={AdminAccountCard}
            contentCard={AdminWithdrawalContent}
            onMarkPaid={onMarkPaid}
            isAdmin={isAdmin}
        />
    );

};
