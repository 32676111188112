import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TeamOutlined, CheckCircleOutlined, SwapOutlined, UserOutlined, BankOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Space, Avatar, Typography } from 'antd';

import QRImageView from '../customer/QRImageView';
import TrackerService from '../../utils/TrackerService';

import RedirectAndScrollTop from './RedirectAndScrollTop';
import { useClientDetails } from './useClientDetails';
import { useTeamDetails } from './useTeamDetails';

const { Title, Text } = Typography;

function rgbToHex(rgbString) {
  if(rgbString) {
    // Extract the values of r, g, and b from the rgbString
    const [r, g, b] = rgbString.substring(4, rgbString.length-1).split(',').map(val => parseInt(val.trim()));
    
    // Convert the values to hex and concatenate them into a single string
    const hex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
    
    // Prepend the '#' symbol to the hex string and return it
    return '#' + hex;
  }
  return null;
}

export const ClientPartnerConnectedCard = ({ client, partner, rgbColor, rgbColorClient }) => {
  const rgbForClient = rgbColorClient || rgbColor;
  return(
    <Space direction='horizontal' align='center'>
      <Avatar src={client.avatar} size='large' style={{ backgroundColor: rgbToHex(rgbForClient) }} icon={<UserOutlined />} alt={client.displayName} />
      <SwapOutlined />
      <Avatar src={partner.avatar} shape='square' size='large' style={{ backgroundColor: rgbToHex(rgbColor) }} icon={<BankOutlined />}  alt={partner.displayName} />
    </Space>
  );
};

export const DataSharingLineItem = ({ id, item, description }) => {
  return <>
  { item && description &&
    <Space key={`sp-${id}`} direction='horizontal'>
      <p style={{ fontWeight: 'bold' }}>{`${item}`}</p>
      <p>{`(${description})`}</p>
    </Space>
  }
  </>
}

export const DataSharingList = ({ displayName, email, partnerDisplayName, tagUid }) => {
  const getDataSource = () => {
    let dataSource = [];

    if(displayName) {
      dataSource = [...dataSource, { 
        id: 'li-share-name',
        item: displayName,
        description: 'name'
      }
      ];
    }

    if(email) {
      dataSource = [...dataSource, { 
        id: 'li-share-email',
        item: email,
        description: 'email'
      }
      ];
    }

    if(tagUid && partnerDisplayName) {
      dataSource = [...dataSource, { 
        id: 'li-share-tag',
        item: tagUid,
        description: `${partnerDisplayName} ID`        
      }
      ];
    }

    if(partnerDisplayName) {
      dataSource = [...dataSource, { 
        id: 'li-share-payments',
        item: 'Payments',
        description: `using ${partnerDisplayName} links`
      }
      ];
    }

    return dataSource;
  }

  const listItems = getDataSource().map((item) =>
    <DataSharingLineItem key={item.id} {...item} />
  );

  return <>
    <Space direction='vertical' size={1}>
      {listItems}
    </Space>
  </>
}

export const SharingDataWithPartnerCard = ({ partnerClientId, clientId, tagId, tagUid }) => {
  const client = useClientDetails(clientId);
  const partner = useClientDetails(partnerClientId);

  const partnerDisplayName = partner ? partner.displayName : null;
  const { displayName, email } = client ?? { };
  
  return (
      <>
        { partner && client &&
            <Card type="inner" >
              <Card.Meta 
                title={`Data shared with ${partnerDisplayName}`} 
                description={'Sign up & Payments'} 
              />
              <Divider />
              <DataSharingList partnerDisplayName={partnerDisplayName} email={email} displayName={displayName} tagUid={tagUid} />
            </Card>
        }
      </> 
  );
};

export const ReturnToPartnerButton = ({ templateTagId, tagId, tag, clientId }) => {
  const { signupConfirmUrl, partnerClientId, tagUid, webhookConfig, is_test, rgbColor } = tag;
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const resultStatus = 'live';

  const client = useClientDetails(clientId);
  const partner = useClientDetails(partnerClientId);

  const mapPartnerData = (partner) => {
    const { displayName } = partner ?? { };
    return { partnerDisplayName: displayName };
  };
  
  const mapClientData = (client) => {
    const { displayName } = client ?? { };
    return { displayName };
  };

  const MakeSignupConfirmUrl = (signupConfirmUrl) => {
    if(signupConfirmUrl && tagUid) {
      return `${signupConfirmUrl}?uid=${tagUid}&status=${resultStatus}&template=${templateTagId}`;
    }

    return signupConfirmUrl;
  };

  const { displayName } = mapClientData(client);
  const { partnerDisplayName } = mapPartnerData(partner);

  const fullSignupConfirmUrl = MakeSignupConfirmUrl(signupConfirmUrl);
  const returnButtonText = partnerDisplayName ? `Return to ${partnerDisplayName}` : null;

  const isLocalHost = window.location.hostname === 'localhost';
  const isTest = isLocalHost;

  const tracker = new TrackerService({
    isTest,
  });

  const data = {
    clientId,
    displayName,
    partnerClientId,
    partnerDisplayName,
    tagId,
    templateTagId,
    tagUid,
    signupConfirmUrl,
    fullSignupConfirmUrl,
    returnButtonText,
    webhookConfig,
    status: resultStatus,
  }

  const onClickReturnToPartner = async () => {
    tracker.mixpanelTrack('tag_cliam_return_to_partner', data);

    if (!isTest) {
      tracker.googleTrack('tag_cliam_return_to_partner', data);
    }

    if(signupConfirmUrl) {
      setShouldRedirect(true);
    }
  };

  return (
      <Space direction='vertical' align='center'>
        <RedirectAndScrollTop 
          when={shouldRedirect}
          to={fullSignupConfirmUrl}
          pushBefore={'/'}
        />
        { returnButtonText && fullSignupConfirmUrl &&
            <Button 
              key={`tag-cliam-return-button-${templateTagId}`} 
              type='primary' 
              onClick={onClickReturnToPartner} 
              icon={<CheckCircleOutlined />}
              style={{ backgroundColor: '#27AE60' }}
              disabled={shouldRedirect}
            >
              {returnButtonText}
            </Button>
        }
        { is_test &&
          <p style={{ color: 'red', fontSize: 10, padding: '15px' }}>{`${fullSignupConfirmUrl}`}</p>
        }
      </Space> 
  );
};

export const ClaimTagSuccessCard = ({ templateTagId, tagId, tag, clientId, isAdmin, locStrings, ...rest }) => {
  const { tagUid, partnerClientId, rgbColor } = tag;
  const client = useClientDetails(clientId);
  const partner = useClientDetails(partnerClientId);
  const { rgbColor: rgbColorClient } = client || { };

  return (
      <>
        { client && partner &&
          <Card>
            <Space direction='vertical' align='center'>
            <p>{`You are now connected to ${partner.displayName}`}</p>

            <ClientPartnerConnectedCard 
              client={client} 
              partner={partner} 
              rgbColor={rgbColor}
              rgbColorClient={rgbColorClient}
            />

            <ReturnToPartnerButton 
              templateTagId={templateTagId}
              tagId={tagId}
              tag={tag}
              clientId={clientId}
            />

            <SharingDataWithPartnerCard 
              clientId={clientId}
              partnerClientId={partnerClientId}
              tagId={tagId}
              tagUid={tagUid}
            />

            <Link to={`/client/manage/${clientId}`}>
              <Button key='confirm' >
                {'Go to Strikepay Dashboard'}
              </Button>
            </Link>
            </Space>
          </Card>
        }
      </> 
  );
};

export const PersonalTagClaimSuccessCard = ({ teamId, teamName, templateTagId, tagId, tag, clientId, client, isAdmin, locStrings, waitingOnRequest, ...rest }) => {
  const team = useTeamDetails(teamId);
  const teamPartner = team || {};
  const teamAsPartner = {
    ...teamPartner,
    displayName: teamPartner.teamName || teamName,
    avatar: teamPartner.topLogo || teamPartner.avatar,
  };
  const tagPartner = tag || {};
  const tagAsPartner = {
    ...tagPartner,
  };
  const { rgbColor: rgbColorTag } = tag || { };
  const { rgbColor: rgbColorTeam } = team || { };  
  const rgbColor = rgbColorTeam || rgbColorTag || 'rgb(255, 0, 198)';
  let { rgbColor: rgbColorClient } = client || { };
  rgbColorClient = rgbColorClient || 'rgb(198, 0, 255)';

  const limit = 26;
  let truncatedTeamName = null;
  if(teamName && teamName.length > 0) {
    truncatedTeamName = teamName.length > limit ? `${teamName.substring(0, limit - 3)}...` : teamName;
  }

  return (
    <Card loading={waitingOnRequest} style={{ maxWidth: '500px', textAlign: 'center' }}>
      <Space direction='vertical' align='center'>
        <Title level={3} type='success'>Success!</Title>
        <p>You have activated your tag</p>
        {teamId && team && !waitingOnRequest && client &&
          <ClientPartnerConnectedCard
            client={client}
            partner={teamAsPartner}
            rgbColor={rgbColor}
            rgbColorClient={rgbColorClient}
          />
        }
        {!teamId && tag && !waitingOnRequest && client &&
          <ClientPartnerConnectedCard
            client={client}
            partner={tagAsPartner}
            rgbColor={rgbColor}
            rgbColorClient={rgbColorClient}
          />
        }
        {teamId && team && !waitingOnRequest &&
          <Link to={`/client/manage/${clientId}/teams/${teamId}`}>
            <Button disabled={waitingOnRequest} key='go-to-team' type='primary'>
              {`Go to '${truncatedTeamName || "Team"}' Dashboard`}
            </Button>
          </Link>
        }
        {clientId && client && !waitingOnRequest &&
          <Link to={`/client/manage/${clientId}`}>
            <Button disabled={waitingOnRequest} key='confirm' type={teamId ? 'link' : 'primary'}>
              {`Go to '${client.displayName || "your"}' Dashboard`}
            </Button>
          </Link>
        }
        {tagId && clientId && !waitingOnRequest &&
          <Link to={`/client/manage/${clientId}/tags/${tagId}`}>
            <Button disabled={waitingOnRequest} key='go-to-tag' type='link'>
              {locStrings.confirm_claim_edit_tag}
            </Button>
          </Link>
        }
        {tag && tagId && !waitingOnRequest &&
          <>
          <Title level={5}>Try out the new QR code</Title>
          <QRImageView tag={tag} tagId={tagId} nosubtext={false} showButton={true} locStrings={locStrings} />
          </>
        }
      </Space>
    </Card>
  );
}
