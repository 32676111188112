import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Typography, PageHeader, Tag, Tabs, Table, Form, Input , Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import firebase from 'firebase/app';

import CenteredSpinner from '../../../components/CenteredSpinner';
import StrikeClientModel from '../../../models/StrikeClientModel';


const { Paragraph } = Typography;
const { TabPane } = Tabs;

export default class ManageAccessScreen extends Component {
  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    const isAdmin = !!props.isAdmin;
    this.clientModel = new StrikeClientModel({ viewModel: this, clientId, isAdmin });

    this.state = {
      clientId: clientId,
      client: null,
      loading: true,
      loadingDelegatedUsers: true,
      loadingInvitedUsers: true,
      mode: 'viewing',
      activeTab: "1",
    };
  }

  componentDidMount() {
    this.clientModel.waitForClientData();
    
    this.getActiveDelegateUsers();
    this.getInvitedDelegateUsers();
  }

  componentWillUnmount() {
    if(this.clientModel) this.clientModel.stop();
  }

  componentDidUpdate() {
  }

  getActiveDelegateUsers = async () => {
    const db = firebase.firestore();
    const querySnapshot = await db.collection('clients').doc(this.state.clientId).collection('delegated_users').get();

    const delegatedUsers = [];

    querySnapshot.docs.forEach((doc, index) => {
      const delegatedUser = doc.data();
      delegatedUser.key = index;
      delegatedUser.userId = doc.id;
      delegatedUsers.push(delegatedUser);
    });
    
    this.setState({ 
      delegatedUsers,
      loadingDelegatedUsers: false,
    });
  }

  getInvitedDelegateUsers = async () => {
    const db = firebase.firestore();
    const querySnapshot = await db.collection('clients').doc(this.state.clientId).collection('invited_users').get();

    const invitedUsers = [];

    querySnapshot.docs.forEach((doc, index) => {
      const invitedUser = doc.data();
      invitedUser.key = index;
      invitedUser.inviteId = doc.id;
      const { status } = invitedUser;

      if(status !== 'used') {
        invitedUsers.push(invitedUser);
      }
    });
    
    this.setState({ 
      invitedUsers,
      loadingInvitedUsers: false,
    });
  }

  removeDelegateUser = async (record) => {
    const result = window.confirm(`This will remove ${record.email}'s access to this account and its tags. Are you sure?`);

    if (result) {
      const userId = record.userId;

      const db = firebase.firestore();
      await db.collection('clients').doc(this.state.clientId).collection('delegated_users').doc(userId).delete();
      this.setState({ loadingDelegatedUsers: true });
      this.getActiveDelegateUsers();
    }
  }

  removeInvitedUser = async (record) => {
    const result = window.confirm(`This will invalidate the invite to ${record.email}. Are you sure?`);

    if (result) {
      const inviteId = record.inviteId;

      const db = firebase.firestore();
      await db.collection('clients').doc(this.state.clientId).collection('invited_users').doc(inviteId).delete();
      this.setState({ loadingInvitedUsers: true });
      this.getInvitedDelegateUsers();
    }
  }

  foundClient = async (client) => {
    this.setState({
      client,
      loading: false,
    });
  }

  clientNotFound = () => {
    this.setState({
      loading: false,
      clientNotFound: true,
    });
  }

  changeMode = (mode) => {
    if (mode === 'viewing') {
      return 'inviting'
    } else if (mode === 'inviting') {
      return 'saving'
    } else {
      return 'viewing';
    }
  }

  onClickEdit = () => {
    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    });
  }

  doneSaving = () => {

    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    });

    this.model.waitForClientData();
  }

  internalSave = async (data) => {
    await this.model.commit(data);
    this.doneSaving();
  }

  onClickInvite = () => {
    this.setState({
      mode: 'inviting',
    });
  }

  onClickCancel = () => {
    console.log(`onClickCancel`);

    this.setState({
      mode: 'viewing',
    });
  }

  getButtons = () => {
    if (this.state.mode === 'viewing') {
      return [
        <Button key="1"  type="text" shape='circle' icon={<PlusCircleOutlined style={{ fontSize: '28px', color :'#070'}}/>} onClick={this.onClickInvite} />
      ]
    } else if (this.state.mode === 'inviting') {
      return [
        <Button key="1" type="danger" onClick={this.onClickCancel}>
          Cancel
        </Button>,
      ];
    } else {
      return [];
    }
  }

  doesContainEmail(array, email) {
    for (const object of array) {
      if (object.email === email) return true;
    }

    return false;
  }

  onFinish = async (values) => {
    const invitedEmail = values.email;
    const alreadyInvited = this.doesContainEmail(this.state.invitedUsers, invitedEmail);

    if (alreadyInvited) {
      window.alert(`${invitedEmail} already has a pending invite to your client account`);
      return;
    }

    const alreadyDelegateUser = this.doesContainEmail(this.state.delegatedUsers, invitedEmail);
    
    if (alreadyDelegateUser) {
      window.alert(`${invitedEmail} is already an active user on your client account`);
      return;
    }

    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode),
      }
    });

    const db = firebase.firestore();
    await db.collection("clients").doc(this.state.clientId).collection("invited_users").add({
      email: invitedEmail,
      invitedAt: firebase.firestore.Timestamp.now(),
      clientDisplayName: this.state.client.displayName,
      clientId: this.state.clientId,
    });

    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode),
        activeTab: "2",
        loadingInvitedUsers: true,
      }
    });

    this.getInvitedDelegateUsers();
  };

  tabClicked = (key) => {
    this.setState({ activeTab: key });
  }

  render() {
    const delegatedUsersDataSource = this.state.delegatedUsers;

    const usersColumns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <a onClick={() => { this.removeDelegateUser(record)} }>Remove</a>
          </span>
        ),
      },
    ];

    const invitedUsersDataSource = this.state.invitedUsers;

    const invitedUsersColumns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <a onClick={() => {this.removeInvitedUser(record)} }>Uninvite</a>
          </span>
        ),
      },
    ];

    if (this.state.mode === 'viewing') {
      return (
        <>
          <PageHeader
            ghost={false}
            title={`Manage access`}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />

          <Tabs defaultActiveKey="1" activeKey={this.state.activeTab} onTabClick={this.tabClicked}>
            <TabPane tab="Active" key="1">

              { this.state.loadingDelegatedUsers
                ? (
                  <CenteredSpinner loading={this.state.loadingDelegatedUsers} />
                )
                : (
                  <Table dataSource={delegatedUsersDataSource} columns={usersColumns} />
                )
              }
              
            </TabPane>
            <TabPane tab="Invited" key="2">

              { this.state.loadingInvitedUsers
                ? (
                  <CenteredSpinner loading={this.state.loadingInvitedUsers} />
                )
                : (
                  <Table dataSource={invitedUsersDataSource} columns={invitedUsersColumns} />
                )
              }
            </TabPane>
          </Tabs>
        </>
      );
    } else if (this.state.mode === 'inviting' || this.state.mode === "saving") {
      return (
        <>
          <PageHeader
            ghost={false}
            title={`Invite user`}
            extra={this.getButtons()}
            tags={this.state.mode === 'saving' && <Tag color="green">Saving...</Tag>}
          />

          <h5>Invited users permissions</h5>

          <ul>
            <li>- Issue withdrawals &amp; create payout methods</li>
            <li>- Manage &amp; edit tags</li>
            <li>- Manage account settings</li>
            <li>- Cannot invite additional users</li>
          </ul>

          <br/>

          <Form
            onFinish={this.onFinish}
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: 'Please input the email to invite',
                  whitespace: false,
                  type: "email",
                },
              ]}
            >
             <Input/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Submit</Button>
            </Form.Item>
          </Form>
        </>
      );
    }
  }
}

