import firebase from 'firebase/app';

export class StrikeTapModel {
  static instance() {
    return new StrikeTapModel();
  }

  async executeTapRefund({ tagId, tapId }) {
    const adminRefundTapFunction = firebase.functions().httpsCallable('tapService-tapHandler-adminRefundTap');
    const payloadData = {
        tagId,
        tapId,
    };
    let errorMessage = null;
    try {
        await adminRefundTapFunction(payloadData);
    } catch (e) {
        errorMessage = e.toString();
    }
    return errorMessage;
  }
}
