import React, { Component } from 'react';
import { Layout } from 'antd';

import StrikeFooter from '../../components/StrikeFooter';
import img_elStrikeLogoImage from '../../images/strikepay_Mark_RGB_White.png';

import '../../App.scss';
import 'antd/dist/antd.css';

const { Header, Content, Footer } = Layout;

export default class AdminLoginLayout extends Component {
  render() {
    return (
      <Layout className="layout">
        <Header className="top" style={{ textAlign : 'start', backgroundColor: '#000098bb'}} >
            <img src={img_elStrikeLogoImage} style={{ height: "50px" }} alt='Home' />
        </Header>
        <Content style={{ padding: '0 0px', minWidth: '300px', width: '100%' }}>
          <div className="site-layout-content">
            {this.props.children}
          </div>
        </Content>
        <StrikeFooter locStrings={this.props.locStrings} />
      </Layout>

    );
  }
}