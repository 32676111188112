import React, { Component } from 'react';
import firebase from 'firebase/app';
import { Input, Button } from 'antd';
import qs from 'qs';

import FooterCard from '../../components/customer/FooterCard';
import PoweredByStrike from '../../components/customer/PoweredByStrike';
import FallbackAvatar from '../../images/Strike_Symbol_RGB_White_Magenta.jpg';
import CenteredSpinner from '../../components/CenteredSpinner';

import '../../App.scss';

const { Search } = Input;

export default class TeamTippingScreen extends Component {
  constructor(props) {
    super(props);

    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    
    let membersOnly = false;
    if (queryParams && queryParams.membersOnly) {
      membersOnly = queryParams.membersOnly === "true";
    }

    this.state = {
      loading: (this.props.loading),
      tapId: props.match.params.tapId,
      tagId: props.match.params.tagId,
      teamId: props.match.params.teamId,
      teamMembers: [],
      filteredTeamMembers: [],
      membersOnly,
    };
  }

  async componentDidMount() {
    let db = firebase.firestore();
    const snapshot = await db
                      .collection('teams')
                      .doc(this.state.teamId)
                      .collection('members')
                      .where('showOnTap', '==', true)
                      .where('isDeleted','==',false)
                      .get();

    const members = this.state.teamMembers;
    snapshot.forEach(doc => {
      const member = doc.data();
      
      const memberId = doc.id;
      member.id = memberId;

      const name = member.displayName ? member.displayName.split(" ")[0] : "";
      member.displayName = name;

      members.push(member);
    });
    
    const team = (await db.collection('teams').doc(this.state.teamId).get()).data();

    const randomisedMembers = this.randomiseArray(members);

    this.setState({
      teamMembers: randomisedMembers,
      filteredTeamMembers: randomisedMembers,
      loading: false,
      team: team,
    });
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  randomiseArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  }

  onSearchChange = (e) => {
    const searchedName = e.target.value.toLowerCase();

    const filteredTeamMembers = this.state.teamMembers.filter( (member) => {
      return member.displayName.toLowerCase().startsWith(searchedName);
    });

    this.setState({ filteredTeamMembers: this.randomiseArray(filteredTeamMembers) });
  }
  
  renderScreen = () => {
      const getMembers = () => {
        return (
          <div className="tipCardContainer">
            { 
              this.state.filteredTeamMembers.map((member, i) => {
                let url = `/v1/tags/${this.state.tagId}/taps/${this.state.tapId}?tmid=${member.id}`;

                return (
                  <a className="tipCard" href={url} key={member.id}>
                    <div>
                      <img src={member.avatar || FallbackAvatar} />
                      <p>{ member.displayName }</p>
                      <p>Tip</p>
                    </div>
                  </a>
                );
              })
            }
          </div>
        );
      }

      return (
        <div className="AppScreen TagDetailScreen">
          <div className="background">
            <div className="cardBg memberTipping" style={{ padding: "10px 20px", textAlign: "center" }}>
              { this.state.team.topLogo &&
                <img src={this.state.team.topLogo} style={{ maxWidth: "120px"}} />
              }

              { !this.state.team.topLogo &&
                <h1>The {this.state.team.teamName} team</h1>
              }
              {this.state.team.topLogo && <h2>{this.state.team.teamName}</h2>}
              <h2>Tip a team member</h2>
              <Search placeholder="Search team members" onChange={this.onSearchChange} style={{ width: "80%", maxWidth: "400px", marginBottom: "20px", textAlign: "center" }} />

              {getMembers()}

              { !this.state.membersOnly &&
                <>
                  <h2>Tip the whole team</h2>
                  <a href={`/v1/tags/${this.state.tagId}/taps/${this.state.tapId}`}><Button className="tip-team-btn">Tip the team</Button></a>
                </>
              }
            </div>

            <PoweredByStrike locStrings={this.props.locStrings} />

            <div className="hasNestedComps elFooterCard">
              <div>
                <FooterCard ref={(el)=> this._elFooterCard = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
              </div>
            </div>
          </div>
        </div>
      );
    }

  render() {
    const loading = this.state.loading;

    if (loading) {
      return <CenteredSpinner loading={loading} noLayout={true} />
    } else if(!loading && this.state.teamMembers) {
      return this.renderScreen();
    } else {
      return null;
    }
  }
}
