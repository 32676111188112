import React, { useState } from 'react';
import locale from 'antd/lib/locale/en_GB';
import {
  Form,
  Select,
} from 'antd';

const { Option } = Select;


export const categoriesEN = {
  charity: 'Charity',
  delivery : 'Food Delivery',
  courier: 'Courier',
  waiter: 'Waiter/Server',
  barista: 'Barista',
  bartender: 'Bartender',
  busker: 'Busker',
  hair: 'Hairdresser/Stylist',
  nails: 'Nail technician',
  beauty: 'Therapist',
  barber: 'Barber',
  hotel: 'Hotel staff',
  porter: 'Porter',
  taxi: 'Taxi',
  other: 'Other'
};

export const categoriesCompanyEN = {
  charity: 'Charity',
  delivery : 'Food Delivery',
  courier: 'Couriers',
  waiter: 'Restaurants',
  barista: 'Coffeshops',
  bartender: 'Bars',
  busker: 'Music',
  hair: 'Hair and Beauty',
  nails: 'Nails',
  beauty: 'Therapy',
  barber: 'Barbers',
  hotel: 'Hotels',
  porter: 'Hotel Operator',
  taxi: 'Taxis',
  other: 'Other Business'
};

export function getCategoryNameWithCompany({ categoryCode, isCompany }) {
  const code = categoryCode || 'other';
  const categoryName = isCompany ? categoriesCompanyEN[code] : categoriesEN[code];
  return categoryName;
}

export const ClientCategoryFormItem = ({ fieldName, category, isCompany, ...rest }) => {
  const [selectedCategory, setSelectedCategory] = useState(category);
  const categoryFieldName = fieldName || "categoryCode";
  const disableEditofRole = false;

  const onSelectCategory = (categoryCode) => {
    setSelectedCategory(categoryCode);
  };

  return (
    <Form.Item 
        name={categoryFieldName}
        label={isCompany ? 'Business Type' : 'Job Role'}
    >
       <Select
          placeholder={isCompany ? 'Select type' : 'Select a role'}
          onSelect={onSelectCategory}
        >
          {
            Object.keys(categoriesEN).map((categoryCode, categoryIndex) => 
              <Option key={categoryCode} value={categoryCode} disabled={disableEditofRole}>
                {getCategoryNameWithCompany({ categoryCode, isCompany })}
              </Option>
            )
          }
        </Select>
  </Form.Item>
  );
};

