import firebase from 'firebase/app';
const isLocalHost = window.location.hostname === 'localhost';

export default class CollectionSubCollectionModel {

  constructor({ id, isAdmin, onUpdate, limit, includeTestData, topCollection, subCollection, orderBy, isAscending }) {
    this.isAdmin = isAdmin || false;
    this.includeTestData = includeTestData || isLocalHost; // MARK: Show test and live accounts on localhost
    this.limit = limit || 1000;
    this.collection = [];
    this.dataById = {};
    this.onUpdate = onUpdate;
    this.observer = null;
    this.topCollection = topCollection;
    this.subCollection = subCollection;
    this.id = id;
    this.orderBy = orderBy || 'createdAt';
    this.isAscending = isAscending || false;
  }

  start() {
    // console.log(`Starting ${this.topCollection}/${this.id}/${this.subCollection} model`);
    if(this.topCollection && this.subCollection && this.id) {
      this.startDataFetch();
    }
  }

  stop() {
    this.stopDataFetch();
  }

  stopDataFetch = () => {
    if (this.observer) { 
      this.observer();
      this.observer = null; 
    }
    this.collection = [];
    this.dataById = {};
  }

  startDataFetch = () => {
    this.stopDataFetch();
    const query = this.getQuery();

    this.observer = query.onSnapshot(querySnapshot => {
      if (querySnapshot.empty) {
        this.zeroElementsFound();
      }

      querySnapshot.docChanges().reverse().forEach(
        change => {
          if (change.type === 'added' || change.type === 'modified') {
            const data = change.doc.data();
            const id = change.doc.id;
            const full_data = { ...data, id };
            this.dataById[id] = full_data;
            this.updateElementWithId(id, full_data);
          }

          if (change.type === 'removed') {
            const id = change.doc.id;
            const element = this.dataById[id];
            this.dataById[id] = null;
            this.deleteElementWithId(id, element);
          }
        }
      );
    }, err => {
      console.log(`Encountered a ${this.topCollection}/${this.id}/${thjis.subCollection} model error: ${err}`);
      this.zeroElementsFound();
    });
  }

  getQuery() {
    const db = firebase.firestore();

    let query = db.collection(this.topCollection)

    if (this.id && this.subCollection) {
      query = query
        .doc(this.id)
        .collection(this.subCollection);
    }

    const orderDirection = this.isAscending ? 'asc' : 'desc';
    const orderByField = this.orderBy;
    if(orderByField) {
      query = query.orderBy(orderByField, orderDirection);
    }

    if(this.limit) {
      query = query.limit(this.limit);
    }

    return query;
  }

  orderElement(a, b) {
    const orderByField = this.orderBy;
    const l = this.isAscending ? a[orderByField] : b[orderByField];
    const r = this.isAscending ? b[orderByField] : a[orderByField];
    return l - r;
  }

  updateElementWithId(id, element) {
    let elements = this.collection.filter(e => e.id !== id);
    elements = [element, ...elements];
    this.collection = elements.sort((a, b) => this.orderElement(a, b));
    if (this.onUpdate) this.onUpdate(this.collection)
  }

  deleteElementWithId(id, element) {
    let elements = this.collection.filter(e => e.id !== id);
    this.collection = elements;
    if (this.onUpdate) this.onUpdate(this.collection)
  }

  zeroElementsFound() {
    this.collection = [];
    if (this.onUpdate) this.onUpdate(this.collection)
  }
}
