import { changeConfirmLocale } from 'antd/lib/modal/locale';
import firebase from 'firebase/app';
import moment from 'moment';

import image_default_avatar from '../images/Strike_Symbol_RGB_White_Magenta.jpg';


export default class StrikeTagsModel {

  constructor({ viewModel, clientId, product, limit, callback }) {
    this.viewModel = viewModel;
    this.clientId = clientId;
    this.tags = [];
    this.client = null;
    this.tagsById = {};
    this.callback = callback;
    this.product = product;
    this.limit = limit;
  }

  getQueryForTagsForClient(params) {
    const {
      clientId,
      product,
      limit,
    } = params;

    const db = firebase.firestore();
    let query = db.collection('tags').where("clientId", "==", clientId);

    if (product) {
      query = query.where('product', '==', product);
    }

    if (limit) {
      query = query.limit(limit);
    }

    query = query.orderBy('updatedAt', 'desc');

    return query;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if(this.observerTags) {
      this.observerTags();
      this.observerTags = null;
    }
  }

  async fetchAllTagsData()  {
    if (this.clientId === null) return;

    const { clientId, product, limit } = this;

    this.stop();

    const db = firebase.firestore();
    this.tags = [];

    const query = this.getQueryForTagsForClient({
      clientId, 
      product, 
      limit,
    });

    this.observerTags = query.onSnapshot(querySnapshot => {
      let index = querySnapshot.size;
      // this.tags = [];
      if(index === 0) this.updateDataSource();

      querySnapshot.docChanges().forEach(
        change => {
          if(change.type === 'added' ) {
            const doc = change.doc.data();
            const id = change.doc.id;
            const full_doc = { ...doc, id };
            this.tags = [ ...this.tags, { ...full_doc } ];
            this.updateDataSource();
          }

          if(change.type === 'modified') {
            const doc = change.doc.data();
            const id = change.doc.id;
            const full_doc = { ...doc, id };
            let updateAtIndex = null;
            this.tags.forEach( (value, i, a) => {
              if(value.id === id) updateAtIndex = i;
            });
            if(updateAtIndex >= 0) {
              this.tags.splice(updateAtIndex, 1);
            }
            this.tags = [ { ...full_doc }, ...this.tags ];
            this.updateDataSource();
          }

          if(change.type === 'removed' )  {
            const id = change.doc.id;
            let removeAtIndex = null;
            this.tags.forEach( (value, i, a) => {
              if(value.id === id) removeAtIndex = i;
            });
            let removed = null;
            if(removeAtIndex >= 0) {
              removed = this.tags.splice(removeAtIndex, 1);
            }
            this.updateDataSource();
          }
        }
      );
    }, err => {
      this.tags = [];
      this.updateDataSource();
      console.log(`Tags query error: ${err}`);
    });


  }

  async updateDataSource() {
    if(this.callback) 
      this.callback(this.tags);
    else if(this.viewModel)
      this.viewModel.updatedTagsDataSource(this.tags);
  }
  
}
