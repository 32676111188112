import React from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import {
  DollarCircleFilled, EuroCircleFilled, PoundCircleFilled , PlusCircleOutlined,
  WarningTwoTone, ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { List, Button, Space , Table, Tag, Typography } from 'antd';
import moment from 'moment';

import { ccyIsoCodeToSymbolMap } from '../../utils/CurrencyUtils';
import MoneyTitle from '../customer/MoneyTitle';

const { Text, Paragraph } = Typography;
const ccy = ccyIsoCodeToSymbolMap;

const ccy_icons = {
  'EUR': <EuroCircleFilled style={{ fontSize: '50px', color: '#00c' }}/>,
  'USD': <DollarCircleFilled  style={{ fontSize: '50px', color: '#08c' }}/>,
  'GBP': <PoundCircleFilled  style={{ fontSize: '50px', color: '#70c' }}/>,
};

const allowedStatuses = [
  'sent', 'paid', 'api_requested', 'waiting', 'batch_requested', 'batched',
  'refund_requested', 'refund_failed', 'refunded',
]; // MARK: sent means batch

const reversedStatuses = [
  'refund_requested', 'refund_failed', 'refunded',
]; // MARK: sent means batch

function iconForCCY(ccy) {
  let icon = ccy_icons[ccy];
  return (icon) ? icon : <DollarCircleFilled  style={{ fontSize: '50px', color: '#08c' }}/>;
}

const makeBankDescription = (item) => {
  let description = "";
  if(item.iban) {
    description = `IBAN (${item.country}): ${item.iban}`;
  } else if(item.sortCode && item.accountNumber) {
    description = `Sort Code (${item.country}): ${item.sortCode} -- Account Number: ${item.accountNumber}`;
  } else if(item.routingNumber && item.accountNumber) {
    description = `${item.accountNumber} # ${item.routingNumber}`;
  } else if(item.provider && item.provider === 'moneyjar') {
    description = 'via MoneyJar App';
  }

  return description;
};

const makeShortBankDescription = (item) => {
  let description = "";
  if(item.iban) {
    description = `***${item.iban.slice(-4)}`;
  } else if(item.sortCode && item.accountNumber) {
    description = `***${item.accountNumber.slice(-4)}`;
  } else if(item.routingNumber && item.accountNumber) {
    description = `***${item.accountNumber.slice(-4)}`;
  } else if(item.provider && item.provider === 'moneyjar') {
    description = 'MoneyJar';
  }

  return description;
};

const makeDisplayName = (item) => {
  const title = `${item.displayName}${item.isCompany ? ' (Business)' : ''}`;
  const style = {
    width: '16px',
    height: '16px',
    backgroundColor: '#FF0098',
    borderRadius: '4px',
    padding: '2px',
    marginLeft: '6px',
    marginRight: '4px',
    marginBottom: '2px',
  };
  const isShowTL = item.source && item.source === 'truelayer' ? true : false;

  return (
    <>
      <span>{title}</span>
      {isShowTL &&
        <span >
          <img alt='truelayer' src="https://truelayer-public-assets.s3-eu-west-1.amazonaws.com/logo/mark/tl-white.svg"  style={{ ...style }} />
        </span>
      }
    </>
    );
};

const truncateString = (str, maxLength) => {
  if (str.length <= maxLength) {
      return str;
  }

  // Find the last space within the maxLength
  let truncated = str.slice(0, maxLength + 1);
  let lastSpaceIndex = truncated.lastIndexOf(' ');

  // If no space is found, return the original truncation
  if (lastSpaceIndex === -1) {
      return truncated.slice(0, maxLength);
  }

  // Return the truncated string up to the last space
  return truncated.slice(0, lastSpaceIndex);
}

export const BankAccountsList = ({ condition, onAddAccount, onEditAccount, onDeleteAccount, locStrings, client, data, isAdmin, ...rest }) => {

  const footerActionCard =  (
    <Space direction='horizontal'>
      <Button key="1" type="text" icon={<PlusCircleOutlined />} onClick={onAddAccount}>Add a New Bank Account</Button>
    </Space>
  );

  const deleteAction = (item, isAdmin) => {
    const isHidden = item.hide ? item.hide : false;
    const actionText = item.hide ? 'Restore' : 'Delete';
    return isAdmin ? <Button type='link' size='small' onClick={() => { onDeleteAccount(item.id, !isHidden) }}>{actionText}</Button> : null;
  }

  const actions = (item, isAdmin) => {
    let actionList = [];

    const status = item.status;
    let statusText = '';
    let color = 'green';

    switch (status) {
      case 'live':
        statusText = 'Live';
        color = 'darkgreen';
        break;
      case 'waiting':
        statusText = 'Activating...';
        color = 'blue';
        break;
      case 'deleted':
        statusText = 'Deleted';
        color = 'red';
        break;
      case 'retry':
        statusText = 'Action required';
        color = 'orange';
        break;
      case 'paused':
        statusText = 'Paused';
        color = 'lightgray';
        break;
      case 'ready':
        statusText = 'Ready';
        break;
      default:
        statusText = status || 'Ready';
        break;
    }

    if (item.provider && item.provider === 'moneyjar') {
      if(item.status && item.status === 'live') {
        actionList = [
          <Link to={`./bank/${item.id}`}>
            <Button type='default' size='small'>Details</Button>
          </Link>,
          <Link to={`./bank/${item.id}/payouts`}>
            <Button type='default' size='small'>Payouts</Button>
          </Link>,
        ]  
      } else {
        actionList = [
          <Link to={`./bank/${item.id}`}>
            <Button type='primary' size='small'>Continue Setup</Button>
          </Link>,
        ]  
      }
    } else {
      actionList = [
        <Link to={`./bank/${item.id}/payouts`}>
          <Button type='default' size='small'>Payouts</Button>
        </Link>,
      ];
    }

    actionList = [
      ...actionList,
      <Button type='link' size='small' onClick={() => { onEditAccount(item.id) }}>Edit</Button>,  
    ];

    if(isAdmin) {
      actionList = [
        ...actionList,
        deleteAction(item, isAdmin),
      ];
    }

    actionList = [
      ...actionList,
      <Tag color={color}>{statusText}</Tag>,
    ];

    return [
      ...actionList,
    ];
  };

  return ( 
    <List
      itemLayout="vertical"
      size='large'
      align='left'
      loading={!condition()}
      header={footerActionCard}
      dataSource={data}
      renderItem={item => (
        <List.Item actions={actions(item, isAdmin)} key={`bank-li-${item.id}`}>
          <List.Item.Meta
            avatar={iconForCCY(item.currency)}
            title={makeDisplayName(item)}
            description={makeBankDescription(item)}
          />
          { item.status === 'retry' && item.errorMessage &&
            <Space direction='vertical'>
              <Space direction='horizontal'>
                <Tag icon={<WarningTwoTone twoToneColor={'#f5222d'}/>}>Fix</Tag>
                <Text ellipsis>
                  {`${truncateString(item.errorMessage, 44)}`}
                </Text>
              </Space>
              {
                item.errorMessage && item.errorMessage.length > 44 && 
                  <Paragraph type='secondary'>{`${item.errorMessage}`}</Paragraph>
              }
            </Space>
          }
        </List.Item>
      )}
    />
  );
};

const PayoutListItem = ({ id, status, date, money, payout }) => {
  let color = (status === 'paid') ? 'gold' : 'green';
  let statusText = (status === 'paid') ? 'Paid' : 'Sent';
  const { currency } = money || {};
  const avatar = iconForCCY(currency);  
  const description = payout ? makeShortBankDescription(payout) : '';
  const title = payout ? makeDisplayName(payout) : null;

  switch(status) {
    case 'sent':
      statusText = 'Sent';
      color = 'green';
      break;
    case 'paid':
      statusText = 'Paid';
      color = 'darkgreen';
      break;
    case 'api_requested':
      statusText = 'Requested...';
      color = 'blue';
      break;
    case 'waiting':
      statusText = 'Check in 2-3 days...';
      color = 'green';
      break;
    case 'batch_requested':
      statusText = 'Check in 2-3 days...';
      color = 'green';
      break;
    case 'batched':
      statusText = 'Check in 2-3 days...';
      color = 'green';
      break;
    case 'refund_requested':
      statusText = 'Refunding...';
      color = 'green';
      break;
    case 'refund_failed':
      statusText = 'Contact Support!';
      color = 'red';
      break;
    case 'refunded':
      statusText = 'Cancelled payout';
      color = 'yellow';
      break;
    default:
      statusText = status;
  }

  return (
    <Space direction='vertical' size={'small'}>
      <Space direction='horizontal' size={'small'}>
        {avatar}
        <Space direction='vertical' size={'small'}>
        <Space direction='horizontal' spacing={0}>
          {title && <Text strong>{title}</Text>}
          {description && <Text>{description}</Text>}
        </Space>
          <Space direction='horizontal'>
            <Text>Ref:</Text>
            <Text code >{id}</Text>
          </Space>
        </Space>
      </Space>
      <Space direction='horizontal'>
        <Tag color={color}>{statusText}</Tag>
        <Button 
          type='default' 
          size='small' 
          onClick={() => { window.location.href = `../../withdrawal/${id}`; }}
        >
          Detail
        </Button>
      </Space>
      <Text>{date}</Text>
    </Space>
  );
}

const PayoutListAmountItem = ({ amount, currency, status }) => {
  return (
    <MoneyTitle
      amount={amount} currency={currency} digits={2}
      strikethrough={reversedStatuses.includes(status) ? true : false}
    />
  );
}

export const PayoutsList = ({ condition, locStrings, payoutMethodId, withdrawals, ...rest }) => {
  const columns = [
    {
      title: 'Withdrawal',
      dataIndex: 'statuses',
      key: 'statuses',
      render: item => ( 
        <PayoutListItem {...item} />
      )
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: item => (
        <PayoutListAmountItem {...item} />
      )
    },
  ];
  
  const tableData = [];
  let key = 1;
  withdrawals.forEach(withdrawal => {
    if (allowedStatuses.includes(withdrawal.status)) {
      const formattedAmount = `${ccy[withdrawal.currency]}${ (withdrawal.amount/100).toFixed(2)}`
      const formattedDate = moment(withdrawal.createdAt.toMillis()).calendar().toString();

      const payoutRow = {
        amount: { 
          amount: withdrawal.amount, currency: withdrawal.currency,
          status: withdrawal.status,
        },
        statuses: { 
          id: withdrawal.withdrawalId,
          date: formattedDate,
          status: withdrawal.status,
          amount: formattedAmount,
          clientId: withdrawal.clientId,
          money: { amount: withdrawal.amount, currency: withdrawal.currency },
          payout: withdrawal.payout,
        },
        date: formattedDate,
        key: `wt-tr-${withdrawal.withdrawalId}`,
      }

      key++;
      tableData.push(payoutRow);
    }
  });

  return ( 
    <Table 
      columns={columns} 
      dataSource={tableData} 
    />
  );

};




