import React, { useState } from 'react';
import { Alert , Card , Button, Modal, Radio, InputNumber, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import firebase from 'firebase/app';
import { Link } from 'react-router-dom';
import moment from 'moment';

import TrackerService from '../../utils/TrackerService';

import { isLocalHost, ccy } from './AccountProfileComponents';
import { useClientTeams } from './useClientTeams';
import { useTeamDetails } from './useTeamDetails';


const setDefaultTeam = (teams, currency) => {
  for (const [i, team] of teams.entries()) {
    if (team.defaultCurrency === currency) {
      return i;
    }
  }

  return 0;
}

export const CashOutToTeamDialog = ({ visible, handleOk, handleCancel, clientId, accountId, currency, clientCurrencyStr, balance, locStrings, ...rest }) => {
  const teams = useClientTeams(clientId);
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(() => (teams && teams.length) ? setDefaultTeam(teams, clientCurrencyStr) : 0);
  const [payoutAmount, setPayoutAmount] = useState(() => balance ? balance / 100 : null);
  const [withdrawalError, setWithdrawalError] = useState(false);
  const [withdrawalErrorMessage, setWithdrawalErrorMessage] = useState("");

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    textAlign: 'left',
  };

  const onSelectedTeamChange = (event) => {
    const index = event.target.value;
    setSelectedTeamIndex(index);    
  };

  const onPayoutAmountChange = (number) => {
    setPayoutAmount(number);
  };

  const withdrawalAvailable = () => {
    return !!teams;
  };

  const teamOptions = teams ? teams.map((team, i) => (
    <Radio 
      style={radioStyle} value={i} key={i} disabled={false}
    >
      {team.teamName}
    </Radio>
    )
  ) : <></>;

  const setWithdrawalObject = async () => {
    if (!withdrawalAvailable()) {
      handleCancel();
      return;
    }

    const payoutAmountCents = Math.round(payoutAmount * 100);

    const teamMemberDetail = teams[selectedTeamIndex];
    const teamId = teamMemberDetail.teamId;

    const db = firebase.firestore();
    const teamDetailDoc = await db.collection('teams').doc(teamId).get();
    const teamDetail = teamDetailDoc.data();
    const transferType = 'client-to-client';
    const fromClientType = 'client';
    const toClientType = 'team';



    if (teamDetail.defaultCurrency !== clientCurrencyStr) {
      setWithdrawalError(true);
      setWithdrawalErrorMessage(`Error: Bank account currency (${ccy[teamDetail.defaultCurrency]}) does not match funds being withdrawn (${currency}). select another payout method or add a new one in Setup Payouts`);
      return;
    }


    if (payoutAmountCents < minimumWithdrawalAmount) {
      setWithdrawalError(true);
      setWithdrawalErrorMessage(`Error: Minimum withdrawal is ${currency}${minimumWithdrawalAmountString}`);
      return;
    }

    const isTest = isLocalHost;

    const tracker = new TrackerService({
      isTest,
    });

    tracker.mixpanelTrack("client_transfer", {
      distinct_id: clientId,
      clientId,
      currency: clientCurrencyStr,
      teamName: teamDetail.teamName,
      teamId,
      payoutAmountCents,
      toClientId: teamDetail.teamClientId,
      accountId: accountId,
      transferType,
      fromClientType,
      toClientType,
    });

    if (!isTest) {
      tracker.googleTrack(
        "client_transfer",
        {
          clientId,
          currency: clientCurrencyStr,
          teamName: teamDetail.teamName,
          teamId,
          payoutAmountCents,
          toClientId: teamDetail.teamClientId,
          accountId: accountId,
          transferType,
          fromClientType,
          toClientType,    
        }
      );
    }

    try {
        await db.collection('transfers').add({
            toTeamId:  teamMemberDetail.teamId,
            fromClientId: clientId,
            toClientId: teamDetail.teamClientId,
            accountId: accountId,
            currency: clientCurrencyStr,
            payoutAmountCents: +payoutAmountCents,
            transferType,
            fromClientType,
            toClientType,      
        });
    } catch (error) {
        console.log(JSON.stringify(error));
        console.log('Transfer request failed.')
    }

    handleOk();
  };

  const minimumWithdrawalAmount = 1;
  const minimumWithdrawalAmountString = (minimumWithdrawalAmount / 100).toFixed(2);

  return (
    <Modal title="Transfer to a Team" visible={visible} onOk={setWithdrawalObject} onCancel={handleCancel}>
      <div style={{ textAlign: 'center' }}>
        {withdrawalError && <Alert message={withdrawalErrorMessage} type="error" showIcon />}

        {withdrawalAvailable()
          ? (
            <>
              <p>{`Note: no limits on Team Transfers`}</p>
              <Card>
                <p>Select Team</p>

                {teams && teams.length > 0
                  ?
                  <Radio.Group onChange={onSelectedTeamChange} value={selectedTeamIndex}>
                    {teamOptions}
                  </Radio.Group>
                  :
                  <>
                    <p>You have no teams yet</p>
                  </>}
              </Card>

              <Card>
                <p>Enter Amount</p>
                <p>Your balance is {currency}{(balance / 100).toFixed(2)}</p>

                {balance >= minimumWithdrawalAmount
                  ?
                  <InputNumber
                    defaultValue={0}
                    min={minimumWithdrawalAmount / 100}
                    max={balance / 100}
                    precision={2}
                    onChange={onPayoutAmountChange} />
                  :
                  <p>{`Minimum payout amount is ${currency}${minimumWithdrawalAmountString}`}</p>}
              </Card>
            </>
          )
          : (
            <Card>
              <p>{`No teams found!`}</p>
            </Card>
          )}
      </div>
    </Modal>
  );
};
