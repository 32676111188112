import firebase from 'firebase/app';

import FirebaseSetup from '../FirebaseSetup'

const isLocalHost = window.location.hostname === 'localhost';

export default class StrikeWithdrawalModel {

  constructor({ clientId, withdrawalId }) {
    this.clientId = clientId;
    this.withdrawalId = withdrawalId;
    const firebaseSetup = new FirebaseSetup();
    this.currentUser = firebaseSetup.getCurrentUserDataInApp();
  } 

  async getWithdrawalData(id) {
    const clientId = this.clientId;
    const withdrawalId = id || this.withdrawalId;
    if (withdrawalId === null || clientId === null) return null;

    const db = firebase.firestore();
    const query = db.collection('clients').doc(clientId).collection('withdrawals').doc(withdrawalId);
    const withdrawalDoc = await query.get();
    if (withdrawalDoc.exists) {
      const withdrawalData = withdrawalDoc.data();
      const { amount: providedAmount, payoutAmountCents } = withdrawalData;
      const amount = providedAmount || payoutAmountCents;
      return { id: withdrawalDoc.id, ...withdrawalDoc.data(), amount }
    } else {
      return null;
    }
  }

  async setWithdrawalStatusToPaid() {
    if (this.withdrawalId && this.clientId) {
      const db = firebase.firestore();
      const query = db.collection('clients').doc(this.clientId).collection('withdrawals').doc(this.withdrawalId);
      return await query.set({
        status: 'paid',
        updatedAt:  firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: this.currentUser,
      }, {
        merge: true
      });
    } else {
      return null;
    }
  }

}
