import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Typography, PageHeader, Tag , Button } from 'antd';
import { LogoutOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';

import StrikeClientModel from '../../../models/StrikeClientModel';
import StrikeTagsModel from '../../../models/StrikeTagsModel';
import { StrikeTagsList } from '../../../components/client/TagsComponents';
import { ShowScanTagDialog } from '../../../components/client/AccountProfileComponents';
import StrikeTagModel from '../../../models/StrikeTagModel';
import CenteredSpinner from '../../../components/CenteredSpinner';


// /client/manage/profile
export default class TagsClientProfileScreen extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    const isAdmin = !!props.isAdmin;
    this.clientModel = new StrikeClientModel({ viewModel: this, clientId, isAdmin });
    this.model = new StrikeTagsModel({ viewModel: this, clientId });

    this.state = {
      clientId: clientId,
      client: null,
      tags: [],
      loading: true,
      loadingTags: true,
      mode: 'viewing',
      isAdmin: isAdmin,
    };
  }

  componentDidMount() {
    this.clientModel.waitForClientData();
  }

  componentWillUnmount() {
    if(this.clientModel) this.clientModel.stop();
    if(this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || false;
  }


  foundClient = async (client) => {
    this.setState(
      (state) => {
        return {
          client,
          loading: false,
          loadingTags: true,
        }
      }
    );

    this.model.fetchAllTagsData();
  }

  updatedTagsDataSource = (tags) => {
      
    this.setState(
      (state) => {
        return {
          tags,
          loadingTags: false,
        }
      }
    );

  }


  clientNotFound = () => {
    this.setState(
      (state) => {
        return {
          loading: false,
          clientNotFound: true,
        }
      }
    );
  }

  changeMode = (mode) => {
    if (mode === 'viewing') {
      return 'editing'
    } else if (mode === 'editing') {
      return 'saving'
    } else {
      return 'viewing';
    }
  }


  onClickEdit = () => {
    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    }
    );
  }

  doneSaving = () => {
    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    }
    );

    this.model.waitForClientData();
  }

  internalSave = async (data) => {
    await this.model.commit(data);
    this.doneSaving();
  }


  onClickAdd = () => {
    this.setState((state) => {
      return {
        mode: 'adding',
        isModalVisible: true,
      }
    }
    );
  }

  onClickCancel = () => {
    console.log(`onClickCancel`);

    this.setState((state) => {
      return {
        mode: 'viewing',
        isModalVisible: false,
      }
    }
    );
  }

  getCSVHeaders = () => {
    return [
      { label: "Device ID", key: "tagId" },
      { label: "Product", key: "product" },
      { label: "Location", key: "nickname" },
      { label: "Address", key: "address" },
      { label: "Number", key: "number" },
      { label: "Contact", key: "contact" },
      { label: "Currency", key: "currency" },
      { label: "Earned Till Date", key: "earnedTillDate" },
      { label: "Updated On", key: "updatedAt" },
      { label: "Updated By", key: "updatedBy" },
    ];
  }

  getFileName = () => {
    const shortName = this.state.client.displayName.replace(/\s+/g, '-').toLowerCase();
    const now = new Date()
    const date = `${now.toISOString().split('T')[0]}`;
    return `${shortName}-tags-${date}.csv`;
  }

  getCSVTagData = (items) => {
    const tags = [];

    items
        .filter(item => !item.is_test)
        .forEach((item) => {
          const tag = {};
          tag.tagId = item.id;
          tag.product = item.product;
          tag.nickname = item.metadata ? item.metadata.nickname : '';
          tag.address = item.metadata ? item.metadata.location : '';
          tag.number = item.metadata ? item.metadata.number : '';
          tag.contact = item.metadata ? item.metadata.contact : '';
          tag.currency = (item.paymentDetails && item.paymentDetails.currency) ? item.paymentDetails.currency : '';
          tag.earnedTillDate = item.totalNetValue ? +(item.totalNetValue/100).toFixed(2): 0.00;
          tag.updatedAt = item.updatedAt ? item.updatedAt.toDate() : '';
          tag.updatedBy = item.updatedBy ? item.updatedBy.email : '';
          tags.push(tag);
        });

    return tags;
  }

  getButtons = () => {
    if (this.state.mode === 'viewing') {
      const headers = this.getCSVHeaders();
      const filename = this.state.client ? this.getFileName() : null;
      const data = this.state.tags ? this.getCSVTagData(this.state.tags) : null;

      return [
        <CSVLink key="1" data={data} headers={headers} filename={filename} >
          <Button type="text" shape='circle' icon={<LogoutOutlined style={{ fontSize: '28px', color :'#070'}}/>} />
        </CSVLink>,
        <Button key="2" type="text" shape='circle' icon={<PlusCircleOutlined style={{ fontSize: '28px', color :'#070'}}/>} onClick={this.onClickAdd} />
      ]
    } else if (this.state.mode === 'adding') {
      return [
        <Button key="1" type="danger" onClick={this.onClickCancel}>
          Cancel
        </Button>,
      ];
    } else {
      return [];
    }
  }

  onEditTag = async (tag, updatedTagData ) => {
    const strikeTagModel = new StrikeTagModel({ viewModel: this, tagId: tag.id });
    console.log('onEditTag ', updatedTagData);
    return await strikeTagModel.updateTagWithData(updatedTagData);
  } 

  render() {
    if (this.state.mode === 'viewing' && !this.state.loading && this.state.client ) {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Your Strikepay Tags`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'adding' ? null: <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />

          <CenteredSpinner loading={this.isLoading()} noLayout={false} />
          <StrikeTagsList
            condition={() => { return !this.state.loadingTags }}
            data={this.state.tags}
            onEditTag={this.onEditTag}
            currentUser={this.clientModel.currentUser}
            {...this.props}
          />
        </div>
      );
    } else if (!this.state.loading) {
      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`Your Strikepay Tags`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
          <ShowScanTagDialog 
            visible={this.state.isModalVisible}  
            handleOk={this.onClickCancel} 
            handleCancel={this.onClickCancel} 
            locStrings={this.props.locStrings} 
          />
        </div>
      );
    } else {
      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`Your Strikepay Tags`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
          <CenteredSpinner loading={this.isLoading()} noLayout={false} />
        </div>
      );
    }
  }
}

