import { useState, useEffect } from 'react';

import WebhooksModel from './WebhooksModel';


export function useWebookConfigs(partnerClientId, enabled) {
    const [webhookConfigs, setWebhookConfigs] = useState(null);

    useEffect(() => {
        const onUpdateConfigs = async (configsList) => {
            setWebhookConfigs(configsList);
        };


        const model = new WebhooksModel({
            partnerClientId,
            onUpdateConfigs,
            configParams: {
                enabled
            }
        });

        model.waitForWebhookConfigData();

        return function cleanup() {
            model.stop();
        };

    },
        []
    );

    return webhookConfigs;
}
