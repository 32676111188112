import React, { Component } from 'react';
import firebase from 'firebase/app';
import '../../App.scss';
import 'antd/dist/antd.css';
import { Space, PageHeader, Card, Button, Layout } from 'antd';
import { Link, Redirect } from 'react-router-dom';

import CenteredSpinner from '../../components/CenteredSpinner';
import { SetupTagMetadataForm } from '../../components/customer/SetupTagMetadataForm';
import StrikeFooter from '../../components/StrikeFooter';
import img_elImage from '../../images/strikepay_Mark_RGB_Black.png';

const { Content, Footer } = Layout;

export default class SetupRequiredScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientNotFound: false,
      route: null,
      tagId: this.props.match.params.tagId,
      selectedCancel: false,
      loading: true,
      setupFinished: false,
      error: null,
    };
  }

  async componentDidMount() {
    await this.getTag();
  }

  getTag = async () => {
    const db = firebase.firestore();
    const tagDoc = await db.collection('tags').doc(this.state.tagId).get();

    this.setState({
      tag: tagDoc ? tagDoc.data() : null,
      loading: false,
    });
  }

  getJustGivingPageId = async (url) => {
    if (url.match(/justgiving.com\/fundraising/)) {
      const pageShortName = url.substring(url.lastIndexOf('/') + 1)
      const response = await fetch(`https://api.justgiving.com/886e8560/v1/fundraising/pages/${pageShortName}`, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
      });
      const body = await response.json();
      return body.pageId;
    }
    return null;
  }

  setTagMetadata = async (values) => {
    this.setState({
      loading: true,
    })
    
    const db = firebase.firestore();

    let metadata = {
      nickname: values.nickname,
      location: values.location,
      number: values.number,
      contact: values.contact,
      email: values.email,
      lastModified: firebase.firestore.Timestamp.fromDate(new Date()),
    }

    let justGivingData = (values.justGivingEnabled) ? {
      justGivingEnabled: values.justGivingEnabled,
      inputUrl: values.inputUrl,
    } : null;

    let tagUpdates = {
      setupRequired: false,
      metadata,
      justGivingData,
      updatedAt: firebase.firestore.Timestamp.now(),
    };

    if (values.justGivingEnabled) {
      const justGivingPageId = await this.getJustGivingPageId(values.inputUrl);
      if (!justGivingPageId) {
        this.setState({
          loading: false,
          setupFinished: false,
          error: 'Not a valid JustGiving link. Please check and try again.'
        });
        return;
      }
      tagUpdates.claimType = 'redirect';
      tagUpdates.redirectUrl = `http://link.justgiving.com/v1/fundraisingpage/donate/pageId/${justGivingPageId}?tipScheme=TipJar2.1&reference=strikepay-${this.state.tag.clientId}`;
    }

    await db.collection('tags').doc(this.state.tagId).update(tagUpdates);

    this.setState({
      loading: false,
      setupFinished: true,
    })
  }

  renderRedirectToTag = () => {
    const toRoute = `/tags/${this.state.tagId}`;
    return <Redirect to={toRoute} />
  }

  render() {
    const style_elImageLogo = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',  
      width: '50%', 
      marginLeft: 'auto',      
      marginRight: 'auto',     
      marginTop: '10px',
      marginBottom: '10px',
      maxWidth: '400px',
    };

    if (this.state.tag && this.state.tag.clientId && !this.state.tag.setupRequired) return this.renderRedirectToTag();

    return (
      <div className="container">

        <Content style={{ padding: '0 12px', maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
          <div style={{ textAlign: "center" }}>
            <img style={style_elImageLogo} src={(img_elImage)} alt="strike" />
          </div>

          <Card>
            <CenteredSpinner loading={this.state.loading} />

            {(!this.state.loading && !this.state.tag) &&
              (<PageHeader
                ghost={false}
                title={`Tag Not Found`}
              />)
            }

            {(!this.state.loading && this.state.tag && !this.state.route && this.state.tag && !this.state.setupFinished) &&
              (
                <>
                  <h3>Finish setup</h3>
                  <p>Fill out details about this tag's location &amp; other information before it can be used</p>
                  <SetupTagMetadataForm tag={this.state.tag} callback={this.setTagMetadata} error={this.state.error} />
                </>
              )
            }

            {(!this.state.loading && this.state.tag && this.state.setupFinished) &&
              (
                <>
                  <p>Tag setup complete</p>
                  <Space>
                    <Link to={`/tags/${this.state.tagId}`}><Button key='confirm' type='primary'>View tag</Button></Link>
                  </Space>
                </>
              )
            }

            {(this.state.client === null) &&
              (null)
            }
          </Card>
        </Content>
        <StrikeFooter locStrings={this.props.locStrings} />
      </div>
    );
  }
}

