import React, { useState, useEffect, useContext } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import {
  Space,
  Typography,
  Table,
  Card,
  Skeleton,
  Divider,
} from 'antd';

import MoneyTitle from '../customer/MoneyTitle';

import StatusTableRowElement from './StatusTableRowelement';

const { SkeletonButton } = Skeleton;

const LoadingCard = ({ loading }) => {
  return loading ? (
    <>
      <Card>
        <Space direction='vertical'>
          <Space direction='horizontal'>
            <SkeletonButton active style={{ width: '100px', height: '44px' }} />
            <SkeletonButton active style={{ width: '100px', height: '44px' }} />
            <SkeletonButton active style={{ width: '100px', height: '44px' }} />
          </Space>
          <Divider />
          <SkeletonButton shape='round' active size='large' />
          <SkeletonButton shape='round' active size='large' />
          <SkeletonButton shape='round' active size='large' />
        </Space>
      </Card>

    </>
  ) : null;

};


function summaryOfTotalsAndCountsByCurrencyAndStatus(dataSource, titles) {
  const data = [];
  let columns = [];
  const currencies = {};
  const statuses = {};
  const amounts = [];
  const counts = [];

  dataSource.forEach((item) => {
    const { currency, status, amount } = item;
    const key = `amount-${currency}-${status}`;
    if(amounts[key]) {
      amounts[key] += amount;
    } else {
      amounts[key] = amount;
    }
    currencies[currency] = true;
    statuses[status] = true;  
    counts[status] = counts[status] ? counts[status] + 1 : 1;
  });

  Object.keys(statuses).forEach((status) => {
    let row = { status, count: counts[status] || 0 };
    Object.keys(currencies).forEach((currency) => {
      const key = `amount-${currency}-${status}`;
      row = { ...row, [currency]: amounts[key] || 0 };
    });
    data.push(row);
  });

  const currencyColumns = Object.keys(currencies).map((currency) => ({
    title: currency,
    dataIndex: currency,
    key: currency,
    align: 'right',
    render: (item) => <MoneyTitle amount={item || 0} currency={currency} digits={2} />
  }));

  columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (item) => <Space direction='horizontal'>
            <StatusTableRowElement status={item}/>
            <Typography.Text>{`Amount ${titles[item] || item}`}</Typography.Text>
          </Space>
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      align: 'right',
    },
    ...currencyColumns,
  ];

  return { data, columns };
}

export const StatusSummaryTable = ({ dataSource, loading, titles }) => {
  const [isLoading, setIsLoading] = useState(loading);
  const [data, setData] = useState(null);
  const [columns, setColumns] = useState(null); 

  useEffect(() => {
    setIsLoading(true);
    const { data, columns } = summaryOfTotalsAndCountsByCurrencyAndStatus(dataSource, titles);  
    setData(data);
    setColumns(columns);
    setIsLoading(false);
  }, [dataSource]);

  return <>
    { isLoading &&
        <loadingCard loading={isLoading} />
    }
    <Table 
      key={`status-table-summary`}
      loading={isLoading}
      dataSource={data || []} 
      columns={columns || []} 
      rowKey={(item) => `summary-${item.currency}-${item.status}`}
      size='small'
    />
  </>;
}
