// Process flows for Payout object
//  'created' => first status when object is created 
//  'ready' => ready to provision (additonal fields added) 
//  'validated' => Payout has been updated with link etc. 
//  'connecting' => Payout has used link to connect to third party Provider (set by button click in UI)
//  'waiting' => Payout has is waiting for third party Provider 
//  'live' => Payout object is available to use 
//  'paused' => (legacy: hide) Stops the Payout object from being in a Cash Out list
//  'closed' => The account is closed

// Different Payouts:
//
//  type == 'instant'
//  provider == 'moneyjar'
//    Use MoneyJarPayoutService to handle this type of Payout setup
//
//  type == 'push'
//  provider == 'revolut'
//    Use RevolutPayoutService to handle this type of Payout setup
// 
//  When type and provider are not provided, we just handle as normal (no special processing)


export default class MoneyJarService {

  static moneyJarClientId = 'moneyjar';

  constructor(payout) {
    const { isTest, clientId, clientDisplayName, uid, link } = payout || {};
    this.payout = payout;
    this.isTest = isTest;
    this.clientId = clientId;
    this.clientDisplayName = clientDisplayName;
    this.uid = uid;
    this.link = link;
  }

  onCreatePayoutData() {
    const moneyJarPayoutData = {
      displayName: 'MoneyJar Instant',
      source: 'moneyjar',
      type: 'instant',
      provider: 'moneyjar',
      currency: 'EUR',
      country: 'IE',
      status: 'created',
      isTest: !!(this.isTest),
    };
    return moneyJarPayoutData;
  }

  getInstallLink() {
    return this.link;
  }

  isMoneyJarPayout() {
    const { type, provider } = this.payout || {};
    return type === 'instant' && provider === 'moneyjar';
  }

  isPayoutActive() {
    const { status } = this.payout || {};
    const active = ['live'].includes(status);
    return active;
  }

  isActionDisbaled() {
    const { status } = this.payout || {};
    const disbaled = !['validated', 'waiting', 'live', 'paused'].includes(status);
    return disbaled;
  }

  getActionButtonDetails() {
    let details = {};
    const { status } = this.payout || {};
    const disbaled = this.isActionDisbaled();

    if (!disbaled) {
      switch (status) {
        case 'validated':
          details = { 
            text: 'Install MoneyJar',
            subText: 'You will need to install the MoneyJar app and create an account.',
          };
          break;
        case 'waiting':
          details = { 
            text: 'Retry',
            subText: 'We are waiting for you to install the MoneyJar app and create an account.',
          };
          break;
        case 'live':
          details = { 
            text: 'Go to MoneyJar',
            subText: 'Open MoneyJar app. This will switch to the the app on this device.',
          };
          break;
        case 'paused':
          details = { 
            text: 'Go to MoneyJar',
            subText: 'Open MoneyJar app. This will switch to the the app on this device.',
          };
          break;
      }

    }

    return details;
  }

  getExistingPayoutId(payouts) {
    if(payouts) {
      const moneyjarPayouts = payouts.filter(v => (v.provider && v.provider === 'moneyjar'));
      if(moneyjarPayouts.length > 0) {
        const payout = moneyjarPayouts[0];
        return payout;
      }
    } 
    return null;
  }

}
