import { useState, useEffect } from 'react';

import StrikeTagsModel from './StrikeTagsModel';

export function useClientTagForProduct(clientId, product) {
    const [tag, setTag] = useState(null);

    useEffect(() => {
        const onUpdateTagsList = async (tagsList) => {
            const tag = tagsList && tagsList.length > 0 ? tagsList[0] : null;
            setTag(tag);
        };

        const model = new StrikeTagsModel({
            clientId,
            product,
            limit: 1,
            callback: onUpdateTagsList,
        });

        model.fetchAllTagsData();

        return function cleanup() {
            model.stop();
        };

    },
        []
    );

    return tag;
}
