import React, { useState, useParams } from 'react';
import firebase from 'firebase/app';
import '../../App.scss';
import 'antd/dist/antd.css';
import moment from 'moment';
import { Button, Card, Checkbox, Descriptions, Form, Input, Modal, Typography, Table, Tabs, Tag, Tooltip, Empty } from 'antd';
const { Text, Paragraph } = Typography;
import { CheckCircleFilled, ClockCircleTwoTone, CloseCircleFilled, RedoOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;

import { useWebookConfigs } from '../../models/useWebhookConfigs';
import { useWebookEvents } from '../../models/useWebhookEvents';
import WebhooksModel from '../../models/WebhooksModel';
import { useClientDetails } from '../client/useClientDetails';

const isLocalHost = window.location.hostname === 'localhost';


export const DeveloperConfigView = ({ clientId, isAdmin, ...rest }) => {
  const [showModal, setShowModal] = useState(false);
  const [configForEditing, setConfigForEditing] = useState(clientId ? { partnerClientId: clientId } : null);
  const [form] = Form.useForm();
  const client = useClientDetails(clientId);
  const webhookEvents = useWebookEvents(clientId);
  const webhookConfigs = useWebookConfigs(clientId);

  const onClickModalCancel = () => {
    form.resetFields();
    setConfigForEditing(null);
    setShowModal(false);
  }

  const modalTitle = (configForEditing ? 'Edit' : 'Add') + ' Webhook Configuration';
  const modalCtaCopy = configForEditing ? 'Update' : 'Create';
  const tabKeyConfig = `${clientId ? clientId : 'strike'}-configs`;
  const tabKeyEvents = `${clientId ? clientId : 'strike'}-events`;
  const tabKeyTemplates = `${clientId ? clientId : 'strike'}-templates`;
  const isDeveloper = (client && client.isDeveloper) ? true : false;

  const createOrUpdateWebhookConfig = (id, values) => {
    const model = new WebhooksModel({ });  
    model.createOrUpdateWebhookConfig(id, values);
  }

  const onFinish = (values) => {
    let valuesUpdated = values;
    if (clientId) valuesUpdated['partnerClientId'] = clientId;
    createOrUpdateWebhookConfig(configForEditing && configForEditing.id, valuesUpdated);
    onClickModalCancel();
  }

  return (
    (isDeveloper || isAdmin)  ?
    <>
      <Tabs defaultActiveKey={tabKeyConfig}>
        <TabPane tab="Configuration" key={tabKeyConfig}>
          <WebhookConfigurationList 
            configs={webhookConfigs} 
            setConfigForEditing={setConfigForEditing} 
            setShowModal={setShowModal} 
            isCreateEnabled={isAdmin}
          />
        </TabPane>
        <TabPane tab="Events" key={tabKeyEvents}>
          <WebhookEventsList events={webhookEvents} />
        </TabPane>
        <TabPane tab="Templates" key={tabKeyTemplates}>
          <Text>Coming soon...</Text>
        </TabPane>
      </Tabs>
      <Modal title={modalTitle}
        visible={showModal}
        onCancel={() => onClickModalCancel()}
        footer={null}
        bodyStyle={{ minHeight: '200px', minWidth: '280px' }}
      >
        <div style={{textAlign: 'center'}}>
          <Card>
            <Form
              form={form}
              name="configForm"
              onFinish={onFinish}
              initialValues={configForEditing}
            >
              <Form.Item
                name="description"
                label="Description"
                style={{ width: '100%', textAlign: 'left' }}
              >
                <Input maxLength={64} />
              </Form.Item>
              <Form.Item
                name="url"
                label="Webhook URL"
                style={{ width: '100%', textAlign: 'left' }}
              >
                <Input maxLength={128} />
              </Form.Item>
              <Form.Item
                name="apiKey"
                label="API Key"
                style={{ width: '100%', textAlign: 'left' }}
              >
                <Input maxLength={64} />
              </Form.Item>
              {
                clientId ?
                  <></>
                :
                  <Form.Item
                    name="partnerClientId"
                    label="Partner Client ID"
                    style={{ width: '100%', textAlign: 'left' }}
                  >
                    <Input maxLength={64} />
                  </Form.Item>
              }
              <Form.Item
                name="enabled"
                label="Enabled"
                valuePropName="checked"
                style={{ width: '100%', textAlign: 'left' }}
              >
                <Checkbox defaultChecked={configForEditing && configForEditing.enabled || true} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {modalCtaCopy}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </Modal>
    </>

    :
    <Paragraph>
      Contact developers@strikepay.co to activate your developer account.
    </Paragraph>
  );
};

const WebhookConfigurationList = ({ configs, setConfigForEditing, setShowModal, isCreateEnabled }) => {
  const isCreateNewConfigButtonEnabled = !!isCreateEnabled;
  let webhookConfigurationCards = isCreateEnabled ? [<WebhookConfigurationButton key={'0'} setConfigForEditing={setConfigForEditing} setShowModal={setShowModal} />] : [];
  if (configs && configs.length > 0) {
    for (let i = 0; i < configs.length; i++) {
      const config = configs[i];
      webhookConfigurationCards.push(<WebhookConfigurationCard config={config} key={i + 1} setConfigForEditing={setConfigForEditing} setShowModal={setShowModal} isLinkEnabled={isCreateEnabled}/>);  
    }
  }

  return webhookConfigurationCards;
}

const addOrEditConfig = (config, setConfigForEditing, setShowModal) => {
  setConfigForEditing(config);
  setShowModal(true);
}

const WebhookConfigurationButton = ({ setConfigForEditing, setShowModal }) => (
  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', margin: '8px 16px' }}>
      <Button
        style={{ backgroundColor: '#ff0098', color: 'white', margin: '8px' }}
        onClick={() => addOrEditConfig(null, setConfigForEditing, setShowModal)}  
      >Add Webhook Configuration</Button>
  </div>
)

const WebhookConfigurationCard = ({ config, setConfigForEditing, setShowModal, isLinkEnabled }) => {
  const prefixURL = isLocalHost ? 'http://localhost:3000/admin/clients' : 'https://app.strikepay.co/admin/clients';
  const partnerClientIdUrl = `${prefixURL}/${config.partnerClientId}`;
  const configEnabled = config.enabled ? true : false;

  const title = (
    <>
      <p>{config.description}</p>
      <Tag color='grey'>{config.id}</Tag>
    </>
  );
  return (
    <Card>
      <Descriptions 
          bordered
          title={title}
          size={'small'}
          extra={<Button type="primary" onClick={() => addOrEditConfig(config, setConfigForEditing, setShowModal)}>Edit</Button>}
      >
        <Descriptions.Item label="API Key">{config.apiKey}</Descriptions.Item>
        <Descriptions.Item label="Webhook URL">{config.url}</Descriptions.Item>
        <Descriptions.Item label="Enabled"><Checkbox defaultChecked={configEnabled} disabled /></Descriptions.Item>
        <Descriptions.Item label="Partner Client ID">
          {isLinkEnabled ? 
            <a href={partnerClientIdUrl}>{config.partnerClientId}</a>
            : <>{config.partnerClientId}</>
          }
        </Descriptions.Item>
        <Descriptions.Item label="Events">
          <WebhookConfigEventsList events={config.events} />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

const WebhookConfigEventsList = ({ events }) => {
  let eventsList = [];
  for (let i = 0; i < events.length; i++) {
    const event = events[i];
    eventsList.push(<span key={`el-${event}`}><Text code>{event}</Text><br /></span>);  
  }
  return eventsList
} 

const WebhookEventsList = ({ events }) => {
  const columns = [
    {
      title: 'Date/time',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Attempts',
      dataIndex: 'numberOfAttempts',
      key: 'numberOfAttempts',
    },
    {
      title: 'Status',
      dataIndex: 'webhookStatus',
      key: 'webhookStatus',
      width: 120,
    },
  ];

  const tagColorForEvent = (type) => {
    switch (type) {
      case 'payment_success':
        return 'purple';
      case 'tag_claimed':
          return 'blue';
      case 'tag_paused':
        return 'red';
      }
    return 'red';
  };

  let eventData = [];

  if(events) {
    for (let i = 0; i < events.length; i++) {
      const event = events[i];
      const createdAt = moment(event.createdAt.toMillis()).format("YYYY-MM-DD HH:mm:ss").toString();
      const numberOfAttempts = event.numberOfAttempts;
      const description = (
        <>
          <b>{event.description ? event.description : ''}</b> - {event.url}
        </>
      );
      const type = <Tag color={tagColorForEvent(event.type)}>{event.type}</Tag>;
      const payload = event.payload ? event.payload : null;

      const pendingCell = (
        <>
          <Tooltip title={`Due to retry ${event.webhookNextAttemptAt ? 'at ' + moment(event.webhookNextAttemptAt.toMillis()).format("HH:mm") : ''}`}>
            <ClockCircleTwoTone style={{ paddingRight: '16px' }} />
          </Tooltip>
          <Button shape="circle" icon={<RedoOutlined />} size="small" onClick={() => replayWebhook(event.id)} />
        </>
      );

      const successCell = (
        <Tooltip title={`Successful ${event.webhookSuccessAt ? 'at ' + moment(event.webhookSuccessAt.toMillis()).format("HH:mm") : ''}`}>
          <CheckCircleFilled style={{ color: 'green' }} />
        </Tooltip>
      );

      let webhookStatus = (event.webhookStatus === 'success') ? successCell : pendingCell;
      if (event.webhookStatus === 'failed') {
        webhookStatus = <CloseCircleFilled style={{ color: 'darkred' }} />
      }
      eventData.push({
        key: i,
        createdAt,
        description,
        numberOfAttempts,
        type,
        webhookStatus,
        payload,
      });  
    }
  }
  return (
    <Table
      columns={columns}
      dataSource={eventData}
      expandable={{
        expandedRowRender: (row) => <p style={{ margin: '12px 48px', fontFamily: 'monospace' }}>{JSON.stringify(row.payload)}</p>,
        rowExpandable: (row) => { return row.payload !== null },
      }}
    />
  );
}

const replayWebhook = async (eventId) => {
  const manualRetryWebhook = firebase.functions().httpsCallable('notificationService-webhookHandler-manualRetryWebhook');
  await manualRetryWebhook({ webhookEventId: eventId });
}
