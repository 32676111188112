import React, { Component } from 'react';
import { List, PageHeader } from 'antd';
import '../../../App.scss';
import 'antd/dist/antd.css';

import useNotifications from '../../../models/useNotifications';
import { JSONParagraph } from '../../../components/admin/JSONParagraph';

const AdminNotificationsScreenBody = () => {
  const notifications = useNotifications(null);

  return (
    <div>
        <List
          loading={!notifications}
          size='small'
          spacing={10}
          pagination={true}
          style={{ textAlign: 'start', margin: '2px', padding: '2px' }}
          dataSource={notifications || []}
          renderItem={notification => (
            <List.Item key={`not-li-${notification.id}`} style={{ backgroundColor: 'lightgray' }}>
              <JSONParagraph item={notification} />
            </List.Item>
          )}
        />
    </div>
  );
};


// /admin/notifictions
export default class AdminNotificationsScreen extends Component {

  constructor(props) {
    super(props);
    this.model = null;

    this.state = {
      lastUpdatedId: null,
      notifications: null,
      loading: true,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    if (this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || false;
  }


  render() {

    return (
      <div style={{ height: '100%', textAlign: 'center' }}>
        <PageHeader
          ghost={false}
          title={`Notifications`}
        />
        <AdminNotificationsScreenBody />
      </div>
    );
  }

}

