import React from 'react';
import { Layout, Space } from 'antd';

import FooterCard from './customer/FooterCard';
import PoweredByStrike from './customer/PoweredByStrike';

const { Footer } = Layout;

const StrikeFooter = (locStrings) => (
    <Footer style={{ textAlign: 'center' }} >
        <Space direction='vertical' size='small'>
            <PoweredByStrike locStrings={locStrings} />
            <FooterCard locStrings={locStrings} />
        </Space>
    </Footer>
);

export default StrikeFooter;