import firebase from 'firebase/app';
const isLocalHost = window.location.hostname === 'localhost';

export default class ClientRolesModel {

  constructor({ onUpdate, clientId, limit }) {
    this.clientSubCollection = 'client_roles';
    this.onUpdate = onUpdate;
    this.clientId = clientId;
    this.limit = limit || 100;
    this.dataArray = [];
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    this.viewModel = null;
    if(this.observer) {
      this.observer();
      this.observer = null;
    }
  }

  fetch = () => {
    this.waitOnLoginBeforeClientData();
  }

  waitOnLoginBeforeClientData = () => {
    if (this.authObserver) this.authObserver();
    this.authObserver = firebase.auth().onAuthStateChanged(currentUser => {
      this.continueFetchWithCurrentUser(currentUser ? currentUser.uid : null);
    });
  }

  includeClientsInSwitcher = async (ownerId) => {
    let clients = [];
    const db = firebase.firestore();
    try {
      clients = await db.collection('clients').where('ownerId','==', ownerId).get();
      clients = clients.docs.map(doc => ({ ...doc.data(), id: doc.id, isLoggedInClient: true }));
      if (clients.length !== 1) { console.log('[ERROR] Not exactly one for owner:', ownerId); }
    } catch {
      clients = [];
    }
    return clients;
  }

  continueFetchWithCurrentUser = async (ownerId) => {
    const baseList = await this.includeClientsInSwitcher(ownerId);

    if (baseList.length == 0) { 
      this.updateDataSource();
      return;
    }

    const loggedInClientId = baseList[0].clientId; // always use the first one

    if(this.observer) {
      this.observer();
      this.observer = null;
    }

    const db = firebase.firestore();
    const preQuery = db
        .collection('users')
        .doc(ownerId)
        .collection(this.clientSubCollection);
    const query = preQuery.limit(this.limit);

    this.observer = query.onSnapshot(querySnapshot => {
      this.dataArray = baseList;

      querySnapshot.forEach(
        doc => {
          let data = doc.data();
          this.dataArray.push( { ...data, id: doc.id, });
        }
      );

      this.didFetchAllData();
    }, err => {
      console.log(`Encountered a ${this.clientSubCollection} model error: ${err}`);
    });

  }

  async didFetchAllData() {
    this.dataArray = this.dataArray.filter((v) => (v.id !== this.clientId));
    this.updateDataSource();
  }

  async updateDataSource() {
    if(this.onUpdate) {
      this.onUpdate(this.dataArray);
    }
  }

}
