import firebase from 'firebase/app';

export default class StrikeAdminModel {

  constructor({ viewModel }) {
    this.viewModel = viewModel;
    this.isAdmin = false;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if (this.observerClient) this.observerClient();
    this.observerClient = null;
    if (this.authObserver) this.authObserver();
    this.authObserver = null;
    this.currentUser = null;
  }

  async checkLoggedInUser() {
    let isAdmin = false;

    if(this.currentUser && this.currentUser.uid) {
      const db = firebase.firestore();
      const query = db.collection('clients').doc('strike').collection('admins').doc(this.currentUser.uid);
      const admin = await query.get();
      isAdmin = admin.exists;
    }

    this.isAdmin = isAdmin;
    this.viewModel.setUserRoleInState(isAdmin);
  }

  waitForAdminCheck = async () => {
    if (!this.currentUser) {
      await this.waitOnLoginBeforeAdminCheck();
    } else {
      this.checkLoggedInUser();
    }
  }

  waitForClientTypeCheck = async (clientId) => {
    const db = firebase.firestore();
    let clientDocSnapshot = await db.collection('clients').doc(clientId).get();

    if (clientDocSnapshot.exists) {
      let clientDocData = clientDocSnapshot.data();

      if (clientDocData.type === 'team') {
        const teamDocSnapshot = await db.collection('teams').doc(clientDocData.ownerTeamId).get();
        if (teamDocSnapshot.exists) {
          return ({
            isClientTypeTeam: true,
            teamOwnerClientId: teamDocSnapshot.data().teamOwnerClientId,
            teamId: teamDocSnapshot.id
          });
        }
      } else {
        return ({
          isClientTypeTeam: false
        });
      }
    }
  }

  waitOnLoginBeforeAdminCheck() {
    if (this.authObserver) this.authObserver();
    this.currentUser = null;
    this.authObserver = firebase.auth().onAuthStateChanged(currentUser => {
      this.currentUser = currentUser;
      this.checkLoggedInUser();
    });
  }

}
