import React, { useState } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import { Checkbox, Divider, Button, Typography, Space, Row, Col , Input, Tag } from 'antd';

import { AllClaimTypes, AllProducts } from '../../models/StrikeTemplateTagsModel';

const Text = Typography.Text;
const CheckboxGroup = Checkbox.Group;

export const TagSearchCard = ({ intent, warning }) => {
    // reply with:
    // {
    //  products: [(string in AllProducts.key())]       
    //  claimTypes: [(string in AllClaimTypes.keys())]       
    //  clientIds: [(string)]
    //  is_test: Bool
    // }

    const allProductValues = Object.keys(AllProducts);
    const allProducts = allProductValues.map(
        (v) => {
            return {
                value: v,
                label: AllProducts[v].label,
            };
        }
    );

    const allClaimTypeValues = Object.keys(AllClaimTypes);
    const allClaimTypes = allClaimTypeValues.map(
        (v) => {
            return {
                value: v,
                label: AllClaimTypes[v].label,
            };
        }
    );

    const [products, setProducts] = useState(allProductValues);
    const [claimTypes, setClaimTypes] = useState(allClaimTypeValues);
    const [clientIds, setClientIds] = useState([]);
    const [is_test, setisTest] = useState(null);
    const [indeterminateProducts, setIndeterminateProducts] = useState(false);
    const [indeterminateClaimTypes, setIndeterminateClaimTypes] = useState(false);
    const [checkAllProducts, setCheckAllProducts] = useState(true);
    const [checkAllClaimTypes, setCheckAllClaimTypes] = useState(true);

    const onFilter = () => {
        const selections = {
            products: (checkAllProducts) ? null : products,
            claimTypes: (checkAllClaimTypes) ? null : claimTypes,
            clientIds: (clientIds && clientIds.length > 0) ? clientIds : [],
            is_test
        };
        console.log(selections);
        if (intent) intent(selections);
    };

    const onChangeProducts = list => {
        setProducts(list);
        setIndeterminateProducts(!!list.length && list.length < allProductValues.length);
        setCheckAllProducts(list.length === allProductValues.length);
    };

    const onCheckAllProductsChange = e => {
        setProducts(e.target.checked ? allProductValues : []);
        setIndeterminateProducts(false);
        setCheckAllProducts(e.target.checked);
    };

    const onChangeClaimTypes = list => {
        setClaimTypes(list);
        setIndeterminateClaimTypes(!!list.length && list.length < allClaimTypeValues.length);
        setCheckAllClaimTypes(list.length === allClaimTypeValues.length);
    };

    const onCheckAllClaimTypesChange = e => {
        setClaimTypes(e.target.checked ? allClaimTypeValues : []);
        setIndeterminateClaimTypes(false);
        setCheckAllClaimTypes(e.target.checked);
    };

    const onPressEnterClientId = e => {
        const clientId = e.target.value;
        console.log('onPressEnterClientId', clientId);

        if (clientId && clientId.length > 0) {
            const clientIdsNew = [...clientIds, clientId];
            console.log(clientIdsNew);
            setClientIds(clientIdsNew);
        }
    }


    const onDeleteClientId = i => {
        console.log('onDeleteClientId', clientIds, i);

        if (clientIds && clientIds.length > 0 && i < clientIds.length) {
            setClientIds(clientIds.filter((c, index) => index !== i));
        }
    }


    return (
        <>
            <Col align='start' span={24}>
                <Row >
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Text strong>{'Products: '}</Text>
                        <Checkbox indeterminate={indeterminateProducts} onChange={onCheckAllProductsChange} checked={checkAllProducts}>
                            All Products
                        </Checkbox>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <CheckboxGroup options={allProducts} value={products} onChange={onChangeProducts} />
                    </Col>
                </Row>
                <Row >
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Text strong>{'Claim Types: '}</Text>
                        <Checkbox indeterminate={indeterminateClaimTypes} onChange={onCheckAllClaimTypesChange} checked={checkAllClaimTypes}>
                            All Claim Types
                        </Checkbox>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <CheckboxGroup options={allClaimTypes} value={claimTypes} onChange={onChangeClaimTypes} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {
                            clientIds && clientIds.length > 0 &&
                            <Text strong>Client Ids:</Text>
                        }
                        {
                            clientIds && clientIds.length > 0 &&
                            clientIds.map((c, i) => {
                                return (
                                    <Space key={`sp-clientId-${c}-${i}`} direction='horizontal' >
                                        <Tag key={`txt-clientId-${c}-${i}`} >
                                            {c}
                                            <Button
                                                type='link'
                                                key={`link-clientId-${c}-${i}`}
                                                onClick={() => onDeleteClientId(i)}
                                                style={{ fontWeight: 'bold', color: 'red', padding: '0px', paddingLeft: '4px' }}
                                            >X</Button>
                                        </Tag>
                                    </Space>
                                );
                            })
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={16} lg={8} xl={8}>
                        <Space direction='horizontal'>
                            <Text strong>{'ClientId: '}</Text>
                            <Input
                                key={'new-clientId'}
                                onPressEnter={onPressEnterClientId}
                                allowClear
                            />
                        </Space>
                        <Space direction='vertical'>
                            <Button type='primary' style={{ backgroundColor: 'green', borderColor: 'green', marginTop: '5px' }} onClick={onFilter}>{'Apply Filter'}</Button>
                            {
                                warning && warning.raised && warning.text &&
                                <Text type='warning'>{warning.text}</Text>
                            }
                        </Space>
                    </Col>
                </Row>
                <Divider />
            </Col>
        </>
    );
};