import { useState, useEffect } from 'react';

import ClientRolesModel from './ClientRolesModel';

export function useClientRoles(clientId) {
    const [roles, setRoles] = useState(null);

    useEffect(() => {
        const onUpdate = async (data) => {
            setRoles(data);
        };

        const model = new ClientRolesModel({
            clientId,
            onUpdate,
        });

        model.fetch();

        return function cleanup() {
            model.stop();
        };

    },
        []
    );

    return roles;
}
