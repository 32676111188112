import { useState, useEffect } from 'react';
import firebase from 'firebase/app';

export function useBatchWithdrawalDetails(batchId, isAdmin) {
    const [batchWithdrawal, setBatchWithdrawal] = useState(null);

    useEffect(() => {
        // Subscribe to the withdrawals collection in Firestore
        const db = firebase.firestore();
        const ref = db.collection('batch-withdrawals').doc(batchId);

        const unsubscribe = ref.onSnapshot((snapshot) => {
            const data = snapshot.data();
            const id = snapshot.id;
            setBatchWithdrawal({ ...data, id });
        });

        // Clean up the subscription on component unmount
        return () => {
            unsubscribe();
        };
    },
        [batchId]
    );

    return batchWithdrawal;
}
