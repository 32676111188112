import React, { useState, useEffect, useContext } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { useParams } from 'react-router-dom';
import { PageHeader, Space } from 'antd';

import CenteredSpinner from '../../../components/CenteredSpinner';
import { useWithdrawalDetails } from '../../../models/useWithdrawalDetails';
import { AdminWithdrawalDetail } from '../../../components/admin/AdminWithdrawalDetail';
import StrikeWithdrawalModel from '../../../models/StrikeWithdrawalModel';

const ClientWithdrawalScreen = ({
  locStrings,
  isAdmin,
}) => {
  const { clientId, withdrawalId } = useParams();
  const withdrawal = useWithdrawalDetails(clientId, withdrawalId);
  const { displayName, status } = withdrawal || {};

  const markWithdrawalAsPaid = async (withdrawal) => {
    const withdrawalModel = new StrikeWithdrawalModel({ clientId, withdrawalId });
    const result = await withdrawalModel.setWithdrawalStatusToPaid();
  }

  return <>
      {
        <PageHeader
          ghost={false}
          title={displayName || 'Withdrawal Detail'}
          onBack={(() => window.history.back())}
        />
      } 
      <Space direction='vertical' style={{ width: '100%', textAlign: 'center' }}>
      { withdrawal &&

        <AdminWithdrawalDetail 
          loading={false}
          type="inner"
          withdrawal={withdrawal}
          clientId={clientId}
          withdrawalId={withdrawalId}
          locStrings={locStrings}
          isAdmin={isAdmin}
          onMarkPaid={isAdmin ? markWithdrawalAsPaid : null}
        />
      }
      </Space>
    </>

};


export default ClientWithdrawalScreen;

