import React, { useState } from 'react';
import {
  TeamOutlined,
  ArrowRightOutlined,
  UserAddOutlined,
  BankOutlined,
  EuroCircleOutlined,
  AreaChartOutlined,
  UnorderedListOutlined,
  DeleteRowOutlined,
  EditOutlined,
  CheckCircleFilled,
  ClockCircleTwoTone,
  UserOutlined,
} from '@ant-design/icons';
import firebase from 'firebase/app';
import { Avatar, Button, message, Modal, Table, Tag, Space, Tooltip, Typography } from 'antd';

import MoneyTitle from '../customer/MoneyTitle';
import { ActivityItemAdminButton } from '../admin/ActivityItemAdminButton';
import timeSince from '../../utils/TimeSince';
import ProductSwitcher, { capitalize, lookupStatusDetails } from '../../utils/ProductSwitcher';

import { TapDetailCard } from './TapsComponents';

import '../../App.scss';
import 'antd/dist/antd.css';

const { Text, Paragraph } = Typography;

const MoneyWithStatus = ({ currency, amount, filled, strikethrough, status, ...rest }) => {
  let filledTooltip = amount > 0 ? (strikethrough ? 'Refunded' : 'Available') : 'Transferred';
  let unfilledTooltip = 'Pending...';
  let statusText = capitalize(status || filledTooltip);

  return (
      <Space direction='horizontal' style={{color: (amount < 0 ? 'red' : null)}} wrap={false} size={4}>
          <MoneyTitle amount={amount} currency={currency} digits={2} strikethrough={strikethrough} {...rest} />
          {filled ? 
              <Tooltip title={statusText}>
                  <CheckCircleFilled style={{ color: (amount < 0 ? 'darkred' : 'green') }} /> 
              </Tooltip>
              :
              <Tooltip title={unfilledTooltip}>
                  <ClockCircleTwoTone />
              </Tooltip> 
          }
      </Space>
  );
};

const TitleTextLimited = ({ text, maxLength, ...rest }) => {
  const maxTextLength = maxLength || 22;
  const textLength = maxTextLength;
  const inputText = text;
  const shortText = inputText.slice(0,textLength + 3);
  const suffix = text.length > textLength ? '...' : '';
  const shortenedText = shortText.length > textLength ? shortText.slice(0,textLength) : shortText;
  const displayText = shortenedText + suffix;

  return (
    <Tooltip title={suffix.length > 0 ? text : null}>
      <Text strong {...rest}>{displayText}</Text>
    </Tooltip>
  );
};

const AvatarTitleText = ({ titleText, isTeam, icon, size, color, backgroundColor, ...rest }) => {
  const avatarIcon = icon || (isTeam ? <TeamOutlined /> : <UserOutlined />);
  const avatarSize = size || 'small';
  const avatarTitle = titleText || ' ';
  const avatarTitleColor = color || 'white';
  const avatarTitleBackgroundColor = backgroundColor || 'drakgreen';
  const avatarStyle = {
    color: avatarTitleColor,
    backgroundColor: avatarTitleBackgroundColor,
  };

  return (
    <Space direction={'horizontal'}>  
      <Avatar 
        icon={avatarIcon} 
        size={avatarSize} 
        style={avatarStyle}
      />
      <TitleTextLimited text={avatarTitle}/>
    </Space>
  );
};


export const RecentActivitiesTable = ({ condition, locStrings, client, accounts, recentActivities, showDates, isAdmin, ...rest }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTap, setModalTap] = useState(null);
  const [refundInProgress, setRefundInProgress] = useState(false);

  if (!condition()) return null;

  const dataSource = [];

  const productSwitcher = new ProductSwitcher({ locStrings });

  recentActivities.map(
    (recentActivity, i) => {
      const isTest = !!recentActivity.is_test;
      const uniqueId = `tap-${recentActivity.id || i}`;

      if (recentActivity.type === 'tap') {
        const activityAtDate = recentActivity.accountsProcessingStartedAt || recentActivity.updatedAt || recentActivity.createdAt || recentActivity.created;
        const activityDate = activityAtDate ? activityAtDate.toDate() : null;
        const timeSinceString = activityDate && timeSince(activityDate);
        const showDateString = activityDate && activityDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
        const showTimeString = activityDate && activityDate.toLocaleTimeString('en-GB');

        const dateTimeAndSince = {
          showDates,
          date: showDateString,
          time: showTimeString,
          since: timeSinceString,
      };
      
        const productMap = productSwitcher.getProductMap(recentActivity.product);
        let tapDescription = recentActivity.customerName || recentActivity.messageText || recentActivity.paymentInfo || recentActivity.teamName || recentActivity.displayName || "Your";
        if (tapDescription.length > 40) {
          tapDescription = tapDescription.substring(0, 40) + "..."
        }

        dataSource.push(
          {
            key: uniqueId,
            date: dateTimeAndSince,
            type: {
              typeName: 'tap',
              typeColor: productMap.color,
              typeAction: productMap.action,
              typeActionTeam: !!(recentActivity.teamName)
            },
            currency: recentActivity.paymentDetails.currency,
            value: {
              currency: recentActivity.paymentDetails.currency,
              amount: recentActivity.amount,
              fee: { currency: recentActivity.paymentDetails.currency, amount: recentActivity.fee }
            },
            balance: 0,
            tapData: { tap: recentActivity, index: i },
            description: `${tapDescription}`,
            status: {
              statusName: lookupStatusDetails(recentActivity.status).statusName,
              statusColor: lookupStatusDetails(recentActivity.status).statusColor,
              sourceName: recentActivity.source,
              sourceColor: recentActivity.source === 'nfc' ? 'blue' : 'black',
            },
            tapUid: recentActivity.tapUid,
            isTest
          }
        );
      }
      if (recentActivity.type === 'transfer') {
        const isString = recentActivity.createdAt ? recentActivity.createdAt.length > 0 : false;
        const activityDate = recentActivity.createdAt ? (isString ? Date.parse(recentActivity.createdAt) : recentActivity.createdAt.toDate()) : null;
        const timeSinceString = activityDate && timeSince(activityDate);
        const showDateString = activityDate && activityDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
        const showTimeString = activityDate && activityDate.toLocaleTimeString('en-GB');
        let typeName = recentActivity.subType;
        let typeAction = 'Sent';
        let typeActionTeam = false;

        if(recentActivity.transferType === 'team-to-member'){
            typeName = 'team-transfer-in';
            typeAction = 'Team Transfer';
            typeActionTeam = true;
        } else if(recentActivity.subType && ['transfer-in'].includes(recentActivity.subType)) {
          typeAction = 'Received';
        }

        const dateTimeAndSince = {
            showDates,
            date: showDateString,
            time: showTimeString,
            since: timeSinceString,
        };

        dataSource.push(
          {
            key: uniqueId,
            date: dateTimeAndSince,
            type: {
              typeName: typeName,
              typeColor: recentActivity.type && recentActivity.subType && ['team-transfer-in', 'transfer-in'].includes(recentActivity.subType) ? 'cyan' : '#b94e48',
              typeAction: typeAction,
              typeActionTeam: typeActionTeam,
            },
            currency: recentActivity.currency,
            value: {
              currency: recentActivity.currency,
              amount: +(recentActivity.payoutAmountCents)
            },
            balance: 0,
            description: `${recentActivity.teamName}`,
            status: {
              statusName: lookupStatusDetails(recentActivity.status).statusName,
              statusColor: lookupStatusDetails(recentActivity.status).statusColor
            },
            tapUid: recentActivity.tapUid,
            teamName: recentActivity.teamName,
            fromClientName: recentActivity.fromClient.displayName,
            toClientName: recentActivity.toClient.displayName,
            isTest
          }
        );
      }
      if (recentActivity.type === 'auto-debited-invoice') {
            const isString = recentActivity.createdAt ? recentActivity.createdAt.length > 0 : false;
            const activityDate = recentActivity.createdAt ? (isString ? Date.parse(recentActivity.createdAt) : recentActivity.createdAt.toDate()) : null;
            const timeSinceString = activityDate && timeSince(activityDate);
            const showDateString = activityDate && activityDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
            const showTimeString = activityDate && activityDate.toLocaleTimeString('en-GB');
            const dateTimeAndSince = {
                showDates,
                date: showDateString,
                time: showTimeString,
                since: timeSinceString,
            };

            dataSource.push(
                {
                    key: uniqueId,
                    date: dateTimeAndSince,
                    type: {
                        typeName: 'monthly invoice',
                        typeColor: 'gold',
                        typeAction: 'Fee',
                        typeActionTeam: false,          
                    },
                    currency: recentActivity.currency,
                    value: {
                        currency: recentActivity.currency,
                        amount: recentActivity.totalAmountChargedCents
                    },
                    description: 'Subscription',
                    status: {
                        statusName: lookupStatusDetails(recentActivity.status).statusName,
                        statusColor: lookupStatusDetails(recentActivity.status).statusColor
                    },
                    isTest
                }
            );
        }
    });

  const iconPadding = 4;

  const columns = [
    {
      title: 'Activity',
      dataIndex: 'type',
      key: 'type',
      render:
        (type, activity) => (
          <Space direction={'vertical'}>
            <Space align={'baseline'}>
              {(activity.type.typeName === 'tap') && activity.description &&
                <AvatarTitleText 
                  titleText={activity.description}
                  isTeam={type.typeActionTeam}
                  backgroundColor={type.typeColor}
                />
              }            

              {(activity.type.typeName === 'team-transfer-in') &&
                <AvatarTitleText 
                  titleText={activity.teamName}
                  isTeam={type.typeActionTeam}
                  backgroundColor={'darkgreen'}
                />
              }

              {(activity.type.typeName === 'transfer-in') &&
                <AvatarTitleText 
                  titleText={activity.fromClientName}
                  isTeam={type.typeActionTeam}
                  backgroundColor={'slateblue'}
                />
              }

              {(activity.type.typeName === 'transfer-out') &&
                <AvatarTitleText 
                  titleText={activity.toClientName}
                  isTeam={type.typeActionTeam}
                  backgroundColor={type.typeColor}
                />
              }

              {(activity.type.typeName === 'monthly invoice') &&
                <AvatarTitleText 
                  titleText={activity.description || 'Monthly Fee'}
                  isTeam={type.typeActionTeam}
                  icon={<BankOutlined />}
                  backgroundColor={type.typeColor}
                />
              }
            </Space>

            <Space align={'baseline'}>
              {(activity.date.showDates) 
                  ?
                  <Space wrap={true}> 
                      <Tag color='geekblue'>{activity.date.date}</Tag> 
                      <Tag color='geekblue'>{activity.date.time}</Tag> 
                  </Space>
                  :                   
                  <div style={{ textAlign: 'left'}}> 
                    <Tag >{activity.date.since}</Tag> 
                  </div>  
              }

              {(activity.type.typeName === 'tap') && 
                <Tag color={type.typeColor}>{type.typeAction}</Tag>
              }            

              {(activity.type.typeName === 'team-transfer-in') &&
                <Tag color={type.typeColor}>{type.typeAction}</Tag>
              }

              {(activity.type.typeName === 'transfer-in') &&
                <Tag color={type.typeColor}>{type.typeAction}</Tag>
              }

              {(activity.type.typeName === 'transfer-out') &&
                <Tag color={type.typeColor}>{type.typeAction}</Tag>
              }

              {(activity.type.typeName === 'monthly invoice') &&
                <Tag color={type.typeColor}>{type.typeAction}</Tag>
              }
            </Space>
          </Space>
        )
    },
    {
      title: 'Amount',
      dataIndex: 'value',
      align: 'right',
      render:
        (value, activity) => (
          <Space direction='vertical'>
            <Space style={{ textAlign: 'right'}} wrap={false}>
              <MoneyWithStatus amount={value.amount} currency={value.currency} 
                  filled={['refunded', 'successful', 'sent', 'available', 'charged'].includes(activity.status.statusName)}
                  strikethrough={(activity.status && (activity.status.statusName  === 'refunded'))}
                  status={activity.status.statusName}
              />
            </Space>
            <Space direction='horizontal' style={{ textAlign: 'right'}} wrap={false}>
               { activity.status && 
                  <Tag color={['pending', 'refunded'].includes(activity.status.statusName) ? 'blue' : 'darkgreen'}>
                    {capitalize(activity.status.statusName)}
                  </Tag>
               }
               { activity.isTest &&
                 <Tag color='red'>Test Only!</Tag>
               }
            </Space>
          </Space>
        )
    },
  ];

  if (isAdmin) {
    columns.push({
      title: '',
      dataIndex: 'value',
      align: 'right',
      render:
        (value, activity) => (
          <div>
              {(activity.type.typeName === 'tap') &&
              <Button onClick={() => displayTapModal(activity.tapData.tap)}>View details</Button>
              }
          </div>
        )
    });
  }

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  }

  const displayTapModal = (tap) => {
    setModalTap(tap);
    setModalVisible(true);
  }

  const refundTap = async () => {
    const confirmed = window.confirm("Are you sure you want to refund this transaction? This cannot be reversed.");
    if (!confirmed) return;

    const adminRefundTapFunction = firebase.functions().httpsCallable('tapService-tapHandler-adminRefundTap');

    const payloadData = {
      tagId: modalTap.tagId,
      tapId: modalTap.id,
    };

    let didSucceed = false;
    setRefundInProgress(true);
    try {
      await adminRefundTapFunction(payloadData);
      didSucceed = true;
    } catch (e) {
      message.error(e.toString());
    }
    setRefundInProgress(false);

    if (didSucceed) setModalVisible(false);
  }

  return condition() ? (
    <>
      <Table
        showHeader={false}
        style={{ width: '100%' }}
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 10 }}
        expandable={{
          expandedRowRender: record => <TapDetailCard tap={record.tapData.tap} index={record.tapData.index} />,
          rowExpandable: record => record.tapData
        }}
      />
      <Modal title="Tap details" visible={modalVisible} onOk={handleOk} onCancel={handleCancel}>
        {
          modalTap && (
            <div style={{ textAlign: 'left' }}>
              <p>Total paid: <MoneyTitle amount={modalTap.amount + modalTap.fee} currency={modalTap.currency} digits={2} /></p>
              <p>Fees: <MoneyTitle amount={modalTap.fee} currency={modalTap.currency} digits={2} /></p>
              <p>Tag ID: {modalTap.tagId}</p>
              <p>Tap ID: {modalTap.id}</p>
              <p>Fees paid by user: {modalTap.paymentDetails.shouldAddOnFees ? "Yes" : "No"}</p>
              <p>Status: <Tag color={lookupStatusDetails(modalTap.status).statusColor}>{lookupStatusDetails(modalTap.status).statusName}</Tag></p>

              {
                isAdmin && modalTap.status !== "refunded" && (
                  refundInProgress
                    ? <Button type="primary" danger onClick={refundTap} disabled>Refund</Button>
                    : <Button type="primary" danger onClick={refundTap}>Refund</Button>
                )
              }

            </div>
          )}
      </Modal>
    </>
  )
    : null;
    
};




