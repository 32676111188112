import firebase from 'firebase/app';
const isLocalHost = window.location.hostname === 'localhost';

export default class WebhooksModel {

  constructor({ viewModel, includeTestData, limit, onUpdateEvents, onUpdateConfigs, partnerClientId, isAdmin, configParams, eventParams }) {
    this.viewModel = viewModel;
    this.includeTestData = includeTestData || isLocalHost; // MARK: Show test and live accounts on localhost
    this.limit = limit || 1000;

    this.webhookConfigs = [];
    this.webhookEvents = [];

    this.webhookConfigsById = {};
    this.webhookEventsById = {};

    this.onUpdateEvents = onUpdateEvents;
    this.onUpdateConfigs = onUpdateConfigs;
    this.partnerClientId = partnerClientId;
    this.isAdmin = isAdmin || true;

    this.configParams = configParams;  // { enabled: boolean  }
    this.eventParams = eventParams;    // { webhookConfigId: string, types: [payment_success, tag_claimed] array of string, webhookStatus: one of [pending, success, failed] string  }
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    this.viewModel = null;
    if (this.configObserver) { this.configObserver = null; }
    if (this.eventObserver) { this.eventObserver = null; }

    this.webhookConfigs = [];
    this.webhookEvents = [];

    this.webhookConfigsById = {};
    this.webhookEventsById = {};
  }

  waitForWebhookConfigData = () => {
    if (this.configObserver) { this.configObserver = null; }

    const { enabled } = this.configParams || { };

    this.webhookConfigs = [];
    this.webhookConfigsById = {};

    const db = firebase.firestore();
    let query = db.collection('webhookConfig').orderBy('createdAt', 'desc');
    if (this.partnerClientId) query = query.where('partnerClientId', '==', this.partnerClientId);
    if (enabled) query = where.where('enabled', '==', enabled);
    query = query.limit(this.limit);

    this.configObserver = query.onSnapshot(querySnapshot => {
      if (querySnapshot.empty) {
        this.zeroConfigElementsFound();
      }

      querySnapshot.docChanges().reverse().forEach(
        change => {
          if (change.type === 'added' || change.type === 'modified') {
            const data = change.doc.data();
            const id = change.doc.id;
            const full_data = { ...data, id };
            this.webhookConfigsById[id] = full_data;
            this.updateConfigElementWithId(id, full_data);
          }

          if (change.type === 'removed') {
            const id = change.doc.id;
            this.webhookConfigsById[id] = null;
            this.deleteConfigElementWithId(id);
          }
        }
      );
    }, err => {
      console.log(`Encountered a webhook model error: ${err}`);
      this.zeroConfigElementsFound();
    });
  }

  waitForWebhookEventsData = () => {
    if (this.eventObserver) { this.eventObserver = null; }

    const { webhookConfigId, types, webhookStatus } = this.eventParams || { };

    this.webhookEvents = [];
    this.webhookEventsById = {};

    const db = firebase.firestore();
    let query = db.collection('webhookEvents').orderBy('createdAt', 'desc');
    if (this.partnerClientId) query = query.where('partnerClientId', '==', this.partnerClientId);
    if (webhookConfigId) query = query.where('webhookConfigId', '==', webhookConfigId);
    if (webhookStatus) query = query.where('webhookStatus', '==', webhookStatus);
    if (types) query = query.where('type', 'in', types); // only one 'in' allowed per query
    query = query.limit(this.limit);

    this.eventObserver = query.onSnapshot(querySnapshot => {
      if (querySnapshot.empty) {
        this.zeroEventElementsFound();
      }

      querySnapshot.docChanges().reverse().forEach(
        change => {
          if (change.type === 'added' || change.type === 'modified') {
            const data = change.doc.data();
            const id = change.doc.id;
            const full_data = { ...data, id };
            this.webhookEventsById[id] = full_data;
            this.updateEventElementWithId(id, full_data);
          }

          if (change.type === 'removed') {
            const id = change.doc.id;
            this.webhookEventsById[id] = null;
            this.deleteEventElementWithId(id);
          }
        }
      );
    }, err => {
      console.log(`Encountered a webhook model error: ${err}`);
      this.zeroEventElementsFound();
    });
  }

  async createOrUpdateWebhookConfig(id, values) {
    const db = firebase.firestore();

    values['events'] = [ 'payment_success', 'tag_claimed' ];
    values['enabled'] = values.enabled ? values.enabled : false;

    if (id) {
      values['updatedAt'] = firebase.firestore.Timestamp.now();
      await db.collection('webhookConfig').doc(id).set(values, { merge: true });
    } else {
      values['createdAt'] = firebase.firestore.Timestamp.now();
      values['updatedAt'] = firebase.firestore.Timestamp.now();
      await db.collection('webhookConfig').add(values);
    }
  }

  async updateConfigElementWithId(id, element) {
    let configs = this.webhookConfigs.filter(e => e.id !== id);
    configs = [element, ...configs];
    this.webhookConfigs = configs.sort((a, b) => b.createdAt - a.createdAt);
    if (this.viewModel) this.viewModel.updatedWebhookConfigsDataSource(this.webhookConfigs);
    if (this.onUpdateConfigs) this.onUpdateConfigs(this.webhookConfigs)
  }

  async updateEventElementWithId(id, element) {
    let events = this.webhookEvents.filter(e => e.id !== id);
    events = [element, ...events];
    this.webhookEvents = events.sort((a, b) => b.createdAt - a.createdAt);
    if (this.viewModel) this.viewModel.updatedWebhookEventsDataSource(this.webhookEvents);
    if (this.onUpdateEvents) this.onUpdateEvents(this.webhookEvents)
  }

  async deleteConfigElementWithId(id) {
    let configs = this.webhookConfigs.filter(e => e.id !== id);
    this.webhookConfigs = configs;
    if (this.viewModel) this.viewModel.updatedWebhookConfigsDataSource(this.webhookConfigs);
    if (this.onUpdateConfigs) this.onUpdateConfigs(this.webhookConfigs)
  }

  async deleteEventElementWithId(id) {
    let events = this.webhookEvents.filter(e => e.id !== id);
    this.webhookEvents = events;
    if (this.viewModel) this.viewModel.updatedWebhookEventsDataSource(this.webhookEvents);
    if (this.onUpdateEvents) this.onUpdateEvents(this.webhookEvents)
  }

  async zeroConfigElementsFound() {
    this.webhookConfigs = [];
    if (this.viewModel) this.viewModel.updatedWebhookConfigsDataSource(this.webhookConfigs);
    if (this.onUpdateConfigs) this.onUpdateConfigs(this.webhookConfigs)
  }

  async zeroEventElementsFound() {
    this.webhookEvents = [];
    if (this.viewModel) this.viewModel.updatedWebhookEventsDataSource(this.webhookEvents);
    if (this.onUpdateEvents) this.onUpdateEvents(this.webhookEvents)
  }
}
