import React, { useState } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import { DollarCircleFilled, EuroCircleFilled, PoundCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Avatar, List, Button, Popconfirm , Table, Tag, Radio, Space, Typography } from 'antd';
import moment from 'moment';

import {ccyIsoCodeToSymbolMap} from '../../utils/CurrencyUtils';
import { capitalize } from '../../utils/ProductSwitcher';

import StatusTableRowElement from './StatusTableRowelement';

const { Text } = Typography;

const ccy = ccyIsoCodeToSymbolMap;

const ccy_icons = {
  'EUR': <EuroCircleFilled style={{ fontSize: '30px', color: '#00c' }} />,
  'USD': <DollarCircleFilled style={{ fontSize: '30px', color: '#08c' }} />,
  'GBP': <PoundCircleFilled style={{ fontSize: '30px', color: '#70c' }} />,
};

function iconForCCY(ccy) {
  let icon = ccy_icons[ccy];
  return (icon) ? icon : <DollarCircleFilled style={{ fontSize: '30px', color: '#08c' }} />;
}

export const AdminWithdrawalsListItemMetaDescription = ({ withdrawal, locStrings }) => {
  let nameAndAddress = "";
  nameAndAddress = `${nameAndAddress} ${withdrawal.payout.holderName}`;

  if(withdrawal.payout.address) {
    nameAndAddress = `${nameAndAddress},  ${withdrawal.payout.address["address-line-one"]}`;
    const al2 = withdrawal.payout.address["address-line-two"] || "";
    if(al2.length > 0) nameAndAddress = `${nameAndAddress},  ${al2}`;
    nameAndAddress = `${nameAndAddress},  ${withdrawal.payout.address["town"]} `;
    nameAndAddress = `${nameAndAddress},  ${withdrawal.payout.address["county"]} `;
    nameAndAddress = `${nameAndAddress},  ${withdrawal.payout.address["country"]} `;
  }

  let bankAndCountry = "";
  bankAndCountry = `${bankAndCountry}${withdrawal.payout.bankName || ''}`;
  bankAndCountry = `${bankAndCountry} (${withdrawal.payout.country})`;

  let bankDetailsByCountryLine1 = "";
  let bankDetailsByCountryLine2 = "";

  if(withdrawal.payout.country === 'US') {
    bankDetailsByCountryLine1 = `Account Number:  ${withdrawal.payout.accountNumber} `;
    bankDetailsByCountryLine1 = `Routing Number:  ${withdrawal.payout.routingNumber} `;
  } else if(['UK', 'GB'].includes(withdrawal.payout.country)) { 
    if(withdrawal.payout.iban) {
      bankDetailsByCountryLine1 = `IBAN:  ${withdrawal.payout.iban} `;
      bankDetailsByCountryLine2 = ` `;
    } else {
      bankDetailsByCountryLine1 = `Sort Code:  ${withdrawal.payout.sortCode} `;
      bankDetailsByCountryLine2 = `Account Number:  ${withdrawal.payout.accountNumber} `;  
    }
  } else {
    bankDetailsByCountryLine1 = `IBAN:  ${withdrawal.payout.iban} `;
    bankDetailsByCountryLine1 = ` `;
  }

  return (
          <div>
              <p>{`${nameAndAddress}`}</p>
              <p>{`${bankAndCountry}`}</p>
              <p>{`${bankDetailsByCountryLine1}` }</p>
              <p>{`${bankDetailsByCountryLine2}` }</p>
          </div>
      );

};

export const AdminWithdrawalsListItemMetaTitle = ({ withdrawal, locStrings }) => {

  const amount = (withdrawal.payoutAmountCents / 100).toFixed(2);
  const displayAmount = `${ccy[withdrawal.currency]}${amount}`;

  return (
          <div>
              <p>{`${withdrawal.client.displayName} requested ${displayAmount}`}</p>
          </div>
      );

};


export const AdminWithdrawalsListItem = ({ withdrawal, locStrings, content: Content, onSelected }) => {
  const dateAt = withdrawal.updatedAt || withdrawal.createdAt;
  const updatedBy = withdrawal.updatedBy;
  const updatedByDisplay = updatedBy ? ` by ${updatedBy.displayName || updatedBy.email}` : "";
  const statusColor = {
    sent: 'success',
    failed: 'warning',
    paid: 'green',
    paused: 'warning',
    batched: 'geekblue',
    batch_requested: 'magenta',
  };
  const statusDisplay = {
    color: statusColor[withdrawal.status] || 'warning',
    text: (withdrawal.status === 'paid') ? `${capitalize(withdrawal.status)}${updatedByDisplay}` : `${capitalize(withdrawal.status)}`,
  };

  let actions = [];
  actions.push((<p>{`${moment(dateAt.toMillis()).fromNow()}`}</p>));
  actions.push(<Button type='primary' size='small' onClick={((e) => (onSelected(withdrawal)))}>Details</Button>);
  actions.push((<Tag color={statusDisplay.color} key={withdrawal.status}>{statusDisplay.text}</Tag>));

  return (
        <List.Item
          key={withdrawal.withdrawalId}
          actions={actions}
          extra={iconForCCY(withdrawal.currency)}
        >
          <List.Item.Meta
            avatar={<Avatar src={withdrawal.client.avatar} size='large'/>}
            title={<AdminWithdrawalsListItemMetaTitle withdrawal={withdrawal} locStrings={locStrings}/>}
            description={<AdminWithdrawalsListItemMetaDescription withdrawal={withdrawal} locStrings={locStrings}/>}
          />
          { Content && (<Content withdrawal={withdrawal} locStrings={locStrings} />)}
        </List.Item>
      );

};

export const AdminWithdrawalsListItemDetail = ({ withdrawal, locStrings }) => {

  const withdrawalId = withdrawal.withdrawalId ? (<Tag>{withdrawal.withdrawalId}</Tag>) : null;
  const payoutId = withdrawal.payoutId ? (<Tag>{withdrawal.payoutId}</Tag>) : null;

  return (
          <div>
              <p>{`Withdrawals are processed via Revolut for Business`}</p>
              <p>
                {withdrawalId}
                {payoutId}
              </p>
          </div>
      );

};


export const WithdrawalStatusSelector = ({ onSelected, defaultStatus }) => {
  const [status, setStatus] = useState(defaultStatus || 'all');
  const statusColor = {
    sent: 'success',
    failed: 'warning',
    paid: 'green',
    paused: 'warning',
    batched: 'geekblue',
    batch_requested: 'magenta',
    all: 'default',
  };

  const onSelectStatus = (status) => {
    setStatus(status);
    onSelected && onSelected(status === 'all' ? null : status);
  }

  return (
    <div style={{ textAlign: 'start', margin: '2px', padding: '2px' }}>
      <Space>
        <Radio.Group onChange={(e) => { onSelectStatus(e.target.value) }} value={status}>
          {Object.keys(statusColor).map((statusKey) => (
            <Radio.Button key={statusKey} value={statusKey}>
              <Space direction='horizontal'>
                <StatusTableRowElement status={statusKey} />
                <Text strong={statusKey === status}>{statusKey}</Text>
              </Space>
            </Radio.Button>
          ))
          }
        </Radio.Group>
      </Space>
    </div>
  );

}

export const AdminWithdrawalsList = ({ condition, onSelected, loading, locStrings, withdrawals, ...rest }) => {

  return (
    <List
      itemLayout="vertical"
      size='large'
      pagination={true}
      style={{ textAlign: 'start', margin: '2px', padding: '2px' }}
      loading={loading}
      dataSource={withdrawals}
      renderItem={withdrawal => (
        <AdminWithdrawalsListItem 
          withdrawal={withdrawal} 
          locStrings={locStrings} 
          content={AdminWithdrawalsListItemDetail} 
          onSelected={onSelected}
        />
      )}
    />
  );

};
