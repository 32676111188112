import { changeConfirmLocale } from 'antd/lib/modal/locale';
import firebase from 'firebase/app';


export default class StrikeClientOrdersModel {

  constructor({ clientId, skuId, orderId, callback }) {
    this.clientId = clientId;
    this.orders = [];
    this.client = null;
    this.ordersById = {};
    this.callback = callback;
    this.orderId = orderId;
    this.skuId = skuId;
  }

  getQueryForOrdersForClient(params) {
    const {
      clientId,
    } = params;

    const db = firebase.firestore();
    return db
            .collection('clients')
            .doc(clientId)
            .collection('orders')
            .orderBy('updatedAt', 'desc');
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if(this.observerOrders) {
      this.observerOrders();
      this.observerOrders = null;
    }
  }

  async fetchAllOrdersData()  {
    if (this.clientId === null) return;

    const clientId = this.clientId;

    this.stop();

    const db = firebase.firestore();
    this.orders = [];

    const query = this.getQueryForOrdersForClient({
      clientId,
    });

    this.observerOrders = query.onSnapshot(querySnapshot => {
      let index = querySnapshot.size;
      this.orders = [];
      if(index === 0) this.updateDataSourceForOrderId();

      querySnapshot.docChanges().forEach(
        change => {
          if(change.type === 'added' || change.type === 'modified') {
            const doc = change.doc.data();
            const id = change.doc.id;
            const full_doc = { ...doc, id };
            this.ordersById[id] = full_doc;
            this.updateDataSourceForOrderId(id);
          }

          if(change.type === 'removed' )  {
            const id = change.doc.id;
            this.ordersById[id] = null;
            this.updateDataSourceForOrderId();
          }
        }
      );
    }, err => {
      this.orders = [];
      this.ordersById = {};
      this.updateDataSourceForOrderId();
      console.log(`Tags query error: ${err}`);
    });


  }

  async updateDataSourceForOrderId(id) {
    this.orders = [];

    if(id) {
      const sku = await this.getSkuDataForOrderId(id)
      if(sku) {
        const full_doc = {  ...sku, ...this.ordersById[id], id };
        this.ordersById[id] = full_doc;
      }
    }

    for(let prop in this.ordersById) {
      this.orders.push(this.ordersById[prop]);  
    }

    if(this.callback) this.callback(this.orders);
  }


  async createOrderForSkuWithClientId({ trackingNumber }) {
    const db = firebase.firestore();
    const query =  db.collection('clients').doc(this.clientId).collection('orders');
    const result = await query.add({
      status: 'created',
      skus: [ this.skuId ],
      shipping: {
          address: null,
          name: null
      },
      tracking_number: trackingNumber || "",
      order_transtions : { // Dates
        created: firebase.firestore.Timestamp.now(),
        paid: null,
        ordered: null,
        fulfilled: null,
        canceled: null,
      },
      is_test : (window.location.hostname === 'localhost'),
      updatedAt: firebase.firestore.Timestamp.now(),
      createdAt: firebase.firestore.Timestamp.now(),
    });

    return result ? { orderId: result.id, ...result.data } : null;
  }

  async updateOrderShippingForClientId({ orderId, clientId, trackingNumber, shipping }) {
    const db = firebase.firestore();
    const query =  db.collection('clients').doc(clientId).collection('orders').doc(orderId);
    const result = await query.set({
      shipping,
      tracking_number: trackingNumber,
      updatedAt: firebase.firestore.Timestamp.now(),
    },
    {
      merge: true
    }
    );
    return result;
  }

  async confirmOrderForClientId({ orderId, clientId, trackingNumber }) {
    const db = firebase.firestore();
    const query =  db.collection('clients').doc(clientId).collection('orders').doc(orderId);
    const result = await query.set({
      status: 'ordered',
      order_transtions : { // Dates
        paid: firebase.firestore.Timestamp.now(),
        ordered: firebase.firestore.Timestamp.now(),
      },
      updatedAt: firebase.firestore.Timestamp.now(),
      tracking_number: trackingNumber,
    },
    {
      merge: true
    }
    );
    return result;
  }


}
