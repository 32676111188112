import firebase from 'firebase/app';
const isLocalHost = window.location.hostname === 'localhost';

export default class AdmindAllBalancesModel {

  constructor({ viewModel, includeTestData, limit }) {
    this.viewModel = viewModel;
    this.accounts = [];
    this.accountsById = {};
    this.includeTestData = includeTestData || isLocalHost; // MARK: Show test and live accounts on lcoalhost
    this.limit = limit || 100;
  }

  stop() {
    // stop all listeners...not sure they get dropped when object drops out of scope.
    this.viewModel = null;
    if (this.observer) {
      this.observer();
      this.observer = null;
    }

    this.accounts = [];
    this.accountsById = {};
  }

  waitForAccountsData = async () => {

    if (this.observer) {
      this.observer();
      this.observer = null;
    }

    this.accounts = [];
    this.accountsById = {};

    try {
      const db = firebase.firestore();
      let query = db.collectionGroup('accounts')
        .where('type', '==', 'available')
        .where('balance', '>', 0)
        .orderBy('balance', 'desc');
      query = query.limit(this.limit);
  
      const results = await query.get();
  
      if (results.empty) {
        this.zeroElementsFound();
      }
  
      results.docs.reverse().map(async (doc) => {
        const data = doc.data();
        const id = doc.id;
        const clientId = doc.ref.parent.parent.id;
        const client = await this.getClientData(clientId);
        const latestWithdrawal = await this.getLastWithdrawalData(clientId);
        const full_data = { ...data, id, clientId, ...client, latestWithdrawal };
        this.accountsById[id] = full_data;
        this.updateElementWithId(id, full_data);
      });
    } catch (err) {
      console.log(err);
    }
  }

  async getClientData(clientId) {
    const db = firebase.firestore();
    let clientDocSnapshot = await db.collection('clients').doc(clientId).get();
    if (clientDocSnapshot.exists) return clientDocSnapshot.data();
    return null;
  }

  async getLastWithdrawalData(clientId) {
    try {
      const db = firebase.firestore();
      let query = db.collection('clients').doc(clientId).collection('withdrawals').orderBy('createdAt', 'desc').limit(1);
      const latestWithdrawal = await query.get();
      if (latestWithdrawal.docs.length > 0)  return latestWithdrawal.docs[0].data();
      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  async updateElementWithId(id, element) {
    let accounts = this.accounts.filter(e => e.id !== id);
    accounts = [element, ...accounts];
    this.accounts = accounts;
    if (this.viewModel) this.viewModel.updatedAccountsDataSource(this.accounts, id);
  }

  async deleteElementWithId(id) {
    let accounts = this.accounts.filter(e => e.id !== id);
    this.accounts = accounts;
    if (this.viewModel) this.viewModel.updatedAccountsDataSource(this.accounts, id);
  }

  async zeroElementsFound() {
    this.accounts = [];
    if (this.viewModel) this.viewModel.updatedAccountsDataSource(this.accounts);
  }
}
