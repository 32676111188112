import React, { Component, useContext } from 'react';

import StrikeRouter from './Router';
import { UserProvider } from './providers/UserProvider';

class App extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
      <UserProvider>
        <div className="App">
          <StrikeRouter />
        </div>
      </UserProvider>
    );
  }
}
export default App;
