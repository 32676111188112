import firebase from 'firebase/app';
const isLocalHost = window.location.hostname === 'localhost';

export default class BatchWithdrawalsModel {

  constructor({ isAdmin, onUpdate, limit, includeTestData }) {
    this.isAdmin = isAdmin || false;
    this.includeTestData = includeTestData || isLocalHost; // MARK: Show test and live accounts on localhost
    this.limit = limit || 1000;
    this.batchWithdrawals = [];
    this.batchWithdrawalsById = {};
    this.onUpdate = onUpdate;
    this.batchWithdrawalsObserver = null;
  }

  batchWithdrawalsAddProgress = async ({ batchId, status, progress }, updateParams) => {
    this.db = firebase.firestore();

    const batchQuery = this.db.collection('batch-withdrawals').doc(batchId);
    const progressRef = batchQuery.collection('progress');

    await batchQuery.update({
      ...updateParams,
      status,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    await progressRef.add(progress);
  }

  batchWithdrawalsUpdateProgress = async ({ batchId, oldStatus, newStatus, info, message, type }, updateParams) => {
    const useNewStatus = newStatus || 'processing';
    let progress = {
      message: message || 'Updated status',
      event: `batch.${useNewStatus}`,
      info: info || '',
      oldStatus: oldStatus || 'processing',
      newStatus: useNewStatus,
      type: type || 'info',
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    await this.batchWithdrawalsAddProgress({ batchId, progress, status: useNewStatus }, updateParams);
  }

  getStatusSummary = (lines, currency) => {
    if(!lines) return {};

    const statuses = ['processing', 'upload_requested', 'uploaded', 'processed', 'failed', 'paid', 'cancelled', 'paused'];
    const summary = [];

    for(let i = 0; i < statuses.length; i++) {
      const status = statuses[i];
      const items = lines && lines.filter((item) => item.status === status);
      const amount = items && items.length > 0 ? items.reduce((acc, item) => acc + item.amount, 0) : 0;
      const count = items && items.length > 0 ? items.length : 0;
      const summaryItem = { 
        amount, 
        count, 
        status,
        currency,
      };
      if(amount || count) summary.push(summaryItem); 
    }

    return summary;
  }


  calculateStatusSummary = async (batchId, currency) => {
    const db = firebase.firestore();
    let linesSnapshot = null;
    
    const linesRef = db.collection('batch-withdrawals').doc(batchId).collection('lines');
    try {
      linesSnapshot = await linesRef.get();
    } catch (err) {
      console.log(`Error getting lines for batch ${batchId}: ${err}`);
      return {};
    }

    let lines = [];

    linesSnapshot.forEach((doc) => {
      const line = doc.data();
      const id = doc.id;
      lines.push({ ...line, id });
    });

    return this.getStatusSummary(lines, currency);
  }

  createBatchWithdrawal = async (batchWithdrawal) => {
    this.db = firebase.firestore();

    const batchWithdrawalRef = this.db.collection('batch-withdrawals').doc();
    await batchWithdrawalRef.set(batchWithdrawal);
    return batchWithdrawalRef.id;
  }

  updateBatchWithdrawal = async (batchId, batchWithdrawal) => {
    this.db = firebase.firestore();

    await this.db.collection('batch-withdrawals').doc(batchId).update({ ...batchWithdrawal });
  }

  executeBatchRequestedForUpload = async (batchId, lines) => {
    this.db = firebase.firestore();

    const batchWithdrawalRef = this.db.collection('batch-withdrawals').doc(batchId);
    const linesRef = batchWithdrawalRef.collection('lines');
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const { status } = line || {};

      if(['failed', 'cancelled', 'paid', 'paused', 'upload_requested'].includes(status)) continue;

      try {
        await linesRef.doc(line.id).update({
          status: 'upload_requested',
          orderIndex: i, // this is to process in the correct order
          lineId: line.id,
          uploadedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } catch (err) {
        console.log(`Error updating line ${i} for batch ${batchId}: ${err}`);
      }
    }

    await this.batchWithdrawalsUpdateProgress(
      {
        batchId,
        newStatus: 'upload_requested',
        type: 'info',
      },
      {
        status: 'upload_requested',
      }
    );
  }

  executeBatchMarkAsPaid = async (batchId, lines) => {
    this.db = firebase.firestore();

    const batchWithdrawalRef = this.db.collection('batch-withdrawals').doc(batchId);
    const linesRef = batchWithdrawalRef.collection('lines');
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const { clientId, withdrawalId, status } = line || {};

      if(['failed', 'cancelled', 'paid', 'paused'].includes(status)) continue;

      try {
        await linesRef.doc(line.id).update({
          status: 'paid',
          lineId: line.id,
          paidAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        const withdrawRef = this.db.collection('clients').doc(clientId).collection('withdrawals').doc(withdrawalId);
        await withdrawRef.update({
          status: 'paid',
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          paidAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } catch (err) {
        console.log(`Error updating line ${i} for batch ${batchId}: ${err}`);
      }
    }

    await this.batchWithdrawalsUpdateProgress(
      {
        batchId,
        newStatus: 'paid',
        type: 'info',
      },
      {
        status: 'paid',
      }
    );
  }

  executeBatchLinesPause = async (batchId, lines) => {
    this.db = firebase.firestore();
    const status = 'paused';

    const batchWithdrawalRef = this.db.collection('batch-withdrawals').doc(batchId);
    const linesRef = batchWithdrawalRef.collection('lines');
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const { clientId, withdrawalId } = line || {};
      try {
        await linesRef.doc(line.id).update({
          status,
          pausedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        const withdrawRef = this.db.collection('clients').doc(clientId).collection('withdrawals').doc(withdrawalId);
        await withdrawRef.update({
          status,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          pausedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } catch (err) {
        console.log(`Error updating line ${i} for batch ${batchId}: ${err}`);
      }
    }

    await this.batchWithdrawalsUpdateProgress(
      {
        batchId,
        newStatus: 'processed',
        type: 'info',
      },
      {
        status,
      }
    );
  }

  executeBatchLinesUnpause = async (batchId, lines) => {
    this.db = firebase.firestore();

    const batchWithdrawalRef = this.db.collection('batch-withdrawals').doc(batchId);
    const linesRef = batchWithdrawalRef.collection('lines');
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const { clientId, withdrawalId, status } = line || {};

      if(status !== 'paused') continue;

      try {
        await linesRef.doc(line.id).update({
          status: 'processed',
          unpausedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        const withdrawRef = this.db.collection('clients').doc(clientId).collection('withdrawals').doc(withdrawalId);
        await withdrawRef.update({
          status: 'batched',
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          unpausedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } catch (err) {
        console.log(`Error updating line ${i} for batch ${batchId}: ${err}`);
      }
    }

    await this.batchWithdrawalsUpdateProgress(
      {
        batchId,
        newStatus: 'processed',
        type: 'info',
      },
      {
        status: 'processed',
      }
    );
  }

  executeBatchCancelAllLines = async (batchId) => {
    const db = firebase.firestore();
    let linesSnapshot = null;
    
    const linesRef = db.collection('batch-withdrawals').doc(batchId).collection('lines');
    try {
      linesSnapshot = await linesRef.get();
    } catch (err) {
      console.log(`Error getting lines for batch ${batchId}: ${err}`);
      return {};
    }

    const linesDocs = linesSnapshot.docs;

    for (let i = 0; i < linesDocs.length; i++) {
      const line = linesDocs[i].data();
      const id = linesDocs[i].id;
      const { clientId, withdrawalId } = line || {};
      try {
        await linesRef.doc(id).update({
          status: 'cancelled',
          lineId: id,
          cancelledAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        const withdrawRef = this.db.collection('clients').doc(clientId).collection('withdrawals').doc(withdrawalId);
        await withdrawRef.update({
          status: 'batch_requested', // return to batch_requested, sends the withdrawal email again
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          cancelledAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } catch (err) {
        console.log(`Error updating line ${id} for batch ${batchId}: ${err}`);
      }
    }

    await this.batchWithdrawalsUpdateProgress(
      {
        batchId,
        newStatus: 'cancelled',
        type: 'warn',
      },
      {
        status: 'cancelled',
      }
    );
  }

  

  start() {
    this.startBatchWithdrawals();
  }

  stop() {
    this.stopBatchWithdrawals();
  }

  stopBatchWithdrawals = () => {
    if (this.batchWithdrawalsObserver) {
      this.batchWithdrawalsObserver();
      this.batchWithdrawalsObserver = null;
    }
    this.batchWithdrawals = [];
    this.batchWithdrawalsById = {};
  }

  startBatchWithdrawals = () => {
    this.db = firebase.firestore();

    this.stopBatchWithdrawals();

    let query = this.db
      .collection('batch-withdrawals')
      .orderBy('createdAt', 'desc');
    query = query.limit(this.limit);

    this.batchWithdrawalsObserver = query.onSnapshot(querySnapshot => {
      if (querySnapshot.empty) {
        this.zeroElementsFound();
      }

      querySnapshot.docChanges().reverse().forEach(
        change => {
          if (change.type === 'added' || change.type === 'modified') {
            const data = change.doc.data();
            const id = change.doc.id;
            const full_data = { ...data, id };
            this.batchWithdrawalsById[id] = full_data;
            this.updateElementWithId(id, full_data);
          }

          if (change.type === 'removed') {
            const id = change.doc.id;
            const element = this.batchWithdrawalsById[id];
            this.batchWithdrawalsById[id] = null;
            this.deleteElementWithId(id, element);
          }
        }
      );
    }, err => {
      console.log(`Encountered a 'batch-withdrawals' model error: ${err}`);
      this.zeroElementsFound();
    });
  }

  updateElementWithId(id, element) {
    let elements = this.batchWithdrawals.filter(e => e.id !== id);
    elements = [element, ...elements];
    this.batchWithdrawals = elements.sort((a, b) => b.createdAt - a.createdAt);
    if (this.onUpdate) this.onUpdate(this.batchWithdrawals)
  }

  deleteElementWithId(id, element) {
    let elements = this.batchWithdrawals.filter(e => e.id !== id);
    this.batchWithdrawals = elements;
    if (this.onUpdate) this.onUpdate(this.batchWithdrawals)
  }

  zeroElementsFound() {
    this.batchWithdrawals = [];
    if (this.onUpdate) this.onUpdate(this.batchWithdrawals)
  }
}
