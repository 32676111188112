import React from 'react';
import '../../App.scss';

import img_elPoweredbyFull from '../../images/strikepay_Mark_poweredby_RGB_Black.png';


export default function PoweredByStrike(props) {
  const style_elCashlessTippingText = {
    fontSize: 8.5,
    color: 'rgba(0, 0, 0, 0.8500)',
    textAlign: 'center',
  };
  
  const style_elStrikeLogoImage = {
    width: '75%',
    maxWidth: '200px',
    height: 'auto',
    cursor: 'pointer',
    pointerEvents: 'auto',
  };

  return (
    <div className="flowRow flowRow_FooterCard_elCashlessTippingText_46302">
        <div className="poweredBy elCashlessTippingText">
            <div className="font-tahomaBold" style={style_elCashlessTippingText}>
              <a href="https://www.strikepay.co" target="_blank" rel="noopener noreferrer" style={{ color: '#000' }}>
                <div>
                    <img className="elStrikeFullLogoImage" style={style_elStrikeLogoImage} src={img_elPoweredbyFull} alt="Strikepay" />
                </div>
              </a>
            </div>
        </div>
    </div>
  );
}