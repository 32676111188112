import { useState, useEffect } from 'react';

import BatchWithdrawalsModel from './BatchWithdrawalsModel';
import CollectionSubCollectionModel from './CollectionSubCollectionModel';


const topCollection = 'batch-withdrawals';
const subCollection = 'lines';
const isAscending = false;

export function useBatchWithdrawalLinesSummary(batchId, currency, isAdmin) {
    const [summary, setSummary] = useState(null);

    useEffect(() => {

        function calculateStatusSummary(lines) {
            const batchModel = new BatchWithdrawalsModel({
                isAdmin: isAdmin || true,
                limit: 0,
            });

            const summaryData = batchModel.getStatusSummary(lines, currency);
            setSummary(summaryData);
        }

        const model = new CollectionSubCollectionModel({
            isAdmin,
            onUpdate: calculateStatusSummary,
            limit: 0,
            topCollection,
            subCollection,
            id: batchId,
            isAscending,
        });

        if(batchId) model.start();

        return function cleanup() {
            model.stop();
        };
    },
        [batchId]
    );

    return summary;
}
