import firebase from 'firebase/app';
const isLocalHost = window.location.hostname === 'localhost';

const allStatuses = [
  'sent', 
  'paid', 
  'batch_requested', 
  'batched', 
  'api_requested', 
  'refunded', 
  'paused', 
  'cancelled',
  'refund_requested',
  'refund_failed',
];

export default class StrikeWithdrawalsGroupModel {

  constructor({ viewModel, includeTestData, limit, statuses }) {
    this.viewModel = viewModel;
    this.withdrawals = [];
    this.withdrawalsById = {};
    this.includeTestData = includeTestData || isLocalHost; // MARK: Show test and live accounts on lcoalhost
    this.limit = limit || 600;
    this.statuses = statuses || allStatuses;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    this.viewModel = null;
    if (this.observer) {
      this.observer();
      this.observer = null;
    }
  }

  filterByStatus(status) {
    this.statuses = status ? [status] : allStatuses;
    if (this.observer) {
      this.observer();
      this.observer = null;
    }
    this.withdrawals = [];
    this.withdrawalsById = {};
    this.waitForWithdrawlsData();
  }

  waitForWithdrawlsData = () => {

    if (this.observer) {
      this.observer();
      this.observer = null;
    }

    const db = firebase.firestore();
    const query = db.collectionGroup('withdrawals')
                    .where('status', 'in', this.statuses)
                    .orderBy('createdAt', 'desc')
                    .limit(this.limit);

    this.observer = query.onSnapshot(querySnapshot => {
      if(querySnapshot.empty && this.viewModel) {
        this.withdrawals = [];
        this.viewModel.updatedWithdrawalsDataSource(this.withdrawals);
      }

      let changes = querySnapshot.docChanges().reverse();
      changes.forEach(
        change => {
          if (change.type === 'added' || change.type === 'modified') {
            const data = change.doc.data();
            const id = change.doc.id;
            const full_data = { ...data, id };
            this.withdrawalsById[id] = full_data;
            const version = full_data.version || '1';
            (version === '2') ? this.updateElementWithId(id, full_data) : this.updateWithNone();
          }

          if (change.type === 'removed') {
            const id = change.doc.id;
            this.withdrawalsById[id] = null;
            this.deleteElementWithId(id);
          }
        }
      );
    }, err => {
      console.log(`Encountered a withdrawal group model error: ${err}`);
      this.withdrawals = [];
      if(this.viewModel) this.viewModel.updatedWithdrawalsDataSource(this.withdrawals);
    });

  }

  async updateElementWithId(id, element) {
    let withdrawals = this.withdrawals.filter(e => e.id !== id);
    withdrawals = [ element, ...withdrawals ];
    this.withdrawals = withdrawals;
    if(this.viewModel) this.viewModel.updatedWithdrawalsDataSource(this.withdrawals, id);
  }

  async deleteElementWithId(id) {
    let withdrawals = this.withdrawals.filter(e => e.id !== id);
    this.withdrawals = withdrawals;
    if(this.viewModel) this.viewModel.updatedWithdrawalsDataSource(this.withdrawals, id);
  }

  async updateWithNone() {
    if(this.viewModel) this.viewModel.updatedWithdrawalsDataSource(this.withdrawals, null);
  }

}
