import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Col, message, PageHeader, Row, Tag, Tooltip, Divider } from 'antd';
import  { QuestionCircleOutlined } from '@ant-design/icons';

import Spinner from '../../../components/Spinner';
import StrikeTeamDetailsModel from '../../../models/StrikeTeamDetailsModel';
import { EditTeamMemberForm } from '../../../components/client/EditTeamForm';
import StrikeTeamTransfersModel from '../../../models/StrikeTeamTransfersModel';
import { TeamMemberAvatarForRole } from '../../../components/client/TeamDashboardComponents';
import { TeamSetOwnerButton } from '../../../components/client/TeamSetOwnerButton';
import { TeamProfileRecentActivityList } from '../../../components/client/TeamProfileRecentActivityList';

let headerImgLayout = {
    xs: {
        offset: 1
    },
    sm: {
        offset: 4
    },
    md: {
        offset: 5
    },
    lg: {
        offset: 8
    },
    xl: {
        offset: 9
    },
    xxl: {
        offset: 10
    }
}

let headerNameLayout = {
    span: 9,
    xs: {
        offset: 0
    },
    sm: {
        offset: 4
    },
    md: {
        offset: 3
    },
    lg: {
        offset: 7
    },
    xl: {
        offset: 7
    },
    xxl: {
        offset: 7
    }
}


export default class TeamMemberDetailsScreen extends Component {

    // Properties used by this component:

    constructor(props) {
        super(props);

        const teamId = this.props.match.params.teamId;
        const clientId = this.props.match.params.clientId;
        let memberClientId = this.props.match.params.memberId;
        const isAdmin = !!props.isAdmin;
        this.locStrings = props.locStrings;
        this.isAdmin = isAdmin;

        this.teamDetailsModel = new StrikeTeamDetailsModel({viewModel: this, teamId, memberClientId: memberClientId});

        this.state = {
            teamId: teamId,
            clientId: clientId,
            memberClientId: memberClientId,
            teamMember: null,
            teamDetails: null,
            teamMembersList: null,
            memberWithdrawals: [],
            loading: true
        };

        this.teamWithdrawalsModel = new StrikeTeamTransfersModel({
            viewModel: this,
            callback: this.setWithdrawalsDataOnPage,
            memberClientId: memberClientId
        });
    }

    componentDidMount() {
        this.teamDetailsModel.waitForTeamDetails({fetchTeamClient: true});
    }

    componentWillUnmount() {
        if (this.teamDetailsModel) this.teamDetailsModel.stop();
        if (this.teamWithdrawalsModel) this.teamWithdrawalsModel.stop();
    }

    componentDidUpdate() {
    }

    isLoading = () => {
        return this.state.loading || false;
    }

    setTeamDetailsOnPage = (teamDetails, teamMembersList) => {
        let filteredTeamMember = teamMembersList.filter(member => member.clientId === this.state.memberClientId);
        if (filteredTeamMember.length !== 1) {
            return;
        }

        this.setState({
            teamDetails,
            teamMembersList,
            teamMember: { memberClientId: this.state.memberClientId, ...filteredTeamMember[0] },
            loading: false
        });
    }

    setTeamClientDetailsOnPage = (teamClient) => {
        this.setState({
            teamClient
        });
    }

    onClickSave = (data) => {
        this.internalSave(data);
    }

    internalSave = async (data) => {
        try {
            await this.teamDetailsModel.updateExistingTeamMember(data);
            message.success('Changes saved successfully');
        } catch (error) {
            message.error('Changes could not be saved');
        }
    }

    render() {
        const style_memberAvatar = {
            display: ((this.state.teamMember && this.state.teamMember.avatar) ? 'inline-block' : 'none'),
            pointerEvents: 'auto',
            width: '200px',
            marginTop: '1px',
            borderRadius: '2px'
        };

        if (!this.state.loading && this.state.teamMember) {
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`${this.state.teamMember.displayName}`}
                        onBack={(() => window.history.back())}
                    />
                    <Row> <Col {...headerImgLayout} style={{
                        fontSize: '22px',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        marginBottom: '2px'
                    }}><img style={style_memberAvatar} src={(this.state.teamMember.avatar)}
                            alt="Member Image"/></Col></Row>
                    <Row> 
                        <Col {...headerNameLayout} style={{
                                fontSize: '22px',
                                fontWeight: 'bold',
                                display: 'inline-block',
                                textAlign: 'center',
                                marginBottom: '15px'
                            }}>{this.state.teamMember.displayName}
                        &nbsp;&nbsp;
                        {(this.state.teamDetails && this.state.teamMember && (this.state.teamDetails.teamOwnerClientId === this.state.teamMember.clientId) && (
                            <Tag color={"cyan"} style={{color: 'black', fontSize: '18px', fontWeight: '400'}}>
                                <Tooltip title="Owner has all permissions preset and non-modifiable" style={{textAlign: 'center'}}>owner &nbsp;<QuestionCircleOutlined  style={{fontSize: '14px'}}/>
                                </Tooltip></Tag>))
                        }
                        {(this.state.teamDetails && this.state.teamMember && (this.state.teamDetails.teamOwnerClientId !== this.state.teamMember.clientId) && (
                            <TeamSetOwnerButton 
                                team={this.state.teamDetails}
                                member={this.state.teamMember}
                            />
                        ))
                        }

                    </Col>
                    </Row>

                    <EditTeamMemberForm
                        team={this.state.teamDetails}
                        teamMember={this.state.teamMember}
                        callback={this.onClickSave}
                        edit={true}
                    />

                    {
                        <div style={{ textAlign: 'start', padding: '8px 0px 4px 8px' }}>
                            <Divider orientation='left'>Recent Activity</Divider>
                            <TeamProfileRecentActivityList
                                teamId={this.state.teamId}
                                clientId={this.state.teamMember.clientId}
                                displayPermissions={this.state.teamMember.displayPermissions}
                                locStrings={this.locStrings}
                                team={this.state.teamDetails}
                                isAdmin={this.isAdmin}
                            />
                        </div>
                    }
                </div>
            );
        } else {
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`Team Details`}
                        onBack={(() => window.history.back())}
                    />
                    <Spinner condition={() => { return this.isLoading() }} />
                </div>
            );
        }
    }
}

