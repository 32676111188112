import React, { useState, useEffect } from 'react';
import { Modal , message } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment';
import firebase from 'firebase';

import TrackerService from '../../utils/TrackerService';

export const TeamMemberInviteModal = ({ visible, teamId, teamName, clientId, clientName, teamMemberInviteUuid, teamMemberInviteCreatedBy, teamMemberInviteCreatedAt, teamMemberInviteExpiresAt, onClickInviteModalOk, onClickModalCancel }) => {

    const isLocalHost = window.location.hostname === 'localhost';
    const urlBase =  isLocalHost ? 'localhost:3000' : `https://app.strikepay.co`;

    const instructionBefore = `Copy the link below and send it to anyone
        (or any group) you want to invite - it will expire in 7 days
        and can be used to invite as many people as you'd like`;

    const instructionAfter = `The link has been copied to your clipboard.
        Open WhatsApp, SMS or Email app, paste the copied link.
        In WhatsApp send this link to your team group chats.
        The same link works for everyone.`;

    const [messageText, setMessageText] = useState(instructionBefore);
    const [isLinkCopied, setIsLinkCopied] = useState(false);
    const [inviteUuid, setInviteUuid] = useState(teamMemberInviteUuid);
    let inviteUrl = `${urlBase}/client/manage/account/teamInvites/${teamId}/${inviteUuid}/`;
    const [inviteCreatedAt, setInviteCreatedAt] = useState(teamMemberInviteCreatedAt);
    const [inviteExpiresAt, setInviteExpiresAt] = useState(teamMemberInviteExpiresAt);
    const [inviteCreatedBy, setInviteCreatedBy] = useState(teamMemberInviteCreatedBy);

    useEffect(() => {
      const nowMoment = moment(new Date());
      const inviteExpiresAtMoment = inviteExpiresAt ? moment(inviteExpiresAt.toDate()) : null;

      if (inviteUuid === null || (inviteUuid !== null && inviteExpiresAtMoment !== null && nowMoment.isAfter(inviteExpiresAtMoment))) {
        setInviteUuid(generateInviteUuid());
        inviteUrl = `https://app.strikepay.co/client/manage/account/teamInvites/${teamId}/${inviteUuid}/`;
        setInviteCreatedAt(nowMoment);
        setInviteExpiresAt(moment(nowMoment.toDate()).add(7, 'days'));
        setInviteCreatedBy(clientId);
      }
    }, [visible]);

    const generateInviteUuid = () => {
      const {v4 : uuidv4} = require('uuid');
      const newUuid = uuidv4().substring(1,7).toString();
      return newUuid;
    };

    const isTest = isLocalHost;

    const tracker = new TrackerService({
      isTest,
    });

    const onCopied = async () => {
      setMessageText(instructionAfter);
      try {
        await updateInviteDetails();
      } catch (err) {
        message.error('Link copy failed', 5);
        return;
      }

      setIsLinkCopied(true);
      message.success('Link copied', 5);

      const inviteCreatedAtString = inviteCreatedAt ? inviteCreatedAt.toDate().toISOString() : null;
      const inviteExpiresAtString = inviteExpiresAt ? inviteExpiresAt.toDate().toISOString() : null; 

      const data = {
        teamId,
        teamName,
        clientId,
        clientName,
        inviteUuid,
        inviteCreatedAt: inviteCreatedAtString,
        inviteExpiresAt: inviteExpiresAtString,
        inviteCreatedBy,
      }

      tracker.mixpanelTrack('invite_team_member', data);
      if (!isTest) {
        tracker.googleTrack('invite_team_member', data);
      }
    };

    const updateInviteDetails = async () => {
      const db = firebase.firestore();
      await db.collection('teams').doc(teamId).update({
        inviteUuid,
        inviteCreatedAt : inviteCreatedAt.toDate(),
        inviteExpiresAt: inviteExpiresAt.toDate(),
        inviteCreatedBy,
      });
    };

    return (
        <Modal title="Invite link"
            visible={visible === null ? true : visible}
            onOk={() => onClickInviteModalOk()}
            onCancel={() => onClickModalCancel()}
            footer={null}
            bodyStyle={{ minHeight: '200px' }}
        >
            <div style={{ textAlign: "center" }}>
                <p className="promoCopy" style={{ textAlign: "left" }}>{messageText}</p>

                <div className="copyableBlock">
                    <span>Tap to copy link</span>

                    <CopyToClipboard
                        text= {inviteUrl}
                        onCopy={() => onCopied()}>
                        <button className="promoCopyable">{isLinkCopied ? 'Copy Again' : 'Copy Invite Link'}</button>
                    </CopyToClipboard>
                </div>
            </div>
        </Modal>
    );
};
