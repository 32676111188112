import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { PageHeader} from 'antd';

import Spinner from '../../../components/Spinner';
import StrikeTeamDetailsModel from '../../../models/StrikeTeamDetailsModel';
import { TapsTable } from '../../../components/client/TapsComponents';
import StrikeTeamMembersTapsModel from '../../../models/StrikeTeamMembersTapsModel';
 

export default class TeamTipScreen extends Component {
    constructor(props) {
        super(props);

        const teamId = this.props.match.params.teamId;
        const clientId = this.props.match.params.clientId;

        this.model = new StrikeTeamDetailsModel({viewModel: this, teamId});
        this.teamMembersTapsModel = new StrikeTeamMembersTapsModel({viewModel: this, teamId});

        this.state = {
            teamId: teamId,
            clientId: clientId,
            memberClientId: clientId,
            teamDetails: null,
            teamMembersTapsList: null,
            loading: true,
        };
    }

    async componentDidMount() {
        this.model.waitForTeamDetails({fetchTeamClient: false});
        this.teamMembersTapsModel.fetchAllTeamMembersTaps();
    }

    clientNotFound = () => {
        this.setState({
            clientNotFound: true
        });
    }

    componentWillUnmount() {
        if (this.model) this.model.stop();
    }

    isLoading = () => {
        return this.state.loading || false;
    }

    setTeamDetailsOnPage = (teamDetails, teamMembersList) => {
        let filteredTeamMember = teamMembersList.filter(member => member.clientId === this.state.memberClientId);
        this.setState({
            teamDetails,
            teamMembersList,
            teamMemberLoggedIn: filteredTeamMember[0],
            isLoggedInMemberAdmin: filteredTeamMember[0] ? (filteredTeamMember[0].roles[0] === 'admin' || filteredTeamMember[0].permissions.isTeamAdmin): [],
            loading: false
        });
    }

    setTeamMembersTapsOnPage = (teamMembersTapsList) => {
        this.setState({
            teamMembersTapsList
        });
    }

    render() {
        if (!this.state.loading && this.state.teamDetails) {
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`${this.state.teamDetails.teamName}`}
                        onBack={(() => window.history.back())}
                    />

                    <h2 style={{marginTop: '15px'}}>Member Tips</h2>
                    <TapsTable
                        taps={this.state.teamMembersTapsList}
                        condition={() => {
                            return this.state.teamMembersTapsList
                        }}
                        showName={true}
                        locStrings={this.props.locStrings}
                        client={this.state.client}
                    />
                </div>
            );
        } else {
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`Member Tips`}
                        style={{fontSize: '42px', fontWeight: 'bold'}}
                        onBack={(() => window.history.back())}
                    />
                    <Spinner condition={() => { return this.isLoading() }} />
                </div>
            );
        }
    }
}

