import React from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import { Tabs, Table } from 'antd';

import MoneyTitle from '../customer/MoneyTitle';

const isLocalHost = window.location.hostname === 'localhost';
const URLPath = isLocalHost ? `http://${window.location.hostname}:3000` : "https://app.strikepay.co";
const moment = require('moment');

const { TabPane } = Tabs;

export const AdminBalancesList = ({ donationAccounts, paymentAccounts, tippingAccounts, ...rest }) => {
  const columns = [
    {
      title: 'Client Name',
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: 'Available Balance',
      key: 'balance',
      render: (record) => (
        <MoneyTitle amount={record.balance} currency={record.currency} digits={2} />
      ),
    },
    {
      title: 'Last Withdrawal',
      key: 'displayName',
      render: (record) => (
        <p>{record.latestWithdrawal ? `${moment(record.latestWithdrawal.createdAt.toDate()).fromNow()}` : 'Never'}</p>
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <a href={`${URLPath}/admin/clients/${record.clientId}`}>See Dashboard</a>
      ),
    },
  ];
  
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Tipping" key="1">
        <Table
          columns={columns}
          dataSource={tippingAccounts}
        />
      </TabPane>
      <TabPane tab="Payments" key="2">
        <Table
          columns={columns}
          dataSource={paymentAccounts}
        />
      </TabPane>
      <TabPane tab="Donations" key="3">
        <Table
          columns={columns}
          dataSource={donationAccounts}
        />
      </TabPane>
    </Tabs>
  );

};
