
import React, { useState } from 'react';
import {
  Button,
  Modal,
  Tag,
  Row,
  Col,
} from 'antd';

import MoneyTitle from '../customer/MoneyTitle';
import { lookupStatusDetails } from '../../utils/ProductSwitcher';
import { StrikeTapModel } from '../../models/StrikeTapModel';

async function executeTapRefund({ tagId, tapId }) {
  const tapModel = StrikeTapModel.instance();
  return await tapModel.executeTapRefund({ tagId, tapId });
}

function createRows(tap) {
  const { currency, amount, strikeFee, netAmount, fee, paymentDetails, status, tagId, tapId } = tap || {};
  const { shouldAddOnFees } = paymentDetails || {};
  const statusDetails = lookupStatusDetails(status || 'unknown');

  let rows = [];

  if (tagId && tapId) {
    rows.push({ id: `ad-ri-am-${tapId}`, label: 'Total Amount:', money: { amount: amount + fee, currency, digits: 2 } });

    if (fee <= 0) {
      rows.push({ id: `ad-ri-fc-${tapId}`, label: 'Fees paid by client:', money: { amount: strikeFee, currency, digits: 2 } });
      rows.push({ id: `ad-ri-nc-${tapId}`, label: 'Net to client: ', money: { amount: netAmount, currency, digits: 2 } });
    }

    if (fee > 0) {
      rows.push({ id: `am-ri-pc-${tapId}`, label: 'Fees paid by customer: ', money: { amount: fee, currency, digits: 2 } });
      rows.push({ id: `ad-ri-nc-${tapId}`, label: 'Full amount to client: ', money: { amount: amount, currency, digits: 2 } });
    }

    rows.push({ id: `ad-ri-tag-${tapId}`, label: 'Tag ID: ', component: `${tagId}` });
    rows.push({ id: `ad-ri-tap-${tapId}`, label: 'Tap ID: ', component: `${tapId}` });
    rows.push({ id: `ad-ri-st-${tapId}`, label: 'Status: ', component: <Tag color={statusDetails.statusColor}>{statusDetails.statusName}</Tag> });
  }

  return rows;
}

const ActivityItemRow = ({ row }) => {
  return <Row key={row.id}>
    <Col flex={"auto"}>{row.label}</Col>
    {row.money && <Col>{<MoneyTitle {...row.money} />}</Col>}
    {row.component && <Col>{row.component}</Col>}
  </Row>;
}

const ActivitItemsCol = ({ rows, isAdmin }) => {
  return <Col>
    {rows.map(row => (<ActivityItemRow row={row} key={`aic-row-${row.id}`} />))}
  </Col>;
}

export const ActivityItemAdminButton = ({ isAdmin, item }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTap, setModalTap] = useState(null);
  const [refundInProgress, setRefundInProgress] = useState(false);

  const displayTapModal = (tap) => {
    setModalTap(tap);
    setModalVisible(true);
  }

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  }

  const refundTap = async ({ tapId, tagId }) => {
    const confirmed = window.confirm("Are you sure you want to refund this transaction? This cannot be reversed.");
    if (!confirmed) return;

    setRefundInProgress(true);
    const errorMessage = await executeTapRefund({ tagId, tapId });

    if (errorMessage) {
      message.error(errorMessage);
    } else {
      setRefundInProgress(false);
      setModalVisible(false);
    }
  }

  let rows = createRows(modalTap);

  return (
    <>
      {isAdmin && item.status !== 'sent' && 
        <Button style={{ alignSelf: 'center' }} onClick={() => displayTapModal(item)} size="small">View details</Button>
      }

      <Modal title="Tap details" visible={modalVisible} onOk={handleOk} onCancel={handleCancel}>
        { rows && modalTap && ( 
            <Col>
              <ActivitItemsCol rows={rows} isAdmin={isAdmin} />
              {isAdmin && modalTap.status !== "refunded" && (
                <Row justify='center'>
                  <Button type="primary" danger onClick={() => refundTap({ tagId: modalTap.tagId, tapId: modalTap.tapId })} disabled={refundInProgress}>Refund</Button>
                </Row>
              )}
            </Col>
          )}
      </Modal>
    </>
  );

};