import React, { useState } from 'react';
import { UserAddOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Typography, Modal , message } from 'antd';

import StrikeTeamDetailsModel from '../../models/StrikeTeamDetailsModel';
import TrackerService from '../../utils/TrackerService';

const { Text } = Typography;
const { confirm } = Modal;

export const TeamMemberDeleteButton = ({ member, isAdmin, teamName, ...rest }) => {
    const { memberClientId, teamId } = member;
    const [displayMessage, setDisplayMessage] = useState(null);
    const [isMemberDeleted, setIsMemberDeleted] = useState(!!member.isDeleted);

    const updatedBlockedStateTo = (isBlocked) => {
        setIsMemberDeleted(isBlocked);
        setDisplayMessage(null);
    };

    const deleteMember = async () => {
        const model = new StrikeTeamDetailsModel({
            teamId,
            memberClientId,
        });
        confirm({
            icon: <DeleteOutlined />,
            title: 'Deleting team member',
            content: 
            <>
              <br />
              <p>Please confirm you want to delete {member.displayName} from {teamName} team.</p> 
              <p>To restore a team member, send an invite link.</p>
            </>,
            okText: 'Delete',
            centered: true,
    
            onOk() {
              model.deleteMemberFromTeam(memberClientId);
              updatedBlockedStateTo(true);
            },
        });
    };

    const restoreMember = async () => {
        const model = new StrikeTeamDetailsModel({
            teamId,
            memberClientId,
        });
        setDisplayMessage('Restoring...');
        message.info(`Restoring ${member.displayName}...`);
        await model.restoreMemberFromTeam(memberClientId);
        message.destroy();
        message.info(`${member.displayName} is restoring!`);
        setDisplayMessage('Restored');

        updatedBlockedStateTo(false);
    };

    const isLocalHost = window.location.hostname === 'localhost';
    const isTest = isLocalHost;

    const tracker = new TrackerService({
      isTest,
    });

    const data = {
      memberClientId,
      memberDisplayName: member.displayName,
      teamId,
      teamName: member.teamName,
    }

    const onClickDelete = async () => {
      deleteMember();

      tracker.mixpanelTrack('delete_team_member', data);

      if (!isTest) {
        tracker.googleTrack('delete_team_member', data);
      }
    };

    const onClickRestore = async () => {
      restoreMember();

      tracker.mixpanelTrack('restore_team_member', data);

      if (!isTest) {
        tracker.googleTrack('restore_team_member', data);
      }
    };

    return (
        <>
            {!isMemberDeleted &&
                <Button key={`member-delete-button-${memberClientId}`} type='link' onClick={onClickDelete} {...rest} danger icon={<DeleteOutlined />}>Delete</Button>}
            {displayMessage === null && isMemberDeleted && isAdmin &&
                <Button key={`member-restore-button-${memberClientId}`} type='link' onClick={onClickRestore} {...rest} icon={<UserAddOutlined />}>Restore</Button>}
            {displayMessage &&
                <Text key={`member-delete-text-${memberClientId}`} {...rest}>{displayMessage}</Text>}
        </> 
    );
};
