import React, { useContext } from 'react';
import { Avatar } from 'antd';

import { UserContext } from '../../providers/UserProvider';

const FallbackAvatar = () => {
    const context = useContext(UserContext);
    const user = context.user;

    let initial = "";

    if (user) {
        const name = user ? user.displayName : "";
        const email = user ? user.email : "";

        initial = name ? name.charAt(0).toUpperCase() : email.charAt(0).toUpperCase();
    }

    return (
        <Avatar
            style={{
                backgroundColor: '#7265e6',
                verticalAlign: 'middle',
            }}
            shape="circle"
            size="small"
            gap={12}
        >
            { initial }
         </Avatar>
    );
};

export default FallbackAvatar;