import { useState, useEffect } from 'react';
import firebase from 'firebase/app';

export function usePayoutDetails(clientId, payoutId) {
    const [payout, setPayout] = useState(null);

    useEffect(() => {
        // Subscribe to the progress collection in Firestore
        const db = firebase.firestore();
        const ref = db.collection('clients').doc(clientId).collection('payouts').doc(payoutId);

        const unsubscribe = ref.onSnapshot((snapshot) => {
            const payoutData = snapshot.data();
            setPayout(payoutData);
        });

        // Clean up the subscription on component unmount
        return () => {
            unsubscribe();
        };
    },
        []
    );

    return payout;
}
