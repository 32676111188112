import React, { Component } from 'react';
import moment, { min } from 'moment';
import { Typography, PageHeader, Button, Tooltip, Space, Card } from 'antd';
import { Redirect } from 'react-router-dom';
import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  SecurityScanOutlined
} from '@ant-design/icons';
import firebase from 'firebase';

import StrikeAccountTransactionsModel from '../../../models/StrikeAccountTransactionsModel';
import StrikeClientRecentActivitiesModel from '../../../models/StrikeClientRecentActivitiesModel';
import StrikeClientModel from '../../../models/StrikeClientModel';
import BankAccountsModel from '../../../models/BankAccountsModel';
import StrikeWithdrawalsModel from '../../../models/StrikeWithdrawalsModel';
import StrikeClientSkusModel from '../../../models/StrikeClientSkusModel';
import { AccountBalancesView, ActionsButtonsCard, PauseButton } from '../../../components/client/AccountProfileComponents';
import ClientAvatar from '../../../components/client/ClientAvatar';
import SkuCardView from '../../../components/client/SkuCardView';
import TrackerService from '../../../utils/TrackerService';
import { countryFlags } from '../../../utils/CurrencyUtils';
import { RecentActivitiesTable } from '../../../components/client/RecentActivitiesComponents';
import CenteredSpinner from '../../../components/CenteredSpinner';
import { IDVTable } from '../../../components/admin/AdminClientsComponents';
import BannerAd from '../../../components/client/BannerAd';
import { ClientRecentAvailableActivityList } from '../../../components/client/TeamProfileRecentActivityList';
import { ProgressCreatingTap } from '../../../components/client/ProgressCreatingTap';
import { LoadableTeamsGrid } from '../../../components/client/LoadableTeamsGrid';

import '../../../App.scss';
import 'antd/dist/antd.css';

const { Paragraph } = Typography;

const isTest = window.location.hostname === 'localhost';


// /client/manage/profile
export default class AccountProfileScreen extends Component {
  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    this.baseURLs = props.baseURLs || {
      manage: '/client/manage',
      login: '/client/login'
    };

    const isAdmin = !!props.isAdmin;

    this.clientModel = new StrikeClientModel({
      onClientFound: this.foundClient,
      onClientNotFound: this.clientNotFound,
      viewModel: this,
      clientId,
      isAdmin,
    });
    this.accountsModel = new StrikeAccountTransactionsModel({
      callback: this.updatedDataSource,
      viewModel: this,
      clientId
    });

    this.skusModel = new StrikeClientSkusModel({
      callback: this.updatedSkus,
      clientId,
      daysToDisplay: 5,
    });

    this.clientId = clientId;
    this.isAdmin = isAdmin;
    this.bankModel = new BankAccountsModel({ viewModel: this, clientId });
    this.withdrawalsModel = new StrikeWithdrawalsModel({ viewModel: this, clientId });
    this.clientRecentActivitiesModel = new StrikeClientRecentActivitiesModel({ viewModel: this, clientId});

    this.state = {
      clientNotFound: false,
      route: null,
      showTestAccounts: true,
      user: null,
      client: null,
      clientId: clientId,
      accounts: [],
      recentActivities: [],
      tags: [],
      payouts: [],
      withdrawals: [],
      percent: 0,
      skus: [],
      showSkusComponent: false,
      loadingDetails: {
        user: false,
        client: true,
        accounts: false,
        transactions: false,
        actions: false,
      },
      showDates: false,
      showDatesCTA: 'Show Dates',
      isAdmin,
      isNewLayout: isAdmin,
    };
  }

  componentDidMount() {
    this.clientModel.waitForClientData();
  }

  componentWillUnmount() {
    if (this.accountsModel) this.accountsModel.stop();
    if (this.clientModel) this.clientModel.stop();
    if (this.clientRecentActivitiesModel) this.clientRecentActivitiesModel.stop();
    if (this.bankModel) this.bankModel.stop();
    if (this.withdrawalsModel) this.withdrawalsModel.stop();
    if (this.skusModel) this.skusModel.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loadingDetails.user ||
      this.state.loadingDetails.client ||
      this.state.loadingDetails.accounts ||
      this.state.loadingDetails.actions ||
      false;
  }

  foundClient = async (client) => {

    const { isNewActivity } = client || {};
    const { clientId, isAdmin } = this || {};

    const isNewLayout = clientId && (isNewActivity || isAdmin);

    this.setState(
      (state) => {
        return {
          clientNotFound: false,
          client,
          loadingDetails: {
            client: false,
          },
          loadingPauseAction: false,
          isNewLayout,
        }
      }
    );

    if(isNewLayout) {
      this.clientRecentActivitiesModel = new StrikeClientRecentActivitiesModel({ viewModel: this, clientId, isPendingOnly: true });
      this.clientRecentActivitiesModel.fetchAllActivitiesData({  }, true);
    } else {
      this.clientRecentActivitiesModel.fetchLatestActivitiesData();
    }

    this.accountsModel.waitForAccountsData();
    this.withdrawalsModel.waitForWithdrawlsData();
    this.bankModel.fetchAllPayoutsData(this.isAdmin);
    this.skusModel.fetchAllSkusData();
  }


  clientNotFound = () => {
    this.setState({
      clientNotFound: true
    });
  }

  foundAccounts = async (accounts) => {

    this.setState(
      (state) => {
        return {
          clientNotFound: false,
          accounts,
          loadingDetails: {
            accounts: false,
          }
        }
      }
    );
  }

  updatedDataSource = async (txns) => {
    const accounts = this.accountsModel.accounts;
    let transactions = this.accountsModel.transactions;


    this.setState(
      (state) => {
        return {
          clientNotFound: false,
          accounts,
          transactions,
        }
      }
    );
  }

  updatedTags = async (tags) => {

    let tippingTag = null;
    let paymentTag = null;

    tags.forEach(
      (v, i, a) => {
        const { product } = v;
        if (tippingTag == null && product === 'tipping') {
          tippingTag = v;
        }
        if (paymentTag == null && product === 'payment') {
          paymentTag = v;
        }
      }
    );

    this.setState(
      {
        tags,
        tippingTag,
        paymentTag,
      }
    );
  }

  updatedSkus = async (skus) => {
    let showSkusComponent = (skus.length > 0);
    let skuIndex = null;
    let sku = null;

    if (showSkusComponent) {
      showSkusComponent = false;
      skus.forEach((iSku, i) => {
        if (!showSkusComponent) {
          sku = iSku;
          showSkusComponent = sku && sku.countOffers < 1;
          skuIndex = i;
        }
      });
    }

    this.setState(
      (state) => {
        return {
          skus,
          sku,
          skuIndex,
          showSkusComponent
        }
      }
    );
  }

  updatedPayoutsDataSource = (payouts) => {
    this.setState(
      (state) => {
        return {
          payouts,
          loadingPayouts: false,
        }
      }
    );
  }

  updatedWithdrawalsDataSource = (withdrawals) => {
    this.setState(
      (state) => {
        return {
          withdrawals,
          loadingWithdrawals: false,
        }
      }
    );
  }

  updatedRecentActivitiesDataSource = async () => {
    let recentActivities = this.clientRecentActivitiesModel.recentActivities;

    this.setState(
      (state) => {
        return {
          clientNotFound: false,
          recentActivities
        }
      }
    );
  }

  handlerActionsButton = (selection) => {
    this.setState(
      {
        route: selection.route,
        subroute: selection.subroute,

      }
    );
  }

  onSelectOpenPDP = () => {
    const route = `order/${this.state.sku.id}`;

    this.trackOrderState('offer_clicked', {
      skuId: this.state.sku.skuId,
      name: this.state.client.displayName,
      clientId: this.state.clientId,
    });

    this.setState(
      {
        route,
        skus: null,
        skuIndex: null,
        sku: null,
        showSkusComponent: false,
      }
    );
  }

  onSelectSKU = (sku, skuIndex) => {
    this.trackOrderState(
      'offer_impression',
      {
        clientId: this.state.clientId,
        name: this.state.client.displayName,
        skuId: sku.skuId,
      }
    );

    this.setState(
      {
        sku,
        skuIndex
      }
    );
  }

  renderRedirect = () => {
    const { route, subroute } = this.state;
    const clientId = this.props.match.params.clientId;
    if (route && clientId) {
      const fromRoute = `${this.baseURLs.manage}/${clientId}`;
      let toRoute = `${this.baseURLs.manage}/${clientId}/${route}`;
      if (subroute) toRoute = `${toRoute}/${subroute}`;
      return <Redirect push to={toRoute} />
    } else {
      return null;
    }
  }

  trackOrderState = (eventId, data) => {
    const isTest = window.location.hostname === 'localhost';

    const tracker = new TrackerService({
      isTest,
    });

    tracker.mixpanelTrack(eventId, { ...data });
    tracker.googleTrack(eventId, { ...data });

  }

  navigateToKycVerification = () => {
    this.setState(
      {
        route: 'idverification'
      }
    );
  }

  isKycButtonShown = () => {
    return this.state.client && this.state.client.idVerificationAllowed
      && ((this.state.client.idVerificationStatus === '') || (this.state.client.idVerificationStatus !== 'rejected'));
  }

  handlerPauseClient = async () => {
    this.setState({ loadingPauseAction: true });
    await this.clientModel.pauseOrEnableClient();
    this.setState({ loadingPauseAction: false });
  }

  render() {
    const avatar = <ClientAvatar client={this.state.client} />
    const avatarParams = { src: avatar };

    const redirected = this.renderRedirect();

    if (redirected) {
      return redirected;
    }

    const isRestricted = this.state.client && this.state.client.isRestricted;
    const isAdmin = this.props && this.props.isAdmin;

    const pauseButton = <PauseButton
      isLoading={this.state.loadingPauseAction}
      isRestricted={isRestricted}
      pauseHandler={this.handlerPauseClient}
      key='2'
    />


    let teamsAllowedButton = null;

    let buttonText = 'Remove Teams';
    let tooltipText = 'Client will lose Teams Access';

    const { client } = this.state;
    const { isShowAds, country } = client || {};
    const flag = country ? countryFlags[country] : '';

    if (isAdmin) {
      let icon = <PauseCircleOutlined />;

      if (this.state.client && !this.state.client.teamsAllowed) {
        buttonText = 'Allow Teams';
        icon = <PlayCircleOutlined />;
        tooltipText = 'Client will get Teams Access';
      }

      teamsAllowedButton =
        <Tooltip title={tooltipText} key='4'>
          <Button icon={icon} onClick={() => handleTeamsAccessChange()} type="secondary" danger={buttonText === 'Remove Teams'}>
            {buttonText}
          </Button>
        </Tooltip>;
    }

    const handleTeamsAccessChange = async () => {
      const db = firebase.firestore();

      await db.collection('clients').doc(this.state.clientId).update({
        teamsAllowed: buttonText === 'Allow Teams'
      })
    }


    let rejectKYCButton = null;

    if (isAdmin && this.state.client && this.state.client.idVerificationAllowed) {
      if ((this.state.client.idVerificationStatus == null)
        || (this.state.client.idVerificationStatus && (this.state.client.idVerificationStatus !== 'rejected'))) {
        let kycButtonText = 'Reject KYC';
        let icon = <PauseCircleOutlined />;
        let kycTooltipText = 'Client will have KYC Verification status set to REJECTED';

        rejectKYCButton =
          <Tooltip title={kycTooltipText} key="6">
            <Button icon={icon} onClick={() => rejectKYC()} type="secondary" danger={true}>
              {kycButtonText}
            </Button>
          </Tooltip>;
      } else {
        rejectKYCButton =
          <Tooltip title={'KYC is already REJECTED'} key='5'>
            <Button type="secondary" danger={true} color={'maroon'}>
              KYC REJECTED
            </Button>
          </Tooltip>;
      }
    }

    const rejectKYC = async () => {
      const db = firebase.firestore();

      await db.collection('clients').doc(this.state.clientId).update({
        idVerificationStatus: 'rejected',
        updatedAt: firebase.firestore.Timestamp.now()
      });

      let idVerificationDocSnapshot = await db.collection('clients').doc(this.state.clientId).collection('idVerifications').get();

      if (!idVerificationDocSnapshot.empty) {
        const idVerificationDocSnapshotId = idVerificationDocSnapshot?.docs[0].id;
        await db.collection('clients').doc(this.state.clientId).collection('idVerifications').doc(idVerificationDocSnapshotId).set({
          status: 'rejected',
          rejectedAt: firebase.firestore.Timestamp.now(),
          updatedAt: firebase.firestore.Timestamp.now()
        }, {merge : true});
      } else {
        await db.collection('clients').doc(this.state.clientId).collection('idVerifications').add({
          clientId: this.state.clientId,
          email: this.state.client.email ? this.state.client.email : null,
          uuid: null, //because verification was never initiated for this client - as KYC was rejected even before he could initiate KYC
          status: 'rejected',
          rejectedAt: firebase.firestore.Timestamp.now(),
          createdAt: firebase.firestore.Timestamp.now(),
          updatedAt: firebase.firestore.Timestamp.now()
        });
      }
    }

    const showDates = async () => {
      this.setState({
        showDatesCTA: this.state.showDates ? 'Show Dates' : 'Show Time Since',
        showDates: !this.state.showDates,
      });
    }

    const routeToTransactions = () => {
      this.props.history.push(`${this.state.clientId}/transactions`);
    }

    const adminButtons = [pauseButton, teamsAllowedButton, rejectKYCButton];
    const isShowAvailableDaliy = true;

    if (isRestricted && !isAdmin) {
      return (
        <div className="accountProfile" style={{ height: '100%' }}>
          <PageHeader
            title={`Account Restricted`}
            subTitle={`This Strikepay account has been locked.`}
          />
          <p>Please get in touch with us at <a href={`mailto:support@strikepay.co?subject=Account%20Locked:%20${this.state.clientId}`}>support@strikepay.co</a> if you believe this is an error.</p>
          {
            this.isKycButtonShown() &&
            <Card align='center'>
              <Paragraph style={{ display: 'inline-block', fontSize: '20px', fontWeight: 'bold' }}>We need you to take some additional steps to verify your identity!</Paragraph><br />
              <Space direction='vertical' width={'100%'}>
                {
                  <Button
                    key={'idverification'}
                    icon={<SecurityScanOutlined />}
                    onClick={() => this.navigateToKycVerification()}
                    type={'primary'}
                    style={{ textAlign: 'start', width: `100%` }}
                  >Start Identity Verification</Button>
                }
              </Space>
            </Card>
          }
        </div>
      );
    }

    return (
      <div className="accountProfile" style={{ height: '100%' }}>
        {(this.state.clientNotFound) &&
          (<PageHeader
            ghost={false}
            title={`Account Not Found`}
            subTitle={`Please register`}
          />)
        }
        {(this.state.client && isAdmin) &&
          (<PageHeader
            ghost={false}
            title={this.state.client.displayName ? `${this.state.client.displayName} ${flag}` : `Add Details`}
            subTitle={this.state.client.profileBio ? `${this.state.client.profileBio}` : null}
            avatar={avatarParams}
            extra={adminButtons}
          />)
        }
        {isAdmin && this.state.client && !this.isLoading() &&
          <IDVTable client={this.state.client}/>
        }

        {(this.state.client === null) &&
          (null)
        }
        {<CenteredSpinner loading={this.isLoading()} />}
        {
          (this.state.showSkusComponent) && (this.state.skus) && (this.state.skus.length > 0) &&
          <SkuCardView sku={this.state.sku} onSelectSku={this.onSelectSKU} onOpenPDP={this.onSelectOpenPDP} />
        }
        {     
          <LoadableTeamsGrid
            condition={() => {
              return !this.isLoading() && this.state.client != null && this.state.client.teamsAllowed
            }}
            clientId={this.state.clientId}
            client={this.state.client}
            isAdmin={isAdmin}
          />
        }
        <AccountBalancesView
          condition={() => { return !this.state.loadingDetails.client && this.state.client != null }}
          locStrings={this.props.locStrings}
          client={this.state.client}
          clientId={this.state.clientId}
          payouts={this.state.payouts}
          withdrawals={this.state.withdrawals}
          {...this.state}
        />
        <ActionsButtonsCard
          visible={!this.state.loadingDetails.client && this.state.client != null}
          tippingTag={this.state.tippingTag}
          paymentTag={this.state.paymentTag}
          handler={this.handlerActionsButton}
          client={this.state.client}
          clientId={this.state.clientId}
          isAdmin={isAdmin}
        />
        {
          (!this.state.loadingDetails.client && this.state.client != null) && (
            <Paragraph style={{ paddingTop: '30px', fontWeight: 'bold' }}>            
              {this.state.isNewLayout ? 'Pending Activity' : 'Current Activity'}
              {' '}
              <Button onClick={showDates} type="secondary" size="small">{this.state.showDatesCTA}</Button>
              { !this.state.isNewLayout &&
              <>
                <Button onClick={routeToTransactions} type="secondary" size="small" style={{ marginLeft: "10px" }}>Downloads</Button>
              </>
              }
              {' '}
            </Paragraph>
          )
        }
        { this.state.clientId && this.state.client && this.state.isNewLayout && 
          <div>
            <ProgressCreatingTap clientId={this.state.clientId} isAdmin={this.isAdmin} locStrings={this.locStrings} />
          </div>
        }
        { this.state.recentActivities && this.state.recentActivities.length > 0 && !this.state.loadingDetails.client &&
        <RecentActivitiesTable
          condition={() => { return !this.state.loadingDetails.client && this.state.client != null }}
          locStrings={this.props.locStrings}
          client={this.state.client}
          showDates={this.state.showDates}
          isAdmin={this.state.isAdmin}
          {...this.state}
        />
        }
        { this.state.clientId && this.state.client && this.state.isNewLayout &&
          <>
          <Paragraph style={{ paddingTop: '30px', fontWeight: 'bold' }}>
            {'Available Activity'}
            <Button onClick={routeToTransactions} type="secondary" size="small" style={{ marginLeft: "10px" }}>Downloads</Button>
          </Paragraph>
          <ClientRecentAvailableActivityList 
            index={this.state.selectedDateRangeIndex}
            clientId={this.state.clientId}
            locStrings={this.props.locStrings}
            isAdmin={isAdmin}
          />
          </>
        }
        {isShowAds && <BannerAd isAdmin={isAdmin} clientId={this.state.clientId}/>}

        {isTest ? <>{'AccountProfileScreen'}</> : null}

      </div>
    );
  }
}

