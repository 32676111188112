import React from 'react';
import '../App.scss';
import { Redirect } from 'react-router-dom';

const Redirect404 = ({ fromPath, reason }) => {
    return <Redirect to={{
        pathname: "/404",
        state: { fromPath, reason, }
    }} />
};

export default Redirect404;