import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const RedirectAndScrollTop = ({ to, when, pushBefore }) => {
  const history = useHistory();

  useEffect(() => {
    if (when) {
      const currentPath = history.location.pathname;
      window.scrollTo(0, 0);

      if (pushBefore) {
        window.history.pushState(null, '', pushBefore);
      }

      if (to.startsWith('http')) {
        window.location.assign(to);
      } else {
        const fullPath = currentPath + to;
        history.push(fullPath);
      }
    }
  }, [when, to, pushBefore]);

  return null;
};

export default RedirectAndScrollTop;
