import React, {useState, useEffect} from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import {
    Space,
    Typography,
    Row, Col,
} from 'antd';
import moment from 'moment';

import MoneyTitle from '../customer/MoneyTitle';
import StrikeTeamMembersTapsModel from '../../models/StrikeTeamMembersTapsModel';
import StrikeTeamTapsModel from '../../models/StrikeTeamTapsModel';
const {Text} = Typography;


export const TimeSinceFirestoreDate = ({ dateAt, ...rest}) => {
    return <Text ellipsis {...rest} >{`${moment(dateAt.toMillis()).fromNow()}`}</Text>;
}

export const LoadingText = ({ ...rest}) => {
    return <Text ellipsis {...rest} >{`Loading...`}</Text>;
}


export const TeamLastTransactionCard = ({
    condition,
    locStrings,
    team,
    fallback: Fallback,
    limit,
    teamMemberId,
}) => {
    const [lastTap, setLastTap] = useState(null);
    const [taps, setTaps] = useState(null);
    const [loading, setLoading] = useState(true);

    const onUpdateLastTap = async (taps) => {
        setLoading(false);
        setTaps(taps);
        if(taps && taps.length > 0) {
            setLastTap(taps[0]);
        }
    };

    useEffect(
        () => {
            const model = new StrikeTeamTapsModel({ 
                teamId: team.teamId, 
                limit: limit,
                teamMemberId,
                callback: onUpdateLastTap,
            });

    
            model.fetchAllTeamTaps();

            return function cleanup() {
                model.stop();
            };
        },
        []
    );

    const useStyle = { fontSize: '16px', fontFamily: 'sans-serif', height: '30px' };

    let text = null;
    let money = null;
    let dateAt = null;

    if(lastTap) {
        dateAt = lastTap.successfulAt || lastTap.createdAt || lastTap.updatedAt || Date.now;
        text = (<Text style={useStyle} ellipsis>{dateAt ? `${moment(dateAt.toMillis()).fromNow()}` : ''}</Text>);
        money =
            (<div style={{ ...useStyle, color: 'green', fontWeight: 'bolder' }}>
                <MoneyTitle {...lastTap} digits={2} />
            </div>);
    }

    return (
        <> 
            {
            (condition && !loading && lastTap) &&
                <Space align='center'>
                    {money}
                    <TimeSinceFirestoreDate dateAt={dateAt} style={useStyle} />
                </Space>
            }
            {
            (condition && !loading && lastTap === null && Fallback ) &&
                <Fallback style={useStyle}/>
            }
            {
            (loading) &&
                <div style={{ ...useStyle, color: 'green', fontWeight: 'bolder' }}>
                    <LoadingText />
                </div>
            }
        </>
    );

};

