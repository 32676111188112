import { useState, useEffect } from 'react';

import FeatureFlagService from '../utils/FeatureFlagService';

export function useFeatureFlagMoneyJar(clientId, isTest) {
    const defaultForMoneyJar = false;
    const [shouldUseMoneyJar, setShouldUseMoneyJar] = useState(defaultForMoneyJar);
    const featureFlagName = 'show-money-jar';

    useEffect(() => {
        const onUpdateFlag = async (shoudUse) => {
          setShouldUseMoneyJar(shoudUse);
        };

        const ff = new FeatureFlagService({ 
          isTest, 
          clientId
        });

        ff.getFlagWithCallback(featureFlagName, defaultForMoneyJar, onUpdateFlag);

        return function cleanup() {
          // do we need to stop the FF service?
        };

    },
        []
    );

    return shouldUseMoneyJar;
}
