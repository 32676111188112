import React from 'react';
import { Button, Card, Col, Row } from 'antd';

import { ccyIsoCodeToSymbolMap as ccy } from '../../utils/CurrencyUtils';
const addAccount = (account, callback) => {
  callback(account);
}

const AccountCard = ({ account, index, callback }) => {
  return (<Card key={index} gutter={16}
    title={account.display_name}
    extra={
      <img alt={account.provider} style={{ width: '28px', paddingRight: '8px' }} src={account.providerImg} />
    }
    style={{
      width: 300,
      textAlign: 'left',
      margin: '12px',
    }}
  >
    {account.number && 
      <p><strong>Account: </strong>{account.number}</p>
    }
    {account.sort_code && 
      <p><strong>Sort Code: </strong>{account.sort_code}</p>
    }
    {account.iban && 
      <p><strong>IBAN: </strong>{account.iban}</p>
    }
    <Button type='primary' onClick={ () => { addAccount(account, callback) } }>Add Bank</Button>
  </Card>);
}

const AccountCards = ({ data, callback }) => {
  const cards = [];
  data.forEach((account, index) => {
    cards.push(<AccountCard key={`${index}`} account={account} index={index} callback={callback} />);
  });
  return <Row gutter={[24, 24]} justify="center">
    {cards}
  </Row>
}

export const AccountsSelector = ({ country, accounts, callback }) => {
    let data = [];
    accounts.forEach((account) => {
      const number = account.account_number.number;
      const sort_code = account.account_number.sort_code;
      const iban = account.account_number.iban;
      const swift_bic = account.account_number.swift_bic;

      if ((sort_code && number) || iban) {
        const accountData = {
          display_name: account.display_name,
          currency: account.currency,
          provider: account.provider.display_name,
          providerImg: account.provider.logo_uri,
        };

        if (['UK', 'GB'].includes(country)) {
          accountData.number = number;
          accountData.sort_code = sort_code;
        } else {
          accountData.iban = iban;
          accountData.swift_bic = swift_bic;
        }
        data.push(accountData);
      }
    });

    if (accounts) {
      return <AccountCards data={data} callback={callback} />;
    } else {
      return(<p>No accounts found</p>);
    }
};
