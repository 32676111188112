import firebase from 'firebase/app';

const isLocalHost = window.location.hostname === 'localhost';
const NUM_MAX_ACTIVITIES = 100;


const allStatuses =  ['pending', 'successful', 'created', 'available_requested', 'available', 'refunded'];
const interStatuses =  ['successful', 'created'];
const availableStatuses =  ['available', 'refunded'];
const pendingStatuses =  ['pending', 'available_requested', 'refunded'];
const defaultStatuses =  ['pending', 'available_requested', 'available', 'refunded'];

export default class StrikeClientRecentActivitiesModel {

  constructor({ viewModel, clientId, includeTestAccounts, onUpdate, isPendingOnly }) {
    this.viewModel = viewModel;
    this.clientId = clientId;
    this.accounts = [];
    this.recentActivities = [];
    this.client = null;
    this.observerTransactions = [];
    this.onUpdate = onUpdate;

    this.recentActivitiesById = {};
    this.mode = "all";
    this.statuses = !!isPendingOnly ? pendingStatuses : defaultStatuses;
  }

  getQueryForTapsForClient(params) {
    const {
      is_test,
      clientId,
      startDate,
      endDate,
    } = params;

    const db = firebase.firestore();
    const taps = db.collectionGroup('taps');
    let query = taps
      .where('clientId', '==', clientId)
      .where('status', 'in', this.statuses);

    if (startDate) {
      query = query.where('createdAt', ">=" , startDate)
    }
    if (endDate) {
      query = query.where('createdAt', "<=" , endDate)
    }
    query = query.orderBy('createdAt', 'desc');

    return query;
  }

  getShortQueryForTapsForClient(params) {
    const {
      is_test,
      clientId,
      shortDate,
      limit,
    } = params;

    const db = firebase.firestore();
    const taps = db.collectionGroup('taps');
    let query = taps
      .where('clientId', '==', clientId)
      .where('status', 'in', interStatuses)

    if (shortDate) {
      query = query.where('createdAt', ">=" , shortDate)
    }
    query = query.orderBy('createdAt', 'desc');

    if(limit > 0) {
      query = query.limit(limit);
    }

    return query;
  }

  getQueryForLatestTapsForClient(params) {
    const {
      is_test,
      clientId,
    } = params;

    const db = firebase.firestore();
    const taps = db.collectionGroup('taps');
    const query = taps
      .where('clientId', '==', clientId)
      .where('status', 'in', this.statuses)
      .orderBy('createdAt', 'desc')
      .limit(NUM_MAX_ACTIVITIES);

    return query;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if (this.observerTaps) {
      this.observerTaps();
      this.observerTaps = null;
    }
    if (this.observerShortTaps) {
      this.observerShortTaps();
      this.observerShortTaps = null;
    }
    if (this.observerInwardTransfers) {
      this.observerInwardTransfers();
      this.observerInwardTransfers = null;
    }
    if (this.observerOutwardTransfers) {
      this.observerOutwardTransfers();
      this.observerOutwardTransfers = null;
    }
    if (this.observerAutoDebitedInvoices) {
      this.observerAutoDebitedInvoices();
      this.observerAutoDebitedInvoices = null;
    }

    this.recentActivitiesById = {};
  }

  async fetchLatestActivitiesData(isTapsOnly) {
    if (this.clientId === null) return;

    const isAllQueries = !isTapsOnly;
    const clientId = this.clientId;
    this.stop();

    this.mode = "latest";

    const query = this.getQueryForLatestTapsForClient({
      clientId,
      is_test: isLocalHost,    // MARK: Only shows test accounts on localhost
    });

    this.observeTaps(query);

    if(isAllQueries) {
      this.observeInwardNOutwardTransfers();
      this.observeAutoDebitedInvoices();
    } else {

    }
  }

  async fetchAllActivitiesData(dateRange, isTapsOnly) {
    if (this.clientId === null) return;
    const { startDate, endDate, shortDate } = dateRange || { };

    const isAllQueries = !isTapsOnly;
    const clientId = this.clientId;
    this.stop();

    this.mode = "all";

    const query = this.getQueryForTapsForClient({
      clientId,
      startDate,
      endDate,
      is_test: isLocalHost,    // MARK: Only shows test accounts on localhost
    });

    this.observeTaps(query);
    if(isAllQueries) {
      this.observeInwardNOutwardTransfers(dateRange);
      this.observeAutoDebitedInvoices(dateRange);
    } else if (shortDate) {
      this.fetchIncompleteTaps({ shortDate });
    }
  }

  async fetchIncompleteTaps({ shortDate, limit }) {
    if (shortDate) {
      const clientId = this.clientId;
      const query = this.getShortQueryForTapsForClient({
        clientId,
        is_test: isLocalHost,    // MARK: Only shows test accounts on localhost
        shortDate,
      });
      this.observeShortTaps(query);
    }
  }

  observeTaps(query) {
    this.observerTaps = query.onSnapshot(querySnapshot => {
      this.recentActivities = [];
      querySnapshot.docChanges().forEach(
        change => {
          if (change.type === 'added' || change.type === 'modified') {
            const tap = change.doc.data();
            tap.type = 'tap';
            const id = change.doc.id;
            const full_tap = { ...tap, id };
            this.recentActivitiesById[id] = full_tap;
          }

          if (change.type === 'removed') {
            const id = change.doc.id;
            delete this.recentActivitiesById[id];
          }
        }
      );
      this.updateDataSource();
    }, err => {
      console.log(`Taps query error: ${err}`);
    });
  }

  observeShortTaps(query) {
    this.observerShortTaps = query.onSnapshot(querySnapshot => {
      this.recentActivities = [];
      querySnapshot.docChanges().forEach(
        change => {
          if (change.type === 'added' || change.type === 'modified') {
            const tap = change.doc.data();
            tap.type = 'tap';
            const id = change.doc.id;
            const full_tap = { ...tap, id };
            this.recentActivitiesById[id] = full_tap;
          }

          if (change.type === 'removed') {
            const id = change.doc.id;
            delete this.recentActivitiesById[id];
          }
        }
      );
      this.updateDataSource();
    }, err => {
      console.log(`Taps query error: ${err}`);
    });
  }

  observeInwardNOutwardTransfers(dateRange) {
    const db = firebase.firestore();
    this.recentActivities = [];
    const { startDate, endDate } = dateRange || { };

    let queryInward = db.collection('transfers')
      .where('toClientId', '==', this.clientId)
      .where('status', '==', 'sent');
    
    if (startDate) {
      queryInward = queryInward.where('createdAt', ">=" , startDate)
    }
    if (endDate) {
      queryInward = queryInward.where('createdAt', "<=" , endDate)
    }
    queryInward = queryInward.orderBy('createdAt', 'desc');

    this.observerInwardTransfers = 
      queryInward
      .onSnapshot(querySnapshot => {
        querySnapshot.docChanges().forEach(
          change => {
            if (change.type === 'added' || change.type === 'modified') {
              const transfer = change.doc.data();
              transfer.type = 'transfer';
              transfer.subType = 'transfer-in';
              const id = change.doc.id;
              this.recentActivitiesById[id] = { ...transfer, created: transfer.createdAt, id }; //created added for sorting in same list as taps
            }

            if (change.type === 'removed') {
              const id = change.doc.id;
              this.recentActivitiesById[id] = null;
            }
          }
        );
        this.updateDataSource();
      }, err => {
        console.log(`Inwards transfer query error: ${err}`);
      });

    let queryOutward = db.collection('transfers')
      .where('fromClientId', '==', this.clientId)
      .where('status', '==', 'sent');

    if (startDate) {
      queryOutward = queryOutward.where('createdAt', ">=" , startDate)
    }
    if (endDate) {
      queryOutward = queryOutward.where('createdAt', "<=" , endDate)
    }
    queryOutward = queryOutward.orderBy('createdAt', 'desc');

    this.observerOutwardTransfers = 
      queryOutward
        .onSnapshot(querySnapshot => {
          querySnapshot.docChanges().forEach(
              change => {
                if (change.type === 'added' || change.type === 'modified') {
                  const transfer = change.doc.data();
                  transfer.type = 'transfer';
                  transfer.subType = 'transfer-out';
                  const id = change.doc.id;
                  transfer.payoutAmountCents = transfer.payoutAmountCents * -1;
                  this.recentActivitiesById[id] = { ...transfer, created: transfer.createdAt, id }; //created added for sorting in same list as taps
                }

                if (change.type === 'removed') {
                  const id = change.doc.id;
                  this.recentActivitiesById[id] = null;
                }
              }
          );
          this.updateDataSource();
        }, err => {
          console.log(`Outwards transfer query error: ${err}`);
        });
  }

  observeAutoDebitedInvoices(dateRange) {
    const db = firebase.firestore();
    this.recentActivities = [];
    const { startDate, endDate } = dateRange || { };

    let queryAuto = db.collectionGroup('monthly-invoices')
      .where('clientId', '==', this.clientId)
      .where('status', '==', 'charged');

    if (startDate) {
      queryAuto = queryAuto.where('createdAt', ">=" , startDate)
    }
    if (endDate) {
      queryAuto = queryAuto.where('createdAt', "<=" , endDate)
    }
    queryAuto = queryAuto.orderBy('createdAt', 'desc');

    this.observerAutoDebitedInvoices = 
      queryAuto.onSnapshot(querySnapshot => {
        querySnapshot.docChanges().forEach(
          change => {
            if (change.type === 'added' || change.type === 'modified') {
              const invoice = change.doc.data();
              invoice.type = 'auto-debited-invoice';
              const id = change.doc.id;
              if (invoice.skus && invoice.skus.length > 0) {
                let totalAmountChargedCentsEUR = 0;
                let totalAmountChargedCentsUSD = 0;
                let totalAmountChargedCentsGBP = 0;
                for(let g=0; g< invoice.skus.length; g++){
                  if (invoice.skus[g].currency === 'EUR') {
                    totalAmountChargedCentsEUR += invoice.skus[g].totalAmount;
                  }
                  if (invoice.skus[g].currency === 'USD') {
                    totalAmountChargedCentsUSD += invoice.skus[g].totalAmount;
                  }
                  if (invoice.skus[g].currency === 'GBP') {
                    totalAmountChargedCentsGBP += invoice.skus[g].totalAmount;
                  }
                }
                if (totalAmountChargedCentsEUR > 0) {
                  invoice.totalAmountChargedCents = totalAmountChargedCentsEUR * -1;
                  invoice.currency = 'EUR';
                  this.recentActivitiesById[id + 'EUR'] = {
                    ...invoice,
                    created: invoice.chargedAt ? invoice.chargedAt : invoice.createdAt,
                    id
                  };
                }

                if (totalAmountChargedCentsUSD > 0) {
                  invoice.totalAmountChargedCents = totalAmountChargedCentsUSD * -1;
                  invoice.currency = 'USD';
                  this.recentActivitiesById[id + 'USD'] = {
                    ...invoice,
                    created: invoice.chargedAt ? invoice.chargedAt : invoice.createdAt,
                    id
                  };
                }
                if (totalAmountChargedCentsGBP > 0) {
                  invoice.totalAmountChargedCents = totalAmountChargedCentsGBP * -1;
                  invoice.currency = 'GBP';
                  this.recentActivitiesById[id + 'GBP'] = {
                    ...invoice,
                    created: invoice.chargedAt ? invoice.chargedAt : invoice.createdAt,
                    id
                  };
                }
              }

            }

            if (change.type === 'removed') {
              const id = change.doc.id;
              this.recentActivitiesById[id] = null;
            }
          }
        );
        this.updateDataSource();
      }, err => {
        console.log(`Auto-debited invoices query error: ${err}`);
      });
  }

  async updateDataSource() {

    let recentActivities = [];

    for (let prop in this.recentActivitiesById) {
      recentActivities.push(this.recentActivitiesById[prop]);
    }

    recentActivities.sort((a, b) => {
      const now = new Date();
      const nowString = now.toISOString();
      const aCreated = (a && a.created) ? a.created : nowString;
      const bCreated = (b && b.created) ? b.created : nowString;
      const aDate = (a && a.accountsProcessingStartedAt) ? a.accountsProcessingStartedAt : aCreated;
      const bDate = (b && b.accountsProcessingStartedAt) ? b.accountsProcessingStartedAt : bCreated;
      return (aDate < bDate) ? 1 : ((aDate > bDate) ? -1 : 0);
    });

    // Ensures we don't have lots of taps frontloaded and then every older transfer at the end of the dataset
    this.recentActivities = this.mode === "all" ? recentActivities : recentActivities.slice(0, NUM_MAX_ACTIVITIES);

    this.viewModel && this.viewModel.updatedRecentActivitiesDataSource();
    this.onUpdate && this.onUpdate(this.recentActivities);
  }

}
