import React, { Component } from 'react';
import firebase from 'firebase/app';
import { Redirect } from 'react-router-dom';
import { Typography, PageHeader, Radio } from 'antd';

import StrikeAccountTransactionsModel from '../../../models/StrikeAccountTransactionsModel';
import StrikeTapsModel from '../../../models/StrikeTapsModel';
import StrikeClientModel from '../../../models/StrikeClientModel';
import BankAccountsModel from '../../../models/BankAccountsModel';
import StrikeWithdrawalsModel from '../../../models/StrikeWithdrawalsModel';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { AccountBalancesView, RecentTransactionsTable } from '../../../components/admin/AdminCoreComponents';
import ClientAvatar from '../../../components/client/ClientAvatar';
import Spinner from '../../../components/Spinner';
const { Paragraph } = Typography;



// /client/manage/profile
export default class AdminDashboardScreen extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);

    this.clientIds = [ 'strike', 'psp-vKtqMnMcYq2m5iE167rw', 'psp-stripe-us' ];

    // const clientId = 'strike'; // TODO: Add rules to allow admin to see this one

    // this.clientModel = new StrikeClientModel({
    //   onClientFound: this.foundClient,
    //   onClientNotFound: this.clientNotFound,
    //   viewModel: this,
    //   clientId,
    //   isAdmin: true,
    // });
    // this.accountsModel = new StrikeAccountTransactionsModel({
    //   callback: this.updatedDataSource,
    //   viewModel: this,
    //   clientId
    // });

    // this.bankModel = new BankAccountsModel({ viewModel: this, clientId });
    // this.withdrawalsModel = new StrikeWithdrawalsModel({ viewModel: this, clientId });
    // this.tapsModel = new StrikeTapsModel({ viewModel: this, clientId });

    this.state = {
      clientNotFound: false,
      route: null,
      showTestAccounts: true,
      user: null,
      client: null,
      clientId: null,
      accounts: [],
      taps: [],
      payouts: [],
      withdrawals: [],
      percent: 0,
      loadingDetails: {
        user: false,
        client: true,
        accounts: false,
      },
    };
  }

  componentDidMount() {
    this.didSelectClient(this.clientIds[0]);
  }

  componentWillUnmount() {
    if (this.accountsModel) this.accountsModel.stop();
    if (this.clientModel) this.clientModel.stop();
  }

  componentDidUpdate() {
  }
  
  didSelectClient = (clientId) => {
    // const clientId = 'strike'; // TODO: Add rules to allow admin to see this one
    this.setState(
      { clientId }
    );

    this.clientModel = new StrikeClientModel({
      onClientFound: this.foundClient,
      onClientNotFound: this.clientNotFound,
      viewModel: this,
      clientId,
      isAdmin: true,
    });
    this.accountsModel = new StrikeAccountTransactionsModel({
      callback: this.updatedDataSource,
      viewModel: this,
      clientId
    });

    this.bankModel = new BankAccountsModel({ viewModel: this, clientId });
    this.withdrawalsModel = new StrikeWithdrawalsModel({ viewModel: this, clientId });
    this.clientModel.waitForClientData();
  }

  isLoading = () => {
    return this.state.loadingDetails.user ||
      this.state.loadingDetails.client ||
      false;
  }

  foundClient = async (client, user) => {

    this.setState(
      (state) => {
        return {
          clientNotFound: false,
          client,
          user,
          loadingDetails: {
            client: false,
          }
        }
      }
    );

    this.accountsModel.waitForAccountsData();
  }


  clientNotFound = () => {
    this.setState({
      clientNotFound: true
    });
  }

  foundAccounts = async (accounts) => {

    this.setState(
      (state) => {
        return {
          clientNotFound: false,
          accounts,
          loadingDetails: {
            accounts: false,
          }
        }
      }
    );
  }

  updatedDataSource = async (txns) => {
    const accounts = this.accountsModel.accounts;
    let transactions = this.accountsModel.transactions;

    this.setState(
      (state) => {
        return {
          clientNotFound: false,
          accounts,
          transactions,
        }
      }
    );
  }

  onChangeRadioClientIds = (e) => {
    this.didSelectClient(e.target.value);
  }

  renderRedirect = () => {
    const { route, clientId } = this.state;
    if (route && clientId) {
      const toRoute = `/admin/manage/${route}`;
      return <Redirect push to={toRoute} />
    } else {
      return null;
    }
  }

  logOut = async () => {
    await firebase.auth().signOut();
  }

  renderRedirectToLogin = () => {
    this.logOut();
    const toRoute = `/admin/login`;
    return <Redirect to={toRoute} />
  }

  render() {
    const avatar = <ClientAvatar client={this.state.client} />
    const avatarParams = { src: avatar };

    const redirected = this.renderRedirect();

    if (redirected) {
      return redirected;
    }

    if (this.state.clientNotFound) return this.renderRedirectToLogin();

    return (
      <div style={{ height: '100%' }}>
        { (this.state.clientNotFound) &&
          (<PageHeader
            ghost={false}
            title={`Account Not Found`}
            subTitle={`Please register`}
          />)
        }
        { (this.state.client && this.state.user) &&
          (<PageHeader
            ghost={false}
            title={this.state.user.email ? `${this.state.user.email}` : `EMail???`}
            subTitle={this.state.client.displayName ? `${this.state.client.displayName}` : null}
            avatar={avatarParams}
          />)
        }
        { (this.state.client === null) &&
          (null)
        }
        <Spinner condition={() => { return this.isLoading() }} />
        { !this.isLoading() && this.state.clientId &&
          (<div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
            <Radio.Group 
              options={this.clientIds} 
              onChange={this.onChangeRadioClientIds} 
              value={this.state.clientId} 
          />
          </div>)
        }
        <AccountBalancesView
          condition={() => { return !this.state.loadingDetails.client && this.state.client != null }}
          locStrings={this.props.locStrings}
          client={this.state.client}
          clientId={this.state.clientId}
          payouts={this.state.payouts}
          withdrawals={this.state.withdrawals}
          noButtons
          {...this.state}
        />
        { (!this.state.loadingDetails.transactions && this.state.transactions != null) &&
          (<Paragraph style={{ paddingTop: '30px', fontWeight: 'bold' }}>Recent Transactions</Paragraph>)
        }
        <RecentTransactionsTable
          condition={() => { return !this.state.loadingDetails.transactions && this.state.transactions != null }}
          locStrings={this.props.locStrings}
          client={this.state.client}
          {...this.state}
        />
      </div>
    );
  }
}

