import { changeConfirmLocale } from 'antd/lib/modal/locale';
import firebase from 'firebase/app';

import image_default_avatar from '../images/Strike_Symbol_RGB_White_Magenta.jpg';

export const AllClaimTypes = {
  redirect : {
    label: 'Redirect',
    description: 'Redirect to THird Party like Just Giving'
  },
  autoClaim: {
    label: 'Auto Claim',
    description: 'Tag is ready to take tips but you need to be logged in to edit the meta data'
  },
  thirdPartyAutoClaim: {
    label: '3rd Party Auto Claim',
    description: 'Tag is ready to take tips wiht a link to edit the meta data (once)'
  },
};


export const AllProducts = {
  giving : {
    label: 'Giving',
    description: 'Strike Giving for Donations'
  },
  payment: {
    label: 'Payment',
    description: 'Strikepay'
  },
  tipping: {
    label: 'Tipping',
    description: 'Strike Tipping'
  },
};


export default class StrikeTemplateTagsModel {

  constructor({ viewModel, issuerId, claimTypes, products, clientIds, is_test, orderBy }) {
    this.viewModel = viewModel;
    this.applySearchParams({ issuerId, claimTypes, products, clientIds, is_test, orderBy });    
  }

  getQueryForTagsForIssuer() {
    const {
      issuerId,
      claimTypes,
      products,
      clientIds,
      is_test,
      orderBy,
    } = this;

    const {
      fieldName,
      order
    } = orderBy;

    const db = firebase.firestore();
    let usedIn = false;
    this.warning = {
      text: '',
      raised: false,
    };
        
    let query = db.collection('issuers').doc(issuerId).collection('template_tags');
    if(products && products.length > 0) {
      if(products.length > 1) { 
        query = query.where('product', 'in', products);
        usedIn = true;
      } else if(products.length === 1) {
        query = query.where('product', '==', products[0]);
      }
    }
    if(claimTypes && claimTypes.length > 0) {
      if(claimTypes.length > 1 && !usedIn) {
        query = query.where('claimType', 'in', claimTypes);
        usedIn = true;
      } else if(claimTypes.length === 1) {
        query = query.where('claimType', '==', claimTypes[0]);
      } else {
        this.warning = {
          text: `Ignoring ${claimTypes.length} claim types. Select 1 or all products and try again.`,
          raised: true,
        };
      }
    }
    if(clientIds && clientIds.length > 0) {
      if(clientIds.length > 1 && !usedIn) { 
        query = query.where('clientId', 'in', clientIds);
        usedIn = true;
      } else if(clientIds.length === 1) {
        query = query.where('clientId', '==', clientIds[0]);
      } else {
        this.warning = {
          text: `Ignoring ${clientIds.length} clientIds. Select all products and claim types and try again.`,
          raised: true,
        };
      }
    }
    if(is_test !== undefined && is_test !== null) query = query.where('is_test', '==', is_test);
    query = query.orderBy(fieldName, order);
    return query;
  }


  applySearchParams({ issuerId, claimTypes, products, clientIds, is_test, orderBy }) {
    this.stop();
    this.issuerId = issuerId;
    this.claimTypes = claimTypes;
    this.products = products;
    this.clientIds = clientIds;
    this.is_test = is_test;
    this.orderBy = orderBy || { fieldName: 'updatedAt', order: 'desc' };
    console.log('applySearchParams', products, claimTypes, orderBy);
  }


  async restartSearch({ issuerId, claimTypes, products, clientIds, is_test, orderBy }) {
    console.log('restartSearch');
    this.applySearchParams({ issuerId, claimTypes, products, clientIds, is_test, orderBy });    
    this.fetchAllTagsData(); 
  }


  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if(this.observerTags) {
      this.observerTags();
      this.observerTags = null;
      this.tagsById = {}
      this.tags = [];
    }
  }

  async fetchAllTagsData()  {
    if (this.issuerId === null) return;

    this.stop();

    const db = firebase.firestore();
    this.tags = [];
    this.tagsById = {};

    let query = this.getQueryForTagsForIssuer();

    this.observerTags = query.onSnapshot(querySnapshot => {
      let index = querySnapshot.size;
      this.tags = [];
      if(index === 0) this.updateDataSource();

      querySnapshot.docChanges().forEach(
        change => {
          if(change.type === 'added' || change.type === 'modified') {
            const doc = change.doc.data();
            const id = change.doc.id;
            const full_doc = { ...doc, id };
            this.tagsById[id] = full_doc;
            this.updateDataSource();
          }

          if(change.type === 'removed' )  {
            const id = change.doc.id;
            this.tagsById[id] = null;
            this.updateDataSource();
          }
        }
      );
    }, err => {
      this.tags = [];
      this.tagsById = {};
      this.updateDataSource();
      console.log(`Template Tags query error: ${err}`);
    });


  }

  async updateDataSource() {
    this.tags = [];

    for(let prop in this.tagsById) {
      this.tags.push(this.tagsById[prop]);  
    }

    this.viewModel.updatedTagsDataSource(this.tags);
  }

}
