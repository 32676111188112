import React, { Component } from 'react';
import qs from 'qs';
import firebase from 'firebase/app';
import { Typography, Col, Row, Space } from 'antd';

import FirebaseLogin from '../../../components/client/ClientFirebaseLogin';

import '../../../App.scss';

const { Title, Text } = Typography;


export default class AdminLoginScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redirectWithUser: false,
      loading: true,
      locStrings: {
        terms_and_conditions: "TERMS",
        privacy_policy: "PRIVACY",
        paymentcard2_cashlesstippingtext_46302: "Powered by ",
      }
    };

    this.handleNewUserSignup = this.handleNewUserSignup.bind(this);
  }

  async componentDidMount() {
    // stop the observer is called multiple times and restart here
    if (this.authObserver) {
      this.authObserver();
      this.authObserver = null;
    }

    firebase.auth().onAuthStateChanged(async function (user) {
      this.handleAuthentication(user);
    }.bind(this));
  }

  async handleAuthentication(user) {
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    let successUrl = "/";

    if (queryParams.redirect) {
      successUrl = queryParams.redirect;
    }

    if (user && user.uid && !user.isAnonymous) {
      this.fetchClientDataWithUserId(user.uid, queryParams.redirect);

      // stop the observer when user found?
      if (this.authObserver) {
        this.authObserver();
        this.authObserver = null;
      }
    }
  }

  componentWillUnmount() {
    if (this.observerClient) {
      this.observerClient();
      this.observerClient = null;
    }
  }

  componentDidUpdate() {
  }

  fetchClientDataWithUserId = async (ownerId, redirect) => {
    const db = firebase.firestore();
    const doc = await db.collection('clients').doc('strike').get();

    if(doc.exists) {
      this.client = doc.data();
      this.clientId = doc ? doc.id : null;
      this.foundClient(this.clientId, redirect);
    } else {
      this.clientNotFound();
    }

  }

  foundClient = (clientId, redirect) => {
    if (clientId) {
      let successUrl;

      if (redirect) {
        successUrl = redirect;
      } else {
        successUrl = `/admin/dashboard`;
      }

      this.props.history.push(successUrl);
      this.setState({ loading: false });
    } else {
      this.clientNotFound()
    }
  }

  clientNotFound = () => {
    // do something
    console.log('No user or client here...');
  }

  async handleNewUserSignup(authResult) {
    const user = authResult.user;
    if (navigator.language) {
      await this.setUserLocale(user.uid, navigator.language);
    }
    await this.handleAuthentication(user);
  }

  async setUserLocale(uid, browserLocale) {
    const db = firebase.firestore();
    await db.collection('users').doc(uid).set({
      locale: browserLocale
    }, { merge: true });
  }

  render() {
    return (
      <div>
        <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]} justify={'center'} style={{ textAlign: 'center' }}>
          <Col sm={24} md={24} lg={8} >{' '}</Col>
          <Col sm={24} md={24} lg={8} > 
            <Row justify={'center'}><Title level={2} >Strikepay Admin</Title></Row>
            <Row justify={'center'}><Text strong type={'danger'} >Restricted to Strikepay Employees only</Text></Row>
            <Row justify={'center'}><Text >(All activity is tracked)</Text></Row>
            <Row justify={'center'} style={{ paddingTop: '44px' }}><FirebaseLogin logins={{ google: true, email: false }} ref={(el) => this._elFirebaseLogin = el} handler={this.handleNewUserSignup} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...this.props} /></Row>
            <Row justify={'center'}><Text type='secondary' >Use your @strikepay.co email</Text></Row>
          </Col>
          <Col sm={24} md={24} lg={8} >{' '}</Col>
        </Row>
      </div>
    );
  }
}
