import React, { useState } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Select,
  Button,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { FormItemLayout, TailFormItemLayout } from '../../utils/Constants';
import { supportedCurrencies, defaultCurrencyByCountry } from '../../utils/CurrencyUtils';
const { Option } = Select;

export const CreateTeamFormMinimal = ({ callback, loading, client, ...rest }) => {
  const [form] = Form.useForm();
  const { country } = client;
  const initialCurrency = defaultCurrencyByCountry[country] || 'EUR';

  const onFinish = (values) => {
    if (callback) {
      callback(values);
    }
  };

  let currencies = {
    ...supportedCurrencies,
  };

  return (
    <Form
      {...FormItemLayout}
      form={form}
      name="teamForm"
      onFinish={onFinish}
      scrollToFirstError
    >
      <Form.Item
        name="teamName"
        label={
          <span>
            Team Name&nbsp;
            <Tooltip title="Your team's name">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        style={{ width: '100%', textAlign: 'left' }}
        rules={[
          {
            required: true,
            message: 'Please input a team name!',
            whitespace: true
          },
        ]}
      >
        <Input maxLength={40} style={{ width: '325px' }} />
      </Form.Item>

      <Form.Item
        name="defaultCurrency"
        initialValue={initialCurrency}
        label={
          <span>
            Team's currency&nbsp;
            <Tooltip title="Default currency to be used for team payments. Can be changed for individual team tags during tag claim process">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        rules={[
          {
            required: true,
            message: 'Please select a currrency!'
          },
        ]}
      >
        <Select
          allowClear={false}
          placeholder="Select team's default currency"
          style={{ maxWidth: '250px', float: 'left', textAlign: 'left' }}
        >
          {Object.keys(currencies).map(
            (i) => (
              <Option key={i} value={i}>{currencies[i]}</Option>
            ))
          }
        </Select>
      </Form.Item>

      <Form.Item {...TailFormItemLayout}>
        <Button type="primary" htmlType="submit" disabled={loading}>
          Create Your Team
        </Button>
      </Form.Item>
    </Form>
  );
}