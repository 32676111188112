import React, { useState } from 'react';
import moment from 'moment';
import {
  Typography,
  Skeleton,
  Space,
} from 'antd';

const { Text } = Typography;

import { useCreatingTaps, useTimerDate } from '../../models/useCreatingTaps';


const getProgressText = (tap) => {
  const statusText = {
    tipping: 'Tip started...',
    giving: 'Donation begun...',
    payment: 'Payment in progress...',
  };
  
  const { product } = tap || {};
  if (product) {
    const progressText = statusText[product];
    return progressText;
  }
  return null;
}

const getBackgroundColor = (tap) => {
  const colorCircle = {
    tipping: '#ff009844',
    giving: '#00ff9844',
    payment: '#98ff0044',
  }
    
  const { product } = tap || {};
  if (product) {
    const backgroundColor = colorCircle[product];
    return backgroundColor;
  }
  return '#00ff9844';
}

const timerSettings = { unit: 'minutes', interval: 1 };

const getShortDate = () => {
  const { unit, interval } = timerSettings;
  const startDateRaw = new Date();
  const shortDate = moment(startDateRaw).subtract(interval, unit).toDate();
  return shortDate;
}

export const ProgressCreatingTap = ({ isAdmin, clientId, locStrings, ...rest }) => {
  const shortDate = useTimerDate({ ...timerSettings });
  const taps = useCreatingTaps(clientId, shortDate);
  const tap = taps && taps.length > 0 ? taps[0] : null;
  const progressText = getProgressText(tap);
  const backgroundColor = getBackgroundColor(tap);

  return (progressText &&
    <Space direction='horizontal'>
      <Skeleton.Button active={true} size={'small'} shape={'circle'} block={false} style={{ backgroundColor }} />
      <Text type='secondary'>
        {progressText}
      </Text>
    </Space>
  );

};
