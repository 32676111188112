import React, { Component } from 'react';
import '../App.scss';
import { Button } from 'antd';

import FooterCard from '../components/customer/FooterCard';
import img_elImage from '../images/strikepay_Mark_RGB_Black.png';
import TrackerService from '../utils/TrackerService';

export default class N404Screen extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    const isLocalHost = window.location.hostname === 'localhost';

    if (this.props.location.state && this.props.location.state.fromPath) {
      const tracker = new TrackerService({ 
        isTest: isLocalHost,
      });

      tracker.mixpanelTrack("404", {
        fromPath: this.props.location.state.fromPath,
        reason: this.props.location.state.reason,
      });
  
      if (!isLocalHost) {
        tracker.googleTrack(
          "404",
          {
            fromPath: this.props.location.state.fromPath,
            reason: this.props.location.state.reason,
          }
        );
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  goToLogin = () => {
    this.props.history.push("/client/login");
  }

  goToHomepage = () => {
    window.location.replace("https://www.strikepay.co");
  }
  
  render() {
    let layoutFlowStyle = {
      marginTop: "120px",
      marginLeft: "auto",
      marginRight: "auto",
    };
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elImage = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen N404Screen">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div style={style_elText}>
            <div>
              <h1>{this.props.locStrings.n404_text_256151}</h1>


              <Button type="primary" style={{ marginTop: "40px", marginBottom: "10px" }} onClick={this.goToHomepage}>Home</Button>
              <br/>
              <Button onClick={this.goToLogin}>Client Login</Button>

            </div>
          </div>
          
          <div className="elImage">
            <img style={style_elImage} src={img_elImage} alt="strike" onClick={this.goToLogin} />
          </div>
        </div>

        <div className="hasNestedComps elFooterCard">
          <div>
            <FooterCard ref={(el)=> this._elFooterCard = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
        </div>
      </div>
    )
  }  
}
