import path from 'path';

import React, {Component} from 'react';
import {Alert, Modal, PageHeader, Spin, Tag, Upload,Button} from 'antd';
import {LoadingOutlined, PlusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import ReactCrop from 'react-image-crop';
import firebase from 'firebase';

import {Spinner} from '../../../components/client/AccountProfileComponents';
import {TeamsTable} from '../../../components/client/TeamsComponents';
import {EditTeamForm} from '../../../components/client/EditTeamForm';
import StrikeClientModel from '../../../models/StrikeClientModel';
import StrikeTeamsModel from '../../../models/StrikeTeamsModel';
import getBase64 from '../../../utils/GetBase64';
import CenteredSpinner from '../../../components/CenteredSpinner';
import TrackerService from '../../../utils/TrackerService';

import 'react-image-crop/dist/ReactCrop.css';
import '../../../App.scss';
import 'antd/dist/antd.css';

export default class TeamsClientProfileScreen extends Component {

    constructor(props) {
        super(props);

        const clientId = this.props.match.params.clientId;
        this.clientId = clientId;
        const isAdmin = !!props.isAdmin;
        this.clientModel = new StrikeClientModel({viewModel: this, clientId, isAdmin});
        this.model = new StrikeTeamsModel({viewModel: this, clientId});

        this.state = {
            clientId: clientId,
            client: null,
            loading: true,
            loadingTeams: true,
            clientNotFound: false,
            mode: 'viewing',
            crop: {
                unit: '%',
                width: 30,
                aspect: 1 / 1,
            },
            savingNewTeam: false,
            teamId: props.match.params.teamId,
            isAdmin: isAdmin,
        };
    }

    componentDidMount() {
        this.clientModel.waitForClientData();
    }

    componentWillUnmount() {
        if (this.clientModel) this.clientModel.stop();
        if (this.model) this.model.stop();
    }

    componentDidUpdate() {
    }

    isLoading = () => {
        return this.state.loadingTeams || false;
    }


    foundClient = async (client) => {
        if(client.hasOwnProperty('teamsAllowed') && !client.teamsAllowed){
            this.props.history.push(`/client/manage/${this.state.clientId}`);
        }
        this.setState({
            client,
            loading: false,
            loadingTeams: true,
        });

        await this.model.fetchAllTeamsForClient();
    }

    updatedTeamsDataSource = (teams) => {
        this.setState({
            teamList: teams,
            loadingTeams: false,
        });
    }

    clientNotFound = () => {
        this.setState({
            loading: false,
            clientNotFound: true,
        });
    }

    changeMode = (mode) => {
        if (mode === 'viewing') {
            return 'adding'
        } else if (mode === 'adding') {
            return 'saving'
        } else {
            return 'viewing';
        }
    }


    onClickAdd = () => {
        this.setState((state) => {
                return {
                    mode: this.changeMode(state.mode)
                }
            }
        )
    }

    doneSaving = () => {
        this.setState((state) => {
                return {
                    mode: this.changeMode(state.mode)
                }
            }
        );
    }

    internalSave = async (data) => {
        let teamId = await this.model.makeNewTeamObject(data, this.state.client);
        if (this.state.base64Image) {
            await this.uploadTopLogo(teamId)
        }
        this.doneSaving();
        setTimeout(() => {
            this.setState({
                savingNewTeam:false
            });
        }, 9000); //Wait for 9 seconds and then release 'creating your team' hold on screen. Will be used only if team creation in backend exceeds 9 seconds
        this.listenToNewTeamGoingLive(teamId);
    }

    listenToNewTeamGoingLive = async (teamId) => {
        const db = firebase.firestore();
        let newTeamObserverUnsubscribe = db.collection('teams').doc(teamId).onSnapshot(snapshot => {
          if(snapshot.data() && snapshot.data().status && snapshot.data().status === 'live'){
              this.setState({
                teamId,
                savingNewTeam: false
              })
          }

          const isLocalHost = window.location.hostname === 'localhost';
          const isTest = isLocalHost;

          const tracker = new TrackerService({
            isTest,
          });

          const data = {
            teamOwnerClientId: this.state.clientId,
            teamOwnerDisplayName: this.state.client && this.state.client.displayName,
            teamId: this.state.teamId,
          }

          tracker.mixpanelTrack('create_team', data);
        
          if (!isTest) {
            tracker.googleTrack(
              'create_team', data);
          }
          newTeamObserverUnsubscribe();
        });
    }

    onClickSave = async (data) => {
        this.setState((state) => {
                return {
                    savingNewTeam: true,
                    mode: this.changeMode(state.mode)
                }
            }
        );
        await this.internalSave(data);
    }

    handleOk = async () => {
        await this.saveImageLocally(this.state.croppedImage);
        this.setState({visible: false});
    };


    onClickCancel = () => {
        this.setState({mode: 'viewing'});
    }

    getButtons = () => {
        if (this.state.mode === 'viewing') {
            return [
                <Button key="1" type="text" shape='circle'
                        icon={<PlusCircleOutlined style={{fontSize: '28px', color: '#070'}}/>}
                        onClick={this.onClickAdd}/>
            ]
        } else if (this.state.mode === 'adding') {
            return [
                <Button key="1" type="danger" onClick={this.onClickCancel}>
                    Cancel
                </Button>,
            ];
        } else {
            return [];
        }
    }

    handleCancel = () => {
        this.setState({ visible: false });
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({crop});
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImage = await this.getCroppedImg(
                this.imageRef,
                crop,
            );
            this.setState({croppedImage});
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    getCroppedImg(image, crop) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = crop.width;
        canvas.height = crop.height;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );


        const timestamp = Date.now();
        const fileName = `${timestamp}.jpg`;
        this.setState({fileName});

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                resolve(blob);
            }, 'image/jpeg');
        });
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({uploading: true});
            return;
        }

        let fileExtn = path.extname(info.file.name).toLowerCase();
        this.setState({fileExtn});
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, base64Image =>
                this.setState({
                    base64Image,
                    uploading: false,
                }),
            );
        }
    };

    uploadTopLogo = async (teamId) => {
        const storageRef = firebase.storage().ref();

        const fileName = teamId + '-topLogo' + (this.state.fileExtn ? this.state.fileExtn : '.jpg');
        const teamImagesRef = storageRef.child(`public-images/team-images/${fileName}`);

        const resultSnapshot = await teamImagesRef.putString(this.state.base64Image, 'data_url', {contentType: 'image/png'});
        const topLogoUrl = await resultSnapshot.ref.getDownloadURL();

        const db = firebase.firestore();

        await db.collection('teams').doc(teamId).update({
            topLogo: topLogoUrl
        })
    }

    saveImageLocally = async (file) => {
        getBase64(file, base64Image =>
            this.setState({
                base64Image
            })
        );
    }


    beforeUpload = (file) => {
        this.setState({visible: true, src: URL.createObjectURL(file)});
        // return false to not have it trigger upload, we want the file but not the actual uploading
        return false;
    }

    render() {
        const teamSelected = {}; // this.state.teamSelected;

        if (this.state.mode === 'viewing' && !this.state.loadingTeams && !this.state.savingNewTeam && this.state.client && this.state.teamList) {
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`All Teams`}
                        tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'adding' ?
                            <Tag color="blue">Adding</Tag> : <Tag color="green">Saving...</Tag>)}
                        onBack={(() => window.history.back())}
                        extra={this.getButtons()}
                    />
                    <TeamsTable
                        condition={() => {
                            return !this.state.loadingTeams
                        }}
                        teams={this.state.teamList}
                        onAddAccount={this.onClickAdd}
                        isAdmin={this.state.isAdmin}
                    />
                </div>
            );
        } else if (!this.state.loadingTeams && this.state.mode === 'adding' && !this.state.savingNewTeam) {
            const {uploading, topLogoUrl} = this.state;
            const topLogo = '';

            const uploadButton = (
                <div>
                    {uploading ? <LoadingOutlined/> : <PlusOutlined/>}
                    <div style={{marginTop: 8}}>Load Team Logo</div>
                </div>
            );
            const {visible, loadingTeams} = this.state;
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`Create New Team`}
                        tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'adding' ? null :
                            <Tag color="green">Saving...</Tag>)}
                        extra={this.getButtons()}
                    />
                    <div style={{marginLeft: "auto", marginRight: 'auto', width: "intrinsic", marginBottom: "25px"}}>

                        <Upload
                            name="topLogo"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={this.saveImageLocally}
                            beforeUpload={this.beforeUpload}
                            onChange={this.handleChange}
                        >
                            {this.state.base64Image ? <img src={this.state.base64Image} alt="Team Logo" style={{
                                width: '104px',
                                height: '104px',
                                borderRadius: '100%'
                            }}/> : uploadButton}
                        </Upload>
                    </div>

                    <Modal
                        visible={visible}
                        title="Crop your image"
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            <Button key="back" onClick={this.handleCancel}>
                                Cancel
                            </Button>,
                            <Button key="submit" type="primary" loading={loadingTeams} onClick={this.handleOk}>
                                Confirm
                            </Button>,
                        ]}
                    >
                        <div className="cropper">
                            {this.state.src && (
                                <ReactCrop
                                    src={this.state.src}
                                    crop={this.state.crop}
                                    ruleOfThirds
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                />
                            )}
                        </div>
                    </Modal>

                    <EditTeamForm
                        team={teamSelected}
                        callback={this.onClickSave}
                        edit={false}
                    />
                </div>
            );
        } else if ((this.state.loadingTeams && !this.state.savingNewTeam) && this.state.mode === 'viewing' && !this.state.teamList) {
            return (
                <div>
                    <CenteredSpinner loading={this.isLoading()} noLayout={false} />
                </div>
            );
        } else if (this.state.savingNewTeam) {
            return (
                <div>
                    <CenteredSpinner loading={this.state.savingNewTeam} noLayout={false} />
                </div>
            );
        } else {
            return (
                <div style={{height: '100%'}}>
                    <PageHeader
                        ghost={false}
                        title={`All Teams`}
                        tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ?
                            <Tag color="blue">Add new</Tag> : <Tag color="green">Saving...</Tag>)}
                        onBack={(() => window.history.back())}
                        extra={this.getButtons()}
                    />
                </div>
            );
        }


    }
}

