import React, {useState} from 'react';
import {
    PlusCircleOutlined,
    TeamOutlined,
    WifiOutlined,
    QrcodeOutlined,
    UnorderedListOutlined,
    ArrowUpOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {
    Button,
    Space,
    Input,
    Modal,
    Card,
    Typography,
    Row,
    Col,
    Statistic,
    Radio,
    InputNumber,
    Alert,
    Tooltip,
    message,
    Checkbox,
    Skeleton,
    Table, Tag } from 'antd';
import moment from 'moment';
import firebase from 'firebase/app';

import { ccyIsoCodeToSymbolMap, supportedCurrencies } from '../../utils/CurrencyUtils';
import MoneyTitle from '../customer/MoneyTitle';
import ProductSwitcher from '../../utils/ProductSwitcher';
import TrackerService from '../../utils/TrackerService';

import '../../App.scss';
import 'antd/dist/antd.css';

const {Paragraph, Text} = Typography;
const {Search} = Input;

// TESTING: Test data to validate datasources
const datasourceForTesting = [
    {
        // REQUIRED fields:
        currency: 'EUR',
        is_test: false,
        product: 'tipping',
        balance: 300, decimalBalance: 3,
        // OPTIONAL fields:
        pending: 100, pendingDecimal: 1,
        available: 200, pendingAvailable: 2,
        withdrawn: 300, withdrawnDecimal: 3,
    },
    {
        currency: 'GBP',
        is_test: false,
        product: 'tipping',
        balance: 400, decimalBalance: 4,
        pending: 200, pendingDecimal: 2,
        available: 0, pendingAvailable: 0,
        withdrawn: 500, withdrawnDecimal: 5,
    },
    {
        currency: 'USD',
        is_test: true,
        product: 'tipping',
        balance: 1400, decimalBalance: 14,
        pending: 200, pendingDecimal: 2,
        available: 200, pendingAvailable: 2,
        withdrawn: 500, withdrawnDecimal: 5,
    }
];

const ccy = ccyIsoCodeToSymbolMap;

export const TeamsTable = ({condition, onAddAccount, locStrings, client, teams, isAdmin, ...rest}) => {

    const footerActionCard = (
        <Space direction='horizontal'>
            <Button key="1" type="text" icon={<PlusCircleOutlined/>} onClick={onAddAccount}>Add a New
                Team</Button> 
        </Space>
    );
    const columns = [
        {
            title: 'Name',
            dataIndex: 'teamName',
            key: 'teamName',
        },
        {
            title: 'Description',
            dataIndex: 'teamDescription',
            key: 'teamDescription'
        },
        {
            title: 'Members',
            dataIndex: 'teamMembers',
            key: 'teamMembers',
        },
        {
            title: '',
            dataIndex: 'teamId',
            key: 'teamId',
            render:
                (teamId, team) => (<>
                        {((team.teamMemberPermissions && team.teamMemberPermissions.isTeamAdmin) || (team.teamMemberRoles && team.teamMemberRoles.includes('admin')))
                        &&
                        <Link to={`./teams/${teamId}`}>
                            <Button type='primary'>Manage Team</Button>
                        </Link>
                        }
                        {((team.teamMemberPermissions && !team.teamMemberPermissions.isTeamAdmin) && (team.teamMemberRoles && !team.teamMemberRoles.includes('admin')))
                        &&
                        <Link to={`./teams/${teamId}`}>
                            <Button type='primary'>View Team</Button>
                        </Link>
                        }</>
                )

        },
    ];

    const tableData = [];
    let key = 1;
    teams.forEach(team => {
        const teamNumberOfDeletedMembers = team && team.teamNumberOfDeletedMembers ? team.teamNumberOfDeletedMembers : 0;
        const teamTotalNumberOfMembers = team.teamNumberOfMembers + teamNumberOfDeletedMembers;
        const teamRow = {
            teamName: team.teamName,
            teamDescription: team.teamDescription,
            teamMembers: isAdmin ? teamTotalNumberOfMembers : team.teamNumberOfMembers,
            teamId: team.teamId,
            teamMemberRoles: team.roles,
            teamMemberPermissions: team.permissions,
            key: key
        }
        key++;
        tableData.push(teamRow);
    });

    return (
        <Table columns={columns} dataSource={tableData} style={{width: '100%'}} pagination={{pageSize: 40}}
               scroll={{y: 300}}/>
    );

};

export const TeamPayoutsList = ({condition, locStrings, teamId, membersList, teamDetails, accounts, currentClientId}) => {
    let defaultPayoutValue = 0;

    const [mode, setMode] = useState('list');
    const [memberBeingPaid, setMemberBeingPaid] = useState('');
    const [payoutAmount, setPayoutAmount] = useState(defaultPayoutValue);
    const [maxAvailableToWithdraw, setMaxAvailableToWithdraw] = useState(0);
    const [errorAlertMessage, setErrorAlertMessage] = useState(null);
    const [successAlertMessage, setSuccessAlertMessage] = useState(null);
    const [processingPayoutForMember, setProcessingPayoutForMember] = useState(false);

    let teamMainAccount = accounts && accounts.length > 0 ? accounts[0] : null;

    const columns = [
        {
            title: 'Name',
            dataIndex: 'displayName',
            key: 'displayName',
            render:
                (displayName, member) => (
                    <div>
                        <Space direction="vertical">
                            <Text 
                                strong={(currentClientId === member.clientId)}
                            >
                                {displayName}{(currentClientId === member.clientId) && ' (You)'}
                            </Text>
                            { member.lastInwardTransferAt && member.lastInwardTransferAt !== 'N/A' &&
                            <Tag color={"green"} >{member.lastInwardTransferAt}</Tag>
                            }
                            { member.lastInwardTransferAt && member.lastInwardTransferAt === 'N/A' &&
                            <Tag >{'No payout yet'}</Tag>
                            }
                        </Space>
                    </div>
                )
        },
        {
            title: 'To-date',
            dataIndex: 'totalAmountReceivedTillDate',
            key: 'totalAmountReceivedTillDate',
        },
        {
            title: 'Last',
            dataIndex: 'lastPayoutAmount',
            key: 'lastPayoutAmount',
        },
        {
            title: '',
            dataIndex: 'clientId',
            key: 'clientId',
            render:
                (clientId, member) => (
                    <Space wrap={true} align='center'>
                        <Button 
                            disabled={!teamMainAccount || member.isDeleted} 
                            type='primary' 
                            block={false}
                            style={{ backgroundColor: 'green', borderColor: 'green' }}
                            onClick={() => showPayoutModal(member)}
                        > 
                            Pay 
                        </Button>
                        { member.totalAmountReceivedTillDate > 0 &&
                        <Link to={`./payouts/${member.clientId}`}>
                            <Button type='secondary'> View </Button> 
                        </Link>
                        }
                    </Space>
                )
        }
    ];

    const tableData = [];
    let key = 1;
    membersList.forEach(member => {
        if (member.isDeleted) {
            return; //skip adding deleted members to the list
        }
        let formattedDate = 'N/A';
        if (member.lastInwardTransferAt) {
            formattedDate = moment(member.lastInwardTransferAt.toMillis()).format("DD/MM/YYYY").toString();
        }
        const memberRow = {
            displayName: member.displayName,
            numberOfWithdrawals: member.numberOfWithdrawals ? member.numberOfWithdrawals : 0,
            totalAmountReceivedTillDate: member.totalAmountReceivedTillDate ? (member.totalAmountReceivedTillDate / 100).toFixed(2) : '-',
            lastInwardTransferAt: formattedDate ? formattedDate : '-',
            lastPayoutAmount: member.lastPayoutAmount ? (member.lastPayoutAmount / 100).toFixed(2) : '-',
            clientId: member.clientId,
            key: member.clientId,
            teamName: member.teamName,
        }
        key++;
        tableData.push(memberRow);
    });

    let onClickAddMember = () => {
        setMode('add')
    };

    let onClickModalOk = () => {
        setMode('list');
    }

    let onClickModalCancel = () => {
        setMode('list');
        setErrorAlertMessage(null);
        setSuccessAlertMessage(null);
        setPayoutAmount(0);
    }

    let showPayoutModal = (member) => {
        setMemberBeingPaid(member);
        setMode('payout-load');
    }

    let navigateToMemberPayoutsPage = (member) => {
        setMemberBeingPaid(member);
        setMode('payout-load');
    }

    let onPayoutAmountChange = (number) => {
        setPayoutAmount(number);
    }

    let initiateMemberPayout = async (member) => {
        setErrorAlertMessage(null);
        setSuccessAlertMessage(null);
        if (payoutAmount > teamMainAccount.availableDecimal) {
            setErrorAlertMessage('Payment amount is greater than available balance!');
            return;
        }

        if (payoutAmount <= 0.00) {
            setErrorAlertMessage('Payment amount should be greater than zero!');
            return;
        }

        setProcessingPayoutForMember(true);

        const isLocalHost = window.location.hostname === 'localhost';
        const isTest = isLocalHost;

        const tracker = new TrackerService({
          isTest,
        });

        const data = {
          teamId,
          teamName: member.teamName,
          memberClientId: member.clientId,
          memberDisplayName: member.displayName,
          numberOfWithdrawals: member.numberOfWithdrawals,
          payoutAmount,
        }

        //callDBWithNewTransferTypeWD()
        const db = firebase.firestore();
        try {
            await db.collection('transfers').add({
                fromTeamId: teamId,
                fromClientId: teamDetails.teamClientId,
                toClientId: memberBeingPaid.clientId,
                accountId: teamMainAccount.availableAccountId,
                currency: teamMainAccount.currency,
                payoutAmountCents: Math.round(payoutAmount * 100),
                transferType: 'team-to-member'
            });
            setSuccessAlertMessage('Transfer request successfully placed');

            tracker.mixpanelTrack("transfer_to_team_member", data);
    
            if (!isTest) {
              tracker.googleTrack(
                "transfer_to_team_member", data);
            }
            setProcessingPayoutForMember(false);
        } catch (error) {
            console.log(JSON.stringify(error));
            setErrorAlertMessage('Your request for transfer could not be placed. Please try again');
            setProcessingPayoutForMember(false);
        }
    }

    let onErrorAlertClose = () => {
        setErrorAlertMessage(null);
    }

    let onSuccessAlertClose = () => {
        setSuccessAlertMessage(null);
    }

    return (
        <div style={{marginLeft: '1%'}}>
            <div style={{textAlign: 'left', marginLeft: '9%', height: '0'}}>
                <Space>
                    <>
                        {
                            (mode === 'payout-load') &&
                            <Modal title="Payout to team member"
                                   visible={mode === 'payout-load'}
                                   onOk={() => onClickModalOk()}
                                   onCancel={() => onClickModalCancel()}
                                   footer={null}
                                   bodyStyle={{minHeight: '200px', minWidth: '280px'}}
                            >
                                <div style={{textAlign: 'center'}}>
                                    {(errorAlertMessage) && <Alert message={errorAlertMessage} type="error" closable
                                                                   onClose={() => onErrorAlertClose()}/>}
                                    {(successAlertMessage) &&
                                    <Alert message={successAlertMessage} type="success" closable
                                           onClose={() => onSuccessAlertClose()}/>}

                                    <Card>
                                        <p>You are about to pay <strong>{memberBeingPaid.displayName}</strong></p>
                                    </Card>
                                    <Card>
                                        <p>Enter Amount</p>
                                        <InputNumber style={{marginBottom: '12px'}}
                                                     defaultValue={0}
                                                     precision={2}
                                                     step={0.01}
                                                     onChange={onPayoutAmountChange}
                                        />
                                        <p>
                                            {'Available balance: '}
                                            <strong>
                                            <MoneyTitle currency={teamMainAccount.currency} amount={teamMainAccount.available} digits={2}/>
                                            </strong>
                                        </p>
                                    </Card>
                                    <Button 
                                        style={{marginTop: '15px'}} 
                                        type={'primary'}
                                        onClick={() => initiateMemberPayout(memberBeingPaid)}
                                        disabled={processingPayoutForMember}
                                    >
                                        Initiate Payment
                                    </Button>
                                </div>
                            </Modal>
                        }
                    </>
                </Space>

            </div>
            <Table columns={columns} dataSource={tableData} style={{width: '100%'}} />
        </div>
    );
};

export const TeamActionsButtonsCard = ({
        visible,
        handler,
        locStrings,
        getCurrencyIcon,
        teamMemberLoggedIn,
        isLoggedInMemberAdmin,
        ...rest
    }) => {
    let routes = [];

    const teamMembersRoute = (isLoggedInMemberAdmin || teamMemberLoggedIn && teamMemberLoggedIn.permissions && teamMemberLoggedIn.permissions.canSeeMembers) ?
        [
            {
                icon: <TeamOutlined/>,
                route: 'members',
                text: 'Team Members'
            }
        ]
        : [];

    const teamPayoutsRoute = isLoggedInMemberAdmin || (teamMemberLoggedIn && teamMemberLoggedIn.permissions && teamMemberLoggedIn.permissions.canSeePayouts) ?
        [
            {
                icon: getCurrencyIcon(),
                route: 'payouts',
                text: 'Payouts'
            }
        ]
        : [];

    const teamSettingsRoute = isLoggedInMemberAdmin ?
        [
            {
                icon: <SettingOutlined />,
                route: 'settings',
                text: 'Team Settings'
            }
        ]
        : [];

    const individualsTxnsRoute = isLoggedInMemberAdmin ?
        [
            {
                icon: <UnorderedListOutlined />,
                route: 'member-tips',
                text: "Tip History",
            }
        ]
        : [];

    routes = [
        {
            icon: <QrcodeOutlined />,
            route: 'qrcode',
            text: 'Show Team QR'
        },
        ...teamMembersRoute,
        ...teamPayoutsRoute,
        ...individualsTxnsRoute,
        {
            icon: <WifiOutlined rotate='90' />,
            route: 'tags',
            text: 'All Claimed QRs'
        },
        ...teamSettingsRoute,
    ];

    return visible && (
        <Space>
            <Card align='center' style={{border: 'none'}}>
                <Paragraph>Select an Option</Paragraph>
                <Space direction='vertical' width={'100%'}>
                    {
                        routes.map((v, i) => (
                            <Button
                                key={v.route}
                                icon={v.icon}
                                onClick={() => (handler ? handler(v) : null)}
                                type={(i) ? "secondary" : "primary"}
                                style={{ textAlign: 'start', width: `100%` }}
                            >{v.text}</Button>
                        ))
                    }
                </Space>
            </Card>
        </Space>
    );
};

export const TeamQRCodeImage = ({teamDetails, qrCodeDocument}) => {

    return teamDetails && (
        <Space direction={'vertical'} align={'center'}>

        </Space>
    );
};

export const TeamAccountBalancesView = ({
                                            condition,
                                            locStrings,
                                            client,
                                            clientId,
                                            payouts,
                                            withdrawals,
                                            accounts,
                                            wide,
                                            noButtons,
                                            ...rest
                                        }) => {
    const contentStyle = {
        height: '200px',
        color: '#fff',
        lineHeight: '200px',
        textAlign: 'center',
        background: 'green',
    };


    let element = null;

    const testing = false;
    let datasource = (testing ? datasourceForTesting : accounts) || [];

    const productSwitcher = new ProductSwitcher({locStrings});

    // Filter out accounts with a balance of 0
    //datasource = datasource.filter(account => !!account.total);

    const gridStyle = {
        width: '240px',
        textAlign: 'center',
        padding: 0,
    };

    const cardStyle = {
        textAlign: 'center',
        width: {xs: '100%', sm: '240px', md: '480px', lg: '100%'},
        minWidth: '240px',
        marginLeft: 'auto',
        marginRight: 'auto'
    };

    const rowColAccountElement =
        (<div className="accounts-balances-card" style={{width: '100%', textAlign: 'center'}}>
            <Row justify='center' style={{paddingBottom: '10px'}}>
                {datasource.map((v, i) => (
                    <Col key={i} xs={{span: 24}} sm={{span: 12}} md={{span: 8}} lg={{span: 6}} xl={{span: 6}}
                         xxl={{span: 6}}>
                        <Row gutter={16} key={'row-' + i}>
                            <Col span={0}/>
                            <Col span={24}>
                                <Card style={{textAlign: 'center', padding: '20px', margin: 0}}>
                                    <Statistic
                                        title={`Available ${productSwitcher.getProductMap(v.product).pluralName} (${v.currency})`}
                                        value={((v.available || 0)/100).toFixed(2)}
                                        precision={2}
                                        valueStyle={{color: '#3f8600'}}
                                        prefix={ccy[v.currency]}
                                    />
                                    {(v.pending != null) &&
                                    <div style={{width: '100%'}}>
                                        <Space direction='vertical'>
                                            <Space align='baseline' direction='horizontal'>
                                                <p style={{fontWeight: 'bolder'}}>Pending:</p>
                                                <MoneyTitle amount={v.pending} currency={v.currency} digits={2}/>
                                            </Space>
                                            <Space align='baseline' direction='horizontal'>
                                                <p style={{fontWeight: 'bold'}}>To-date:</p>
                                                <MoneyTitle amount={v.total} currency={v.currency} digits={2}/>
                                            </Space>
                                        </Space>
                                    </div>
                                    }
                                </Card>
                            </Col>
                            <Col span={0}/>
                        </Row>
                    </Col>
                ))
                }
            </Row>
        </div>);

    if (datasource.length > 0) {
        element = rowColAccountElement;
    } else {
        element = null;
    }
    return condition() ? element : null;
};

export const IndividualvsClaimSelector = ({
                                              clientTeams,
                                              client,
                                              setSelectedOption,
                                              claimTypeSelected,
                                              teamIdSelected
                                          }) => {
    
    let onClaimTypeChange = (e) => { 
      let option = e.target.value;
      setSelectedOption({ option });
    }

    let onTeamSelection = (e) => {
        let selectedTeamIdValue = e.target.value;
        let selectedTeamNameValue = e.target['data-teamName'];
        setSelectedOption({option: 'team', teamIdSelected: selectedTeamIdValue, teamNameSelected: selectedTeamNameValue});
    }

    let teamListDisplay = () => {
      return (claimTypeSelected === 'team') ? 'block' : 'none';
    }

    const contentStyle = {
        height: '200px',
        color: '#fff',
        lineHeight: '200px',
        textAlign: 'center',
        background: 'green',
    };

    return <div style={{textAlign: 'center'}}>
        <h3>Claiming Tag for?</h3>
        <Radio.Group 
          onChange={onClaimTypeChange} 
          value={claimTypeSelected}
          style={{marginTop: '20px', marginBottom: '25px'}}>
            <Radio value={'individual'}>Yourself</Radio>
            <Radio value={'team'}>Your team</Radio>
        </Radio.Group>

        {
            <div style={{ width: '100%', textAlign: 'center', display: teamListDisplay(), marginBottom: '20px'}}>
              {clientTeams && clientTeams.length > 0 &&
                <>
                  <h4>Select team</h4>
                  <Radio.Group 
                    onChange={onTeamSelection} 
                    value={teamIdSelected}
                    buttonStyle={'solid'} 
                    buttonColor={'green'}
                    size={'middle'}
                    >
                      <Space direction={'vertical'}>
                        {clientTeams.map((team) => {
                          return <Radio.Button 
                            value={team.teamId}
                            name={team.teamName}
                            key={team.teamId}
                            style={{ width: '100%'}}
                            >
                              {`${team.teamName}`}
                          </Radio.Button>;
                        })}
                      </Space>
                  </Radio.Group>
                </>
              }
            </div>
        }

    </div>;
};

export const MemberTransfersTable = ({condition, locStrings, client, inwardTransfers, ...rest}) => {

    //if( !condition() ) return null;

    const statusColor = {
        'sent': 'green',
        'ready': 'yellow',
        'failed': 'red'
    };

    const dataSource = [];

    const productSwitcher = new ProductSwitcher({locStrings});

    inwardTransfers.map(
        (transferObject, i) => {
            let transfer = transferObject.transfer;
            const formattedDate = moment(transfer.createdAt.toMillis()).format("DD/MM/YYYY").toString();
            const productMap = productSwitcher.getProductMap(transfer.product);
            dataSource.push(
                {
                    key: `transfer-${i}`,
                    date: formattedDate,
                    currency: transfer.currency,
                    value: {currency: transfer.currency, amount: +(transfer.payoutAmountCents)},
                    balance: 0,
                    status: transfer.status,
                    transferUid: transferObject.id
                }
            );
        });


    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render:
                status => (
                    <>
                        <Tag color={statusColor[status]}>{status}</Tag>
                    </>
                )
        },
        {
            title: 'Amount',
            dataIndex: 'value',
            align: 'right',
            render:
                value => (
                    <div>
                        <MoneyTitle amount={value.amount} currency={value.currency} digits={2}/>
                    </div>
                )
        },
    ];

    return (
        <Table
            style={{width: '100%'}}
            dataSource={dataSource}
            columns={columns}
            pagination={{pageSize: 40}} scroll={{y: 300}}
        />);

};

