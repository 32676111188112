import React, { useState, useEffect, useContext } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import {
  QrcodeOutlined,
  SettingOutlined,
  UserAddOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { Link, Redirect } from 'react-router-dom';
import {
  Button,
  Space,
  Card,
  Typography,
  Divider,
  Skeleton,
  Table,
} from 'antd';
import SkeletonButton from 'antd/lib/skeleton/Button';
import moment from 'moment';

import MoneyTitle from '../customer/MoneyTitle';
import { useBatchWithdrawalLinesSummary } from '../../models/useBatchWithdrawalLinesSummary';

import StatusTableRowElement from './StatusTableRowelement';


export const BatchWithdrawalsLoadingCard = ({ loading }) => {
  return loading ? (
    <>
      <Card
        title={<Skeleton active avatar />}
      >
        <Space direction='vertical'>
          <Space direction='horizontal'>
            <SkeletonButton active style={{ width: '100px', height: '44px' }} />
            <SkeletonButton active style={{ width: '100px', height: '44px' }} />
            <SkeletonButton active style={{ width: '100px', height: '44px' }} />
          </Space>
          <Divider />
          <SkeletonButton shape='round' active size='large' />
          <SkeletonButton shape='round' active size='large' />
          <SkeletonButton shape='round' active size='large' />
        </Space>
      </Card>

    </>
  ) : null;

};

const BatchStatusSummary = ({ batchId, currency }) => {
  const data = useBatchWithdrawalLinesSummary(batchId, currency, true);

  return <>
    {!data &&
      <SkeletonButton active style={{ width: '200px', height: '44px' }} />
    }
    { data && data.length > 0 &&
      <Table
        key={`batch-status-summary-${batchId}-${currency}`}
        showHeader={false}
        size='small'
        style={{ padding: '0px', margin: '0px' }}
        dataSource={data}
        columns={[
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'left',
            render: (status) => <Space>
                <StatusTableRowElement status={status} />
                <Typography.Text>{status}</Typography.Text>
              </Space>,
          },
          {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            align: 'right',
          },
          {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right', 
            render: (amount, record) => <MoneyTitle amount={amount} currency={record.currency} digits={2} />,
          },
        ]}
        pagination={false}  
        rowKey={(record) => `bss-table-row-${record.status}-${record.currency}`}
      />
    }
  </>;
};

export const BatchWithdrawalCard = ({ batchWithdrawal, isDetail, compact }) => {
  const {
    batchId,
    status,
    filename,
    directory,
    currency,
    amount,
    amountProcessing,
    amountProcessed,
    countProcessed,
    countProcessing,
    createdAt,
  } = batchWithdrawal;

  const createdDate = createdAt ? moment(createdAt.toDate()).format('lll') : 'N/A';

  const title = <>
    <Space direction='horizontal' align='center'>
      {`Batch ${batchId}`}
    </Space>
  </>;

  let actions = [];

  if (!isDetail) {
    actions = [
      <Link to={`/admin/batches/${batchId}`}>
        Show Details
      </Link>,
    ];
  }

  return (
    <>
      <Card style={{ width: '100%' }} actions={actions} key={`bwc-card-${batchId}`}>
        <Space direction='vertical'>
          <Space direction='horizontal' style={{ fontWeight: 'bold' }}>
            <StatusTableRowElement status={status} />
            <>{createdDate}</>
            <Divider type='vertical' />
            <Typography.Text>{countProcessed || countProcessing || 0} withdrawals</Typography.Text>
            <Divider type='vertical' />
            <MoneyTitle amount={amount || amountProcessed || amountProcessing || 0} currency={currency} digits={2} />
          </Space>
          {batchId && currency && !compact &&
            <BatchStatusSummary batchId={batchId} currency={currency} />
          }
          { !compact &&
          <Typography.Text>Filename: {`${directory || ''}/${filename || ''}`}</Typography.Text>
          }
        </Space>
      </Card>
    </>
  );
};

export const BatchWithdrawalsGroupCard = ({ title, elements, count, truncatedLength, status }) => {
  const [length, setLength] = useState(truncatedLength);
  const viewAllExpandCount = count - truncatedLength;
  const viewAllText = `View All (${viewAllExpandCount})`;

  const totalAmountEUR = elements.reduce((accumulator, currentValue) => {
    return accumulator + (currentValue.currency === 'EUR' ? currentValue.amount || 0 : 0);
  }, 0);
  const totalAmountGBP = elements.reduce((accumulator, currentValue) => {
    return accumulator + (currentValue.currency === 'GBP' ? currentValue.amount || 0 : 0);
  }, 0);

  const titleText = `${title} (${count})`;
  let type = null;
  let compact = false;
  
  switch (status) {
    case 'failed':
      type = 'danger';
      compact = true;
      break;
    case 'paid':
      type = 'success';
      break;
  }

  return <>
    {count > 0 &&
      <>
        {<Space direction='horizontal'>
          <Typography.Title level={3} type={type}>{titleText}</Typography.Title>
          <Divider type='vertical' />
          {/* <div style={{ fontWeight: 'bold' }}>
            <MoneyTitle amount={totalAmountEUR} currency='EUR' digits={2} />
            <Divider type='vertical' />
            <MoneyTitle amount={totalAmountGBP} currency='GBP' digits={2} />
          </div> */}
        </Space>
        }
        {
          elements.slice(0, length).map((batchWithdrawal, index) => {
            return <BatchWithdrawalCard batchWithdrawal={batchWithdrawal} key={index} compact />;
          })
        }
        {
          count > length && count > truncatedLength &&
          <Button type='link' onClick={() => { setLength(count) }}>{viewAllText}</Button>
        }
        {
          count <= length && count > truncatedLength &&
          <Button type='link' onClick={() => { setLength(truncatedLength) }}>Collapse</Button>
        }
        <Divider />
      </>
    }
  </>;

};
