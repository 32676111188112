
export default class ProductSwitcher {
  constructor({ locStrings }) {

    this.keys =
      [
        "tipping",
        "giving",
        "payment",
        "tipping_action",
        "giving_action",
        "payment_action",
        "tipping_instance",
        "giving_instance",
        "payment_instance",
        "tipping_collection",
        "giving_collection",
        "payment_collection",
      ];


    this.products = [
      "tipping",
      "giving",
      "payment"
    ];

    if (locStrings) {
      this.productLabels = {
        "tipping": locStrings.tipping,
        "giving": locStrings.giving,
        "payment": locStrings.payment,
        "tipping_action": locStrings.tipping_action,
        "giving_action": locStrings.giving_action,
        "payment_action": locStrings.payment_action,
        "tipping_instance": locStrings.tipping_instance,
        "giving_instance": locStrings.giving_instance,
        "payment_instance": locStrings.payment_instance,
        "tipping_collection": locStrings.tipping_collection,
        "giving_collection": locStrings.giving_collection,
        "payment_collection": locStrings.payment_collection,
      };
    }

    this.productMap = {
      giving: {
        product: 'Giving',
        heading: "Accept cashless donations anywhere",
        subheading: "Secure. Cost Effective. Simple.",
        subsubheading: "Free to sign up. Someone from the Strikepay team will contact you.",
        label: 'Donation',
        pluralName: 'Donations',
        type: 'donations',
        lower: 'donate',
        upper: 'Donate',
        action: 'Donate to',
        success: 'Successful Donation',
        credit: {
          name: 'Donate',
          color: 'green'
        },
        debit: {
          name: 'Donate',
          color: 'red'
        },
        color: 'black',
        stripe: {
          label: 'Donation',
          button: 'donate'
        },
        button: 'donate',
        again: {
          label: 'Want to give again?',
          button: 'Donate again'
        },
        precision: 0,
        minValue: 1,
        maxValue: 5000,
      },

      tipping: {
        product: 'Tips',
        heading: "Get Cashless Tips",
        subheading: "With an instant QR code",
        subsubheading: "Free to sign up. No app required!",
        label: 'Tip',
        pluralName: 'Tips',
        type: 'tips',
        lower: 'tip',
        upper: 'Tip',
        action: 'Tip',
        success: 'Successful Tip',
        credit: {
          name: 'Tip',
          color: 'green'
        }, debit: {
          name: 'Tip',
          color: 'red'
        },
        color: 'purple',
        stripe: {
          label: 'Tip',
          button: 'default'
        },
        button: 'default',
        again: {
          label: 'Want to tip again?',
          button: 'Tip again'
        },
        precision: 0,
        minValue: 1,
        maxValue: 2500,
      },

      payment: {
        product: 'Payment',
        heading: "Take payments with a QR code",
        subheading: "Fast. Safe. Simple.",
        subsubheading: "Free to sign up. Get paid faster!",
        label: 'Payment',
        pluralName: 'Payments',
        type: 'payments',
        lower: 'pay',
        upper: 'Pay',
        action: 'Pay',
        success: 'Successful Payment',
        credit: {
          name: 'Payment',
          color: 'green'
        },
        debit: {
          name: 'Payment',
          color: 'red'
        },
        color: 'blue',
        stripe: {
          label: 'Payment',
          button: 'default'
        },
        button: 'default',
        again: {
          label: 'Want to pay again?',
          button: 'Pay again'
        },
        precision: 2,
        minValue: 1,
        maxValue: 5000,
      },
    };

  }

  getProductMap(product) {
    const isProduct = this.products.includes(product);
    const productKey = isProduct ? product : 'payment';
    const map = this.productMap[productKey];
    return map;
  }
}

export const lookupStatusDetails = function (status) {
  const statusColor = {
    'successful': 'yellow',
    'created': 'yellow',
    'ready': 'yellow',
    'pending': 'magenta',
    'available_requested': 'black',
    'available': 'green',
    'withdrawn': 'red',
    'sent': 'blue',
    'refunded': 'orange',
    'charged': 'volcano',
    'paid': 'darkgreen',
  };
  const statusName = {
    'successful': 'paid',
    'created': 'creating...',
    'ready': 'checkout',
    'pending': 'pending',
    'available_requested': 'updating...',
    'available': 'available',
    'withdrawn': 'withdrawn',
    'sent': 'successful',
    'refunded': 'refunded',
    'charged': 'charged',
    'waiting': 'waiting',
    'paid': 'paid',
  };
  return {
    statusColor: statusColor[status] || 'grey',
    statusName: statusName[status] || 'started',
  }
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
