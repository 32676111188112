import React, { Component } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import './AdminProfileLayout.css';
import { Layout, Breadcrumb, Button } from 'antd';
import firebase from 'firebase/app';
import {Redirect} from 'react-router-dom';

import StrikeFooter from '../../components/StrikeFooter';
import StrikeAdminModel from '../../models/StrikeAdminModel';
import { AdminMenu } from '../../components/admin/AdminMenu'
import CenteredSpinner from '../../components/CenteredSpinner';

const { Header, Content, Sider } = Layout;

export default class AdminLayout extends Component {

    constructor(props) {
        super(props);
        const clientId = this.props.match.params.clientId;
        this.model = new StrikeAdminModel({viewModel: this});
        this.state = {
            //isClientProfileDisplayScreen flag ensures that the extra client type check is done only for team client profile display screen
            isClientProfileDisplayScreen: props.match && (props.match.path === '/admin/clients/:clientId'),
            clientId: clientId,
            isLoggedInUserAdmin: false,
            checkingAdminUser: true,
            checkingClientType: props.match && (props.match.path === '/admin/clients/:clientId')
        };
    }

    async logOut() {
        await firebase.auth().signOut();
    }

    async componentDidMount() {
        await this.model.waitForAdminCheck();
        if (this.state.isClientProfileDisplayScreen) {
            let teamClientObject = await this.model.waitForClientTypeCheck(this.state.clientId);
            if (teamClientObject.isClientTypeTeam) {
                window.location.href = `/admin/clients/${teamClientObject.teamOwnerClientId}/teams/${teamClientObject.teamId}`;
            } else {
                this.setState({checkingClientType: false});
            }
        }
    }

    componentWillUnmount() {
        if (this.model) this.model.stop();
    }

    setUserRoleInState(isUserAdmin) {
        this.setState({isLoggedInUserAdmin: isUserAdmin});
        this.setState({checkingAdminUser: false});
    }


    render() {
        const crumbs = this.props.crumbs || ['Admin', 'Dashboard'];

        if (this.state.checkingAdminUser || this.state.checkingClientType) {
            return <CenteredSpinner loading={this.state.checkingAdminUser}/>
        }

        if (!this.state.isLoggedInUserAdmin) {
            return <Redirect to="/"/>
        }
        return (
            <Layout className="layout">
                <Sider collapsible collapsedWidth={0} style={{backgroundColor: 'white'}} breakpoint='lg'>
                    <AdminMenu/>
                </Sider>
                <Layout>
                    <Header className="top" style={{textAlign: 'start', backgroundColor: '#000098bb'}}>
                        {/* <Link to='/admin/dashboard'><img src={img_elStrikeLogoImage} style={{ height: "50px" }} alt='Home' /></Link> */}
                        <Button style={{color: '#fff', cursor: 'select'}} className="log-out" type="text"
                                onClick={this.logOut}>Log out</Button>
                    </Header>
                    <Content style={{padding: '0 0px', minWidth: '300px', width: '100%'}}>
                        <div className="site-layout-content">
                            {this.props.children}
                        </div>
                    </Content>
                    <StrikeFooter locStrings={this.props.locStrings}/>
                </Layout>
            </Layout>
        );
    }
}
