import { changeConfirmLocale } from 'antd/lib/modal/locale';
import firebase from 'firebase/app';

import image_default_avatar from '../images/Strike_Symbol_RGB_White_Magenta.jpg';


export default class StrikeTagsModel {

  constructor({ viewModel, clientId, teamId }) {
    this.viewModel = viewModel;
    this.clientId = clientId;
    this.teamId = teamId;
    this.tags = [];
    this.client = null;
    this.tagsById = {};
  }

  getQueryForTagsForTeam(params) {
    const {
      teamId
    } = params;

    const db = firebase.firestore();
    return db.collection('tags').where("teamId", "==", teamId).orderBy('updatedAt', 'desc');
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if(this.observerTags) {
      this.observerTags();
      this.observerTags = null;
    }
  }

  async fetchTagData() {
    if (this.tagId === null) return null;

    const db = firebase.firestore();
    const query = db.collection('tags').doc(this.tagId);
    this.tag = null;

    this.observerTag = query.onSnapshot(tagDoc => {
      this.tag = null;

      if (tagDoc.exists) {
        const data = tagDoc.data();
        const id = tagDoc.id;
        const full_data = { ...data, id };
        this.tag = full_data;
        this.updateDataSource();
      } else {
        this.tag = null;
        this.updateDataSource();
      }
    }, err => {
      this.tag = null;
      this.updateDataSource();
      console.log(`Tags query error: ${err}`);
    });
  }

  async fetchAllTagsData()  {
    if (this.teamId === null) return;

    const teamId = this.teamId;

    this.stop();

    const db = firebase.firestore();
    this.tags = [];

    const query = this.getQueryForTagsForTeam({
      teamId
    });

    this.observerTags = query.onSnapshot(querySnapshot => {
      let index = querySnapshot.size;
      this.tags = [];
      if(index === 0) this.updateDataSource();

      querySnapshot.docChanges().forEach(
        change => {
          if(change.type === 'added' || change.type === 'modified') {
            const doc = change.doc.data();
            const id = change.doc.id;
            const full_doc = { ...doc, id };
            this.tagsById[id] = full_doc;
            this.updateDataSource();
          }

          if(change.type === 'removed' )  {
            const id = change.doc.id;
            this.tagsById[id] = null;
            this.updateDataSource();
          }
        }
      );
    }, err => {
      this.tags = [];
      this.tagsById = {};
      this.updateDataSource();
      console.log(`Team Tags query error: ${err}`);
    });


  }

  async updateTagWithData(data) {
    if (this.tagId && data) {
      const db = firebase.firestore();
      const query = db.collection('tags').doc(this.tagId);
      return await query.set({
        ...data,
        updatedAt:  firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: this.currentUser,
      }, {
        merge: true
      });
    } else {
      return null;
    }
  }

  async updateDataSource() {

    this.tags = [];

    for(let prop in this.tagsById) {
      this.tags.push(this.tagsById[prop]);  
    }

    this.viewModel.updatedTagsDataSource(this.tags);
  }

}
