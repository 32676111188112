import React, { Component } from 'react';
import '../../App.scss';
import Rating from 'react-rating';
import firebase from 'firebase/app';

export default class RatingPanel extends Component {

  // Properties used by this component:
  // rating, countOfTips, averageRating

  constructor(props) {
    super(props);
    
    this.state = {
      rating: props.tap.rating,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  valueChanged_rating = (rating) => {
    this.setState({rating: rating});
 
    let db = firebase.firestore();
    db.collection('tags').doc(this.props.tap.tagId).collection('taps').doc(this.props.tapId).update({
      rating: rating,
    });
  }

  
  
  render() {
    const style_elAvgRatingTitleText = {
      fontSize: 13.3,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const value_avgRatingText = this.props.tap.averageRating;
    
    const style_elAvgRatingText = {
      fontSize: 11.4,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elCountOfStrikesTitleText = {
      fontSize: 13.3,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const value_countOfStrikesText = this.props.tap.countOfTips;
    
    const style_elCountOfStrikesText = {
      fontSize: 11.4,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elRating = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="RatingPanel">
        <div className="layoutFlow">
          <div className="flowRow flowRow_RatingPanel_elAvgRatingTitleText_463032">
            <div className="half-tile">
              <div className="elAvgRatingTitleText">
                <div className="systemFontBold" style={style_elAvgRatingTitleText}>
                  <div>{this.props.locStrings.ratingpanel_text_463032}</div>
                </div>
              </div>
              
              <div className="elAvgRatingText">
                <div className="systemFontRegular" style={style_elAvgRatingText}>
                  <div>{value_avgRatingText !== undefined ? value_avgRatingText : (<span className="propValueMissing">{this.props.locStrings.ratingpanel_avgratingtitletextcopy_212403}</span>)}</div>
                </div>
              </div>
            </div>

            <div className="half-tile">
              <div className="elCountOfStrikesTitleText">
                <div className="systemFontBold" style={style_elCountOfStrikesTitleText}>
                  <div>{this.props.locStrings.ratingpanel_avgratingtitletextcopy_614032}</div>
                </div>
              </div>
              
              <div className="elCountOfStrikesText">
                <div className="systemFontRegular" style={style_elCountOfStrikesText}>
                  <div>{value_countOfStrikesText !== undefined ? value_countOfStrikesText : (<span className="propValueMissing">{this.props.locStrings.ratingpanel_countofstrikescopy_473531}</span>)}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="elRating">
            <div style={style_elRating}>
              <Rating readonly={false} start={0} stop={5} initialRating={parseInt(this.state.rating)} onChange={this.valueChanged_rating} fullSymbol={<div style={{fontSize:18, width:20, height:20}}>★</div>} emptySymbol={<div style={{fontSize:17, width:20, height:20}}>☆</div>} />
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
