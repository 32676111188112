import React, { useState } from 'react';
import { Button } from 'antd';

import { StrikeColors } from '../../utils/Colors';
import MoneyJarService from '../../utils/MoneyJarService';

import AddPayoutMoneyJarButton from './AddPayoutMoneyJarButton';
import { ClientPartnerConnectedCard } from './ClaimTagSuccessCard';
import { useClientDetails } from './useClientDetails';
import RedirectAndScrollTop from './RedirectAndScrollTop';

const PreInstallView = ({ payout, clientId, onClickButton }) => {
  const moneyjarService = new MoneyJarService(payout);
  const buttonDetails = moneyjarService.getActionButtonDetails();
  const visible = !moneyjarService.isPayoutActive();
  const disbaled = moneyjarService.isActionDisbaled();

  return <>
    {visible && clientId &&
      <>
        <h2>Sign up to MoneyJar</h2>
        <h5>We have partnered with MoneyJar to offer instant cash out.</h5>
        <p style={{ fontWeight: 'normal' }}>You will need to sign up for a MoneyJar account. There are additional fees to use MoneyJar.</p>
        <AddPayoutMoneyJarButton
          clientId={clientId}
          onClick={onClickButton}
          disabled={disbaled}
          {...buttonDetails}
        />
      </>
    }
  </>;

};


const PostInstallView = ({ payout, clientId, onClickButton }) => {
  const [shouldRedirect, setRedirect] = useState(false);
  const isLocalHost = window.location.hostname === 'localhost';
  const isTest = isLocalHost;
  const link = isTest ? 'http://localhost:3000' : '/';
  const moneyjarService = new MoneyJarService(payout);
  const buttonDetails = moneyjarService.getActionButtonDetails();
  const visible = moneyjarService.isPayoutActive();
  const disbaled = moneyjarService.isActionDisbaled();

  const onClickGotoDashboard = () => {
    setRedirect(true);
  };

  return <>

    {link && shouldRedirect &&
      <RedirectAndScrollTop
        when={shouldRedirect}
        to={link}
      />
    }

    {!shouldRedirect && visible && clientId &&
      <>
        <h2 style={{ color: StrikeColors.magenta }}>Congrats!</h2>
        <h5>You now have instant cash out with MoneyJar.</h5>
        <p>Got to your Dashboard and tap on 'Cash Out' and select 'MoneyJar Instant'.</p>
        <Button type='primary' onClick={onClickGotoDashboard}>Go to Dashboard</Button>
        <h5>You can view to your MoneyJar app by tapping this button.</h5>
        <AddPayoutMoneyJarButton
          clientId={clientId}
          onClick={onClickButton}
          disabled={disbaled}
          {...buttonDetails}
        />
      </>
    }
  </>;

};



const MoneyJarPayoutView = ({ clientId, payoutId, payout, onClick }) => {
  const [shouldRedirect, setRedirect] = useState(false);
  const isLocalHost = window.location.hostname === 'localhost';
  const isTest = isLocalHost;
  const moneyjarService = new MoneyJarService(payout);
  const link = moneyjarService.getInstallLink();
  const isMoneyJarPayout = moneyjarService.isMoneyJarPayout();
  const client = useClientDetails(clientId);
  const provider = useClientDetails(MoneyJarService.moneyJarClientId);

  const onClickButton = async () => {
    if(onClick) {
      await onClick(payout);
      setRedirect(true);
    }
  };

  return isMoneyJarPayout ? (
      <div style={{  textAlign: 'center' }}>
        { link && shouldRedirect &&
          <RedirectAndScrollTop 
            when={shouldRedirect}
            to={link}
          />
        }
        { client && provider &&
          <ClientPartnerConnectedCard 
            client={client}
            partner={provider}
          />
        }
        { clientId && client && payout &&
          <PreInstallView clientId={clientId} payout={payout} onClickButton={onClickButton}/>
        }
        { clientId && client && payout &&
          <PostInstallView clientId={clientId} payout={payout} onClickButton={onClickButton}/>
        }
      </div>
  ) : null;
};

export default MoneyJarPayoutView;
