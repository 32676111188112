import path from 'path';

import React, { Component } from 'react';
import { Button, message, Modal, PageHeader, Upload } from 'antd';
import { Redirect } from 'react-router-dom';
import {
    DollarOutlined,
    EuroOutlined,
    EuroCircleOutlined,
    PoundOutlined,
    LoadingOutlined,
    PlusOutlined
} from '@ant-design/icons';
import ReactCrop from 'react-image-crop';
import firebase from 'firebase';

import Spinner from '../../../components/Spinner';
import StrikeTeamDetailsModel from '../../../models/StrikeTeamDetailsModel';
import { EditTeamForm } from '../../../components/client/EditTeamForm';
import getBase64 from '../../../utils/GetBase64';

import 'react-image-crop/dist/ReactCrop.css';
import '../../../App.scss';
import 'antd/dist/antd.css';

export default class TeamSettingsScreen extends Component {
    constructor(props) {
        super(props);

        const clientId = this.props.match.params.clientId;
        const teamId = this.props.match.params.teamId;

        this.baseURLs = props.baseURLs || {
            teamDetails: `/client/manage/${clientId}/teams/${teamId}`
        };

        this.model = new StrikeTeamDetailsModel({viewModel: this, teamId});

        this.state = {
            teamId: teamId,
            teamDetails: null,
            loading: true,
            route: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 1 / 1,
            },
        };
    }

    internalSave = async (data) => {
        try {
            await this.model.updateExistingTeam(data);
            if (this.state.base64Image) {
                await this.uploadTopLogo(this.props.match.params.teamId);
            }
            message.success('Changes saved successfully');
        } catch (error) {
            message.error('Changes could not be saved' + JSON.stringify(error));
        }

    }

    onClickSave = (data) => {
        this.internalSave(data);
    }

    componentDidMount() {
        this.model.waitForTeamDetails({ fetchTeamClient: false });
    }

    componentWillUnmount() {
        if (this.model) this.model.stop();
    }

    isLoading = () => {
        return this.state.loading || false;
    }

    setTeamDetailsOnPage = (teamDetails, teamMembersList) => {
        let filteredTeamMember = teamMembersList.filter(member => member.clientId === this.state.memberClientId);
        this.setState({
            teamDetails,
            teamMembersList,
            teamMemberLoggedIn: filteredTeamMember[0],
            isLoggedInMemberAdmin: filteredTeamMember[0] ? (filteredTeamMember[0].roles[0] === 'admin' || filteredTeamMember[0].permissions.isTeamAdmin) : [],
            loading: false
        });
    }

    renderRedirect = () => {
        const {route} = this.state;
        const clientId = this.props.match.params.clientId;
        const teamId = this.props.match.params.teamId;
        if (route && clientId && teamId && this.baseURLs.manage) {
            const toRoute = `${this.baseURLs.manage}/${clientId}/teams/${teamId}/${route}`;
            return <Redirect push to={toRoute}/>
        } else if (route && clientId && teamId && this.baseURLs.teamDetails) {
            const toRoute = `${this.baseURLs.teamDetails}/${route}`;
            return <Redirect push to={toRoute}/>
        } else {
            return null;
        }
    }

    getCurrencyIcon = () => {
        if (this.state.teamDetails.defaultCurrency) {
            switch (this.state.teamDetails.defaultCurrency) {
                case 'EUR':
                    return <EuroOutlined style={{fontSize: '17px', marginTop: '2px'}}/>;
                case 'USD':
                    return (<DollarOutlined style={{fontSize: '17px', marginTop: '2px'}}/>);
                case 'GBP':
                    return (<PoundOutlined style={{fontSize: '17px', marginTop: '2px'}}/>);
                default:
                    return <EuroOutlined style={{fontSize: '17px', marginTop: '2px'}}/>;
            }
        } else {
            return (<EuroCircleOutlined style={{fontSize: '17px', marginTop: '2px'}}/>);
        }
    }

    handleOk = async () => {
        await this.saveImageLocally(this.state.croppedImage);
        this.setState({visible: false});
    };

    handleCancel = () => {
        this.setState({visible: false});
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({crop});
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImage = await this.getCroppedImg(
                this.imageRef,
                crop,
            );
            this.setState({croppedImage});
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    getCroppedImg(image, crop) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = crop.width;
        canvas.height = crop.height;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );


        const timestamp = Date.now();
        const fileName = `${timestamp}.jpg`;
        this.setState({fileName});

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                resolve(blob);
            }, 'image/jpeg');
        });
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({uploading: true});
            return;
        }

        let fileExtn = path.extname(info.file.name).toLowerCase();
        this.setState({fileExtn});
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, base64Image =>
                this.setState({
                    base64Image,
                    uploading: false,
                }),
            );
        }
    };


    uploadTopLogo = async (teamId) => {
        const storageRef = firebase.storage().ref();

        const fileName = teamId + '-topLogo' + (this.state.fileExtn ? this.state.fileExtn : '.jpg');
        const teamImagesRef = storageRef.child(`public-images/team-images/${fileName}`);

        const resultSnapshot = await teamImagesRef.putString(this.state.base64Image, 'data_url', {contentType: 'image/png'});
        const topLogoUrl = await resultSnapshot.ref.getDownloadURL();

        const db = firebase.firestore();

        await db.collection('teams').doc(teamId).update({
            topLogo: topLogoUrl
        })
    }

    saveImageLocally = async (file) => {
        getBase64(file, base64Image =>
            this.setState({
                base64Image
            })
        );
    }

    beforeUpload = (file) => {
        this.setState({visible: true, src: URL.createObjectURL(file)});

        // return false to not have it trigger upload, we want the file but no the actual uploading
        return false;
    }

    getImageDisplayed = () => {
        if (this.state.base64Image) {
            return this.state.base64Image
        } else if (this.state.teamDetails && this.state.teamDetails.topLogo) {
            return this.state.teamDetails.topLogo;
        } else {
            return null;
        }
    }

    render() {
        const redirected = this.renderRedirect();
        if (redirected) {
            return redirected;
        }

        const { visible, loading } = this.state;

        if (!this.state.loading && this.state.teamDetails) {
            const { uploading } = this.state;

            const uploadButton = (
                <div>
                    {uploading ? <LoadingOutlined/> : <PlusOutlined/>}
                    <div style={{marginTop: 8}}>Load Team Logo</div>
                </div>
            );
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`${this.state.teamDetails.teamName}`}
                        onBack={(() => window.history.back())}
                    />
                    <div style={{marginLeft: "auto", marginRight: 'auto', width: "intrinsic", marginBottom: "10px"}}>
                        <Upload
                            name="topLogo"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={this.saveImageLocally}
                            beforeUpload={this.beforeUpload}
                            onChange={this.handleChange}
                            disabled={!this.state.isLoggedInMemberAdmin}
                        >
                            {this.getImageDisplayed() ? <img src={this.getImageDisplayed()} alt="Team Logo" style={{
                                width: '104px',
                                height: '104px',
                                borderRadius: '100%'
                            }}/> : uploadButton}
                        </Upload>
                    </div>

                    <Modal
                        visible={visible}
                        title="Crop your image"
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            <Button key="back" onClick={this.handleCancel}>
                                Cancel
                            </Button>,
                            <Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
                                Confirm
                            </Button>,
                        ]}
                    >
                        <div className="cropper">
                            {this.state.src && (
                                <ReactCrop
                                    src={this.state.src}
                                    crop={this.state.crop}
                                    ruleOfThirds
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                />
                            )}
                        </div>

                    </Modal>

                    <EditTeamForm
                        teamId={this.state.teamId}
                        team={this.state.teamDetails}
                        callback={this.onClickSave}
                        edit={true}
                        teamMemberLoggedIn={this.state.teamMemberLoggedIn}
                        isLoggedInMemberAdmin={this.state.isLoggedInMemberAdmin}
                    />
                </div>
            );
        } else {
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`Team Details`}
                        onBack={(() => window.history.back())}
                    />
                    <Spinner condition={() => { return this.isLoading() }} />
                </div>
            );
        }
    }
}

