import { useState, useEffect } from 'react';

import BatchWithdrawalsModel from './BatchWithdrawalsModel';

export function useBatchWithdrawals() {
    const [batchWithdrawals, setBatchWithdrawals] = useState(null);
    const isAdmin = true;

    useEffect(() => {
        const onUpdate = async (list) => {
            setBatchWithdrawals(list);
        };


        const model = new BatchWithdrawalsModel({
            isAdmin,
            onUpdate,
            limit: 100,
        });

        model.start();

        return function cleanup() {
            model.stop();
        };

    },
        []
    );

    return batchWithdrawals;
}
