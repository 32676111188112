import React, {Component} from 'react';
import qs from 'qs';
import {Typograph, PageHeader, Steps, Result} from 'antd';
import {AuditOutlined} from '@ant-design/icons';

import StrikeClientModel from '../../../models/StrikeClientModel';
import Spinner from '../../../components/Spinner';

import '../../../App.scss';
import 'antd/dist/antd.css';
import 'react-image-crop/dist/ReactCrop.css';

const {Step} = Steps;

export default class IDVerificationScreen extends Component {

    constructor(props) {
        super(props);

        const clientId = this.props.match.params.clientId;
        const isAdmin = !!props.isAdmin;

        const parsed = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
        const mode = (parsed.mode) ? 'editing' : 'viewing';

        this.model = new StrikeClientModel({viewModel: this, clientId, isAdmin});

        this.state = {
            user: null,
            client: null,
            clientId: clientId,
            idVerificationUuid: null,
            idVerificationDocData: null,
            loading: true,
            mode,
            currentVerificationStep: 0,
            steps: []
        };
    }

    beforeUpload = (file) => {
        this.setState({visible: true, src: URL.createObjectURL(file)});

        // return false to not have it trigger upload, we want the file but no the actual uploading
        return false;
    }

    receiveMessage = (event) => {
// Only allow messages from webapp
        if (event.origin !== "https://websubmission.client.id-pal.com") {
            return;
        }
        if (event.data === "idpal_success") {
            this.setState(
                (state) => {
                    state.client.idVerificationStatus = 'submitted';
                    return {
                        client: state.client,
                        loading: false
                    }
                }
            );
        }
    }

    componentDidMount() {
        this.model.waitForClientData();
        window.addEventListener("message", this.receiveMessage, true);
    }


    componentWillUnmount() {
        if (this.model) this.model.stop();
    }

    componentDidUpdate() {
    }

    isLoading = () => {
        return this.state.loading || false;
    }

    getRejectedSteps = () => {
        if(!this.state.idVerificationDocData){
            return []; //Client rejected before any step in his verification journey can be completed
        }

        let stepsTillRejection = [];
        if(this.state.idVerificationDocData.uuidGeneratedAt){
            stepsTillRejection.push({
                title: 'Upload Documents'
            });
        }

        if(this.state.idVerificationDocData.submittedAt){
            stepsTillRejection.push({
                title: 'Processing'
            });
        }

        return stepsTillRejection;
    }

    foundClient = async (client) => {
        if(client) {
            if (client.idVerificationStatus === null || client.idVerificationStatus === 'uuid-generated') {
                this.setState({
                    steps: this.initialSteps,
                    currentVerificationStep: 0
                });
            } else if (client.idVerificationStatus === 'submitted') {
                this.setState({
                    steps: this.initialSteps,
                    currentVerificationStep: 1
                });
            } else if(client.idVerificationStatus === 'verified'){
                const steps = [...this.sureSteps, {title: 'Verified Successfully', status: 'finish'}];
                this.setState({
                    steps: steps,
                    currentVerificationStep: 2
                });
            } else if(client.idVerificationStatus === 'rejected'){
                let rejectedSteps = this.getRejectedSteps(client);
                const steps = [...rejectedSteps, {title: 'Rejected', status: 'error'}];
                this.setState({
                    steps: steps,
                    currentVerificationStep: rejectedSteps.length
                });
            }
        }

        if (!client.idVerificationAllowed) {
            this.props.history.push("/client/login");
        }
        this.setState(
            (state) => {
                return {
                    client,
                    loading: false,
                }
            }
        );

        if (client) {
            this.setState({
                loading: true
            })
            this.model.fetchVerificationData(this.state.clientId, client);
            this.setState({
                loading: false
            })
        }

    }

    updateVerificationData = ({uuid, idVerificationDocData}) => {
        this.setState({idVerificationUuid: uuid, loading: false, idVerificationDocData: idVerificationDocData});
    }

    initialSteps = [
        {
            title: 'Upload Documents'
        },
        {
            title: 'Processing'
        },
        {
            title: 'Verification Status'
        }
    ];

    sureSteps = [
        {
            title: 'Upload Documents'
        },
        {
            title: 'Processing'
        }
    ];


    getSteps = () => {
        return (
            <div style={{textAlign: 'initial', marginBottom: '30px', marginLeft: `${(3-this.state.steps.length) * 22}` + '%' }}>
                <Steps current={this.state.currentVerificationStep} labelPlacement={'vertical'}
                       style={{maxWidth: '100%', marginTop: '20px', marginLeft: '0', paddingRight: '8px'}} size={'default'}>
                    {this.state.steps.map(item => (
                        <Step key={item.title} title={item.title} status={item.status}/>
                    ))}
                </Steps>
            </div>
        );
    }

    getContent = () => {
        if (!this.state.client) {
            return <span></span>
        }

        if (this.state.client.idVerificationStatus === null || this.state.client.idVerificationStatus) {
            const idVerStatus = this.state.client.idVerificationStatus;

            if (['uuid-generated'].includes(idVerStatus)) {
                return (
                    <div style={{height: '100%', textAlign: 'center'}}>
                        <iframe
                            src={`https://websubmission.client.id-pal.com/?lang=en&uuid=${this.state.idVerificationUuid}&disableheader=true`}
                            width={'100%'}
                            height={'600px'}
                            style={{border: 'none'}}/>
                    </div>
                );
            } else if (idVerStatus === 'submitted') {
                return <Result status="success" icon={<AuditOutlined />} title="We are verifying your documents!"/>
            } else if (idVerStatus === 'verified') {
                return <Result status="success" title="Your Identity Verification is successful!"/>
            }  else if (idVerStatus === 'rejected') {
                return <Result status="error" title="Sorry, your Identity Verification is rejected!"/>
            }


        }
    }

    render() {
        return <>
            <PageHeader
            ghost={false}
            title={`ID Verification`}
            onBack={(() => window.history.back())}
        />
            <Spinner condition={() => { return this.isLoading() }} />
            {this.state.client && this.getSteps(this.state.client.idVerificationStatus)}
            {this.getContent()}
        </>
    }
}

