import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Alert, PageHeader, Tag, message , Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import qs from 'qs';

import Spinner from '../../../components/Spinner';
import { BankAccountsList } from '../../../components/client/BankAccountComponents';
import { EditBankAccountForm } from '../../../components/client/EditBankAccountForm';
import StrikeClientModel from '../../../models/StrikeClientModel';
import BankAccountsModel from '../../../models/BankAccountsModel';
import {isBankUnique} from '../../../utils/PayoutUtils';
import FeatureFlagService from '../../../utils/FeatureFlagService';
import MoneyJarService from '../../../utils/MoneyJarService';
import RedirectAndScrollTop from '../../../components/client/RedirectAndScrollTop';


function bankFilter(payout, isAdmin) {
  let isIncluded = false;
  const { hide, status, provider } = payout;
  const stripeStatuses = ['live', 'retry', 'waiting'];
  const moneyjarStatuses = ['live'];

  switch (provider) {
    case 'stripeus':
      isIncluded = (stripeStatuses.includes(status) || isAdmin || !hide);
      break;
    case 'moneyjar':
      isIncluded = (moneyjarStatuses.includes(status) || isAdmin || !hide);
      break;
    default:
      isIncluded = (isAdmin || !hide);
      break;
  }
  return isIncluded;
}

const limitedCountries = ['US']; // MARK: USD limited USD countries

// /client/manage/profile
export default class BankClientProfileScreen extends Component {
  // Properties used by this component:

  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    const isAdmin = !!props.isAdmin;
    this.clientModel = new StrikeClientModel({ viewModel: this, clientId, isAdmin });
    this.model = new BankAccountsModel({ viewModel: this, clientId, filter: bankFilter });

    this.state = {
      isEditing: false,
      bankSelected: null,
      clientId: clientId,
      client: null,
      loading: true,
      loadingPayouts: true,
      clientNotFound: false,
      mode: 'viewing',
    };
  }

  getErrorMessageForErrorCode(error) {
    if (error) {
      if (error === '1') {
        return 'We haven’t been able to authenticate with your bank. Please check your details and try again';
      } else if (error === '2') {
        return 'Something has gone wrong. Please add your bank details manually below, or try again later'
      } else if (error === '3') {
        return 'You have already added this bank account';
      }
    }
    return 'Something has gone wrong. Please add your bank details manually below, or try again later'
  }

  componentDidMount() {
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (queryParams.errorCode){
      message.error(this.getErrorMessageForErrorCode(queryParams.errorCode), 7);
    } else if(queryParams.trueLayerSuccess){
      message.success('Bank account added successfully', 7);
    }
    this.clientModel.waitForClientData();
  }

  componentWillUnmount() {
    if(this.clientModel) this.clientModel.stop();
    if(this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || false;
  }


  foundClient = async (client) => {
    this.setState(
      (state) => {
        return {
          client,
          loading: false,
          loadingPayouts: true,
        }
      }
    );

    this.model.fetchAllPayoutsData(this.clientModel.isAdmin);
  }

  updatedPayoutsDataSource = (payouts) => {
    this.setState(
      (state) => {
        return {
          payouts,
          loadingPayouts: false,
        }
      }
    );

  }

  clientNotFound = () => {
    this.setState(
      (state) => {
        return {
          loading: false,
          clientNotFound: true,
        }
      }
    );
  }

  changeMode = (mode) => {
    if (mode === 'viewing') {
      return 'adding'
    } else if (mode === 'adding') {
      return 'saving'
    } else {
      return 'viewing';
    }
  }


  onClickAdd = () => {
    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    }
    );
  };

  onClickEdit = (payoutId) => {
      const bank = this.model.payouts.filter((payout) => { return (payout.id === payoutId) })[0];
      this.setState((state) => {
        return {
          isEditing: true,
          bankSelected: bank,
          mode: this.changeMode(state.mode),
        }
      });
  };

  onClickDelete = (payoutId, hide) => {
    if (this.clientModel.isAdmin) {
      this.model.markPayoutHidden(payoutId, hide);
    }
  };

  doneSaving = (payoutId, data) => {
    let redirectToPayoutId = null;
    if(payoutId && data) {
      const payout = { ...data, uid: payoutId }
      const moneyjarService = new MoneyJarService(payout);
      const isMoneyJarPayout = moneyjarService.isMoneyJarPayout();
      redirectToPayoutId = isMoneyJarPayout ? payoutId : null;
    }
    
    this.setState((state) => {
      return {
        bankSelected: null,
        isEditing: false,
        mode: this.changeMode(state.mode),
        redirectToPayoutId,
      }
    }
    );

    this.clientModel.waitForClientData();
  }

  internalSave = async (data) => {
    let payoutId = null;
    if (data) {
      data.source = 'manual';
      data.isCompany = !!data.isCompany;
      data.descriptor = data.descriptor || '';
    }
    if (this.state.isEditing) {
      payoutId = this.state && this.state.bankSelected && this.state.bankSelected.id;
      await this.model.updateExistingPayout(payoutId, data);
    } else {
      payoutId = await this.model.makeNewPayoutObject(data);
    }
    this.doneSaving(payoutId, data);
  }

  onClickSave = async(data) => {
    const bankUnique = isBankUnique(data, this.state.payouts);
    const allowSave = this.state.isEditing || bankUnique;

    if (allowSave) {
      this.setState((state) => {
        return {
          mode: this.changeMode(state.mode),
        }
      });

      this.internalSave(data);
    } else {
      this.setState({duplicateBankAcc: true})
    }
  }

  resetDuplicateBankAccError = () => {
    this.setState({duplicateBankAcc: false})
  }

  onClickCancel = () => {
    this.setState((state) => {
      return {
        bankSelected: null,
        isEditing: false,
        mode: 'viewing',
      }
    }
    );
  }

  getButtons = () => {
    if (this.state.mode === 'viewing') {
      return [
        <Button key="1" type="text" shape='circle' icon={<PlusCircleOutlined style={{ fontSize: '28px', color :'#070'}}/>} onClick={this.onClickAdd} />
      ]
    } else if (this.state.mode === 'adding') {
      return [
        <Button key="1" type="danger" onClick={this.onClickCancel}>
          Cancel
        </Button>,
      ];
    } else {
      return [];
    }
  }

  render() {
    const shouldNotUseTrueLayer = this.state.client && limitedCountries.includes(this.state.client.country);
    const trueLayerAttemptedAt = this.state.client && !!this.state.client.trueLayerAttemptedAt;
    const isManualEnabled = shouldNotUseTrueLayer || trueLayerAttemptedAt;
    const clientId = this.state.clientId;
    const isEditing = this.state.isEditing;
    const bankSelected = this.state.bankSelected;
    const redirectToPayoutId = this.state.redirectToPayoutId;

    if(redirectToPayoutId) {
      return (
        <RedirectAndScrollTop 
          to={`/${redirectToPayoutId}`}
          when={redirectToPayoutId}
        />
      );
    } else if (this.state.mode === 'viewing' && !this.state.loading && this.state.client ) {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Your Money`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'adding' ? <Tag color="blue">Adding</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
          <Spinner condition={() => { return this.isLoading() }} />
          <BankAccountsList
            isAdmin={this.clientModel.isAdmin}
            condition={() => { return !this.state.loadingPayouts }}
            data={this.state.payouts}
            onAddAccount={this.onClickAdd}
            onEditAccount={(payout) => { this.onClickEdit(payout) }}
            onDeleteAccount={(payout, hide) => { this.onClickDelete(payout, hide) }}
          />
        </div>
      );
    } else if (!this.state.loading) {
      return (
        <div style={{ height: '100%' }}>
          { this.state.duplicateBankAcc &&
              <Alert
                message="Error"
                description="You have already added this bank account"
                type="error"
                showIcon
                closable
                afterClose={this.resetDuplicateBankAccError}
              />
          }
          <PageHeader
            ghost={false}
            title={isEditing ? `Edit Bank Account` : `Add Bank Account`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'adding' ? null : <Tag color="green">Saving...</Tag>)}
            extra={this.getButtons()}
          />
          <EditBankAccountForm
            bank={bankSelected}
            edit={isEditing}
            callback={this.onClickSave}
            trueLayerAttemptedAt={isManualEnabled}
            isTrueLayerEnabled={!shouldNotUseTrueLayer}
            clientId={clientId}
            payouts={this.state.payouts}
            client={this.state.client}
          />
        </div>
      );
    } else {
      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`Your Money`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Add new</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
        </div>
      );
    }


  }
}

