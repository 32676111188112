import firebase from 'firebase/app';

import image_default_avatar from '../images/Strike_Symbol_RGB_White_Magenta.jpg';

const isLocalHost = window.location.hostname === 'localhost';

export default class StrikeTeamAccountTransactionsModel {

    constructor({callback, viewModel, clientId, includeTestAccounts}) {
        this.productsSupportedForTeams = ['tipping', 'payment'];
        this.callback = callback;
        this.viewModel = viewModel;
        this.clientId = clientId;
        this.uid = null;
        this.accounts = [];
        this.transactions = [];
        this.includeTestAccounts = includeTestAccounts || isLocalHost; // MARK: Show test and live accounts on lcoalhost
        this.observerTransactions = [];

        this.transactionsById = {};
    }

    stop() {
        // stop all listeners.,.not sure they get droppped when object drops out of scope.
        this.viewModel = null;
        if (this.observerAccounts) {
            this.observerAccounts();
            this.observerAccounts = null;
        }
    }

    waitForAccountsData = () => {
        if (this.clientId === null) return;

        const clientId = this.clientId

        if (this.observerAccounts) {
            this.observerAccounts();
            this.observerAccounts = null;
        }

        const db = firebase.firestore();
        const preQuery = db.collection('clients')
                        .doc(clientId)
                        .collection('accounts')
                        .where('product', 'in', this.productsSupportedForTeams)
                        .where('version', '==', '2');
        const query = (this.includeTestAccounts) ? preQuery : preQuery.where('is_test', '==', false); 

        this.promisesAll = [];
        this.accounts = [];
        this.accountIds = [];
        this.transactions = [];

        this.observerAccounts = query.onSnapshot(querySnapshot => {
            this.accounts = [];
            this.accountIds = [];

            querySnapshot.forEach(
                doc => {
                    let account = doc.data();

                    if (account.type !== 'withdrawn') {
                        account.decimalBalance = account.balance / 100.0;
                        if (account.type === 'available' && account.isTeamAccount) {
                            let psuedoWithdrawnAccount = {...account};
                            psuedoWithdrawnAccount.type = 'withdrawn';
                            if (account.transferredOutTillDate) {
                                psuedoWithdrawnAccount.decimalBalance = account.transferredOutTillDate / 100.0;
                                psuedoWithdrawnAccount.balance = account.transferredOutTillDate;
                            } else {
                                psuedoWithdrawnAccount.decimalBalance = 0.0;
                                psuedoWithdrawnAccount.balance = 0;
                            }
                            this.accounts.push({account: psuedoWithdrawnAccount, id: doc.id});
                        }

                        this.accounts.push({account, id: doc.id});
                        this.accountIds.push(doc.id);
                    }
                }
            );

            this.didFetchAllAccounts();
        }, err => {
            console.log(`Query accounts data error: ${err}`);
            this.didFetchAllAccounts();
        });
    }

    async didFetchAllAccounts() {
        await this.addGroupedBalancesForAccounts();
        this.transactions = [];
        this.getAllTransactionsData(this.accountIds);
    }


    async addGroupedBalancesForAccounts() {
        this.balances = [];
        let groupedBalances = {};
        this.accounts.map(
            (accountObj, i) => {
                const account = accountObj.account;
                const accountId = accountObj.id;

                const key = `/${account.product}/${account.currency}/${(account.is_test ? "test" : "live")}`;

                if (groupedBalances[key] == null) {
                    groupedBalances[key] =
                        {
                            product: account.product,
                            currency: account.currency,
                            is_test: account.is_test,
                            pending: 0, pendingDecimal: 0.0,
                            available: 0, availableDecimal: 0.0,
                            withdrawn: 0, withdrawnDecimal: 0.0,
                        };
                }

                const type = account.type;
                const typeDecimal = `${type}Decimal`;
                const accountIdPropertyName = `${type}AccountId`;

                groupedBalances[key] = {
                    ...groupedBalances[key],
                    [type]: account.balance,
                    [accountIdPropertyName]: accountId,
                    [typeDecimal]: account.balance / 100.0,
                };
            }
        );

        for (var k in groupedBalances) {
            const balanceDictionary = groupedBalances[k];
            balanceDictionary['balance'] = balanceDictionary.pending + balanceDictionary.available;
            balanceDictionary['decimalBalance'] = balanceDictionary.balance / 100.0;
            balanceDictionary['total'] = balanceDictionary.balance + balanceDictionary.withdrawn;
            balanceDictionary['decimalTotal'] = balanceDictionary.total / 100.0;
            this.balances.push(groupedBalances[k]);
        }

        this.accounts = this.balances;
    }


    async getAllTransactionsData(accountIds) {
        if (this.clientId === null || accountIds === null) return;

        const clientId = this.clientId;

        const db = firebase.firestore();
        let queryResults = [];
        this.transactions = [];

        let index = accountIds.length;

        accountIds.map(
            async (accountId, i) => {
                const query = db.collection('clients').doc(clientId).collection('accounts').doc(accountId)
                    .collection('transactions')
                    .orderBy('createdAt', 'desc')
                    .limit(100); // MARK: 100 seems big and small!
                const querySnapshot = await query.get();
                queryResults.push(querySnapshot);

                index--;

                if (querySnapshot) {
                    let size = querySnapshot.size;

                    if (size > 0) {
                        querySnapshot.forEach(
                            doc => {
                                const transaction = doc.data();
                                const full_transaction = {...transaction, id: doc.id, accountId: accountId};
                                this.transactionsById[doc.id] = full_transaction;
                                size--;
                                if (index <= 0 && size <= 0) {
                                    this.updateDataSource();
                                }
                            }
                        );
                    } else {
                        if (index <= 0 && size <= 0) {
                            this.updateDataSource();
                        }
                    }
                }

            }
        );
    }

    async updateDataSource() {

        this.transactions = [];

        for (let prop in this.transactionsById) {
            this.transactions.push(this.transactionsById[prop]);
        }

        let transactions = this.transactions;
        this.transactions = transactions.sort((a, b) => {
            return (a.createdAt < b.createdAt) ? 1 : ((a.createdAt > b.createdAt) ? -1 : 0)
        });

        this.callback ? this.callback(this.transactions) : this.viewModel.updatedTransactionsDataSource(this.transactions);
    }

}
