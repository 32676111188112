import { useState, useEffect } from 'react';

import StrikeAccountTransactionsModel from '../../models/StrikeAccountTransactionsModel';



export function useClientBalances(clientId) {
    const [clientBalances, setClientBalances] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const onUpdateClientBalances = async (balances) => {
            setClientBalances(balances);
            setLoading(false);
        };


        const model = new StrikeAccountTransactionsModel({
            clientId,
            onUpdateBalances: onUpdateClientBalances,
            excludeTransactions: true,
        });

        if (clientId) {
            model.waitForAccountsData();
        } else {
            setLoading(false);
            setClientBalances([]);
        }

        return function cleanup() {
            model.stop();
        };

    },
        []
    );

    return clientBalances;
}
