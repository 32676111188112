import React, { useState } from 'react';
import {
  Form,
  Select,
  Button,
} from 'antd';
import firebase from 'firebase';

import { FormItemLayout, TailFormItemLayout } from '../../utils/Constants';
const { Option } = Select;


const sampleBanks = {
  US: 'Revolut, BofA, Chase, etc.',
  UK: 'Revolut, Lloyds, RBS, Monzo, etc.',
  IE: 'Revolut, AIB, Bank of Ireland, AIB, N26, etc.',
  LT: 'Revolut',
};

export const InitiateTrueLayerForm = ({ bank, clientId }) => {
  const isLocalHost = window.location.hostname === 'localhost';
  const [form] = Form.useForm();
  const [selectedCountry, setSelectedCountry] = useState(bank && bank.country ? bank.country : "IE");

  const onSelectCountry = (value) => {
    setSelectedCountry(value);
  };

  const db = firebase.firestore();

    const baseUrl = isLocalHost ? 'auth.truelayer-sandbox.com' : 'auth.truelayer.com';
    const tlClientId = isLocalHost ?  'sandbox-strikepay-eaf425' : 'strikepay-eaf425';
    const strikeBaseUrl = isLocalHost ? 'http://localhost:3000' : 'https://app.strikepay.co';
    let providers = (selectedCountry === 'IE') ? 'ie-ob-all' : 'uk-ob-all%20uk-oauth-all';
    if (isLocalHost) { providers = 'uk-cs-mock' }
    const trueLayerUrl = `https://${baseUrl}/?response_type=code&client_id=${tlClientId}&scope=info%20accounts&redirect_uri=${strikeBaseUrl}/client/bank-callback&providers=${providers}&state=${selectedCountry}`;

    const onClickAddBankAccount = async() => {
        //Not awaiting here purposefully to allow user to not see 2nd Panel appearing(whenever possible)
        db.collection('clients').doc(clientId).update({
          trueLayerAttemptedAt: new Date(),
          updatedAt: new Date()
        });
      window.location.href = trueLayerUrl;
    }

    return (
      <Form
        {...FormItemLayout}
        form={form}
        name="bankRegister"
        initialValues={bank}
        scrollToFirstError
      >
        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8">
            <span className="" style={{ fontWeight: 500, fontSize: '15px' }}>Select the country of your bank</span>
          </div>
        </div>

        <Form.Item
          name="country"
          label="Country"
          rules={[
            {
              required: true,
              message: 'Please provide the country where the bank is located',
            },
          ]}
        >
          <Select
            placeholder="Select a country"
            onSelect={onSelectCountry}
            defaultValue={"IE"}
          >
            <Option value="IE">Ireland</Option>
            <Option value="GB">United Kingdom</Option>
          </Select>
        </Form.Item>

        <Form.Item {...TailFormItemLayout}>
          <p>Please add your bank account by pressing 'Add New Bank Account'. You'll be asked to
            authenticate with your bank and they will share your account details with us securely via TrueLayer.</p>
          <Button type="primary">
            <a onClick={() => onClickAddBankAccount()}>Add New Bank Account</a>
          </Button>
        </Form.Item>
      </Form>
    );
};
