import firebase from 'firebase/app';
const isLocalHost = window.location.hostname === 'localhost';

export default class StrikeTeamTransfersModel {

  constructor({ callback, viewModel, clientId, includeTestAccounts, teamDetails, memberClientId, teamId }) {
    this.callback = callback;
    this.viewModel = viewModel;
    this.clientId = clientId;
    this.memberClientId = memberClientId;
    this.uid = null;
    this.transfers = [];
    this.includeTestAccounts = includeTestAccounts || isLocalHost; // MARK: Show test and live accounts on lcoalhost
    this.teamId = teamId;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    this.viewModel = null;
    if(this.observerAccounts) {
      this.observerAccounts();
      this.observerAccounts = null;
    }
  }

  async getTransferDetails(transferId) {
    const db = firebase.firestore();
    let query = db.collection('transfers').doc(transferId);
    let snapshot = await query.get(); 
    let data = snapshot.data();
    return { ...data, id: snapshot.id };
  }

  waitForTransfersData = () => {
    if (this.clientId === null) return;

    const clientId = this.clientId

    if(this.observerAccounts) {
      this.observerAccounts();
      this.observerAccounts = null;
    }

    const db = firebase.firestore();
    let preQuery = db.collection('transfers').where('transferType', '==', 'team-to-member');

    if (this.memberClientId) {
      preQuery = preQuery.where('toClientId', '==', this.memberClientId);
    }

    if(this.teamId) {
      preQuery = preQuery.where('fromTeamId', '==', this.teamId);
    }
    const query = (this.includeTestAccounts) ? preQuery : preQuery.where('is_test', '==', false); // MARK: strangeness in Firebasee 

    this.promisesAll = [];

    this.observerAccounts = query.onSnapshot(querySnapshot => {
      let index = querySnapshot.size;
      this.transfers = [];

      querySnapshot.forEach(
        doc => {
          let transfer = doc.data();
          this.transfers.push( { transfer: transfer, id: doc.id });
        }
      );

      this.didFetchAllAccounts();
    }, err => {
      console.log(`Encountered a Strike Team Transfers model error: ${err}`);
    });

  }

  async didFetchAllAccounts() {
    this.updateDataSource();
  }

  async updateDataSource() {
    if(this.callback){
      this.callback(this.transfers);
    }

  }

}
