import React, { Component } from 'react';
import '../../App.scss';
import { InputNumber, Row, Col } from 'antd';

import {ccyIsoCodeToSymbolMap} from '../../utils/CurrencyUtils';

/*** MAX value for a default selection is 5000 €/£/$ */
const MAX_PAY_VALUE = 5000;
const MIN_PAY_VALUE = 1;

export default class CustomAmountNumberField extends Component {

  constructor(props) {
    super(props);
    
    const { value, minValue, maxValue, invisible, placeholder, onValueChange , index, currency, precision } = this.props;

    this.state = {
      value , 
      index,
      currency : currency || 'EUR',
      precision : precision || 0,
      invisible,
      minValue : minValue || MIN_PAY_VALUE,
      maxValue : maxValue || MAX_PAY_VALUE,      
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  setValue = (value) => {
    this.setState({ value: value * 100 });
    this.props.onValueChange(this.state.index, value * 100)
  };

  render() {
    const { value, index, currency, precision } = this.state;

    const ccy = ccyIsoCodeToSymbolMap;

    return  ( !this.state.invisible &&
              <InputNumber 
                  key={`input-${index}`} 
                  style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', fontSize: '24px' }}
                  placeholder={this.props.placeholder} 
                  defaultValue={(this.props.defaultValue / 100).toFixed(precision)} 
                  min={this.state.minValue}
                  max={this.state.maxValue}
                  precision={precision}
                  size={"large"}
                  onChange={(v) => (this.setValue(v))}
              />
            );
  }
}

