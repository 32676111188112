import { useState, useEffect } from 'react';

import ClientAvailableTransactionsGroupedModel from './ClientAvailableTransactionsGroupedModel';

export function useClientAvailableTransactions(clientId, dateRange) {
    const [transactions, setTransactions] = useState(null);

    useEffect(() => {
        const callback = async (data) => {
            setTransactions(data);
        };

        const model = new ClientAvailableTransactionsGroupedModel({
            clientId,
            ...dateRange,
            callback,
        });

        model.fetch();
        
        return function cleanup() {
            model.stop();    
            setTransactions(null);
        };

    },
        [clientId, dateRange]
    );

    return transactions;
}
