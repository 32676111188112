import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import { StrikeColors } from '../../utils/Colors';
import { useFeatureFlagMoneyJar } from '../../models/useFeatureFlagMoneyJar';
import MoneyJarService from '../../utils/MoneyJarService';

import AddPayoutMoneyJarButton from './AddPayoutMoneyJarButton';

const AddPayoutMoneyJarBanner = ({ clientId, onClickAdd, payouts }) => {
  const isLocalHost = window.location.hostname === 'localhost';
  const isTest = isLocalHost;
  const shouldUseMoneyJar = useFeatureFlagMoneyJar(clientId, isTest);
  const moneyjarService = new MoneyJarService({ clientId, isTest });
  const existingPayout = moneyjarService.getExistingPayoutId(payouts);
  const { status } = existingPayout || {};

  const onClickAddPayout = () => {
    const moneyJarPayoutData = moneyjarService.onCreatePayoutData();
    if(onClickAdd) {
      onClickAdd(moneyJarPayoutData);
    }
  };

  return shouldUseMoneyJar && status !== 'live' ? (
      <div style={{ backgroundColor: StrikeColors.magenta, padding: '5x 5px 20px 5px', textAlign: 'center', color: 'white' }}>
        <h2 style={{ color: 'white' }}>Cash Out Instantly to MoneyJar!</h2>
        <h5 style={{ color: 'white' }}>We have partnered with MoneyJar so you can get your money anytime.</h5>
        <p style={{ fontWeight: 'normal' }}>Your funds will arrive instantly in your MoneyJar app.</p>
        { existingPayout && 
          <>
          <Link to={`./bank/${existingPayout.uid}`}>
            <Button type='default' size='small'>Go to Setup</Button>
          </Link>
          <p>Only one MoneyJar account can be connected to Strikepay.</p>
          </>
        }
        {!existingPayout && 
          <AddPayoutMoneyJarButton clientId={clientId} onClick={onClickAddPayout}/> 
        }
      </div>
  ) : null;
};

export default AddPayoutMoneyJarBanner;
