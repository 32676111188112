import React, { useState } from 'react';
import { Button, Space, Avatar } from 'antd';
import { UserSwitchOutlined } from '@ant-design/icons';

import TrackerService from '../../utils/TrackerService';
import { useClientRoles } from '../../models/useClientRoles';

import { useClientDetails } from './useClientDetails';
import FallbackAvatar from './FallbackAvatar';
import RedirectAndScrollTop from './RedirectAndScrollTop';


function ClientAvatarSwitchButton({ onClick, clientId, id, isLoggedInClient }) {
  const client = useClientDetails(id);
  const buttonText = !!isLoggedInClient ? ' (You)' : '';
  const backgroundColor = null;
  const style = { textAlign: 'start', width: `100%`, backgroundColor }

  return (
    <>
      {client && id && onClick &&
        <Button
          key={`btn-switch-${clientId}-${id}`}
          onClick={() => (onClick(id))}
          type={ !!isLoggedInClient ? 'primary' : 'secondary' }
          style={style}
        >
          <Space direction='horizontal'>
            { client.avatar ?
              <Avatar
                src={client.avatar}
                shape="circle"
                size="small"
              />
              :
              <FallbackAvatar />
            }
            {`${client.displayName}${buttonText}`}
          </Space>
        </Button>
      }
    </>);

}



function SwitchClientButton({ clientId, ...rest }) {
  const [showList, setShowList] = useState(false);
  const [route, setRoute] = useState(null);
  const keyValue = `btn-roles-${clientId}`;
  const roles = useClientRoles(clientId);
  const title = roles && roles.length ? `Switch User` : null;
  const isTest = window.location.hostname === 'localhost';
  const path = isTest ? '' : 'https://app.strikepay.co';

  const trackClickedStatus = (targetClientId) => {
    const eventId = `client_clicked_switch_roles`;
    const data = {
      clientId,
      targetClientId,
    };

    const tracker = new TrackerService({
      isTest,
    });

    tracker.mixpanelTrack(eventId, { ...data });
    if (!isTest) {
      tracker.googleTrack(eventId, { ...data });
    }
  }


  const onClickSwitchButton = () => {
    setShowList(true);
  };

  const onClickButton = (targetClientId) => {
    if (targetClientId) {
      console.log('onClickButton', `/client/manage/${targetClientId}`);
      const fullPath = `${path}/client/manage/${targetClientId}`;
      trackClickedStatus();
      setRoute(fullPath);
    }
  };

  return (
    <>
      {!showList && title &&
        <Button
          key={`${keyValue}-${clientId}`}
          icon={<UserSwitchOutlined />}
          onClick={onClickSwitchButton}
          type={'secondary'}
          style={{ textAlign: 'start', width: `100%` }}
        >
          {title}
        </Button>
      }
      {showList && roles && title &&
        (roles.map((v) =>
        (
          <ClientAvatarSwitchButton
            key={`${keyValue}-${v.id}`}
            clientId={clientId}
            id={v.id}
            onClick={() => (onClickButton(v.id))}
            isLoggedInClient={!!v.isLoggedInClient}
          />
        ))
        )
      }
      {
        <RedirectAndScrollTop
          to={route}
          when={route}
        />
      }
    </>
  );
}

export default SwitchClientButton;
