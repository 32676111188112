import React, { Component } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import './accountProfileLayout.css';
import { Layout } from 'antd';
import { LinkContainer } from 'react-router-bootstrap'
import { UserOutlined, BankOutlined, TeamOutlined, LogoutOutlined } from '@ant-design/icons';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import firebase from 'firebase/app';
import { Redirect } from 'react-router-dom';

import {ccyIsoCodeToSymbolMap, countryFlags } from '../../utils/CurrencyUtils';
import StrikeFooter from '../../components/StrikeFooter';
import { getMaxAccountCcyTotal } from '../../utils/AccountCalculations';
import StrikeClientModel from '../../models/StrikeClientModel';
import StrikeAccountTransactionsModel from '../../models/StrikeAccountTransactionsModel';
import placeholderImage from '../../images/placeholder_avatar.png';
import img_StrikeLogoLg from '../../images/strikepay_Mark_url_RGB_White.png';
import img_StrikeLogoSm from '../../images/strikepay_Mark_url_RGB_White_small.png';


const { Content } = Layout;

const ccy = ccyIsoCodeToSymbolMap;

const CountryFlagIcon = ({client}) => {
  const clientCountry = client && client.country ? client.country : null;
  const flag = clientCountry ? countryFlags[clientCountry] : null;
  return flag ? <>{flag}</> : <UserOutlined />;
}

export default class AccountProfileLayout extends Component {

  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;

    this.baseURLs = props.baseURLs || {
      manage : '/client/manage',
      login : '/client/login' 
    };

    this.clientModel = new StrikeClientModel({ 
      onClientFound: this.foundClient,
      onClientNotFound: this.clientNotFound,
      viewModel: this, 
      clientId,
      isAdmin: false,
    });

    this.accountsModel = new StrikeAccountTransactionsModel({ 
      callback: this.updatedDataSource,
      viewModel: this, 
      clientId
    });

    this.state = {
      clientId: clientId,
      triggeredLogOut: false,
      isUseDashboard: false,
    }
  }

  componentDidMount() {
    this.clientModel.waitForClientData();
  }

  componentWillUnmount() {
    if(this.clientModel) this.clientModel.stop();
  }
  
  foundClient = async (client) => {

    this.setState(
      (state) => {
        return {
          clientNotFound: false,
          client,
          isUseDashboard: client ? !!client.isUseDashboard : false,
          loadingDetails: {
            client: false,
          }
        }
      }
    );

    this.accountsModel.waitForAccountsData();
  }

  clientNotFound = () => {
    console.log("No client found. Stay logged out.");

    // This layout is also used for the claim flow and we want to avoid this issue at this check
    if (this.props.match.path !== "/client/claim/:tagId") {
      this.setState({ 
        clientNotFound: true 
      });
    }
  }

  renderRedirectToLogin = () => {
    let toRoute = `${this.baseURLs.login}`;

    if (this.state.triggeredLogOut === false && this.props.match.path !== "/client/manage/:clientId" && this.props.match.path !== "/client/claim/:tagId") {
      const fullPath = this.props.match.url;
      const remainingRoute = fullPath.split(`${this.state.clientId}/`)[1];

      toRoute += `?redirectRoute=${remainingRoute}`;
    }

    return <Redirect to={toRoute} />
  }

  logOut = async () => {
    this.setState({triggeredLogOut: true});
    await firebase.auth().signOut();
  }

  updatedDataSource = async (txns) => {
    const accounts = this.accountsModel.accounts;

    this.setState(
      (state) => {
        return {
          accounts,
        }
      }
    );
  }
  
  render() {
    const clientId = this.props.match.params.clientId;
    const displayName = this.state.client ? this.state.client.displayName : "Your Profile";
    const avatarSrc = this.state.client && this.state.client.avatar ? this.state.client.avatar : placeholderImage;
    const accounts = (this.state.accounts && this.state.accounts.length > 0) ? this.state.accounts : null;
    const mainBalance = accounts ? getMaxAccountCcyTotal(accounts) : null;
    const balance = mainBalance ? (ccy[mainBalance.currency] + mainBalance.decimalBalance.toFixed(2)) : null;
    const isUseDashboard = false;
    const logoLink = isUseDashboard ? `/teams/manage/${clientId}` : `/client/manage/${clientId}`;
    const isRestricted = this.state.client ? (this.state.client.status === 'paused') : true;

    const clientHomeLink = `/client/manage/${clientId}`;
    const profileLink = `/client/manage/${clientId}/profile`;
    const payoutsLink = `/client/manage/${clientId}/bank`;
    const accessLink = `/client/manage/${clientId}/access`;

    if (this.state.clientNotFound) return this.renderRedirectToLogin();

    return (
      <>
        <Navbar bg="primary" variant="dark" expand="lg" collapseOnSelect>
          <Navbar.Brand href={logoLink} id="navbar-logo">
            <img
              alt="Strikepay"
              src={img_StrikeLogoSm}
              height="44"
              className="align-top d-lg-none mr-auto"
            />
            <img
              alt="Strikepay"
              src={img_StrikeLogoLg}
              height="44"
              className="align-top d-none d-lg-block mr-auto"
            />
          </Navbar.Brand>

          {balance && (
            <div id="navbar-balance" className="d-flex justify-content-center mx-auto">
              <Nav.Link href={clientHomeLink} className="navbar-nav mx-auto text-center">
                <span className="badge badge-pill badge-light">{balance}</span>
              </Nav.Link>
            </div>
          )}
          
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <img className="rounded-circle"
              width="40" height="40" 
              src={avatarSrc} 
              alt={displayName}
            />
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              {!isRestricted && (
                <>
                  <LinkContainer to={profileLink}>
                    <Nav.Link className="d-lg-none"><CountryFlagIcon client={this.state.client} /><span> Profile</span></Nav.Link>
                  </LinkContainer>
                  <LinkContainer to={payoutsLink}>
                    <Nav.Link className="d-lg-none"><BankOutlined /> Payouts</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to={accessLink}>
                    <Nav.Link className="d-lg-none"><TeamOutlined /> Access</Nav.Link>
                  </LinkContainer>
                </>
              )}
              <Nav.Link onClick={ this.logOut } className="d-lg-none"><LogoutOutlined /> Log out</Nav.Link>

              <NavDropdown title={
                  <img className="rounded-circle"
                      width="40" height="40" 
                      src={avatarSrc} 
                      alt={displayName}
                  />
                }
                id="basic-nav-dropdown" alignRight className="d-none d-lg-block">
                {!isRestricted && (
                  <>
                    <LinkContainer exact to={clientHomeLink}>
                      <NavDropdown.Item><strong>{displayName}</strong></NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={profileLink}>
                      <NavDropdown.Item><CountryFlagIcon client={this.state.client} /><span> Profile</span></NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={payoutsLink}>
                      <NavDropdown.Item>
                        <BankOutlined /> Payouts
                        {/* {' '} // For adding notification and actions
                        <span className="badge badge-pill badge-danger">2</span> */}
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={accessLink}>
                      <NavDropdown.Item><TeamOutlined /> Access</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Divider />
                  </>
                )}
                <NavDropdown.Item onClick={ this.logOut }><LogoutOutlined /> Log out</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <Container fluid>
          <Content style={{ padding: '0 0 0 0' }}>
            <div className="site-layout-content">
              {this.props.children}
            </div>
          </Content>
          <StrikeFooter locStrings={this.props.locStrings} />
        </Container>
      </>
    );
  }
}
