import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ConstrainedAppRoute = ({  condition , component: Component, layout: Layout, locStrings, ...rest }) => {
    return (
        !condition() ?
            <Redirect to="/" />
        :
            <Route {...rest} render={props => (
                <Layout {...props} locStrings={locStrings}>
                    <Component {...props} locStrings={locStrings}/>
                </Layout>
            )} />
    );
};

export default ConstrainedAppRoute;
