import { useState, useEffect } from 'react';

import StrikeTeamsModel from '../../models/StrikeTeamsModel';


export function useClientTeams(clientId) {
    const [teams, setTeams] = useState(null);

    useEffect(() => {
        const onUpdateTeamsList = async (teamsList) => {
            setTeams(teamsList);
        };


        const model = new StrikeTeamsModel({
            clientId,
            onUpdate: onUpdateTeamsList,
        });

        model.fetchAllTeamsForClient();

        return function cleanup() {
            model.stop();
        };

    },
        []
    );

    return teams;
}
