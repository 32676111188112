import firebase from 'firebase/app';

const isLocalHost = window.location.hostname === 'localhost';

export default class StrikeTeamModel {

    constructor({teamId, onUpdate}) {
        this.teamId = teamId;
        this.teamDetails = null;
        this.onUpdate = onUpdate;
        this.db = firebase.firestore();
    }

    stop() {
        // stop all listeners.,.not sure they get droppped when object drops out of scope.
        this.viewModel = null;
        if (this.observerTeamObject) {
            this.observerTeamObject();
            this.observerTeamObject = null;
        }
    }

    waitForTeam = async () => {
        if (this.teamId === null) return;
        
        this.stop();

        const queryTeam = this.db.collection('teams').doc(this.teamId);

        this.observerTeamObject = queryTeam.onSnapshot(doc => {
            let teamObject = doc.data();
            teamObject = { ...teamObject, id: doc.id, teamId: doc.id  };
            this.teamDetails = teamObject;
            this.didFetchTeamObject();
        }, err => {
            console.log(`Encountered a team object fetching error: ${err}`);
        });

    }

    getTeam = async () => {
        if (this.teamId === null) return;
        const queryTeam = this.db.collection('teams').doc(this.teamId);
        const teamSnapshot = await queryTeam.get();
        let teamObject = teamSnapshot.data();
        teamObject = { ...teamObject, id: teamSnapshot.id, teamId: teamSnapshot.id };
        return teamObject;
    }

    async didFetchTeamObject() {
        this.updateDataSource();
    }

    async updateDataSource() {
        if (this.onUpdate) {
            this.onUpdate(this.teamDetails);
        }
    }


}
