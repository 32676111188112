import firebase from 'firebase/app';

import {ccyIsoCodeToSymbolMap} from '../utils/CurrencyUtils';
const isLocalHost = window.location.hostname === 'localhost';

const currenciesMap = ccyIsoCodeToSymbolMap;

export default class StrikeAccountTransactionsModel {

  constructor({ callback, viewModel, clientId, includeTestAccounts, excludeTransactions, onUpdateBalances }) {
    this.callback = callback;
    this.viewModel = viewModel;
    this.clientId = clientId;
    this.excludeTransactions = !!excludeTransactions;
    this.onUpdateBalances = onUpdateBalances;
    this.uid = null;
    this.accounts = [];
    this.transactions = [];
    this.includeTestAccounts = includeTestAccounts || isLocalHost; // MARK: Show test and live accounts on lcoalhost
    this.observerTransactions = [];

    this.transactionsById = {};
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    this.viewModel = null;
    if(this.observerAccounts) {
      this.observerAccounts();
      this.observerAccounts = null;
    }
  }

  waitForAccountsData = () => {
    if (this.clientId === null) return;

    const clientId = this.clientId

    if(this.observerAccounts) {
      this.observerAccounts();
      this.observerAccounts = null;
    }

    const db = firebase.firestore();
    const preQuery = db.collection('clients').doc(clientId).collection('accounts').where('version', '==', '2');
    const query = (this.includeTestAccounts) ? preQuery : preQuery.where('is_test', '==', false); // MARK: strangeness in Firebasee 

    this.promisesAll = [];
    this.accounts = [];
    this.accountIds = [];
    this.transactions = [];

    this.observerAccounts = query.onSnapshot(querySnapshot => {
      let index = querySnapshot.size;
      this.accounts = [];
      this.accountIds = [];
  
      if(index > 0) {
        querySnapshot.forEach(
          doc => {
            let account = doc.data();
            account.decimalBalance = account.balance / 100.0;
            this.accounts.push( { account, id: doc.id });
            this.accountIds.push(doc.id);
            index--;
            if(index <= 0) this.didFetchAllAccounts();
          }
        );
      } else {
        this.didFetchAllAccounts();
      }
    }, err => {
      console.log(`Query accounts data error: ${err}`);
      this.didFetchAllAccounts();
    });

  }

  async didFetchAllAccounts() {
    await this.addGroupedBalancesForAccounts();
    if(!this.excludeTransactions) {
      this.transactions = [];
      this.getAllTransactionsData(this.accountIds);
    } 
    if (this.onUpdateBalances) {
      this.onUpdateBalances(this.accounts);
    }
  }

  async addGroupedBalancesForAccounts() {
    this.balances = [];
    let groupedBalances = {};
    this.accounts.map(
      (accountObj, i) => {
        const { account, id: accountId } = accountObj || {};
        if (!account) return;
        
        const { 
          product, currency, is_test,
          balance, type,
          transferredOutTillDate,
        } = account || {};

        const key = `/${product}/${currency}/${(is_test ? "test" : "live")}`;

        if(groupedBalances[key] == null)  {
          groupedBalances[key] = 
                    { 
                      product: product,
                      currency: currency,
                      is_test: is_test, 
                      pending: 0, pendingDecimal: 0.0,
                      available: 0, availableDecimal: 0.0,
                      withdrawn: 0, withdrawnDecimal: 0.0,
                      transferredOutTillDate: 0, transferredOutTillDateDecimal: 0.0
                    };
        }

        const typeDecimal = `${type}Decimal`;
        const accountIdPropertyName = `${type}AccountId`;

        groupedBalances[key] = { 
          ...groupedBalances[key],
          [type] : balance,
          [accountIdPropertyName]: accountId,
          [typeDecimal] : balance/100.0,
        };
        if (type === 'available') {
            groupedBalances[key].transferredOutTillDate = transferredOutTillDate ? transferredOutTillDate : 0;
        }
      }
    );

    for( var k in groupedBalances ) {
      const balanceDictionary = groupedBalances[k];
      const ccySymbol = currenciesMap[balanceDictionary.currency];
      balanceDictionary['balance'] = balanceDictionary.pending + balanceDictionary.available;
      balanceDictionary['decimalBalance'] = balanceDictionary.balance / 100.0;
      balanceDictionary['ccyLeadDecimalBalance'] = ccySymbol + (balanceDictionary.balance / 100.0).toFixed(2);
      if (balanceDictionary.transferredOutTillDate !== undefined) { //Check for cases where clients are yet to have an available account(ideally should not happen)
          balanceDictionary['total'] = balanceDictionary.balance + balanceDictionary.withdrawn + balanceDictionary.transferredOutTillDate;
      } else {
          balanceDictionary['total'] = balanceDictionary.balance + balanceDictionary.withdrawn;
      }
      balanceDictionary['decimalTotal'] = balanceDictionary.total / 100.0;
      this.balances.push(groupedBalances[k]);
    }

    this.accounts = this.balances;
  }


  async getAllTransactionsData(accountIds)  {
    if (this.clientId === null || accountIds === null) return;

    const clientId = this.clientId;

    const db = firebase.firestore();
    let queryResults = [];
    this.transactions = [];

    let index = accountIds.length;

    accountIds.map(
      async (accountId,i) => {
        const query = db.collection('clients').doc(clientId).collection('accounts').doc(accountId)
                            .collection('transactions')
                            .orderBy('createdAt', 'desc')
                            .limit(100); // MARK: 100 seems big and small!
        const querySnapshot = await query.get();
        queryResults.push(querySnapshot);

        index--;

        if(querySnapshot) {
          let size = querySnapshot.size;

          if(size > 0) {
            querySnapshot.forEach(
              doc => {
                const transaction = doc.data();
                const full_transaction = { ...transaction, id: doc.id, accountId: accountId };
                this.transactionsById[doc.id] = full_transaction;
                size--;
                if(index <= 0 && size <=0) {
                  this.updateDataSource();
                }
              }
            );  
          } else {
            if(index <= 0 && size <=0) {
              this.updateDataSource();
            }
          }
        }

      }
    );
  }

  async updateDataSource() {

    this.transactions = [];

    for(let prop in this.transactionsById) {
      this.transactions.push(this.transactionsById[prop]);  
    }

    let transactions = this.transactions;
    this.transactions = transactions.sort((a, b) => { return (a.createdAt < b.createdAt) ? 1 : ( (a.createdAt > b.createdAt) ? -1 : 0 )});

    this.callback ? this.callback(this.transactions) : this.viewModel.updatedTransactionsDataSource(this.transactions);
  }

}
