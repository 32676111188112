import { changeConfirmLocale } from 'antd/lib/modal/locale';
import firebase from 'firebase/app';
import moment from 'moment';


export default class StrikeClientSkusModel {

  constructor({ clientId, callback, daysToDisplay }) {
    this.clientId = clientId;
    this.skus = [];
    this.client = null;
    this.skusById = {};
    this.callback = callback;
    this.daysToDisplay = daysToDisplay || 0;
  }

  getQueryForSkusForClient(params) {
    const isLocalHost = window.location.hostname === 'localhost';

    const {
      clientId,
    } = params;

    const db = firebase.firestore();

    const query = db
      .collection('clients')
      .doc(clientId)
      .collection('skus')
      .orderBy('updatedAt', 'desc');

    
    const now = moment().utc();
    const units = isLocalHost ? 'minutes' : 'days';
    const daysAgo = now.subtract(this.daysToDisplay, units).toDate();

    const limitQuery = (this.daysToDisplay > 0) ? query.where('updatedAt', '>=', daysAgo) : query;

    return limitQuery;
  }

  async getSkuDataForSkuId(SkuId) {
    const db = firebase.firestore();
    const doc = await db
            .collection('skus')
            .doc(SkuId)
            .get();
    return doc ? doc.data() : null;
  }

  async updateSkuWithOrderDetailsForClientId({ clientId, skuId, quantityOrders, quantityOffers }) {
    // update count of orders 
    const db = firebase.firestore();
    const query = db
            .collection('clients')
            .doc(clientId)
            .collection('skus')
            .doc(skuId);

      const result = await query.set(
        {
          countOrders: firebase.firestore.FieldValue.increment(quantityOrders || 0),
          countOffers: firebase.firestore.FieldValue.increment(quantityOffers || 0),
          updatedAt: firebase.firestore.Timestamp.now(),
        },
        {
          merge: true
        }
      );

      return result;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    this.skusById = {};
    this.skus = [];
    if(this.observerTags) {
      this.observerTags();
      this.observerTags = null;
    }
  }

  async fetchAllSkusData()  {
    if (this.clientId === null) return;

    const clientId = this.clientId;

    this.stop();

    const db = firebase.firestore();
    this.skus = [];
    this.skusById = {};

    const query = this.getQueryForSkusForClient({
      clientId,
    });

    this.observerTags = query.onSnapshot(querySnapshot => {
      let countSkus = querySnapshot.size;
      this.skus = [];
      if(countSkus === 0) this.updateDataSource();

      querySnapshot.forEach(
        doc => {
            const docData = doc.data();
            const id = doc.id;
            const full_doc = { ...this.skusById[id], ...docData, id };
            this.skusById[id] = full_doc;
            countSkus --;
            if(countSkus === 0) this.updateDataSource();
      });
    }, err => {
      this.skus = [];
      this.skusById = {};
      this.updateDataSource();
      console.log(`Tags query error: ${err}`);
    });


  }

  async updateDataSource() {
    this.skus = [];

    for(let prop in this.skusById) {
      const sku = await this.getSkuDataForSkuId(prop);
      if(sku) {
        const full_doc = {  ...sku, ...this.skusById[prop],  };
        this.skusById[prop] = full_doc;
        this.skus.push(full_doc);  
      }
    }

    if(this.callback) this.callback(this.skus);
  }

}
