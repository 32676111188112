import firebase from 'firebase/app';

import FirebaseSetup from '../FirebaseSetup'

const isLocalHost = window.location.hostname === 'localhost';

export default class StrikeScanEventModel {

  constructor({ tagId, tagUid, source, uid, teamMemberId, amount, name, reference, refId, url }) {
    this.observerScan = null;

    this.tagId = tagId;
    this.tagUid = tagUid;
    this.source = source;
    this.uid = uid;
    this.teamMemberId = teamMemberId;
    this.amount = amount;
    this.name = name;
    this.reference = reference;
    this.refId = refId;
    this.url = url;
  }

  stop() {
    if (this.observerScan) this.observerScan();
    this.observerScan = null;
  }

  async createScan() {    
    let data = {
      uid: this.uid,
      tagId: this.tagId,
      tagUid: this.tagUid,
      source: this.source,
      teamMemberId: this.teamMemberId,
      amount: this.amount,
      name: this.name,
      reference: this.reference,
      refId: this.refId,
      url: this.url,
    };

    if (data) {
      const db = firebase.firestore();
      const query = db.collection('scans');
      return await query.add({
        ...data,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } else {
      return null;
    }
  }

  observeScan(scanId, resultCB) {
    this.scanId = scanId || this.scanId;
    if (this.tagId && this.scanId) {
      const db = firebase.firestore();
      const query = db.collection('scans').doc(this.scanId);

      this.observerScan = query.onSnapshot(snap => {
        this.scan = null;
        if (snap.exists) {
          this.scan = { scanId: snap.id, ...snap.data() };
          resultCB(this.scan);
        } else {
          resultCB(null);
        }
      });

    } else {
      resultCB(null);
    }
  }
}
