import React from 'react';
import {
  Typography,
} from 'antd';

const { Paragraph } = Typography;

export const JSONFieldsDefault = [
  'id', 'item', 'createdAt',
  'displayName', 'status',
  'type', 'mode', 'target', 'text',
  'client', 'clientId',
  'team', 'teamId', 'teamName',
  'account', 'accountId',
  'is_test', 'status',
  'payout', 'payoutId', 'product',
  'tap', 'tapId', 'tagUid',
  'tag', 'tagId', 'source',
  'amount', 'currency',
  'fees', 'strikeFee', 'email', 'fee',
  'avatar', 'topLogo', 'rgbColor',
  'payout', 'payoutId', 'payoutAmountCents',
  'withdrawal', 'withdrawalId',
  'striker_email', 'customerName', 'paymentInfo', 'reference',
  'type', 'templateTagId',
];

export const JSONParagraph = ({ item, fields }) => {
  const showFields = fields || JSONFieldsDefault;
  return (
    <Paragraph code style={{ whiteSpace: 'pre-wrap' }}>
      {`${JSON.stringify(item, showFields, 4)}`}
    </Paragraph>
  );
}
