import React, { Component } from 'react';
import '../../App.scss';

export default class ValueSectionOption extends Component {

  // Properties used by this component:
  // title, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  renderState0() {
    const style_state0_elCard = {
      width: '100%',
      height: '100%',
     };

    let backgroundColor = '#fff';
    let titleColor = 'rgba(0, 0, 0, 0.8500)';

    if (this.props.lowContrastBackground) {
      backgroundColor = '#EFEFEF';
      titleColor = 'rgba(0, 0, 0, 0.8500)';
    }

    const style_state0_elCard_outer = {
      backgroundColor: backgroundColor,
      borderRadius: '4px',
     };
    
    const value_title = this.props.title;
    const value_subtitle = this.props.subtitle;
    
    const style_state0_elTitle = {
      fontSize: 18,
      color: titleColor,
      textAlign: 'center',
    };
    
     const style_state0_elSubTitle = {
      fontSize: 8.3,
      color: titleColor,
      textAlign: 'center',
      height: '15%',
    };
    
    return (
      <div className="ValueSectionOption">
        <div className="foreground">
          <div className="containerMinHeight state0_elCard" style={style_state0_elCard_outer}>
            <div style={style_state0_elCard} />
          </div>
          
          <div className="systemFontRegular  state0_elTitle" style={style_state0_elTitle}>
            <div className="bottomAlignedContent">{value_title !== undefined ? value_title : (<span className="propValueMissing">{this.props.locStrings.button1_title_443161}</span>)}</div>
          </div>
          <div className="systemFontRegular  state0_elTitle" style={style_state0_elSubTitle}> {value_subtitle || (null)} </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  renderState1() {
    const style_state1_elCardSelected = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elCardSelected_outer = {
      backgroundColor: '#000',
      borderRadius: '4px',
     };
    
    const value_title = this.props.title;
    const value_subtitle = this.props.subtitle;

    const style_state1_elTitle = {
      fontSize: 18,
      color: '#eeeeee',
      textAlign: 'center',
    };

    const style_state1_elSubTitle = {
      fontSize: 8.3,
      color: '#eeeeee',
      textAlign: 'center',
      height: '15%',
    };
    
    
    return (
      <div className="ValueSectionOption">
        <div className="foreground">
          <div className="containerMinHeight state1_elCardSelected" style={style_state1_elCardSelected_outer}>
            <div style={style_state1_elCardSelected} />
          </div>
          
          <div className="systemFontRegular  state1_elTitle" style={style_state1_elTitle}>
            <div className="bottomAlignedContent">{value_title !== undefined ? value_title : (<span className="propValueMissing">{this.props.locStrings.button1_title_371051}</span>)}</div>
          </div>
          <div className="systemFontRegular  state1_elTitle" style={style_state1_elSubTitle}> {value_subtitle || (null)} </div>
          <div className="state1_elSelectionMarker" />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
