import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { PageHeader, Space } from 'antd';

import Spinner from '../../../components/Spinner';
import StrikeTeamDetailsModel from '../../../models/StrikeTeamDetailsModel';
import QRImageView from '../../../components/customer/QRImageView';


export default class TeamQRScreen extends Component {

    // Properties used by this component:

    constructor(props) {
        super(props);

        const teamId = this.props.match.params.teamId;
        const isAdmin = !!props.isAdmin;

        this.model = new StrikeTeamDetailsModel({viewModel: this, teamId});

        this.state = {
            teamId: teamId,
            teamDetails: null,
            teamMembersList: null,
            loading: true
        };
    }

    componentDidMount() {
        this.model.waitForTeamDetails({fetchTeamClient: false});
    }

    componentWillUnmount() {
        if (this.model) this.model.stop();
    }

    componentDidUpdate() {
    }

    isLoading = () => {
        return this.state.loading || false;
    }

    setTeamDetailsOnPage = (teamDetails, teamMembersList) => {
        this.setState({
            teamDetails,
            teamMembersList,
            loading: false
        });
    }

     render() {

         const crumbs = this.props.crumbs || ['Account', 'Profile'];
         const style_teamTopLogoImage = {
             display: ((this.state.teamDetails && this.state.teamDetails.topLogo) ? 'inline-block': 'none'),
             cursor: 'pointer',
             pointerEvents: 'auto',
             width: '150px',
             marginTop: '1px',
             marginBottom: '20px',
             borderRadius: '2px'
         };

         if (!this.state.loading && this.state.teamDetails) {
             return (
                 <div style={{height: '100%', textAlign: 'center'}}>
                     <PageHeader
                         ghost={false}
                         title={`${this.state.teamDetails.teamName}`}
                         onBack={(() => window.history.back())}
                     />
                     <h2>{`${this.state.teamDetails.teamName}`} - QR Code</h2>
                     <Space align={'center'} direction={'vertical'}><img style={style_teamTopLogoImage} src={(this.state.teamDetails.topLogo)} alt="Team Logo" />
                     <QRImageView {...this.props} tag={this.state.tag} tagId={this.state.teamDetails.defaultTagId} ref={(el)=> this._elQRImageView = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
                     </Space>
                 </div>
             );
         } else {
             return (
                 <div style={{height: '100%', textAlign: 'center'}}>
                     <PageHeader
                         ghost={false}
                         title={`Team QR`}
                         onBack={(() => window.history.back())}
                     />
                    <Spinner condition={() => { return this.isLoading() }} />
                 </div>
             );
         }
     }
}

