import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import firebase from 'firebase/app';
import qs from 'qs';
import { PageHeader, Tag , Button } from 'antd';
import {Redirect} from 'react-router-dom';

import Spinner from '../../../components/Spinner';
import { AccountsSelector } from '../../../components/client/AccountsSelector';
import BankAccountsModel from '../../../models/BankAccountsModel';
import {isBankUnique} from '../../../utils/PayoutUtils';

// /client/manage/profile
export default class BankClientProfileScreen extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      client: null,
      code: null,
      token: null,
      loading: true,
      fetchingAccounts: false,
      clientNotFound: false,
      country: null,
      bankModel: null
    };
  }

  getTrueLayerErrorCode(error) {
    if (error) {
      if (['validation_error', 'invalid_date_range', 'deprecated_provider', 'unauthorized',
        'invalid_token', 'access_denied', 'invalid_credentials_key', 'sca_exceeded',
        'account_not_found', 'provider_too_many_requests', 'provider_request_limit_exceeded'].includes(error)) {
        return '1';
      } else if (['internal_server_error', 'endpoint_not_supported', 'provider_error',
        'connector_overload', 'temporarily_unavailable', 'provider_timeout', 'connector_timeout'].includes(error)) {
        return '2';
      }
    }
    return '2';
  }

  async componentDidMount() {
    await this.waitOnLoginBeforeClientData();
    const queryParams = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
    if (queryParams.error) {
      this.setState({
        errorCode: this.getTrueLayerErrorCode(queryParams.error),
        fetchingAccounts: false,
        country: queryParams.state
      });
    } else if (queryParams.code) {
      this.setState({
        code: queryParams.code,
        fetchingAccounts: true,
        country: queryParams.state
      });
    }
  }

  componentWillUnmount() {
    if(this.clientModel) this.clientModel.stop();
    if(this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || false;
  }

  getCurrentUser = async (auth) => {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }

  waitOnLoginBeforeClientData = async () => {
    this.currentUser = await this.getCurrentUser(firebase.auth());
    this.fetchClientDataWithUserId(this.currentUser ? this.currentUser.uid : null);
  }

  fetchClientDataWithUserId = (ownerId) => {
    const db = firebase.firestore();
    const query = db.collection('clients').where("ownerId", "==", ownerId).limit(1);
    this.observerClient = query.onSnapshot(snap => {
      this.client = null;
      const size = snap.size;
      if (size > 0) {
        snap.forEach(
          doc => {
            this.client = doc.data();
            this.clientId = doc.id;
            this.foundClient(this.client, this.clientId);
          }
        );
      } else {
        this.clientNotFound();
      }
    });
  }

  foundClient = async (client, clientId) => {
    this.setState({
      client,
      clientId,
    });
    const isLocalHost = window.location.hostname === 'localhost';
    const getAccountsService = firebase.functions().httpsCallable('clientService-withdrawalHandler-getAccountsForClient');
    const payloadData = {
      is_test: isLocalHost,
      clientId: clientId,
      code: this.state.code,
      country: this.state.country
    }

    try {
      const response = await getAccountsService(payloadData);

      const { data } = response || {};
      const { accounts } = data || {};

      this.setState({
        loading: false,
        accounts: (accounts && accounts.length > 0) ? accounts : null,
        errorCode: (accounts && accounts.length > 0) ? null : '1',  //error code for all 4XX errors
      });
    } catch (error){
      console.log(`Error: ${error}`);
      this.setState({
        loading: false,
        errorCode: '1', //error code for all 4XX errors
      });
    }
    this.bankModel = new BankAccountsModel({ viewModel: this, clientId });
  }

  clientNotFound = () => {
    this.setState({
      loading: false,
      clientNotFound: true,
    });
  }

  addBankAccount = async (account, country) => {
    const { provider, providerImg, currency, display_name, number, sort_code, iban, swift_bic } = account || {};

    let payout = {
      country: country || null,
      bankName: provider || null,
      currency: currency || null,
      displayName: display_name || null,
      holderName: display_name || null,
      avatar: providerImg || null,
    }
    if (['UK', 'GB'].includes(country)) {
      payout.accountNumber = number || null;
      payout.sortCode = sort_code || null;
    } else {
      payout.iban = iban || null;
      payout.bic = swift_bic || null;
    }
    payout.source = 'truelayer';

    const db = firebase.firestore();
    let payoutQuerySnapshot = await db.collection('clients').doc(this.state.clientId).collection('payouts').get();
    let isUnique = true;
    if (payoutQuerySnapshot.size > 0) {
      let existingPayouts = payoutQuerySnapshot.docs.map(payoutSnapshot => payoutSnapshot.data());
      isUnique = isBankUnique(payout, existingPayouts);
    }

    if (isUnique) {
      await this.bankModel.makeNewPayoutObject(payout);
      this.props.history.push(`/client/manage/${this.clientId}/bank?trueLayerSuccess=true`);
    } else {
      this.setState({
        errorCode: '3', //3-duplicate account
        fetchingAccounts: false,
        country: country
      });
    }
  }

  render() {
    const country = this.state.country;
    if (this.state.errorCode) {
      return <Redirect to={`/client/manage/${this.clientId}/bank?errorCode=${this.state.errorCode}`}/>;
    }
    return (
        <div style={{height: '100%', textAlign: 'center'}}>
          <PageHeader
              ghost={false}
              title={`Bank Details`}
          />
          <Spinner condition={() => {
            return this.isLoading()
          }}/>
          {!this.isLoading() && this.state.accounts &&
              <AccountsSelector country={country} accounts={this.state.accounts} callback={(account) => {
                this.addBankAccount(account, country)
              }}/>
          }
          {!this.isLoading() && !this.state.accounts &&
              <>
                <p>Something went wrong. Please return to the 'Payouts' screen and try again</p>
                <Button onClick={(() => this.props.history.push(`/client/manage/${this.clientId}/bank`))}>Back</Button>
              </>
          }
        </div>
    );
  }
}

