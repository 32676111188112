import React, { Component } from 'react';
import firebase from 'firebase/app';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';

import TrackerService from '../../utils/TrackerService';
import ProductSwitcher from '../../utils/ProductSwitcher';
import { getMinimumCharge } from '../../utils/PaymentUtils';
import { ccyIsoCodeToSymbolMap } from '../../utils/CurrencyUtils';

import '../../App.scss';


const ELEMENT_OPTIONS = {
	style: {
		paymentRequestButton: {
		type: 'default',
		theme: 'dark',
		},
	},
};

export default class PaymentRequestButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canMakePayment: false,
      hasCheckedAvailability: false,
      errorMessage: null,
      walletProvider: null,
      customer: props.customer,
    };
  }

  async componentDidMount() {
		const stripe = this.props.stripe;
    if (stripe && !this.paymentRequest) {
      this.createPaymentRequest(stripe);
    }
	}

	getFees = () => {
		const feePercentage = this.props.appliedRevenueFeeSlab.percentage / 10000;
		const baseFee = this.props.appliedRevenueFeeSlab.base;
		return {feePercentage, baseFee};
	}
    
  async createPaymentRequest(stripe) {
		const selectedAmount = parseInt(this.props.selectedAmount);
		let amountChargable = selectedAmount;

		const productSwitcher = new ProductSwitcher({ locStrings: this.props.locStrings });
		const productMap = productSwitcher.getProductMap(this.props.tap.product);

		// Move strings to string file
		const mainLabel = productMap.stripe.label;
		ELEMENT_OPTIONS.style.paymentRequestButton.type = productMap.stripe.button;

		const displayItems = [
			{
				amount: selectedAmount,
				label: mainLabel,
			}
		];

		if (this.props.shouldAddOnFees) {
			const { feePercentage, baseFee } = this.getFees();
			const percentageFee = Math.floor(selectedAmount * feePercentage);
			const totalFee = percentageFee + baseFee;
			amountChargable += totalFee;

			// Move string to string
			displayItems.push({
				amount: totalFee,
				label: "Fees",
			});
		}
		
		const currency = this.props.tap.paymentDetails.currency;

		this.paymentRequest = stripe.paymentRequest({
			country: 'IE',
			currency: currency.toLowerCase(),
			displayItems,
			total: {
				label: `Total ${mainLabel.toLocaleLowerCase()}`,
				amount: amountChargable,
			},
			requestPayerName: true,
      requestPayerEmail: true,
		});

		const canMakePayment = await this.paymentRequest.canMakePayment();

		if (canMakePayment) {
			this.props.handler(true);
			const walletProvider = canMakePayment.applePay ? "apple-pay" : "other";
			this.setState({canMakePayment: true, hasCheckedAvailability: true, walletProvider: walletProvider});
		} else {
			this.setState({canMakePayment: false, hasCheckedAvailability: true});
		}
	
		this.paymentRequest.on('cancel', async (event) => {
			const walletProvider = this.state.walletProvider;

			const selectedAmount = parseInt(this.props.selectedAmount);
			let totalAmount = selectedAmount;
			let totalFee = 0;

			if (this.props.shouldAddOnFees) {
				const { feePercentage, baseFee } = this.getFees();
				const percentageFee = Math.floor(selectedAmount * feePercentage);
				totalFee = percentageFee + baseFee;
				totalAmount += totalFee;
			}

			const currency = this.props.tap.paymentDetails.currency;

			const tracker = new TrackerService({
				object: this.props.tapId,
				tapId: this.props.tapId,
			});

			const { decimalAmount, decimalFee } = tracker.intValuesToDecimals({ amount: totalAmount, fee: totalFee  });


			// Track all default data plus fees and amount as calculated in this method
			tracker.mixpanelTrack("cancelled_wallet_payment", {
				paidFees: this.props.shouldAddOnFees,
				fees: decimalFee,
				amount: decimalAmount,
				walletProvider: walletProvider,
				currency: currency,
			});

			if (!this.props.tap.is_test) {
				tracker.googleTrack(
					"cancelled_wallet_payment", 
					{
						currency: currency,
						items: [
						{
							brand: this.props.tap.placeOfWork,
							category: this.props.tap.product,
							id: this.props.tap.tagId,
							name: this.props.tap.displayName,
							price: decimalAmount,
							quantity: 1,
						},
						],
						value: decimalAmount,
						walletProvider: walletProvider,
					}
				);
 
			}
		});
    
    this.paymentRequest.on('paymentmethod', async (event) => {
      this.setState({paymentMethod: event.paymentMethod});

			// apple-pay
			// google-pay
			// microsoft-pay
			const walletProvider = event.methodName;
			
			const onStripeWalletPaymentFunction = firebase.functions().httpsCallable('tapService-tapHandler-onStripeWalletPayment');

			const tagId = this.props.tap.tagId;
			const tapId = this.props.tapId;
			const messageText = this.props.messageText;

			const { customerName, paymentInfo } = this.props.customer;

			const selectedAmount = parseInt(this.props.selectedAmount);

			const payloadData = {
				amount: selectedAmount,
				appliedRevenueFeeSlabId: this.props.appliedRevenueFeeSlabId,
				avatar: this.props.tap.avatar,
				customerEmail: event.payerEmail,
				customerName: customerName,
				displayName: this.props.tap.displayName,
				messageText: messageText,
				paymentInfo: paymentInfo,
				shouldAddOnFees: this.props.shouldAddOnFees,
				tagId: tagId,
				tapId: tapId,
				teamMemberId: this.props.teamMemberId,
				walletProvider: walletProvider,
			}

			let clientSecret, successUrl;
			try {
				const result = await onStripeWalletPaymentFunction(payloadData);
				if (result && result.data) {
					clientSecret = result.data.clientSecret;
					successUrl = result.data.successUrl;
				} else {
					console.log('This tag has been paused. Wallet payment canceled.');
					event.complete('fail');
				}
			} catch (error){
				console.log(error);
			}

      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: event.paymentMethod.id },
        { handleActions: false }
      );

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
				// the payment interface.
				console.log(confirmError);
				event.complete('fail');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        event.complete('success');
        
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11" instead
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const {error} = await stripe.confirmCardPayment(clientSecret);
          
          if (error) {
						console.log(error);
						// The payment failed -- ask your customer for a new payment method.
						alert("Payment method failed - please try again or use an alternative payment method");
          } else {
						// The payment has succeeded.
						this.props.history.push(successUrl);
          }
        } else {
          // The payment has succeeded.
          this.props.history.push(successUrl);
        }
      }
    });
	}

	async updatePaymentDetails(event) {
		const currency = this.props.tap.paymentDetails.currency;
		const selectedAmount = parseInt(this.props.selectedAmount);
		let amountChargable = selectedAmount;

		const productSwitcher = new ProductSwitcher({ locStrings: this.props.locStrings });
		const productMap = productSwitcher.getProductMap(this.props.tap.product);

		const mainLabel = productMap.label;

		const displayItems = [
			{
				amount: selectedAmount,
				label: mainLabel,
			}
		];

		let totalFee = 0;
		if (this.props.shouldAddOnFees) {
			const { feePercentage, baseFee } = this.getFees();
			const percentageFee = Math.floor(selectedAmount * feePercentage);
			totalFee = percentageFee + baseFee;
			amountChargable += totalFee;

			// Move string to string
			displayItems.push({
				amount: totalFee,
				label: "Fees",
			});
		}
		
		const minimumCharge = getMinimumCharge(currency);
		if (amountChargable < minimumCharge) {
			const minimumChargeString = (minimumCharge / 100).toFixed(2);
			const displayAmount = `${ccyIsoCodeToSymbolMap[currency]}${minimumChargeString}`;
			
			alert(`Error: Amount cannot be less than the minimum amount (${displayAmount})`);
			return;
		}

		const tracker = new TrackerService({
			object: this.props.tapId,
			tapId: this.props.tapId,
		});
		
		const { decimalAmount, decimalFee } = tracker.intValuesToDecimals({ amount: amountChargable, fee: totalFee  });

		// Track all default data plus fees and amount as calculated in this method
		tracker.mixpanelTrack("clicked_wallet_payment", {
			paidFees: this.props.shouldAddOnFees,
			fees: decimalFee,
			amount: decimalAmount,
			walletProvider: this.state.walletProvider,
		});

		if (!this.props.tap.is_test) {
			tracker.googleTrack(
				"clicked_wallet_payment", 
				{
					currency: currency,
					items: [
					{
						brand: this.props.tap.placeOfWork,
						category: this.props.tap.product,
						id: this.props.tap.tagId,
						name: this.props.tap.displayName,
						price: decimalAmount,
						quantity: 1,
					},
					],
					value: decimalAmount,
					walletProvider: this.state.walletProvider,
				}
			);	  
		}

		this.paymentRequest.update({
			currency: currency.toLowerCase(),
			displayItems,
			total: {
				label: `Total ${mainLabel.toLocaleLowerCase()}`,
				amount: amountChargable, // NOTE: this is a Stripe API and needs to be an INT and not a Decimal!!
			},
		});
	}

  render() {
    const {
      canMakePayment,
      paymentMethod,
    } = this.state;
		
		return (
      <form>
        {canMakePayment && (
          <PaymentRequestButtonElement
            onClick={(event) => {
              if (paymentMethod) {
                event.preventDefault();
                this.setState({
                  errorMessage:
                    'You can only use the PaymentRequest button once. Refresh the page to start over.',
                });
              } else {
                this.updatePaymentDetails(event);
              }
            }}
            options={{
              ...ELEMENT_OPTIONS,
              paymentRequest: this.paymentRequest,
            }}
          />
        )}
      </form>
    );
  }
}
