export const PaymentsMaxAmounts = {
    values: {
        "EUR": 500000,
        "GBP": 500000,
        "USD": 500000,
    },
};

export const DefaultTeamPermissions = {
    isTeamAdmin: false,
    canAddMembers: true,
    canPayMembers: false,
    canSeeMembers: true,
    canSeePayouts: false,
    canSeeAllTxns: false,
    canSeeTeamBalance: false,
};

export const AdminTeamPermissions = {
    isTeamAdmin: true,
    canAddMembers: true,
    canPayMembers: true,
    canSeeMembers: true,
    canSeePayouts: true,
    canSeeAllTxns: true,
    canSeeTeamBalance: true,
};


export const RestrictedTeamPermissions = {
    canAddMembers: false,
    canPayMembers: false,
    canSeeAllTxns: false,
    canSeeMembers: false,
    canSeePayouts: false,
    canSeeTeamBalance: false,
    isTeamAdmin: false,
};

export const FormItemLayout = {
    labelCol: {
        xs: {
            span: 24
        },
        sm: {
            span: 8
        },
    },
    wrapperCol: {
        xs: {
            span: 24
        },
        sm: {
            span: 16
        }
    },
};

export const TailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0
        },
        sm: {
            span: 16,
            offset: 8
        }
    }
};