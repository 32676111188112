import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import firebase from 'firebase/app';

import App from './App';
import * as serviceWorker from './serviceWorker';

import 'firebase/firestore';
import 'firebase/performance';
import 'firebase/analytics';
import 'firebase/functions';
import 'firebase/storage';

import './mui.min.css';
import './index.css';


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// ************************************
// *    Progressive Web App Settings  *
// *    MODE DEFAULT: `TEST`          *
// *    MOVE LIVE ONLY: `PRODUCTION`  *
// ************************************
// *    PRODUCTION MODE WARNING!      *
// ************************************

const swModeStartup = 'TEST';

if(swModeStartup === 'TEST') {
    serviceWorker.unregister();
} else if(swModeStartup === 'PRODUCTION') {
    serviceWorker.register();
    console.log("WARNING: Production Mode Set");
} else {
    serviceWorker.unregister();
}
       
const isLocalHost = window.location.hostname === 'localhost';

let config = {
    apiKey: "AIzaSyB_9ENQDze3o7L6Y4XVc3iXIJbZxLJAap8",
    authDomain: "app.strikepay.co",
    databaseURL: "https://strike-tips-demo.firebaseio.com",
    projectId: "strike-tips-demo",
    storageBucket: "strike-tips-demo.appspot.com",
    messagingSenderId: "243940245715",
    appId: "1:243940245715:web:3f181055ae3548a7fd5426",
    measurementId: "G-06CJSZLKHP"
};

if (isLocalHost) {
    config = {
        ...config,
        databaseURL: 'http://localhost:8080?ns=strike-tips-demo',
    };
}


// Initialize web service plugin 'strike-tips-demo'
firebase.initializeApp(config);

let settings = isLocalHost ? { host:"localhost:8080", ssl: false } : {};
firebase.firestore().settings(settings);
firebase.performance();
firebase.analytics();
firebase.storage();

if (isLocalHost) {
    firebase.functions().useEmulator("localhost", 5001);
    firebase.auth().useEmulator('http://localhost:9099/');
    firebase.firestore().useEmulator("localhost", 8080);
    firebase.storage().useEmulator("localhost", 9199);
}

ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));
