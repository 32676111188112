import { useState, useEffect } from 'react';

import StrikeTeamModel from '../../models/StrikeTeamModel';



export function useTeamDetails(teamId) {
    const [teamDetails, setTeamDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const onUpdateTeamDetails = async (team) => {
            setTeamDetails(team);
            setLoading(false);
        };


        const model = new StrikeTeamModel({
            teamId,
            onUpdate: onUpdateTeamDetails,
        });

        model.waitForTeam();

        return function cleanup() {
            model.stop();
        };

    },
        []
    );

    return teamDetails;
}
