import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import firebase from 'firebase/app';
import { Image, Row, Col, Card, Skeleton, Button } from 'antd';

import { StrikeColors } from '../../utils/Colors';
import img_elStrikeLogoImage_dark from '../../images/strikepay_Mark_url_RGB_White_small.png';


import 'antd/dist/antd.css';
import '../../App.scss';
import './divboxgradient.css'

const baseUrl = (window.location.hostname === 'localhost') ? 'http://localhost:8088' : 'https://pay.strikepay.co';

function modifyURL(url) {
  // Parse the URL using the URL constructor
  let urlObj = new URL(url);

  // Replace "/tags" with "/qr" in the pathname
  urlObj.pathname = urlObj.pathname.replace('/tags', '/qr');

  // Insert "/png" at the end of the path but before the query marker
  urlObj.pathname = urlObj.pathname.endsWith('/') ? `${urlObj.pathname}png` : `${urlObj.pathname}/png`;

  if (urlObj.port === '3000') {
    urlObj.port = '8088';
  }

  let modifiedURL = urlObj.toString();

  // Remove any trailing '?'
  if (modifiedURL.endsWith('?')) {
      modifiedURL = modifiedURL.slice(0, -1);
  }

  // Return the modified URL
  return modifiedURL;
}

function makeUrlForImage(tag, targetUrl) {
  let url = '';

  if (targetUrl) {
    url = modifyURL(targetUrl);
    return url;
  }


  const { tagId, templateTagId, tagUid } = tag;
  
  if (templateTagId && tagUid) {
    url = `${baseUrl}/qr/${templateTagId}/png?qr=${tagUid}`;
  } else {
    url = `${baseUrl}/qr/${tagId}/png`;
  }
  return url;
}

function makeUrlForButton(tag, targetUrl) {
  let url = '';

  if (targetUrl) {
    return url;
  }

  const { tagId, templateTagId, tagUid } = tag;

  if (templateTagId && tagUid) {
    url = `${baseUrl}/tags/${templateTagId}?qr=${tagUid}`;
  } else {
    url = `${baseUrl}/tag/${tagId}`;
  }
  return url;
}

export default class QRImageView extends Component {

  // Properties used by this component:
  // profileBio, displayName, avatar

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      imageData: null,
      productURL: null,
      tagId: props.tagId,
      tag: props.tag,
    };
  }

  componentDidMount() {
    this.waitForQRImageData();
  }

  waitForQRImageData = () => {
    if(this.props.tag || this.props.url) {
      this.setState({
        loading: false
      });  
      return;
    }

    this.setState({
      loading: true
    });
    const db = firebase.firestore();
    // console.log('getImageData for ', this.props.tagId);
    const doc = db.collection('QRs').doc(this.props.tagId);
    this.unsubscribe = doc.onSnapshot(snap => {
      if (snap.exists) {
        // console.log(`waitForQRImageData -- doc ${snap.data()}`);
        const data = snap.data();
        const { imageData, product, productURL, status } = data;
        if (status && status === 'generated') {
          this.unsubscribe();
          this.setState({
            imageData,
            productURL,
            product,
            status,
            loading: false
          });
        }
      }
    }, err => {
      console.log(`waitForQRImageData -- error ${err}`);
      this.setState({ loading: false, errorText: err.message, error: true });
      this.unsubscribe();
    });

  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {

    const { style } = this.props;
    const qrImageSize = 200;
    const qrLogoImageSize = 50;
    const offsetForLogo = qrImageSize + qrLogoImageSize/2;

    const view_style = {
      marginTop:'12px',
      marginLeft: 'auto',
      marginRight: 'auto',
    };

    const style_elSquareimage = {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundColor: 'transparent',
      borderRadius: 8,
      borderColor: 'green',
      paddingTop: '2px',
    };

    const style_elSquareInner = {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundColor: 'transparent',
      borderRadius: 8,
      borderColor: 'green',
      paddingTop: '2px',
    };

    const style_elCol = {
      borderColor: 'red',
      marginTop: '12px',
      marginBottom: '2px',
      width: qrImageSize,
      backgroundColor: 'transparent',
      alignText: 'center',
      color: 'green',
      borderRadius: 4,
      borderColor: StrikeColors.magenta,
      borderWidth: '2px',
      borderStyle: 'solid',
    };

    const innerQRSize = qrImageSize - 20;
    const loadingStyle = { width: innerQRSize, height: innerQRSize };

    const { imageData, productURL, loading, error } = this.state;

    const { tagId, tag, url } = this.props;
    tag.tagId = tagId || tagId.id;
    const imgUrl = tag ? makeUrlForImage(tag, url) : null;
    const buttonUrl = tag ? makeUrlForButton(tag, url) : url;

    return (
        <div className="QRImageView" style={view_style}>
          <Row id="qr-block" style={{ width: '220px', height: '220px', marginLeft: 'auto',
                        marginRight: 'auto' }} justify="center" align="middle" gutter={0}>
            <Col id="qr-image-overlay" span={24} justify="center" align="middle"   >
              <div className='QRdiv' style={{ width: '220px', height: '220px', paddingTop: '2px', marginLeft: 'auto', marginRight: 'auto' }}>
                <div style={style_elCol}>
                  { !loading && !error ?
                    <div style={{ 
                      display: 'flex',
                      justifyContent: 'center',
                      width: '190px', height: '190px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}>
                      <Image width={'190px'} src={imgUrl || imageData} alt={productURL} />
                      <div style={{ 
                        display: 'flex',
                        justifyContent: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}>
                        <img width={'60px'} height={'60px'} src={img_elStrikeLogoImage_dark}  
                          style={{ position: 'absolute', top: '80px', left: '80px', marginLeft: 'auto', marginRight: 'auto', }}
                        />
                      </div>
                    </div>
                    :
                    <Skeleton.Image active style={loadingStyle} />
                  }
                </div>
              </div>
              {!this.props.nosubtext && !loading && !error ?
                "SCAN QR CODE"
                :                     
                (!this.props.nosubtext && 
                  <div style={{ width: qrImageSize + 20, textAlign: 'center' }}>
                    <Skeleton.Button active style={{ width: qrImageSize - 40 }}/>
                  </div>
                )
              }
            </Col>
          </Row>
        {this.props.showButton && !loading && !error && buttonUrl &&
          <div style={{ textAlign: 'center', marginTop: '40px' }}>
            <Button key='confirm' type={'primary'} href={buttonUrl}>
              {`Try it!`}
            </Button>
            <div className="copyableBlock">
              <span>Tap to copy link</span>
              <CopyToClipboard
                text={buttonUrl}
                onCopy={() => {
                  this.setState({ linkCopied: !this.state.linkCopied });
                }}
              >
                <button className="promoCopyable">{this.state.linkCopied ? 'Copy Link Again' : 'Copy Link'}</button>
              </CopyToClipboard>
            </div>
            <p style={{ fontSize: '11px', fontStyle: 'italic' }}>
              {`${buttonUrl}`}
            </p>
          </div>
        }
        </div>
    );
  }

}
