import React, { useState } from 'react';
import { Space, Typography, Table, Tag, Statistic, Card, Row, Col , Button } from 'antd';
import {  ArrowUpOutlined } from '@ant-design/icons';

import timeSince from '../../utils/TimeSince';
import MoneyTitle from '../customer/MoneyTitle';
import HeroProfile from '../customer/HeroProfile';
import ProductSwitcher from '../../utils/ProductSwitcher';
import {ccyIsoCodeToSymbolMap} from '../../utils/CurrencyUtils';
import TrackerService from '../../utils/TrackerService';
import { ShowCashOutDialog } from '../client/ShowCashOutDialog';

import '../../App.scss';
import 'antd/dist/antd.css';

const { Paragraph } = Typography;

const isLocalHost = window.location.hostname === 'localhost';

const ccy = ccyIsoCodeToSymbolMap;

// TESTING: Test data to validate datasources
const datasourceForTesting = [
  {
    // REQUIRED fields:
    currency: 'EUR',
    is_test: false,
    product: 'tipping',
    balance: 300, decimalBalance: 3,
    // OPTIONAL fields:
    pending: 100, pendingDecimal: 1,
    available: 200, pendingAvailable: 2,
    withdrawn: 300, withdrawnDecimal: 3,
  },
  {
    currency: 'GBP',
    is_test: false,
    product: 'tipping',
    balance: 400, decimalBalance: 4,
    pending: 200, pendingDecimal: 2,
    available: 0, pendingAvailable: 0,
    withdrawn: 500, withdrawnDecimal: 5,
  },
  {
    currency: 'USD',
    is_test: true,
    product: 'tipping',
    balance: 1400, decimalBalance: 14,
    pending: 200, pendingDecimal: 2,
    available: 200, pendingAvailable: 2,
    withdrawn: 500, withdrawnDecimal: 5,
  }
];


const setDefaultPayout = (payouts, clientCurrencyStr) => {
  for (const [i, payout] of payouts.entries()) {
    if (payout.currency === clientCurrencyStr) {
      return i;
    }
  }

  return 0;
}

// MARK: Keeping Profile Image around for another run through tomorrow
export const ProfileImage = ({ condition, locStrings, client, ...rest }) => {
  return condition() ?
    <HeroProfile {...client} {...rest} object={client} locStrings={locStrings} />
    : null;
};


// TODO: When Screen is WIDER show a Grid instead of the Carousel (use `wide` param)
// accoutns is an array of dictionary with : {
//    currency, balance, decimalBalance, is_test, product // required
//    pending, avaliable, withdrawn
//    pendingDecimal, avaliableDecimal, withdrawnDecimal
// }
export const AccountBalancesView = ({ condition, locStrings, client, clientId, payouts, withdrawals, accounts, wide, noButtons, ...rest }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isCashOutVisible, setIsCashOutVisible] = useState(false);

  const showCashOutModal = () => {
    setIsCashOutVisible(true);
  };

  const handleCashOutOk = () => {
    setIsCashOutVisible(false);
  };

  const handleCashOutCancel = () => {
    setIsCashOutVisible(false);
  };

  const productSwitcher = new ProductSwitcher({ locStrings });
  let element = null;

  const testing = false;
  const datasource = testing ? datasourceForTesting : accounts;

  const rowColAccountElement =
    (<div className="accounts-balances-card" style={{ width: '100%', textAlign: 'center' }}>
      <Row justify='center' style={{ paddingBottom: '10px' }}>
        {datasource.map((v, i) => (
          <Col key={i} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }} >
            <Row gutter={16} key={'row-' + i} >
              <Col span={0} />
              <Col span={24}>
                <Card style={{ textAlign: 'center', padding: '20px', margin: 0 }}>
                  <Statistic
                    title={`Available ${productSwitcher.getProductMap(v.product).pluralName} (${v.currency})`}
                    value={((v.available || 0)/ 100).toFixed(2)}
                    precision={2}
                    valueStyle={{ color: '#3f8600' }}
                    prefix={ccy[v.currency]}
                  />
                  {(v.pending != null) &&
                    <div style={{ width: '100%' }}>
                      <Space direction='vertical'>
                        <Space align='baseline' direction='horizontal'>
                          <p style={{ fontWeight: 'bolder' }}>Pending:</p>
                          <MoneyTitle amount={v.pending} currency={v.currency} digits={2} />
                        </Space>
                        <Space align='baseline' direction='horizontal'>
                          <p style={{ fontWeight: 'bold' }}>Total:</p>
                          <MoneyTitle amount={v.total} currency={v.currency} digits={2} />
                        </Space>
                      </Space>
                    </div>
                  }

                  {!noButtons &&
                    (
                      <>
                        <Button disabled={!(v.availableAccountId && v.available > 0)} type={(!v.is_test) ? "primary" : "dashed"} style={{ marginLeft: 'auto', marginRight: 'auto' }} onClick={showCashOutModal}>{(v.available) ? 'Cash Out' : 'No Cash Out'}</Button>
                        <ShowCashOutDialog visible={isCashOutVisible} handleOk={handleCashOutOk} handleCancel={handleCashOutCancel} accountId={v.availableAccountId} clientId={clientId} currency={ccy[v.currency]} clientCurrencyStr={v.currency} payouts={payouts} withdrawals={withdrawals} balance={v.available} isAdmin={true} {...rest} />
                      </>
                    )
                  }
                </Card>
              </Col>
              <Col span={0} />
            </Row>
          </Col>
        ))
        }
      </Row>
    </div>);

  if (datasource.length > 0) {
    element = rowColAccountElement;
  }


  return condition() ? element : null;

};


export const RecentTransactionsTable = ({ condition, locStrings, client, accounts, transactions, ...rest }) => {

  if (!condition()) return null;



  const dataSource = [
  ];

  const productSwitcher = new ProductSwitcher({ locStrings });

  transactions.map(
    (transaction, i) => {
      const productMap = productSwitcher.getProductMap(transaction.product);
      return dataSource.push(
        {
          key: `tx-${i}`,
          date: timeSince(transaction.createdAt.toDate()), // transaction.timestamp, // 1612816116399
          type: {
            typeName: (transaction.credit > 0 ? productMap.credit.name : productMap.debit.name),
            typeColor: (transaction.credit > 0 ? productMap.credit.color : productMap.debit.color),
            typeDescription: transaction.description,
            productColor: productMap.color,
          },
          currency: transaction.currency,
          value: { currency: transaction.currency, amount: transaction.amount },
          balance: 0,
          description: `${transaction.description} `,
        }
      );
    });


  const columns = [
    {
      title: 'When',
      dataIndex: 'date',
      key: 'date',
      render:
        date => (
          <>
            <Tag color='geekblue'>{date}</Tag>
          </>
        )
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render:
        type => (
          <>
            <Tag color={type.typeColor}>{type.typeDescription}</Tag>
            <Tag color={type.productColor}>{type.typeName}</Tag>
          </>
        )
    },
    {
      title: 'Amount',
      dataIndex: 'value',
      align: 'right',
      render:
        value => (
          <div >
            { (value.amount !== 0) &&
              (<MoneyTitle amount={value.amount} currency={value.currency} digits={2} />)
            }
            { (value.amount === 0) &&
              (<Tag color={'blue'}>none</Tag>)
            }
          </div>
        )
    },
  ];

  return condition() ?
    <Table
      style={{ width: '100%' }}
      dataSource={dataSource}
      columns={columns}
      pagination={{ pageSize: 40 }} scroll={{ y: 300 }}
      expandable={{
        expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
        rowExpandable: record => record.name !== 'Not Expandable',
      }}
    />
    : null;

};




