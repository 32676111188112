import React from 'react';
import { Timeline } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled, StopFilled, SyncOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useProgressCollection } from '../../models/useProgressCollection';
import { usePayoutDetails } from '../../models/usePayoutDetails';
import { useWithdrawalDetails } from '../../models/useWithdrawalDetails';

export const ProgressListView = ({ progressCollection, item, isPendingCallback, shouldUseDate }) => {

  const isPending = () => {
    if(isPendingCallback) { return isPendingCallback(item); }

    if (item.status) {
      return ['created', 'ready', 'validated', 'connecting', 'waiting', 'api_requested', 'processing'].includes(item.status);
    }
    return false;
  };

  const dotColor = (progress) => {
    const { type } = progress || {};
    switch (type) {
      case 'info':
        return 'blue';
      case 'warn':
        return 'orange';
      case 'success':
        return 'green';
      case 'fail':
        return 'red';
    }
    return 'blue';
  };

  const dotIcon = (progress, spin) => {
    const { type } = progress || {};
    switch (type) {
      case 'warn':
        return <ExclamationCircleFilled />;
      case 'success':
        return <CheckCircleFilled />;
      case 'fail':
        return <StopFilled />;
    }
    return spin ? <SyncOutlined spin /> : null;
  };

  const isSpin = (index) => {
    return progressCollection && ((progressCollection.length - 1) === index) && isPending();
  }

  const useDate = (element) => {
    if(!shouldUseDate) { return ''; }
    const { createdAt } = element || { }; 
    const createdDate = createdAt ? moment(createdAt.toDate()).format('lll') : '';
    return createdDate;
  }

  const progressMessage = (element) => {  
    let msg = '';
    if(!element) { return ''; }  
    const { message } = element || {};  
    if(message && message.length > 0) {
      msg += message;
    }
    
    return msg;
  } 

  const progressDate = (element) => {
    if(!element) { return ''; }  
    const { createdAt } = element || { }; 
    const createdDate = createdAt ? moment(createdAt.toDate()).format('lll') : '';
    return createdDate;
  }


  return (
    <Timeline pending={null} mode='right'>
      {progressCollection.map((progress, index) => (
        <Timeline.Item key={index} color={dotColor(progress)} dot={dotIcon(progress, isSpin(index))} label={progressDate(progress)}>
          <p style={{ margin: '0px', fontWeight: 'bold' }}>
            {progressMessage(progress)}
          </p>
          <p style={{ margin: '0px' }}>
            {progress.info || ''}
          </p>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export const WithdrawalProgressListView = ({ clientId, withdrawalId }) => {
  const progressCollection = useProgressCollection('withdrawals', clientId, withdrawalId);
  const withdrawal = useWithdrawalDetails(clientId, withdrawalId);

  return (
    <ProgressListView progressCollection={progressCollection} item={withdrawal} shouldUseDate={true} />
  );
};

const PayoutProgressListView = ({ clientId, payoutId }) => {
  const progressCollection = useProgressCollection('payouts', clientId, payoutId);
  const payout = usePayoutDetails(clientId, payoutId);

  return (
    <ProgressListView progressCollection={progressCollection} item={payout} />
  );
};

export default PayoutProgressListView;
