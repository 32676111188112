import { useState, useEffect } from 'react';

import StrikeTeamTransfersModel from '../../models/StrikeTeamTransfersModel';
import StrikeTeamDetailsModel from '../../models/StrikeTeamDetailsModel';



export function useTeamRecentActivities(teamId, memberClientId, displayPermissions, dateRange) {    
    const [recentActivies, setRecentActivities] = useState(null);
    let fetchTeamTaps = false; 
    let fetchMemberTaps = true;
    let fetchTransfers = false;

    useEffect(() => {
        const onUpdateRecentActivities = async (activies) => {
            setRecentActivities(activies);
        };

        const model = new StrikeTeamDetailsModel({
            onUpdateActivities: onUpdateRecentActivities,
            memberClientId,
            teamId,
            fetchTeamTaps, 
            fetchMemberTaps, 
            fetchTransfers, 
            limitedToMember: memberClientId,
            dateRange,
            permissions: displayPermissions,
        });
        
        model.waitForTeamTransactions();

        return function cleanup() {
            model.stop();
            setRecentActivities(null);
        };

    },
        [dateRange, displayPermissions]
    );

    return recentActivies;
}
