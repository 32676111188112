// MARK: Helper functions for building URLs

export function getAccountsCcyTotals(accounts) {
  let totals = {};

  accounts.forEach((account) => {
    if (totals.hasOwnProperty(account.currency)) {
      totals[account.currency] = totals[account.currency] + account.decimalBalance
    } else {
      totals[account.currency] = account.decimalBalance
    }
  });

  Object.keys(totals).forEach(key => totals[key] = parseFloat(parseFloat(totals[key]).toFixed(2)));
  return totals;
}

export function getMaxAccountCcyTotal(accounts) {
  const totals = getAccountsCcyTotals(accounts);
  const highestCcy = Object.keys(totals).reduce((a, b) => (totals[a] > totals[b]) ? a : b);
  return { 
    currency: highestCcy, 
    decimalBalance: totals[highestCcy],
  };
}
