import React, { Component } from 'react';
import { Progress, Typography, Row, Col, Tag, Space } from 'antd';

import ProductSwitcher from '../../utils/ProductSwitcher';

import MoneyTitle from './MoneyTitle';

import '../../App.scss';
import 'antd/dist/antd.css';

const { Paragraph } = Typography;

export default class RunningTotalsCard extends Component {

    constructor(props) {
        super(props);

        const goalTarget = this.getGoalTarget();

        this.state = {
            withGoal: (goalTarget !== null && goalTarget > 0),
            forceDisplay: !!props.forceDisplay,
            asTag: !!props.asTag
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate() {
    }

    render() {
        const shouldShowCard = this.hasStrikes();
        return shouldShowCard ? this.renderRunningTotalsCard() : null;
    }

    hasStrikes = () => {
        const { totalNetValue, countOfStrikes } = this.getRunningTotals();
        return this.state.forceDisplay || (totalNetValue > 0 && countOfStrikes > 0);
    }

    renderRunningTotalsCard = () => {
        return this.state.asTag ? this.blockWithoutGoalAsTag() : this.renderRunningTotalsCardFull();
    }

    renderRunningTotalsCardFull = () => {
        const { span_completion, span_percentage } = this.getColumnSpans();
        const percentageBlock = this.blockPercentageComplete();
        const completionBlock = this.blockCompletionDescription();

        const style_elRunningTotalsCard = {
            textAlign: 'center',
            padding: 0,
            width: "100%",
            margin: "auto",
            marginTop: 20,
        };

        return (
            <div className="RunningTotalsCard">
                <div style={style_elRunningTotalsCard}>
                    <Row id="progress-totals" style={{ background: 'transparent', borderRadius: 8 }} justify="center" align="middle" gutter={0}>
                        <Col id="percentage" span={span_percentage}>
                            {percentageBlock}
                        </Col>
                        <Col id="progress" span={span_completion}>
                            {completionBlock}
                        </Col>
                    </Row>
                </div>
            </div>
        );

    }

    blockPercentageComplete = () => {
        let percentageOfTarget = this.getPercentageofTarget();
        const { text_color_rgba, bg_color_rgba } = this.getColorsBasedOnPercentageCompletion(percentageOfTarget);
        const fontSizeForPercentage = Math.max(14, 21 - (percentageOfTarget/100)); // Font size Range: [14..20]
        percentageOfTarget = percentageOfTarget ? percentageOfTarget : "<1"; // always show 0% as <1%

        const style_box = {
            height: 60,
            background: bg_color_rgba,
            color: text_color_rgba,
            borderRadius: 4,
        };

        const style_percentage = {
            fontSize: fontSizeForPercentage,
            color: text_color_rgba,
            margin: 8
        };

        const style_footer = {
            fontSize: 10,
            color: text_color_rgba,
            height: 30
        };

        const style_row1 = {
            height: 40,
            background: "transparent",
        };

        const style_row2 = {
            height: 20,
            background: "transparent",
        };

        return (
            <div style={style_box}>
                <Row id="percentage-big-text" justify='space-around' style={style_row1}>
                    <Paragraph style={style_percentage}>{percentageOfTarget}%</Paragraph>
                </Row>
                <Row id="percentage-footer" justify='space-around' style={style_row2}>
                    <Paragraph style={style_footer}>complete</Paragraph>
                </Row>
            </div>
        );
    }

    blockCompletionDescription = () => {
        const fontSize = this.getFontSizeForDescription();


        const style_row1 = {
            fontSize: fontSize,
            height: 40,
            background: "transparent",
        };

        const style_row2 = {
            height: 20,
            background: "transparent",
        };

        const descriptionBlock = this.blockDescription();
        const progressBlock = this.blockProgressBar();

        return (
            <div>
                <Row id="progress-text" justify='center' style={style_row1}>
                    {descriptionBlock}
                </Row>
                <Row id="progress-slider" justify='center' style={style_row2}>
                    {progressBlock}
                </Row>
            </div>
        );
    }

    blockDescription = () => {
        return  this.state.withGoal ? this.blockWithGoal() : this.blockWithoutGoal();
    }

    blockWithoutGoal = () => {
        const typeText = this.getTypeText();
        const { countOfStrikes, totalNetValue, totalFeeValue, currency } = this.getRunningTotals();
        const runningTotalText = ` in ${countOfStrikes} ${typeText}`;

        const style = {
            color: 'white',
            background: "black",
            paddingLeft: 18,
            paddingRight: 18,
            paddingTop: 4,
            paddingBottom: 4,
            borderRadius: 4,
        };

        return totalNetValue ? (
            <Paragraph autosize={"true"} type={'primary'} style={style}>
                <MoneyTitle amount={totalNetValue} fees={totalFeeValue} digits={0} currency={currency} />
                {runningTotalText}
            </Paragraph>
        ) : null;
    }

    blockWithoutGoalAsTag = () => {
        const typeText = this.getTypeText();
        const { countOfStrikes, totalNetValue, totalFeeValue, currency } = this.getRunningTotals();
        const runningTotalText = ` in ${countOfStrikes} ${typeText}`;
        return totalNetValue ? (
            <Tag color='darkgreen'>
                <MoneyTitle amount={totalNetValue} fees={totalFeeValue} digits={0} currency={currency} />
                {runningTotalText}
            </Tag>
        ) : null;
    }

    blockWithGoal = () => {
        const { countOfStrikes, totalNetValue, totalFeeValue, currency } = this.getRunningTotals();
        const typeText = this.getTypeText();
        const goalTarget = this.getGoalTarget();
        const goalText = ` of `;
        const runningTotalText = ` in ${countOfStrikes} ${typeText}`;

        const style_paragraph = { width: '90%', margin: 'auto', height: 40, fontSize: 14 };
        return this.state.withGoal ? (
            <Paragraph autosize={"true"} type={'primary'} style={style_paragraph}>
                <MoneyTitle amount={totalNetValue} fees={totalFeeValue} digits={0} currency={currency} />
                {goalText}
                <MoneyTitle amount={goalTarget} digits={0} currency={currency} />
                {runningTotalText}
            </Paragraph>
        ) : null;
    }

    blockProgressBar = () => {
        const percentageOfTarget = this.getPercentageofTarget();
        const { bg_color_rgba } = this.getColorsBasedOnPercentageCompletion(percentageOfTarget);

        const style_progresBlock = { width: '90%', margin: 'auto', height: 20};
        return this.state.withGoal ? (
            <Progress
                percent={percentageOfTarget}
                strokeColor={{ from: 'lightgreen', to: bg_color_rgba }}
                showInfo={false}
                steps={0}
                trailColor='lightgrey'
                style={style_progresBlock} 
            />
        ) : null;
    }

    getColumnSpans = () => {
        const span_percentage = this.state.withGoal ? 6 : 0;
        const span_completion = 24 - span_percentage;
        return {  span_percentage, span_completion };
    }

    getRunningTotals = () => {
        const totalNetValue = this.props.object.totalNetValue || 0;
        const totalFeeValue = this.props.object.totalFeeValue || 0;
        const countOfStrikes = this.props.object.countOfTaps || 0;
        const paymentDetails = this.props.object.paymentDetails || { 
            shouldAddOnFees: true,
            currency: 'EUR',
        };
        const currency = paymentDetails.currency || 'EUR';
        return { countOfStrikes, totalNetValue, totalFeeValue, currency };
    }

    getPercentageofTarget = () => {
        const { totalNetValue } = this.getRunningTotals();
        const goalTarget = this.getGoalTarget();
        return goalTarget ? Math.round(100 * (totalNetValue / goalTarget)) : 0;
    }

    getColorsBasedOnPercentageCompletion = (percentageOfTarget) => {
        const bg_color_percent_value = 0.8 + 0.2 * (percentageOfTarget / 100);
        const bg_color_rgba = `rgba(0, 127, 0, ${bg_color_percent_value})`;
        const text_color_value = 200 + 55 * (percentageOfTarget / 100);
        const text_color_rgba = `rgba(${text_color_value}, ${text_color_value}, ${text_color_value}, 1.0)`;
        return { text_color_rgba, bg_color_rgba };
    }

    getTypeText = () => {
        const product = this.props.object.product;
        const productSwitcher = new ProductSwitcher({ locStrings: this.props.locStrings });
        const productMap = productSwitcher.getProductMap(product);

        return productMap.type;
    }

    getGoalTarget = () => {
        let { values, selectedValueByIndex } = this.props.object;
        selectedValueByIndex = selectedValueByIndex || 0;
        values = values || [10000];
        const defaultValue = values[selectedValueByIndex] * 10;
        const isTest = !!(this.props.object.is_test);
        const mode = isTest ? 'test' : 'live';
        const goals = this.props.object.goals || 
                        (this.props.forceDisplay ? { 
                                'test' : defaultValue,
                                'live' : defaultValue,
                            } : null);
        const goalTarget = goals ? goals[mode] : null;
        return goalTarget;
    }

    getFontSizeForDescription = () => {
        const goalTarget = this.getGoalTarget();
        const fontSize = this.state.withGoal ? 16 : 20;
        return fontSize;
    }
}
