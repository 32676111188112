import React, { useState } from 'react';
import {
  Card,
  Typography,
  List,
  Divider,
  Space,
  Tabs,
} from 'antd';

import MoneyTitle from '../customer/MoneyTitle';

const { Text } = Typography;
const { TabPane } = Tabs;

// FEES come from /clients/{id}/fees (depends on currency, and type of account)
const mainrevenuePricingMap = {
  tipping: {},
  payment: {},
  giving: {},
};

// TIPPING:

mainrevenuePricingMap.tipping['fees_tipping_slab_below_eq_8'] = {
  percentage: 600,   // 6%
  base: 6,           // 0c
  vat: 'IE'          // 21%
};

mainrevenuePricingMap.tipping['fees_tipping_slab_above_8'] = {
  percentage: 500,   // 3%
  base: 12,          // 0c
  vat: 'IE'          // 21%
};

mainrevenuePricingMap.tipping['fees_tipping_slab_above_25'] = {
  percentage: 400,   // 2%
  base: 36,          // 0c
  vat: 'IE'          // 21%
};

mainrevenuePricingMap.tipping['fees_tipping_slab_above_50'] = {
  percentage: 300,   // 1.5%
  base: 86,          // 0c
  vat: 'IE'          // 21%
};

// GIVING:

mainrevenuePricingMap.giving['fees_giving_slab_below_eq_8'] = {
  percentage: 600,   // 6%
  base: 6,           // 0c
  vat: 'IE'          // 21%
};

mainrevenuePricingMap.giving['fees_giving_slab_above_8'] = {
  percentage: 500,   // 3%
  base: 12,          // 0c
  vat: 'IE'          // 21%
};

mainrevenuePricingMap.giving['fees_giving_slab_above_25'] = {
  percentage: 400,   // 2%
  base: 36,          // 0c
  vat: 'IE'          // 21%
};

mainrevenuePricingMap.giving['fees_giving_slab_above_50'] = {
  percentage: 300,   // 1.5%
  base: 86,          // 0c
  vat: 'IE'          // 21%
};

// PAYMENT:

mainrevenuePricingMap.payment['fees_payment_slab_below_eq_8'] = {
  percentage: 200,   // 6%
  base: 6,           // 0c
  vat: 'IE'          // 21%
};

mainrevenuePricingMap.payment['fees_payment_slab_above_8'] = {
  percentage: 200,   // 3%
  base: 12,          // 0c
  vat: 'IE'          // 21%
};

mainrevenuePricingMap.payment['fees_payment_slab_above_25'] = {
  percentage: 200,   // 2%
  base: 25,          // 0c
  vat: 'IE'          // 21%
};

mainrevenuePricingMap.payment['fees_payment_slab_above_50'] = {
  percentage: 200,   // 1.5%
  base: 25,          // 0c
  vat: 'IE'          // 21%
};




export function getDefaultRevenuePricing({ product }) {
  if (product) {
    return mainrevenuePricingMap[product];
  }
  return {
    ...mainrevenuePricingMap['tipping'],
    ...mainrevenuePricingMap['payment'],
    ...mainrevenuePricingMap['giving'],
  };
}

export function getPricingCategories({ pricing }) {
  const categories = { };

  pricing.map((v) => {
    const { id } = v;
    const [prefix, product, rest] = id.split('_');
    if (!categories[product]) {
      categories[product] = [];  
    }
    categories[product].push(v);
  }); 

  return categories;
}

const PercentageText = ({ percentage }) => {
  const calc = percentage / 100;
  const textCalc = calc.toFixed(2);
  const display = `${textCalc}%`;
  return <Text type={'secondary'}>{display}</Text>;
}

function properCase(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

const TierKeyToPlainEnglishView = ({ id, currency, isDefault }) => {
  // Split the key by underscores
  const [prefix, product, slab, conditionA, valueOrEq, valueOrNull] = id.split('_');
  const comparison = (valueOrEq === 'eq') ? `${conditionA}_eq` : conditionA;
  const thresholdStr = (valueOrEq === 'eq') ? valueOrNull : valueOrEq;
  const threshold = parseInt(thresholdStr, 10) * 100;

  let conditionText = 'equal to';
  switch (comparison) {
    case 'above':
      conditionText = 'above';
      break;
    case 'below':
      conditionText = 'below';
      break;
    case 'above_eq':
      conditionText = 'above or equal to';
      break;
    case 'below_eq':
      conditionText = 'below or equal to'
      break;
  }
  const defaultText = isDefault ? 'Uses default ' : '';
  const prefixText = properCase(`${defaultText}${product} fees for amounts ${conditionText}`);

  return <div style={{ fontSize: '13px', color: 'green' }}>
    <Text strong>{prefixText}</Text>
    <Text strong>{'  '}</Text>
    <MoneyTitle amount={threshold} currency={currency} digits={2} />
    <Text strong>{' : '}</Text>
  </div>;
}

/** 
 * tiers:
 *  Array of Tiers
 *  
 *  Tier:
 *    `id`, `currency`, `amount`, `base`, `percentage`
 * 
 */

const TierViewCard = ({ tier, edit, product }) => {
  const [isEdit, setEdit] = useState(!!edit);
  const [updatedTier, setUpdatedTier] = useState(tier);
  const { id, currency, amount, base, percentage } = updatedTier || tier || {};

  return <>
    {updatedTier &&
      <Space>
        <Text code>{`amount`}</Text>
        <Text code>{'*'}</Text>
        <PercentageText percentage={percentage} />
        <Text code>{'+'}</Text>
        <MoneyTitle amount={base} currency={currency} digits={2} />
        <Text>{' for each transaction'}</Text>
      </Space>
    }
  </>;

}


const PricingSlabsView = ({ tiers, product, isDefault }) => {
  const [updatedTiers, setUpdatedTiers] = useState(tiers);

  return <>
    {
      <List
        align='start'
        dataSource={updatedTiers}
        renderItem={item => (
          <List.Item
            key={item.id}
          >
            <List.Item.Meta
              title={
                <TierKeyToPlainEnglishView
                  id={item.id} currency={item.currency} isDefault={isDefault}
                />}
              description={<TierViewCard key={`list-${item.id}`} tier={item} product={product} />}
            />
          </List.Item>
        )}
      >
      </List>
    }
  </>;
}

export const CategoryPricingSlabsView = ({ tiers, isDefault }) => {
  const categories = getPricingCategories({ pricing: tiers });
  const items = Object.keys(categories);
  const singleProduct = items.length === 1 ? items[0] : null;

  return <>
    { items.length > 1 &&
      <Tabs type='card' style={{ marginTop: '1em' }}>
        {items.map((product) => (
          <TabPane tab={product} key={product}>
            <PricingSlabsView
              tiers={categories[product]} isDefault={isDefault} product={product}
            />
          </TabPane>
        ))
        }
      </Tabs>
    }
    { singleProduct &&
      <>
      <Divider />
      <PricingSlabsView
        tiers={categories[items[0]]} isDefault={isDefault} product={singleProduct}
      />
      </>
    }
    <Divider />
  </>;
}


export function convertPricingToArray({ pricing, currency }) {
  const keys = Object.keys(pricing);
  const pricingArray = [];

  keys.map((k) => {
    const { base, percentage } = pricing[k];
    const priceItem = {
      id: `${k}`,
      base, percentage,
      amount: 0,
      currency,
    }
    pricingArray.push(priceItem);
  });

  return pricingArray;
}

export const WarningLineCard = ({ }) => {
  return <Text type='danger'>Contact support if you think any of these fees are wrong.</Text>;
}
