import React, { Component } from 'react';
import '../../App.scss';
import { loadStripe } from '@stripe/stripe-js';
// UI framework component imports
import Button from 'muicss/lib/react/button';
import {Button as AntButton, Checkbox } from 'antd';
import { Elements } from '@stripe/react-stripe-js';
import firebase from 'firebase/app';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {ShareAltOutlined} from '@ant-design/icons';

import ProductSwitcher from '../../utils/ProductSwitcher';
import { paymentUrl } from '../../utils/URLBuilder';
import TrackerService from '../../utils/TrackerService';
import { getMinimumCharge } from '../../utils/PaymentUtils';

import ProgressSpinner from './ProgressSpinner';
import MoneyTitle from './MoneyTitle';
import PaymentRequestButtons from './PaymentRequestButtons';

function TotalToPay(props) {
  const {
    amount,
    currency,
    fee,
    feesAdded,
  } = props;

  return (
    (amount && amount > 0)
      ? (
        <div style={{ textAlign: 'center' }}>
          {'Total to pay '}
          <MoneyTitle amount={amount} currency={currency} digits={2} fees={amount > 0 ? (feesAdded ? fee : 0) : 0} />
        </div>
      )
      : null
  );
}


export default class PaymentCard extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);

    this.state = {
      feesCheckbox: false,
      showFeesCheckbox: true,
      isLoading: this.props.loading,
      isInProgress : false,
      actions: this.props.actions || {
        'pay' : true,
        'claim' : false,
        'publish' : false ,
        'edit' : false,
        'unclaim' : false,
        'register' : false,
        'account' : false,
      },
      session_id: this.props.stripe_session_id,
      walletProviderPresent: false,
      shareUrlCopied: false,
    };

    this.paymentButtonHandler = this.paymentButtonHandler.bind(this);
  }

  async componentDidMount() {
    const shouldAddOnFees = this.props.tap.paymentDetails.shouldAddOnFees;
    const shouldShowFeesOption = (this.props.tap.paymentDetails.shouldShowFeesOption != null) ? this.props.tap.paymentDetails.shouldShowFeesOption : true;
    const stripe = await this.getStripeForTap(this.props.tap);

    this.setState({ stripe, feesCheckbox: shouldAddOnFees, showFeesCheckbox: shouldShowFeesOption });
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  async getStripeForTap(tap) {
    const stripeEUPubKeys = {
      "live": "pk_live_51Ha7SzC9jbQqUT81LkLPhtMRaNxwFySSWoIJg6p2tLEpHwEGyJp0PFZHhXJIVctXIF9m4vj5BmEYvS0sRUzyVIp600zuwlwBjL",
      "test": "pk_test_51Ha7SzC9jbQqUT81ypqbqvXNmgswmxTPmX0LLheaMGmdVI5PVBrUuwrqO6vcJc7Ufbsf5pIstbUTf3nuG9g21uqU00jxwGBMjL",
    };

    if (tap.currency === "GBP") {
      return tap.is_test ? loadStripe(stripeEUPubKeys.test) : loadStripe(stripeEUPubKeys.live);
    } else if (tap.currency === "EUR") {
      return tap.is_test ? loadStripe(stripeEUPubKeys.test) : loadStripe(stripeEUPubKeys.live);
    } else {
      // Fallback platform = EU/IE
      return tap.is_test ? loadStripe(stripeEUPubKeys.test) : loadStripe(stripeEUPubKeys.live);
    }
  }

  /// PRICING: DEFAULT, `EUR`, `IE`
  /// 6% + 6c
  /// Incl VAT @21%
  getFees = () => {
      const percentage = this.props.appliedRevenueFeeSlab.percentage / 10000;
      const basefee = this.props.appliedRevenueFeeSlab.base;
      return {percentage, basefee};
  }

  handleCheckout = async (ev) => {
    this.setState({ isInProgress: true, infoText: 'Preparing payment...' });

    const value_selected_amount = this.props.selectedAmount;
    const { percentage, basefee } = this.getFees();
    const value_selected_fee = this.props.fee || (parseInt(value_selected_amount * percentage) + basefee);
    const amount = ( parseInt(value_selected_amount) + parseInt(value_selected_fee) );
    const fee = value_selected_fee;

    const tracker = new TrackerService({
      object: this.props.tap,
      tapId: this.props.tapId,
    });

    const { decimalAmount, decimalFee } = tracker.intValuesToDecimals({ amount, fee });

    // Track all default data plus fees and amount as calculated in this method
    tracker.mixpanelTrack("begin_checkout", {
      fees: decimalFee,
      amount: decimalAmount,
      paidFees: this.state.feesCheckbox || false,
    });

    if (!this.props.tap.is_test) {
      tracker.googleTrack(
        "begin_checkout",
        {
          currency: "EUR",
          items: [
            {
              brand: this.props.tap.placeOfWork,
              category: this.props.tap.product,
              id: this.props.tap.tagId,
              name: this.props.tap.displayName,
              price: decimalAmount,
              quantity: 1,
            },
          ],
          value: decimalAmount,
        }
      );
    }

    const amountChanged = this.props.tap.amount !== this.props.selectedAmount;
    const feesChanged = this.props.tap.paymentDetails.shouldAddOnFees !== this.state.feesCheckbox;
    const messageChanged = this.props.tap.messageText !== this.props.messageText
    const appliedRevenueFeeSlabIdChange = this.props.tap.appliedRevenueFeeSlabId !== this.props.appliedRevenueFeeSlabId

    const tapChanges = amountChanged || feesChanged || messageChanged || appliedRevenueFeeSlabIdChange || this.props.teamMemberId;
    const sessionInvalid  = amountChanged || feesChanged;

    if (tapChanges) {
      await this.updateTap();
    }

    let sessionId = this.props.tap.stripe_session_id;

    if (!sessionId || sessionInvalid) {
      const createCheckoutSessionFunction = firebase.functions().httpsCallable('tapService-tapHandler-createCheckoutSession');

      const result = await createCheckoutSessionFunction({
        tagId: this.props.tap.tagId,
        tapId: this.props.tapId,
      });

      if (result && result.data && result.data.session) {
        this.setState({ session: result.data.session });
        sessionId = result ? result.data.session.id : sessionId;
      } else {
        this.setState({ isInProgress: false, errorText : 'This tag has been paused. ', infoText: '' });
        return;
      }
    }

    const result = await this.state.stripe.redirectToCheckout({
      sessionId: sessionId,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      console.log(`handle submit -- error ${result.error.message}`);
      this.setState({ isInProgress: false, errorText : result.error.message, infoText: '' });
    } else {
      this.setState((state) => { return { isInProgress: false, errorText : ``, infoText: '' } });
    }
  }

  updateTapProperty = async(propertyName, value) => {
    const updateTapPropertyFunction = firebase.functions().httpsCallable('tapService-tapHandler-updateTapProperty');

    await updateTapPropertyFunction({
      property: propertyName,
      value,
      tagId: this.props.tap.tagId,
      tapId: this.props.tapId,
    });
  }

  async updateTap() {
    const tapObjUpdates = [
      this.updateTapProperty("amount", this.props.selectedAmount),
      this.updateTapProperty("paymentDetails.shouldAddOnFees", this.state.feesCheckbox),
      this.updateTapProperty("appliedRevenueFeeSlabId", this.props.appliedRevenueFeeSlabId),
    ];

    if (this.props.messageText !== "You're my hero!" && this.props.messageText !== "Add your message") {
      tapObjUpdates.push(this.updateTapProperty("messageText", this.props.messageText));
    }

    if (this.props.customer.customerName) {
      tapObjUpdates.push(this.updateTapProperty("customerName", this.props.customer.customerName));
    }

    if (this.props.customer.paymentInfo) {
      tapObjUpdates.push(this.updateTapProperty("paymentInfo", this.props.customer.paymentInfo));
    }

    if (this.props.teamMemberId) {
      tapObjUpdates.push(this.updateTapProperty("teamMemberId", this.props.teamMemberId));
      tapObjUpdates.push(this.updateTapProperty("clientId", this.props.teamMemberId));
      tapObjUpdates.push(this.updateTapProperty("avatar", this.props.tap.avatar));
      tapObjUpdates.push(this.updateTapProperty("displayName", this.props.tap.displayName));
    }

    await Promise.all(tapObjUpdates);
  }

  checkboxChanged_feesCheckbox = (event) => {
    this.setState({feesCheckbox: (event.target.checked ? true : false)});
  }

  onClick_elRegisterButton = (event) => {
    // Go to screen 'Login'
    this.props.appActions.goToScreen('login', { transitionId: 'fadeIn' });
  }

  buildShareUrl = () => {
    return paymentUrl(this.props.tap.tagId, this.props.selectedAmount, this.props.customer.customerName, this.props.customer.paymentInfo);
  }

  onCopyShareUrl = () => {
    this.setState({ shareUrlCopied: true });
  }

  onClickShare = async () => {
    const displayName = this.props.tap.displayName;
    const placeOfWork = this.props.tap.placeOfWork;
    const product = this.props.tap.product;

    const productSwitcher = new ProductSwitcher({ locStrings: this.props.locStrings });
    const productMap = productSwitcher.getProductMap(product);

    const shareText = `${productMap.action} ${displayName} with ${placeOfWork}`;

    const shareData = {
      title: document.title,
      text: shareText,
      url: this.buildShareUrl(),
    }

    // Assuming this.props.isTest is already set
    const tracker = new TrackerService({
      object: this.props.tap,
      tapId: this.props.tapId,
    });

    // Track all default data plus fees and amount as calculated in this method
    tracker.mixpanelTrack("clicked_share", {
      // empty
    });

    try {
      await navigator.share(shareData)
    } catch(err) {
      console.log('Error: ' + err);
    }
  }

  paymentButtonHandler(walletProviderPresent) {
    this.setState({
      walletProviderPresent: walletProviderPresent,
    });
  }

render() {

  const style_elPaywithApplePay = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#000',
      cursor: 'pointer',
      pointerEvents: 'auto',
  };

  const style_action_later = {
    display: 'block',
    color: 'grey',
    textAlign: 'center',
    backgroundColor: 'white',
    cursor: 'pointer',
    pointerEvents: 'auto',
    fontSize: 12.0,
    fontWeight: "heavy",
    marginTop: '5px'
  };

    const {
        paymentDetails,
        product,
        revenueFee
    } = this.props.tap;

    const { percentage, basefee } = this.getFees();
    const value_selected_amount = this.props.selectedAmount;

    const value_selected_fee = this.props.fee || (parseInt(value_selected_amount * percentage) + basefee);

   let {
     currency,
   } = paymentDetails || {
         currency : 'EUR',
         paymentMethodIndex : 0,
         paymentMethods : ['Pays', 'Card'],
         shouldAddOnFees : true,
       };

    const { isInProgress, isLoading, errorText } = this.state;
    const isInErrorState = !!errorText;

    const style_elGdprCheckBox = {
      fontSize: 12,
      width: 'auto',
      pointerEvents: 'auto',
    };

    const shouldShowShareButton =  (this.props.shouldShowShareButton && product === 'payment') || false;

    const productSwitcher = new ProductSwitcher({ locStrings: this.props.locStrings });
    const productMap = productSwitcher.getProductMap(product);

    const buttonStyle = { marginLeft: 'auto', marginRight: 'auto', width: `100px`, marginTop: '7px' };
    const minimumCharge = getMinimumCharge(currency);

    return (
      <div className="PaymentCard">
        <div className="layoutFlow">
          { this.state.showFeesCheckbox &&
            <div className="elGdprCheckBox">
              <Checkbox className="font-SFUITextLight" style={style_elGdprCheckBox} onChange={this.checkboxChanged_feesCheckbox} checked={this.state.feesCheckbox}>
                {this.state.feesCheckbox ? this.props.locStrings.paymentcard_checkbox_opt_in_fees : this.props.locStrings.paymentcard_checkbox_opt_out_fees}
              </Checkbox>
            </div>
          }

          <div>
            <TotalToPay amount={value_selected_amount} currency={currency} feesAdded={this.state.feesCheckbox} fee={value_selected_fee} />
          </div>

          {
            this.state.stripe &&
              <div className="paymentRequestButtonHolder">
                <Elements stripe={this.state.stripe}>
                  <PaymentRequestButtons {...this.props} tap={this.props.tap} appliedRevenueFeeSlabId={this.props.appliedRevenueFeeSlabId} appliedRevenueFeeSlab={this.props.appliedRevenueFeeSlab} tapId={this.props.tapId} selectedAmount={this.props.selectedAmount} shouldAddOnFees={this.state.feesCheckbox} messageText={this.props.messageText} handler={this.paymentButtonHandler} stripe={this.state.stripe} customer={this.props.customer} teamMemberId={this.props.teamMemberId} />
                </Elements>
              </div>
          }

          { this.state.walletProviderPresent ?
            <div className="elPaywithApplePay secondaryPaymentContainer">
              <Button className="headlineFont secondary" onClick={ (!isLoading && !isInProgress, !isInErrorState) ? this.handleCheckout : null} disabled={isLoading || isInProgress || value_selected_amount < minimumCharge || this.props.minimumAmountError}>
                { (isInProgress) &&
                  <ProgressSpinner spinning={isInProgress} />
                }

                { (!isLoading && !isInProgress) && `${this.props.locStrings.paymentcard_button_secondary}`}

                { (!isLoading && !isInProgress && !this.state.walletProviderPresent) &&
                  (value_selected_amount && (value_selected_amount >= minimumCharge) && (<MoneyTitle amount={value_selected_amount} currency={currency} digits={2} fees={value_selected_amount > 0 ? (this.state.feesCheckbox ? value_selected_fee : 0) : 0 }/> ) )
                }
              </Button>
            </div>
          :
            <div className="elPaywithApplePay pay">
                <Button className="headlineFont" style={{...style_elPaywithApplePay}} onClick={ (!isLoading && !isInProgress, !isInErrorState) ? this.handleCheckout : null} disabled={isLoading || isInProgress || value_selected_amount < minimumCharge || this.props.minimumAmountError}>
                  { (isInProgress) &&
                    <ProgressSpinner spinning={isInProgress} />
                  }

                  { (!isLoading && !isInProgress) && (productMap.upper) }
                </Button>
            </div>
          }

          { isInErrorState &&
            <div className="errorPanel">
              <p className="errorText"><strong>Error: </strong>{this.state.errorText}</p>
              <p className="errorText">Please contact support: <a href='mailto:support@strikepay.co'>support@strikepay.co</a></p>
            </div>
          }

          { (shouldShowShareButton) &&
          <>
            <div className="elSpacer"><div /></div>

            <CopyToClipboard text={this.buildShareUrl()}
                onCopy={() => this.onCopyShareUrl() }>
                <div className="elPaywithApplePay pay">
                {this.state.shareUrlCopied ? <AntButton
                    icon={<ShareAltOutlined />}
                    onClick={() => this.onClickShare()}
                    type="primary"
                    style={buttonStyle}
                >Share</AntButton> : <button style={{...style_action_later}}>Copy link</button>}
              </div>
            </CopyToClipboard>
          </>
          }
        </div>
      </div>
    )
  }
}
