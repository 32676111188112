import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Checkbox,
} from 'antd';

import { FormItemLayout, TailFormItemLayout } from '../../utils/Constants';

export const SetupTagMetadataForm = ({ callback, tag, error, ...rest }) => {
  const [form] = Form.useForm();
  const isJustGivingEnabled = (tag.justGivingData && tag.justGivingData.justGivingEnabled) ? tag.justGivingData.justGivingEnabled : false;
  const [justGivingEnabled, setJustGivingEnabled] = useState(isJustGivingEnabled);

  const initialValues = {
    ...tag.metadata,
    ...tag.justGivingData,
  }

  const onFinish = (values) => {
    const appliedValues = {
      nickname: values.nickname || '',
      location: values.location || '',
      number: values.number || '',
      contact: values.contact || '',
      email: values.email || '',
      justGivingEnabled: values.justGivingEnabled || false,
      inputUrl: values.inputUrl || '',
    };

    if (callback) {
      callback(appliedValues);
    }
  };

  return (
    <Form
      {...FormItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item
        {...FormItemLayout}
        name="nickname"
        label="Place Name"
        rules={[
          {
            required: true,
            message: 'Way to identify which tag this is',
          },
        ]}
      >
        <Input placeholder="Murphy's Pharmacy" />
      </Form.Item>

      <Form.Item
        {...FormItemLayout}
        name="location"
        label="Address"
        rules={[
          {
            required: false,
            message: 'Where is this tag located',
          },
        ]}
      >
        <Input placeholder="Main St, Castlebar" />
      </Form.Item>

      <Form.Item
        {...FormItemLayout}
        name="number"
        label="Unit ID"
        rules={[
          {
            required: false,
            message: 'Number of the box or unit the tag is linked to',
          },
        ]}
      >
        <Input placeholder="1234" />
      </Form.Item>

      <Form.Item
        {...FormItemLayout}
        name="contact"
        label="Phone"
        rules={[
          {
            required: false,
            message: 'Please input your contact details',
          },
        ]}
      >
        <Input placeholder="+353 87 1234567" />
      </Form.Item>

      <Form.Item
        {...FormItemLayout}
        name="email"
        label="Contact email"
        rules={[
          {
            required: false,
            message: 'Please input your contact email',
          },
        ]}
      >
        <Input placeholder={"example@org.com"} />
      </Form.Item>

      <Form.Item
        {...TailFormItemLayout}
        name="justGivingEnabled"
        valuePropName="checked"
        style={{
          marginBottom: '14px'
        }}
      >
        <Checkbox
          onChange={(e) => {
            setJustGivingEnabled(e.target.checked)
          }}>Redirect to JustGiving page</Checkbox>
      </Form.Item>

      {justGivingEnabled && (
        <Form.Item
          {...FormItemLayout}
          name="inputUrl"
          label="JustGiving page"
          disabled={false}
          rules={[
            {
              required: false,
              message: 'Copy and paste your JustGiving page URL here',
            },
          ]}
        >
          <Input placeholder={"https://www.justgiving.com/fundraising/your-page"} />
        </Form.Item>
      )}

      {error && (
        <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>
      )}

      <Form.Item {...TailFormItemLayout} style={{ textAlign: 'center' }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};