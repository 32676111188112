// MARK: Helper functions for building URLs

/// function getQueryStringForParams
///   params:
///     {
///       amount: number?
///       name: string?
///       reference: string?
///       uid: string?
///     }
function getQueryStringForParams(params) {
  var query = Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');
  return query;
}

export function paymentUrl(tagId, amount, name, reference) {
  const isLocalHost = window.location.hostname === 'localhost';
  const urlBase =  isLocalHost ? 'http://localhost:3000' : `https://pay.strikepay.co`;
  const tagUrl = `${urlBase}/tags/${tagId}?`;
  let params = {};

  if (amount) params.amount = amount;
  if (name) params.name = name;
  if (reference) params.reference = reference;

  var query = getQueryStringForParams(params);

  return (tagUrl + query);
}


export function shareUrl(tag, refId) {
  const isLocalHost = window.location.hostname === 'localhost';
  const urlBase =  isLocalHost ? 'http://localhost:3000' : `https://pay.strikepay.co`;
  let params = {};
  let tagId = tag.id;
  const typeLink = "tags";

  if (tag.templateTagId && tag.tagUid)  {
    tagId = tag.templateTagId;
    params.uid = tag.tagUid;
  }

  if (refId) params.refId = refId;

  const query = getQueryStringForParams(params);
  const tagUrl = `${urlBase}/${typeLink}/${tagId}?`;

  return (tagUrl + query);
};

export function shareQRUrl(tag, refId) {
  const isLocalHost = window.location.hostname === 'localhost';
  const urlBase =  isLocalHost ? 'http://localhost:3000' : `https://pay.strikepay.co`;
  let params = {};
  let tagId = tag.id;
  const typeLink = "qr";

  if (tag.templateTagId && tag.tagUid)  {
    tagId = `${tag.templateTagId}---${tag.tagUid}`;
  }

  if (refId) params.refId = refId;

  const query = getQueryStringForParams(params);
  const tagUrl = `${urlBase}/${typeLink}/${tagId}?`;
  return (tagUrl + query);
};
