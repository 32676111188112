import { useState, useEffect } from 'react';

import StrikeNotificationsModel from './StrikeNotificationsModel';


export default function useNotifications(clientId) {
    const [notifications, setNotifications] = useState(null);

    useEffect(() => {
        const onUpdate = async (list) => {
            setNotifications(list);
        };


        const model = new StrikeNotificationsModel({
            clientId,
            onUpdate,
        });

        model.start();

        return function cleanup() {
            model.stop();
        };

    },
        []
    );

    return notifications;
}
