// MARK: Helper function for Date

export default function timeSince(timestamp, optionsParam) {
  if (timestamp === null) {
    return 'just now';
  }
 
  let now = new Date();
  let then = new Date(timestamp) || new Date();
  let milliseconds = now - then;
  let seconds = Math.floor(milliseconds/1000.0);
  let minutes = Math.floor(seconds/60.0);
  let hours = Math.floor(minutes/60.0);
  let days = Math.floor(hours/24.0);
  let weeks = Math.floor(days/7.0);
  let months = Math.floor(days/30.0);
  var options = optionsParam || { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute:'numeric' };

  var thenAsString = then.toLocaleDateString('en-GB', options) || '';

  var result = thenAsString;

  if (weeks < 2) {
    if(days < 2) {
      if(hours < 2) {
        if(minutes < 2) {
          result = "just now";
        } else {
          result = `${minutes}m ago`;
        }
      } else {
        result = `${hours}h ago`;
      }
    } else {
      result = `${days}d ago`;
    }
  } else if (weeks < 9) {
    result = `${weeks}w ago`;
  } else if (months < 12) {
    result = `${months}months ago`;
  } else if (weeks <= 53) {
    result = `<1y ago`;
  } else {
    result = thenAsString;
  }

  return result;
}
