import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { PageHeader } from 'antd';

import Spinner from '../../../components/Spinner';
import StrikeTeamDetailsModel from '../../../models/StrikeTeamDetailsModel';
import StrikeTeamTransfersModel from '../../../models/StrikeTeamTransfersModel';
import { MemberTransfersTable } from '../../../components/client/TeamsComponents';


export default class TeamMemberPayoutsScreen extends Component {

    constructor(props) {
        super(props);

        const teamId = this.props.match.params.teamId;
        const clientId = this.props.match.params.clientId;
        let memberClientId = this.props.match.params.memberId;
        const isAdmin = !!props.isAdmin;

        this.teamDetailsModel = new StrikeTeamDetailsModel({viewModel: this, teamId, memberClientId: memberClientId});

        this.state = {
            teamId: teamId,
            clientId: clientId,
            memberClientId: memberClientId,
            teamMember: null,
            teamDetails: null,
            teamMembersList: null,
            memberTransfers: [],
            loading: true
        };

        this.teamTransfersModel = new StrikeTeamTransfersModel({
            viewModel: this,
            callback: this.setTransfersDataOnPage,
            memberClientId : memberClientId,
            teamId
        });

        this.setTransfersDataOnPage = this.setTransfersDataOnPage.bind(this);
    }

    componentDidMount() {
        this.teamDetailsModel.waitForTeamDetails({fetchTeamClient: true});
    }

    componentWillUnmount() {
        if (this.teamDetailsModel) this.teamDetailsModel.stop();
        if (this.teamTransfersModel) this.teamTransfersModel.stop();
    }

    componentDidUpdate() {
    }

    isLoading = () => {
        return this.state.loading || false;
    }

    setTeamDetailsOnPage = (teamDetails, teamMembersList) => {
        let filteredTeamMember = teamMembersList.filter(member => member.clientId === this.state.memberClientId);
        if(filteredTeamMember.length !== 1) {
            return;
        }

        this.setState({
            teamDetails,
            teamMembersList,
            teamMember: filteredTeamMember[0],
            loading: false
        });
    }

    setTeamClientDetailsOnPage = (teamClient) => {
        this.setState({
            teamClient
        });

        this.teamTransfersModel.clientId = teamClient.id;
        this.teamTransfersModel.memberClientId = this.state.memberClientId;
        this.teamTransfersModel.waitForTransfersData();
    }

    setTransfersDataOnPage = async (txns) => {
        const transfers = this.teamTransfersModel.transfers;
        this.setState(
            (state) => {
                return {
                    transfers: transfers
                }
            }
        );
    }


    render() {
        if (!this.state.loading && this.state.teamDetails && this.state.teamClient && this.state.teamMember && this.state.transfers) {
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`${this.state.teamDetails.teamName}`}
                        onBack={(() => window.history.back())}
                    />
                    <h2 style={{textDecoration: 'underline'}}>Payouts for {this.state.teamMember.displayName}</h2>
                    <MemberTransfersTable
                        condition={this.teamDetailsModel}
                        inwardTransfers={this.state.transfers}
                        teamDetails={this.state.teamDetails}
                        teamId={this.state.teamId}
                    />
                </div>
            );
        } else {
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`Team Payouts`}
                        style={{fontSize: '42px', fontWeight: 'bold'}}
                        onBack={(() => window.history.back())}
                    />
                    <Spinner condition={() => { return this.isLoading() }} />
                </div>
            );
        }
    }
}

