import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Typography, PageHeader, Tag, List , Button } from 'antd';
import { PlusCircleOutlined, QrcodeOutlined, ShareAltOutlined, CopyOutlined } from '@ant-design/icons';
import firebase from 'firebase/app';

import Spinner from '../../../components/Spinner';
import StrikeTemplateTagModel from '../../../models/StrikeTemplateTagModel';
import { TagsListItemDetail } from '../../../components/client/TagsComponents';
import ProductSwitcher from '../../../utils/ProductSwitcher';

const short = require('short-uuid');
const { Paragraph } = Typography;

export default class AdminTemplateTagScreen extends Component {
  constructor(props) {
    super(props);

    const issuerId = this.props.match.params.issuerId;
    const templateTagId = this.props.match.params.templateTagId;

    this.model = new StrikeTemplateTagModel({ viewModel: this, issuerId, templateTagId });

    this.state = {
      tags: [],
      loading: false,
      loadingTags: true,
      isAdmin: true,
      mode: 'viewing',
      issuerId,
    };
  }

  componentDidMount() {
    this.model.getTemplateTagData();
  }

  componentWillUnmount() {
    if(this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || false;
  }

  updatedTagDataSource = (tag) => {
    this.setState({ tag, loadingTags: false });
  }

  changeMode = (mode) => {
    if (mode === 'viewing') {
      return 'editing'
    } else if (mode === 'editing') {
      return 'saving'
    } else {
      return 'viewing';
    }
  }


  onClickEdit = () => {
    this.setState({ mode: this.changeMode(this.state.mode)});
  }

  doneSaving = () => {
    this.setState({ mode: this.changeMode(this.state.mode) });
    this.model.waitForClientData();
  }

  onClickAdd = () => {
    this.setState({ mode: 'adding', isModalVisible: true });
  }

  onClickCancel = () => {
    this.setState({ mode: 'viewing', isModalVisible: false });
  }

  uuid = () => {
    const uuid = short.generate();
    return uuid;
}    

shareLink = (tag, queryParam) => {
  const iUUID = this.uuids[tag.id] || this.uuid();
  this.uuids[tag.id] = iUUID;
  return `tags/${this.state.tagId}?${queryParam}=${iUUID}`;
}

fullURIForShare = (tag, queryParam) => {
  if(!this.state.isAdmin) {
      const uri = this.shareLink(tag, queryParam);
      return `${this.urlBase}/${uri}`;
  } else {
      return (tag.templateTagId && tag.tagUid)
      ? `${this.urlBase}/tags/${tag.templateTagId}?uid=${tag.tagUid}`
      : `${this.urlBase}/tags/${tag.id}`;
  }
};

fullURLForQR = (tag) => {
  return `${this.urlBase}/qr/${this.state.tagId}`;
};

duplicateTemplateTag = async (templateTag) => {
  const duplicateId = window.prompt("Please enter the name/id to give the duplicated template tag");

  const db = firebase.firestore();

  const issuerId = "strike";
  const templateTagDoc = await db.collection('issuers').doc(issuerId).collection("template_tags").doc(duplicateId).get();
  
  if (templateTagDoc.exists) {
    window.alert("Error: This template tag name/id already exists. Please try again with a different name/id");
    return;
  }

  if (templateTag.displayName) {
      templateTag.displayName = `Duplicate of ${templateTag.displayName}`;
  } else {
      templateTag.displayName = `Duplicate of ${templateTag.id}`;
  }

  // Template tag obj from client has added id and clientId, we want to remove before saving
  delete templateTag.id;
  delete templateTag.clientId;

  await db.collection('issuers').doc(issuerId).collection("template_tags").doc(duplicateId).set({
      ...templateTag,
      modified: false,
      createdAt: firebase.firestore.Timestamp.now(),
      updatedAt: firebase.firestore.Timestamp.now(),
  });
}

onClickShare = async (tag, queryParam) => {
    const displayName = tag.displayName;
    const placeOfWork = tag.placeOfWork || 'Strike';
    const product = tag.product;

    const productSwitcher = new ProductSwitcher({ locStrings: this.props.locStrings });
    const productMap = productSwitcher.getProductMap(product);

    const shareText = `${productMap.action} ${displayName} with ${placeOfWork}`;

    console.log(this.fullURIForShare(tag, queryParam));

    const shareData = {
        title: displayName,
        text: shareText,
        url: `${this.fullURIForShare(tag, queryParam)}`,
    }

    try {
        await navigator.share(shareData)
    } catch (err) {
        console.log(`Error: ${shareData.url}\n` + err);
    }
}

  getButtons = () => {
    if (this.state.mode === 'viewing') {
      return [
        <Button key="1"  type="text" shape='circle' icon={<PlusCircleOutlined style={{ fontSize: '28px', color :'#070'}}/>} onClick={this.onClickAdd} />
      ]
    } else if (this.state.mode === 'adding') {
      return [
        <Button key="1" type="danger" onClick={this.onClickCancel}>
          Cancel
        </Button>,
      ];
    } else {
      return [];
    }
  }

  tagActions = () => {
    const buttonStyle = { marginLeft: 'auto', marginRight: 'auto', width: `100%`, marginBottom: '10px' };

    const actions = [
        <Button
            key={1}
            icon={<QrcodeOutlined />}
            href={this.state.isAdmin ? null : this.fullURLForQR(this.state.tag)}
            onClick={this.state.isAdmin ? ((e)=> (this.onClickShare(this.state.tag, 'qr'))) : null}
            type="secondary"
            style={buttonStyle} 
        >QR Code</Button>,
        <Button
            key={2}
            icon={<ShareAltOutlined />}
            onClick={((e)=> (this.onClickShare(this.state.tag, 'uid')))}
            type="primary"
            style={buttonStyle} 
        >Share</Button>,  
    ];

    if (this.state.isAdmin) {
        actions.push(
            <Button
                icon={<CopyOutlined />}
                onClick={((e)=> (this.duplicateTemplateTag(this.state.tag)))}
                type="secondary"
                style={buttonStyle} 
            >Duplicate</Button>,  
        );
    }

    return actions;
  }

  onEditTag = async (tag, updatedTagData ) => {
    console.log('onEditTag ', updatedTagData);
    await this.model.updateTagWithData(updatedTagData);
  } 

  render() {
    if (this.state.mode === 'viewing' && !this.state.loading && this.state.tag) {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Edit Template Tag`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'adding' ? null: <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
          <Spinner condition={() => { return this.isLoading() }} />
          <List
            style={{ textAlign: 'start', maxWidth: '1024px' }}
            itemLayout="vertical"
            size='default'
            className="centred-action-buttons"
          >
            <List.Item
              key={this.state.tagId}
              actions={this.tagActions()}
            >
              <TagsListItemDetail 
                isAdmin={this.state.isAdmin}
                tag={this.state.tag} tagId={this.state.tagId} locStrings={this.props.locStrings} onEditTag={this.onEditTag}
              />
            </List.Item>
          </List>
        </div>
      );
    } else if (!this.state.loading) {
      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`Template Tag`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
          <Paragraph>Add a Template Tag</Paragraph>
        </div>
      );
    } else {
      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`Template Tag`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
        </div>
      );
    }


  }
}

