import React, { useEffect, useState, useRef } from 'react';
import { LoadingOutlined, UserOutlined, BankOutlined, QrcodeOutlined, TeamOutlined, WifiOutlined, PauseCircleOutlined, PlayCircleOutlined, SecurityScanOutlined , ArrowUpOutlined, CheckCircleTwoTone, ApiOutlined } from '@ant-design/icons';
import firebase from 'firebase/app';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { 
  DatePicker, Spin, Space, Typography, 
  Table, Tag , Statistic, Card, Row, Col, 
  Badge , Button, Modal, Image, message,
  Switch, Divider,
} from 'antd';

import HeroProfile from '../customer/HeroProfile';
import MoneyTitle from '../customer/MoneyTitle';
import timeSince from '../../utils/TimeSince';
import tag_a_tag_image from '../../images/Strike_Symbol_RGB_White_Magenta.jpg';
import ProductSwitcher from '../../utils/ProductSwitcher';
import {ccyIsoCodeToSymbolMap} from '../../utils/CurrencyUtils';
import StrikeTapsModel from '../../models/StrikeTapsModel';
import ClientAvailableTransactionsModel from '../../models/ClientAvailableTransactionsModel';

import LaunchQRPageButton from './LaunchQRPageButton';
import SwitchClientButton from './SwitchClientButton';
import { ShowCashOutDialog } from './ShowCashOutDialog';
import { CashOutToTeamDialog } from './CashOutToTeamDialog';
import { useClientTeams } from './useClientTeams';
import { DownloadDataReport } from './DownloadDataReport';

import '../../App.scss';
import 'antd/dist/antd.css';

const { Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;
const SpinnerIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

export const isLocalHost = window.location.hostname === 'localhost';

export const ccy = ccyIsoCodeToSymbolMap;


// TESTING: Test data to validate datasources
const datasourceForTesting = [
  {
    // REQUIRED fields:
    currency: 'EUR',
    is_test: false,
    product: 'tipping',
    balance: 300, decimalBalance: 3,
    // OPTIONAL fields:
    pending: 100, pendingDecimal: 1,
    available: 200, pendingAvailable: 2,
    withdrawn: 300, withdrawnDecimal: 3,
  },
  {
    currency: 'GBP',
    is_test: false,
    product: 'tipping',
    balance: 400, decimalBalance: 4,
    pending: 200, pendingDecimal: 2,
    available: 0, pendingAvailable: 0,
    withdrawn: 500, withdrawnDecimal: 5,
  },
  {
    currency: 'USD',
    is_test: true,
    product: 'tipping',
    balance: 1400, decimalBalance: 14,
    pending: 200, pendingDecimal: 2,
    available: 200, pendingAvailable: 2,
    withdrawn: 500, withdrawnDecimal: 5,
  }
];

export const PauseButton = ({ isLoading, isRestricted, pauseHandler }) => {
  if (isLoading) {
    return (
      <Button key="1" loading type="secondary">Loading...</Button>
    )
  }
  const buttonText = isRestricted ? 'Enable' : 'Pause';
  const icon = isRestricted ? <PlayCircleOutlined /> : <PauseCircleOutlined />;

  return (
    <Button key="1" icon={icon} onClick={pauseHandler} type="secondary" danger={isRestricted}>
      {buttonText}
    </Button>
  );
}

export const ShowScanTagDialog = ({ visible, handleOk, handleCancel, locStrings, ...rest }) => {
  return (
    <Modal title="Claim Your Tag" visible={visible} onOk={handleOk} onCancel={handleCancel}>
      <div style={{ textAlign: 'center' }}>
        <Card >
          <p>Tap a Tag</p>
          <Image src={tag_a_tag_image} width={80} style={{ borderRadius: '50%' }} />
        </Card>
        <p>  </p>
        <p style={{ fontSize: '30px' }} >OR</p>
        <Card>
          <p>Scan a QR</p>
          <QrcodeOutlined style={{ fontSize: '80px' }} />
        </Card>
      </div>
    </Modal>);
};

export const setDefaultPayout = (payouts, clientCurrencyStr) => {
  for (const [i, payout] of payouts.entries()) {
    if (payout && payout.currency === clientCurrencyStr) {
      return i;
    }
  }

  return 0;
}

export const ActionsButtonsCard = ({ visible, handler, locStrings, client, isAdmin, clientId, ...rest }) => {
  const isDeveloper = client && client.isDeveloper ? true : false;
  
  let routes = [
  ];

  const viewTeamsRoute = client && (!client.hasOwnProperty('teamsAllowed') || (client.hasOwnProperty('teamsAllowed') && client.teamsAllowed))  ?
      [
        {
          icon: <TeamOutlined />,
          route: 'teams',
          text: 'All Teams'
        }
      ]
      : [];

  const idVerificationButton = client && client.idVerificationAllowed ?
      [
        {
          icon: <SecurityScanOutlined />,
          route: 'idverification',
          text: 'Identity Verification'
        }
      ]
      : [];

  const profileButton = (isAdmin) ?
      [
        {
          icon: <UserOutlined />,
          route: 'profile',
          text: 'Edit Profile'
        }
      ]
      : [];

  const accessButton = (isAdmin) ?
      [
        {
          icon: <TeamOutlined />,
          route: 'access',
          text: 'Manage Access'
        }
      ]
      : [];

  const payouts = (isAdmin) ? 
  [
    {
      icon: <BankOutlined />,
      route: 'bank',
      text: 'Payouts'
    },
  ] : [];

  const developer = (isAdmin || isDeveloper) ? 
  [
    {
      icon: <ApiOutlined />,
      route: 'developer',
      text: 'Developer'
    },
  ] : [];

  const myQRs = [
    {
      icon: <WifiOutlined rotate='90' />,
      route: 'tags',
      text: 'All QRs'
    },
  ];

  routes = [
    ...viewTeamsRoute,
    ...profileButton,
    ...myQRs,
    ...payouts,
    ...accessButton,
    ...idVerificationButton,
    ...developer,
  ];

  return visible && (
    <Card align='center'>
      <Paragraph>Select an Option</Paragraph>
      <Space direction='vertical' width={'100%'}>

        <SwitchClientButton 
          clientId={clientId}
        />

        <LaunchQRPageButton
          clientId={clientId}
          product={'payment'}
          path={'payme'}
          title={'Get Paid'}
        />

        <LaunchQRPageButton
          clientId={clientId}
          product={'tipping'}
          path={'tipme'}
          title={'Get Tips'}
        />

        {
          routes.map((v, i) => (
            <Button
              key={v.route}
              icon={v.icon}
              onClick={() => (handler ? handler(v) : null)}
              type={(i) ? "secondary" : "primary"}
              style={{ textAlign: 'start', width: `100%` }}
            >{v.text}</Button>
          ))
        }
      </Space>
    </Card>
  );
};

export const DownloadTapsReport = ({ client, clientId, updatedTapsRange, ...rest }) => {
  const clientTapsModel = new StrikeTapsModel({ clientId });
  
  return (
    <DownloadDataReport 
      client={client}
      clientId={clientId}
      model={clientTapsModel}
      updatedDateRange={updatedTapsRange}
      exportButtonText={'Export Taps Only'}
    />
  );
};

export const DownloadTransactionsReport = ({ client, clientId, updatedTapsRange, ...rest }) => {
  const clientTxnsModel = new ClientAvailableTransactionsModel({ clientId });

  return (
    <DownloadDataReport 
      client={client}
      clientId={clientId}
      model={clientTxnsModel}
      updatedDateRange={updatedTapsRange}
      exportButtonText={'Export CSV'}
      subText={'Available transactions only'}
    />
  );
};

export const SwitchBigRedCloudExport = ({ clientId, toggleExportMode, showBrcCTA, isAdmin, isChecked, ...rest }) => {
  const isLocalHost = window.location.hostname === 'localhost';
  const isTest = isLocalHost;

  const useBRC = isAdmin || true;

  return useBRC && (
    <Space direction='horizontal'>
      <Switch onChange={toggleExportMode} checked={isChecked} defaultChecked />
      <Text>{showBrcCTA}</Text>
    </Space>
  );
};


// MARK: Keeping Profile Image around for another run through tomorrow
export const ProfileImage = ({ condition, locStrings, client, ...rest }) => {
  return condition() ?
    <HeroProfile {...client} {...rest} object={client} locStrings={locStrings} />
    : null;
};


// TODO: When Screen is WIDER show a Grid instead of the Carousel (use `wide` param)
// accoutns is an array of dictionary with : {
//    currency, balance, decimalBalance, is_test, product // required
//    pending, avaliable, withdrawn
//    pendingDecimal, avaliableDecimal, withdrawnDecimal
// }
export const AccountBalancesView = ({ condition, locStrings, client, clientId, payouts, withdrawals, accounts, wide, noButtons, ...rest }) => {
  const teams = useClientTeams(clientId);
  
  const [isCashOutToTeamModalVisible, setIsCashOutToTeamModalVisible] = useState(false);

  const [isCashOutVisible, setIsCashOutVisible] = useState(false);

  const [selectedAvailableAccountId, setAvailableAccountId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [balanceUpdatePending, setBalanceUpdatePending] = useState(false);
  const [latestWithdrawalId, setLatestWithdrawalId] = useState(null);

  const showCashOutModal = (availableAccountId) => {
    setAvailableAccountId(availableAccountId)
    setIsCashOutVisible(true);
  };

  const showCashOutToTeamModal = (availableAccountId) => {
    setAvailableAccountId(availableAccountId)
    setIsCashOutToTeamModalVisible(true);
  };


  const handleCashOutOk = () => {
    // setIsCashOutVisible(false);
    setBalanceUpdatePending(true)
  };

  const handleCashOutCancel = () => {
    setIsCashOutVisible(false);
  };


  const handleCashTeamOutOk = () => {
    setIsCashOutToTeamModalVisible(false);
  };

  const handleCashOutTeamCancel = () => {
    setIsCashOutToTeamModalVisible(false);
  };


  let element = null;

  const testing = false;
  let datasource = testing ? datasourceForTesting : accounts;

  const productSwitcher = new ProductSwitcher({ locStrings });
  const isTransferToTeamAllowed = (client && client.isTransferToTeamAllowed) ? true : false;

  // Filter out accounts with a balance of 0
  // datasource = datasource.filter(account => !!account.total);

  const db = firebase.firestore();

  let latestWithdrawalObserver = null;
  const statusListForDone = [
    'sent', 'batch_requested', 'failed', 'paid', 'refunded',
    'api_requested', 'waiting',
  ];

  useEffect(() => {
    if (balanceUpdatePending) {
      latestWithdrawalObserver = db.collection('clients').doc(clientId).collection('withdrawals').doc(latestWithdrawalId)
        .onSnapshot((snapshot) => {
          const snapshotData = snapshot.data();
          if (statusListForDone.includes(snapshotData.status)) { // MARK: sent means batch

            setTimeout(() => {
              setBalanceUpdatePending(false);
              setLatestWithdrawalId(null);
              latestWithdrawalObserver();                
            }, 2000);

            switch (snapshotData.status) {
              case 'sent':
              case 'batch_requested':
                message.success('Cash out successfully requested. Funds can take 1-3 days to arrive in your bank account.'); // MARK: sent means batch
                break;
              case 'api_requested':
              case 'waiting':
                message.success('Cash out requested. Funds can take some time to arrive in your bank account.');
                break;
              case 'paid':
                message.success('Cash out successfully paid. Funds should be in your bank account.');
                break;
              case 'failed':
                message.error(`Cash out failed. Reason: ${snapshotData.statusReason}`);
                break;
              case 'refunded':
                message.error('There was an issue while processing your cash out. Funds have been refunded to your account.');
                break;
            }
          }
      });
    }
  }, [clientId, db, balanceUpdatePending, latestWithdrawalId]);
  

  const rowColAccountElement =
    (<div className="accounts-balances-card" style={{ width: '100%', textAlign: 'center' }}>
      <Divider>My Account Balances</Divider>
      <Row justify='center' style={{ paddingBottom: '10px' }}>
        {datasource.map((v, i) => (
          <Col key={i} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }} >
            <Row gutter={16} key={'row-' + i} >
              <Col span={0} />
              <Col span={24}>
                <Card style={{ textAlign: 'center', padding: '20px', margin: 0 }}>
                  <Statistic
                    title={`Available ${productSwitcher.getProductMap(v.product).pluralName} (${v.currency})`}
                    value={((v.available || 0)/ 100).toFixed(2)}
                    precision={2}
                    valueStyle={{ color: '#3f8600' }}
                    prefix={ccy[v.currency]}
                  />
                  {(v.pending != null) &&
                    <div style={{ width: '100%' }}>
                      <Space direction='vertical'>
                        <Space align='baseline' direction='horizontal'>
                          <p style={{ fontWeight: 'bolder' }}>Pending:</p>
                          <MoneyTitle amount={v.pending} currency={v.currency} digits={2} />
                        </Space>
                        <Space align='baseline' direction='horizontal'>
                          <p style={{ fontWeight: 'bold' }}>To-date:</p>
                          <MoneyTitle amount={v.total} currency={v.currency} digits={2} />
                        </Space>
                      </Space>
                    </div>
                  }

                  {!noButtons &&
                    (
                      <div style={{ textAlign: 'center', padding: '0px', margin: '0px', width: '100%' }}>
                        <Space direction='vertical'>
                          <Divider>Cash out to Bank</Divider>
                          { v.is_test && false &&
                          <Space direction='vertical' size={0}>
                            <Text>{(v.availableAccountId) ? `${v.availableAccountId}` : 'No account id'}</Text>
                            <Text>{(v.available) ? `Avail: ${v.available}` : 'No balance'}</Text>
                            <Text>{(loading) ? `Loading...` : 'Loaded'}</Text>
                            <Text>{(balanceUpdatePending) ? `Updating...` : 'Updated'}</Text>
                          </Space>
                          }
                          <Button disabled={(balanceUpdatePending) || (loading) || (!(v.availableAccountId && v.available > 0))} type={(!v.is_test) ? "primary" : "dashed"} style={{ marginLeft: 'auto', marginRight: 'auto' }} onClick={() => showCashOutModal(v.availableAccountId)}>{(v.available) ? 'Cash Out' : 'No Cash Out'}</Button>
                          { isTransferToTeamAllowed && v.product === 'tipping' &&
                            <>
                              <Divider>OR</Divider>
                              <Typography.Text>Send to your Team</Typography.Text>
                              <Button danger disabled={(balanceUpdatePending) || (loading) || (!(v.availableAccountId && v.available > 0))} style={{ marginLeft: 'auto', marginRight: 'auto' }} onClick={() => showCashOutToTeamModal(v.availableAccountId)}>{'Transfer to a Team'}</Button>
                            </>
                          }
                        </Space>
                        <ShowCashOutDialog visible={isCashOutVisible && (selectedAvailableAccountId === v.availableAccountId)} setLatestWithdrawalId={setLatestWithdrawalId} loading={loading} setLoading={setLoading} handleOk={handleCashOutOk} handleCancel={handleCashOutCancel} accountId={v.availableAccountId} clientId={clientId} currency={ccy[v.currency]} clientCurrencyStr={v.currency} payouts={payouts} withdrawals={withdrawals} balance={v.available} isAdmin={false} {...rest} />
                        <CashOutToTeamDialog visible={isCashOutToTeamModalVisible && (selectedAvailableAccountId === v.availableAccountId)} handleOk={handleCashTeamOutOk} handleCancel={handleCashOutTeamCancel} accountId={v.availableAccountId} clientId={clientId} currency={ccy[v.currency]} clientCurrencyStr={v.currency} balance={v.available} locStrings={locStrings} {...rest} />
                      </div>
                    )
                  }
                </Card>
              </Col>
              <Col span={0} />
            </Row>
          </Col>
        ))
        }
      </Row>
    </div>);

  if (datasource.length > 0) {
    element = rowColAccountElement;
  } else {
    element = null;
  }


  return condition() ? element : null;

};


export const RecentTransactionsTable = ({ condition, locStrings, client, accounts, transactions, ...rest }) => {

  if (!condition()) return null;



  const dataSource = [
  ];

  const productSwitcher = new ProductSwitcher({ locStrings });

  transactions.map(
    (transaction, i) => {
      const productMap = productSwitcher.getProductMap(transaction.product);
      return dataSource.push(
        {
          key: `tx-${i}`,
          date: timeSince(Date.parse(transaction.timestamp)), // transaction.timestamp, // 1612816116399
          type: {
            typeName: (transaction.credit > 0 ? productMap.credit.name : productMap.debit.name),
            typeColor: (transaction.credit > 0 ? productMap.credit.color : productMap.debit.color),
            typeDescription: transaction.description,
            productColor: productMap.color,
          },
          currency: transaction.currency,
          value: { currency: transaction.currency, amount: transaction.amount },
          balance: 0,
          description: `${transaction.description} `,
        }
      );
    });


  const columns = [
    {
      title: 'When',
      dataIndex: 'date',
      key: 'date',
      render:
        date => (
          <>
            <Tag color='geekblue'>{date}</Tag>
          </>
        )
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render:
        type => (
          <>
            <Tag color={type.typeColor}>{type.typeName}</Tag>
          </>
        )
    },
    {
      title: 'Amount',
      dataIndex: 'value',
      align: 'right',
      render:
        value => (
          <div >
            { (value.amount !== 0) &&
              (<MoneyTitle amount={value.amount} currency={value.currency} digits={2} />)
            }
            { (value.amount === 0) &&
              (<Tag color={'blue'}>none</Tag>)
            }
          </div>
        )
    },
  ];

  return condition() ?
    <Table
      style={{ width: '100%' }}
      dataSource={dataSource}
      columns={columns}
      pagination={{ pageSize: 40 }} scroll={{ y: 300 }}
      expandable={{
        expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
        rowExpandable: record => record.name !== 'Not Expandable',
      }}
    />
    : null;

};

export const VerifiedClientTag = ({ client, style }) => {

  if(!client.idVerificationStatus || (client.idVerificationStatus && client.idVerificationStatus !== 'verified')){
    return null;
  }

  return  <div style={{textAlign: 'center', width: '100%'}}>
    <Badge count={<CheckCircleTwoTone
                  style={{ fontSize: "18px", strokeWidth: "30", position: 'relative', left:'-7px', top:'-18px', display: 'block'}}
                  twoToneColor={'#1890ff'}
                  />}
    >
      <Tag color={'green'} style={style}>VERIFIED</Tag>
    </Badge>
  </div>

};




