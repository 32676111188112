import React, { useState } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Card, Typography, Space, Row, Col } from 'antd';

import img_elImage from '../../images/strikepay_Mark_RGB_Black.png';
import TrackerService from '../../utils/TrackerService';
import { StrikeColors } from '../../utils/Colors';
import ProductSwitcher from '../../utils/ProductSwitcher';
import { getContrastWithRGBColor, rgbColorToHex } from '../../utils/RGBColorToHex';

import ContrastColor from './ContrastColor';
import QRImageView from './QRImageView';


import 'antd/dist/antd.css';
import '../../App.scss';


const { Paragraph, Text, Title } = Typography;


const baseStyle = {
  minWidth: '260px',
  marginLeft: '0px',
  marginRight: 'auto',
  textAlign: 'start'
};

const mainSizeStyle = { 
  width: '100%', 
  marginLeft: 'auto', marginRight: 'auto', 
  paddingLeft: '0px', paddingRight: '0px' 
};

const bodySizeStyle = {
  width: '100%', 
  marginLeft: 'auto', marginRight: 'auto', 
  paddingLeft: '8px', paddingRight: '8px',
  textAlign: 'start',
}

const style_elImageLogo = {
  height: 'auto',
  cursor: 'pointer',
  pointerEvents: 'auto',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '0px',
  marginBottom: '0px',
  maxHeight: '40px',
  padding: '0px',
};
const style_elImageTopLogo = {
  height: 'auto',
  cursor: 'pointer',
  pointerEvents: 'auto',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '0px',
  marginBottom: '0px',
  maxWidth: 'auto',
  maxHeight: '40px',
  padding: '0px',
  paddingLeft: '8px',
};
const style_elImage = {
  height: 'auto',
  cursor: 'pointer',
  pointerEvents: 'auto',
  marginTop: '0px',
  marginBottom: '0px',
  maxWidth: '100%',
  maxHeight: '300px',
  padding: '0px',
  borderRadius: '4px',
  paddingBottom: '16px',
};


function ActionButton({ text, intent, onClick, secondaryText, secondaryIntent, color, colour, ...rest }) {
  const buttonColor = color || StrikeColors.magenta;
  const { textLabel, linkLabel } = secondaryText || {};

  return (
    <>
      <Space size='small' direction='vertical'>

        <Button
          type='primary'
          onClick={() => (onClick({ intent }))}
          style={{ fontSize: '26px', height: '60px', background: buttonColor, borderColor: buttonColor, borderRadius: '4px', marginTop: 0 }}
        >
          <>{text}{' '}<ArrowRightOutlined style={{ fontSize: '16px', fontWeight: 'lighter', color: 'white', verticalAlign: 'middle' }} /></>
        </Button>

        {
          textLabel && 
          <p>{textLabel}</p>
        }
        <p></p>
        {
          secondaryText && secondaryIntent &&
          <Button
            type='default'
            onClick={() => (onClick({ intent: `${secondaryIntent}` }))}
          >
            {linkLabel || secondaryText}
          </Button>
        }
      </Space>
    </>
  );
}


function trackTagData({ event, tagId, tag, user }) {
  const isTest = !!(tag.is_test);

  const tracker = new TrackerService({
    tagId,
    isTest,
    object: tag,
  });

  let dataToTrack = {
    tagId: tagId,
    isAnonymous: user.isAnonymous,
  };

  if (tag && tag.templateTagId) {
    dataToTrack = {
      ...dataToTrack,
      templateTagId: tag.templateTagId,
    };
  }

  tracker.mixpanelTrack(event, {
    distinct_id: user.uid,
    ...dataToTrack
  });

  if (!isTest) {
    tracker.googleTrack(
      event,
      {
        userId: user.uid,
        ...dataToTrack
      }
    );
  }

}

export function ClaimTagComponent({ tagId, tag, user, locStrings, loading, onAction, ...rest }) {
  const {
    product,
    topLogo,
    avatar,
    actionText,
    displayName,
    profileBio,
    is_test,
    rgbColor,
  } = tag;

  const topBackgoundColor = rgbColor;
  const backgroundColorStyle = { backgroundColor: topBackgoundColor ?? StrikeColors.magenta, ...mainSizeStyle };

  const isTest = !!is_test;
  const shouldShowScanMeText = window.innerWidth >= 768;

  const onClickButton = ({ intent }) => {
    const event = `start_${intent}_clicked`;
    trackTagData({ event, tagId, tag, user });
    if (onAction) onAction(intent);
  };


  const isAnonymous = user && user.displayName ? false : true;
  const productSwitcher = new ProductSwitcher({ locStrings });
  const productMap = productSwitcher.getProductMap(product);
  let { heading, subheading, subsubheading } = productMap;

  let confirmClaimButtonText = locStrings.begin_claim_start_button;
  let defaultSignupText = locStrings.begin_claim_signup_button;
  let signupWarningText = locStrings.begin_claim_tag_part_1;
  let additionalInfoForTag = [];
  let signupImage = null;

  if (actionText) {
    heading = actionText.heading || heading;
    subheading = actionText.subheading || subheading;
    subsubheading = actionText.subsubheading || subsubheading;
    confirmClaimButtonText = actionText.claim || confirmClaimButtonText;
    defaultSignupText = actionText.signup || defaultSignupText;
    additionalInfoForTag = actionText.questions || additionalInfoForTag;
    signupWarningText = actionText.questionsTitle || signupWarningText;
    signupImage = actionText.signupImage || signupImage;
  }

  let supportingTextForButton = isAnonymous ?
    locStrings.begin_claim_tag_part_2_new :
    locStrings.begin_claim_tag_part_2_existing;

  return (
    <div style={baseStyle}>
      <div className="elImage">
        {((!loading && topLogo) ?
          (
            <img style={style_elImageTopLogo} src={(topLogo)} alt="strike" />
          ) :
          (
            <img style={style_elImageLogo} src={(img_elImage)} alt="strike" />
          )
        )}
      </div>
      <div style={mainSizeStyle}>
        <div style={mainSizeStyle}>
          <Space direction='vertical' size='small' style={mainSizeStyle}>
            <div style={backgroundColorStyle}>
              <div style={bodySizeStyle}>
                <ContrastColor backgroundColor={rgbColor}>
                  <p style={{ fontSize: '36px', fontStyle: 'bold' }}>{heading}</p>
                  <p style={{ fontSize: '20px' }}>{subheading}</p>
                  <div style={{ textAlign: 'start', paddingBottom: '12px' }}>
                    {(signupImage || avatar) 
                      ?
                      <img style={style_elImage} src={(signupImage || avatar)} alt="strike" />
                      :
                      <>
                        <QRImageView tag={tag} tagId={tagId} nosubtext={true} locStrings={locStrings} />
                        { shouldShowScanMeText &&
                          <p style={{ textAlign: 'center', fontSize: '16px', fontStyle: 'italic' }}>
                            To continue on your phone, scan this QR with your phone camera
                          </p>
                        }
                      </>
                    }
                  </div>
                  <p style={{ fontSize: '20px' }}>{displayName}</p>
                </ContrastColor>
              </div>
            </div>

            <div style={bodySizeStyle}>
              <div style={{ minWidth: '30px' }}><p></p></div>
              <div style={{ textAlign: 'start'}}>
                <div>
                  <ActionButton
                    text={isAnonymous ? defaultSignupText : confirmClaimButtonText}
                    intent={isAnonymous ? 'signup' : 'claim'}
                    secondaryText={isAnonymous ? null : 'Go to Dashboard'}
                    secondaryIntent={isAnonymous ? 'signin' : 'dashboard'}
                    onClick={onClickButton}
                    color={isAnonymous ? StrikeColors.magenta : StrikeColors.blue}
                  />
                </div>
                {
                  isAnonymous &&
                  <Title level={5}>{subsubheading}</Title>
                }
                { !isAnonymous && user && user.email && user.displayName &&
                  <Paragraph>{`${supportingTextForButton} ${user.email} (${user.displayName})`}</Paragraph>
                }
              </div>
              <div style={{ minWidth: '30px' }}><p></p></div>
            </div>
          </Space>

          <div style={bodySizeStyle}>
            {
              additionalInfoForTag.length > 0 &&
              <Paragraph style={{ paddingTop: 10, fontSize: 16, fontWeight: 'bold' }}>{signupWarningText}</Paragraph>
            }
            {
              additionalInfoForTag.length > 0 &&
              additionalInfoForTag.map((item, i) => (
                <Row key={`${i}-card`} style={{ textAlign: 'start' }}>
                  <Col span={6} style={{ fontWeight: 'bold' }} key={`${i}-q`}>{`${item.question}`}</Col>
                  <Col span={18} style={{ fontWeight: 'normal' }} key={`${i}-a`}>{`${item.answer}`}</Col>
                </Row>
              ))
            }
          </div>
        </div>
      </div>

    </div>
  );

}


