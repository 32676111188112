import React, { Component } from 'react';
import { Button } from 'antd';

import strikeLogo from '../../../images/strikepay_Mark_RGB_Black.png';
import FooterCard from '../../../components/customer/FooterCard';
import PoweredByStrike from '../../../components/customer/PoweredByStrike';

import '../../../App.scss';


export default class ClientEmailVerifiedScreen extends Component {

	constructor(props) {
		super(props);

		this.state = {
			locStrings: {
				terms_and_conditions: "TERMS",
				privacy_policy: "PRIVACY",
				paymentcard2_cashlesstippingtext_46302: "Powered by ",
			}
		};
	}

	routeToLogin = () => {
		this.props.history.push("/client/login");
	}

	render() {
		let layoutFlowStyle = {};
		let baseStyle = {};

		const style_elBackground = {
			width: '100%',
			height: '100vh',
		};
		const style_elBackground_outer = {
			backgroundColor: '#f6f6f6',
		};

		if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
			baseStyle.animation = '0.25s ease-in-out ' + this.props.transitionId;
		}
		if (!this.props.atTopOfScreenStack) {
			layoutFlowStyle.height = '100vh';
			layoutFlowStyle.overflow = 'hidden';
		}

		const style_elCard_outer = {
			padding: '7.0px',
			borderRadius: '4.0px',
			overflow: 'visible',
		};

		const style_elImageLogo = {
			height: 'auto',
			cursor: 'pointer',
			pointerEvents: 'auto',
			width: '50%',
			marginLeft: 'auto',
			marginRight: 'auto',
		};

		const style_elFooterContainerCard_outer = {
			padding: '4.0px',
			borderRadius: '4.0px',
			overflow: 'visible',
		};

		const style_copyBlock = {
			marginTop: "50px",
			marginBottom: "50px",
		};

		return (
			<div className="AppScreen TagDetailScreen" style={baseStyle}>
				<div className="background">
					<div className="containerMinHeight elBackground" style={style_elBackground_outer}>
						<div className="appBg" style={style_elBackground}>
							<div className="layoutFlow" style={layoutFlowStyle}>
								<div className="flowRow flowRow_TagDetailScreen_elCard_565678">
									<div className="elCard" style={style_elCard_outer}>
										<div className="cardBg">

											<div className="elImage">
												<img style={style_elImageLogo} src={(strikeLogo)} alt="Strikepay" />
											</div>

											<div style={style_copyBlock}>
												<h1>Email verified</h1>
												<p>
													We've verified your email and you can now continue with the signup process.
													<br /><br />
													<Button key="submit" type="primary" onClick={this.routeToLogin}>
														Continue
													</Button>
												</p>
											</div>
										</div>

										{
											<div className="elFooterContainerCard" style={style_elFooterContainerCard_outer}>
												<div className="hasNestedComps elFooterCard">
													<div>
														<PoweredByStrike locStrings={this.state.locStrings} />
														<FooterCard ref={(el) => this._elFooterCard = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.state.locStrings} />
													</div>
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
