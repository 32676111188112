import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Empty, PageHeader } from 'antd';

import { DeveloperConfigView } from '../../../components/admin/DeveloperComponents';

// /admin/balances
export default class ClientDeveloperScreen extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);
    this.clientId = this.props.match.params.clientId;
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    this.clientId = null;
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return false;
  }

  render() {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Developer`}
            onBack={(() => window.history.back())}
          />
          <DeveloperConfigView key={this.clientId ? `webhooks-${this.clientId}` : `webhooks-strike`} clientId={this.clientId}/>
        </div>
      );
  }
}