import firebase from 'firebase/app';

import { DefaultTeamPermissions } from '../utils/Constants';

export default class StrikeTeamsModel {

  constructor({ viewModel, clientId, onUpdate, limit }) {
    this.viewModel = viewModel;
    this.clientId = clientId;
    this.dataArray = [];
    this.client = null;
    this.dataDictionary = {};
    this.onUpdate = onUpdate;
    this.limit = limit;
  }

  getQueryForTeamsForClient(params) {
    const {
      clientId,
    } = params;

    const db = firebase.firestore();
    let query = db.collectionGroup('members').where('clientId', '==', clientId).where('isDeleted', '==', false).orderBy('updatedAt', 'desc');
    if (this.limit) query = query.limit(this.limit);
    return query;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if(this.observerTeamsCollectionGroup) {
      this.observerTeamsCollectionGroup();
      this.observerTeamsCollectionGroup = null;
    }
  }

  async fetchAllTeamsForClient()  {
    if (this.clientId === null) return;

    const clientId = this.clientId;

    this.stop();

    const db = firebase.firestore();
    let queryResults = [];
    this.dataArray = [];
    this.indexes = [];

    const query = this.getQueryForTeamsForClient({
      clientId
    });

    this.observerTeamsCollectionGroup = query.onSnapshot(querySnapshot => {
      let index = querySnapshot.size;
      this.dataArray = [];

      querySnapshot.docChanges().forEach(
        change => {
          if(change.type === 'added' || change.type === 'modified') {
            const itemDoc = change.doc.data();
            this.indexes = this.indexes.filter( item => (item !== itemDoc.teamId) );
            this.dataDictionary[itemDoc.teamId] = itemDoc;
            this.indexes = [...this.indexes, itemDoc.teamId];
          } else if (change.type === 'removed' )  {
            const itemDoc = change.doc.data();
            this.indexes = this.indexes.filter(item => (item !== itemDoc.teamId));
          }
        }
      );

      this.updateDataSource();

    }, err => {
      this.dataArray = [];
      this.dataDictionary = {};
      this.updateDataSource();
      console.log(`Team query error: ${err}`);
    });


  }

  async updateDataSource() {
    this.dataArray = [];

    this.indexes.forEach( (prop) => {
      const item = this.dataDictionary[prop];
      if(item) this.dataArray.push(item);
    });

    this.viewModel && this.viewModel.updatedTeamsDataSource(this.dataArray);
    this.onUpdate && this.onUpdate(this.dataArray);
  }

  async makeNewTeamObject(formData, client) {
    if (this.clientId === null) return;
    const db = firebase.firestore();
    const res = await db.collection('teams').add({
      teamName: formData.teamName,
      teamOwnerClientId: this.clientId,
      teamDescription: formData.teamDescription ? formData.teamDescription : null,
      defaultCurrency: formData.defaultCurrency ? formData.defaultCurrency : null,
      defaultPermissions: formData.defaultPermissions,
      teamTapBehaviour: formData.teamTapBehaviour,
      placeOfWork: (client && client.employerName) ? client.employerName : null,
    });
    return res.id;
  }


  createTeamWithDefaultSettings = async ({ teamName, teamCurrency }) => {
    const defaultPermissions = { ...DefaultTeamPermissions };

    const db = firebase.firestore();
    const newTeamDoc = await db.collection("teams").add({
      teamName,
      defaultCurrency: teamCurrency,
      teamOwnerClientId: this.clientId,
      teamDescription: null,
      defaultPermissions,
      teamTapBehaviour: "members",
      placeOfWork: null,
    });

    return newTeamDoc.id;
  }

}
