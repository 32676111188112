import React, { Component } from 'react';
import { Layout } from 'antd';

import StrikeFooter from '../../components/StrikeFooter';

import 'antd/dist/antd.css';
import '../../App.scss';

const { Header, Content, Footer } = Layout;

export default class AnonLayout extends Component {
  render() {
    return (
      <Layout className="anon-layout">
        <Content style={{ padding: '0 0px', minWidth: '300px', width: '100%' }}>
          <div className="site-layout-content">
            {this.props.children}
          </div>
        </Content>
        <StrikeFooter locStrings={this.props.locStrings} />
      </Layout>
    );
  }
}
