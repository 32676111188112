import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Typography, PageHeader, Button, Modal, Alert } from 'antd';

import StrikeClientModel from '../../../models/StrikeClientModel';
import StrikeTeamsModel from '../../../models/StrikeTeamsModel';
import { PauseButton } from '../../../components/client/AccountProfileComponents';
import ClientAvatar from '../../../components/client/ClientAvatar';
import { TeamsGrid } from '../../../components/client/TeamDashboardComponents';
import CenteredSpinner from '../../../components/CenteredSpinner';
import { CreateTeamFormMinimal } from '../../../components/client/CreateTeamFormMinimal';
import TrackerService from '../../../utils/TrackerService';

import '../../../App.scss';
import 'antd/dist/antd.css';

const { Paragraph } = Typography;

const SortByPicker = ({ sortBy, setSortBy }) => {
  return (
    <Button.Group>
      <Button
        onClick={() => setSortBy('name')}
        type={sortBy === 'name' ? 'primary' : 'default'}
      >
        Sort by Name
      </Button>
      <Button
        onClick={() => setSortBy('date')}
        type={sortBy === 'date' ? 'primary' : 'default'}
      >
        Most recent
      </Button>
    </Button.Group>
  );
};


// /client/dashboard/:clientId
export default class ClientDashboardScreen extends Component {
  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    const isAdmin = !!props.isAdmin;
    this.teamsModel = new StrikeTeamsModel({viewModel: this, clientId});
    this.clientModel = new StrikeClientModel({
      onClientFound: this.foundClient,
      onClientNotFound: this.clientNotFound,
      viewModel: this,
      clientId,
      isAdmin,
    });


    this.state = {
      clientNotFound: false,
      route: null,
      showTestAccounts: true,
      user: null,
      client: null,
      clientId: clientId,
      percent: 0,
      teamList: [],
      loadingTeams: true,
      loadingUser: true,
      loadingClient: true,
      isAdmin,
      showAddTeamModal: false,
      sortBy: 'name',
    };
  }

  componentDidMount() {
    this.clientModel.waitForClientData();
  }

  componentWillUnmount() {
    this.stopLoading();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return  this.state.loadingUser ||
            this.state.loadingClient ||
            this.state.loadingTeams ||
            false;
  }

  stopLoading = () => {
    if (this.teamsModel) this.teamsModel.stop();
    if (this.clientModel) this.clientModel.stop();
  }

  foundClient = async (client) => {

    this.setState(
      (state) => {
        return {
          clientNotFound: false,
          client,
          loadingUser: false,
          loadingClient: false,
          loadingPauseAction: false,
        }
      }
    );

    this.teamsModel.fetchAllTeamsForClient();
  }


  clientNotFound = () => {
    this.setState({
      clientNotFound: true
    });
  }

  createTeam = async (data) => {
    const isLocalHost = window.location.hostname === 'localhost';
    const isTest = isLocalHost;

    this.setState({ creatingTeam: true });

    const teamName = data.teamName;
    const teamCurrency = data.defaultCurrency;

    const newTeamId = await this.teamsModel.createTeamWithDefaultSettings({ teamName, teamCurrency });

    const tracker = new TrackerService({
      isTest,
    });

    const payloadData = {
      teamOwnerClientId: this.clientId,
      teamName,
      teamCurrency,
    }

    tracker.mixpanelTrack("create_team", payloadData);

    if (!isTest) {
      tracker.googleTrack("create_team", payloadData);
    }

    window.scrollTo(0, 0);

    this.setState({
      showAddTeamModal: false,
      newTeamId,
      newTeamName: teamName,
      creatingTeam: false,
      routeToNewTeam: true,
    });
  }


  updatedTeamsDataSource = (teams) => {
    const sortedTeamsByName = teams.sort((a, b) => {
      return a.teamName.localeCompare(b.teamName);
    });

    this.setState({
        teamList: sortedTeamsByName,
        loadingTeams: false,
    });
  }

  render() {
    const avatar = <ClientAvatar key={`${this.state.clientId}-avatar-client`} client={this.state.client} />
    const avatarParams = { src: avatar };

    const clientNameTeams = (this.state.client && this.state.client.displayName) ? `${this.state.client.displayName}'s Teams` : null;
    const isRestricted = this.state.client && this.state.client.isRestricted;
    const isAdmin = this.props && this.props.isAdmin;

    const clientButtons = [
        <Button 
          key='add-team-btn' 
          onClick={() => (this.setState({ showAddTeamModal: true }))} 
          style={{ backgroundColor: '#27AE60', borderColor: '#27AE60', color: 'white',  borderRadius: '4px' }}
        >Create a team</Button>
    ];
    const adminButtons = [...clientButtons];


    if (!!this.state.routeToNewTeam && this.state.newTeamId) {
      const teamLink = isAdmin ?  `/admin/clients/${this.state.clientId}/teams/${this.state.newTeamId}` : `/client/manage/${this.state.clientId}/teams/${this.state.newTeamId}`;
      return <Redirect push to={teamLink} />
    }
    
    return (
      <div className="accountProfile" style={{ height: '100%' }}>
        { (this.state.client) && !isAdmin && 
          <div style={{ paddingBottom: '5px' }}>
            <Alert 
              message={'Create a team to get started.'} 
              type="info" 
              closable 
            />
          </div>
        }

        {(this.state.clientNotFound) &&
          (<PageHeader
            ghost={false}
            title={`Account Not Found`}
            subTitle={`Please register`}
            onBack={(() => window.history.back())}
          />)
        }
        {(this.state.client && isAdmin) &&
          (<PageHeader
            ghost={false}
            title={clientNameTeams || 'Your Teams'}
            avatar={avatarParams}
            extra={adminButtons}
            onBack={(() => window.history.back())}
          />)
        }
        {(this.state.client && !isAdmin) &&
          (<PageHeader
            ghost={false}
            title={'Your Teams'}
            extra={clientButtons}
            onBack={(() => window.history.back())}
          />)
        }
        {(this.state.client === null) &&
          (null)
        }
        {
          <Modal
            title="Create a team"
            visible={this.state.showAddTeamModal}
            onCancel={() => (this.setState({ showAddTeamModal: false, newTeamId: null, newTeamName: null }))}
            footer={[
              <Button key="back" onClick={() => (this.setState({ showAddTeamModal: false, newTeamId: null, newTeamName: null }))}>
                Cancel
              </Button>,
            ]}
          >
          <p>You can edit and customise your new team's appearance and settings later.</p>

          <CreateTeamFormMinimal
            callback={this.createTeam}
            edit={false}
            loading={this.state.creatingTeam}
            client={this.state.client}
          />
        </Modal>
        }
        {<CenteredSpinner loading={this.isLoading()} />}
        { (this.state.newTeamId) &&
          <div style={{ paddingBottom: '5px' }}>
            <Alert 
              message={`${this.state.newTeamName} successfully created. Redirecting...`} 
              type="success" 
              closable 
            />
          </div>
        }
        {!this.state.loadingTeams && this.state.teamList.length > 4 &&
          <div style={{ paddingBottom: '5px' }}>
            <SortByPicker
              sortBy={this.state.sortBy}
              setSortBy={(sortBy) => this.setState({ sortBy })}
            />
          </div>
        }        
        { 
          <TeamsGrid
            condition={() => {
              return !this.state.loadingTeams
            }}
            teams={this.state.teamList}
            clientId={this.state.clientId}
            client={this.state.client}
            onAddAccount={this.onClickAdd}
            isAdmin={isAdmin}
            sortBy={this.state.sortBy}
          />
        }
      </div>
    );
  }
}

