import React, { Component } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import { Space, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default class ProgressSpinner extends Component {

    constructor(props) {
        super(props);

        this.state = {
            spinning: this.props.spinning || true,
            delay: this.props.delay || 30,
            size: this.props.size || 'small',
            containerSize: this.props.containerSize || 'large',
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate() {
    }

    start = () => {
      this.setState({ spinning : true  })
    }

    stop = () => {
      this.setState({ spinning : false  })
    }

    toggle = () => {
      this.setState((state) => { return { spinning : !state.spinning } })
    }

    render() {
        const {
          spinning,
          delay,
          size,
          containerSize,
        } = this.state;

        const spinnerIcon = <LoadingOutlined spin/>;

        return (
            <div className="ProgressSpinner">
              <Space size={containerSize} >
                <Spin delay={delay} spinning={spinning} size={size} indicator={spinnerIcon} />
              </Space>
            </div>
        );

    }
}
