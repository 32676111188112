
export default class StrikeStateFlows  {

    constructor(props) {
        this.initialize();
      }


    initialize() {
        this.tagStates = [
            'available', 
            'claimed',
            'live',
            'paused',
            'blocked',
        ];

        this.tagStatesTransitions = {
            available : {
                claimed : 'claim', // <state> : <action> (sets: { ownerId : uid })
            }, 
            claimed : {
                live : 'publish', // Requires: uid == ownerId
            },
            live: {
                paused : 'pause', // Requires: uid == ownerId
                live : 'tap', // Requires: uid != ownerId
            },
            paused: {
                live : 'republish', // Requires: uid == ownerId
                blocked : 'block',  // Requires: uid == ownerId
                available : 'unclaim', // Requires: uid == ownerId
            },
            blocked: {
                available : 'release', // Requires: Strike Admin
            },
        };
    }


    getUserStateForTag( tag, user ) {
        const { status, ownerId } = tag;
        const uid = (!!user && user.uid) ? user.uid : '';
        const isOwner = (ownerId === uid);
        const isLoggedIn = !!user && !(user.isAnonymous);

        // console.log(`getUserStateForTag isOwner=${isOwner} isLoggedIn=${isLoggedIn} status=${status}`);

        return { isOwner, isLoggedIn }; /// Used to control the display of buttons in TagDetailScreen
    }


    getActionsForTagState( tag, user ) {
        const { status, ownerId } = tag;
        const uid = (!!user && user.uid) ? user.uid : '';
        const isOwner = ownerId && uid && (ownerId === uid);
        const isLoggedIn = !!user && !(user.isAnonymous);

        const actions = {
            'pay'           : (!isOwner && (status === 'live' || status === 'created')),  
            'claim'         : (status === 'available'),
            'edit'         : (isOwner && status === 'live'),
            'publish'       : (isOwner && status === 'claimed'),
            'republish'    : (isOwner && status === 'paused'),
            'unclaim'       : (isOwner && status === 'paused'),
            'block'         : (isOwner && status === 'paused'),
            'register'      : false, // Needed elsewhere
            'account'       : false, // (isOwner && isLoggedIn && status === 'live'),
            'release'       : (status === 'blocked'), // ADMIN system only
        };

        // console.log(`getActionsForTagState tag.ownerId=${ownerId} ?= user.uid=${uid}  tag.status=${status} :: ${JSON.stringify(actions)}`);

        this.actions = actions;

        return actions; /// Used to control the display of buttons in TagDetailScreen
    }

    /// Returns: { action, errorMessage }
    /// action is one of null, `claim`, `edit`, `pay`
    /// aciton is null, errorMessage is String with error.
    getSimplifiedActionsForTagState( tag, user ) {
        var action = null;
        var errorMessage = null;
        var { status, ownerId } = tag;
        const uid = (user && user.uid && user.uid.length > 0)? user.uid : null;
        ownerId = (ownerId && ownerId.length > 0)? ownerId : null;
        const isAvailable = (status === null || status.length == 0 || status === 'available')  ? true : false;
        const isOwner = ((uid && ownerId) && (uid === ownerId)) ? true : false;
        const isLive = (status === null || status.length == 0 || status === 'live')  ? true : false;
        const isBlocked = (status === null || status.length == 0 || status === 'blocked')  ? true : false;

        if(isAvailable) {
            action = 'claim';
        } else if(isOwner) {
            switch(status) {
                case 'live':
                    action = 'edit';
                    break;
                case 'claimed':
                    action = 'edit';
                    break;
                case 'paused':
                    action = 'edit';
                    break;
                case 'blocked':
                    action = null;
                    errorMessage = `Tag is blocked`;
                    break;
                default:
                    action = null;
                    errorMessage = `Invalid state ${status}`;
                    break;
            }
        } else if (isLive & !isOwner) {
            action = 'pay';
        } else {
            action = null;
            errorMessage = `Invalid state ${status}`;
        }

        // console.log(`getSimplifiedActionsForTagState tag.ownerId=${ownerId} ?= user.uid=${uid}  tag.status=${status} :: { ${action}, ${errorMessage} }`);

        return { action, errorMessage }; 
    }

    updateTagClaimByUserIfValid( tag, user ) {
        var { status, ownerId } = tag;
        const { uid } = user;

        return (ownerId === null && status === 'available') 
            ? {
                ...tag,
                ownerId : uid,
                status : 'claimed',  
            } 
            : {
                ...tag,
            };
            
    }

    messagesForTagActions(tag, user, actions ) {
        const { status, ownerId } = tag;
        const uid = (!!user && user.uid) ? user.uid : '';
        const isOwner = ownerId && uid && (ownerId === uid);
        const isLoggedIn = !!user && !(user.isAnonymous);
        var infoText = '';
        var warnText = '';
        var errorText = '';

        // console.log(`messagesForTagActions ${ownerId || 'none'} ?= ${uid} :: ${JSON.stringify(actions)}`);
    
        infoText = infoText + ((actions['pay'] && !isOwner) ? 'You accept the fees' : '');
        errorText = errorText + ((actions['pay'] && isOwner) ? 'You cannot pay yourself!' : '');
    
        // if(actions['pay'] && isOwner && isLoggedIn) { 
        //     actions['edit'] = true;
        //     actions['pay'] = false;
        //     errorText = '';
        // }

        infoText = infoText + ((actions['claim'] && !isOwner) ? 'Claim this tag' : '');
        errorText = errorText + ((actions['claim'] && isOwner) ? 'Already claimed!' : '');
        
        infoText = infoText + ((actions['publish'] && isOwner) ? 'Publish this tag' : '');
        errorText = errorText + ((actions['publish'] && !isOwner) ? 'Only the owner can publish this tag!' : '');
    
        infoText = infoText + ((actions['edit'] && isOwner) ? 'Edit this tag' : '');
        errorText = errorText + ((actions['edit'] && !isOwner) ? 'Only the owner can edit this tag!' : '');
    
        warnText = warnText + ((actions['unclaim'] && isOwner) ? 'WARN: Unclaim cannot be undone!' : '');
        errorText = errorText + ((actions['unclaim'] && !isOwner) ? 'Only the owner can unclaim this tag!' : '');
    
        warnText = warnText + ((actions['release']) ? '' : '');
        errorText = errorText + ((actions['release']) ? 'Only Strikepay can unblock this tag!' : '');

        infoText = infoText + ((actions['register']) ? 'Register for a Strikepay account' : '');
        infoText = infoText + ((actions['account']) ? '' : '');

        // console.log(`messagesForTagActions info:${infoText} error:${errorText} warn:${warnText}`);

        return { infoText, errorText, warnText, actions  };
    }

    tapAllowedForTag(actions) {
        return (actions['pay'] && !actions['account']);
    }


}