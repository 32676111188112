import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import firebase from 'firebase/app';
import { Card, Image, Skeleton } from 'antd';
import qs from 'qs';

import Redirect404 from '../../components/Redirect404';
import TrackerService from '../../utils/TrackerService';
import PoweredByStrike from '../../components/customer/PoweredByStrike';
import FooterCard from '../../components/customer/FooterCard';
import StrikeScanEventModel from '../../models/StrikeScanEventModel';
import img_LogoImage from '../../images/strikepay_Mark_RGB_Black.png';

import '../../App.scss';
import 'antd/dist/antd.css';

export default class TagDetailScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, rating, countOfTips, averageRating, messageText, values, topLogo, profileBio, displayName, avatar, selectedValueByIndex, bottomLogo

  constructor(props) {
    super(props);
    
    this.state = {
      loading: true,
      loadingText: 'Loading...'
    };

    document.title = "Tag - Strikepay";
  }

  async componentDidMount () {
    this.startScan();
  }

  async getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
       const unsubscribe = auth.onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
       }, reject);
    });
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }


  // MARK: Routing tap to proper location

  startScan = async () => {
    let user = await this.getCurrentUser(firebase.auth());

    if (!user || user.isAnonymous) {
      const result = await firebase.auth().signInAnonymously();
      user = result.user;
    }

    const isLocalHost = window.location.hostname === 'localhost';

    const parsed = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const cancelled = parsed.cancelled;
    const isTest = isLocalHost || parsed.isTest;
    const uuid = parsed.uuid;

    let tagUid = null;
    let qrUid = null;
    let source = null;
    let teamMemberId = null;

    // payment link query params
    let amount = null;
    let name = null;
    let reference = null;
    let refId = null;

    let urlParameter = null;

    if (this.props.location && this.props.location.search) {
      const parsed = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
      if (parsed.uid || parsed.UID) {
        tagUid = parsed.uid || parsed.UID;
        source = "nfc";
      } else if (parsed.qr || parsed.QR) {
        qrUid = parsed.qr || parsed.QR;
        source = "qr"
      }

      if (parsed.tmid) teamMemberId = parsed.tmid;
      if (parsed.amount) amount = parseInt(parsed.amount);
      if (parsed.name) name = decodeURIComponent(parsed.name);
      if (parsed.reference) reference = decodeURIComponent(parsed.reference);
      if (parsed.refId) refId = decodeURIComponent(parsed.refId);
      if (parsed.url) urlParameter = decodeURIComponent(parsed.url);
    }

    const tagId = this.props.match.params.tagId;
    
    const payloadData = {
      tagId,
      tagUid: tagUid || qrUid,
      source,
      uid: user.uid,
      teamMemberId,
      amount,
      name,
      reference,
      refId,
      url: urlParameter,
    }

    this.setState({
      user,
      waitingOnTagEvent: true,
      cancelled,
      isTest,
      tagId,
      tagUid: tagUid || qrUid,
      qrUid,
      source,
      amount: parsed.amount,
      currency: parsed.currency,
      uuid,
      templateTagId: tagId,
      url: urlParameter,
    });

    this.trackScan();

    this.waitOnTagEventUpdate(payloadData);
  }


  waitOnTagEventUpdate = async (payloadData) => {
    this.scanModel = new StrikeScanEventModel({
      ...payloadData
    });

    let scanRef = await this.scanModel.createScan();
    this.scanId = scanRef.id;

    this.setState({
      waitingOnTagEvent: true,
      loadingText: `Waiting on server...`
    });


    this.scanModel.observeScan(this.scanId,
      (result) => {
        if(result.error) {
          console.log(result.error);
          this.scanModel.stop();
          this.setState({
            waitingOnTagEvent: false,
            loadingText: `Could not resolve scan.`,
            ...result,
            loading: false,
            redirectRoute: null,
          });
        } else if(result.route) {
          this.scanModel.stop();
          this.handleTagEvent({ ...result });
        }
      }
    );

  }

  handleTagEvent = async (result) => {
    let user = this.state.user;

    let route = result.route;
    let tapId = result.tapId;
    let tagId = result.tagId || this.state.tagId;
    let clientId = result.clientId;
    let redirectUrl = result.redirectUrl;

    let tagUid = this.state.tagUid;
    let qrUid = this.state.qrUid;
    let source = this.state.source;
    let isTest = this.state.isTest;
    let urlParameter = this.state.url;

    let redirectRoute = "/404";
    let routeType = "404";

    const tracker = new TrackerService({
      tagId,
      object: this.state.tag,
      isTest,
    });

    if (route === "tap") {
      // NOTE: Will now 404
      redirectRoute = `/v1/tags/${tagId}/taps/${tapId}`;

      if (result.queryParams) {
        redirectRoute += result.queryParams;
      }

      routeType = "tap";
    } else if (route === "claim") {
      let route = `/claim/${tagId}`;
      routeType = "claim";

      if (tagUid) {
        route += `?uid=${tagUid}`;
      } else if (qrUid) {
        route += `?qr=${qrUid}`;
      }

      redirectRoute = route;
    } else if (route === "client-manage") {
      redirectRoute = `/client/manage/${clientId}`;
      routeType = "client-manage";
    } else if (route === "tag-manage") {
      redirectRoute = `/client/manage/${clientId}/tags/${tagId}`;
      routeType = "tag-manage";
    } else if (route === "team-member-tipping") {
      const teamId = result.teamId;

      redirectRoute = `/team-tipping/${teamId}/${tagId}/${tapId}?membersOnly=true`;
      routeType = "team-member-tipping";
    } else if (route === "team-and-member-tipping") {
      const teamId = result.teamId;

      redirectRoute = `/team-tipping/${teamId}/${tagId}/${tapId}?membersOnly=false`;
      routeType = "team-and-member-tipping";
    } else if (route === "redirect") {
      routeType = "external-redirect";
      redirectRoute = redirectUrl;
    } else if (route === 'paused-404'){
      //For future - logic for paused tag error message display will begin from here
    }

    tracker.mixpanelTrack("tag_lookup", {
      distinct_id: user.uid,
      tagId: tagId,
      templateTagId: this.state.tagId,
      redirectType: routeType,
      isAnonymous: user.isAnonymous,
      source: this.state.source || 'none',
      tagUid: this.state.tagUid || 'none',
      url: this.state.url,
    });  

    if (!isTest) {
      tracker.googleTrack(
        "tag_lookup", 
        {
          userId: user.uid,
          tagId: tagId,
          templateTagId: this.state.tagId,
          redirectType: routeType,
          isAnonymous: user.isAnonymous,
          source: this.state.source || 'none',
          tagUid: this.state.tagUid || 'none',
          url: this.state.url,
        }
      );
    }

    if (route === "redirect") {
      const fromUrl = this.props.location ? this.props.location.pathname : null;
      tracker.mixpanelTrack("tag_redirect", {
        distinct_id: user.uid,
        tagId: tagId,
        templateTagId: this.state.tagId,
        redirectUrl: redirectUrl,
        fromUrl: fromUrl,
        isAnonymous: user.isAnonymous,
        source: this.state.source || 'none',
        tagUid: this.state.tagUid || 'none',
        url: this.state.url,
      });
      window.location.replace(redirectUrl);
    }

    if (this.state.cancelled) {
      const checkout_tag_id = tagId;
      const checkout_total_amount = this.state.amount;
      const checkout_currency = this.state.currency;

      const { decimalAmount, decimalFee } = tracker.intValuesToDecimals({ amount: checkout_total_amount, fee: 0  });
  
      // Track all default data plus fees and amount as calculated in this method
      tracker.mixpanelTrack("checkout_cancelled", {
        distinct_id: this.state.uuid,
        currency: checkout_currency,
        tagId: checkout_tag_id,
        amount: decimalAmount,
      });  

      if (!isTest) {
        tracker.googleTrack(
          "checkout_cancelled", 
          {
            currency: checkout_currency,
            tagId: checkout_tag_id,
            value: decimalAmount,
          }
        );
      }
    }

    this.setState({
      waitingOnTagEvent: false,
      loadingText: 'Done',
      loading: false,
      redirectRoute,
    });
  }

  trackScan = async () => {
    let user = await this.getCurrentUser(firebase.auth());
    const isLocalHost = window.location.hostname === 'localhost';
    let isTest = this.state.tag ? this.state.tag.is_test : isLocalHost;    

    const tracker = new TrackerService({
      tagId: this.state.tagId,
      object: this.state.tag,
      isTest,
    });

    tracker.mixpanelTrack("tag_scan", {
      distinct_id: user.uid,
      tagId: this.state.tagId,
      isAnonymous: user.isAnonymous,
      templateTagId: this.state.templateTagId || 'generic',
      source: this.state.source || 'none',
      tagUid: this.state.tagUid || 'none',
      url: this.state.url,
    });

    if (!isTest) {
      tracker.googleTrack(
        "tag_scan",
        {
          userId: user.uid,
          tagId: this.state.tagId,
          isAnonymous: user.isAnonymous,
          templateTagId: this.state.templateTagId || 'generic',
          source: this.state.source || 'none',
          tagUid: this.state.tagUid || 'none',
          url: this.state.url,
        }
      );
    }
  }


  // MARK: render()
  renderTagSearching = () => {    
    const gridStyle = {
      width: '100%',
      textAlign: 'start',
    };

    const loadingText = this.state.loading ? (this.state.loadingText || "Loading...") : "";

    return (
      <div style={{ maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto', }}>
        <Image
          src={img_LogoImage}
          style={{ width: 'auto', maxHeight: '40px', marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' }}
        />

        <Card
          style={{ maxWidth: '100%', margin: 'auto' }}
        >
            <Card.Grid style={gridStyle}><Skeleton loading={true} active /></Card.Grid>
            <Card.Grid style={gridStyle}><Skeleton.Image loading={true} active /></Card.Grid>
            <Card.Grid style={gridStyle}><p style={{ fontWeight: 'bold' }}>{loadingText}</p></Card.Grid>
            <Card.Grid style={gridStyle}><Skeleton.Button loading={true} active size='large' /></Card.Grid>
        </Card>
      </div>
    );
  }


  render() {
    const { loading, redirectRoute } = this.state;

    if (!loading && redirectRoute) {
      return (<Redirect to={redirectRoute}/>);
    } else if (loading) {
      return this.renderTagSearching();
    } else {
      return <Redirect404 fromPath={this.props.location ? this.props.location.pathname : null} reason={"tag-not-found"} />
    }
  }

}
