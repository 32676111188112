import { useState, useEffect } from 'react';

import WebhooksModel from './WebhooksModel';

export function useWebookEvents(partnerClientId, webhookConfigId, types, webhookStatus) {
    const [webhookEvents, setWebhookEvents] = useState(null);

    useEffect(() => {
        const onUpdateEvents = async (eventsList) => {
            setWebhookEvents(eventsList);
        };


        const model = new WebhooksModel({
            partnerClientId,
            onUpdateEvents,
            eventParams: {
                webhookConfigId, types, webhookStatus
            }
        });

        model.waitForWebhookEventsData();

        return function cleanup() {
            model.stop();
        };

    },
        []
    );

    return webhookEvents;
}
