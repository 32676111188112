import React, { Component } from 'react';
import firebase from 'firebase/app';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Space, PageHeader, Card, Button, Select } from 'antd';
import { Link } from 'react-router-dom';

import CenteredSpinner from '../../../components/CenteredSpinner';
import StrikeClientModel from '../../../models/StrikeClientModel';

const { Option } = Select;

export default class AccessInviteScreen extends Component {
  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    const isAdmin = !!props.isAdmin;
    this.clientModel = new StrikeClientModel({ viewModel: this, clientId, isAdmin });

    this.state = {
      clientNotFound: false,
      clientId,
      inviteId: props.match.params.inviteId,
      invitingClientId: props.match.params.invitingClientId,
      clientId: clientId,
      route: null,
      client: null,
      loading: true,
      inviteAccepted: false,
    };
  }

  async componentDidMount() {
    this.clientModel.waitForClientData();
  }

  componentWillUnmount() {
    if (this.clientModel) this.clientModel.stop();
  }

  componentDidUpdate() {
  }

  getInvite = async () => {
    const db = firebase.firestore();
    const inviteDoc = await db.collection('clients').doc(this.state.invitingClientId).collection("invited_users").doc(this.state.inviteId).get();
    const invite = inviteDoc ? inviteDoc.data() : null;

    if (invite && invite.email === this.state.client.email) {
      this.setState({ 
        invite: invite,
        loading: false,
        wrongEmail: false,
      });
    } else {
      this.setState({ 
        invite: invite,
        loading: false,
        wrongEmail: true,
      });
    }
  }

  foundClient = async (client) => {
    this.setState(
      (state) => {
        return {
          client,
          loading: false,
          loadingTags: true,
        }
      }
    );

    await this.getInvite();
  }

  clientNotFound = () => {
    this.setState(
      (state) => {
        return {
          loading: false,
          clientNotFound: true,
        }
      }
    );
  }

  acceptInvite = async () => {
    const clientId = this.state.clientId;
    const acceptClientDelegateInviteFunction = firebase.functions().httpsCallable('clientService-clientHandler-acceptClientDelegateInvite');


    const {
      displayName, avatar
    } = this.state.client || { };

    const payloadData = {
      inviteId: this.state.inviteId,
      invitingClientId: this.state.invitingClientId,
      displayName: displayName || "",
      avatar: avatar || null,
      clientId,
    };

    try {
      await acceptClientDelegateInviteFunction(payloadData);

      this.setState({
        route: `/client/manage/${clientId}`,
        inviteAccepted: true,
      });
    } catch (error){
      window.alert('Error: Invite not valid for your account');
    }
  }

  onClickConfirm = async() => {
    this.setState({
      loading: true
    });

    await this.acceptInvite();

    this.setState({
      loading: false
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <CenteredSpinner loading={this.state.loading} />
      );
    } else if (!this.state.loading && !this.state.invite) {
      return (
        <div>
          <PageHeader
            ghost={false}
            title={`Invite Not Found`}
          />

          <Link to={`/client/manage/${this.state.clientId}`}><Button>Back to dashboard</Button></Link>
        </div>
      );
    } else if (!this.state.loading && this.state.invite && this.state.wrongEmail) {
      return (
        <div>
          <PageHeader
            ghost={false}
            title={`This invite doesn't apply to your account`}
          />

          <Link to={`/client/manage/${this.state.clientId}`}><Button>Back to dashboard</Button></Link>
      </div>
      )
    } else {
      return (
        <div>
          <PageHeader
            ghost={false}
            title={`Invite to access account`}
          />

          <Card>
            { this.state.inviteAccepted
              ? (
                <>
                  <p>You've accepted {this.state.invite.clientDisplayName}'s invite and now have access to their client account.</p>

                  <Link to={`/client/manage/${this.state.invitingClientId}`}><Button disabled={this.state.loading}>View {this.state.invite.clientDisplayName}'s dashboard</Button></Link>
                  <br/>
                  <Link to={`/client/manage/${this.state.clientId}`}><Button>View dashboard</Button></Link>
                </>
              )
              :
                (
                  <>
                    <h5>{this.state.invite.clientDisplayName} has issued you delegated access to their Strike account</h5>
                    <h5>This will allow you to</h5>

                    <ul>
                      <li>- Manage &amp; edit tags</li>
                      <li>- Manage account settings</li>
                      <li>- and more</li>
                    </ul>

                    <br/>

                    <Space>
                      <Button type="primary" disabled={this.state.loading} onClick={this.onClickConfirm}>Accept</Button>
                      <Link to={`/client/manage/${this.state.clientId}`}><Button>Back to dashboard</Button></Link>
                    </Space>
                  </>
              )
            }
          </Card>
        </div>
      );
    }
  }
}

