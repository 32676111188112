import React, { useState } from 'react';
import { Button } from 'antd';
import { QrcodeOutlined } from '@ant-design/icons';

import { useClientTagForProduct } from '../../models/useClientTagForProduct';
import TrackerService from '../../utils/TrackerService';

import RedirectAndScrollTop from './RedirectAndScrollTop'; 

function LaunchQRPageButton({ clientId, product, path, title, ...rest }) {
  const [route, setRoute] = useState(null);
  const keyValue = `btn-qr-${clientId}-${product}`;
  const tag = useClientTagForProduct(clientId, product);
  const tagId = tag ? tag.id : null; 

  const trackClickedStatus = () => {
    const eventId = `client_clicked_${product}_qr`;
    const data = {
      clientId,
      tagId,
      product,
    };
    const isTest = window.location.hostname === 'localhost';

    const tracker = new TrackerService({
      isTest,
    });

    tracker.mixpanelTrack(eventId, { ...data });
    if (!isTest) { 
      tracker.googleTrack(eventId, { ...data });
    }
  }

  const onClickButton = () => {
    if (tag && tagId && path) {
      const fullPath = `/${path}/${tagId}`;
      trackClickedStatus();
      setRoute(fullPath);
    }
  };

  return (
    <>
      {tag && tagId && path &&
        <Button
          key={`${keyValue}-${tagId}`}
          icon={<QrcodeOutlined />}
          onClick={onClickButton}
          type={'primary'}
          style={{ textAlign: 'start', width: `100%` }}
        >
          {title}
        </Button>
      }
      {
        <RedirectAndScrollTop
          to={route}
          when={route}
        />
      }
    </>
  );
}

export default LaunchQRPageButton;
