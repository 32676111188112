import firebase from 'firebase/app';

const isLocalHost = window.location.hostname === 'localhost';

export default class StrikeTeamMembersModel {

    constructor({teamId, memberClientId, onUpdate, includeBlocked}) {
        this.teamId = teamId;
        this.teamDetails = null;
        this.teamMembersList = null;
        this.teamClient = null;
        this.memberClientId = memberClientId || null;
        this.memberClient = null;
        this.onUpdate = onUpdate;
        this.includeBlocked = includeBlocked === null ? true : includeBlocked;
        this.db = firebase.firestore();
    }

    stop() {
        // stop all listeners.,.not sure they get droppped when object drops out of scope.
        this.viewModel = null;
        if (this.observerTeamMembers) {
            this.observerTeamMembers();
            this.observerTeamMembers = null;
        }
    }

    waitForTeamMembers = async () => {
        if (this.teamId === null) return;

        this.stop();

        const queryTeam = this.db.collection('teams').doc(this.teamId);
        const prequery = queryTeam.collection('members').where('status', '==', 'approved');
        const query = this.includeBlocked ?  prequery : prequery.where('isDeleted', '==', false);

        this.observerTeamMembers = query.onSnapshot(querySnapshot => {
            let index = querySnapshot.size;
            this.teamMembersList = [];
            querySnapshot.forEach(
                doc => {
                    let teamMember = doc.data();
                    teamMember.isMemberClient = teamMember.clientId === this.memberClientId;
                    this.teamMembersList = [ ...this.teamMembersList, teamMember ];
                }
            );
            this.didFetchTeamMembers();
        }, err => {
            console.log(`Encountered a team members fetching error: ${err}`);
        });

    }

    async didFetchTeamMembers() {
        this.updateDataSource();
    }

    async updateDataSource() {
        if (this.onUpdate) {
            this.onUpdate({ 
                teamId: this.teamId,
                teamDetail: this.teamDetailData,
                memberClientId: this.memberClientId,
                members: this.teamMembersList 
            });
        }
    }


}
