import { useState, useEffect, useRef } from 'react';
import moment from 'moment';

import StrikeClientRecentActivitiesModel from './StrikeClientRecentActivitiesModel';

export function useTimerDate({ unit, interval }) {
  const startDateRaw = new Date();
  const shortDate = moment(startDateRaw).subtract(interval, unit).toDate();
  const [date, setDate] = useState(shortDate);
  const timer = useRef(null); // we can save timer in useRef and pass it to child

  const updateShortDate = () => {
    const startDateRaw = new Date();
    const shortDate = moment(startDateRaw).subtract( interval, unit).toDate();
    setDate(shortDate);
  }

  useEffect(() => {
    // useRef value stored in .current property
    timer.current = setInterval(
      () => updateShortDate(), 
      30 * 1000 // can be 1 minute
    );

    // clear on component unmount
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  return date;
}

export function useCreatingTaps(clientId, shortDate) {
    const [transactions, setTransactions] = useState(null);

    useEffect(() => {
        const callback = async (data) => {
            setTransactions(data);
        };

        const model = new StrikeClientRecentActivitiesModel({ 
          clientId, 
          isPendingOnly: true,
          onUpdate: callback
        });

        model.fetchIncompleteTaps({ shortDate, limit: 1 });
        
        return function cleanup() {
            model.stop();    
            setTransactions(null);
        };

    },
        [clientId, shortDate]
    );

    return transactions;
}
