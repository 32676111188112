import React from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import {
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Space, Row, Col, Switch, Typography } from 'antd';

const { Text } = Typography;

const noTippingBehavioursForCurrencies = ['USD'];
function shouldShowTippingBehaviour(currency) {
  return !noTippingBehavioursForCurrencies.includes(currency);
}

export const TeamTapBehaviourControl = ({ team, teamId, clientId, onChange, teamTapBehaviour, ...rest }) => {
    const teamTapBehaviours = {
        members: 'Tip Members only',
        team: 'Tip Team only',
        both: 'Tip Both Team & Members'
    };

    const { defaultCurrency } = team;

    const isMembersTapChecked = (teamTapBehaviour === 'members' || teamTapBehaviour === 'both');
    const isTeamTapChecked = (teamTapBehaviour === 'team' || teamTapBehaviour === 'both');

    const updateNewTeamBehavour = ({ newIsMembersTapChecked, newIsTeamTapChecked }) => {
        const newTeamTapBehavour = newIsMembersTapChecked && newIsTeamTapChecked ?
            'both'
            :
            (
                newIsMembersTapChecked ? 'members' : 'team'
            );
        onChange && onChange(newTeamTapBehavour);

    };

    const onClickMember = async () => {
        const newIsMembersTapChecked = !isMembersTapChecked;
        const newIsTeamTapChecked = newIsMembersTapChecked ? isTeamTapChecked : true;
        updateNewTeamBehavour({
            newIsMembersTapChecked,
            newIsTeamTapChecked,
        });
    };


    const onClickTeam = async () => {
        const newIsTeamTapChecked = !isTeamTapChecked;
        const newIsMembersTapChecked = newIsTeamTapChecked ? isMembersTapChecked : true;
        updateNewTeamBehavour({
            newIsMembersTapChecked,
            newIsTeamTapChecked,
        });
    };

    return shouldShowTippingBehaviour(defaultCurrency) && (
        <div style={{ marginLeft: '8px', marginTop: '10px', marginBottom: '20px' }}>
            <Row style={{ margin: '4px' }}>
                <Col xs={12} sm={12} md={6} lg={4} >
                    <Space direction='horizontal' size='small'>
                        <Switch
                            style={{ width: '100%', backgroundColor: isTeamTapChecked ? '#ff0098' : 'lightgrey'}}
                            checked={isTeamTapChecked}
                            checkedChildren={'Pooled'}
                            unCheckedChildren={'Pooled'}
                            onChange={onClickTeam} />
                    </Space>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} >
                    <Space direction='horizontal' size='small' style={{ width: '100%'}}>
                        <Switch
                            style={{ width: '100%', backgroundColor: isMembersTapChecked ? '#ff0098' : 'lightgrey'}}
                            checked={isMembersTapChecked}
                            checkedChildren={'Members'}
                            unCheckedChildren={'Members'}
                            onChange={onClickMember}
                            disabled={noTippingBehavioursForCurrencies.includes(defaultCurrency)} // MARK: USD needs to be adjusted
                        />
                    </Space>
                </Col>
                <Col xs={0} sm={0} md={12} lg={16} >
                </Col>
            </Row>
        </div>
    );

};
