import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { PageHeader } from 'antd';

import Spinner from '../../../components/Spinner';
import StrikeTeamDetailsModel from '../../../models/StrikeTeamDetailsModel';
import StrikeTeamTagsModel from '../../../models/StrikeTeamTagsModel';
import { StrikeTagsList } from '../../../components/client/TagsComponents';


export default class TeamDetailsScreen extends Component {

    // Properties used by this component:

    constructor(props) {
        super(props);

        const clientId = this.props.match.params.clientId;
        const teamId = this.props.match.params.teamId;
        const isAdmin = !!props.isAdmin;

        this.model = new StrikeTeamDetailsModel({viewModel: this, teamId});
        this.teamTagsModel = new StrikeTeamTagsModel({viewModel: this, teamId});

        this.state = {
            teamId: teamId,
            teamDetails: null,
            teamMembersList: null,
            loading: true,
            route: null
        };
    }

    componentDidMount() {
        this.model.waitForTeamDetails({fetchTeamClient: false});
        this.teamTagsModel.fetchAllTagsData();
    }

    componentWillUnmount() {
        if (this.model) this.model.stop();
        if (this.teamTagsModel) this.teamTagsModel.stop();
    }

    componentDidUpdate() {
    }

    isLoading = () => {
        return this.state.loading || false;
    }

    setTeamDetailsOnPage = (teamDetails, teamMembersList) => {
        this.setState({
            teamDetails,
            teamMembersList,
            loading: false
        });
    }

    updatedTagsDataSource = (tags) => {

        this.setState(
            (state) => {
                return {
                    tags,
                    loadingTags: false,
                }
            }
        );
    }

    changeMode = (mode) => {
        if (mode === 'viewing') {
            return 'editing'
        } else if (mode === 'editing') {
            return 'saving'
        } else {
            return 'viewing';
        }
    }


    onClickEdit = () => {
        this.setState((state) => {
                return {
                    mode: this.changeMode(state.mode)
                }
            }
        );
    }

    doneSaving = () => {

        this.setState((state) => {
                return {
                    mode: this.changeMode(state.mode)
                }
            }
        );

        this.model.waitForClientData();
    }

    internalSave = async (data) => {
        await this.model.commit(data);
        this.doneSaving();
    }


    onEditTeamTag = async (tag, updatedTagData ) => {
        const strikeTeamsTagModel = new StrikeTeamTagsModel({ viewModel: this, tagId: tag.id });
        return await strikeTeamsTagModel.updateTagWithData(updatedTagData);
    }


    render() {
        if (!this.state.loading && this.state.teamDetails) {
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`${this.state.teamDetails.teamName}`}
                        onBack={(() => window.history.back())}
                    />
                    <h2>{`${this.state.teamDetails.teamName}`} - Tags</h2>
                    <Spinner condition={() => { return this.isLoading() }} />
                    <StrikeTagsList
                        condition={() => { return !this.state.loadingTags }}
                        data={this.state.tags}
                        onEditTag={this.onEditTeamTag}
                        {...this.props}
                    />
                </div>
            );
        } else {
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`Team Tags`}
                        onBack={(() => window.history.back())}
                    />
                    <Spinner condition={() => { return this.isLoading() }} />
                </div>
            );
        }
    }
}

