import React, { Component } from 'react';
import '../../App.scss';

const howtoQR = {
    heading : "How to scan a QR code",

    steps: [
        "Open the Camera or QR Reader app from the Home screen, Control Center, or Lock screen.",
        "Select the rear facing camera. Hold your device so that the QR code appears in the viewfinder.", 
        "Your device recognizes the QR code and shows a notification.",
        "Tap the notification to open the link associated with the QR code.",
        "When using the QR Reader app, the page will load automatically.",
        ],

    bullets: 'numbers'
};


export default class QRHowToScanView extends Component {

  // Properties used by this component:
  // profileBio, displayName, avatar

  constructor(props) {
    super(props);
    
    this.state = {
        loading : true,
        error : false,
        heading: howtoQR.heading,
        steps: howtoQR.steps,
        bullets: howtoQR.bullets,
    };
  }

  componentDidMount() {
      this.setState({ loading: false });
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {


    const style_elHeaderText = {
        fontSize: 16,
        color: 'rgba(123, 123, 123, 1.00)',
        textAlign: 'left',
        paddingLeft: '1rem',
        paddingRight: '1rem',
    };

    const style_elStepText = {
        fontSize: 11,
        color: 'rgba(123, 123, 123, 1.00)',
        textAlign: 'left',
        paddingLeft: '1rem',
        paddingRight: '1rem',
    };

    const { style } = this.props;

    const { loading, error, heading, steps, bullets } = this.state;

    return !loading && !error && (
        <div className="QRHowToScanView">
            <div className="layoutFlow">
          
                <div className="systemFontBold" style={ style_elHeaderText }>
                    <div>{heading !== undefined ? heading : (<span className="propValueMissing">{''}</span>)}</div>
                </div>
          

                { 
                steps.map( (step, index) => (
                        (
                            <div key={index} className="systemFontRegular" style={ style_elStepText }>
                                <div>{`${(bullets === 'numbers' ? (index + 1): '' )}. ${step}`}</div>
                            </div>
                            
                        )
                ))
                } 

          </div>
        </div>        
    )
  }
  
}
