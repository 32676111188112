import React, { useState, useEffect, useContext } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { useParams } from 'react-router-dom';
import { PageHeader, Space } from 'antd';

import StrikePayoutModel from '../../../models/StrikePayoutModel';
import CenteredSpinner from '../../../components/CenteredSpinner';
import PayoutProgressListView from '../../../components/client/PayoutProgressListView';
import { usePayoutDetails } from '../../../models/usePayoutDetails';
import MoneyJarPayoutView from '../../../components/client/MoneyJarPayoutView';

const ClientPayoutScreen = ({
  locStrings,
  isAdmin,
}) => {
  const { clientId, payoutId } = useParams();
  const payout = usePayoutDetails(clientId, payoutId);
  const { displayName, status } = payout || {};

  const onClick = async () => {
    const payoutModel = new StrikePayoutModel();
    await payoutModel.startConnectingProviderIfNeeded(payout);
  };

  return <>
      {
        <PageHeader
          ghost={false}
          title={displayName || 'Payout Detail'}
          onBack={(() => window.history.back())}
        />
      } 
      <Space direction='vertical'>

      { payout &&
        <MoneyJarPayoutView 
          clientId={clientId}
          payoutId={payoutId}
          payout={payout}
          onClick={onClick}
        />
      }


      { payout ?
        <PayoutProgressListView 
          clientId={clientId}
          payoutId={payoutId}
        />
        :
        <CenteredSpinner />
      }
      </Space>
    </>

};


export default ClientPayoutScreen;

