import React, { Component } from 'react';
import '../../App.scss';

export default class FooterCard extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    const style_elTermAndConditionsLinkCopy = {
      fontSize: 10,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    return (
      <div className="FooterCard">
        <div className="layoutFlow">
          
          <div className="elTermAndConditionsLinkCopy">
            <div className="font-tahoma" style={style_elTermAndConditionsLinkCopy}>
              <div>
                <a className="terms-and-conditions" href="https://www.strikepay.co/terms-of-service" target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{__html: this.props.locStrings.terms_and_conditions}}></a>
                <a className="terms-and-conditions" href="https://www.strikepay.co/privacy-policy" target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{__html: this.props.locStrings.privacy_policy}}></a>
                <br/>
                © { new Date().getFullYear() } Strike Technology Ltd
              </div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
