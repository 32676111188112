import React, { useState, useEffect, useContext } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import {
  QrcodeOutlined,
  SettingOutlined,
  UserAddOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { Link, Redirect } from 'react-router-dom';
import {
  Button,
  Space,
  Card,
  Typography,
  Divider,
  Skeleton,
  Row, Col,
  Image,
  Alert,
  PageHeader,
  Table,
} from 'antd';
import SkeletonAvatar from 'antd/lib/skeleton/Avatar';
import SkeletonButton from 'antd/lib/skeleton/Button';
import SkeletonImage from 'antd/lib/skeleton/Image';
import moment from 'moment';

import CenteredSpinner from '../../../components/CenteredSpinner';
import MoneyTitle from '../../../components/customer/MoneyTitle';
import { BatchWithdrawalsLoadingCard as LoadingCard, BatchWithdrawalsGroupCard } from '../../../components/admin/AdminBatchedPayoutsComponents';
import { useBatchWithdrawals } from '../../../models/useBatchWithdrawals';
import { useAllLines } from '../../../models/useAllLines';
import BatchWithdrawalsModel from '../../../models/BatchWithdrawalsModel';
import StatusTableRowElement from '../../../components/admin/StatusTableRowelement';
import BatchStartButton from '../../../components/admin/BatchStartButton';
import { StatusSummaryTable } from '../../../components/admin/StatusSummaryTable';

const greenButton = { background: '#27AE60', color: 'white' };
const redButton = { color: '#bf2222' };


const AdminBatchedPayoutsScreen = ({
  locStrings,
  isAdmin,
}) => {
  const statuses = ['processing', 'upload_requested', 'uploaded', 'processed', 'paid', 'cancelled', 'failed'];
  const batchWithdrawals = useBatchWithdrawals();
  const lines = useAllLines({ });
  const loading = !batchWithdrawals;
  const truncatedLength = 4;

  const batches = {};

  const titles = {
    processing: 'Processing',
    processed: 'Ready to Pay Out',
    failed: 'Failed',
    paid: 'Already Paid',
    upload_requested: 'Upload Requested',
    uploaded: 'Uploaded',
    cancelled: 'Cancelled',
  };

  statuses.forEach((status) => {
    const items = batchWithdrawals && batchWithdrawals.filter((item) => item.status === status);
    batches[status] = { 
      items, 
      count: items && items.length > 0 ? items.length : 0, 
      title: titles[status] || `${status}`,
      status: `${status}`, 
    };
  });

  return <>
      <PageHeader
        ghost={false}
        title={'Batched Payouts'}
        onBack={(() => window.history.back())}
      />
      {<LoadingCard loading={loading} />}
      { !loading && 
        <>
          { <>
            <Space direction='horizontal'>
            { ['EUR', 'GBP', 'USD'].map((item) => (
                <Space direction='horizontal' key={`space-start-${item}`}>
                  <BatchStartButton key={`btn-start-${item}`} currency={item} />
                  <Divider type='vertical' />
                </Space>
              ))
            }
            </Space>
            <Divider direction='horizontal' />
            </>
          }
          { lines &&
            <StatusSummaryTable
              dataSource={lines}
              loading={loading}
              titles={titles} 
            />
          }     
          { !lines &&
            <LoadingCard loading={true} />
          }     
          {
            <StatusSummaryTable
              dataSource={batchWithdrawals}
              loading={loading}
              titles={titles} 
            />
          }
          { Object.keys(batches).map((key) => (
            <BatchWithdrawalsGroupCard 
              key={`bwgc-${key}`}
              title={batches[key].title} 
              elements={batches[key].items} 
              count={batches[key].count} 
              truncatedLength={truncatedLength}
              status={batches[key].status}
            />
            ))
          }
        </>
      }
  </>;
};

export default AdminBatchedPayoutsScreen;

