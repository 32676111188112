import React from 'react';

import { colorForBackgroundColor } from '../../utils/RGBColorToHex';

 function ContrastColor({ children, backgroundColor, ...rest }) {
   const textColor = colorForBackgroundColor(backgroundColor);

   return (
     <div style={{ color: textColor, backgroundColor }}>
       {children}
     </div>
   );
 }

 
 export default ContrastColor;