import React from 'react';
import {
    List,
    Typography,
} from 'antd';
import moment from 'moment';

const { Text } = Typography;

function getActivitiesByDay(activities, maxDays = 0) {
  const activitiesByDay = {};
  const daysOfActivity = [];
  activities && activities.map((activity, i) => {
      const activityAt = activity.activityAt;
      const dayOfActivity = activityAt && moment(activityAt.toMillis()).format("YYYYMMDD").toString();
      if (activitiesByDay[dayOfActivity] == null) {
          activitiesByDay[dayOfActivity] = [];
          daysOfActivity.push(dayOfActivity);
      }
      return activitiesByDay[dayOfActivity].push({ ...activity, updatedAt: activityAt });
  });

  const numOfDays = daysOfActivity.length;
  const spliceCount = (maxDays > 0 && numOfDays > maxDays) ? maxDays : numOfDays;

  return {
      daysOfActivity: daysOfActivity.sort((a, b) => b - a).splice(0, spliceCount),
      activitiesByDay
  };
}

export const DailyRecentActivityList = ({ listItemComponent, recentActivities, isAdmin, locStrings, maxDays, ...rest }) => {
  const { daysOfActivity, activitiesByDay } = getActivitiesByDay(recentActivities, (maxDays && maxDays > 0) ? maxDays : 32);

  return (
      <>
          { 
              <List
                  style={{ width: '100%' }}
                  loading={!recentActivities}
                  dataSource={daysOfActivity || []}
                  itemLayout={'vertical'}
                  renderItem={item => (
                      <ActivityDailyList
                          activities={activitiesByDay[item] || {}}
                          locStrings={locStrings}
                          isAdmin={isAdmin}
                          listItemComponent={listItemComponent}
                      />
                  )}
              />
          }
      </>
  );

};

const ActivityDailyList = ({ listItemComponent: ActivityListItem, activities, isAdmin, locStrings, ...rest }) => {
  const firstActivity = activities[0];
  const dayOfActivity = (firstActivity && firstActivity.activityAt && moment(firstActivity.activityAt.toMillis()).format('LL')) || 'No Date';

  return (
      <List.Item  >
          <List
              style={{ width: '100%' }}
              header={<Text strong>{dayOfActivity}</Text>}
              dataSource={activities}
              itemLayout='horizontal'
              size='default'
              split={false}
              renderItem={item => (
                  <>
                      <ActivityListItem
                          item={item}
                          {...item}
                          locStrings={locStrings}
                          isAdmin={isAdmin}
                      />
                  </>
              )}
          />
      </List.Item>
  );
};
