import firebase from 'firebase/app';

export default class StrikePayoutModel {

  async startConnectingProviderIfNeeded(payout) {
    const db = firebase.firestore();

    const { status, clientId, uid } = payout || { };
    const entryStatus = 'validated';
    const newStatus = 'connecting';

    console.log('startConnectingProviderIfNeeded', status, clientId, uid);

    if (status && clientId && uid && status === entryStatus) {
      const ref = db
        .collection('clients')
        .doc(clientId)
        .collection('payouts')
        .doc(uid);

      await ref.update({
        status: newStatus,
        connectingAt: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now(),
      });
    }    

  }


}
