import { useState, useEffect } from 'react';

import StrikeTeamMembersModel from '../../models/StrikeTeamMembersModel';


export function useTeamMembers(teamId, teamDetails, clientId, includeBlocked) {
    const [teamMembers, setTeamMembers] = useState(null);

    useEffect(
        () => {
            const onUpdateTeamMembers = async ({                 
                teamId,
                teamDetail,
                memberClientId,
                members  
            }) => {
                setTeamMembers(members);
            };
        
            const model = new StrikeTeamMembersModel({ 
                teamId: teamId,
                memberClientId: clientId,
                onUpdate: onUpdateTeamMembers,
                includeBlocked: true,
            });
        
            model.waitForTeamMembers();

            return function cleanup() {
                model.stop();
            };
        },
        []
    );

    return teamMembers;
}