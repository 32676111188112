import { useState, useEffect } from 'react';

function getSessionStorageOrDefault(key, defaultValue, allowedValues) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  const pStored = JSON.parse(stored);
  if (!allowedValues.includes(pStored)) {
    return defaultValue;
  }
  return pStored;
}

export function useSessionStorage(key, defaultValue, allowedValues) {
  const [value, setValue] = useState(
    getSessionStorageOrDefault(key, defaultValue, allowedValues)
  );

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

function getLocalStorageOrDefault(key, defaultValue, allowedValues) {
  const stored = localStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  const pStored = JSON.parse(stored);
  if (!allowedValues.includes(pStored)) {
    return defaultValue;
  }
  return pStored;
}

export function useLocalStorage(key, defaultValue, allowedValues) {
  const [value, setValue] = useState(
    getLocalStorageOrDefault(key, defaultValue, allowedValues)
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

