import { useState, useEffect } from 'react';

import StrikeLinesGroupModel from './StrikeLinesGroupModel';

export function useAllLines({ payoutId, withdrawalId, batchId, clientId, limit, statuses }) {
    const [lines, setLines] = useState(null);

    useEffect(() => {
        const onUpdate = async (list) => {
            setLines(list);
        };

        const model = new StrikeLinesGroupModel({
            clientId,
            payoutId,
            withdrawalId,
            batchId,
            limit,
            statuses,
            onUpdate,
        });

        model.start();

        return function cleanup() {
            model.stop();
        };

    },
        [payoutId, withdrawalId, batchId, clientId, limit, statuses]
    );

    return lines;
}
