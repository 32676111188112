import React, { useState } from 'react';
import firebase from 'firebase/app';
import locale from 'antd/lib/locale/en_GB';
import {
  DatePicker,
  Form,
  Input,
  Tooltip,
  Select,
  Checkbox,
  Button,
  Divider,
  Typography,
  Space,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import { FormItemLayout, TailFormItemLayout } from '../../utils/Constants';

import { ClientCategoryFormItem, categoriesEN } from './ClientCategoryFormItem';

const { Option } = Select;
const { Text } = Typography;

const countryByCountryCode = {
  'US' : 'United States',
  'IE' : 'Ireland',
  'GB' : 'United Kingdom',
};

const phonePlaceHolderByCountryCode = {
  'IE' : '+353881234567',
  'GB' : '+441234567890',
  'US' : '6505551234',
};

const availableCountryCodes = ['US', 'GB', 'IE'];
const countriesSupportingBusiness = ['IE', 'GB'];
const countriesNotSupportingBusiness = ['US'];
const countriesRequiringSSN = ['US'];

export const AdminCheckBoxFormItems = ({ isAdmin, title, isViewOnly, client }) => {
  const showCheckBoxes = isAdmin && !isViewOnly;
  const showListOfItems = isAdmin && !!isViewOnly;

  const adminCheckBoxes = [
    {
      key: 'isTransferToTeamAllowed',
      label: 'Allow balance transfer to teams',
      unsetLabel: 'No transfer to teams',
      rules: [{ required: false, }]
    },
    {
      key: 'shouldNotPauseTags',
      label: 'Should not pause tags',
      unsetLabel: 'May pause tags',
      rules: [{ required: false, }]
    },
    {
      key: 'isNewActivity',
      label: 'Use new recent history layout (temp)',
      unsetLabel: 'Use old recent history layout (temp)',
      rules: [{ required: false, }]
    },
    {
      key: 'isDeveloper',
      label: 'Is a developer',
      unsetLabel: 'Is not a developer',
      rules: [{ required: false, }]
    },
    {
      key: 'isCompany',
      label: 'Is a company',
      unsetLabel: 'Is an individual',
      rules: [{ required: false, }]
    },
    {
      key: 'isShowAds',
      label: 'Display banner ads',
      unsetLabel: 'Disable banner ads',
      rules: [{ required: false, }]
    },
  ];

  return <>
    {isAdmin && (
      <>
        <Divider>{title}</Divider>

        {showCheckBoxes &&
          adminCheckBoxes.map((v) => (
            <Form.Item
              key={`fi-admin-${v.key}`}
              name={v.key}
              valuePropName="checked"
              rules={v.rules}
              {...TailFormItemLayout}
            >
              <Checkbox>
                {v.label}
              </Checkbox>
            </Form.Item>
          ))
        }

        {showListOfItems && client &&
          <Space direction='vertical'>
            {adminCheckBoxes.map((v) => (
              <Text key={`si-admin-${v.key}`} type={(client[v.key]) ? 'success' : 'secondary'}>
                {(client[v.key]) ? v.label : v.unsetLabel}
              </Text>
            ))}
          </Space>
        }

        <Divider />
      </>)}
  </>;

}

function notRequiredWhenAdmin({ isAdmin, required }) {
  return (isAdmin) ? false : required;
}

export const EditClientProfileForm = ({ callback, client, isSignUp, isAdmin, ...rest }) => {
  const [form] = Form.useForm();
  const isShowAllFields = (!!isSignUp) ? false : true;
  const { isCompany } = client || { isCompany: false };
  const [isCompanyChecked, setIsCompanyChecked] = useState(isCompany);
  const [selectedCountry, setSelectedCountry] = useState(client && client.country ? client.country : null);


  const onFinish = (values) => {
    if (values.categoryCode) values['categoryName'] = categoriesEN[values.categoryCode];

    if (values.dob) {
      const dobMoment = values.dob;
      values.dob = firebase.firestore.Timestamp.fromDate(new Date(dobMoment.valueOf()));
    }

    if (callback) {
      callback(values);
    }
  };

  const onSelectCountry = (value) => {
    setSelectedCountry(value);
  };

  const placeHolderPhone = () => {
    if (!selectedCountry) return phonePlaceHolderByCountryCode['US'];
    return phonePlaceHolderByCountryCode[selectedCountry];
  }

  return (
    <Form
      {...FormItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={client}
      scrollToFirstError
    >

      <>
        <Form.Item
          name={'country'}
          rules={[{ required: true, message: 'Country is required' }]}
          label={
            <span>
              Your Country&nbsp;
              <Tooltip title="Please enter a country">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
        >
          <Select
            placeholder="Country of residence"
            onSelect={onSelectCountry}
          >
            {
              availableCountryCodes.map(
                (code) => (
                  <Option key={code} value={code}>{countryByCountryCode[code]}</Option>
                )
              )
            }
          </Select>
        </Form.Item>
      </>

      {false && countriesNotSupportingBusiness.includes(selectedCountry) &&
        <Form.Item
          name="isCompany"
          valuePropName="checked"
          rules={[
            {
              required: false,
            },
          ]}
          {...TailFormItemLayout}
        >
          <Checkbox onChange={(v) => (setIsCompanyChecked(!isCompanyChecked))}>
            Register as Business
          </Checkbox>
        </Form.Item>
      }

      {true &&
        <ClientCategoryFormItem fieldName='categoryCode' category={client.categoryCode} isCompany={isCompanyChecked} />
      }

      {!isCompanyChecked &&
        <Form.Item
          name="displayName"
          label={
            <span>
              Full Name&nbsp;
              <Tooltip title="Please enter your full name.">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: notRequiredWhenAdmin({ isAdmin, required: true }),
              message: 'Use your full name. This will display as first name and first initial!',
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder="Full given name"
          />
        </Form.Item>
      }

      {isCompanyChecked &&
        <Form.Item
          name="displayName"
          label={
            <span>
              Full Company Name&nbsp;
              <Tooltip title="Please enter your business name.">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: notRequiredWhenAdmin({ isAdmin, required: true }),
              message: 'Use your full business name.',
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder="ACME Ltd."
          />
        </Form.Item>
      }

      {isShowAllFields &&
        <Form.Item
          name="profileBio"
          label={
            <span>
              Your Tag Line&nbsp;
              <Tooltip title="What's your tag line?">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: false,
              message: 'Please input a short tag line!',
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder="Best of the best"
          />
        </Form.Item>
      }


      {true &&
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[
            {
              required: notRequiredWhenAdmin({ isAdmin, required: true }),
              message: 'We use your phone number to help recover your account',
            },
          ]}
        >
          <Input
            placeholder={placeHolderPhone()}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
      }

      {true &&
        <Form.Item
          name="dob"
          label={isCompanyChecked ? 'Founded on' : 'Date of Birth'}
          rules={[
            {
              required: notRequiredWhenAdmin({ isAdmin, required: true }),
              message: 'We use your birthday to keep your account safe',
            },
          ]}
        >
          {/* Default picker to Jan 1 1990 */}
          <DatePicker allowClear={false} defaultPickerValue={moment(631152000000)} format={"DD/MM/YYYY"} />
        </Form.Item>
      }

      {isShowAllFields &&
        <Form.Item
          name="employerName"
          label={isCompanyChecked ? 'Trading as' : 'Employer Name'}
          rules={[
            {
              required: false,
              message: 'We need to know where you work',
            },
          ]}
        >
          <Input
            placeholder={isCompanyChecked ? 'Trading as name' : 'Tell us where you work'}
          />
        </Form.Item>
      }

      {isShowAllFields &&
        <Form.Item
          name="email"
          label={
            <span>
              Notifications Email&nbsp;
              <Tooltip title="Add an additional email for notifications. You cannot change your email used to login.">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: false,
              message: 'Please input your notification E-mail address!',
            },
          ]}
        >
          <Input
            placeholder="Notification email address"
          />
        </Form.Item>
      }

    { countriesRequiringSSN.includes(selectedCountry) &&
        <Form.Item
          name="idLast4"
          label={
            <span>
              Last four digits of your SSN&nbsp;
              <Tooltip title="Only provide the last four digits of your SSN">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: true,
              message: "Must be the last four digits of your SSN",
              whitespace: true,
              pattern: new RegExp(/^\d{4}$/),
            },
          ]}
        >
          <Input />
        </Form.Item>
      }


      <Form.Item
        name="agreeTerms"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              (value || isAdmin) ? Promise.resolve() : Promise.reject('Please accept terms'),
          },
        ]}
        {...TailFormItemLayout}
      >
        <Checkbox>
          I accept the <a href="https://www.strikepay.co/eula" target="_blank">terms.</a>
        </Checkbox>
      </Form.Item>

      <Form.Item
        name="marketingOptIn"
        valuePropName="checked"
        rules={[
          {
            required: false,
          },
        ]}
        {...TailFormItemLayout}
      >
        <Checkbox>
          Allow Strikepay to send important communications.
        </Checkbox>
      </Form.Item>

      <AdminCheckBoxFormItems isAdmin={isAdmin} title="Admin Only Settings" />

      <Form.Item {...TailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};