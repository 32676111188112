import React, { useEffect, useState } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import { DollarCircleFilled, EuroCircleFilled, PoundCircleFilled, PauseCircleOutlined, PlayCircleOutlined , CheckCircleTwoTone,ExclamationCircleTwoTone } from '@ant-design/icons';
import { Avatar, Collapse, List, Button, Input, Table, Tooltip, Tag } from 'antd';
import moment from 'moment';
import firebase from 'firebase/app';

import { capitalize } from '../../utils/ProductSwitcher';
import {ccyIsoCodeToSymbolMap, countryFlags } from '../../utils/CurrencyUtils';
const isLocalHost = window.location.hostname === 'localhost';
const URLPath = isLocalHost ? `http://${window.location.hostname}:3000` : "https://app.strikepay.co";
const { Search } = Input;
const { Panel } = Collapse;

const ccy = ccyIsoCodeToSymbolMap;

const locale = {
  'IE': 'Ireland',
  'GB': 'English',
  'US': 'US',
};

const ccy_icons = {
  'EUR': <EuroCircleFilled style={{ fontSize: '30px', color: '#00c' }} />,
  'USD': <DollarCircleFilled style={{ fontSize: '30px', color: '#08c' }} />,
  'GBP': <PoundCircleFilled style={{ fontSize: '30px', color: '#70c' }} />,
};

function iconForCCY(ccy) {
  let icon = ccy_icons[ccy];
  return (icon) ? icon : <DollarCircleFilled style={{ fontSize: '30px', color: '#08c' }} />;
}

export const AdminClientsListItemMetaDescription = ({ client, locStrings }) => {
  const localeText = locale[client.locale] || client.locale;
  const clientLocale = localeText ? (<Tag>{`${(localeText)}`}</Tag>) : null;
  const phoneNumber = client.phoneNumber || "None";
  const employerName = client.employerName ? (<p>Employer Name: {client.employerName}</p>) : null;
  const profileBio = client.profileBio ? (<p>{client.profileBio}</p>) : null;

  return (
          <div>
              {profileBio}
              <p>{phoneNumber}</p>
              {employerName}
              {clientLocale}
          </div>
      );

};

export const AdminClientsListItemMetaTitle = ({ client, locStrings }) => {
  const clientPath = `${URLPath}/admin/clients/${client.id}`;
  const clientLink = (<a href={clientPath}>{client.displayName}</a>);
  const clientEmailVerifiedColor = !!client.emailVerified ? "#52c41a" : "#ff0000";
  const checkIcon = <CheckCircleTwoTone twoToneColor={clientEmailVerifiedColor} />;
  const exclaimIcon = <ExclamationCircleTwoTone twoToneColor={clientEmailVerifiedColor} />;
  const emailVerfiedIcon = !!client.emailVerified ? checkIcon : exclaimIcon;
  const clientCountry = client.country ? countryFlags[client.country] : null;

  return (
          <div>
              <p>
                <span>
                  {`${clientCountry || ''}`}
                  { clientLink }
                  {` `}
                  { emailVerfiedIcon }
                </span>
                {`  ${client.email || ''} `}
              </p>
          </div>
      );

};


const AdminClientsListItem = ({ client, locStrings, content: Content }) => {
  const clientStatus = `${capitalize(client.status || "unknown")}`;
  const clientStatusColor = client.status && client.status === 'live' ? "success" : "warning";
  const clientCreatedAt = client.createdAt;

  let actions = [];

  if(clientCreatedAt) {
    actions = [ 
      `Since ${moment(clientCreatedAt.toMillis()).format("MMM DD, YYYY").toString()}`
    ];
  }

  actions = [ 
    ...actions,
    <Tag color={clientStatusColor}>{clientStatus}</Tag>
  ];

  const handleTeamsAccessChange = async() =>{
      const db = firebase.firestore();

      await db.collection('clients').doc(client.id).update({
          teamsAllowed: buttonText === 'Allow Teams'
      })
  }

  let buttonText = 'Remove Teams';
  let icon = <PauseCircleOutlined/>;
  let tooltipText = 'This client will lose Teams access';

  if (client.hasOwnProperty('teamsAllowed')) {
    if (!client.teamsAllowed) {
      buttonText = 'Allow Teams';
      icon = <PlayCircleOutlined/>;
      tooltipText = 'This client will get Teams access';
    }
  }

    return (
        <List.Item
          key={client.id}
          actions={[...actions, <Tooltip title={tooltipText}><Button key="1" icon={icon} onClick={() => handleTeamsAccessChange()} type="secondary" danger={buttonText === 'Remove Teams'}>
              {buttonText}
          </Button> </Tooltip>]}
        >
          <List.Item.Meta
            avatar={<Avatar src={client.avatar} size='large'/>}
            title={<AdminClientsListItemMetaTitle client={client} locStrings={locStrings}/>}
            description={<AdminClientsListItemMetaDescription client={client} locStrings={locStrings}/>}
          />
          { Content && (<Content client={client} locStrings={locStrings} />)}
        </List.Item>
      );

};

export const IDVTable = ({ client }) => {
  let clientIdvStatusChanges = [];
  client && client.recentStatusHistory && client.recentStatusHistory.map((doc) => {
    const updatedByDisplayName = doc.updatedBy && doc.updatedBy.displayName ? doc.updatedBy.displayName : null;
    const updatedByEmail = doc.updatedBy && doc.updatedBy.email ? doc.updatedBy.email : null;
    const updatedByFallback = doc.updatedBy && typeof doc.updatedBy === 'string' ? doc.updatedBy : '-';
    const updatedBy = updatedByDisplayName || updatedByEmail || updatedByFallback;
    
    const historyEntry = {
      createdAt: doc.createdAt,
      statusChange: `${doc.previousStatus} → ${doc.newStatus}`,
      updatedBy,
      remarks: doc.remarks,
    }
    clientIdvStatusChanges.push(historyEntry);
  })

  const columns = [
    {
      title: 'Status Change',
      dataIndex: 'statusChange',
      key: 'statusChange',
      align: 'center',
      render:
        (statusChange) => (
          <p>
            {statusChange}
          </p>
        )
    },
    {
      title: 'Updated At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render:
        (createdAt) => (
          <p key={createdAt}>
            {moment(createdAt.toMillis()).format('LLL')}
          </p>
        )
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      align: 'center',
      render:
        (updatedBy) => (
          <p key={updatedBy}>
            {updatedBy && updatedBy.displayName ? updatedBy.displayName : updatedBy}
          </p>
        )
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      align: 'center',
      render:
        (remarks) => (
          <p key={remarks}>
            {remarks ? remarks : ''}
          </p>
        )
    },
  ];

  return (
    <Collapse defaultActiveKey={['0']}>
      <Panel header="idVerificationStatus changes" key="1">
        {!clientIdvStatusChanges.length ?
          (<div>
            <p> <i>No idVerificationStatus change history</i> </p>
            {client.idVerificationStatus ?
              <p>Current Status: <b>{client.idVerificationStatus}</b> </p> :
              <p>Current Status: <b>No Status</b> </p>
            }
          </div>)
          :
          <>
            <p>Current Status: <b>{client.idVerificationStatus}</b> </p>
            <Table
              align='center'
              columns={columns}
              dataSource={clientIdvStatusChanges}
              size="small"
              pagination={{ hideOnSinglePage: true}}
              rowKey="Id"
            />
          </>
        }
      </Panel>
    </Collapse>
  );
}

const AdminClientsListItemDetail = ({ client, locStrings }) => {
  return (
          <div>
              <p>{`Client data is private -- do not share`}</p>
          </div>
      );

};

export const ClientsSearch = ({ initialValue, callbackOnSearch, disabled, loading, locStrings }) => {

  const onSearch = (value) => {
    return callbackOnSearch ? callbackOnSearch(value) : null;
  };

  return (
    <Search 
      placeholder="input search text" 
      enterButton="Search" 
      size="large" 
      loading={!!loading}
      allowClear
      onSearch={onSearch}
      disabled={disabled}
    />
  );

};



export const AdminClientsList = ({ condition, locStrings, clients, ...rest }) => {

  return (
    <List
      itemLayout="vertical"
      size='large'
      pagination={true}
      style={{ textAlign: 'start', margin: '2px', padding: '2px' }}
      loading={!condition()}
      dataSource={clients}
      renderItem={client => (
        <AdminClientsListItem 
          client={client} 
          locStrings={locStrings} 
          content={AdminClientsListItemDetail} 
        />
      )}
    />
  );

};
