import React, { Component } from 'react';
import { Col, Row } from 'antd';

import ProductSwitcher from '../../utils/ProductSwitcher';
import { getMinimumCharge } from '../../utils/PaymentUtils';
import { ccyIsoCodeToSymbolMap } from '../../utils/CurrencyUtils';

import CustomAmountNumberField from './CustomAmountNumberField';
import MoneyTitle from './MoneyTitle';
import ValueSectionOption from './ValueSectionOption';

import '../../App.scss';

export default class AmountSelectionCard extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);

    this.state = {
      selectedCustom: false,
      // worth improving low contrast detection in-future to apply this for more than just exclusively white bg
      lowContrastBackground: props.rgbColor ? (props.rgbColor === "255, 255, 255" || props.rgbColor === "255,255,255" ? true : false) : false,
      displayValues: this.props.values || [
        100, 200, 500, 1000
      ],
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }


  selectorSelectionCustom = (ev) => {
    this.setState({ selectedCustom: true });
  }

  handleOtherAmountClick = () => {
    const totalValues = this.state.displayValues.length;
    this.props.handler(totalValues, this.state.displayValues[totalValues - 1]);
  }

  render() {
    const {
      paymentDetails,
      subtitles,
      rgbColor,
      product,
   } = this.props;

    const {
      currency,
    } = paymentDetails || {
      currency: 'EUR',
      paymentMethodIndex: 0,
      paymentMethods: ['Pays', 'Card'],
      shouldAddOnFees: true,
    };

    const minimumAmount = getMinimumCharge(currency);
    const minimumAmountString = (minimumAmount / 100).toFixed(2);
    const displayAmount = `${ccyIsoCodeToSymbolMap[currency]}${minimumAmountString}`;

    const selectedValueByIndex = this.props.selectedValueByIndex || 0;
    const displaySubtitles = subtitles || [];

    const totalValues = this.state.displayValues.length;
    let columnSpan = 6;

    if (totalValues === 4) {
      columnSpan = 6;
    } else if (totalValues === 3) {
      columnSpan = 8;
    } else if (totalValues === 2) {
      columnSpan = 12;
    } else if (totalValues === 1) {
      columnSpan = 24;
    }

    const selectedIndex = (() => {
      let val = this.props.selectedValueByIndex;
      if (val !== undefined) {
        val = parseInt(val, 10);
        if (!isNaN(val)) {
          return val;
        }
      }

      return isNaN(this.props.selectedValueByIndex) ? (selectedValueByIndex || 0) : this.props.selectedValueByIndex;
    })();

    const selectedAmount = (() => {
      let val = (this.props.selectedAmount ? this.props.selectedAmount : '');
      if (val !== undefined) {
        val = parseInt(val, 10);
        if (!isNaN(val)) {
          return val;
        }
      }
      return isNaN(this.props.selectedAmount) ? (0) : this.props.selectedAmount;
    })();

    const strikePrimaryColor = '255, 0, 152';
    let borderRGBColor = rgbColor ? rgbColor : strikePrimaryColor;

    const borderColor = `rgba(${borderRGBColor}, 1)`;
    const backgroundColor =  `rgba(${borderRGBColor}, 0.14)`;

    const style_elSelector = {
      height: 'auto',  // This element is in scroll flow
      width: '95%',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      border: `2px solid ${borderColor}`,
      borderRadius: '4px',
      backgroundColor: backgroundColor,
      padding: '10px',
    };
    // eslint-disable-next-line no-unused-vars
    const style_elSelector_part = {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      pointerEvents: 'auto',
    };

    const style_elSelector_part_custom = {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      pointerEvents: 'auto',
    };

    const productSwitcher = new ProductSwitcher({ locStrings: this.props.locStrings });
    const { precision, minValue, maxValue }  = productSwitcher.getProductMap(product);

    return (
      <div className="AmountSelectionCard">
        <div className="layoutFlow">
          <div className="hasNestedComps elSelector">
            <div style={style_elSelector}>
              <Row>
                {this.state.displayValues.map((amount, index) => (
                  (<Col span={columnSpan} key={index} style={style_elSelector_part} onClick={this.props.handler.bind(this, index, amount)}>
                    <ValueSectionOption
                      lowContrastBackground={this.state.lowContrastBackground}
                      visualStateIndex={selectedIndex === index ? 1 : 0}
                      title={<MoneyTitle amount={amount} currency={currency} />}
                      subtitle={(displaySubtitles[index] || '')}
                      appActions={this.props.appActions}
                      deviceInfo={this.props.deviceInfo}
                      locStrings={this.props.locStrings}
                    />
                  </Col>)
                ))
                }
              </Row>

              <Row style={{ marginTop: "10px" }}>
                {this.props.custom && (selectedIndex !== totalValues) &&
                  (<Col key={totalValues} span={24} style={style_elSelector_part_custom} onClick={ this.handleOtherAmountClick }>
                    <ValueSectionOption
                      lowContrastBackground={this.state.lowContrastBackground}
                      visualStateIndex={selectedIndex === totalValues ? 1 : 0}
                      title={'Other amount'}
                      appActions={this.props.appActions}
                      deviceInfo={this.props.deviceInfo}
                      locStrings={this.props.locStrings}
                    />
                  </Col>)
                }

                {(this.props.custom && (selectedIndex === totalValues)) &&
                  (<Col key={(totalValues + 1)} span={24}>
                    <CustomAmountNumberField
                      invisible={!(this.props.custom && (selectedIndex === totalValues))}
                      onValueChange={this.props.handler}
                      defaultValue={selectedAmount <= 0 ? this.state.displayValues[totalValues - 1] : selectedAmount}
                      value={selectedAmount}
                      index={totalValues}
                      placeholder={'Enter value'}
                      appActions={this.props.appActions}
                      deviceInfo={this.props.deviceInfo}
                      locStrings={this.props.locStrings}
                      currency={currency}
                      precision={precision}
                      minValue={minValue}
                      maxValue={maxValue}
                    />
                  </Col>)
                }
              </Row>

            </div>

            { this.props.minimumAmountError && (
              <div style={{ margin: "10px 0", color: 'red' }}>
                <p>Minimum required amount is { displayAmount }</p>
              </div>
            )}
          </div>
        </div>

      </div>
    )
  }

}