import React, { useState, useEffect } from 'react';
import {
    CheckCircleFilled,
    PlusCircleFilled,
    MinusCircleFilled,
} from '@ant-design/icons';
import {
    List,
    Tag,
    Space,
    Row,
    Col,
} from 'antd';

import { ActivityItemAdminButton } from '../admin/ActivityItemAdminButton';
import { useClientAvailableTransactions } from '../../models/useClientAvailableTransactions';
import { capitalize } from '../../utils/ProductSwitcher';
import { useSessionStorage, useLocalStorage } from '../../models/useSessionStorage';

import { ActivityDescriptionView, MoneyStatus, TapMoneyView, AvatarBadge } from './TapsComponents';
import { DailyRecentActivityList } from './DailyRecentActivityList';
import { useTeamRecentActivities } from './useTeamRecentActivities';
import DateRangeFilterSelector, { dateRangesForFilter, defaultDateRangeIndex, defaultDateRangeForTeamsIndex } from './DateRangeFilterSelector';
import { isLocalHost } from './AccountProfileComponents';

export const TeamProfileRecentActivityList = ({ teamId, isAdmin, clientId, team, displayPermissions, locStrings, ...rest }) => {
    const admKey = isAdmin ? 'a' : 'u';
    const stortageKey = `${admKey}_${teamId}_index`;
    const allowedValues = Object.keys(dateRangesForFilter);
    const [index, setIndex] = useSessionStorage(stortageKey, defaultDateRangeForTeamsIndex, allowedValues);
    const dateRange = dateRangesForFilter[index];
    const recentActivities = useTeamRecentActivities(teamId, clientId, displayPermissions, dateRange);

    const onSelectRange = (newIndex) => {
        setIndex(newIndex);
    }

    return (<>
        <DateRangeFilterSelector
            selectedIndex={index}
            onSelectRange={onSelectRange}
            disbaled={!recentActivities}
        />
        <DailyRecentActivityList
            recentActivities={recentActivities}
            isAdmin={isAdmin}
            locStrings={locStrings}
            listItemComponent={TeamActivityListItem}
        />
    </>);

};

export const ClientRecentAvailableActivityList = ({ isAdmin, clientId, locStrings, ...rest }) => {
    const admKey = isAdmin ? 'a' : 'u';
    const stortageKey = `${admKey}_${clientId}_index`;
    const allowedValues = Object.keys(dateRangesForFilter);
    const [index, setIndex] = useSessionStorage(stortageKey, defaultDateRangeIndex, allowedValues);
    const dateRange = dateRangesForFilter[index];
    const recentActivities = useClientAvailableTransactions(clientId, dateRange);

    const onSelectRange = (newIndex) => {
        setIndex(newIndex);
    }

    return (<>
        <DateRangeFilterSelector 
            selectedIndex={index}
            onSelectRange={onSelectRange}
            disbaled={!recentActivities}
        />
        <DailyRecentActivityList
            recentActivities={recentActivities}
            isAdmin={isAdmin}
            locStrings={locStrings}
            listItemComponent={ClientActivityListItem}
        />
    </>);

};

const ActivityItemTag = ({ item }) => {
    const { link } = item;

    const statusTag = ({ item }) => { 
        const { direction, status, activityType } = item;

        return <>
        {status === 'refunded' ? 
            <Tag color={'red'}>{capitalize(status)}</Tag> :
            <Tag color={direction === 'in' ? 'green' : 'red'}>{activityType}</Tag>
        }
        </>;
    };

    return <ConditonalLink link={link} component={statusTag} item={item} />;
}

const ConditonalLink = ({ link, component: Component, ...rest }) => {
    return <>
        { (link && link !== 'N/A') ?
            <a href={link}>
                <Component { ...rest }/>
            </a> :
            <>
                <Component { ...rest } />
            </>
        }
    </>;
};

const TeamActivityListItem = ({
    id, isAdmin, item, activityType, direction, clientType, title, avatar, amount, currency, status, activityAt, locStrings,
    fee, strikeFee, source, tagUid, templateTagId, ...rest
}) => {
    const titleText = ({ title }) => {
        return <>{title}</>;
    };

    // MARK: Move all link paths to a global
    let path = isLocalHost ? `http://localhost:8088` : `https://pay.strikepay.co`;
    path = `${path}/tags`;
    const link = `${path}/${item.tagId}/${item.tapId}`;

    const titleLine = <>
        <ConditonalLink
            link={link}
            component={titleText}
            title={title}
        />
    </>;

    return (
        <List.Item key={`ra-li-ti-${id}`} style={{ width: '100%', marginTop: '4px' }}>
            <List.Item.Meta
                avatar={
                    <AvatarBadge
                        avatar={avatar}
                        direction={direction}
                        isTeam={(clientType === 'team')}
                    />
                }
                title={titleLine}
                description={<>
                    <ActivityDescriptionView
                        activityAt={activityAt} clientType={clientType} activityType={activityType} direction={direction}
                        status={status} source={source} templateTagId={templateTagId} tagUid={tagUid}
                    />
                    {isAdmin && item.status !== 'sent' &&
                        <ActivityItemAdminButton
                            isAdmin={isAdmin}
                            item={item}
                        />
                    }
                </>}
            />
            <Space direction='horizontal' align='center' size={0}>
                <TapMoneyView
                    amount={amount} strikeFee={strikeFee} fee={fee} currency={currency} status={status}
                />
            </Space>
        </List.Item>
    );
};


const MoneyCircleFilled = ({ amount, isTotal }) => {
    const colorTotal = amount > 0 ? 'green' : '#aa3333';
    const plusCircle =  <PlusCircleFilled style={{ color: 'green' }} />;
    const minusCircle = <MinusCircleFilled style={{ color: '#aa3333' }} />;
    const checkCircleFilled = isTotal ? <CheckCircleFilled style={{ color: colorTotal }} /> : null;

    let moneyCircle = amount > 0 ? plusCircle : minusCircle;
    moneyCircle = checkCircleFilled || moneyCircle;
    return moneyCircle;
}

const MoneyStatusRow = ({ item }) => {
    const { id, status, amount, currency, isTotal } = item;
    const isFilled = ['sent', 'available', 'refunded', 'paid'].includes(status);
    const isStrikeThru = status === 'refunded';
    const colorTotal = amount > 0 ? 'green' : '#aa3333';
    const checkCirleFilled = <CheckCircleFilled style={{ color: colorTotal }} />;
    
    return (
        <Row key={`ca-li-ri-${id}`} wrap={false} justify='end'>
            <MoneyStatus 
                amount={amount} 
                currency={currency}
                filled={isFilled}
                strikethrough={isStrikeThru}
                icon={<MoneyCircleFilled amount={amount} isTotal={isTotal}/>}
            />
        </Row>  
    );

}

const MoneyStatusTag = ({ statusTag }) => {
    return (
        <Row wrap={false} justify='end' style={{ paddingTop: '4px', paddingRight: '6px' }}>
            {statusTag}
        </Row>  
    );
}

const MoneyStatusRows = ({ id, items, totalOnly, statusTag }) => {
    const showTotalOnly = !!totalOnly;
    const excludeTotal = true;
    const rows = items.length == 1 ? items : items.filter((v) =>( 
        (showTotalOnly && v.isTotal) || (excludeTotal && !v.isTotal))
    );

    return <>
        <Row justify='middle'>
            <Col >
                {rows.map(item => (
                    <MoneyStatusRow key={`ca-li-ri-${item.id}`} item={item} />
                ))}
                <MoneyStatusTag key={`ca-li-st-${id}`} statusTag={statusTag} />
            </Col>
        </Row>
    </>;
}

const ClientActivityListItem = ({ id, activityAt, items, item, locStrings, isAdmin, ...rest }) => {
    const {
        avatar, direction, clientType, title, teamName, tagUid, link, is_test,
        status, activityType, templateTagId, source,
    } = items[0];
    const activityItemTag = <ActivityItemTag key={`ait-id-${id}`} item={items[0]}/>;
    const isTest = !!is_test;
    const testTag = isTest ? <Tag>Test only</Tag> : null;
    const isTeam = (clientType === 'team');

    const titleText = ({ title }) => {
        return <>{title}</>;
    };

    const titleLine = <>
        <ConditonalLink 
            link={link}  
            component={titleText}
            title={title}
            />
        </>;

    return (
        <List.Item key={`ca-li-${id}`} style={{ width: '100%', marginTop: '4px' }}>
            <List.Item.Meta
                avatar={
                    <AvatarBadge
                        avatar={avatar}
                        direction={direction}
                        isTeam={isTeam}
                    />
                }
                title={titleLine}
                description={<Space direction='horizontal'>
                    <ActivityDescriptionView 
                        activityAt={activityAt} clientType={clientType} activityType={activityType} direction={direction}
                        status={status} source={source} templateTagId={templateTagId} tagUid={tagUid}
                    />
                    {isAdmin && item.status !== 'sent' &&
                        <ActivityItemAdminButton
                            isAdmin={isAdmin}
                            item={items[0]}
                        />
                    }
                </Space>}
            />
            <MoneyStatusRows id={id} items={items} totalOnly={false} statusTag={testTag} />
        </List.Item>
    );
};

