import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function enforceAdmin() {
    return true;
}


const AdminAppRoute = ({ component: Component, layout: Layout, locStrings, baseURLs, crumbs, ...rest }) => {

    return (
        !enforceAdmin() ?
            <Redirect to="/" />
        :
            <Route {...rest} render={props => (
                <Layout {...props} locStrings={locStrings} isAdmin={true} crumbs={crumbs} >
                    <Component {...props} isAdmin={true} locStrings={locStrings} baseURLs={baseURLs}/>
                </Layout>
            )} />
    );
};

export default AdminAppRoute;