import { initialize } from 'launchdarkly-js-client-sdk';

export default class FeatureFlagService {
  constructor(props) {
    this.props = props;

    const isTest = props.isTest;
    const clientId = props.clientId;

    if (isTest) {
      this.launchdarkly = initialize('623b437dd94dd10cb573e03f', { key: clientId });
    } else {
      this.launchdarkly = initialize('623b437dd94dd10cb573e040', { key: clientId });
    }
  }

  async getFlag(name, fallback) {
    await this.launchdarkly.waitUntilReady();
    return this.launchdarkly.variation(name, fallback);
  }

  async getFlagWithCallback(name, fallback, callback) {
    const flagResult = await this.getFlag(name, fallback);
    if(callback) { callback(flagResult); }
  }

}
