import firebase from 'firebase/app';

export default class StrikeTeamTapsModel {

  constructor({ viewModel, teamId, callback, limit, teamMemberId, dateRange, isFormatted }) {
    this.viewModel = viewModel;
    this.teamId = teamId;
    this.callback = callback;
    this.limit = limit;
    this.teamTaps = [];
    this.teamTapsById = {};
    this.teamMemberId = teamMemberId;
    this.dateRange = dateRange;
    this.isFormatted = isFormatted || false;
    this.db = firebase.firestore();
  }

  formatTapData = (tap) => {
    let teamTap = { ...tap };
    teamTap.type = 'tap';
    teamTap.activityType = teamTap.product === 'tipping' ? 'Tip' : 'Pay';
    teamTap.title = teamTap.displayName;
    teamTap.activityAt = teamTap.accountsProcessingStartedAt || teamTap.created || teamTap.updatedAt;
    teamTap.currency = teamTap.paymentDetails.currency;
    teamTap.clientType = teamTap.teamMemberId ? 'individual' : 'team';
    teamTap.direction = teamTap.status === 'refunded' ? 'out' : 'in';
    return teamTap;
  }

  fomatTapDataIfRequired = (tap) => {
    return this.isFormatted ? tap : this.formatTapData(tap);
  }

  updateQueryWithDates = (query, dateFieldName, dateRange) => {  
    const { startDate, endDate } = dateRange || {};
    if(!startDate || !endDate || !dateFieldName) return query;

    return query
      .where(dateFieldName, ">=", startDate)
      .where(dateFieldName, "<=", endDate)
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if(this.observerTeamMembersTaps) {
      this.observerTeamMembersTaps();
      this.observerTeamMembersTaps = null;
    }
  }

  fetchAllTeamTaps()  {
    if (this.teamId === null) return;

    const teamId = this.teamId;
    this.stop();
    this.teamTaps = [];

    const mainQuery = this.db
        .collectionGroup('taps')
        .where('teamId', '==', teamId)
        .where('status', 'in', ['pending', 'available_requested', 'available', 'refunded'])
        .orderBy('updatedAt', 'desc');

    const queryLimitToMember = this.teamMemberId ? mainQuery.where('clientId', '==', this.teamMemberId) : mainQuery;

    let query = this.limit ? queryLimitToMember.limit(this.limit) : queryLimitToMember;

    query = this.updateQueryWithDates(query, 'updatedAt', this.dateRange);

    this.observerTeamMembersTaps = query.onSnapshot(querySnapshot => {
      let index = querySnapshot.size;
      this.teamTaps = [];

      querySnapshot.docChanges().forEach(
          change => {
            if(change.type === 'added' || change.type === 'modified') {
              const doc = change.doc.data();
              const id = change.doc.id;
              let tap = {...doc, id};
              tap = this.fomatTapDataIfRequired(tap);
              this.teamTapsById[id] = { ...tap };
            }

            if(change.type === 'removed' )  {
              const id = change.doc.id;
              this.teamTapsById[id] = null;
            }
          }
      );

      this.updateDataSource();

    }, err => {
      this.teamTaps = [];
      this.teamTapsById = {};
      this.updateDataSource();
      console.log(`Team Members Taps query error: ${err}`);
    });
  }

  async updateDataSource() {
    this.teamTaps = [];

    for(let prop in this.teamTapsById) {
      if(this.teamTapsById[prop]) this.teamTaps.push(this.teamTapsById[prop]);
    }

    this.teamTaps.sort((a, b) => {
      const now = new Date();
      const nowString = now.toISOString();
      const aUpdated = (a && a.updatedAt) ? a.updatedAt : nowString;
      const bupdatedAt = (b && b.updatedAt) ? b.updatedAt : nowString;
      const aDate = aUpdated.length > 0 ? aUpdated : aUpdated.toDate().toISOString();
      const bDate = bupdatedAt.length > 0 ? bupdatedAt : bupdatedAt.toDate().toISOString();
      return (aDate < bDate) ? 1 : ( (aDate > bDate) ? -1 : 0 );
    });

    this.viewModel && this.viewModel.setTeamMembersTapsOnPage(this.teamTaps);
    this.callback && this.callback(this.teamTaps);
  }

}
