import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Empty, PageHeader } from 'antd';

import { AdminWithdrawalsList, WithdrawalStatusSelector } from '../../../components/admin/AdminWithdrawalsComponents';
import Spinner from '../../../components/Spinner';
import StrikeWithdrawalsGroupModel from '../../../models/StrikeWithdrawalsGroupModel';
import { AdminWithdrawalDetail } from '../../../components/admin/AdminWithdrawalDetail';
import StrikeWithdrawalModel from '../../../models/StrikeWithdrawalModel';
import { StatusSummaryTable } from '../../../components/admin/StatusSummaryTable';

// /client/manage/profile
export default class AdminWithdrawalsScreen extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);
    this.model = new StrikeWithdrawalsGroupModel({ viewModel: this });

    this.state = {
      lastUpdatedId: null,
      withdrawals: null,
      loading: true,
      withdrawal: null,
      displaySelected: false,
      saving: false,
    };
  }

  componentDidMount() {
    this.model.waitForWithdrawlsData();
  }

  componentWillUnmount() {
    if(this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return !!this.state.loading;
  }

  updatedWithdrawalsDataSource = (withdrawals, id) => {
    this.setState(
      (state) => {
        return {
          lastUpdatedId: id,
          withdrawals,
          loading: false,
          saving: false,
        }
      }
    );
  }

  toggleSeletedWithdrawal = (withdrawal) => {
    this.setState(
      (state) => {
        return {
          saving: false,
          withdrawal: withdrawal,
          displaySelected: !state.displaySelected,
        }
      }
    );
  }

  markWithdrawalAsPaid = async (withdrawal) => {
    this.setState(
      { 
        saving: true
      }
    );
    const withdrawalModel = new StrikeWithdrawalModel(withdrawal);
    const result = await withdrawalModel.setWithdrawalStatusToPaid();
    this.toggleSeletedWithdrawal();
  }

  onStatusFilterChanged = (status) => {
    this.setState(
      { 
        statusFilter: status,
        loading: true,
        withdrawals: null,
      }
    );
    this.model.filterByStatus(status);
  }

  render() {

    if(this.state.displaySelected && this.state.withdrawal) {
      return (
        <div style={{ height: '100%', textAlign: 'center' }}>
          <PageHeader
            ghost={false}
            title={`Withdrawal Detail`}
            onBack={(() => this.toggleSeletedWithdrawal())}
          />
          <AdminWithdrawalDetail
            withdrawal={this.state.withdrawal}
            loading={this.state.saving}
            onMarkPaid={this.markWithdrawalAsPaid}
            isAdmin={true}
          />
        </div>
      );
    } else if (!this.state.loading && this.state.withdrawals.length > 0) {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Withdrawals List`}
            onBack={(() => window.history.back())}
          />
          <WithdrawalStatusSelector 
            onSelected={(status) => this.onStatusFilterChanged(status)}
            defaultStatus={this.state.statusFilter}
          />
          <StatusSummaryTable
            dataSource={this.state.withdrawals}
            loading={this.state.loading}
            titles={[]}
          />
          <AdminWithdrawalsList
            condition={() => { return !this.state.loading }}
            withdrawals={this.state.withdrawals}
            onSelected={this.toggleSeletedWithdrawal}
          />
        </div>
      );
    } else if (!this.state.loading && (this.state.withdrawals.length === 0 || !this.state.withdrawals) ) {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Withdrawals List`}
            onBack={(() => window.history.back())}
          />
          <WithdrawalStatusSelector 
            onSelected={(status) => this.onStatusFilterChanged(status)}
            defaultStatus={this.state.statusFilter}
          />
          <Empty title="No withdrawals found" />
        </div>
      ); 
    } else {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Withdrawals List`}
            onBack={(() => window.history.back())}
          />
          <Spinner condition={() => { return this.isLoading() }} />
        </div>
      ); 
    }
  }
}

