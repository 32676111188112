import React, { useState } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Select,
  Button,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { FormItemLayout, TailFormItemLayout } from '../../utils/Constants';
const { Option } = Select;

const countryByCountryCode = {
  'IE' : 'Ireland',
  'GB' : 'United Kingdom',
  'US' : 'United States',
};

export const DeliveryAddressForm = ({ callback, shipping, edit, buttonText, countryCodes, ...rest }) => {
  const availableCountryCodes = countryCodes && countryCodes.length > 0 ? countryCodes : ['IE', 'US', 'GB'];
  const countryCodeInitialValue = availableCountryCodes[0];
  const initialShippingData = { 
    ...shipping,
   };
  const [form] = Form.useForm();
  const [selectedCountry, setSelctedCountry] = useState(countryCodeInitialValue);

  const onFinish = (values) => {
    values["address"]["line2"] = values["address"]["line2"] || "";
    console.log('Received values of form: ', values);
    if(callback) {
      callback(values);
    }
  };

  const onSelectCountry = (value) => {
    setSelctedCountry(value);
  };

  return (
    <Form
      {...FormItemLayout}
      form={form}
      name="deliveryAddress"
      onFinish={onFinish}
      initialValues={initialShippingData}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label={
          <span>
            Name on Card&nbsp;
            <Tooltip title="Your name as it will appear on the Strikepay card">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        rules={[
          {
            required: true,
            message: 'Please input your name!',
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={
          <span>
            Your Address&nbsp;
            <Tooltip title="The address where your want your card delivered">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
      >
        <Input.Group>
          <Form.Item
            name={['address', 'line1']}
            noStyle
            rules={[{ required: true, message: 'Address is required' }]}
          >
            <Input style={{ width: '50%' }} placeholder="Address line 1" />
          </Form.Item>
          <Form.Item
            name={['address', 'line2']}
            noStyle
            rules={[{ required: false, message: 'Street is required' }]}
          >
            <Input style={{ width: '50%' }} placeholder="(Optional) Address line 2" />
          </Form.Item>
          <Form.Item
            name={['address', 'town']}
            noStyle
            rules={[{ required: true, message: 'City is required' }]}
          >
            <Input style={{ width: '50%' }} placeholder="City" />
          </Form.Item>
          <Form.Item
            name={['address', 'county']}
            noStyle
            rules={[{ required: true, message: 'State/county is required' }]}
          >
            <Input style={{ width: '50%' }} placeholder="State or county" />
          </Form.Item>
          <Form.Item
            name={['address', 'zip']}
            noStyle
            rules={[{ required: true, message: 'Zip/Postcode is required' }]}
          >
            <Input style={{ width: '50%' }} placeholder="Zip/Postcode" />
          </Form.Item>
          <Form.Item
            name={['address', 'country']}
            noStyle
            rules={[{ required: true, message: 'Country is required' }]}
          >
            <Select
              style={{ width: '50%' }}
              placeholder="Select a country"
            >
              { 
                availableCountryCodes.map(
                  (code) => (
                    <Option key={code} value={code}>{countryByCountryCode[code]}</Option>
                  )
                ) 
              }
            </Select>
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item {...TailFormItemLayout}>
        <Button type="primary" htmlType="submit">
         {(edit) ? "Confirm Address" : "Confirm Address"}
        </Button>
      </Form.Item>
    </Form>
  );
};