import React, { useState } from 'react';
import {
  Button,
  Space,
} from 'antd';

import BatchWithdrawalsModel from '../../models/BatchWithdrawalsModel';

const greenButton = { background: '#27AE60', color: 'white' };
const redButton = { color: '#bf2222' };

const BatchStartButton = ({ currency, onBatchCreated, onBatchConfirmed, onBatchCancelled, isAdmin }) => {
  const [batchId, setBatchId] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const model = new BatchWithdrawalsModel({ isAdmin });

  const onStartBatch = async () => {
    setDisabled(true);
    const batchData = {
      currency,
      status: 'prepare_batch',
    };
    const newBatchId = await model.createBatchWithdrawal(batchData);
    onBatchCreated && onBatchCreated({ batchId: newBatchId, ...batchData } );
    setBatchId(newBatchId);
  };

  const onConfirmBatch = async () => {
    setDisabled(false);
    const batchData = {
      currency,
      status: 'execute_requested',
    };
    await model.updateBatchWithdrawal(batchId, batchData);
    onBatchConfirmed && onBatchConfirmed({ batchId, ...batchData });
    setBatchId(null);
  };

  const onCancelBatch = async () => {
    setDisabled(false);
    const batchData = {
      currency,
      status: 'cancelled',
    };  
    await model.updateBatchWithdrawal(batchId, batchData);
    onBatchCancelled && onBatchCancelled({ batchId, ...batchData });
    setBatchId(null);
  }

  const startText = `Start ${currency} Batch`;
  const confirmText = `Confirm ${currency} Batch`;
  const cancelText = `Cancel`;

  return <>
    { batchId && 
      <Space direction='horizontal'>
        <Button type='primary' style={greenButton} disabled={!disabled} onClick={() => { onConfirmBatch() }}>{confirmText}</Button>
        <Button type='ghost' style={redButton} disabled={!disabled} onClick={() => { onCancelBatch() }}>{cancelText}</Button>
      </Space>
    }
    { !batchId && 
      <Button disabled={disabled} type='primary' onClick={() => { onStartBatch() }}>{startText}</Button>
    }
  </>
};

export default BatchStartButton;
