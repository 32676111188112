import React, { Component } from 'react';
import firebase from 'firebase/app';
import { Typography, PageHeader, Tag, Upload , Button, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ReactCrop from 'react-image-crop';
import qs from 'qs';

import StrikeClientModel from '../../../models/StrikeClientModel';
import { EditClientProfileForm } from '../../../components/client/EditClientProfileForm';
import TrackerService from '../../../utils/TrackerService';
import getBase64 from '../../../utils/GetBase64';
import CenteredSpinner from '../../../components/CenteredSpinner';

import '../../../App.scss';
import 'antd/dist/antd.css';
import 'react-image-crop/dist/ReactCrop.css';

export default class CompleteClientSignup extends Component {
  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    const isAdmin = !!props.isAdmin;

    const parsed = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const mode = (parsed.mode) ? 'editing' : 'viewing';

    this.model = new StrikeClientModel({ viewModel: this, clientId, isAdmin });
    this.isAdmin = isAdmin;

    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    this.state = {
      user: null,
      client: null,
      clientId: clientId,
      percent: 0,
      loading: true,
      mode,
      redirect: queryParams.redirect,
      crop: {
        unit: '%',
        width: 30,
        aspect: 1 / 1,
      },
    };
  }

  beforeUpload = (file) => {
    this.setState({ visible: true, src:  URL.createObjectURL(file) });

    // return false to not have it trigger upload, we want the file but no the actual uploading
    return false;
  }

  componentDidMount = async () => {
    this.model.waitForClientData();

    const user = await this.getCurrentUser(firebase.auth());
    this.setState({ user });
  }

  componentWillUnmount() {
    if(this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || false;
  }

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ uploading: true });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          uploading: false,
        }),
      );
    }
  };

  uploadImage =  async (file) => {
    const storageRef = firebase.storage().ref();

    const fileName = this.state.fileName;
    const clientImagesRef = storageRef.child(`public-images/client-images/${fileName}`);
    
    const resultSnapshot = await clientImagesRef.put(file);
    const imageUrl = await resultSnapshot.ref.getDownloadURL();
    
    const userId = this.state.client.ownerId;
    const db = firebase.firestore();

    const clientId = this.state.clientId;
    const imagePromises = [];

    imagePromises.push(
      db.collection('users').doc(userId).set({
        photoURL: imageUrl,
      }, { merge: true }),
      db.collection('clients').doc(clientId).update({
        avatar: imageUrl,
      }),
      this.state.user.updateProfile({
        photoURL: imageUrl,
      })
    );

    await Promise.all(imagePromises);
  }

  async getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
       const unsubscribe = auth.onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
       }, reject);
    });
  }

  clientNotFound = () => {
    console.log('No user or client here...');
  }

  foundClient = async (client) => {

    this.setState(
      (state) => {
        return {
          client,
          loading: false,
        }
      }
    );
  }

  changeMode = (mode) => {
    if (mode === 'viewing') {
      return 'editing'
    } else if (mode === 'editing') {
      return 'saving'
    } else {
      return 'viewing';
    }
  }


  onClickEdit = () => {
    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    }
    );
  }

  doneSaving = () => {

    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    }
    );

    this.model.waitForClientData();
  }

  // MARK: Apply IP Address when terms are accepted
  applyIPAddressWhenTermsAccepted = async (isLocalHost) => {
    const clientId = this.state.clientId;
    if (!clientId) return;
    const baseUrl =  isLocalHost ? 'http://localhost:8088' : `https://pay.strikepay.co`;
    const apiPath = 'api';
    const url = `${baseUrl}/${apiPath}/clients/${clientId}/acceptedterms`;
    const data = { };
    // Define the request options
    const requestOptions = {
      method: "PUT", // Specify the request method
      headers: { "Content-Type": "application/json" }, // Specify the content type
      body: JSON.stringify(data) // Send the data in JSON format
    };
    try {
      await fetch(url, requestOptions);  
    } catch (error) {
      // Ignore the error -- we don't want to block the user from signing up
      console.error('Error:', error);
    }
  }

  internalSave = async (data) => {
    const isLocalHost = window.location.hostname === 'localhost';
    await this.model.commit(data);
    await this.applyIPAddressWhenTermsAccepted(isLocalHost);
    this.doneSaving();
  }

  onClickSave = (data) => {
    const isLocalHost = window.location.hostname === 'localhost';
    const isTest = isLocalHost;

    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode),
      }
    }
    );

    this.internalSave(data);
    
    let successUrl = `/client/manage/${this.state.clientId}`;
    let redirected = false;

    if (this.state.redirect) {
      redirected = true;
      successUrl = this.state.redirect;
    }

    const tracker = new TrackerService({
      isTest,
    });

    const analyticsPayload = {
      isAnonymous: this.state.user.isAnonymous,
      clientId: this.state.clientId,
      redirected,
      successUrl,
    }

    const userId = this.state.user.uid;
    tracker.mixPanelIdentify(userId, this.state.clientId, data.displayName, data.email);
    tracker.mixpanelTrack("client_signup_complete", {
      distinct_id: userId,
      clientName: this.state.client.displayName,
      ...analyticsPayload,
    });  
    
    if (!isTest) {
      tracker.googleTrack(
        "client_signup_complete", 
        {
          userId,
          ...analyticsPayload,
        }
      );
    }

    this.props.history.push(successUrl);
  }

  getButtons = () => {
    if (this.state.mode === 'viewing') {
      return [
        <Button key="1" type="primary" onClick={this.onClickEdit}>
          Edit
        </Button>,
      ]
    } else if (this.state.mode === 'editing') {
      return [];
    } else {
      return [];
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true, visible: false });
    this.uploadImage(this.state.croppedImage);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImage = await this.getCroppedImg(
        this.imageRef,
        crop,
      );
      this.setState({ croppedImage });
    }
  }

  onImageLoaded = image => {
    this.imageRef = image;
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width;
    canvas.height = crop.height;

    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    
    const clientId = this.state.clientId;
    const timestamp = Date.now();
    const fileName = `${clientId}-${timestamp}.jpg`;
    this.setState({ fileName });

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg');
    });
  }

  render() {
    if (!this.state.loading ) {
      const { uploading, imageUrl } = this.state;
      const avatarUrl = imageUrl ? imageUrl : this.state.client.avatar;

      const uploadButton = (
          <div>
            { uploading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload Profile Picture</div>
          </div>
      );

      const { visible, loading } = this.state;

      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`Complete Signup`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
          />
          <div style={{ marginLeft: "auto", marginRight: 'auto', width: "intrinsic", marginBottom: "10px"}}>
            
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={this.uploadImage}
              beforeUpload={this.beforeUpload}
              onChange={this.handleChange}
            >
              {avatarUrl ? <img src={avatarUrl} alt="avatar" style={{ width: '104px', height: '104px', borderRadius: '100%' }} /> : uploadButton}
            </Upload>
          </div>

          <Modal
            visible={visible}
            title="Crop your image"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
                Upload
              </Button>,
            ]}
          >
            <div className="cropper">
              { this.state.src && (
                <ReactCrop
                  src={this.state.src}
                  crop={this.state.crop}
                  ruleOfThirds
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              )}
            </div>
            
          </Modal>

          <EditClientProfileForm
            client={this.state.client}
            callback={this.onClickSave}
            isSignUp={true}
            isAdmin={this.isAdmin}
          />
        </div>
      );
    } else {
      return (
        <CenteredSpinner loading={this.isLoading()} noLayout={false} />
      );
    }
  }
}

