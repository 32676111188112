import { useState, useEffect } from 'react';

import StrikeTeamsModel from './StrikeTeamsModel';

const limit = 3;

export function useTeamsList(clientId) {
    const [teams, setTeams] = useState(null);

    useEffect(() => {
        const onUpdate = async (data) => {
          setTeams(data);
        };

        const model = new StrikeTeamsModel({
            clientId,
            limit,
            onUpdate,
        });

        model.fetchAllTeamsForClient();

        return function cleanup() {
            model.stop();
        };

    },
        []
    );

    return teams;
}
