import React, { useState } from 'react';
import {
  Button,
  Space,
} from 'antd';

const greenButton = { background: '#27AE60', color: 'white' };
const redButton = { color: '#bf2222' };

const BatchActionButton = ({ onConfirm, text, disabled, onUpdate }) => {
  const [isConfirmActive, setConfirmActive] = useState(false);

  const cancelText = `Cancel`;

  const onClickConfirm = async () => {
    onConfirm && onConfirm();
    setConfirmActive(false);
    onUpdate && onUpdate(false);
  }

  const onClickCancel = async () => {
    setConfirmActive(false);
    onUpdate && onUpdate(false);
  }

  const onClick = async () => {
    setConfirmActive(true);
    onUpdate && onUpdate(true);
  }

  return <>
    {!isConfirmActive &&
      <Button type='primary' disabled={disabled} key={`bab-${text}`} onClick={() => { onClick() }} >{text}</Button>
    }
    {isConfirmActive &&
      <Space direction='horizontal'>
        <Button key={`confirm-bab-${text}`} type='default' style={greenButton} onClick={() => { onClickConfirm() }}>{`Confirm ${text}`}</Button>
        <Button key={`cancel-bab-${text}`} type='ghost' style={redButton} onClick={() => { onClickCancel() }}>{cancelText}</Button>
      </Space>
    }
  </>;
}

const BatchActionButtons = ({ batchId, onBatchUpload, onBatchMarkPaid, onBatchCancelled, onPause, onUnpause, isAdmin }) => {
  const [disabled, setDisabled] = useState(false);

  const onUpload = async () => {
    onBatchUpload && onBatchUpload({ batchId });
  };

  const onMarkPaidOut = async () => {
    onBatchMarkPaid && onBatchMarkPaid({ batchId });
  };

  const onCancelBatch = async () => {
    onBatchCancelled && onBatchCancelled({ batchId });
  };

  const onPauseBatch = async () => {
    onPause && onPause({ batchId });
  }

  const onUnpauseBatch = async () => {
    onUnpause && onUnpause({ batchId });
  }

  const uploadBatchText = `Upload`; // set status to 'upload_requested'
  const markPaidText = `Mark Paid`; // set status to 'paid'
  const cancelBatchText = `Cancel All`; // set status to 'cancelled' for ALL lines
  const pauseText = `Pause`;        // set status to 'paused'
  const unpauseText = `Unpause`;    // set status to 'batched'

  const onUpdateButton = (isConfirmActive) => {
    setDisabled(isConfirmActive);
  }

  return <>
    {
      <Space direction='horizontal'>
        <BatchActionButton
          onConfirm={onUpload}
          text={uploadBatchText}
          onUpdate={onUpdateButton}
          disabled={disabled}
        />
        <BatchActionButton
          onConfirm={onMarkPaidOut}
          text={markPaidText}
          onUpdate={onUpdateButton}
          disabled={disabled}
        />
        <BatchActionButton
          onConfirm={onCancelBatch}
          text={cancelBatchText}
          onUpdate={onUpdateButton}
          disabled={disabled}
        />
        <BatchActionButton
          onConfirm={onPauseBatch}
          text={pauseText}
          onUpdate={onUpdateButton}
          disabled={disabled}
        />
        <BatchActionButton
          onConfirm={onUnpauseBatch}
          text={unpauseText}
          onUpdate={onUpdateButton}
          disabled={disabled}
        />
      </Space>
    }
  </>
};

export default BatchActionButtons;
