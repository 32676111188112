import React, { Component } from 'react';
import { Typography, PageHeader, Tag , Button } from 'antd';

import StrikeClientModel from '../../../models/StrikeClientModel';
import { ProfileImage } from '../../../components/client/AccountProfileComponents';
import { EditClientProfileForm } from '../../../components/client/EditClientProfileForm';
import Spinner from '../../../components/Spinner';

import '../../../App.scss';
import 'antd/dist/antd.css';

const { Paragraph } = Typography;


// /client/manage/profile
export default class QrsClientProfileScreen extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    const isAdmin = !!props.isAdmin;

    console.log(`EditClientProfileScreen clientId: ${clientId}`);

    this.model = new StrikeClientModel({ viewModel: this, clientId, isAdmin });
    this.isAdmin = isAdmin;

    this.state = {
      user: null,
      client: null,
      percent: 0,
      loading: true,
      mode: 'viewing',
    };
  }

  componentDidMount() {
    this.model.waitForClientData();
  }

  componentWillUnmount() {
    if(this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || false;
  }


  foundClient = async (client) => {
    // console.log(`EditClientProfileScreen: Found Client...${JSON.stringify(client)}`);

    this.setState(
      (state) => {
        return {
          client,
          loading: false,
        }
      }
    );
  }

  changeMode = (mode) => {
    if (mode === 'viewing') {
      return 'editing'
    } else if (mode === 'editing') {
      return 'saving'
    } else {
      return 'viewing';
    }
  }


  onClickEdit = () => {
    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    }
    );
  }

  doneSaving = () => {

    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    }
    );

    this.model.waitForClientData();
  }

  internalSave = async (data) => {
    await this.model.commit(data);
    this.doneSaving();
  }


  onClickSave = (data) => {

    console.log(`onClickSave`, data);

    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode),
      }
    }
    );

    this.internalSave(data);
  }

  getButtons = () => {
    if (this.state.mode === 'viewing') {
      return [
        <Button key="1" type="primary" onClick={this.onClickEdit}>
          Edit
        </Button>,
      ]
    } else if (this.state.mode === 'editing') {
      return [];
    } else {
      return [];
    }
  }

  render() {
    const avatar = this.state.client ? this.state.client.avatar : null;
    const avatarParams = { src: avatar };

    if (this.state.mode === 'viewing' && !this.state.loading && this.state.client ) {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`My QRs`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
          <Spinner condition={() => { return this.isLoading() }} />
          <ProfileImage
            condition={() => { return !this.isLoading() && this.state.client }}
            locStrings={this.props.locStrings}
            client={this.state.client}
          />
          <Paragraph>{this.state.client.email || ""}</Paragraph>
          <Paragraph>{this.state.client.phoneNumber || ""}</Paragraph>
          <Paragraph>{this.state.client.employerName || ""}</Paragraph>
          <Paragraph>{this.state.client.agreeTerms ? "Agreed Terms" : "Need to agree terms"}</Paragraph>
        </div>
      );
    } else if (!this.state.loading) {
      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`My QRs`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
          <EditClientProfileForm
            client={this.state.client}
            callback={this.onClickSave}
            isAdmin={this.isAdmin}
          />
        </div>
      );
    } else {
      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`My QRs`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
        </div>
      );
    }


  }
}

