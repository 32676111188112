import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Empty, PageHeader, Space } from 'antd';

import { AdminBalancesList } from '../../../components/admin/AdminBalancesComponents';
import AdminAllBalancesModel from '../../../models/AdminAllBalancesModel';


// /admin/balances
export default class AdminBalancesScreen extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);
    this.model = new AdminAllBalancesModel({ viewModel: this });

    this.state = {
      donationAccounts: null,
      paymentAccounts: null,
      tippingAccounts: null,
      lastUpdatedId: null,
      loading: true,
      loadingAccounts: true,
      searching: false,
    };
  }

  componentDidMount() {
    this.model.waitForAccountsData();
  }

  componentWillUnmount() {
    if(this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || false;
  }

  updatedAccountsDataSource = (accounts, id) => {
    this.setState(
      (state) => {
        return {
          lastUpdatedId: id,
          donationAccounts: accounts.filter((acc) => { return acc.clientId !== 'strike' && acc.product === 'giving' }),
          paymentAccounts: accounts.filter((acc) => { return acc.clientId !== 'strike' && acc.product === 'payment' }),
          tippingAccounts: accounts.filter((acc) => { return acc.clientId !== 'strike' && acc.product === 'tipping' }),
          loading: false,
        }
      }
    );
  }

  executeSearch = (searchText) => {
    this.setState(
      {
        loading: true,
      }
    ); 

    this.filteredDonationAccounts = this.state.donationAccounts;
    this.filteredPaymentAccounts = this.state.paymentAccounts;
    this.filteredTippingAccounts = this.state.tippingAccounts;

    this.completedSearch();
  }

  completedSearch = () => {
    this.setState(
      {
        searching: false,
        filteredDonationAccounts: this.filteredDonationAccounts,
        filteredPaymentAccounts: this.filteredPaymentAccounts,
        filteredTippingAccounts: this.filteredTippingAccounts,
        isSearchDisabled: false,
        loading: false,
      }
    );
  }


  render() {
    if (!this.state.loading) {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Client Balances`}
            onBack={(() => window.history.back())}
          />
          <AdminBalancesList
            condition={() => { return !this.state.loading }}
            donationAccounts={this.state.filteredDonationAccounts || this.state.donationAccounts}
            paymentAccounts={this.state.filteredPaymentAccounts || this.state.paymentAccounts}
            tippingAccounts={this.state.filteredTippingAccounts || this.state.tippingAccounts}
          />
        </div>
      );
    } else {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Client Balances`}
            onBack={(() => window.history.back())}
          />
          <Empty />
        </div>
      ); 
    }
  }
}