import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Empty, PageHeader } from 'antd';

import { PayoutsList } from '../../../components/client/BankAccountComponents';
import StrikeWithdrawalsModel from '../../../models/StrikeWithdrawalsModel';
import CenteredSpinner from '../../../components/CenteredSpinner';


// /client/manage/profile
export default class PayoutsBankClientProfileScreen extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    const isAdmin = !!props.isAdmin;

    this.model = new StrikeWithdrawalsModel({ viewModel: this, clientId, isAdmin });

    this.state = {
      clientId: props.match.params.clientId,
      payoutMethodId: props.match.params.payoutId,
      withdrawals: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.model.waitForWithdrawlsData();
  }

  componentWillUnmount() {
    if(this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || false;
  }

  updatedWithdrawalsDataSource = (withdrawals) => {
    this.setState(
      (state) => {
        return {
          withdrawals,
          loading: false,
        }
      }
    );
  }

  render() {
    if (!this.state.loading && this.state.withdrawals.length > 0) {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Your Payouts`}
            onBack={(() => window.history.back())}
          />
          <PayoutsList
            condition={() => { return !this.state.loadingPayouts }}
            withdrawals={this.state.withdrawals}
            payoutMethodId={this.state.payoutMethodId}
          />
        </div>
      );
    } else if (!this.state.loading && (this.state.withdrawals.length === 0 || !this.state.withdrawals) ) {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Your Payouts`}
            onBack={(() => window.history.back())}
          />
          <Empty />
        </div>
      ); 
    } else {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Your Payouts`}
            onBack={(() => window.history.back())}
          />
          
          <CenteredSpinner loading={this.isLoading()} noLayout={false} />
        </div>
      ); 
    }
  }
}

