import React, { Component } from 'react';
import qs from 'qs';
import firebase from 'firebase/app';
import { Typography, Col, Row } from 'antd';
import { Redirect } from 'react-router-dom';

import FirebaseLogin from '../../../components/client/ClientFirebaseLogin';
import CenteredSpinner from '../../../components/CenteredSpinner';
import TrackerService from '../../../utils/TrackerService';

import '../../../App.scss';


const { Title, Text } = Typography;

export function ClientSignupScreen(props) {
  return <ClientLoginScreen isSignup {...props} />;
}

export default class ClientLoginScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isSignup: !!props.isSignup,
      redirectWithUser: false,
      redirectOnLogin: null,
      loading: false,
      locStrings: {
        terms_and_conditions: "TERMS",
        privacy_policy: "PRIVACY",
        paymentcard2_cashlesstippingtext_46302: "Powered by ",
      }
    };

    this.handleNewUserSignup = this.handleNewUserSignup.bind(this);
  }

  async componentDidMount() {
    // stop the observer is called multiple times and restart here
    if (this.authObserver) {
      this.authObserver();
      this.authObserver = null;
    }
    if (this.observerClient) {
      this.observerClient();
      this.observerClient = null;
    }

    this.authObserver = firebase.auth().onAuthStateChanged(async function (user) {
      this.handleAuthentication(user);
    }.bind(this));
  }

  componentWillUnmount() {
    if (this.observerClient) {
      this.observerClient();
      this.observerClient = null;
    }
    if (this.authObserver) {
      this.authObserver();
      this.authObserver = null;
    }
  }

  async getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }

  async handleAuthentication(user) {
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { uid, isAnonymous, emailVerified } = user || {}; 

    if (uid && !isAnonymous) {
      this.setState({ user, loading: true });
      this.fetchClientDataWithUserId(uid, emailVerified, queryParams.redirect, queryParams.redirectRoute);

      if (this.authObserver) {
        this.authObserver();
        this.authObserver = null;
      }
    }
  }

  componentDidUpdate() {
  }

  fetchClientDataWithUserId = async (ownerId, emailVerified, redirect, redirectRoute) => {
    if (this.observerClient) return;

    const db = firebase.firestore();
    const query = db.collection('clients').where("ownerId", "==", ownerId).limit(1);
    this.observerClient = query.onSnapshot(snap => {

      this.client = null;
      const size = snap.size;
      if (size > 0) {
        snap.forEach(
          doc => {
            this.client = doc.data();
            this.clientId = doc ? doc.id : null;
            this.foundClient(this.clientId, emailVerified, redirect, redirectRoute);
          }
        );
      }
    });
  }

  foundClient = (clientId, emailVerified, redirect, redirectRoute) => {    
    const isLocalHost = window.location.hostname === 'localhost';
    const isTest = isLocalHost;
    const isPaused = this.client.status === 'paused';

    if (clientId && this.state.loading) {
      let successUrl;

      if (this.observerClient) {
        this.observerClient();
        this.observerClient = null;
      }

      if (this.authObserver) {
        this.authObserver();
        this.authObserver = null;
      }

      if (this.client && !this.client.agreeTerms) {
        successUrl = `/client/completeSignup/${clientId}`;

        if (redirect) {
          successUrl += `?redirect=${redirect}`
        } else if (redirectRoute) {
          successUrl += `?redirect=${`/client/manage/${clientId}/${redirectRoute}`}`;
        }
      } else if (redirect) {
        successUrl = redirect;
      } else if (redirectRoute) {
        successUrl = `/client/manage/${clientId}/${redirectRoute}`;
      } else {
        successUrl = `/client/manage/${clientId}`;
      }

      // Let's remove the `this.userSignIn` to this so we always kick people to 
      // email verification. We'll set a flag on all new clients and enforce here.
      if (this.userSignedIn && !emailVerified && !isPaused) {
        if (redirect) {
          successUrl = `/client/verify-email?redirect=${redirect}`;
        } else {
          successUrl = `/client/verify-email`;
        }
      }

      const tracker = new TrackerService({
        isTest,
      });

      const user = this.state.user;
      const userId = user.uid;
      const isNewSignup = user.metadata.creationTime === user.metadata.lastSignInTime;

      const { displayName, email } = this.client;
      if (displayName && email) {
        tracker.mixPanelIdentify(userId, clientId, displayName, email);
      }

      tracker.mixpanelTrack("client_sign_in", {
        distinct_id: userId,
        clientId,
        isNewSignup,
        redirect: redirect ? true : false,
        redirectUrl: successUrl,
      });

      if (!isTest) {
        tracker.googleTrack(
          "start_claim_clicked",
          {
            userId,
            clientId,
            isNewSignup,
            redirect: redirect ? true : false,
            redirectUrl: successUrl,
          }
        );
      }

      // this.props.history.push(successUrl);
      this.setState({
        loading: false,
        redirectOnLogin: successUrl,
      });
    } else {
      this.clientNotFound()
    }
  }

  clientNotFound = () => {
    console.log('No user or client here...');
    firebase.app().auth().signOut();
  }

  async handleNewUserSignup(authResult) {
    const user = authResult.user;
    this.userSignedIn = true;
    const { emailVerified, uid, displayName } = user || {};

    if (navigator.language && uid) {
      await this.setUserLocale(uid, navigator.language);
    }
    if(emailVerified && uid) {
      await this.setUserEmailVerifiedToTrue({ uid, displayName });
    }
  }

  async setUserLocale(uid, browserLocale) {
    const db = firebase.firestore();
    await db.collection('users').doc(uid).set({
      locale: browserLocale
    }, { merge: true });
  }

  async setUserEmailVerifiedToTrue({ uid, displayName }) {
    const emailVerified = true;
    const db = firebase.firestore();
    await db.collection('users').doc(uid).set({
      emailVerified,
      displayName: displayName || null,
    }, { merge: true });
  }

  renderRedirect() {
    console.log('Switching to ', this.state.redirectOnLogin);
    return (this.state.redirectOnLogin ? <Redirect to={this.state.redirectOnLogin} /> : null);
  }

  render() {
    const titleText = this.state.isSignup ? "Welcome to Strikepay" : "Welcome back to Strikepay";
    const subtitleText = this.state.isSignup ? "Sign up with Google or email" : "Sign in to access your account";
    const subsubtitleText = this.state.isSignup ? "A Strikepay account will be created for you" : "See your balance and manage your account";

    if (this.state.redirectOnLogin) {
      return this.renderRedirect();
    }

    return (
      <Row gutter={[0, 0]} align='middle' style={{ minWidth: '260px', width: '100%' }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={4} style={{ maxWidth: '500px' }}>
          {
            this.state.loading
              ? ( <CenteredSpinner loading={this.state.loading} />)
              : (
                <div style={{ textAlign: 'start' }} >
                  <Title level={1} >{titleText}</Title>
                  <Title level={4} >{subtitleText}</Title>
                  <Text  >{subsubtitleText}</Text>
                  <div style={{ textAlign: 'center', marginTop: '44px' }} >
                    <FirebaseLogin isSignup={this.state.isSignup} ref={(el) => this._elFirebaseLogin = el} handler={this.handleNewUserSignup} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...this.props} />
                  </div>
                </div>
              )
          }
        </Col>
        <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={10}></Col>
      </Row>
    );
  }
}
