export function isBankUnique(newPayout, payouts) {
    const { 
        country: dataCountry, 
        iban: dataIban,
        routingNumber: dataRoutingNumber,
        accountNumber: dataAccountNumber,
        sortCode: dataSortCode,
    } = newPayout || {};

    let result = true;

    const countryMatched = dataCountry && payouts.find(({ country }) => country === dataCountry);
    if (countryMatched) {
        const ibanMatched = dataIban && 
            payouts.find(({ iban }) => iban === dataIban);
        const ukBankMatched = dataSortCode && dataAccountNumber && 
            payouts.find(({ sortCode, accountNumber }) => sortCode === dataSortCode && accountNumber === dataAccountNumber);
        const usBankMatched = dataRoutingNumber && dataAccountNumber && 
            payouts.find(({ routingNumber, accountNumber }) => routingNumber === dataRoutingNumber && accountNumber === dataAccountNumber);       
        result = !(ibanMatched || ukBankMatched || usBankMatched);
    }

    return result;
}
