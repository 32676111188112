import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserDeleteOutlined } from '@ant-design/icons';
import { Button, Typography, Modal , message } from 'antd';

import StrikeTeamDetailsModel from '../../models/StrikeTeamDetailsModel';
import TrackerService from '../../utils/TrackerService';

const { Text } = Typography;
const { confirm } = Modal;

export const TeamMemberLeaveButton = ({ clientId, team, isAdmin, ...rest }) => {
    const { teamId, teamName } = team;
    const memberClientId = clientId;
    const memberDisplayName = "Self";
    const [isMemberDeleted, setIsMemberDeleted] = useState(false);
    const history = useHistory();
    

    const updatedBlockedStateTo = (isBlocked) => {
        setIsMemberDeleted(isBlocked);
    };

    const deleteMember = async () => {
        const model = new StrikeTeamDetailsModel({
            teamId,
            memberClientId,
        });
        confirm({
            icon: <UserDeleteOutlined />,
            title: 'Do you want to leave this team?',
            content: 
            <>
              <br />
              <p><strong>Warning:</strong> You will not be able to earn new tips from this team.</p> 
              <p>Of course, your keep all of your existing tips.</p>
              <p>Please confirm you want to leave the team, <strong>{teamName}</strong>.</p> 
            </>,
            okText: 'Leave',
            centered: true,
    
            onOk() {
              model.deleteMemberFromTeam(memberClientId);
              updatedBlockedStateTo(true);
              history.push(isAdmin ? `/admin/clients/${clientId}/teams` : `/client/manage/${clientId}/teams`);
            },
        });
    };

    const isLocalHost = window.location.hostname === 'localhost';
    const isTest = isLocalHost;

    const tracker = new TrackerService({
      isTest,
    });

    const data = {
      memberClientId,
      memberDisplayName,
      teamId,
      teamName,
    }

    const onClickDelete = async () => {
      deleteMember();

      tracker.mixpanelTrack('team_member_leave', data);

      if (!isTest) {
        tracker.googleTrack('team_member_leave', data);
      }
    };

    return (
        <>
            {!isMemberDeleted &&
                <Button 
                  key={`member-leave-button-${memberClientId}`} 
                  type='default' 
                  onClick={onClickDelete} 
                  {...rest} 
                  danger 
                  icon={<UserDeleteOutlined />}
                >
                  {`Leave ${teamName}`}
                </Button>}
        </> 
    );
};
