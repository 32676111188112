import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Empty, PageHeader, Space } from 'antd';

import { AdminClientsList, ClientsSearch } from '../../../components/admin/AdminClientsComponents';
import { Spinner } from '../../../components/admin/AdminCoreComponents';
import AdminAllClientsModel from '../../../models/AdminAllClientsModel';
import CenteredSpinner from '../../../components/CenteredSpinner';


// /client/manage/profile
export default class AdminClientsScreen extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);
    this.model = new AdminAllClientsModel({ viewModel: this });

    this.state = {
      lastUpdatedId: null,
      clients: null,
      loading: true,
      accounts: null,
      loadingAccounts: true,
      searching: false,
    };
  }

  componentDidMount() {
    this.model.waitForClientsData();
  }

  componentWillUnmount() {
    if(this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || false;
  }

  updatedClientsDataSource = (clients, id) => {
    this.setState(
      (state) => {
        return {
          lastUpdatedId: id,
          clients,
          loading: false,
        }
      }
    );
  }

  executeSearch = (searchText) => {
    this.setState(
      {
        loading: true,
      }
    ); 

    this.filteredClients = this.state.clients.filter(
      (client) => {
        const lowerSearchText = searchText.toLowerCase();
        const lowerEmail = (client.email || "").toLowerCase();
        const lowerDisplayName = (client.displayName || "").toLowerCase();
        const lowerPhoneNumber = (client.phoneNumber || "").toLowerCase();
        const lowerEmployerName = (client.employerName || "").toLowerCase();
        const lowerProfileBio = (client.profileBio || "").toLowerCase();

        return ( 
          lowerEmail.includes(lowerSearchText) || 
          lowerDisplayName.includes(lowerSearchText) ||
          lowerPhoneNumber.includes(lowerSearchText) ||
          lowerEmployerName.includes(lowerSearchText) ||
          lowerProfileBio.includes(lowerSearchText) 
        );
      }
    );

    this.completedSearch();
  }

  completedSearch = () => {
    this.setState(
      {
        searching: false,
        filteredClients: this.filteredClients,
        isSearchDisabled: false,
        loading: false,
      }
    );
  }

  onSearch = (searchText) => {
    const isSearchTextProvided = (searchText && searchText.length > 0);
    this.setState(
      {
        searching: isSearchTextProvided,
        searchText: searchText,
        isSearchDisabled: isSearchTextProvided,
        filteredClients: null
      }
    );    
    if(isSearchTextProvided) this.executeSearch(searchText);
  }

  render() {
    if (!this.state.loading && this.state.clients.length > 0) {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Clients List`}
            onBack={(() => window.history.back())}
          />
          <ClientsSearch 
            initialValue={this.searchText}
            callbackOnSearch={this.onSearch}
            loading={this.state.searching}
            disabled={this.state.isSearchDisabled}
          />
          <AdminClientsList
            condition={() => { return !this.state.loading }}
            clients={this.state.filteredClients || this.state.clients}
          />
        </div>
      );
    } else if (!this.state.loading && (this.state.clients.length === 0 || !this.state.clients) ) {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Clients List`}
            onBack={(() => window.history.back())}
          />
          <Empty />
        </div>
      ); 
    } else {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Clients List`}
            onBack={(() => window.history.back())}
          />
          <Space direction='vertical' style={{ width: '100%' }}>
          { this.state.searching &&
            <ClientsSearch 
              initialValue={this.searchText}
              callbackOnSearch={this.onSearch}
              loading={this.state.searching}
              disabled={this.state.isSearchDisabled}
            />
            }
            <CenteredSpinner loading={this.isLoading()} noLayout={false} />
          </Space>
        </div>
      ); 
    }
  }
}