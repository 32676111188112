import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebaseui/dist/firebaseui.css';
import qs from 'qs';

var firebaseui = require('firebaseui');

export default class ClientFirebaseLogin extends Component {

  constructor(props) {
    super(props);
    const authList = this.props.logins || { google: true, email: true };

    this.loginOptions = [];
    this.isSignup = !!this.props.isSignup;


    if(authList && authList.google) {
      this.loginOptions = [
        {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        fullLabel: (this.isSignup) ? 'Sign up with Google' : 'Sign in with Google'
        }
      ];
    }

    if(authList && authList.email) {
      this.loginOptions = [
        ...this.loginOptions,
        {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        fullLabel: (this.isSignup) ? 'Sign up with email' : 'Sign in with email'
        }
      ];
    }

    this.state = {
      isSignedIn: false,
    };
  }

  async componentDidMount() {
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    let successUrl = "/client/email-verified";

    if (queryParams.redirect) {
      successUrl = '/client/login?redirect=' + queryParams.redirect;
    }

    if (queryParams.redirectRoute) {
      successUrl = `/client/manage/account/${queryParams.redirectRoute}`;
    }

    const firebaseUIConfig = {
      autoUpgradeAnonymousUsers: false,
      signInFlow: 'popup',
      signInOptions: this.loginOptions,
      callbacks: {
        signInSuccessWithAuthResult: async (authResult) => {
          const user = authResult.user;

          if (!user.emailVerified) {
            const isLocalHost = window.location.hostname === 'localhost';
            const urlBase =  isLocalHost ? 'localhost:3000' : `https://app.strikepay.co`;
            const url = `${urlBase}${successUrl}`
            await user.sendEmailVerification({ url });
          };

          this.props.handler(authResult);
          return false;
        },
        signInFailure: (error) => {
          console.error("** Firebase sign-in failed: ", error);
          if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
            this.loginFailed(error);
          } else {
            this.signInFailedDueToAnonUpgradeMergeConflict(error);
          }
          
          return false;
        },
      }
    };

    let firebaseUi;

    if (!firebaseui.auth.AuthUI.getInstance()) {
      firebaseUi = new firebaseui.auth.AuthUI(firebase.auth());
    } else {
      firebaseUi = firebaseui.auth.AuthUI.getInstance()
    }

    firebaseUi.start('#firebaseui-auth-container', firebaseUIConfig);
  }

  componentWillUnmount() {
  }

  async signInFailedDueToAnonUpgradeMergeConflict(error) {
    console.error("*** signInFailedDueToAnonUpgradeMergeConflict: ", error);

    var code = error.code;
    if (code !== 'firebaseui/anonymous-upgrade-merge-conflict') return;

    console.error("signInFailedDueToAnonUpgradeMergeConflict: cred");
    var cred = error.credential;
    // const uid = this.props.appActions.firebase.anonymousUserId;
    // var anonId = uid;
    var anonId = null;
    console.error("signInFailedDueToAnonUpgradeMergeConflict: firestoreUsersUpdateWithUser(uid)", anonId);

    console.error("signInFailedDueToAnonUpgradeMergeConflict: signInWithCredential()");
    var user = await firebase.auth().signInWithCredential(cred); // fires `firebase.auth().onAuthStateChanged()`
    var user = await firebase.auth().linkWithCredential(cred); // fires `firebase.auth().onAuthStateChanged()`
    // const anonymousUser = this.props.appActions.firebase.anonymousUser;
    const anonymousUser = null;
    const realId = user.uid || 'no_real_user_id_found';

    if (anonymousUser) { 
      await this.props.appActions.firebase.firestoreUsersUpdateWithUser(anonymousUser, { realId });
      console.error("signInFailedDueToAnonUpgradeMergeConflict: delete anon user uid: ", anonId);
      await anonymousUser.delete();
    }
  }
  
  loginFailed(err) {
    // This is typically never called by Firebase UI because the sign-in flow is handled as a popup.
    // We're including this code from React Studio as documentation for other plugins.
    sessionStorage.setItem('loginStatus_login', 'failed');
    this.props.appActions.goToScreen('n404', { errorText: ''+err });
  }

  render() {
      return (
        <div id="firebaseui-auth-container"></div>
      )
  }
}
