import React, { Component } from 'react';
import '../../App.scss';

export default class HeroProfile extends Component {

  // Properties used by this component:
  // profileBio, displayName, avatar

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    const style_elRoundimage = {
      backgroundImage: 'url('+(this.props.object.avatar)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      borderRadius: '50.0%',
     };

     const style_elSquareimage = {
      backgroundImage: 'url('+(this.props.object.avatar)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      borderRadius: '0',
     };
    
    const value_displayNameText = this.props.object.displayName;
    
    const style_elDisplayNameText = {
      fontSize: 17.1,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const value_bioText = this.props.object.profileBio;
    
    const style_elBioText = {
      fontSize: 13.3,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    return (
      <div className="HeroProfile">
        <div className="layoutFlow">
          { this.props.object.avatar && this.props.object.product === "giving"
            ?
              ( 
                <div className="elRoundimage">
                  <div style={style_elSquareimage} />
                </div>
              )
            :  this.props.object.avatar &&
              ( 
                <div className="elRoundimage">
                  <div style={style_elRoundimage} />
                </div>
              )
          }
          <div className="elDisplayNameText">
            <div className="systemFontBold" style={style_elDisplayNameText}>
              <div>{value_displayNameText !== undefined ? value_displayNameText : (<span className="propValueMissing">{this.props.locStrings.heroprofile_text_886418}</span>)}</div>
            </div>
          </div>
          
          <div className="elBioText">
            <div className="systemFontRegular" style={style_elBioText}>
              <div>{value_bioText !== undefined ? value_bioText : (<span className="propValueMissing">{this.props.locStrings.heroprofile_displaynametextcopy_408941}</span>)}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
