import firebase from 'firebase/app';
const isLocalHost = window.location.hostname === 'localhost';

export default class AdminAllClientsModel {

  constructor({ viewModel, includeTestData, limit }) {
    this.viewModel = viewModel;
    this.clients = [];
    this.clientsById = {};
    this.includeTestData = includeTestData || isLocalHost; // MARK: Show test and live accounts on lcoalhost
    this.limit = limit || 100;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    this.viewModel = null;
    if (this.observer) {
      this.observer();
      this.observer = null;
    }

    this.clients = [];
    this.clientsById = {};
  }

  waitForClientsData = () => {

    if (this.observer) {
      this.observer();
      this.observer = null;
    }

    this.clients = [];
    this.clientsById = {};

    const db = firebase.firestore();
    let query = db.collection('clients').where('type', '!=', 'team').orderBy('type').orderBy('updatedAt', 'desc');
    query = query.limit(this.limit);

    this.observer = query.onSnapshot(querySnapshot => {
      if (querySnapshot.empty) {
        this.zeroElementsFound();
      }

      querySnapshot.docChanges().reverse().forEach(
        change => {
          if (change.type === 'added' || change.type === 'modified') {
            const data = change.doc.data();
            const id = change.doc.id;
            const full_data = { ...data, id };
            this.clientsById[id] = full_data;
            this.updateElementWithId(id, full_data);
          }

          if (change.type === 'removed') {
            const id = change.doc.id;
            this.clientsById[id] = null;
            this.deleteElementWithId(id);
          }
        }
      );
    }, err => {
      console.log(`Encountered a withdrawal group model error: ${err}`);
      this.zeroElementsFound();
    });

  }

  async updateElementWithId(id, element) {
    let clients = this.clients.filter(e => e.id !== id);
    clients = [element, ...clients];
    this.clients = clients;
    if (this.viewModel) this.viewModel.updatedClientsDataSource(this.clients, id);
  }

  async deleteElementWithId(id) {
    let clients = this.clients.filter(e => e.id !== id);
    this.clients = clients;
    if (this.viewModel) this.viewModel.updatedClientsDataSource(this.clients, id);
  }

  async zeroElementsFound() {
    this.clients = [];
    if (this.viewModel) this.viewModel.updatedClientsDataSource(this.clients);
  }

}
