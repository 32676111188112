import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Typography, PageHeader, Tag , Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import Spinner from '../../../components/Spinner';
import StrikeTemplateTagsModel from '../../../models/StrikeTemplateTagsModel';
import StrikeTemplateTagModel from '../../../models/StrikeTemplateTagModel';
import { StrikeTagsList } from '../../../components/client/TagsComponents';
import { TagSearchCard } from '../../../components/admin/AdminTemplateSearchCard';

const { Paragraph } = Typography;

export default class AdminTemplateTagsScreen extends Component {
  constructor(props) {
    super(props);

    const issuerId = this.props.match.params.issuerId;
    const isAdmin = !!props.isAdmin;

    this.model = new StrikeTemplateTagsModel({ 
      viewModel: this, 
      issuerId,
    });

    this.state = {
      tags: [],
      loading: false,
      loadingTags: false,
      mode: 'viewing',
      issuerId,
      searchParams : {}
    };
  }

  componentDidMount() {
    //this.model.fetchAllTagsData();
  }

  componentWillUnmount() {
    if(this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || false;
  }

  updatedTagsDataSource = (tags) => {
      
    this.setState(
      (state) => {
        return {
          tags,
          loadingTags: false,
        }
      }
    );

  }

  changeMode = (mode) => {
    if (mode === 'viewing') {
      return 'editing'
    } else if (mode === 'editing') {
      return 'saving'
    } else {
      return 'viewing';
    }
  }


  onClickEdit = () => {
    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    }
    );
  }

  doneSaving = () => {

    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    }
    );

    this.model.waitForClientData();
  }

  onClickAdd = () => {
    this.setState((state) => {
      return {
        mode: 'adding',
        isModalVisible: true,
      }
    }
    );
  }

  onClickCancel = () => {
    console.log(`onClickCancel`);

    this.setState((state) => {
      return {
        mode: 'viewing',
        isModalVisible: false,
      }
    }
    );
  }

  getButtons = () => {
    if (this.state.mode === 'viewing') {
      return [
        <Button key="1"  type="text" shape='circle' icon={<PlusCircleOutlined style={{ fontSize: '28px', color :'#070'}}/>} onClick={this.onClickAdd} />
      ]
    } else if (this.state.mode === 'adding') {
      return [
        <Button key="1" type="danger" onClick={this.onClickCancel}>
          Cancel
        </Button>,
      ];
    } else {
      return [];
    }
  }

  onEditTag = async (tag, updatedTagData) => {
    const strikeTemplateTagModel = new StrikeTemplateTagModel({ tagId: tag.id, issuerId: this.state.issuerId });
    console.log('onEditTag ', updatedTagData);
    await strikeTemplateTagModel.updateTagWithData(updatedTagData);
  } 

  
  // Restart on change of Search Partameters
  onUpdateSearchParameters = async (searchParams) => {
    console.log(searchParams);

    this.setState({ 
      tags: [],
      loading: false,
      loadingTags: true,
      ...searchParams
    });

    this.model.restartSearch({ ...this.state, ...searchParams });
  } 


  render() {
    if (this.state.mode === 'viewing' && !this.state.loading ) {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Template Tags`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'adding' ? null: <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
          <TagSearchCard 
            intent={this.onUpdateSearchParameters}
            warning={this.model ? this.model.warning : null}
          />
          <Spinner condition={() => { return this.isLoading() }} />
          <StrikeTagsList
            condition={() => { return !this.state.loadingTags }}
            data={this.state.tags}
            onEditTag={this.onEditTag}
            isTagTemplateListing={true}
            {...this.props}
          />
        </div>
      );
    } else if (!this.state.loading) {
      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`Your Strikepay Tags`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
          <Paragraph>Add a Template Tag</Paragraph>
        </div>
      );
    } else {
      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`Template Tags`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
        </div>
      );
    }


  }
}

