import React, { Component } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import './accountProfileLayout.css';
import { Layout, Typography } from 'antd';
import { LinkContainer } from 'react-router-bootstrap'
import { UserOutlined, BankOutlined, TeamOutlined, LogoutOutlined } from '@ant-design/icons';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import firebase from 'firebase/app';
import { Redirect } from 'react-router-dom';

import {ccyIsoCodeToSymbolMap} from '../../utils/CurrencyUtils';
import StrikeFooter from '../../components/StrikeFooter';
import { getMaxAccountCcyTotal } from '../../utils/AccountCalculations';
import StrikeClientModel from '../../models/StrikeClientModel';
import StrikeAccountTransactionsModel from '../../models/StrikeAccountTransactionsModel';
import StrikeTeamModel from '../../models/StrikeTeamModel';
import placeholderImage from '../../images/placeholder_avatar.png';
import img_StrikeLogoLg from '../../images/strikepay_Mark_url_RGB_White.png';
import img_StrikeLogoSm from '../../images/strikepay_Mark_url_RGB_White_small.png';

const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;

const ccy = ccyIsoCodeToSymbolMap;

export default class TeamLayout extends Component {

  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    const teamId = this.props.match.params.teamId;

    this.baseURLs = props.baseURLs || {
      manage: '/client/manage',
      login: '/client/login'
    };

    this.clientModel = new StrikeClientModel({
      onClientFound: this.foundClient,
      onClientNotFound: this.clientNotFound,
      viewModel: this,
      clientId,
      isAdmin: false,
    });

    this.accountsModel = new StrikeAccountTransactionsModel({
      callback: this.updatedDataSource,
      viewModel: this,
      clientId,
      excludeTransactions: false,
    });

    this.teamModel = new StrikeTeamModel({
      teamId,
      onUpdate: this.onUpdateTeamDetails,
    });


    this.state = {
      clientId: clientId,
      teamId: teamId,
      triggeredLogOut: false,
      isUseDashboard: false,
    }
  }

  componentDidMount() {
    this.clientModel.waitForClientData();
  }

  componentWillUnmount() {
    if(this.clientModel) this.clientModel.stop();
  }
  
  foundClient = async (client) => {

    this.setState(
      (state) => {
        return {
          clientNotFound: false,
          client,
          isUseDashboard: false,
          loadingDetails: {
            client: false,
          }
        }
      }
    );

    this.accountsModel.waitForAccountsData();
    this.teamModel.waitForTeam();
  }

  clientNotFound = () => {
    console.log("Client not found.");

    // This layout is also used for the claim flow and we want to avoid this issue at this check
    if (this.props.match.path !== "/client/claim/:tagId") {
      this.setState({ 
        clientNotFound: true 
      });
    }
  }

  renderRedirectToLogin = () => {
    let toRoute = `${this.baseURLs.login}`;

    if (this.state.triggeredLogOut === false && this.props.match.path !== "/client/manage/:clientId" && this.props.match.path !== "/client/claim/:tagId") {
      const fullPath = this.props.match.url;
      const remainingRoute = fullPath.split(`${this.state.clientId}/`)[1];

      toRoute += `?redirectRoute=${remainingRoute}`;
    }

    return <Redirect to={toRoute} />
  }

  logOut = async () => {
    this.setState({triggeredLogOut: true});
    await firebase.auth().signOut();
  }

  updatedDataSource = async (txns) => {
    const accounts = this.accountsModel.accounts;

    this.setState(
      (state) => {
        return {
          accounts,
        }
      }
    );
  }

  onUpdateTeamDetails = async (team) => {
    this.setState(
      (state) => {
        return {
          team,
        }
      }
    );
  }
  
  render() {
    const clientId = this.props.match.params.clientId;
    const teamId = this.props.match.params.teamId;
    const displayName = this.state.client ? this.state.client.displayName : "Your Profile";
    const avatarSrc = this.state.client && this.state.client.avatar ? this.state.client.avatar : placeholderImage;
    const accounts = (this.state.accounts && this.state.accounts.length > 0) ? this.state.accounts : null;
    const mainBalance = accounts ? getMaxAccountCcyTotal(accounts) : null;
    const balance = mainBalance ? (ccy[mainBalance.currency] + mainBalance.decimalBalance.toFixed(2)) : null;
    const isUseDashboard = this.state.isUseDashboard;
    const logoLink = `/client/manage/${clientId}`;

    const teamHomeLink = `/client/manage/${clientId}/teams/${teamId}`;
    const clientPageLink = `/client/manage/${clientId}`;

    if (this.state.clientNotFound) return this.renderRedirectToLogin();

    return (
      <div className='team-layout'>
        <Navbar bg="primary" variant="dark" expand="lg" collapseOnSelect>
          <Navbar.Brand href={logoLink} id="navbar-logo">
            <img
              alt="Strikepay"
              src={img_StrikeLogoSm}
              height="44"
              className="align-top d-lg-none mr-auto"
            />
            <img
              alt="Strikepay"
              src={img_StrikeLogoLg}
              height="44"
              className="align-top d-none d-lg-block mr-auto"
            />
          </Navbar.Brand>

          {this.state.team && (
            <div id="navbar-team-title" className="d-flex justify-content-center mx-auto">
              <Nav.Link href={teamHomeLink} className="navbar-nav mx-auto text-center">
                <Text level={2} style={{ color: 'white', marginTop: '8px' }} ellipsis={true}>{this.state.team.teamName.substring(0, 18)}</Text>
              </Nav.Link>
            </div>
          )}

          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <img className="rounded-circle"
              width="40" height="40" 
              src={avatarSrc} 
              alt={displayName}
            />
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <LinkContainer to={clientPageLink}>
                <Nav.Link className="d-lg-none"><UserOutlined /><span>{displayName}</span></Nav.Link>
              </LinkContainer>
              <Nav.Link onClick={ this.logOut } className="d-lg-none"><LogoutOutlined /> Log out</Nav.Link>

              <NavDropdown title={
                  <img className="rounded-circle"
                      width="40" height="40" 
                      src={avatarSrc} 
                      alt={displayName}
                  />
                }
                id="basic-nav-dropdown" alignRight className="d-none d-lg-block">
                <LinkContainer to={clientPageLink}>
                  <NavDropdown.Item><UserOutlined /><span> Profile</span></NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item onClick={ this.logOut }><LogoutOutlined /> Log out</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <Container fluid>
          <Content style={{ padding: '0 0 0 0', margin: '0 0 0 0' }}>
            <div className="site-layout-content">
              {this.props.children}
            </div>
          </Content>
          <StrikeFooter locStrings={this.props.locStrings} />
        </Container>
      </div>
    );
  }
}
