import React, { Component } from 'react';
import { PageHeader } from 'antd';
import firebase from 'firebase/app';

import HeroProfile from '../../../components/customer/HeroProfile';
import Redirect404 from '../../../components/Redirect404';
import QRImageView from '../../../components/customer/QRImageView';
import CenteredSpinner from '../../../components/CenteredSpinner';

import '../../../App.scss';
import 'antd/dist/antd.css';


export default class QRTipmeScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, rating, countOfTips, averageRating, messageText, values, topLogo, profileBio, displayName, avatar, selectedValueByIndex, bottomLogo

  constructor(props) {
    super(props);

    this.state = {
      field: '',
      loading: true,
    };
  }

  async componentDidMount() {
    let tagId = this.props.match.params.tagId;
    let db = firebase.firestore();
    const doc = await db.collection('tags').doc(tagId).get();

    const tag = doc.data();

    this.setState({
      tag: tag,
      tagId: tagId,
      loading: false,
    });
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  textInputChanged_field = (event) => {
    this.setState({ field: event.target.value });
  }

  getShowRunningTotal = () => {
    const displayOptions = this.state.tag.displayOptions || {
      showRunningTotal: false,
    };
    return displayOptions.showRunningTotal || false;
  }

  // MARK: render()
  renderQR = () => {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out ' + this.props.transitionId;
    }
    if (!this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    const style_elBackground = {
      width: '100%',
      height: '100vh',
    };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
    };
    const style_elCard_outer = {
      backgroundColor: 'white',
      borderRadius: '8.0px',
      overflow: 'visible',
    };

    const value_image = this.state.tag.topLogo;

    const style_elImage = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
      maxHeight: '64px',
      marginTop: '10px',
      marginBottom: '10px',
      width: '100%',
    };

    const style_elBannerText = {
      fontSize: 15.2,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
    };

    const style_elQRText = {
      fontSize: 15.2,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingTop: '20px',
    };

    const style_elTestText = {
      fontSize: 12,
      fontWeight: "bold",
      color: `#000`,
      background: 'rgba(255, 0, 152, 0.07)',
      border: '1px solid #FF0098',
      textAlign: 'center',
      width: "100%",
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '10px',
      paddingTop: '5px',
      paddingBottom: '5px',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      maxWidth: '260px',
    };

    const style_elFooterContainerCard_outer = {
      padding: '4.0px',
      borderRadius: '8.0px',
      overflow: 'visible',
    };

    const {
      is_test,
      averageRating,
      countOfTips,
      rating,
    } = this.state.tag;

    const shouldShowQRCode = true;
    const shouldShowHeroPanel = true;
    const shouldShowAvgRating = !!averageRating;
    const shouldShowTipCount = !!countOfTips;
    const shouldShowRatingHistory = (shouldShowAvgRating && shouldShowTipCount);
    const shouldShowRatingStars = !!rating;
    const shouldShowRatingPanel = false;
    const isTest = !!(is_test);

    const shouldShowRunningTotal = this.getShowRunningTotal();

    return (
      <div className="AppScreen TagDetailScreen" style={baseStyle}>
        <PageHeader
          ghost={false}
          title={`Scan QR to Tip`}
          onBack={(() => window.history.back())}
        />
        {
          (isTest) && (
            <div className="elBannerText">
              <div className="systemFontBold" style={style_elTestText}><span>PREVIEW MODE - NOT CHARGED</span></div>
            </div>
          )
        }

        { (!this.state.loading && shouldShowHeroPanel) ?
          (<div className="hasNestedComps elHeroProfile">
            <div>
              <HeroProfile {...this.props} ref={(el) => this._elHeroProfile = el} object={this.state.tag} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>)
          : null
        }

        { (!this.state.loading && shouldShowQRCode) ?
          (<div className="hasNestedComps elQRImageView">
            <div className="elSquareimage">
              <QRImageView {...this.props} tag={this.state.tag} tagId={this.state.tagId} ref={(el) => this._elQRImageView = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>)
          : null
        }

        {
          (!this.state.loading && shouldShowQRCode) && (
            <div className="elBannerText">
              <div className="systemFontBold" style={style_elQRText}><span>Use phone camera to scan QR code</span></div>
            </div>
          )
        }

      </div>
    );
  }


  render() {
    const loading = this.state.loading;

    if (loading) {
      return <CenteredSpinner loading={loading} noLayout={true} />
    } else if (!loading && this.state.tag) {
      return this.renderQR();
    } else {
      return <Redirect404 fromPath={this.props.location ? this.props.location.pathname : null} reason={"qr-tip-me-not-found"} />
    }
  }

}
