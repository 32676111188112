import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Typography, PageHeader, Tag , Button, List } from 'antd';
import { PlusCircleOutlined , QrcodeOutlined, ShareAltOutlined, CopyOutlined } from '@ant-design/icons';
import firebase from 'firebase/app';

import Spinner from '../../../components/Spinner';
import StrikeClientModel from '../../../models/StrikeClientModel';
import { TagsListItemDetail } from '../../../components/client/TagsComponents';
import { ShowScanTagDialog } from '../../../components/client/AccountProfileComponents';
import StrikeTeamTagModel from '../../../models/StrikeTeamTagModel';
import ProductSwitcher from '../../../utils/ProductSwitcher';

const short = require('short-uuid');
const { Paragraph } = Typography;

export default class TeamTagEditScreen extends Component {
  constructor(props) {
    super(props);
    
    const clientId = this.props.match.params.clientId;
    const tagId = this.props.match.params.tagId;
    const isAdmin = !!props.isAdmin;
    this.clientModel = new StrikeClientModel({ viewModel: this, clientId, isAdmin });
    this.model = new StrikeTeamTagModel({ viewModel: this, tagId });

    this.uuids = {};

    const isLocalHost = window.location.hostname === 'localhost';
    this.urlBase =  isLocalHost ? 'localhost:3000' : `https://app.strikepay.co`;
    
    this.state = {
      client: null,
      clientId,
      tagId,
      isAdmin,
      loading: true,
      loadingTags: true,
      mode: 'viewing',
    };
  }

  async componentDidMount() {
    this.clientModel.waitForClientData();
    this.model.fetchTagData();
  }

  componentWillUnmount() {
    if(this.clientModel) this.clientModel.stop();
    if(this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || false;
  }

  updatedTagDataSource = async (tag) => {
    this.setState({ tag });
  }

  foundClient = async (client) => {
    this.setState(
      (state) => {
        return {
          client,
          loading: false,
          loadingTags: true,
        }
      }
    );
  }

  clientNotFound = () => {
    this.setState(
      (state) => {
        return {
          loading: false,
          clientNotFound: true,
        }
      }
    );
  }

  changeMode = (mode) => {
    if (mode === 'viewing') {
      return 'editing'
    } else if (mode === 'editing') {
      return 'saving'
    } else {
      return 'viewing';
    }
  }


  onClickEdit = () => {
    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    }
    );
  }

  doneSaving = () => {
    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    }
    );

    this.model.waitForClientData();
  }

  internalSave = async (data) => {
    await this.model.commit(data);
    this.doneSaving();
  }


  onClickAdd = () => {
    this.setState((state) => {
      return {
        mode: 'adding',
        isModalVisible: true,
      }
    }
    );
  }

  onClickCancel = () => {
    console.log(`onClickCancel`);

    this.setState((state) => {
      return {
        mode: 'viewing',
        isModalVisible: false,
      }
    }
    );
  }

  uuid = () => {
      const uuid = short.generate();
      return uuid;
  }    

  shareLink = (tag, queryParam) => {
    const iUUID = this.uuids[tag.id] || this.uuid();
    this.uuids[tag.id] = iUUID;
    return `tags/${this.state.tagId}?${queryParam}=${iUUID}`;
  }

  fullURIForShare = (tag, queryParam) => {
    if(!this.state.isAdmin) {
        const uri = this.shareLink(tag, queryParam);
        return `${this.urlBase}/${uri}`;
    } else {
        return (tag.templateTagId && tag.tagUid)
        ? `${this.urlBase}/tags/${tag.templateTagId}?uid=${tag.tagUid}`
        : `${this.urlBase}/tags/${tag.id}`;
    }
  };

  fullURLForQR = (tag) => {
    return `${this.urlBase}/qr/${this.state.tagId}`;
  };

  duplicateTemplateTag = async (templateTag) => {
    const duplicateId = window.prompt("Please enter the name/id to give the duplicated template tag");

    const db = firebase.firestore();

    const issuerId = "strike";
    const templateTagDoc = await db.collection('issuers').doc(issuerId).collection("template_tags").doc(duplicateId).get();
    
    if (templateTagDoc.exists) {
      window.alert("Error: This template tag name/id already exists. Please try again with a different name/id");
      return;
    }

    if (templateTag.displayName) {
        templateTag.displayName = `Duplicate of ${templateTag.displayName}`;
    } else {
        templateTag.displayName = `Duplicate of ${templateTag.id}`;
    }

    // Template tag obj from client has added id, we want to remove before saving
    delete templateTag.id;
    
    await db.collection('issuers').doc(issuerId).collection("template_tags").doc(duplicateId).set({
        ...templateTag,
        modified: false,
        createdAt: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now(),
    });
  }

  onClickShare = async (tag, queryParam) => {
      const displayName = tag.displayName;
      const placeOfWork = tag.placeOfWork || 'Strike';
      const product = tag.product;

      const productSwitcher = new ProductSwitcher({ locStrings: this.props.locStrings });
      const productMap = productSwitcher.getProductMap(product);

      const shareText = `${productMap.action} ${displayName} with ${placeOfWork}`;

      console.log(this.fullURIForShare(tag, queryParam));

      const shareData = {
          title: displayName,
          text: shareText,
          url: `${this.fullURIForShare(tag, queryParam)}`,
      }

      try {
          await navigator.share(shareData)
      } catch (err) {
          console.log(`Error: ${shareData.url}\n` + err);
      }
  }

  getButtons = () => {
    if (this.state.mode === 'viewing') {
      return [
        <Button key="1" type="text" shape='circle' icon={<PlusCircleOutlined style={{ fontSize: '28px', color :'#070'}}/>} onClick={this.onClickAdd} />
      ]
    } else if (this.state.mode === 'adding') {
      return [
        <Button key="1" type="danger" onClick={this.onClickCancel}>
          Cancel
        </Button>,
      ];
    } else {
      return [];
    }
  }

  onEditTag = async (tag, updatedTagData ) => {
    const strikeTeamTagModel = new StrikeTeamTagModel({ tagId: tag.id });
    return await strikeTeamTagModel.updateTagWithData(updatedTagData);
  } 

  tagActions = () => {
    const buttonStyle = { marginLeft: 'auto', marginRight: 'auto', width: `100%`, marginBottom: '10px' };

    const actions = [
        <Button
            icon={<QrcodeOutlined />}
            href={this.state.isAdmin ? null : this.fullURLForQR(this.state.tag)}
            onClick={this.state.isAdmin ? ((e)=> (this.onClickShare(this.state.tag, 'qr'))) : null}
            type="secondary"
            style={buttonStyle} 
        >QR Code</Button>,
        <Button
            icon={<ShareAltOutlined />}
            onClick={((e)=> (this.onClickShare(this.state.tag, 'uid')))}
            type="primary"
            style={buttonStyle} 
        >Share</Button>,  
    ];

    if (this.state.isAdmin) {
        actions.push(
            <Button
                icon={<CopyOutlined />}
                onClick={((e)=> (this.duplicateTemplateTag(this.state.tag)))}
                type="secondary"
                style={buttonStyle} 
            >Duplicate</Button>,  
        );
    }

    return actions;
  }

  handleBack = () => {
    if (this.props.location.state && this.props.location.state.fromTagsList) {
      window.history.back();
    } else {
      this.props.history.push(`/client/manage/${this.state.clientId}`);
    }
  }

  render() {
    if (this.state.mode === 'viewing' && !this.state.loading && this.state.client && this.state.tag) {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Edit Tag`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'adding' ? null: <Tag color="green">Saving...</Tag>)}
            onBack={(() => this.handleBack())}
            extra={this.getButtons()}
          />
          <Spinner condition={() => { return this.isLoading() }} />

          <List
            style={{ textAlign: 'start' }}
            itemLayout="vertical"
            size='default'
            className="centred-action-buttons"
          >
            <List.Item
              key={this.state.tagId}
              actions={this.tagActions()}
            >
              <TagsListItemDetail
                isAdmin={this.state.isAdmin}
                tag={this.state.tag} tagId={this.state.tagId} locStrings={this.props.locStrings} onEditTag={this.onEditTag}
              />
            </List.Item>
          </List>
        </div>
      );
    } else if (!this.state.loading) {
      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`Your Strike Tags`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
          <ShowScanTagDialog 
            visible={this.state.isModalVisible}  
            handleOk={this.onClickCancel} 
            handleCancel={this.onClickCancel} 
            locStrings={this.props.locStrings} 
          />
        </div>
      );
    } else {
      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`Your Strike Tags`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
        </div>
      );
    }
  }
}

