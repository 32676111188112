import React from 'react';
import { Avatar } from 'antd';

import FallbackAvatar from './FallbackAvatar';

const ClientAvatar = (props) => {
    return (
        props.client && props.client.avatar
            ? 
                <Avatar src={props.client.avatar}/>
            :
                <FallbackAvatar />
    );
};

export default ClientAvatar;