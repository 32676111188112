import React from 'react';
import { Button } from 'antd'

import { useFeatureFlagMoneyJar } from '../../models/useFeatureFlagMoneyJar';

const AddPayoutMoneyJarButton = ({ clientId, onClick, text, subText, disabled }) => {
  const isLocalHost = window.location.hostname === 'localhost';
  const isTest = isLocalHost;
  const shouldUseMoneyJar = useFeatureFlagMoneyJar(clientId, isTest);

  const buttonText = text || `${shouldUseMoneyJar ? 'Get MoneyJar' : 'Not available'}`;
  const buttonSubText = subText || 'Clicking this button will bring you to the app store to install MoneyJar.';

  return (
    <div>
      <div style={{ margin: '5px 50px 5px 50px', textAlign: 'center', fontWeight: 'lighter' }}>
        <Button 
          type='primary' 
          style={{ backgroundColor: 'green', borderColor: 'green' }} 
          onClick={onClick}
          disabled={disabled}
        >
          {buttonText}
        </Button>
        <p>{buttonSubText}</p>
      </div>
    </div>
  );
};

export default AddPayoutMoneyJarButton;
