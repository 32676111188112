import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Typography, PageHeader, Tag } from 'antd';

import Spinner from '../../../components/Spinner';
import StrikeClientModel from '../../../models/StrikeClientModel';
import StrikeClientSkusModel from '../../../models/StrikeClientSkusModel';
import StrikeClientOrdersModel from '../../../models/StrikeClientOrdersModel';
import SkuCardView from '../../../components/client/SkuCardView';
import { DeliveryAddressForm } from '../../../components/client/DeliveryAddressForm';
import TrackerService from '../../../utils/TrackerService';

const { Paragraph } = Typography;



// /client/manage/profile
export default class SkuProductDisplayPage extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    const skuId = this.props.match.params.skuId;
    const isAdmin = !!props.isAdmin;
    this.clientModel = new StrikeClientModel({ viewModel: this, clientId, isAdmin });
    this.skusModel = new StrikeClientSkusModel({ 
      callback: this.updatedSkus,
      clientId,
    });
    this.ordersModel = new StrikeClientOrdersModel({ 
      callback: this.updatedOrders,
      clientId,
      skuId,
    });

    this.state = {
      clientId,
      client: null,
      sku: null,
      skuId,
      skus: null,
      loading: true,
      loadingSkus: true,
      mode: 'viewing',
      orderComplete: false,
      orderId: null,
      order: null,
      shipping : null,
      step: 'loading',
    };
  }

  componentDidMount() {
    this.clientModel.waitForClientData();
  }

  componentWillUnmount() {
    if(this.clientModel) this.clientModel.stop();
    if(this.skusModel) this.skusModel.stop();
    if(this.ordersModel) this.ordersModel.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || this.state.loadingSkus || false;
  }


  foundClient = async (client) => {
    this.setState(
      (state) => {
        return {
          client,
          loading: false,
          loadingSkus: true,
          shipping: {
            name: client.displayName,
            address: null
          },
          step: 'client',
        }
      }
    );

    this.skusModel.fetchAllSkusData();
  }

  updatedSkus = async (skus) => {
    let sku = null;  
    const skuId = this.state.skuId;

    if(skus) {
      skus.map(  
        (v, i) => {
          if(v.id === skuId) {
            sku = v;
          }
        }
      );
    }

    this.setState(
      (state) => {
        return {
          skus,
          sku,
          loadingSkus: false,
          step: state.step === 'client' ? 'pdp' : state.step
        }
      }
    );

  }

  updatedOrders = async (orders) => {
    // do nothing
  }


  clientNotFound = () => {
    this.setState(
      (state) => {
        return {
          loading: false,
          clientNotFound: true,
        }
      }
    );
  }

  changeMode = (mode) => {
    if (mode === 'viewing') {
      return 'editing'
    } else if (mode === 'editing') {
      return 'saving'
    } else {
      return 'viewing';
    }
  }
  
  getButtons = () => {
      return [];
  }

  onSelectOpenPDP = () => {
    console.log('onSelectSku', this.state.sku, this.state.skuIndex);
  }

  onSelectSKU = (sku, skuIndex) => {
    console.log('onSelectSku', sku);
  }

  onClickShipping = async () => {
    console.log('onSelectShipping', this.state.sku);
    let order = this.state.order;
    const skuId = this.state.skuId;
    const clientId = this.state.clientId;
    const sku = this.state.sku;

    if(skuId && clientId && order === null) {
      order = await this.ordersModel.createOrderForSkuWithClientId({ skuId, clientId })

      this.trackOrderState('order_enter_address', {
        skuId,
        clientId,
        orderId: order.orderId || "",
      });  
    }

    this.setState(
      {
        showAddressForm: order.orderId !== null,
        orderId: order ? order.orderId : null,
        order,
        step: 'address'
      }
    );
  }

  onSaveAddress = async (shipping) => {
    console.log('onSaveAddress', shipping);
    const sku = this.state.sku;

    const result = await this.ordersModel.updateOrderShippingForClientId({ 
      orderId: this.state.orderId, 
      clientId: this.state.clientId, 
      trackingNumber: "", 
      shipping
    });

    this.trackOrderState('order_completed_address', {
      skuId: this.state.skuId,
      clientId: this.state.clientId,
      orderId: this.state.orderId, 
      name: this.state.shipping.name, 
    });  
    
    this.setState(
      {
        shipping,
        showAddressForm: false,
        step: 'finish'
      }
    );
  }

  onConfirmOrder = async () => {
    console.log('onConfirmOrder');
    const sku = this.state.sku;

    //  order status -> 'ordered'

    const resultOrderUpdate = await this.ordersModel.confirmOrderForClientId({ 
      orderId: this.state.orderId, 
      clientId: this.state.clientId, 
      trackingNumber: "", 
    });

    const resultSkuUpdate = await this.skusModel.updateSkuWithOrderDetailsForClientId({ 
      clientId: this.state.clientId, 
      skuId: this.state.skuId, 
      quantityOrders: 1, 
      quantityOffers: 1, 
    });

    this.trackOrderState('order_confirmed', {
      skuId: this.state.skuId,
      clientId: this.state.clientId,
      orderId: this.state.orderId,
      name: this.state.shipping.name,
    });  

    this.trackOrderState('Purchase', {
      skuId: this.state.skuId,
      clientId: this.state.clientId,
      orderId: this.state.orderId,
      name: this.state.shipping.name,
      amount: 0,
    });  

    this.setState(
      {
        orderComplete: true,
        step: 'thanks'
      }
    );

  }

  onBack = () => {
    if(this.state.step === 'pdp' || this.state.step === 'thanks') 
      window.history.back();
    else if(this.state.step === 'finish')
      this.setState({
        step: 'address'
      }); 
    else if(this.state.step === 'address')
    this.setState({
      step: 'pdp'
    }); 

  }

  trackOrderState = (eventId, data) => {
    const isTest = window.location.hostname === 'localhost';
  
    const tracker = new TrackerService({
      isTest,
    });

    tracker.mixpanelTrack(eventId, { ...data });  
    tracker.googleTrack( eventId, {...data });    

  }

  

  render() {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Complete Your Order`}
            onBack={this.onBack}
            extra={this.getButtons()}
          />
          <Spinner condition={() => { return this.isLoading() }} />
          {  !this.isLoading() && (this.state.sku) && this.state.step === 'pdp' &&
            <SkuCardView
              sku={this.state.sku}
              shipping={this.state.shipping}
              pdp
              onSelectSku={this.onSelectSKU} 
              onOpenPDP={this.onSelectOpenPDP}
              onClickShipping={this.onClickShipping}
            />
          }
          {
            !this.isLoading() && (this.state.client) && this.state.step === 'address' &&
            <DeliveryAddressForm 
              shipping={this.state.shipping}
              callback={this.onSaveAddress}
              countryCodes={this.state.sku ? Object.keys(this.state.sku.deliveryOptions): null}
            />
          }
          {  !this.isLoading() && (this.state.sku) && this.state.step === 'finish' &&
            <SkuCardView
              sku={this.state.sku}
              finish
              shipping={this.state.shipping}
              onSelectSku={this.onSelectSKU} 
              onConfirmOrder={this.onConfirmOrder}
            />
          }
          {  !this.isLoading() && (this.state.sku) && this.state.step === 'thanks' &&
            <SkuCardView
              sku={this.state.sku}
              thanks
              shipping={this.state.shipping}
              onSelectSku={this.onSelectSKU} 
              onReturnToDashoard={(() => window.history.back())}
            />
          }
        </div>
      );

  }
}

