import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { PageHeader } from 'antd';

import Spinner from '../../../components/Spinner';
import StrikeTeamDetailsModel from '../../../models/StrikeTeamDetailsModel';
import { TeamAccountBalancesView, TeamPayoutsList } from '../../../components/client/TeamsComponents';
import StrikeTeamTransfersModel from '../../../models/StrikeTeamTransfersModel';
import StrikeTeamAccountTransactionsModel from '../../../models/StrikeTeamAccountTransactionsModel';


export default class TeamPayoutsScreen extends Component {

    // Properties used by this component:

    constructor(props) {
        super(props);

        const teamId = props.match.params.teamId;
        const clientId = props.match.params.clientId;
        const accountId = props.match.params.accountId;
        const isAdmin = !!props.isAdmin;

        this.isAdmin = isAdmin;
        this.availableAccountId = accountId;
        this.model = new StrikeTeamDetailsModel({viewModel: this, teamId});

        this.state = {
            teamId: teamId,
            clientId: clientId,
            memberClientId: clientId,
            teamDetails: null,
            teamMembersList: null,
            loading: true,
            availableAccountId: accountId,
            accounts: [],
            transactions: []
        };

        this.teamWithdrawalsModel = new StrikeTeamTransfersModel({
            viewModel: this,
            callback: this.setWithdrawalsDataOnPage
        });


        this.teamAccountsModel = new StrikeTeamAccountTransactionsModel({
            viewModel: this
        });

        this.setAccountsDataOnPage = this.setAccountsDataOnPage.bind(this);
        this.setWithdrawalsDataOnPage = this.setWithdrawalsDataOnPage.bind(this);

    }

    componentDidMount() {
        this.model.waitForTeamDetails({fetchTeamClient: true});
    }

    componentWillUnmount() {
        if (this.model) this.model.stop();
        if (this.teamAccountsModel) this.teamAccountsModel.stop();
        if (this.teamWithdrawalsModel) this.teamWithdrawalsModel.stop();
    }

    componentDidUpdate() {
    }

    isLoading = () => {
        return this.state.loading || false;
    }

    setTeamDetailsOnPage = (teamDetails, teamMembersList) => {
        let filteredTeamMember = teamMembersList.filter(member => member.clientId === this.state.memberClientId);
        let sortedMembers = teamMembersList.sort((a, b) => { 
            return a.displayName < b.displayName ? -1 : 1 
        });
        const teamMemberLoggedIn = filteredTeamMember.length > 0 ? filteredTeamMember[0] : null;
        const { roles, permissions } = teamMemberLoggedIn || { roles: [], permissions: {} };
        const isLoggedInMemberAdmin = (roles[0] === 'admin' || permissions.isTeamAdmin || this.isAdmin);

        this.setState({
            teamDetails,
            teamMembersList: sortedMembers,
            teamMemberLoggedIn,
            isLoggedInMemberAdmin,
            loading: false
        });
    }

    setTeamClientDetailsOnPage = (teamClient) => {
        this.setState({
            teamClient
        });

        this.teamAccountsModel.clientId = teamClient.id;
        this.teamAccountsModel.callback = this.setAccountsDataOnPage;
        this.teamAccountsModel.waitForAccountsData();
        this.teamWithdrawalsModel.waitForTransfersData();
    }

    setAccountsDataOnPage = async (txns) => {
        let accounts = this.teamAccountsModel.accounts;
        const transactions = this.teamAccountsModel.transactions;
        if (this.state.availableAccountId) {
            accounts = accounts.filter( account => 
                account.availableAccountId === this.state.availableAccountId
            );
        }
        this.setState(
            (state) => {
                return {
                    clientNotFound: false,
                    accounts,
                    transactions
                }
            }
        );
    }

    setWithdrawalsDataOnPage = async (txns) => {
        const withdrawals = this.teamWithdrawalsModel.withdrawals;
        this.setState(
            (state) => {
                return {
                    withdrawals: withdrawals
                }
            }
        );
    }


    render() {
        if (!this.state.loading && this.state.teamDetails && this.state.teamClient) {
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`${this.state.teamDetails.teamName}`}
                        onBack={(() => window.history.back())}
                    />
                    {(this.state.isLoggedInMemberAdmin ||
                        (this.state.teamMemberLoggedIn && this.state.teamMemberLoggedIn.permissions && this.state.teamMemberLoggedIn.permissions.canSeeTeamBalance))
                    && <TeamAccountBalancesView
                        condition={() => {
                            return !this.state.teamDetails.teamClient
                        }}
                        locStrings={this.props.locStrings}
                        client={this.state.teamClient}
                        clientId={this.state.teamDetails.teamClientId}
                        {...this.state}
                    />}
                    <h2 style={{textDecoration: 'underline'}}>Team Payouts</h2>
                    {(this.state.isLoggedInMemberAdmin ||
                        (this.state.teamMemberLoggedIn && this.state.teamMemberLoggedIn.permissions && this.state.teamMemberLoggedIn.permissions.canSeePayouts))
                    &&<TeamPayoutsList
                        membersList={this.state.teamMembersList}
                        teamDetails={this.state.teamDetails}
                        teamId={this.state.teamId}
                        accounts={this.state.accounts}
                        currentClientId={this.state.clientId}
                    />}
                </div>
            );
        } else {
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`Team Payouts`}
                        style={{fontSize: '42px', fontWeight: 'bold'}}
                        onBack={(() => window.history.back())}
                    />
                    <Spinner condition={() => { return this.isLoading() }} />
                </div>
            );
        }
    }
}

