import React, { useState, useEffect } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import {
    Space,
    Typography,
    Row, Col,
    Divider,
} from 'antd';
import moment from 'moment';

import { useTeamsList } from '../../models/useTeamsList';

import { TeamsGrid } from './TeamDashboardComponents';

const { Text } = Typography;


export const TimeSinceFirestoreDate = ({ dateAt, ...rest }) => {
    return <Text ellipsis {...rest} >{`${moment(dateAt.toMillis()).fromNow()}`}</Text>;
}

export const LoadingText = ({ ...rest }) => {
    return <Text ellipsis {...rest} >{`Loading...`}</Text>;
}


export const LoadableTeamsGrid = ({
    clientId,
    client,
    condition,
    isAdmin,
}) => {

    const teams = useTeamsList(clientId);

    return (condition && teams && teams.length > 0 &&
        <>
            <Divider>Active Teams</Divider>
            <Row align='top' justify='center'>
                <Col style={{ width: '100%' }} span={24}>
                    <TeamsGrid
                        clientId={clientId}
                        client={client}
                        condition={condition}
                        teams={teams}
                        isAdmin={isAdmin}
                        prefixPath={`/${clientId}`}
                        sortBy={'date'}
                        compact={true}
                    />
                </Col>
            </Row>
        </>
    );

};

