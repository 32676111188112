import firebase from 'firebase/app';
import moment from 'moment';

import ClientAvailableTransactionsModel from './ClientAvailableTransactionsModel';

const isLocalHost = window.location.hostname === 'localhost';

export default class ClientAvailableTransactionsGroupedModel {

  constructor({ clientId, callback, includeTest, startDate, endDate, defaultInterval, defaultUnits }) {
    this.callback = callback;
    this.clientId = clientId;
    this.includeTest = includeTest || isLocalHost; // MARK: Show test and live accounts on lcoalhost
    this.interval = defaultInterval || 1;
    this.units = defaultUnits || 'day';
    this.dateRange = { startDate, endDate };
    this.model = new ClientAvailableTransactionsModel({ clientId, callbackAll: callback, includeTest });
  }

  stop() {
    this.model.stopHook();
  }

  selectDate = (dates) => {
    const { startDate, endDate } = dates;
    return { 
      startDate,
      endDate,
    };
  }

  sortGroupItems = ({ id, activityAt, items }) => {
    let multiLineItems = [...items];
    multiLineItems = multiLineItems.sort((a, b) => (b.amount - a.amount));
    const { status, currency } = multiLineItems[0];

    if (items.length > 1) {
        let totalAmount = 0;
        multiLineItems.map((v) => (totalAmount += v.amount));
        const totalItem = { amount: totalAmount, currency, status, isTotal: true  };
        multiLineItems.push(totalItem);
    } else if (items.length > 0) {
        multiLineItems[0].isTotal = true;
    }
    return { id, activityAt, items: multiLineItems };
  }

  groupByKeyId = (data) => {
    const groupedData = {  };
    data.map((v) =>{ 
      const keyId = v.keyId;
      if (keyId) {
        const existingItems = groupedData[keyId] ? groupedData[keyId].items : [];
        groupedData[keyId] = { id: keyId, activityAt: v.activityAt, items: [ v, ...existingItems ] };
      }
    });
    const keys = Object.keys(groupedData);

    const activityItems = [];
    keys.map((k) => {
      const sortedGroupedItems = this.sortGroupItems(groupedData[k]);
      activityItems.push(sortedGroupedItems);
    });

    return activityItems.sort((a, b) => (b.activityAt - a.activityAt));
  }

  fetchResult(data) {
    const groupedData = this.groupByKeyId(data);
    if(this.callback) this.callback(groupedData);
    return groupedData;
  }

  fetch() {
    const dates = this.selectDate(this.dateRange);
    this.model.observeAllWithDates(dates, (data) => {
      this.fetchResult(data);
    });
  }

}

