import React, { Component, createContext, useState, useEffect } from 'react';
import firebase from 'firebase/app';

export const UserContext = createContext({ user: null });

export const UserProvider = ( {children} ) => {
  const [user, setUser] = useState( () => firebase.auth().currentUser );

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(setUser);
    return () => {
      unsubscribe();
    }
  }, []);

  return (
    <UserContext.Provider value={{user}}>
      { children }
    </UserContext.Provider>
  )
}