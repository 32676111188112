import React, { Component } from 'react';
import { Typography, PageHeader, Button, Col, Row, Space, Switch } from 'antd';
import { Redirect } from 'react-router-dom';

import StrikeClientRecentActivitiesModel from '../../../models/StrikeClientRecentActivitiesModel';
import StrikeClientModel from '../../../models/StrikeClientModel';
import { DownloadTapsReport, DownloadTransactionsReport, SwitchBigRedCloudExport } from '../../../components/client/AccountProfileComponents';
import { RecentActivitiesTable } from '../../../components/client/RecentActivitiesComponents';
import CenteredSpinner from '../../../components/CenteredSpinner';


import '../../../App.scss';
import 'antd/dist/antd.css';

const { Paragraph, Text } = Typography;


const bigredTextWhen = {
  off: 'Tap for new export format',
  on: 'Use new export format',
};


// /client/manage/profile
export default class AccountTransactionsScreen extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    this.baseURLs = props.baseURLs || {
      manage: '/client/manage',
      login: '/client/login'
    };

    const isAdmin = !!props.isAdmin;

    this.clientModel = new StrikeClientModel({
      onClientFound: this.foundClient,
      onClientNotFound: this.clientNotFound,
      viewModel: this,
      clientId,
      isAdmin,
    });

    this.clientRecentActivitiesModel = new StrikeClientRecentActivitiesModel({ viewModel: this, clientId });

    this.state = {
      clientNotFound: false,
      route: null,
      showTestAccounts: true,
      user: null,
      client: null,
      clientId: clientId,
      accounts: [],
      recentActivities: [],
      withdrawals: [],
      percent: 0,
      loadingDetails: {
        client: true,
        transactions: false,
      },
      showDates: false,
      showDatesCTA: 'Show Dates',
      showBigRedBook: true,
      showBrcCTA: bigredTextWhen.on,
      exportButtonText: 'Export Taps',
      isAdmin,
    };
  }

  componentDidMount() {
    this.clientModel.waitForClientData();
  }

  componentWillUnmount() {
    if (this.clientModel) this.clientModel.stop();
    if (this.clientRecentActivitiesModel) this.clientRecentActivitiesModel.stop();

  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loadingDetails.client || false;
  }

  foundClient = async (client) => {

    this.setState({
      clientNotFound: false,
      client,
      loadingDetails: {
        client: false,
      },
      loadingPauseAction: false,
    });

    // this.clientRecentActivitiesModel.fetchAllActivitiesData();
  }

  clientNotFound = () => {
    this.setState({
      clientNotFound: true
    });
  }

  updatedDataSource = async (txns) => {
    const accounts = this.accountsModel.accounts;
    let transactions = this.accountsModel.transactions;

    this.setState({
      clientNotFound: false,
      accounts,
      transactions,
    });
  }

  updatedRecentActivitiesDataSource = async () => {
    let recentActivities = this.clientRecentActivitiesModel.recentActivities;

    this.setState({
      clientNotFound: false,
      recentActivities
    });
  }

  updatedTapsRange = ({ startDate, endDate }) => {
    this.setState({
      recentActivities: [],
    });
    if (startDate && endDate) {
      this.clientRecentActivitiesModel.fetchAllActivitiesData({ startDate, endDate });
    }
  }

  renderRedirect = () => {
    const { route, subroute } = this.state;
    const clientId = this.props.match.params.clientId;
    if (route && clientId) {
      const fromRoute = `${this.baseURLs.manage}/${clientId}`;
      let toRoute = `${this.baseURLs.manage}/${clientId}/${route}`;
      if (subroute) toRoute = `${toRoute}/${subroute}`;
      return <Redirect push to={toRoute} />
    } else {
      return null;
    }
  }

  render() {
    const redirected = this.renderRedirect();

    if (redirected) {
      return redirected;
    }

    const showDates = async () => {
      this.setState({
        showDatesCTA: this.state.showDates ? 'Show Dates' : 'Show Time Since',
        showDates: !this.state.showDates,
      });
    }

    const toggleExportMode = async (checked) => {
      this.setState({
        showBrcCTA: checked ? bigredTextWhen.on : bigredTextWhen.off,
        showBigRedBook: checked,
      });
    }

    return (
      <div className="accountProfile" style={{height: '100%'}}>
        <Row align='center' direction='vertical' style={{display: 'flex', justifyContent:'space-between'}}>
          <Col>
            <PageHeader
              ghost={false}
              title={`Exports`}
              onBack={(() => window.history.back())}
            />
          </Col>
          {this.state.client && this.state.clientId &&
            <Col>
              { true &&
              <SwitchBigRedCloudExport 
                clientId={this.state.clientId}
                showBrcCTA={this.state.showBrcCTA}
                toggleExportMode={toggleExportMode}
                isAdmin={this.state.isAdmin}
                isChecked={this.state.showBigRedBook}
              />
              }
              {this.state.showBigRedBook &&
                <DownloadTransactionsReport
                  client={this.state.client}
                  clientId={this.state.clientId}
                  updatedTapsRange={this.updatedTapsRange}
                />
              }
              {!this.state.showBigRedBook &&
                <DownloadTapsReport
                  client={this.state.client}
                  clientId={this.state.clientId}
                  updatedTapsRange={this.updatedTapsRange}
                />
              }
            </Col>
          }
        </Row>

        {<CenteredSpinner loading={this.isLoading()} />}

        {(!this.state.loadingDetails.client && this.state.client != null) && (
          <div style={{ padding: '20px', fontWeight: 'bold', paddingBottom: '5px' }}>
            <Button onClick={() => showDates()} type="secondary" size="small">{this.state.showDatesCTA}</Button>
          </div>
        )}

        <RecentActivitiesTable
          condition={() => { return !this.state.loadingDetails.client && this.state.client != null }}
          locStrings={this.props.locStrings}
          client={this.state.client}
          showDates={this.state.showDates}
          isAdmin={this.state.isAdmin}
          {...this.state}
        />
      </div>
    );
  }
}

