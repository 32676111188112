import firebase from 'firebase/app';

import FirebaseSetup from '../FirebaseSetup'

export default class StrikeTemplateTagModel {

  constructor({ viewModel, issuerId, templateTagId }) {
    this.viewModel = viewModel;
    this.issuerId = issuerId;
    this.templateTagId = templateTagId;
    const firebaseSetup = new FirebaseSetup();
    this.currentUser = firebaseSetup.getCurrentUserDataInApp();
  }

  async getTemplateTagData() {
    if (this.templateTagId === null || this.issuerId === null) return null;

    const db = firebase.firestore();
    const query = db.collection('issuers').doc(this.issuerId).collection('template_tags').doc(this.templateTagId);
    this.tag = null;

    this.observerTag = query.onSnapshot(tagDoc => {
      this.tag = null;

      if (tagDoc.exists) {
        const data = tagDoc.data();
        const id = tagDoc.id;
        const full_data = { ...data, id };
        this.tag = full_data;
        this.updateDataSource();
      } else {
        this.tag = null;
        this.updateDataSource();
      }
    }, err => {
      this.tag = null;
      this.updateDataSource();
      console.log(`Tags query error: ${err}`);
    });
  }

  async updateDataSource() {
    this.viewModel.updatedTagDataSource(this.tag);
  }

  async updateTagWithData(data) {
    if (this.templateTagId && data) {
      const db = firebase.firestore();
      const query = db.collection('issuers').doc(this.issuerId).collection('template_tags').doc(this.templateTagId);

      return await query.set({
        ...data,
        modified: false,
        updatedAt:  firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: this.currentUser,
      }, {
        merge: true
      });
    } else {
      return null;
    }
  }

  stop() {
    if (this.observerTag) {
      this.observerTag();
      this.observerTag = null;
    }
  }

}
