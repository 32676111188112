import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import firebase from 'firebase/app';
import qs from 'qs';

import FooterCard from '../../components/customer/FooterCard';
import Redirect404 from '../../components/Redirect404';
import PoweredByStrike from '../../components/customer/PoweredByStrike';
import { ClaimTagComponent } from '../../components/customer/ClaimTagComponents';
import RedirectAndScrollTop from '../../components/client/RedirectAndScrollTop';
import CenteredSpinner from '../../components/CenteredSpinner';

import '../../App.scss';
import 'antd/dist/antd.css';

export default class BeginClaimScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      proceedToClaim: false,
    };
  }

  async componentDidMount() {
    let user = await this.getCurrentUser(firebase.auth());

    if (!user || user.isAnonymous) {
      const result = await firebase.auth().signInAnonymously();
      user = result.user;
    }

    const tagId = this.props.match.params.tagId;

    let db = firebase.firestore();
    const doc = await db.collection('tags').doc(tagId).get();

    const tag = doc.data();

    this.setState({
      tag: tag,
      tagId: tagId,
      loading: false,
      user: user,
      proceedToClaim: false,
      proceedToDashboard: false,
      proceedToSignup: false,
      goBack: false,
    });
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  async getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }

  getProceedToRoute = (intent) => {
    let proceedToRoute = null;
    let returnFromRoute = null;
    let params = {}; 
    let tagId = this.state.tagId;

    const proceedToDashboardRoute = this.makeFullUrlWithRoute('/client/login', params);
    const goBackRoute = '/';

    if(tagId) {
      params = { 
        ...params,
        redirect: `/client/claim/${tagId}`,
      };
    }
    // NOTE: params now includes redirectRoute
    const proceedToClaimRoute = this.makeFullUrlWithRoute('/client/login', params);
    const proceedToSignupRoute = this.makeFullUrlWithRoute('/client/signup', params);

    switch(intent) {
      case 'claim': 
      proceedToRoute = proceedToClaimRoute;
      break;
    case 'signup':
      proceedToRoute = proceedToSignupRoute;
      break;
    case 'signin':
      proceedToRoute = proceedToClaimRoute;      
      break;
    case 'cancel':
      proceedToRoute = goBackRoute;
      break;
    case 'dashboard':
      proceedToRoute = proceedToDashboardRoute;
      returnFromRoute = tagId ? `/tags/${tagId}` : null;
      break;
    default:
      break;
    }

    return { proceedToRoute, returnFromRoute };
  }

  onAction = (intent) => {
    console.log(intent);
    const { proceedToRoute, returnFromRoute } = this.getProceedToRoute(intent);
    this.setState({ proceedToRoute, returnFromRoute });
  }

  makeFullUrlWithRoute = (route, params, isNotEncoded) => {
    const queryParams = [];

    for (let [key, value] of Object.entries(params)) {
      if(value && !isNotEncoded) {
        queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      } 
      if(value && !!isNotEncoded) {
        queryParams.push(`${key}=${value}`);
      } 
    }
    let queryString = queryParams.join('&');

    if(queryString.length > 0) {
      queryString = `?${queryString}`;
    }

    let fullUrl = `${route}${queryString}`
    return fullUrl;
  }

  render() {
    const { locStrings } = this.props;
    const { loading, proceedToRoute, returnFromRoute, tagId, tag, user } = this.state;
    const isTagFound = !loading && tag && tagId && user;
    const isRedirect = isTagFound && proceedToRoute;

    return (
      <>
      { (loading) && 
        <CenteredSpinner loading={loading} noLayout={true} />
      }
      { (!loading && isRedirect) &&
          <Redirect to={proceedToRoute} from={returnFromRoute} />
      }
      { (!loading && !isRedirect && tag && user) &&
          <ClaimTagBody
            loading={loading}
            user={user}
            tagId={tagId}
            tag={tag}
            onAction={this.onAction}
            locStrings={locStrings}
          />
      }
      { (!loading && !isTagFound) &&
        <Redirect404 fromPath={this.props.location ? this.props.location.pathname : null} reason={"begin-claim-failed"} />
      }
      </>
    );
  }

}

export function ClaimTagBody({ tagId, tag, user, loading, onAction, locStrings, ...rest }) {
  const baseStyle = {
    maxWidth: '100%',
    minWidth: '260px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'start'
  };

  return (
    <div className="AppScreen BeginClaimScreen" style={baseStyle}>
        <ClaimTagComponent
          loading={loading}
          user={user}
          tagId={tagId}
          tag={tag}
          onAction={onAction}
          locStrings={locStrings}
        />
    </div>
  );
}
