import firebase from 'firebase/app';

import TrackerService from '../utils/TrackerService';

function getCountryCode(country) {
  switch (country) {
    case 'UK':
      return 'GB';
    default:
      return country;
  }
}

export default class BankAccountsModel {
  constructor({ viewModel, clientId, filter }) {
    this.viewModel = viewModel;
    this.clientId = clientId;
    this.payouts = [];
    this.client = null;
    this.payoutsById = {};
    this.filter = filter;
  }

  getQueryForPayoutsForClient(params) {
    const {
      clientId,
    } = params;

    const db = firebase.firestore();
    return db.collection('clients').doc(clientId).collection('payouts');
  }

  stop() {
    // stop all listeners.,.not sure they get dropped when object drops out of scope.
    if(this.observerPayouts) {
      this.observerPayouts();
      this.observerPayouts = null;
    }
  }

  async fetchAllPayoutsData(isAdmin)  {
    if (this.clientId === null) return;
    const clientId = this.clientId;
    this.stop();
    this.payouts = [];

    const query = this.getQueryForPayoutsForClient({
      clientId,
    });

    this.observerPayouts = query.onSnapshot(querySnapshot => {
      let index = querySnapshot.size;
      this.payouts = [];
      if(index === 0) this.updateDataSource();
      querySnapshot.docChanges().forEach(
        change => {
          if (change.type === 'added' || change.type === 'modified') {
            const payout = change.doc.data();
            const id = change.doc.id;
            const full_payout = { ...payout, id };
            const { hide, country, address } = payout;
            const { country: addrCountry } = address || {};
            if (country) {
              full_payout.country = getCountryCode(country);
            }
            if (address && addrCountry) {
              full_payout.address.country = getCountryCode(addrCountry);
            }
            let isIncluded = (isAdmin || !hide);
            if (this.filter) {
              isIncluded = this.filter(full_payout, isAdmin);
            }
            if (isIncluded) {
              this.payoutsById[id] = full_payout;
            }
            this.updateDataSource();
          }

          if (change.type === 'removed' )  {
            const id = change.doc.id;
            this.payoutsById[id] = null;
            this.updateDataSource();
          }
        }
      );
    }, err => {
      this.payouts = [];
      this.payoutsById = {};
      this.updateDataSource();
      console.log(`Payout query error: ${err}`);
    });


  }

  async updateDataSource() {

    this.payouts = [];

    for(let prop in this.payoutsById) {
      this.payouts.push(this.payoutsById[prop]);  
    }

    this.viewModel.updatedPayoutsDataSource(this.payouts);
  }

  assignProcessorToPayout(payout) {
    const { country, currency } = payout;
    const adjustedPayout = { ...payout };

    switch (currency) {
      case 'USD':
        switch (country) {
          case 'US':
            adjustedPayout.provider = 'stripeus';
            adjustedPayout.type = 'auto';
            break;
        }
        break;
    }
    return adjustedPayout;
  }

  async makeNewPayoutObject(payoutData) {
    if (this.clientId === null) return;
    const clientId = this.clientId;
    const query = this.getQueryForPayoutsForClient({
      clientId,
    });

    const data = this.assignProcessorToPayout(payoutData);
    const payout = await query.add(data);
    const payoutId = payout.id;
    const isLocalHost = window.location.hostname === 'localhost';
    const isTest = isLocalHost;
    const { 
      source, 
      currency, bic, sortCode, routingNumber, isCompany, 
      provider, type, status,
      descriptor,
    } = data;

    const tracker = new TrackerService({
      isTest,
    });

    const analyticsData = {
      clientId,
      payoutId,
      currency,
      source,
      bic, 
      sortCode, 
      routingNumber, 
      isCompany,
      provider, 
      type, 
      status,
      descriptor,
    };

    // console.log(`analyticsData: ${JSON.stringify(analyticsData)}`);

    tracker.mixpanelTrack("payout_created", {
      distinct_id: clientId,
      ...analyticsData,
    });

    if (!isTest) {
      tracker.googleTrack("payout_created", analyticsData);
    }

    return payoutId;
  }

  async updateExistingPayout(id, data) {
    if (this.clientId === null) return;
    const clientId = this.clientId;
    const query = this.getQueryForPayoutsForClient({
      clientId,
    });
    // When updating an existing payout, we need to check on status
    // If status is `retry`
    // We need to re-start the payout from `ready` status
    // This will re-try the createStripeBankAccount process
    const payout = await this.getPayout(clientId, id);
    const { status } = payout;
    if (status === 'retry') {
      data.status = 'ready';
    }
    await query.doc(id).set(data, { merge : true });
  }

  async markPayoutHidden(id, hide) {
    if (this.clientId === null) return;
    const clientId = this.clientId;
    const query = this.getQueryForPayoutsForClient({
      clientId,
    });

    await query.doc(id).set({ hide }, { merge : true });
  }

  async getPayout(clientId, payoutId) {
    const db = firebase.firestore();

    if (payoutId && clientId) {
      const payoutSnapshot = await
        db
          .collection('clients')
          .doc(clientId)
          .collection('payouts')
          .doc(payoutId)
          .get();

      if (payoutSnapshot.exists) {
        const payoutData = payoutSnapshot.data();
        return payoutData;
      }
    }
    return null;
  }
}
