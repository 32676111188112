
import React from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import {
  CheckCircleOutlined,
  QuestionCircleFilled,
  DollarCircleFilled,
  RedoOutlined,
  UndoOutlined,
  PauseCircleTwoTone,
  StopTwoTone,
  FileDoneOutlined,
  FileTextFilled,
  FileTextTwoTone,
  WarningTwoTone,
  ClockCircleTwoTone,
} from '@ant-design/icons';


const StatusTableRowElement = ({ status }) => {
  switch(status) {
    case 'paid':
      return <DollarCircleFilled style={{ color: 'green' }} alt={`${status}`}/>;
    case 'processed':
    case 'batched':
      return <CheckCircleOutlined style={{ color: 'green' }} alt={`${status}`}/>;
    case 'processing':
      return <RedoOutlined spin style={{ color: 'green' }} alt={`${status}`}/>;
    case 'upload_requested':
      return <UndoOutlined spin style={{ color: 'orange' }} alt={`${status}`}/>;
    case 'uploaded':
      return <FileTextTwoTone style={{ color: 'green' }} alt={`${status}`}/>;
    case 'paused':
      return <PauseCircleTwoTone twoToneColor="red" alt={`${status}`}/>; 
    case 'failed':
      return <StopTwoTone twoToneColor="red" alt={`${status}`} />;
    case 'cancelled': 
      return <WarningTwoTone twoToneColor="red" alt={`${status}`} />;
    case 'batch_requested': 
      return <ClockCircleTwoTone twoToneColor="green" alt={`${status}`} />;
    case 'all': 
      return <FileTextFilled twoToneColor="green" alt={`${status}`} />;
    case 'sent': 
      return <FileDoneOutlined twoToneColor="orange" alt={`${status}`} />;
    default:
      return <QuestionCircleFilled style={{ color: 'orange' }} alt={`${status}`} />;
  }
};

export default StatusTableRowElement;
