import { useState, useEffect } from 'react';

import CollectionSubCollectionModel from './CollectionSubCollectionModel';

const topCollection = 'batch-withdrawals';
const subCollection = 'progress';
const isAscending = true;

export function useBatchWithdrawalProgress(batchId) {
    const [batchWithdrawalProgress, setBatchWithdrawalProgress] = useState(null);
    const isAdmin = true;
    const id = batchId;

    useEffect(() => {
        const onUpdate = async (list) => {
            setBatchWithdrawalProgress(list);
        };

        const model = new CollectionSubCollectionModel({
            isAdmin,
            onUpdate,
            limit: 0,
            topCollection,
            subCollection,
            id,
            isAscending,
        });

        model.start();

        return function cleanup() {
            model.stop();
        };

    },
        [batchId]
    );

    return batchWithdrawalProgress;
}
