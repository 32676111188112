// MARK: Helper functions to convert RGB strung to Hex
// input: `rgb(r, g, b)`
// output: `#RRGGBB`

export function rgbColorToHex(rgbString) {
  if(rgbString) {
    // Extract the values of r, g, and b from the rgbString
    const [r, g, b] = rgbString.substring(4, rgbString.length-1).split(',').map(val => parseInt(val.trim()));
    
    // Convert the values to hex and concatenate them into a single string
    const hex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
    
    // Prepend the '#' symbol to the hex string and return it
    return '#' + hex;
  }

  return null;
}

export function getContrastWithRGB(r, g, b) {
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? 'light' : 'dark';
}

export function getContrastWithRGBColor(rgbColor) {
  const justColors = rgbColor ? rgbColor.match(/\d+/g) : null;
  const r = (justColors?.length === 3) ? parseInt(justColors?.[0], 10) : 0x00;
  const g = (justColors?.length === 3) ? parseInt(justColors?.[1], 10) : 0x00;
  const b = (justColors?.length === 3) ? parseInt(justColors?.[2], 10) : 0x00;
  return getContrastWithRGB(r, g, b);
}

export function colorForBackgroundColor(backgroundColor) {
  const isDark = getContrastWithRGBColor(backgroundColor) === 'dark';
  const textColor = isDark ? 'white' : 'black';
  return textColor;
}