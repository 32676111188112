import React, { useContext, useState, useEffect } from 'react';
import { Alert , Card , Button, Modal, Radio, InputNumber, Tooltip, Space, Typography } from 'antd';
import { QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import firebase from 'firebase/app';
import { Link } from 'react-router-dom';
import moment from 'moment';

import TrackerService from '../../utils/TrackerService';
import { UserContext } from '../../providers/UserProvider';

import { setDefaultPayout, isLocalHost, ccy } from './AccountProfileComponents';

const { Text, Paragraph } = Typography;

export const ShowCashOutDialog = ({ setLatestWithdrawalId, loading, setLoading, visible, handleOk, handleCancel, accountId, clientId, currency, clientCurrencyStr, payouts, withdrawals, balance, selectedAvailableAccountId, isAdmin, ...rest }) => {
  const [selectedPayoutMethod, setSelectedPayoutMethod] = useState(() => (payouts && payouts.length) ? setDefaultPayout(payouts, clientCurrencyStr) : null);
  const [payoutAmount, setPayoutAmount] = useState(() => balance ? balance / 100 : null);
  const [withdrawalError, setWithdrawalError] = useState(false);
  const [withdrawalErrorMessage, setWithdrawalErrorMessage] = useState("");
  const [withdrawalId, setWithdrawalId] = useState(null);
  const [withdrawalInfoMessage, setWithdrawalInfoMessage] = useState("");
  const [withdrawalInfoMessageTextType, setWithdrawalInfoMessageTextType] = useState(null);

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    textAlign: 'left',
  };

  const context = useContext(UserContext);
  const user = context.user;

  const onPayoutMethodChange = (event) => {
    setSelectedPayoutMethod(event.target.value);
  };

  const onPayoutAmountChange = (number) => {
    setPayoutAmount(number);
  };

  const setWithdrawalInfoMessageByStatus = (status) => {
    if (status) {
      setWithdrawalInfoMessageTextType(null);
      switch (status) {
        case 'created':
          setWithdrawalInfoMessage('Creating Cash Out...');
          break;
        case 'ready':
          setWithdrawalInfoMessage('Creating Cash Out...');
          break;
        case 'successful':
          setWithdrawalInfoMessage('Successfully funded Cash Out...');
          break;
        case 'failed':
        case 'refund_failed':
        case 'incomplete':
          setWithdrawalInfoMessageTextType('danger');
          setWithdrawalInfoMessage('Something went wrong. Check error message!');
          break;
        case 'sent':
        case 'api_requested':
        case 'batch_requested':
        case 'waiting':
        case 'batched':
          setWithdrawalInfoMessageTextType('success');
          setWithdrawalInfoMessage('Cash Out on its way!');
          break;
        case 'paid':
          setWithdrawalInfoMessageTextType('success');
          setWithdrawalInfoMessage('Cash Out is paid!');
          break;
        case 'refund_requested':
          setWithdrawalInfoMessage('Cash Out is being reversed...');
          break;
        case 'refunded':
          setWithdrawalInfoMessage('Cash Out is reversed!');
          break;
        case 'refund_failed':
          setWithdrawalInfoMessageTextType('danger');
          setWithdrawalInfoMessage('Cash Out is failed to reverse. Contact support!');
          break;
        default:
          setWithdrawalInfoMessage(`Cash Out status ${status}!`);
      }
    } else {
      setWithdrawalInfoMessage('');
    }
  };

  const withdrawalAvailable = () => {
    // Escape hatch to allow users without a payout method to see they should add one now
    if (payouts.length === 0) {
      return true;
    }

    let allOnCooldown = true;
    for (const payout of payouts) {
      const payoutMethodCooldown = isPayoutMethodCooldown(withdrawals, payout);

      if (!payoutMethodCooldown) {
        allOnCooldown = false;
      }
    }

    return !allOnCooldown;
  };

  function getWithdrawalPeriod() {
    const defaultCooldown = {
      duration: 24,
      units: 'hours'
    };
    const localhostCooldown = {
      duration: 2,
      units: 'minutes'
    };
    return isLocalHost ? localhostCooldown : defaultCooldown;
  }

  const isPayoutMethodCooldown = (withdrawals, payout) => {
    const payoutMethodWithdrawals = withdrawals.filter((withdrawal) => {
      return withdrawal.payoutId === payout && payout.id && withdrawal.status !== "failed";
    });

    const sortedWithdrawals = payoutMethodWithdrawals.sort((a, b) => {
      return (a.withdrawal.createdAt < b.withdrawal.createdAt) ? 1 : ((b.withdrawal.createdAt < a.withdrawal.createdAt ? -1 : 0));
    });

    const latestWithdrawal = sortedWithdrawals.length > 0 ? sortedWithdrawals[0].withdrawal : null;

    if (latestWithdrawal && latestWithdrawal.createdAt) {
      const now = moment().utc();
      const withdrawalTime = moment(latestWithdrawal.createdAt.toMillis()).utc();

      const withdrawalPeriod = getWithdrawalPeriod();
      const nextWithdrawalTime = withdrawalTime.add(withdrawalPeriod.duration, withdrawalPeriod.units);

      return nextWithdrawalTime.isAfter(now) ? true : false;
      }
      return false;
    };

  const withdrawalsCooldown = () => {
    const sortedWithdrawals = withdrawals.sort((a, b) => {
      return (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt ? -1 : 0));
    });

    const latestWithdrawal = sortedWithdrawals.length > 0 ? sortedWithdrawals[0] : null;

    if (latestWithdrawal && latestWithdrawal.createdAt) {
      const now = moment().utc();
      const withdrawalTime = moment(latestWithdrawal.createdAt.toMillis()).utc();

      const withdrawalPeriod = getWithdrawalPeriod();
      const nextWithdrawalTime = withdrawalTime.add(withdrawalPeriod.duration, withdrawalPeriod.units);

      if (nextWithdrawalTime.isAfter(now)) {
        const diffMinutes = nextWithdrawalTime.diff(now, "minutes");
        return diffMinutes >= 60 ? `${Math.floor(diffMinutes / 60)} hours` : `${diffMinutes} minutes`;
      }
    }

    return null;
  };

  const currencyMappings = {
    EUR: '€',
    GBP: '£',
    USD: '$',
  };

  const mapCurrencySafely = (ccy) => {
    return currencyMappings[ccy] ? currencyMappings[ccy] : '';
  };

  const tooltopForRadioButton = (payout) => {
    let isQuestion = true;

    let displayName = '';
    displayName = payout.holderName ? `${displayName}${payout.holderName.substring(0, 32)} ` : `${displayName}`;

    let accountNumber = payout.accountNumber ? `**${payout.accountNumber.slice(-8)}` : '';
    accountNumber = payout.iban ? `${payout.iban.substring(0, 8)}**${payout.iban.slice(-4)}` : `${accountNumber}`;

    let currencyLabel = payout.currency ? `(${mapCurrencySafely(payout.currency)}) ` : '';
    accountNumber = `${currencyLabel} ${accountNumber}`;


    let tooltipTitle = 
      <Space direction='vertical'>
        <>{displayName}</>
        <>{accountNumber}</>
      </Space>;

    if(isPayoutMethodCooldown(withdrawals, payout)) {
      isQuestion = false;
      tooltipTitle = "Has already been used this period";
    }

    if((payout.currency !== clientCurrencyStr)) {
      const toCcyString = mapCurrencySafely(payout.currency);
      const fromCcyString = mapCurrencySafely(clientCurrencyStr);
      isQuestion = false;
      tooltipTitle = toCcyString && fromCcyString 
          ? `You may not cash out your ${fromCcyString} balance to a ${toCcyString} bank account.`
          : 'You may not cash out to your bank account as it has a different currency to your avaliable balance.' ;
    }

    return <Tooltip title={tooltipTitle}>
          { isQuestion ? <QuestionCircleOutlined /> : <ExclamationCircleOutlined />}
      </Tooltip>;
  };

  const payoutOptions = payouts.map((payout, i) => 
    <Radio 
      style={radioStyle} 
      value={i} 
      key={i} 
      disabled={isPayoutMethodCooldown(withdrawals, payout) || (payout.currency !== clientCurrencyStr)}
    >
        {payout.displayName ? `${payout.displayName.substring(0,16)} ` : '' }
        {tooltopForRadioButton(payout)}
    </Radio>
  );

  const setWithdrawalObject = async () => {
    if (!withdrawalAvailable()) {
      handleCancel();
      return;
    }
    setLoading(true);
    setWithdrawalInfoMessage("Preparing cash out...");

    const payoutAmountCents = Math.round(payoutAmount * 100);

    const selectedPayout = payouts[selectedPayoutMethod];
    const payoutId = selectedPayout.id;

    if (selectedPayout.currency !== clientCurrencyStr) {
      setWithdrawalError(true);
      setWithdrawalErrorMessage(`Error: Bank account currency (${ccy[selectedPayout.currency]}) does not match funds being withdrawn (${currency}). select another payout method or add a new one in Setup Payouts`);
      setLoading(false)
      return;
    }

    if (payoutAmountCents < minimumWithdrawalAmount) {
      setWithdrawalError(true);
      setWithdrawalErrorMessage(`Error: Minimum withdrawal is ${currency}${minimumWithdrawalAmountString}`);
      setLoading(false)
      return;
    }

    const withdrawalObjV2 = {
      payoutId: payoutId,
      payoutAmountCents: +payoutAmountCents,
      accountId: accountId,
      currency: clientCurrencyStr,
      version: '2',
      initiatorUserId: user.uid,
    };

    const payoutAmountDecimal = ((payoutAmountCents * 1)/100).toFixed(2);

    const db = firebase.firestore();
    const  latestWithdrawalSnapshot = await db.collection('clients').doc(clientId).collection('withdrawals').add(withdrawalObjV2)
    setLatestWithdrawalId(latestWithdrawalSnapshot.id)
    setWithdrawalId(latestWithdrawalSnapshot.id)
    // setLoading(false)

    const isTest = isLocalHost;

    const tracker = new TrackerService({
      isTest,
    });

    tracker.mixpanelTrack("payout_requested", {
      distinct_id: clientId,
      clientId,
      payoutId,
      payoutAmountCents,
      amount: payoutAmountDecimal,
      accountId,
      currency: clientCurrencyStr,
      initiatorUserId: withdrawalObjV2.initiatorUserId
    });

    if (!isTest) {
      tracker.googleTrack(
        "payout_requested",
        {
          clientId,
          payoutId,
          payoutAmountCents,
          amount: payoutAmountDecimal,
          accountId,
          currency: clientCurrencyStr,
          initiatorUserId: withdrawalObjV2.initiatorUserId
        }
      );
    }

    handleOk();
  };

  const trackWithdrawalStatusChange = (withdrawal) => {
    const { withdrawalId, status, payoutId, clientId, accountId, currency, payoutAmountCents, is_test, initiatorUserId, type, provider } = withdrawal || {};
    const amount = (payoutAmountCents * 1)/100;
    const amountText = amount.toFixed(2);
    const isTest = isLocalHost || !!is_test;
    const payoutType = type || 'batch';
    const payoutProvider = provider || 'strike';

    const isValidWithdrawalStatus = withdrawal && status && status.length > 0 && amount && amount > 0 && withdrawalId && withdrawalId.length > 0;

    if (!isValidWithdrawalStatus) {
      return;
    }

    const tracker = new TrackerService({
      isTest,
    });

    const eventName = `withdrawal_${status}`;

    tracker.mixpanelTrack(eventName, {
      distinct_id: clientId,
      clientId,
      payoutId,
      payoutType,
      payoutProvider,
      accountId,
      withdrawalId,
      amount: amountText,
      currency,
      initiatorUserId,
      status,
    });

    if (!isTest) {
      tracker.googleTrack(
        eventName,
        {
          clientId,
          payoutId,
          payoutType,
          payoutProvider,
          accountId,
          withdrawalId,
          amount,
          currency,
          initiatorUserId,
          status,
        }
      );
    }
  }

  const minimumWithdrawalAmount = isAdmin ? 0 : 1000;
  const minimumWithdrawalAmountString = (minimumWithdrawalAmount / 100).toFixed(2);

  const withdrawalPeriod = getWithdrawalPeriod();
  const withdrawalInProgessStatuses = [
    'sent',
    'batch_requested',
    'api_requested',
  ];
  const withdrawalFailedStatuses = [
    'failed',
  ];

  const db = firebase.firestore();
  
  // TODO: DOUBLE WITHDRAWAL STATUS UPDATE
  useEffect(() => {
    if (clientId && withdrawalId) {
      const latestWithdrawalObserver = db.collection('clients').doc(clientId).collection('withdrawals').doc(withdrawalId)
        .onSnapshot((snapshot) => {
          const snapshotData = snapshot.data();
          setWithdrawalInfoMessageByStatus(snapshotData.status);
          trackWithdrawalStatusChange(snapshotData);
          if (withdrawalInProgessStatuses.includes(snapshotData.status)) {
            latestWithdrawalObserver();
            setWithdrawalId(null);
            setTimeout(() => { 
              setLoading(false);
              setWithdrawalError(false);
              setWithdrawalErrorMessage('');
              setWithdrawalInfoMessageByStatus(null);
              setWithdrawalInfoMessageTextType(null);
              handleCancel();
            }, 2400)
          }
          if(withdrawalFailedStatuses.includes(snapshotData.status)) {
            latestWithdrawalObserver();
            setWithdrawalId(null);
            setTimeout(() => { 
              setLoading(false);
              setWithdrawalError(false);
              setWithdrawalErrorMessage('');
              setWithdrawalInfoMessageByStatus(null);
              setWithdrawalInfoMessageTextType(null);
            }, 1400)
          }
      });
    }
  }, [withdrawalId]);

  return (
    <Modal 
      title="Cash Out Available Balance" 
      visible={visible} 
      onOk={setWithdrawalObject} 
      onCancel={handleCancel} 
      cancelButtonProps={{ disabled: loading }} 
      okButtonProps={{ disabled: loading || !withdrawalAvailable() || (payouts && payouts.length === 0) }}
      destroyOnClose={true}
    >
      <div style={{ textAlign: 'center' }}>
        {withdrawalError && <Alert message={withdrawalErrorMessage} type="error" showIcon />}

        {withdrawalAvailable()
          ? (
            <>
              <Space direction='vertical' size={'small'}>
                <Text >Allow for up to <strong>3 business days</strong> for money to arrive in your chosen bank account.</Text>
              </Space>
              
              <p></p>

              <Card actions={payouts.length > 0 ? ['Tap on `?` for account details'] : []}>
                <p>Select Payout Method</p>

                <Radio.Group onChange={onPayoutMethodChange} value={selectedPayoutMethod}>
                  {payoutOptions}
                  <p></p>
                  <Link to={`/client/manage/${clientId}/bank`}><Button type='primary'>{payouts.length > 0 ? 'Add New Payout' : 'Add Payout Method'}</Button></Link>
                </Radio.Group>

              </Card>

              <Card>
                <p>Enter Amount</p>

                {balance >= minimumWithdrawalAmount
                  ?
                  <InputNumber
                    defaultValue={balance / 100}
                    min={minimumWithdrawalAmount / 100}
                    max={balance / 100}
                    precision={2}
                    onChange={onPayoutAmountChange} />
                  :
                  <p>{`Minimum payout amount is ${currency}${minimumWithdrawalAmountString}`}</p>
                  }

                  <p>Your available balance: {currency}{(balance / 100).toFixed(2)}</p>

              </Card>

              <Space direction='vertical' size={0}>
                <Text>{`Cash Out is limited to one per ${withdrawalPeriod.duration} ${withdrawalPeriod.units}`} per account</Text>
                {withdrawalInfoMessage && withdrawalInfoMessage.length > 0 &&
                  <Text type={withdrawalInfoMessageTextType}>{withdrawalInfoMessage}</Text>
                }
              </Space>        
            </>
          )
          : (
            <Card>
              <p>{`Withdrawals are limited to one per ${withdrawalPeriod.duration} ${withdrawalPeriod.units}`}</p>
              <p>{`You can withdraw again in ${withdrawalsCooldown()}`}</p>
            </Card>
          )}
      </div>
    </Modal>
  );
};
