import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../../App.scss';
import 'antd/dist/antd.css';
import {
  QrcodeOutlined,
  SettingOutlined,
  UserAddOutlined,
  ArrowRightOutlined,
  ContactsTwoTone,
  ShopTwoTone,
  CalendarTwoTone,
  CheckCircleFilled,
  CheckCircleOutlined,
  QuestionCircleFilled,
  DollarCircleFilled,
  RedoOutlined,
  PauseCircleTwoTone,
} from '@ant-design/icons';
import {
  Button,
  Space,
  Card,
  Typography,
  Divider,
  Skeleton,
  Row, Col,
  Image,
  Alert,
  PageHeader,
  Table,
  Tabs,
} from 'antd';
import moment from 'moment';

import MoneyTitle from '../../../components/customer/MoneyTitle';
import { BatchWithdrawalCard } from '../../../components/admin/AdminBatchedPayoutsComponents';
import { ProgressListView } from '../../../components/client/PayoutProgressListView';
import { useBatchWithdrawalLines } from '../../../models/useBatchWithdrawalLines';
import { useBatchWithdrawalDetails } from '../../../models/useBatchWithdrawalDetails';
import { useBatchWithdrawalProgress } from '../../../models/useBatchWithdrawalProgress';
import StatusTableRowElement from '../../../components/admin/StatusTableRowelement';
import BatchActionButtons from '../../../components/admin/BatchActionButton';
import BatchWithdrawalsModel from '../../../models/BatchWithdrawalsModel';

const { TabPane } = Tabs;

const csvFormats = {
  EUR: { targetFormat: 'SEPA' },
  GBP: { targetFormat: 'UK' },
  USD: { targetFormat: 'US' },
};

function getTargetFormatForCurrency(currency) {
  const targetFormatInfo = csvFormats[currency] || {};
  return targetFormatInfo.targetFormat;
}

const TabTable = ({ useKey, batchId, tabContent, loading, columns }) => {
  const model = new BatchWithdrawalsModel({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const getTabContent = () => {
    const content = selectedRowKeys.length > 0 ? tabContent.filter((v) => selectedRowKeys.includes(v.withdrawalId)) : tabContent;
    return content;
  }

  const onBatchUpload = async () => {
    const content = getTabContent();
    await model.executeBatchRequestedForUpload(batchId, content);
  };

  const onBatchCancelled = async () => {
    await model.executeBatchCancelAllLines(batchId);
  }

  const onBatchMarkAsPaid = async () => {
    const content = getTabContent();
    await model.executeBatchMarkAsPaid(batchId, content);
  }

  const onBatchPause = async () => {
    const content = getTabContent();
    await model.executeBatchLinesPause(batchId, content);
  }

  const onBatchUnpause = async () => {
    const content = getTabContent();
    await model.executeBatchLinesUnpause(batchId, content);
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return <Space direction='vertical' style={{ width: '100%' }}>
    <BatchActionButtons 
      batchId={batchId} 
      onBatchUpload={onBatchUpload} 
      onBatchCancelled={onBatchCancelled}
      onBatchMarkPaid={onBatchMarkAsPaid}
      onPause={onBatchPause}
      onUnpause={onBatchUnpause}
    />
    <Table
      key={`${batchId}-table-${useKey}`}
      footer={(currentPageData) => (<>Batch {batchId} showing {currentPageData.length} withdrawals</>)}
      loading={loading}
      dataSource={tabContent || []}
      columns={columns}
      rowKey={(item) => `${item.withdrawalId}`}
      elipsis={true}
      rowSelection={rowSelection}
    />
  </Space>;
};

function getTableColumns(batch) {
  let { targetFormat, currency } = batch || {};

  if(!targetFormat) {
    targetFormat = getTargetFormatForCurrency(currency)
  }

  let accountDetails = [];

  if(targetFormat === 'SEPA') {
    accountDetails = [
      {
        title: 'IBAN',
        dataIndex: 'iban',
        key: 'iban',
        align: 'left',
      },
      {
        title: 'BIC',
        dataIndex: 'bic',
        key: 'bic',
        align: 'left',
        sorter: (a, b) => ((a.bic > b.bic) ? -1 : 1),
      },
    ];
  } else if (targetFormat === 'UK') {
    accountDetails = [
      {
        title: 'Account Number',
        dataIndex: 'accountNumber',
        key: 'accountNumber',
        align: 'left',
      },
      {
        title: 'Sort Code',
        dataIndex: 'sortCode',
        key: 'sortCode',
        align: 'left',
        sorter: (a, b) => ((a.sortCode > b.sortCode) ? -1 : 1),
      },
    ];
  } else if (targetFormat === 'US') {
    accountDetails = [
      {
        title: 'Account Number',
        dataIndex: 'accountNumber',
        key: 'accountNumber',
        align: 'left',
      },
      {
        title: 'Routing Number',
        dataIndex: 'routingNumber',
        key: 'routingNumber',
        align: 'left',
        sorter: (a, b) => ((a.routingNumber > b.routingNumber) ? -1 : 1),
      },
    ];
  }

  const columns = [
    {
      title: 'Index',
      dataIndex: 'itemIndex',
      key: 'itemIndex',
      align: 'left',
      sorter: (a, b) => a.itemIndex - b.itemIndex,
    },
    {
      title: 'Holder Name',
      dataIndex: 'holderName',
      key: 'holderName',
      align: 'left',
      sorter: (a, b) => ((a.holderName > b.holderName) ? -1 : 1),
    },
    {
      title: 'Client ID',
      dataIndex: 'clientId',
      key: 'clientId',
      align: 'left',
    },
    ...accountDetails,
    {
      title: 'Payment Reference',
      dataIndex: 'paymentReference',
      key: 'paymentReference',
      align: 'left',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      sorter: (a, b) => a.amount - b.amount,
      render: (text, item, index) => <MoneyTitle amount={item.amount || 0} currency={item.currency} digits={2} />,
    },
    {
      title: '?',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, item, index) => <StatusTableRowElement status={item.status} />,
      sorter: (a, b) => ((a.status > b.status) ? -1 : 1),
    }
  ];
  
  return columns;
}


const AdminBatchedPayoutDetailScreen = ({
  locStrings,
  isAdmin,
}) => {
  const { batchId } = useParams();
  const batchWithdrawal = useBatchWithdrawalDetails(batchId, isAdmin);
  const batchWithdrawals = useBatchWithdrawalLines(batchId);
  const batchWithdrawalProgress = useBatchWithdrawalProgress(batchId);
  const loading = !batchWithdrawals;
  const columns = getTableColumns(batchWithdrawal);

  const individualWithdrawals = batchWithdrawals ? batchWithdrawals.filter((v) => v.recipientType === 'Individual') : [];
  const companyWithdrawals = batchWithdrawals ? batchWithdrawals.filter((v) => v.recipientType === 'Company') : [];

  const tabContent = {
    'individual': individualWithdrawals,
    'company': companyWithdrawals,
  };
  
  const amountTotalIndividual = individualWithdrawals.reduce((acc, item) => acc + item.amount, 0);
  const amountTotalCompany = companyWithdrawals.reduce((acc, item) => acc + item.amount, 0);

  const amounts = {
    'individual': (amountTotalIndividual/100).toFixed(2),
    'company': (amountTotalCompany/100).toFixed(2),
  };

  return <>
      <PageHeader
        ghost={false}
        title={`Batch ${batchId}`}
        onBack={(() => window.history.back())}
      />
      <BatchWithdrawalCard batchWithdrawal={{ ...batchWithdrawal, batchId }} isDetail={true}/>
      <Tabs type='card' style={{ marginTop: '1em' }}>
        <TabPane tab={<><ContactsTwoTone />{`Individual (${amounts['individual']})`}</>} key="individual">
          <TabTable useKey={'individual'} batchId={batchId} tabContent={tabContent['individual'] || []} loading={loading} columns={columns} /> 
        </TabPane>
        <TabPane tab={<><ShopTwoTone />{`Company (${amounts['company']})`}</>} key="company">
          <TabTable useKey={'company'} batchId={batchId} tabContent={tabContent['company'] || []} loading={loading} columns={columns} />
        </TabPane>
        { !loading && batchWithdrawalProgress && 
        <TabPane tab={<><CalendarTwoTone />{`Progress (${batchWithdrawal.status})`}</>} key="progress">
          <div style={{ marginLeft: '1em' }}>
            <ProgressListView progressCollection={batchWithdrawalProgress} item={batchWithdrawal} shouldUseDate={true} />
          </div>
        </TabPane>
        }
      </Tabs>
  </>;
};

export default AdminBatchedPayoutDetailScreen;

