import { useState, useEffect } from 'react';
import firebase from 'firebase/app';

export function useProgressCollection(collectionName, clientId, payoutId) {
    const [progressCollection, setProgressCollection] = useState([]);

    useEffect(() => {
        // Subscribe to the progress collection in Firestore
        const db = firebase.firestore();
        const ref = db.collection('clients').doc(clientId).collection(collectionName).doc(payoutId).collection('progress').orderBy('updatedAt', 'asc');

        const unsubscribe = ref.onSnapshot((snapshot) => {
            const updatedCollection = snapshot.docs.map((doc) => doc.data());
            setProgressCollection(updatedCollection);
        });

        // Clean up the subscription on component unmount
        return () => {
            unsubscribe();
        };
    },
        []
    );

    return progressCollection;
}
