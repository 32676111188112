import React, { Component } from 'react';
import firebase from 'firebase/app';
import { Link, Redirect } from 'react-router-dom';
import { Button, Typography , Input, Tooltip } from 'antd';
import { InfoCircleOutlined, UserOutlined, MessageOutlined } from '@ant-design/icons';
import qs from 'qs';

import HeroProfile from '../../components/customer/HeroProfile';
import AmountSelectionCard from '../../components/customer/AmountSelectionCard';
import PaymentCard from '../../components/customer/PaymentCard';
import FooterCard from '../../components/customer/FooterCard';
import PoweredByStrike from '../../components/customer/PoweredByStrike';
import { PaymentKeyboard } from '../../components/customer/PaymentsComponents';
import { getMinimumCharge } from '../../utils/PaymentUtils';
import CenteredSpinner from '../../components/CenteredSpinner';

import '../../App.scss';

export default class TapDetailScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, rating, countOfTips, averageRating, messageText, values, topLogo, profileBio, displayName, avatar, selectedValueByIndex, bottomLogo

  constructor(props) {
    super(props);

    this.state = {
      messageText: '',
      loading: (this.props.loading),
      selectedValueByIndex: 0,
      selectedAmount: 0,
      customerName: '',
      paymentInfo: '',
      shouldShowShareButton: true,
      nameInputDisabled: false,
      paymentInfoInputDisabled: false,
      minimumAmountError: false,
    };
  }

  async componentDidMount() {
    const pathArray = this.props.location.pathname.split("/");
    const tagId = pathArray[3];
    const tapId = pathArray[5];

    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const teamMemberId = queryParams ? queryParams.tmid : null;

    let db = firebase.firestore();
    let doc;
    try {
      doc = await db.collection('tags').doc(tagId).collection('taps').doc(tapId).get();
      const tap = doc.data();

      if (teamMemberId) {
          let teamMemberDocSnapshot = await db.collection('teams').doc(tap.teamId).collection('members').doc(teamMemberId).get();
          let teamMemberDocData = teamMemberDocSnapshot.data();
          tap.displayName = teamMemberDocData.displayName;
          tap.avatar = teamMemberDocData.avatar;
      }

      const tapCanAcceptPayment = tap.status === "created" || tap.status === "ready";

      if (!tapCanAcceptPayment) {
        window.location.href = `/tags/${tagId}`;
      }

      let selectedAmount = (tap.values && tap.values.length > 0) ? tap.values[tap.selectedValueByIndex || 0] : 0;
      let selectedIndex =  tap.selectedValueByIndex || 0;

      if (tap.amount) {
        selectedAmount = tap.amount;
        selectedIndex = tap.values.length;
      }

      let {appliedRevenueFeeSlab, appliedRevenueFeeSlabId} = this.determineRevenueSlab(tap, selectedAmount);
      
      this.setState({
        tap: tap,
        tapId: tapId,
        loading: false,
        customerName: tap.customerName,
        amountInputHidden: tap.amount && tap.product === "payment", // disable input for payment links with pre-set payment
        nameInputDisabled: tap.customerName && tap.product === "payment",
        paymentInfoInputDisabled: tap.paymentInfo && tap.product === "payment",
        paymentInfo: tap.paymentInfo,
        selectedValueByIndex: selectedIndex,
        selectedAmount: selectedAmount,
        appliedRevenueFeeSlab: appliedRevenueFeeSlab,
        appliedRevenueFeeSlabId: appliedRevenueFeeSlabId,
        placeholderMessageText: tap.messageText || '(optional) Your message',
        teamMemberId,
      });
    } catch(error) {
      // redirect if you don't have access to tap or tap is invalid
      window.location.href = `/tags/${tagId}`;
    }

  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  handleMessageTextChange = (event) => {
    this.props.appActions.dataSheets.tap.items[0].messageText = event.target.value;
    this.setState({messageText: event.target.value});
  }

  handlerSelectorChange = (idx, amount, ev) => {
    const tap = this.state.tap;
    const minimumCharge = getMinimumCharge(tap.paymentDetails.currency);

    if (amount < minimumCharge) {
      this.setState({ minimumAmountError: true });
      return;
    } else {
      this.setState({ minimumAmountError: false });
    }

    let {appliedRevenueFeeSlab, appliedRevenueFeeSlabId} = this.determineRevenueSlab(tap, amount);

    this.setState({
      selectedAmount: amount,
      appliedRevenueFeeSlab: appliedRevenueFeeSlab,
      appliedRevenueFeeSlabId: appliedRevenueFeeSlabId,
      selectedValueByIndex: idx,
    });
  }

  determineRevenueSlab = (tap, amount) => {
      let appliedRevenueFeeSlab, appliedRevenueFeeSlabId;
      if(amount <= 800) {
          appliedRevenueFeeSlab = tap.revenueFee[`fees_${tap.product}_slab_below_eq_8`];
          appliedRevenueFeeSlabId = `fees_${tap.product}_slab_below_eq_8`;
      } else if(amount > 800 && amount <= 50000){
          appliedRevenueFeeSlab = tap.revenueFee[`fees_${tap.product}_slab_above_8`];
          appliedRevenueFeeSlabId = `fees_${tap.product}_slab_above_8`;
      } else if(amount > 50000 && amount <= 100000){
          appliedRevenueFeeSlab = tap.revenueFee[`fees_${tap.product}_slab_above_500`];
          appliedRevenueFeeSlabId = `fees_${tap.product}_slab_above_500`;
      } else {
          appliedRevenueFeeSlab = tap.revenueFee[`fees_${tap.product}_slab_above_1000`];
          appliedRevenueFeeSlabId = `fees_${tap.product}_slab_above_1000`;
      }
      return {appliedRevenueFeeSlab, appliedRevenueFeeSlabId};
  }

  getShowRunningTotal = () => {
    const displayOptions = this.props.displayOptions || {
      showRunningTotal: false,
    };
    return displayOptions.showRunningTotal || false;
  }


  onUpdateCustomerName = (customerName) => {
    this.setState(
      {
        customerName
      }
    );
  }

  onUpdatePaymentInfo = (paymentInfo) => {
    this.setState(
      {
        paymentInfo
      }
    );
  }

  resetSetupRequiredForTag = async () => {
    if(this.state.tap.tagId) {
      const db = firebase.firestore();
      await db.collection('tags').doc(this.state.tap.tagId).update({
        setupRequired: true,
        setupRequiredChangedAt: firebase.firestore.Timestamp.now()
      });
    }
  };

  // MARK: render()
  renderTap = () => {
      let layoutFlowStyle = {
        // height: '100vh',
      };
      let baseStyle = {
        backgroundColor: '#EEEEEE',
      };

      if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
        baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
      }
      if ( !this.props.atTopOfScreenStack) {
        layoutFlowStyle.overflow = 'hidden';
      }

      const style_elBackground = {
        width: '100%',
        height: '100vh',
       };
      const style_elBackground_outer = {

       };
      const style_elCard_outer = {
        borderRadius: '8.0px',
        overflow: 'visible',
       };

      const style_elPaywithApplePay = {
        display: 'block',
        color: '#fff',
        textAlign: 'center',
        backgroundColor: '#000',
        cursor: 'pointer',
        pointerEvents: 'auto',
    };

      const style_elImage = {
        height: 'auto',
        cursor: 'pointer',
        pointerEvents: 'auto',
        width: '100%',
        marginTop: '20px',
      };

      const style_elPromoImage = {
        height: 'auto',
        cursor: 'pointer',
        pointerEvents: 'auto',
        width: '100%',
        maxHeight: '64px',
        marginTop: '10px',
        marginBottom: '10px',
      };

      if (!this.state.tap.avatar) {
        style_elImage.maxHeight = "400px";
      }

       const style_elTestText = {
        fontSize: 12,
        fontWeight: "bold",
        color: `#000`,
        background: 'rgba(255, 243, 202, 1)',
        border: '1px solid #FFE58F',
        textAlign: 'center',
        width: "100%",
        marginLeft: '0',
        marginRight: '0',
        marginBottom: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '1rem',
        paddingRight: '1rem',
       };

      const style_elField = {
        display: 'block',
        backgroundColor: 'white',
        paddingLeft: '1rem',
        boxSizing: 'border-box', // ensures padding won't expand element's outer size
        textAlign: 'left',
        pointerEvents: 'auto',
       };

       const style_elFooterContainerCard_outer = {
        padding: '4.0px',
        paddingTop: "10px",
        paddingBottom: '0',
        borderRadius: '4px',
        overflow: 'visible',
       };

       const style_elPaywithApplePay_loading = {
        ...style_elPaywithApplePay,
        color: '#777',
        width: '100px',
      };

       const {
         averageRating,
         countOfTips,
         messageText,
         rating,
         subtitles,
         displayName,
      } = this.state.tap;

      const shouldShowRunningTotal = this.getShowRunningTotal();

      const displaySubtitles = subtitles || [];
      const shouldShowHeroPanel = true;
      const shouldShowSelectAmountPanel = (this.state.tap.product !== "payment");
      const shouldShowPaymentKeyboard = (this.state.tap.product === "payment");
      const shouldShowSelectPaymentPanel = true;
      const shouldShowAvgRating = !!averageRating;
      const shouldShowTipCount = !!countOfTips;
      const shouldShowRatingHistory = (shouldShowAvgRating && shouldShowTipCount);
      const shouldShowRatingStars = !!rating;
      const shouldShowRatingPanel = (shouldShowRatingHistory && shouldShowRatingStars);
      const shouldShowMessage = !!messageText;
      const shouldShowMessagePanel = (shouldShowMessage);
      const shouldShowCustomerNameInput = (this.state.tap.product === "payment") && !this.state.nameInputDisabled;
      const shouldShowPaymentInfoInput = (this.state.tap.product === "payment") && !this.state.paymentInfoInputDisabled;


      return (
        <div className="AppScreen TagDetailScreen" style={baseStyle}>
          <div className="background">
            <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
              <div className="appBg" style={style_elBackground}>
                <div className="layoutFlow" style={layoutFlowStyle}>
                <div className="flowRow flowRow_TagDetailScreen_elCard_565678" style={layoutFlowStyle}>
                  <div className="elCard" style={style_elCard_outer}>

                    <div className="cardBg" style={layoutFlowStyle}>
                      {
                        (this.state.tap.is_test) && (
                        <div className="elBannerText">
                          <div className="systemFontBold" style={style_elTestText}><span>PREVIEW MODE - NOT CHARGED</span></div>
                        </div>
                        )
                      }

                      <div className="elImage">
                        {(!this.state.loading && this.state.tap.topLogo && this.state.tap.product === "giving" && (
                          <img style={style_elImage} src={(this.state.tap.topLogo)} alt="User banner" />
                        ))}
                        {(!this.state.loading && this.state.tap.topLogo && this.state.tap.product !== "giving" && (
                          <img style={style_elPromoImage} src={(this.state.tap.topLogo)} alt="Promo banner" />
                        ))}
                      </div>

                      { (!this.state.loading && shouldShowHeroPanel) ?
                        (<div className="hasNestedComps elHeroProfile">
                          <div>
                            <HeroProfile {...this.props} ref={(el)=> this._elHeroProfile = el} object={this.state.tap} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
                          </div>
                        </div>)
                        :null
                      }

                      { (!this.state.loading && shouldShowCustomerNameInput) &&
                        <div 
                            className="hasNestedComps elHeroProfile" 
                            style={{ 
                                alignItems: 'center', 
                                width: '90%', 
                                marginLeft: 'auto', 
                                marginRight: 'auto', 
                                marginTop: '10px'
                              }}
                        >
                          <Input
                              size='large'
                              placeholder="Enter your name"
                              prefix={<UserOutlined className="site-form-item-icon" />}
                              suffix={
                                <Tooltip title={`${displayName} needs your name to connect your payment`}>
                                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                              }
                              value={this.state.customerName}
                              disabled={this.state.nameInputDisabled}
                              onChange={((e)=>(this.onUpdateCustomerName(e.target.value)))}
                          />
                        </div>
                      }

                      { (!this.state.loading && shouldShowPaymentInfoInput) &&
                        <div 
                          className="hasNestedComps elHeroProfile" 
                          style={{ 
                            alignItems: 'center', 
                            width: '90%', 
                            marginLeft: 'auto', 
                            marginRight: 'auto', 
                            marginTop: '10px'
                          }}
                          >
                            <Input
                              size='large'
                              placeholder="Add a message"
                              prefix={<MessageOutlined className="site-form-item-icon" />}
                              suffix={
                                <Tooltip title={`${displayName} sees this message`}>
                                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                              }
                              value={this.state.paymentInfo}
                              disabled={this.state.paymentInfoInputDisabled}
                              onChange={((e)=>(this.onUpdatePaymentInfo(e.target.value)))}
                              style={{ marginTop: '5px'}}
                            />
                        </div>
                      }

                      <div className="strikeCard">
                        { (!this.state.loading && shouldShowSelectAmountPanel) ?
                          (<div className="hasNestedComps elAmountSelectionCard">
                            <div>
                              <AmountSelectionCard {...this.props} custom product={this.state.tap.product}  values={this.state.tap.values} paymentDetails={this.state.tap.paymentDetails} subtitles={displaySubtitles} rgbColor={this.state.tap.rgbColor} ref={(el)=> this._elAmountSelectionCard = el} handler={this.handlerSelectorChange} values={this.state.tap.values} selectedValueByIndex={this.state.selectedValueByIndex} selectedAmount={this.state.selectedAmount} minimumAmountError={this.state.minimumAmountError} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
                            </div>
                          </div>)
                          : null
                        }

                        { (!this.state.loading && shouldShowPaymentKeyboard) ?
                          (<div className="hasNestedComps elAmountSelectionCard">

                            { this.state.nameInputDisabled &&
                              <div style={{ marginBottom: "10px" }}>
                                Name: {this.state.customerName}
                              </div>
                            }

                            { this.state.paymentInfoInputDisabled &&
                              <div style={{ marginBottom: "10px" }}>
                                Reference: {this.state.paymentInfo}
                              </div>
                            }

                            <div>
                              <PaymentKeyboard
                                initialValue={this.state.selectedAmount}
                                currency={this.state.tap.paymentDetails ? this.state.tap.paymentDetails.currency : null}
                                previewBottom
                                deviceInfo={this.props.deviceInfo}
                                locStrings={this.props.locStrings}
                                hidden={this.state.amountInputHidden}
                                onChange={this.handlerSelectorChange}
                              />
                            </div>
                          </div>)
                          : null
                        }

                        <div className="elFooterContainerCard" style={style_elFooterContainerCard_outer}>
                          { (!this.state.loading && shouldShowSelectPaymentPanel) ?
                            (<div className="hasNestedComps elPaymentCard">
                              <div>
                                <PaymentCard {...this.props} 
                                  ref={(el)=> this._elPaymentCard = el} 
                                  tap={this.state.tap} 
                                  tapId={this.state.tapId} 
                                  selectedAmount={this.state.selectedAmount} 
                                  appliedRevenueFeeSlab={this.state.appliedRevenueFeeSlab} 
                                  appliedRevenueFeeSlabId={this.state.appliedRevenueFeeSlabId} 
                                  messageText={this.state.messageText} 
                                  loading={this.state.loading} 
                                  deviceInfo={this.props.deviceInfo} 
                                  locStrings={this.props.locStrings} 
                                  customer={{customerName: this.state.customerName, paymentInfo: this.state.paymentInfo}} 
                                  teamMemberId={this.state.teamMemberId}
                                  shouldShowShareButton={this.state.shouldShowShareButton}
                                  minimumAmountError={this.state.minimumAmountError}
                                />
                              </div>
                            </div>)
                            : null
                          }
                        </div>
                      </div>

                      <PoweredByStrike locStrings={this.props.locStrings} />

                    </div>
                  </div>
                </div>
              </div>

              <div className="hasNestedComps elFooterCard">
                <div>
                  { (!this.state.loading && !this.state.tap.setupRequired && this.state.tap.product === 'giving') && (
                    <div className="setupRequiredCta">
                      <Link  to={`/setup/${this.state.tap.tagId}`}>
                        <Button type='link' style={{ color: 'grey'}} onClick={() => this.resetSetupRequiredForTag()}>
                          Change tag setup
                        </Button>
                      </Link>
                    </div>
                  )}
                  { (!this.state.loading && this.state.tap.setupRequired) && (
                    <div className="setupRequiredCta">
                      <Link to={`/setup/${this.state.tap.tagId}`}>{this.state.tap.setupRequiredCta ? this.state.tap.setupRequiredCta : "Finish tag setup"}</Link>
                    </div>
                  )}
                  <FooterCard ref={(el)=> this._elFooterCard = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      );
    }

  render() {
    const loading = this.state.loading;

    if (loading) {
      return <CenteredSpinner loading={loading} noLayout={true} />
    } else if(!loading && this.state.tap) {
      return this.renderTap();
    } else {
      return null;
    }
  }
}
