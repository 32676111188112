import React from 'react';

import '../App.scss';
import Spinner from './Spinner';

const CenteredSpinner = ({loading, noLayout}) => {
    const style = {
        width: "100%",
        textAlign: "center",
    };

    return (
        <div style={style}>
            {
                noLayout
                    ? <Spinner className='loading-spinner' condition={() => loading} />
                    : <Spinner className='loading-spinner loading-spinner-with-navbar' condition={() => loading}/>
            }
            
        </div>
    );
};

export default CenteredSpinner;
