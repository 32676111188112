import { useState, useEffect } from 'react';

import CollectionSubCollectionModel from './CollectionSubCollectionModel';

const topCollection = 'batch-withdrawals';
const subCollection = 'lines';
const orderBy = 'amount';
const isAscending = false;

export function useBatchWithdrawalLines(batchId, orderByField) {
    const [batchWithdrawalWithdrawals, setBatchWithdrawalWithdrawals] = useState(null);
    const isAdmin = true;
    const id = batchId;

    useEffect(() => {
        const onUpdate = async (list) => {
            setBatchWithdrawalWithdrawals(list);
        };

        const model = new CollectionSubCollectionModel({
            isAdmin,
            onUpdate,
            limit: 0,
            topCollection,
            subCollection,
            id,
            orderBy: orderByField || orderBy,
            isAscending,
        });

        model.start();

        return function cleanup() {
            model.stop();
        };

    },
        [batchId]
    );

    return batchWithdrawalWithdrawals;
}
