import React, {useState, useEffect} from 'react';
import {
    TeamOutlined,
    UserOutlined,
    ArrowRightOutlined,
    EuroCircleFilled,
    DollarCircleFilled,
    PoundCircleFilled,
    CheckCircleFilled,
    ExclamationCircleFilled,
} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {
    Button,
    Space,
    Card,
    Typography,
    Tooltip,
    Avatar,
    Divider,
    Skeleton,
    Tag,
    Row, Col, List,
} from 'antd';
import SkeletonAvatar from 'antd/lib/skeleton/Avatar';
import SkeletonButton from 'antd/lib/skeleton/Button';
import moment from 'moment';

import {ccyIsoCodeToSymbolMap} from '../../utils/CurrencyUtils';
import { capitalize } from '../../utils/ProductSwitcher';

import { TeamLastTransactionCard } from './TeamLastTransactionCard';
import { useTeamDetails } from './useTeamDetails';
import { useTeamMembers } from './useTeamMembers';

const {Text, Title} = Typography;


import '../../App.scss';
import 'antd/dist/antd.css';

const ccy = ccyIsoCodeToSymbolMap;

const ccy_icons = {
  'EUR': <EuroCircleFilled style={{ fontSize: '24px', color: '#00c' }} />,
  'USD': <DollarCircleFilled style={{ fontSize: '24px', color: '#08c' }} />,
  'GBP': <PoundCircleFilled style={{ fontSize: '24px', color: '#70c' }} />,
};

export const IconForCCY = ({ccy, size, ...rest}) => {
  let icon = ccy_icons[ccy];
  return (icon) ? icon : <DollarCircleFilled style={{ fontSize: '24px', color: '#08c' }} {...rest} />;
};


export const VerificationStatusIcon = ({ status }) => {
    return (
        <Tooltip title={`${capitalize(status || "unverified")}`} placement="top">
            {
                status && status.length > 0
                    ? <CheckCircleFilled style={{ fontSize: '24px', color: 'green' }} />
                    : <ExclamationCircleFilled style={{ fontSize: '24px', color: 'grey' }} />
            }
        </Tooltip>
    );
};



function getRoleForMember({ team, member }) {
    const role = (team.teamOwnerClientId === member.clientId) 
        ?
        'owner'
        : 
        (member && member.permissions && member.permissions.isTeamAdmin ? 'manager' : `${member.roles[0]}`);

    return role;
}

function getRoleTitleForMember({ team, member }) {
    const role = (team.teamOwnerClientId === member.clientId) 
        ?
        'You are the owner'
        : 
        (member && member.permissions && member.permissions.isTeamAdmin ? 'You are a manager' : `You are a ${member.roles[0]}`);

    return role;
}


export const TeamMemberAvatarForRole = ({ member, team, onClick, route, size, ...rest }) => {

    const backgroundColorByRole = {
        admin: '#f56a00',
        member: '#009800',
        manager: '#000098',
        default: '#009800'
    };    

    const roleForMember = (member) => {
        const role = getRoleForMember({ team, member });
        return role;
    };

    const colorForMember = (member) => {
        const role = roleForMember(member);
        return backgroundColorByRole[role];
    };

    const memberColor = colorForMember(member);

    const TeamMemberAvatar = ({ size, ...rest }) => {
        return <Avatar
            key={`member-avatar-${member.clientId}`}
            style={{
                backgroundColor: memberColor,
                borderColor: memberColor,
            }}
            src={member.avatar}
            icon={<UserOutlined />}
            size={size}
            {...rest}
        />;
    };

    return route !== null ? (
        <Link key={`member-a-link-${member.clientId}`} to={route} >
            <Button key={`member-a-button-${member.clientId}`}  type='link' style={{ maxWidth: '50px' }} >
                <TeamMemberAvatar size={!size ? 'default' : size} />
            </Button>
        </Link>
    )
    : (
        <Tooltip key={`member-a-tooltip-${member.clientId}`} title={`${member.displayName} (${roleForMember(member)})`} placement="top" >
            <TeamMemberAvatar size={!size ? 'default' : size}/>
        </Tooltip>
    );
};

export const TeamMemberTitleForItem = ({ item, ...rest }) => {

    const TextTitleItem = ({ item }) => {
        return <Text strong={item.isYourself} {...rest}>{`${item.name} `}<Tag color={item.memberColor}>{capitalize(item.role)}</Tag></Text>;
    };

    return item.routeMember === null ?  <TextTitleItem item={item} /> : <Link to={item.routeMember}><TextTitleItem item={item} /></Link>

};

export function getTeamCardTitle(loading, team, clientId) {
    let teamCardTitle = null;

    if (!loading && team) {
        if (team.teamOwnerClientId) {
            teamCardTitle = team.teamOwnerClientId === clientId ? 'You are the owner' : null;
        }

        if (teamCardTitle === null && team.members) {
            let member = null;
            const keys = Object.keys(team.members);
            keys.map(k => {
                const m = team.members[k];
                if (m.memberClientId === clientId)
                    member = m;
            });
            if (member && member.roles && member.roles.length > 0) {
                const role = member.permissions && member.permissions.isTeamAdmin ? 'manager' : member.roles[0];
                teamCardTitle = `You are a ${role}`;
            }
        }

        if (teamCardTitle === null && team.placeOfWork) {
            teamCardTitle = !loading && team && team.placeOfWork ? `${team.placeOfWork} Team` : 'Your Team';
        }

        teamCardTitle = teamCardTitle || 'Your Team';
    }
    return teamCardTitle;
}



export const TeamsGrid = ({
    condition, locStrings, client, clientId, teams, isAdmin, personal, prefixPath,
    sortBy, compact, onClick, clickText, ...rest
}) => {
    const tableData = [];
    let key = 1;
    teams.forEach(team => {
        const teamRow = {
            ...team,
            rowType: 'team',
            teamName: team.teamName,
            teamDescription: team.teamDescription,
            teamNumberOfMembers: team.teamNumberOfMembers,
            teamId: team.teamId,
            teamMemberRoles: team.roles,
            teamMemberPermissions: team.permissions,
            updatedAt: team.updatedAt,
            key: `tg-team-${team.teamId}`
        }

        key++;
        tableData.push(teamRow);
    });

    if (sortBy === 'date') {
        tableData.sort(
            (a, b) => (a.updatedAt > b.updatedAt ? -1 : 1)
        )
    }
    if (sortBy === 'name') {
        tableData.sort(
            (a, b) => (a.teamName > b.teamName ? 1 : -1)
        )
    }

    if (!!personal) {
        tableData.push({ rowType: 'personal', key: `tg-personal-${clientId}` })
    }

    const isPagenate = tableData.length > 5;

    return (
        <List
            grid={{
                gutter: 16,
                xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 5,
            }}
            style={{ minWidth: '280px', textAlign: 'center' }}
            pagination={isPagenate}
            dataSource={tableData}
            renderItem={item => (
                item.rowType === 'team' ?
                    (
                        <List.Item
                            key={`list-${item.rowType}-${item.teamId}`}
                        >
                            <TeamCard
                                condition={true}
                                locStrings={locStrings}
                                teamId={item.teamId}
                                team={item}
                                clientId={clientId}
                                isAdmin={isAdmin}
                                prefixPath={prefixPath}
                                compact={compact}
                                onClick={onClick}
                                clickText={clickText}
                            />
                        </List.Item>
                    ) : (
                        <List.Item
                            key={`list-${item.rowType}-${clientId}`}
                        >
                            <PersonalCard
                                condition={true}
                                locStrings={locStrings}
                                clientId={clientId}
                                client={client}
                                currencies={['EUR']}
                                isAdmin={isAdmin}
                            />
                        </List.Item>
                    )
            )}
        />


    );

};


export const TeamCard = ({
    condition,
    locStrings,
    teamId,
    clientId,
    team,
    isAdmin,
    prefixPath,
    compact,
    onClick,
    clickText,
}) => {
    const teamDetails = useTeamDetails(teamId);
    const teamMembers = useTeamMembers(teamId, teamDetails, clientId);
    const target = onClick ? null : `.${prefixPath || ''}/teams/${teamId}`;
    const override = false;
    const yourself = teamMembers && teamMembers.find(m => m.clientId === clientId);
    let teamCardTitle = yourself && teamDetails && getRoleTitleForMember({ member: yourself, team: teamDetails });
    const limitToMemberClientId = (yourself && yourself.roles && yourself.roles[0] === 'member') ? clientId : null;
    const actionText = clickText || 'View Team';
    let teamDescriptor = team.teamDescription && team.teamDescription.length > 0 && team.teamDescription.substring(0, 60);
    teamDescriptor = compact ? null : teamDescriptor;

    const TeamCardTitle = ({ team }) => {
        return team ? <>
                <Tooltip title={(team.teamName && team.teamName.length > 24) ? team.teamName : null}>
                    <Title level={5}>{team.teamName.substring(0, 24)}</Title>
                </Tooltip>
            </> : null;
    };

    const TeamCardTitleCompact = ({ team }) => {
        return team ? <>
            <Row style={{ textAlign: 'left' }} justify='space-evenly'>
                <Avatar src={team.topLogo} icon={<TeamOutlined />} size={40} style={{ borderRadius: '10%', marginRight: '10px'}} />
                <TeamCardTitle team={team} />
            </Row>
        </> : null;
    };

    let actions = [];
    if(teamDetails) {
        const dateAt = teamDetails.updatedAt || teamDetails.createdAt;
        const Fallback = (props) => { return <Text key={`team-lastupdated-${teamId}`} ellipsis {...props}>{dateAt ? `Updated ${moment(dateAt.toMillis()).fromNow()}`: ' '}</Text> };
        actions.push(<TeamLastTransactionCard 
                        condition={true}
                        locStrings={locStrings}
                        team={teamDetails}
                        fallback={Fallback}
                        teamMemberId={limitToMemberClientId}
                        key={`team-txns-${teamId}`}
                />);
    }

    return (condition && teamDetails && teamMembers) ? (
        <>
            <Card
                loading={override}
                size='small'
                title={compact ? <TeamCardTitleCompact team={teamDetails} /> : <TeamCardTitle team={teamDetails} />}
                extra={<IconForCCY ccy={teamDetails.defaultCurrency} />}
                actions={compact ? null : actions}
                key={`team-profile-card-${teamId}`}
            >
                { !compact &&
                <Card.Meta 
                    description={teamDescriptor}
                    avatar={<Avatar src={teamDetails.topLogo} icon={<TeamOutlined />} size={compact ? 40 : 100} style={{borderRadius: '10%',}}/>}
                />
                }
                <Space direction='vertical' style={{ width: '100%' }}>
                    { !compact &&
                        <TeamMembersAvatars 
                            key={`team-avatars-list-${teamId}`}
                            locStrings={locStrings}
                            membersList={teamMembers}
                            divider
                            team={teamDetails}
                        />
                    }
                    <Button 
                        key={`team-view-button-${teamId}`}
                        shape='round' size='large' type='primary' 
                        style={{ borderColor: '#ff0098', background: '#ff0098', borderRadius: '4px', marginTop: '4px'}}
                        href={target}
                        onClick={onClick ? () => onClick(teamDetails) : null}
                    >
                         {actionText}<ArrowRightOutlined  />
                    </Button>
                    { !compact &&
                        <Text style={{ paddingTop: '0px', paddingLeft: '2px' }}>{teamCardTitle}</Text>
                    }
                </Space>
            </Card>

        </>
    ) : <LoadingCard />;

};


export const PersonalCard = ({
    condition,
    locStrings,
    clientId,
    client,
    currencies,
    isAdmin,
}) => {

    const [loading, setLoading] = useState(false);
//    const target = `/client/manage/${clientId}`;
    const target = isAdmin ? `/admin/clients/${clientId}/profile` : `/client/manage/${clientId}/profile`;
    const override = false;
    const idVerificationStatus = client ? client.idVerificationStatus : null;

    return (condition && !loading && client && clientId) ? (
        <>
            <Card
                loading={override}
                size='small'
                key={`card-${clientId}`}
                title={'Your Profile'}
                actions={[<Text ellipsis style={{ height: '30px' }} >{client.email}</Text>]}
                extra={<VerificationStatusIcon status={idVerificationStatus} />}
            >
                <Card.Meta 
                    title={client.displayName} 
                    description={<Text ellipsis >{client.profileBio || ' '}</Text>} 
                    avatar={<Avatar src={client.avatar}  icon={<UserOutlined />} />}
                />
                
                <Divider orientation='left' style={{ width: '100%' }}>
                    { currencies ? "Currencies" : "Profile" }
                </Divider>
                

                <Space direction='vertical' style={{ width: '100%', padding: 3 }}>
                    <Avatar.Group
                        maxCount={5}
                        maxStyle={{
                            color: '#f56a00',
                            backgroundColor: '#fde3cf',
                        }}
                    >
                        { currencies && 
                            currencies.map( (ccy, i) => (
                                <Avatar 
                                    key={`${clientId}-${ccy}`}
                                    icon={<IconForCCY ccy={ccy} />} 
                                    style={{
                                        backgroundColor: 'white',
                                    }}
                                />
                            )
                        )}
                    </Avatar.Group>
                    <Button 
                        shape='round' size='large' type='primary'
                        href={target}
                        style={{ borderRadius: '4px' }}
                    >
                        View Profile <ArrowRightOutlined  />
                    </Button>
                </Space>
            </Card>

        </>
    ) : <LoadingCard />;

};



const LoadingCard = (loading) => {
    return loading ? (
        <>
            <Card
                title={<Skeleton.Button size={'small'} active />}
            >
                <Skeleton active avatar />
                <Divider />
                <SkeletonAvatar active/>
                <SkeletonAvatar active/>
                <SkeletonAvatar active/>
                <SkeletonButton shape='round' active size='large' />
            </Card>

        </>
    ) : null;

};



export const TeamMembersAvatars = ({
    locStrings,
    membersList,
    divider,
    team,
    size,
    max,
}) => {

    const isUseDivider = !!divider;

    return membersList.length && (
        <>
            {isUseDivider &&
                <Divider
                    key={`team-members-title-${team.teamId}`}
                    orientation='left'
                    style={{ width: '100%' }}
                >
                    {`${team.teamNumberOfMembers} ${team.teamNumberOfMembers === 1 ? "member" : "members"} `}
                </Divider>
            }
            <Avatar.Group
                key={`team-members-avatars-${team.teamId}`}
                size={size}
                maxCount={!max ? 6 : max}
                maxStyle={{
                    color: '#f56a00',
                    backgroundColor: '#fde3cf',
                    borderColor: '#f56a00',
                }}
            >
                {membersList.map((member, i) => (
                    (!member.isDeleted) &&
                    <TeamMemberAvatarForRole  key={`team-member-avatar-${member.clientId}`} member={member} team={team} route={null} size={size} />
                )
                )}
            </Avatar.Group>

        </>
    );

};
