import React, { useState } from 'react';
import { TeamOutlined } from '@ant-design/icons';
import { Button, Typography, Modal , message } from 'antd';

import StrikeTeamDetailsModel from '../../models/StrikeTeamDetailsModel';
import TrackerService from '../../utils/TrackerService';

const { Text } = Typography;
const { confirm } = Modal;

export const TeamSetOwnerButton = ({ member, isAdmin, team, ...rest }) => {
  const buttonText = 'Make owner';
  const { memberClientId, teamId, displayName } = member;
  const { teamOwnerClientId, teamOwnerDisplayName, teamName } = team;
  const [displayMessage, setDisplayMessage] = useState(null);
  const [isButtonPressed, setIsButtonPressed] = useState(false);

  const updatedBlockedStateTo = (isBlocked) => {
      setIsButtonPressed(isBlocked);
      setDisplayMessage(null);
  };

  const makeMemberTeamOwner = async () => {
      const model = new StrikeTeamDetailsModel({
          teamId,
          memberClientId,
      });
      confirm({
          icon: <TeamOutlined />,
          title: 'Change team owner',
          content: 
          <>
            <br />
            <p>Please confirm you want to make {displayName} the owner for {teamName}.</p> 
          </>,
          okText: 'Confirm',
          centered: true,
  
          async onOk() {
            updatedBlockedStateTo(true);
            await model.setTeamOwnerClientId(memberClientId);
            updatedBlockedStateTo(false);
          },
      });
  };

  const isLocalHost = window.location.hostname === 'localhost';
  const isTest = isLocalHost;

  const tracker = new TrackerService({
    isTest,
  });

  const data = {
    prevTeamOwnerClientId: teamOwnerClientId, 
    prevTeamOwnerDisplayName: teamOwnerDisplayName, 
    teamOwnerClientId: memberClientId,
    teamOwnerDisplayName: displayName,
    teamId,
    teamName: teamName,
  }

  const onClickMakeOwner = async () => {
    makeMemberTeamOwner();

    tracker.mixpanelTrack('team_owner_changed', data);

    if (!isTest) {
      tracker.googleTrack('team_owner_changed', data);
    }
  };

  return (
      <>
          {!isButtonPressed &&
            <Button 
              key={`member-owner-button-${memberClientId}`} 
              type='link' 
              onClick={onClickMakeOwner} 
              {...rest} 
              icon={<TeamOutlined />}
            >
              {buttonText}
            </Button>
          }
          {displayMessage &&
            <Text 
              key={`member-owner-text-${memberClientId}`} 
              {...rest}
            >
              {displayMessage}
            </Text>
          }
      </> 
  );
};
