import firebase from 'firebase/app';
import moment from 'moment';

import FeatureFlagService from '../utils/FeatureFlagService';

export default class StrikeTapsModel {

  constructor({ viewModel, clientId, includeTestAccounts, callback,  limit }) {
    this.viewModel = viewModel;
    this.clientId = clientId;
    this.accounts = [];
    this.taps = [];
    this.client = null;
    this.observerTransactions = [];
    this.callback = callback;
    this.limit = limit;

    this.tapsById = {};
  }

  getQueryForTapsForClient(params) {
    const {
      status,
      is_test,
      product,
      clientId,
    } = params;

    const db = firebase.firestore();
    const taps = db.collectionGroup('taps');
    const query = taps
      .where('clientId', '==', clientId)
      .where('is_test', '==', is_test)
      .where('status', 'in', ['pending', 'available_requested', 'available'])
      .orderBy('created', 'desc');

    const limitedQuery = this.limit ? query.limit(this.limit) : query;

    return limitedQuery;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if(this.observerTaps) {
      this.observerTaps();
      this.observerTaps = null;
    }
  }

  async fetchAllTapsData()  {
    if (this.clientId === null) return;

    const clientId = this.clientId;

    this.stop();

    const db = firebase.firestore();
    let queryResults = [];
    this.taps = [];

    const query = this.getQueryForTapsForClient({
      clientId,
      is_test : isLocalHost,    // MARK: Only shows test accounts on localhost
    });

    this.observerTaps = query.onSnapshot(querySnapshot => {
      let index = querySnapshot.size;
      this.taps = [];
      querySnapshot.docChanges().forEach(
        change => {
          if(change.type === 'added' || change.type === 'modified') {
            const tap = change.doc.data();
            const id = change.doc.id;
            const full_tap = { ...tap, id };
            this.tapsById[id] = full_tap;
            this.updateDataSource();
          }

          if(change.type === 'removed' )  {
            const id = change.doc.id;
            this.tapsById[id] = null;
            this.updateDataSource();
          }
        }
      );
    }, err => {
      console.log(`Taps query error: ${err}`);
    });


  }

  async updateDataSource() {

    let taps = [];

    for(let prop in this.tapsById) {
      taps.push(this.tapsById[prop]);  
    }

    taps.sort((a, b) => { 
      const now = new Date();
      const nowString = now.toISOString();
      const aCreated = (a && a.created) ? a.created : nowString;
      const bCreated = (b && b.created) ? b.created : nowString;
      const aDate = aCreated.length > 0 ? aCreated : aCreated.toDate().toISOString();
      const bDate = bCreated.length > 0 ? bCreated : bCreated.toDate().toISOString();
      return (aDate < bDate) ? 1 : ( (aDate > bDate) ? -1 : 0 );
    });

    this.taps = taps;

    this.viewModel && this.viewModel.updatedTapsDataSource();
    this.callback && this.callback(this.taps);
  }

  async fetchAllWithDates(params) {
    return await this.fetchAllTapsforCSV(params);
  }

  async fetchAllTapsforCSV(params) {
    const {
      clientId,
      startDate,
      endDate,
    } = params;

    this.clientTaps = []

    const db = firebase.firestore()
    const querySnapshot = await db.collectionGroup('taps')
    .where('clientId', '==', clientId)
    .where('created', ">=" , startDate)
    .where('created', "<=" , endDate)
    .where('status', 'in', ['available', 'available_requested'])
    .orderBy('created', 'desc')
    .get();

    querySnapshot.forEach(doc => {
      const tapDocData = doc.data()
      const tapDocId = doc.id
      const tagDocId = doc.ref.parent.parent.id

      const tap = this.getTapItemDetail({ tapDocData, tapDocId, tagDocId });
      this.clientTaps.push(tap);
    });
    return this.clientTaps;
  }

  async fetchTapDetailForExport(params) {
    let tap;

    const {
      tagId,
      tapId,
    } = params;

    if (tagId && tapId) {
      const db = firebase.firestore()
      const querySnapshot = await db
            .collection('tags').doc(tagId)
            .collection('taps').doc(tapId)
            .get();

      const tapDocData = querySnapshot.data();
      const tapDocId = tapId;
      const tagDocId = tagId;      
      tap = querySnapshot.exists ? this.getTapItemDetail({ tapDocData, tapDocId, tagDocId }) : { };

      return tap;
    }
    
    tap = {}
    tap.created = "N/A";
    tap.tapId = "N/A";
    tap.source = "N/A";
    tap.refId = "N/A";
    tap.product = "N/A";
    tap.currency = "N/A"
    tap.amount = "N/A"
    tap.fee = "N/A"
    tap.netAmount = "N/A";
    tap.strikeFee = "N/A";
    tap.totalPaid = "N/A";
    tap.tagId = "N/A";
    tap.deviceLocation = "N/A";
    tap.deviceNumber = "N/A";
    tap.deviceInternalID = "N/A";
    tap.contactEmail = "N/A";
    tap.contactPhone = "N/A";
    tap.customerName = "NONE";
    tap.paymentInfo = "N/A";
    tap.teamName = "N/A";
    tap.is_test = false;

    return tap;
  }

  getTapItemDetail({ tapDocData, tapDocId, tagDocId }) {
      const amount = (parseFloat(tapDocData.amount) / 100).toFixed(2);
      const fee = (parseFloat(tapDocData.fee) / 100).toFixed(2);
      const netAmountValue = (parseFloat(tapDocData.netAmount) / 100).toFixed(2);
      const strikeFeeValue = (parseFloat(tapDocData.strikeFee) / 100).toFixed(2);
      const netAmount = tapDocData.netAmount ? netAmountValue : 'N/A';
      const strikeFee = tapDocData.strikeFee ? strikeFeeValue : 'N/A';
      const totalPaid = (parseFloat(tapDocData.amount + tapDocData.fee) / 100).toFixed(2);

      const tap = { ...tapDocData };
      tap.created = moment(tapDocData.created.toDate()).format('DD-MM-YYYY hh:mm A');
      tap.tapId = tapDocId;
      tap.source = tapDocData.source ? tapDocData.source : "No source";
      tap.refId = tapDocData.refId? tapDocData.refId : "No RefId";
      tap.product = tapDocData.product;
      tap.currency = tapDocData.paymentDetails.currency;
      tap.amountValue = amount;
      tap.feeValue = fee;
      tap.netAmountValue = netAmount;
      tap.strikeFeeValue = strikeFee;
      tap.totalPaid = tapDocData.amount + tapDocData.fee;
      tap.totalPaidValue = totalPaid;
      tap.tagId = tagDocId;
      tap.deviceLocation = tapDocData.metadata && tapDocData.metadata.nickname ? tapDocData.metadata.nickname : "N/A";
      tap.deviceNumber = tapDocData.metadata && tapDocData.metadata.number ? tapDocData.metadata.number : 'NOT SET';
      tap.deviceInternalID = tapDocData.tagUid ? tapDocData.tagUid : 'NONE';
      tap.contactEmail = tapDocData.metadata && tapDocData.metadata.email ? tapDocData.metadata.email : "N/A";
      tap.contactPhone = tapDocData.metadata && tapDocData.metadata.contact ? tapDocData.metadata.contact : "N/A"
      tap.customerName = tapDocData.customerName ? tapDocData.customerName : 'NONE'
      tap.paymentInfo = tapDocData.paymentInfo ? tapDocData.paymentInfo : 'NONE'
      tap.teamName = tapDocData.teamName ? tapDocData.teamName : 'Individual';
      tap.teamId = tapDocData.teamId;
      tap.status = tapDocData.status;
      return tap;
  }

  getFieldHeaders () {
    return [
      { label: "Tap Date & Time", key: "created" },
      { label: "Tap Reference (Tap ID)", key: "tapId" },
      { label: "Tap Source", key: "source" },
      { label: "Tap Product", key: "product" },
      { label: "Tap RefId", key: "refId" },
      { label: "Tap Currency", key: "currency" },
      { label: "Tap Amount", key: "amountValue" },
      { label: "Tap Fee", key: "feeValue" },
      { label: "Tag ID", key: "tagId" },
      { label: "Customer Name", key: "customerName" },
      { label: "Reference", key: "paymentInfo" },
      { label: "Device Location", key: "deviceLocation" },
      { label: "Device Number", key: "deviceNumber" },
      { label: "Device Internal ID", key: "deviceInternalID" },
      { label: "Contact Email", key: "contactEmail" },
      { label: "Contact Phone", key: "contactPhone" },
      { label: "Net Amount", key: "netAmountValue" },
      { label: "Applied Fee", key: "strikeFeeValue" },
      { label: "Total Paid", key: "totalPaidValue" },
      { label: "Team Name", key: "teamName" },
    ];
  }

  getExportFilename () {
    const { clientId } = this;
    const shortName = `${clientId}`;
    const now = new Date()
    const date = `${now.toISOString().split('T')[0]}`;
    return `${shortName}-transactions-${date}.csv`;
  }

}
