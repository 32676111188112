import React, { useState, useEffect } from 'react';


const stack_flex = {
  display: 'flex',
  alignItems: 'center',
};

const stack_vertical = {
  flexDirection: 'column',
};

const stack_horizontal = {
  flexDirection: 'row',
};

const contentLayout = {
  vertical: {
    start: { flex: 1, alignItems: 'center' },
    middle: { flex: 1, alignItems: 'center' },
    end: { flex: 1, alignItems: 'center' },
  },
  horizontal: {
    start: { flex: 1, alignItems: 'start' },
    middle: { flex: 1, alignItems: 'center' },
    end: { flex: 1, alignItems: 'end' },
  },
};


export const Stack = ({ flexDirection, flexDirection_internal, contentIndex, contentStart, contentMiddle, contentEnd }) => {
  return (
    <div className="auto-stack" style={{
      ...stack_flex,
      ...flexDirection,
    }}>
      <div style={{ 
        display: 'flex', 
        ...flexDirection_internal, 
        ...contentLayout[contentIndex].start, 
        // backgroundColor: 'red'  
      }}>
        {contentStart}
      </div>
      <div style={{ 
        display: 'flex', 
        ...flexDirection_internal, 
        ...contentLayout[contentIndex].middle, 
        // backgroundColor: 'green' 
      }}>
        {contentMiddle}
      </div>
      <div style={{ 
        display: 'flex', 
        ...flexDirection_internal, 
        ...contentLayout[contentIndex].end, 
        // backgroundColor: 'blue' 
      }}>
        {contentEnd}
      </div>
    </div>
  );
};

export const VStack = (props) => {
  const params = {
    flexDirection: stack_vertical,
    flexDirection_internal: stack_horizontal,
    contentIndex: 'vertical',
  };

  return (
    <Stack {...props} {...params} />
  );
};

export const HStack = (props) => {
  const params = {
    flexDirection: stack_horizontal,
    flexDirection_internal: stack_vertical,
    contentIndex: 'horizontal',
  };

  return (
    <Stack {...props} {...params} />
  );
};

export const AutoStack = ({ contentStart, contentMiddle, contentEnd, ...rest }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const props = { contentStart, contentMiddle, contentEnd };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = screenWidth <= 768; // Adjust the breakpoint as per your requirements

  return (
    <div {...rest}>
      {isMobile ? <VStack {...props} /> : <HStack {...props} />}
    </div>
  );
};

