import mixpanel from 'mixpanel-browser';
import firebase from 'firebase/app';

export default class TrackerService {

  constructor(props) {
    this.props = props;

    let baseData = null;

    if (props.object) {
      baseData = {
        // Customer
        distinct_id: props.object.striker_userId,
  
        // Client
        clientId: props.object.clientId,
        displayName: props.object.displayName,
  
        // Tag
        tagId: props.object.tagId || props.tagId,
        currency: (props.object.paymentDetails) ? (props.object.paymentDetails.currency || 'EUR') : 'EUR',
        product: props.object.product || 'tipping',
        placeOfWork: props.object.placeOfWork,
  
        // Tap
        tapId: props.ds_tapid || props.tapId,
        tagUid: props.tagUid,
        source: props.source,
        paidFees: (props.paymentDetails) ? props.paymentDetails.shouldAddOnFees : false,
      };
    }

    this.state = {
      isTest: props.object ? props.object.is_test || false : props.isTest || false,
      baseData,
    }
    
    if (this.state.isTest) {
      mixpanel.init("d640916443dff1f8941cd617479bc3e2", { "api_host": "https://api-eu.mixpanel.com" }, "");
    } else {
      mixpanel.init("69b491f259aef50b30c75e03f0f31b37", { "api_host": "https://api-eu.mixpanel.com" }, "");
    }
  }

  intValuesToDecimals({ amount, fee }) {
    const decimalAmount = (parseInt(amount) / 100).toFixed(2);
    const decimalFee = (parseInt(fee) / 100).toFixed(2);
    return {
      decimalAmount,
      decimalFee
    };
  }


  getMixpanelTrackData(name, data) {
    return {
      ...this.state.baseData,
      ...data,
    };
  }

  getGoogleTrackData(name, data) {
    return data;
  }

  mixPanelIdentify(userId, clientId, displayName, email) {
    mixpanel.identify(userId);
    if (displayName && email) {
      mixpanel.people.set({
        '$name': displayName,
        '$email': email,
        'clientId': clientId,
      });
    }
  }

  mixpanelTrack(name, data) {
    mixpanel.track(name, this.getMixpanelTrackData(name, data));
  }

  googleTrack(name, data) {
    firebase.analytics().logEvent(name, this.getGoogleTrackData(name, data));
  }

}