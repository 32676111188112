import React from 'react';
import { Spin } from 'antd';
import '../App.scss';
import { LoadingOutlined } from '@ant-design/icons';

const Spinner = ({ condition, className }) => {
    const spinnerIcon = <LoadingOutlined style={{ fontSize: "60px" }} spin />;

    return condition() ?
      <Spin className={className} indicator={spinnerIcon} />
      : null;
};

export default Spinner;