import React, { useState } from 'react';
import moment from 'moment';
import { Typography, PageHeader, Button, Tooltip, Space, Card } from 'antd';

const { Paragraph, Text } = Typography;

function getDateRange(period, minus) {
  let currentDate = moment();
  if (minus) {
    currentDate = currentDate.subtract(minus, period);
  } 
  let startDate = currentDate.clone().startOf(period).toDate();
  let endDate = currentDate.clone().endOf(period).toDate();
  const dateRange = {
    startDate,
    endDate,
  };
  return dateRange;
}

function todayDateRange() {
  return getDateRange('day');
}

function yesterdayDateRange() {
  return getDateRange('day', 1);
}

function thisWeekDateRange() {
  return getDateRange('week');
}

function lastWeekDateRange() {
  return getDateRange('week', 1);
}

function thisMonthDateRange() {
  return getDateRange('month');
}

function lastMonthDateRange() {
  return getDateRange('month', 1);
}

export const dateRangesForFilter = {
  lastMonth: lastMonthDateRange(),
  thisMonth: thisMonthDateRange(),
  lastWeek: lastWeekDateRange(),
  thisWeek: thisWeekDateRange(),
  yesterday: yesterdayDateRange(),
  today: todayDateRange(),
};

const dateRangeLabels = {
  today: 'Today',
  yesterday: 'Yesterday',
  thisWeek: 'This week',
  lastWeek: 'Last week',
  thisMonth: 'This month',
  lastMonth: 'Last month',
};

export const defaultDateRangeIndex = 'today';
export const defaultDateRangeForTeamsIndex = 'thisWeek';

function dateFormatted(date) {
  return (date && moment(date)).format('LL') || null;
}

const DateRangeDescriptor = ({ index }) => {
  const { startDate, endDate } = index ? dateRangesForFilter[index] : {};
  const dayOfStart = dateFormatted(startDate) || 'No Date';
  const dayOfEnd = dateFormatted(endDate) || 'No Date';

  return (startDate && endDate && 
    <>
      { ['lastWeek', 'lastMonth'].includes(index) && 
      <Text type='secondary'>From {dayOfStart} to {dayOfEnd}</Text>
      }
      { ['thisWeek', 'thisMonth'].includes(index) && 
      <Text type='secondary'>Since {dayOfStart}</Text>
      }      
      { ['yesterday', 'today'].includes(index) && 
      <Text type='secondary'>{' '}</Text>
      }      
    </>
  );
}

const SelectorButtonLabel = ({ currentIndex, selectedIndex }) => {
  return <p style={{ fontWeight: (currentIndex === selectedIndex ? 'bold' : 'normal')}}>
    {dateRangeLabels[currentIndex]}
  </p>;
}

const DateRangeFilterSelector = ({ selectedIndex, onSelectRange, disbaled }) => {
    const [index, setIndex] = useState(selectedIndex || defaultDateRangeIndex);
    const rangeIndexes = Object.keys(dateRangesForFilter);

    const onClickButton = (newIndex) => {
      setIndex(newIndex);
      if (onSelectRange) {
        onSelectRange(newIndex);
      }
    }

    return (<>
        {rangeIndexes.map(v => (
            <Button 
              key={`btn-ds-${v}`} 
              onClick={() => (onClickButton(v))} 
              disabled={!!disbaled}
              size='small'
            > 
              <SelectorButtonLabel  selectedIndex={index} currentIndex={v} />
            </Button>
        ))
        }
        { index &&
          <div>
            <DateRangeDescriptor index={index}  />
          </div>
        }
    </>);
};

export default DateRangeFilterSelector;
