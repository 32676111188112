import React, { useState } from 'react';
import { QrcodeOutlined } from '@ant-design/icons';
import {
    Button,
    Space
} from 'antd';

import QRImageView from '../customer/QRImageView';



export const TeamProfileQRCodeView = ({ tagId, onChange, visible, ...rest }) => {
    const [isQRVisible, setIsQRVisible] = useState(visible);

    const onClick = () => {
        setIsQRVisible(!isQRVisible);
        onChange && onChange(isQRVisible);
    };

    return (
        <Space direction='vertical' align='center' style={{ width: '240px' }} {...rest}>
            {tagId && isQRVisible &&
                <div style={{ width: '240px' }}>
                    <QRImageView
                        tagId={tagId}
                        tag={{ tagId }}
                        nosubtext />
                </div>}
            <Button
                type='text'
                onClick={onClick}
                icon={<QrcodeOutlined />}
            >
                {isQRVisible ? 'Hide QR' : 'Show QR'}
            </Button>
        </Space>
    );
};
