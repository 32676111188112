import React, { useState } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import { DollarCircleFilled, EuroCircleFilled, PoundCircleFilled, QrcodeOutlined, ShareAltOutlined, EditOutlined, CopyOutlined, LinkOutlined , LoadingOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { message, List, Button, Tag, Card, Input, InputNumber, Row, Col, Space, Checkbox, Upload, Modal, Icon } from 'antd';
import { Link } from 'react-router-dom';
import Avatar from 'antd/lib/avatar/avatar';
import firebase from 'firebase/app';
import ReactCrop from 'react-image-crop';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import ProductSwitcher, { capitalize }  from '../../utils/ProductSwitcher';
import RunningTotalsCard from '../customer/RunningTotalsCard';
import { PaymentsMaxAmounts } from '../../utils/Constants';
import { paymentUrl, shareUrl, shareQRUrl } from '../../utils/URLBuilder';
import QRImageView from '../customer/QRImageView';
import MoneyTitle from '../customer/MoneyTitle';
import getBase64 from '../../utils/GetBase64';
import {ccyIsoCodeToSymbolMap} from '../../utils/CurrencyUtils';
import { TagSpecificPricingView } from '../admin/TagSpecificPricingView';

import { PauseButton } from './AccountProfileComponents';

const { TextArea } = Input;
const short = require('short-uuid');

const ccy = ccyIsoCodeToSymbolMap;

const ccy_icons = {
    'EUR': <EuroCircleFilled style={{ fontSize: '20px', color: '#00c' }} />,
    'USD': <DollarCircleFilled style={{ fontSize: '20px', color: '#08c' }} />,
    'GBP': <PoundCircleFilled style={{ fontSize: '20px', color: '#70c' }} />,
};

/*** MAX value for a default selection is 250 €/£/$ */
const MAX_TAG_VALUE = 250;
const MIN_TAG_VALUE = 1;

const cardStyle = {
    width: '100%',
    textAlign: 'start',
    backgroundColor: 'transparent'
};

function iconForCCY(ccy) {
    let icon = ccy_icons[ccy];
    return (icon) ? icon : <DollarCircleFilled style={{ fontSize: '20px', color: '#08c' }} />;
}


function itemTitle(item) {
    let title = item.displayName ? `${item.displayName}` : "";
    title = title + ((item.profileBio) ? `, ${item.profileBio}` : "");
    return title;
};

const teamTag = (tag) => {
    return tag.isTeamTag ? <Tag color={'#2db7f5'}>{'Team'}</Tag> : null;
}

export const TagsListItemContextCard = ({ type, tag, tagId, locStrings, onEditTag }) => {
    const { paymentDetails, displayName, profileBio, clientId } = tag;

    let currency = paymentDetails ? paymentDetails.currency : 'EUR';
    currency = currency || 'EUR';
    const topLogoSrc = tag.topLogo;

    const cardStyle = {
        width: '100%',
        textAlign: 'start',
        backgroundColor: !!tag.is_test ? 'transparent' : 'transparent'
    };

    const topLogoStyle = {
        width: topLogoSrc ? '70%' : '0%',
        marginLeft: 'auto', marginRight: 'auto',
    };

    const [isEditVisible, setIsEditVisible] = useState(false);
    const [inputDisplayName, setDisplayName] = useState(displayName || "");
    const [inputProfileBio, setProfileBio] = useState(profileBio || "");
    const [uploading, setUploading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const timestamp = Date.now();
    const fileName = clientId ? `${clientId}-${timestamp}.jpg` : `${tagId}-${timestamp}.jpg`;

    const defaultCrop = {
        unit: '%',
        width: 30,
        aspect: 1 / 1,
    };

    const [crop, setCrop] = useState(defaultCrop);
    const [croppedImage, setCroppedImage] = useState(null);
    const [userUploadedImage, setUserUploadedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(tag && tag.avatar ? tag.avatar : null);
    const [imageRef, setImageRef] = useState(null);

    const handleDisplayNameChange = (e) => {
        setDisplayName(e.target.value);
    }

    const handleProfileBioChange = (e) => {
        setProfileBio(e.target.value);
    }

    const saveTagData = async () => {
        message.loading('Saving..');
        setIsEditVisible(false);
        await onEditTag(
            tag, {
            displayName: inputDisplayName,
            profileBio: inputProfileBio,
            modified: true,
        } );
        message.destroy();
        message.success('New tag content is live!', 1.5)
    }

    const buttonStyle = { marginTop: '5px', marginBottom:'5px' };

    const editAction = (<Button style={buttonStyle} type='text' onClick={(e) => ( setIsEditVisible(true) )}><EditOutlined /> Edit Tag Content</Button>);
    const saveAction = (<Button style={buttonStyle} type='primary' onClick={(e) => ( saveTagData() )}>Save</Button>);
    const cancelAction = (<Button style={buttonStyle} type='text' onClick={(e) => ( setIsEditVisible(false) )}>Cancel</Button>);

    const actions = [];
    if(isEditVisible) {
        actions.push(saveAction);
        actions.push(cancelAction);
    }
    if(!isEditVisible) actions.push(editAction);

    const beforeUpload = (file) => {
        setUserUploadedImage(URL.createObjectURL(file));
        setModalVisible(true);

        // return false to not have it trigger upload, we want the file but no the actual uploading
        return false;
    }

    const handleOk = () => {
        setModalVisible(false);
        uploadImage(croppedImage);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    const onCropComplete = crop => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        setCrop(crop);
    };

    const makeClientCrop = async (crop) => {
        if (imageRef && crop.width && crop.height) {
            const croppedImage = await getCroppedImg(
                imageRef,
                crop,
            );
            setCroppedImage(croppedImage);
        }
    }

    const onImageLoaded = image => {
        setImageRef(image);
    };

    const getCroppedImg = (image, crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = crop.width;
        canvas.height = crop.height;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            if (!blob) {
              console.error('Canvas is empty');
              return;
            }
            blob.name = fileName;
            resolve(blob);
          }, 'image/jpeg');
        });
    }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setUploading(true);
          return;
        }
        if (info.file.status === 'done') {
          getBase64(info.file.originFileObj, imageUrl => {
            setImageUrl(imageUrl);
            setUploading(false);
          });
        }
      };

    const uploadImage =  async (file) => {
        const storageRef = firebase.storage().ref();

        if (tag.clientId) {
            const clientTagImagesRef = storageRef.child(`public-images/client-images/${clientId}/tag-images/${tagId}/${fileName}`);

            const resultSnapshot = await clientTagImagesRef.put(file);
            const imageUrl = await resultSnapshot.ref.getDownloadURL();

            setImageUrl(imageUrl);

            const db = firebase.firestore();
            await db.collection('tags').doc(tagId).update({
                avatar: imageUrl,
                modified: true,
            });
        } else {
            const templateTagImagesStorageRef = storageRef.child(`public-images/template-tag-images/${tagId}/${fileName}`);

            const resultSnapshot = await templateTagImagesStorageRef.put(file);
            const imageUrl = await resultSnapshot.ref.getDownloadURL();

            setImageUrl(imageUrl);

            const issuerId = "strike";
            const db = firebase.firestore();

            await db.collection('issuers').doc(issuerId).collection("template_tags").doc(tagId).update({
                avatar: imageUrl,
                modified: false
            });
        }
    }

    const uploadButton = (
        <div>
          { uploading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const title = () => {
        let title = `Strikepay ${capitalize(tag.product)} Tag`;

        if (tag.metadata && tag.metadata.nickname) {
            title += ` - ${tag.metadata.nickname}`;
        }

        return title;
    }

    return (
        <Card
            size="default"
            type={type}
            hoverable
            style={cardStyle}
            cover={<img style={topLogoStyle} src={topLogoSrc} />}
            extra={iconForCCY(currency)}
            actions={actions}
            title={title()}
        >
            { !isEditVisible && (
                <Card.Meta
                    avatar={<Avatar src={tag.avatar} size='default' />}
                    title={tag.displayName || 'Display Name'}
                    description={tag.profileBio || 'Profile bio'}
                />
            )}

            { isEditVisible && (
                <>
                    <div style={{ marginLeft: "auto", marginRight: 'auto', width: "intrinsic", marginBottom: "10px"}}>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={uploadImage}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '104px', height: '104px', borderRadius: '100%' }} /> : uploadButton}
                        </Upload>
                    </div>

                    <Modal
                        visible={modalVisible}
                        title="Crop your image"
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={[
                        <Button key="back" onClick={handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={uploading} onClick={handleOk}>
                            Upload
                        </Button>,
                        ]}
                    >
                        <div className="cropper">
                        { userUploadedImage && (
                            <ReactCrop
                                src={userUploadedImage}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={onImageLoaded}
                                onComplete={onCropComplete}
                                onChange={onCropChange}
                            />
                        )}
                        </div>

                    </Modal>

                    <Input addonBefore={"Display name"} value={inputDisplayName} onChange={handleDisplayNameChange}/>
                    <p style={{margin: '10px 0 0 0'}}>Profile bio:</p>
                    <TextArea value={inputProfileBio} onChange={handleProfileBioChange}/>
                </>
            )}
        </Card>
    );
}

export const TeamTagsListItemContextCard = ({ type, tag, tagId, locStrings, onEditTag }) => {
    const { paymentDetails, teamName, teamDescription, clientId } = tag;

    let currency = paymentDetails ? paymentDetails.currency : 'EUR';
    currency = currency || 'EUR';
    const topLogoSrc = tag.topLogo;

    const cardStyle = {
        width: '100%',
        textAlign: 'start',
        backgroundColor: !!tag.is_test ? 'transparent' : 'transparent'
    };

    const topLogoStyle = {
        width: topLogoSrc ? '70%' : '0%',
        marginLeft: 'auto', marginRight: 'auto',
    };

    const [isEditVisible, setIsEditVisible] = useState(false);
    const [inputTeamName, setTeamName] = useState(teamName || "");
    const [inputTeamDescription, setTeamDescription] = useState(teamDescription || "");
    const [uploading, setUploading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const timestamp = Date.now();
    const fileName = clientId ? `${clientId}-${timestamp}.jpg` : `${tagId}-${timestamp}.jpg`;

    const defaultCrop = {
        unit: '%',
        width: 30,
        aspect: 1 / 1,
    };

    const [crop, setCrop] = useState(defaultCrop);
    const [croppedImage, setCroppedImage] = useState(null);
    const [userUploadedImage, setUserUploadedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(tag && tag.avatar ? tag.avatar : null);
    const [imageRef, setImageRef] = useState(null);

    const handleTeamNameChange = (e) => {
        setTeamName(e.target.value);
    }

    const handleTeamDescriptionChange = (e) => {
        setTeamDescription(e.target.value);
    }

    const saveTagData = async () => {
        message.loading('Saving..');
        setIsEditVisible(false);
        await onEditTag(
            tag, {
            teamName: inputTeamName,
            teamDescription: inputTeamDescription,
            nameOrDescOverrid: true
        });
        message.destroy();
        message.success('New tag content is live!', 1.5)
    }

    const buttonStyle = { marginTop: '5px', marginBottom:'5px' };

    const editAction = (<Button style={buttonStyle} type='text' onClick={(e) => ( setIsEditVisible(true) )}><EditOutlined /> Edit Tag Content</Button>);
    const saveAction = (<Button style={buttonStyle} type='primary' onClick={(e) => ( saveTagData() )}>Save</Button>);
    const cancelAction = (<Button style={buttonStyle} type='text' onClick={(e) => ( setIsEditVisible(false) )}>Cancel</Button>);

    const actions = [];
    if(isEditVisible) {
        actions.push(saveAction);
        actions.push(cancelAction);
    }
    if(!isEditVisible) actions.push(editAction);

    const beforeUpload = (file) => {
        setUserUploadedImage(URL.createObjectURL(file));
        setModalVisible(true);

        // return false to not have it trigger upload, we want the file but no the actual uploading
        return false;
    }

    const handleOk = () => {
        setModalVisible(false);
        uploadImage(croppedImage);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    const onCropComplete = crop => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        setCrop(crop);
    };

    const makeClientCrop = async (crop) => {
        if (imageRef && crop.width && crop.height) {
            const croppedImage = await getCroppedImg(
                imageRef,
                crop,
            );
            setCroppedImage(croppedImage);
        }
    }

    const onImageLoaded = image => {
        setImageRef(image);
    };

    const getCroppedImg = (image, crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = crop.width;
        canvas.height = crop.height;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            if (!blob) {
              console.error('Canvas is empty');
              return;
            }
            blob.name = fileName;
            resolve(blob);
          }, 'image/jpeg');
        });
    }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setUploading(true);
          return;
        }
        if (info.file.status === 'done') {
          getBase64(info.file.originFileObj, imageUrl => {
            setImageUrl(imageUrl);
            setUploading(false);
          });
        }
      };

    const uploadImage =  async (file) => {
        const storageRef = firebase.storage().ref();

        if (tag.clientId) {
            const clientTagImagesRef = storageRef.child(`public-images/client-images/${clientId}/tag-images/${tagId}/${fileName}`);

            const resultSnapshot = await clientTagImagesRef.put(file);
            const imageUrl = await resultSnapshot.ref.getDownloadURL();

            setImageUrl(imageUrl);

            const db = firebase.firestore();
            await db.collection('tags').doc(tagId).update({
                avatar: imageUrl,
                modified: true,
            });
        } else {
            const templateTagImagesStorageRef = storageRef.child(`public-images/template-tag-images/${tagId}/${fileName}`);

            const resultSnapshot = await templateTagImagesStorageRef.put(file);
            const imageUrl = await resultSnapshot.ref.getDownloadURL();

            setImageUrl(imageUrl);

            const issuerId = "strike";
            const db = firebase.firestore();

            await db.collection('issuers').doc(issuerId).collection("template_tags").doc(tagId).update({
                avatar: imageUrl,
                modified: false
            });
        }
    }

    const uploadButton = (
        <div>
          { uploading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const title = () => {
        let title = `STRIKE ${capitalize(tag.product)} Tag`;

        if (tag.metadata && tag.metadata.nickname) {
            title += ` - ${tag.metadata.nickname}`;
        }

        return title;
    }

    return (
        <Card
            size="default"
            type={type}
            hoverable
            style={cardStyle}
            cover={<img style={topLogoStyle} src={topLogoSrc} alt={'tag logo'}/>}
            extra={iconForCCY(currency)}
            actions={actions}
            title={<Space>{title()}{teamTag(tag)}</Space>}
        >
            { !isEditVisible && (
                <Card.Meta
                    avatar={<Avatar src={tag.avatar} size='default' />}
                    title={tag.teamName || 'Team Name'}
                    description={tag.teamDescription || 'Team Description'}
                />
            )}

            { isEditVisible && (
                <>
                    <div style={{ marginLeft: "auto", marginRight: 'auto', width: "intrinsic", marginBottom: "10px"}}>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={uploadImage}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '104px', height: '104px', borderRadius: '100%' }} /> : uploadButton}
                        </Upload>
                    </div>

                    <Modal
                        visible={modalVisible}
                        title="Crop your image"
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={[
                        <Button key="back" onClick={handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={uploading} onClick={handleOk}>
                            Upload
                        </Button>,
                        ]}
                    >
                        <div className="cropper">
                        { userUploadedImage && (
                            <ReactCrop
                                src={userUploadedImage}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={onImageLoaded}
                                onComplete={onCropComplete}
                                onChange={onCropChange}
                            />
                        )}
                        </div>

                    </Modal>

                    <Input addonBefore={"Team Name"} value={inputTeamName} onChange={handleTeamNameChange}/>
                    <p style={{margin: '10px 0 0 0'}}>Profile bio:</p>
                    <TextArea value={inputTeamDescription} onChange={handleTeamDescriptionChange}/>
                </>
            )}
        </Card>
    );
}

export const TagsListItemMetadataCard = ({ type, tag, tagId, locStrings, onEditTag }) => {
    const { metadata } = tag;

    const [isEditVisible, setIsEditVisible] = useState(false);
    const [inputNickname, setNickname] = useState(metadata ? (metadata.nickname ? metadata.nickname : "") : "");
    const [inputLocation, setLocation] = useState(metadata ? (metadata.location ? metadata.location : "") : "");
    const [inputNumber, setNumber] = useState(metadata ? (metadata.number ? metadata.number : "") : "");
    const [inputContactDetails, setContactDetails] = useState(metadata ? (metadata.contact ? metadata.contact : "") : "");
    const [inputContactEmail, setContactEmail] = useState(metadata ? (metadata.email ? metadata.email : "") : "");

    const saveTagData = async () => {
        message.loading('Saving..');
        setIsEditVisible(false);
        await onEditTag(
            tag, {
                metadata: {
                    nickname: inputNickname,
                    location: inputLocation,
                    number: inputNumber,
                    contact: inputContactDetails,
                    email: inputContactEmail,
                    lastModified: firebase.firestore.Timestamp.fromDate(new Date()),
                },
                setupRequired: false,
            }
        );
        message.destroy();
        message.success('New location saved!', 1.5)
    }

    const buttonStyle = { marginTop: '5px', marginBottom:'5px' };

    const editAction = (<Button style={buttonStyle} type='text' onClick={(e) => ( setIsEditVisible(true) )}><EditOutlined /> Edit Tag Location</Button>);
    const saveAction = (<Button style={buttonStyle} type='primary' onClick={(e) => ( saveTagData() )}>Save</Button>);
    const cancelAction = (<Button style={buttonStyle} type='text' onClick={(e) => ( setIsEditVisible(false) )}>Cancel</Button>);

    const actions = [];
    if(isEditVisible) {
        actions.push(saveAction);
        actions.push(cancelAction);
    }
    if(!isEditVisible) actions.push(editAction);

    return (
        <Card
            title="Private Data (Not shown to users)"
            size="default"
            type={type}
            style={cardStyle}
            actions={actions}
            hoverable
        >
            { !isEditVisible && (
                <div>
                    <p>{`Place Name: ` + (tag.metadata ? tag.metadata.nickname : 'N/A')}</p>
                    <p>{`Address: ` + (tag.metadata ? tag.metadata.location : 'N/A')}</p>
                    <p>{`ID: ` + (tag.metadata ? tag.metadata.number : 'N/A')}</p>
                    <p>{`Phone: ` + (tag.metadata ? tag.metadata.contact : 'N/A')}</p>
                    <p>{`Contact Email: ` + (tag.metadata ? tag.metadata.email : 'N/A')}</p>
                    <p>{`Updated: ` + ((tag.metadata && tag.metadata.lastModified) ? moment(tag.metadata.lastModified.toMillis()).format('DD/MM/YYYY, h:mma') : 'N/A')}</p>
                </div>
            )}

            { isEditVisible && (
                <>
                    <Input placeholder={"Place Name"} addonBefore={"Place Name:"} value={inputNickname} onChange={(e) => setNickname(e.target.value)} />
                    <Input placeholder={"Address"} addonBefore={"Address:"} value={inputLocation} onChange={(e) => setLocation(e.target.value)} />
                    <Input placeholder={"Unit ID"} addonBefore={"Unit ID:"} value={inputNumber} onChange={(e) => setNumber(e.target.value)} />
                    <Input placeholder={"Phone"} addonBefore={"Phone:"} value={inputContactDetails} onChange={(e) => setContactDetails(e.target.value)} />
                    <Input placeholder={"example@org.com"} addonBefore={"Contact Email:"} value={inputContactEmail} onChange={(e) => setContactEmail(e.target.value)} />
                </>
            )}
        </Card>
    );
}

export const TemplateTagListItemActionTextCard = ({ type, tag, tagId, locStrings, onEditTag }) => {
    const { actionText } = tag;

    const [uploading, setUploading] = useState(false);
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [heading, setHeading] = useState(actionText && actionText.heading);
    const [subheading, setSubheading] = useState(actionText && actionText.subheading);
    const [subsubheading, setSubsubheading] = useState(actionText && actionText.subsubheading);
    const [signupImage, setSignupImage] = useState(actionText && actionText.signupImage);
    const [questionsTitle, setQuestionsTitle] = useState(actionText && actionText.questionsTitle);
    const [questions, setQuestions] = useState(actionText && actionText.questions);

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setUploading(true);
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl => {
                setImageUrl(imageUrl);
                setUploading(false);
            });
        }
      };

    const addEmptyActionText = async () => {
        message.loading('Saving..');
        setIsEditVisible(false);
        await onEditTag(
            tag, {
                actionText: {
                    questions: [],
                },
                updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
            }
        );
        message.destroy();
        message.success('Template tag data updated!', 1.5)
    }

    if (!actionText) {
        return (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Button type='secondary' onClick={addEmptyActionText}>Add Action Text</Button>
            </div>
        );
    }

    const saveTagData = async () => {
        message.loading('Saving..');
        setIsEditVisible(false);
        await onEditTag(
            tag, {
                actionText: {
                    heading: heading ? heading : null,
                    subheading: subheading ? subheading : null,
                    subsubheading: subsubheading ? subsubheading : null,
                    signupImage: signupImage ? signupImage : null,
                    questionsTitle: questionsTitle ? questionsTitle : null,
                    questions,
                },
                updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
            }
        );
        message.destroy();
        message.success('Template tag data updated!', 1.5)
    }

    const uploadImage =  async (file) => {
        const storageRef = firebase.storage().ref();
        const timestamp = Date.now();
        const templateTagImagesStorageRef = storageRef.child(`public-images/template-tag-images/${tagId}/${tagId}-${timestamp}.jpg`);

        const resultSnapshot = await templateTagImagesStorageRef.put(file);
        const imageUrl = await resultSnapshot.ref.getDownloadURL();

        const issuerId = "strike";
        const db = firebase.firestore();

        await db.collection('issuers').doc(issuerId).collection("template_tags").doc(tagId).update({
            avatar: imageUrl,
            modified: false
        });
    }

    const addNewQuestion = () => {
        setQuestions([
            ...questions,
            {
                question: "",
                answer: "",
            }
        ]);
    }

    const deleteQuestion = (index) => {
        let items = [...questions];
        items.splice(index, 1)
        setQuestions(items);
    }

    const updateQuestion = (index, value, isQuestion) => {
        let items = [...questions];
        let item = {...items[index]};

        if (isQuestion) {
            item.question = value;
        } else {
            item.answer = value;
        }

        items[index] = item;
        setQuestions(items);
    }

    const buttonStyle = { marginTop: '5px', marginBottom:'5px' };

    const editAction = (<Button style={buttonStyle} type='text' onClick={(e) => ( setIsEditVisible(true) )}><EditOutlined /> Edit Template Data</Button>);
    const saveAction = (<Button style={buttonStyle} type='primary' onClick={(e) => ( saveTagData() )}>Save</Button>);
    const cancelAction = (<Button style={buttonStyle} type='text' onClick={(e) => ( setIsEditVisible(false) )}>Cancel</Button>);
    
    const actions = [];
    if(isEditVisible) {
        actions.push(saveAction);
        actions.push(cancelAction);
    }
    if(!isEditVisible) actions.push(editAction);

    const uploadButton = (
        <div>
          { uploading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Card
            title="Template Data"
            size="default"
            type={type}
            style={cardStyle}
            actions={actions}
            hoverable
        >
            { !isEditVisible && (
                <div>
                    <img src={signupImage} style={{ width: '100%', paddingBottom: '16px' }} alt={heading} />
                    <p><strong>Heading: </strong>{(heading ? heading : 'N/A')}</p>
                    <p><strong>Subheading: </strong>{(subheading ? subheading : 'N/A')}</p>
                    <p><strong>Subsubheading:</strong>{(subsubheading ? subsubheading : 'N/A')}</p>
                    <p><strong>Questions Heading: </strong>{(questionsTitle ? questionsTitle : 'N/A')}</p>
                    <br />
                    <p><strong>Questions</strong></p>
                    <QuestionsDisplay questions={questions} />
                </div>
            )}

            { isEditVisible && (
                <>
                    <div style={{ marginLeft: "auto", marginRight: 'auto', width: "intrinsic", marginBottom: "10px"}}>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            handleChange={handleChange}
                            customRequest={uploadImage}
                        >
                            {signupImage ? <img src={signupImage} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </div>
                    <Input placeholder={"Heading"} value={heading} onChange={(e) => setHeading(e.target.value)} />
                    <Input placeholder={"Subheading"} value={subheading} onChange={(e) => setSubheading(e.target.value)} />
                    <Input placeholder={"Subsubheading"} value={subsubheading} onChange={(e) => setSubsubheading(e.target.value)} />
                    <Input placeholder={"Questions Heading"} value={questionsTitle} onChange={(e) => setQuestionsTitle(e.target.value)} />
                    <p style={{ paddingTop: '16px' }}><strong>Questions</strong></p>
                    <QuestionsEdit questions={questions} onUpdateQuestion={updateQuestion} onDeleteQuestion={deleteQuestion} />
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() => addNewQuestion()}
                        type="secondary"
                        style={{ width: '100%' }}
                    >Add Question</Button>
                </>
            )}
        </Card>
    );
}

const QuestionsDisplay = ({ questions }) => {
    if (questions) {
        return(
            questions.map((question, index) => {
                return <p key={index}><strong>Q: </strong>{question.question} - <strong>A: </strong>{question.answer}</p>
            })
        );
    } else {
        return null;
    }
}

const QuestionsEdit = ({ questions, onUpdateQuestion, onDeleteQuestion }) => {
    if (questions) {
        return(
            questions.map((q, index) => {
                return (
                    <div key={index} style={{ paddingBottom: '4px' }}>
                        <Input placeholder={"question"} value={q.question} onChange={(e) => onUpdateQuestion(index, e.target.value, true)} style={{ width: '44%', marginRight: '1%'}} />
                        <Input placeholder={"answer"} value={q.answer} onChange={(e) => onUpdateQuestion(index, e.target.value, false)} style={{ width: '48%', marginLeft: '1%', marginRight: '1%' }} />
                        <Button
                            icon={<MinusCircleOutlined />}
                            onClick={() => { onDeleteQuestion(index) }}
                            type="secondary"
                            style={{ width: '5%' }}
                        />
                    </div>
                )
            })
        );
    } else {
        return null;
    }
}

export const TagsListItemValuesCard = ({ type, tag, onEditTag }) => {
    const { paymentDetails, values, selectedValueByIndex } = tag;
    let currency = paymentDetails ? paymentDetails.currency : 'EUR';
    currency = currency || 'EUR';

    const percentage = (100 / values.length).toFixed(0);
    const widthPercentage = `${percentage}%`;
    let spanInputFields = (24/values.length).toFixed(0);
    spanInputFields = spanInputFields < 6 ? 6 : spanInputFields;

    const [isEditVisible, setIsEditVisible] = useState(false);
    const [inputValues, setInputValues] = useState(values || []);

    const setValuesAtIndex = (oldValues, value, index) => {
        let newValues = [ ...oldValues ];
        newValues[index] = value * 100;
        setInputValues([...newValues]);
    };

    const saveValuesArray = async () => {
        message.loading('Saving values..');
        setIsEditVisible(false);
        await onEditTag(
            tag, {
            values : [ ...inputValues ]
        } );
        message.destroy();
        message.success('New tag values live!', 1.5)
    }

    const saveSelectedValue = async (index) => {
        message.loading('Saving new default..');
        await onEditTag(
            tag,
            { selectedValueByIndex : index }
        );
        message.destroy();
        message.success('New default value live!', 1.5)
    }


    const cardStyle = {
        width: '100%',
        textAlign: 'start',
    };

    const gridStyle = {
        width: widthPercentage,
        textAlign: 'center',
    };

    const gridStyleSelected = {
        ...gridStyle,
        backgroundColor: '#535353',
    };

    const valueStyle = {
        fontWeight: 'normal',
        fontSize: '14px',
    };

    const valueStyleSelected = {
        ...valueStyle,
        fontWeight: 'bold',
        color: 'white',
    };

    const buttonStyle = { marginTop: '5px', marginBottom:'5px' };

    const editAction = (<Button style={buttonStyle} type='text' onClick={(e) => ( setIsEditVisible(true) )}><EditOutlined /> Edit Tag Values</Button>);
    const saveAction = (<Button style={buttonStyle} type='primary' onClick={(e) => ( saveValuesArray() )}>Save</Button>);
    const cancelAction = (<Button style={buttonStyle} type='text' onClick={(e) => ( setIsEditVisible(false) )}>Cancel</Button>);

    const actions = [];
    if(isEditVisible) {
        actions.push(saveAction);
        actions.push(cancelAction);
    }
    if(!isEditVisible) actions.push(editAction);

    return (
        <Card
            title="Choose default value"
            size="default"
            type={type}
            style={cardStyle}
            actions={actions}
            hoverable
        >
            { !isEditVisible && inputValues.map((value, index) =>
            (
                <Card.Grid
                    key={`grid-${index}`}
                    style={(index === selectedValueByIndex) ? gridStyleSelected : gridStyle}
                    size="small"
                    onClick={(e) => ( saveSelectedValue(index) )}
                >
                    {
                    (!isEditVisible) &&
                    <p style={(index === selectedValueByIndex) ? valueStyleSelected : valueStyle}>{`${ccy[currency] || currency}${(value / 100).toFixed(0)}`}</p>
                    }
                </Card.Grid>
            )
            )}

            { isEditVisible && (
                (<Row  gutter={[0, 0]}>
                    {(inputValues.map(
                        (value, index) =>
                            (<Col key={`col-input-${index}`} span={spanInputFields}>
                                <InputNumber
                                    key={`input-${index}`}
                                    style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto'}}
                                    placeholder={`${ccy[currency] || currency}${(value / 100).toFixed(0)}`}
                                    defaultValue={(value / 100).toFixed(0)}
                                    min={MIN_TAG_VALUE}
                                    max={MAX_TAG_VALUE}
                                    precision={0}
                                    size={"large"}
                                    onChange={(v) => (setValuesAtIndex(inputValues, v, index))}
                                />
                            </Col>)
                        )
                    )}
                </Row>)
            )}


        </Card>

    );
}

export const TagToggleFeesCheckbox = ({ type, tag, onEditTag, locStrings }) => {
    const { paymentDetails } = tag;
    const shouldAddOnFees = paymentDetails ? !!paymentDetails.shouldAddOnFees : true;
    const label = "Pre-select accept fees option";
    const successFalse = "You will pay fees by default";
    const successTrue = "The payor will pay fees by default";

    const [checked, setChecked] = useState(shouldAddOnFees);

    const onChange = async (e) => {
        message.loading('Saving settings..');
        setChecked(e.target.checked);
        await onEditTag(
            tag, {
            paymentDetails : {
                shouldAddOnFees: e.target.checked
            }
        } );
        message.destroy();
        message.success(e.target.checked ? successTrue : successFalse, 1.5)
    }


    return (
        <p style={{ marginBottom: '20px' }}>
            <Checkbox
                checked={checked}
                disabled={false}
                onChange={onChange}
            >
                {label}
            </Checkbox>
        </p>
    );
}



export const TagsListItemDetail = ({ tag, tagId, locStrings, onEditTag, isAdmin }) => {
    const statusColor = {
        'available': 'geekblue',
        'live': 'green',
    };

    const tagStatus = {
        color: !tag.is_test ?  (tag.status ? (statusColor[tag.status] || 'warning') : 'default') : 'red',
        text: !tag.is_test ? capitalize(tag.status || "unknown") : "Test"
    }

    const statusText = tag.status ? (<Tag color={tagStatus.color}>{tagStatus.text}</Tag>) : null;
    const templateTagId = tag.templateTagId ? (<Tag color='#ff0098'>{tag.templateTagId}</Tag>) : null;
    const templateTagUid = tag.tagUid ? (<Tag color='geekblue'>{tag.tagUid}</Tag>) : null;
    const title = templateTagId && templateTagUid
                    ? (<Space align='baseline'> {templateTagId} {templateTagUid} </Space>)
                    : (tag.id ? (<Tag>{tag.id}</Tag>) : null);
    const showMetadataEdit = tag.product === "giving" || tag.product === "payment";
    const isTemplateTag = window.location.pathname.includes('admin/issuers');

    return (
        <Card size='default' style={{ textAlign: 'start', backgroundColor: 'transparent' }}>
            {!tag.isTeamTag && <TagsListItemContextCard type={'inner'} tag={tag} tagId={tagId} locStrings={locStrings} onEditTag={onEditTag} />}

            {tag.isTeamTag && <TeamTagsListItemContextCard type={'inner'} tag={tag} tagId={tagId} locStrings={locStrings} onEditTag={onEditTag} />}

            { tag.values && tag.values.length > 0 &&
                <TagsListItemValuesCard type={'inner'} tag={tag} locStrings={locStrings} onEditTag={onEditTag}/>
            }
            <p/>
            <TagToggleFeesCheckbox
                type="inner"
                tag={tag}
                onEditTag={onEditTag}
                locStrings={locStrings}
                style={{ marginTop: '8px' }}
            />
            { showMetadataEdit &&
                <TagsListItemMetadataCard type={'inner'} tag={tag} tagId={tagId} locStrings={locStrings} onEditTag={onEditTag} />
            }
            { isTemplateTag &&
                <TemplateTagListItemActionTextCard type={'inner'} tag={tag} tagId={tagId} locStrings={locStrings} onEditTag={onEditTag} />
            }
            { isAdmin && tag.paymentDetails && tag.paymentDetails.currency && tag.product &&
                <TagSpecificPricingView pricing={tag.tagSpecificPricing} currency={tag.paymentDetails.currency} product={tag.product} />
            }
            <Space align='baseline' style={{ marginTop: '5px'}}>
                {statusText}
                {title}
            </Space>

        </Card>
    );
};

export const TagsListItemCompact = ({ tag, tagId, locStrings, isAdmin }) => {
    const statusColor = {
        'available': 'geekblue',
        'live': 'green',
    };

    const tagStatus = {
        color: !tag.is_test ?  (tag.status ? (statusColor[tag.status] || 'warning') : 'default') : 'red',
        text: !tag.is_test ? capitalize(tag.status || "unknown") : "Test"
    }

    const statusText = tag.status ? (<Tag color={tagStatus.color}>{tagStatus.text}</Tag>) : null;
    const useTagUid = tag.tagUid ? (<Tag color='black'>{tag.tagUid}</Tag>) : null;
    const templateTagUid = useTagUid ? useTagUid : (<Tag color='black'>{tag.id}</Tag>);
    const templateTagId = (tag.templateTagId) ? (<Tag color='#ff0098'>{tag.templateTagId}</Tag>) : null;

    const { paymentDetails } = tag;

    let currency = paymentDetails ? paymentDetails.currency : 'EUR';
    currency = currency || 'EUR';

    const getTopLogo = (tag) => {
        if (!tag.isTeamTag) {
            return tag.topLogo;//Support for existing topLogo which is 'not' of a team
        }
        return '';
    }

    const cardStyle = {
        width: '100%',
        textAlign: 'start',
        backgroundColor: 'transparent'
    };

    const topLogoStyle = {
        width: getTopLogo(tag) ? '70%' : '0%',
        marginLeft: 'auto', marginRight: 'auto',
    };

    const cardTitle = () => {
        let title = `${capitalize(tag.product)} Tag`;

        if (tag.metadata && tag.metadata.nickname) {
            title += ` - ${tag.metadata.nickname}`;
        }

        return title;
    }

    const getDisplayName = (tag) => {
        if(tag.isTeamTag){
            return (tag.teamName ? tag.teamName : 'Team Tag');
        }
        return (tag.displayName ? tag.displayName : 'Display Name');
    }

    const getAvatar = (tag) => {
        if (tag.isTeamTag && tag.topLogo) {
            return tag.topLogo;
        }
        return tag.avatar;
    }

    const getProfileBio = (tag) => {
        if(tag.isTeamTag){
            return (tag.teamDescription ? tag.teamDescription : 'Team Description');
        }
        return (tag.profileBio ? tag.profileBio : 'Profile bio');
    }

    const getLocationData = (tag) => {
        if (tag.metadata) {
            return `Place Name: ${tag.metadata.nickname}\n` +
            `Address: ${tag.metadata.location}\n` +
            `ID: ${tag.metadata.number}\n` +
            `Phone: ${tag.metadata.contact}`;
        }
    }

    return (
        <Card
            size="default"
            type={'inner'}
            style={cardStyle}
            cover={<img style={topLogoStyle} src={getTopLogo(tag)} />}
            extra={<Row>{teamTag(tag)}{iconForCCY(currency)}</Row>}
            title={<Space>{statusText}{cardTitle()}</Space>}
        >
            <Row>
                <Col xs={{ span: 24 }} md={{span: tag.metadata ? 14 : 24}}>
                    <Card.Meta
                        avatar={<Avatar src={getAvatar(tag)} size='default' />}
                        title={getDisplayName(tag)}
                        description={getProfileBio(tag)}
                    />
                </Col>
                <Col xs={{ span: 24 }} md={{span: tag.metadata ? 10 : 0}}>
                    <Card.Meta
                        style={{ whiteSpace: 'pre', paddingTop: '16px'}}
                        description={getLocationData(tag)}
                    />
                    
                </Col>
            </Row>
            <Space direction='vertical' size='small' style={{ marginTop: '10px' }}>
                <Space align='baseline'>
                        {templateTagId}
                        {templateTagUid}
                </Space>
                { tag && tag.totalNetValue &&
                <Space align='baseline'>
                    <RunningTotalsCard type={'inner'} object={tag} asTag />
                </Space>
                }
            </Space>
        </Card>
    );
}

// TODO: Pass Action Components in to the List to control what can be done at the screen level
export const StrikeTagsList = ({ condition, locStrings, client, data, handler, onEditTag, isAdmin, isTagTemplateListing, currentUser, ...rest }) => {
    const isLocalHost = window.location.hostname === 'localhost';
    const urlBase =  isLocalHost ? 'http://localhost:3000' : `https://app.strikepay.co`;
    const uuids = {};

    const defaultAmount = null;

    const [amount, setAmount] = useState(defaultAmount);
    const [name, setName] = useState(null);
    const [reference, setReference] = useState(null);
    const [link, setLink] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [tagId, setTagId] = useState(null);
    const [tag, setTag] = useState(null);
    const [maxAmount, setMaxAmount] = useState(500000);
    const [error, setError] = useState(false);

    const uuid = () => {
        const uuid = short.generate();
        return uuid;
    }

    const shareLink = (tag, queryParam) => {
        const iUUID = uuids[tag.id] || uuid();
        uuids[tag.id] = iUUID;
        return `tags/${tag.id}?${queryParam}=${iUUID}`;
    }

    const fullURIForShare = (tag, queryParam) => {
        const iUUID = uuids[tag.id] || uuid();
        if(queryParam === 'qr') {
            return shareQRUrl(tag, null)
        } else {
            return shareUrl(tag, null)
        }

        // if(!!isAdmin) {
        //     const uri = shareLink(tag, queryParam);
        //     return `${urlBase}/${uri}`;
        // } else {
        //     return (tag.templateTagId && tag.tagUid)
        //     ? `${urlBase}/tags/${tag.templateTagId}?uid=${tag.tagUid}`
        //     : `${urlBase}/tags/${tag.id}`;
        // }
    };

    const fullURLForQR = (tag) => {
        return shareQRUrl(tag, null)
    };

    const duplicateTemplateTag = async (templateTag) => {
        const duplicateId = window.prompt("Please enter the name/id to give the duplicated template tag");

        const db = firebase.firestore();

        const issuerId = "strike";
        const templateTagDoc = await db.collection('issuers').doc(issuerId).collection("template_tags").doc(duplicateId).get();

        if (templateTagDoc.exists) {
          window.alert("Error: This template tag name/id already exists. Please try again with a different name/id");
          return;
        }

        if (templateTag.displayName) {
            templateTag.displayName = `Duplicate of ${templateTag.displayName}`;
        } else {
            templateTag.displayName = `Duplicate of ${templateTag.id}`;
        }

        // Template tag obj from client has added id, we want to remove before saving
        delete templateTag.id;

        await db.collection('issuers').doc(issuerId).collection("template_tags").doc(duplicateId)
            .set({
                ...templateTag,
                modified: false,
                createdAt: firebase.firestore.Timestamp.now(),
                updatedAt: firebase.firestore.Timestamp.now(),
            });
    }

    const createPaymentLink = async (tag) => {
        setAmount(defaultAmount);
        setName(null);
        setReference(null);

        setTag(tag);
        setTagId(tag.id);
        setMaxAmount(PaymentsMaxAmounts.values[tag.paymentDetails.currency]);

        setModalVisible(true);
    }

    const handleAmountChange = (value) => {
        if ((value * 100) > maxAmount) {
            setError(true)
        } else {
            setError(false);
            setAmount(value);
        }
    }

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleReferenceChange = (e) => {
        setReference(e.target.value);
    }

    const handleCancel = () => {
        setModalVisible(false);
    };

    const createLink = () => {
        const safeAmount = amount ? (amount * 100).toFixed(0) : null;
        const linkUrl = paymentUrl(tagId, safeAmount, name, reference)
        setLink(linkUrl);
    }

    const onClickShare = async (tag, queryParam) => {
        const displayName = tag.displayName;
        const placeOfWork = tag.placeOfWork || 'Strike';
        const product = tag.product;

        const productSwitcher = new ProductSwitcher({ locStrings });
        const productMap = productSwitcher.getProductMap(product);

        const shareText = `${productMap.action} ${displayName} with ${placeOfWork}`;

        const shareData = {
            title: displayName,
            text: shareText,
            url: `${fullURIForShare(tag, queryParam)}`,
        }

        try {
            await navigator.share(shareData)
        } catch (err) {
            window.open(shareData.url);
        }
    }

    const toggleLivePauseForTag = async (tag) => {
        let oldStatus = tag.status;
        let newStatus = tag.status;

        if (oldStatus === 'live') {
            newStatus = 'paused';
        } else if (oldStatus === 'paused') {
            newStatus = 'live';
        }

        if (oldStatus !== newStatus) {
            try {
                let tagRecentStatusHistory = tag.recentStatusHistory ? tag.recentStatusHistory : [];

                tagRecentStatusHistory.unshift({
                    previousStatus: oldStatus,
                    newStatus: newStatus,
                    remarks: 'Admin paused/enabled tag',
                    createdAt: firebase.firestore.Timestamp.now(),
                    updatedBy: {
                        uid: (currentUser && currentUser.uid)? currentUser.uid : null,
                        displayName: (currentUser && currentUser.displayName)? currentUser.displayName : null,
                        email: (currentUser && currentUser.email)? currentUser.email : null,
                    }
                });

                if (tagRecentStatusHistory.length > 5) {
                    tagRecentStatusHistory.pop();
                }

                const db = firebase.firestore();
                await db.collection('tags').doc(tag.id).update({
                    status: newStatus,
                    recentStatusHistory: tagRecentStatusHistory,
                    updatedAt: firebase.firestore.Timestamp.now()
                });
            } catch (error) {
                console.log('error:' + error.message);
            }
        }
    }

    const buttonStyle = { marginLeft: 'auto', marginRight: 'auto', width: `100%`, marginBottom: '10px' };

    const getActions = (item) => {
        const actions = [
            <Link to={{pathname: `${rest.match.url}/${item.id}`, state: { fromTagsList: true } }}>
                <Button
                    icon={<EditOutlined />}
                    type="secondary"
                    style={buttonStyle}
                >Edit</Button>
            </Link>,
            <Button
                icon={<QrcodeOutlined />}
                href={!!isAdmin ? null : fullURLForQR(item)}
                onClick={!!isAdmin ? ((e)=> (onClickShare(item, 'qr'))) : null}
                type="secondary"
                style={buttonStyle}
            >QR Code</Button>,
            <Button
                icon={<ShareAltOutlined />}
                onClick={((e)=> (onClickShare(item, 'uid')))}
                type="primary"
                style={buttonStyle}
            >Share</Button>
        ];

        if (item.product === "payment") {
            actions.push(
                <Button
                    icon={<LinkOutlined />}
                    onClick={((e)=> (createPaymentLink(item)))}
                    type="secondary"
                    style={buttonStyle}
                >Create Payment Link</Button>,
            );
        }

        if (isAdmin && isTagTemplateListing) {
            actions.push(
                <Button
                    icon={<CopyOutlined />}
                    onClick={((e)=> (duplicateTemplateTag(item)))}
                    type="secondary"
                    style={buttonStyle}
                >Duplicate</Button>,
            );
        }

        if (isAdmin) {
            actions.push(
                <PauseButton
                    isRestricted={item.status === 'paused'}
                    pauseHandler={() => toggleLivePauseForTag(item)}
                />
            );
        }

        return actions;
    }

    const copySuccess = () => {
        message.success('Link copied', 5);
    };

    const styleModalInputs = {
        marginBottom: "10px",
    };

    return (
        <>
            <List
                style={{ textAlign: 'start', maxWidth: '800px' }}
                itemLayout="vertical"
                size='large'
                className="centred-action-buttons"
                pagination
                loading={!condition()}
                dataSource={data}
                renderItem={item => (
                    <List.Item
                        key={item.id}
                        actions={getActions(item)}
                    >
                        <TagsListItemCompact key={`list-${item.id}`} tag={item} tagId={item.id} locStrings={locStrings} onEditTag={onEditTag} isAdmin={isAdmin}/>
                    </List.Item>
                )}
            />

            <Modal
                visible={modalVisible}
                title="Create payment link"
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Close
                    </Button>,
                ]}
            >
                <div>
                    <p>Fill in any combination of the below to generate a link that will pre-fill them for the recipient. These fields will not be editable by the receiver. <br/><br/> Links are multi-use and are safe to try out before distributing.</p>
                </div>

                <div style={styleModalInputs}>
                    <span>Name:</span>
                    <Input
                        value={name}
                        placeholder={"John O'Neill"}
                        onChange={handleNameChange}
                    />
                </div>

                <div style={styleModalInputs}>
                    <span>Reference:</span>
                    <Input
                        value={reference}
                        placeholder={"Y302JDFLWI"}
                        onChange={handleReferenceChange}
                    />
                </div>

                <div style={styleModalInputs}>
                    <span>Amount:</span>
                    <br/> {/* Breaking here due to InputNumber having a different behaviour than the normal inputs */}
                    <InputNumber
                        max={maxAmount}
                        defaultValue={amount}
                        precision={2}
                        controls={true}
                        value={amount}
                        onChange={handleAmountChange}
                    />

                    { error && (
                        <p style={{ color: 'red' }}>{`Error: Exceeded max value of`} <MoneyTitle amount={maxAmount} currency={tag.paymentDetails.currency} digits={2} />{` - please enter a lower amount.`}</p>
                    )}

                </div>

                <Button key="createLink" onClick={createLink}>
                    Generate link
                </Button>

                <div style={{ textAlign: "center", marginTop: "20px", display: link ? "block" : "none" }}>
                    <div className="copyableBlock">
                        <span>Tap to copy link</span>

                        <CopyToClipboard text={link}
                            onCopy={() => copySuccess() }>
                            <button className="promoCopyable">Copy link</button>
                        </CopyToClipboard>
                    </div>
                    <div>
                    { link &&
                        <div className="hasNestedComps elQRImageView">
                            <div className="elSquareimage">
                                <QRImageView url={link} tag={tag} tagId={tagId} />
                            </div>
                        </div>
                    }
                    </div>
                </div>
            </Modal>
        </>
    );

};




