import React, { useState, useEffect } from 'react';

import TrackerService from '../../utils/TrackerService';

import { AutoStack } from './StackView';
import RedirectAndScrollTop from './RedirectAndScrollTop';


/*
 You could be due a tax refund! 
 Earning income and paying tax in Ireland? 
 Strikepay has partnered with Taxback to help you claim your tax refund. 
 Taxback's average Irish tax refund is €1,880 so it's well worth checking what you're owed! 
*/

const RenderImage = ({ imageUrl, style }) => {
  if (imageUrl && imageUrl.trim() !== '') {
    return <img src={imageUrl} alt="Banner Ad" style={style} />;
  }
  return <img src={'https://www.taxback.com/wp-content/themes/Taxback/assets/logos/tb_logo_red.svg'} alt='Logo' style={style} />;
};

const BannerAdStart = ({ imageUrl }) => {
  return (
    <>
      <RenderImage style={{ minWidth: '100px', maxHeight: '50px' }} imageUrl={imageUrl} />
    </>
  );

}

const BannerAdMiddle = ({ imageUrl }) => {
  return (
    <div style={{ marginLeft: '10px' }}>
      <h2>You could be due a tax refund!</h2>
      <p>Earning income and paying tax in Ireland? Strikepay has partnered with Taxback to help you claim your tax refund.</p>
      <p>Average Irish Tax Refund</p>
      <p style={{ color: 'darkred' }}><strong>€1,880</strong></p>
      <p>Your refund can be sent to your Strikepay account!</p>
    </div>
  );
}

const BannerAdEnd = ({ imageUrl }) => {
  return (
    <div style={{ marginLeft: '10px', textAlign: 'start' }}>
    </div>
  );
}

const BannerAd = ({ isAdmin, clientId, imageUrl, onDisplay, onClick }) => {
  const isLocalHost = window.location.hostname === 'localhost';
  const isTest = isLocalHost;
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const landingPage = 'ireland-registration'; // 'apply-irish-tax-refund-strikepay'
  const ref = 'strikepayee';
  const url = `https://www.taxback.com/en/partner/${landingPage}/?utm_ref=${ref}&utm_content=${clientId}`;

  const showBannerAd = !isAdmin; // useFeatureFlagClientAds()

  const tracker = new TrackerService({
    isTest,
  });

  const data = {
    clientId,
    is_test: isTest,
    target: 'taxback',
    url,
    landing: 'PAYE',
    displayName: 'Taxback Banner Ad',
    partnerClientId: 'taxback',
    partnerDisplayName: 'Taxback',
  }

  const onClickTrack = async () => {
    tracker.mixpanelTrack('partner_ad_clicked', data);

    if (!isTest) {
      tracker.googleTrack('partner_ad_clicked', data);
    }

  };

  const onDisplayTrack = async () => {
    if(!showBannerAd) return;

    tracker.mixpanelTrack('partner_ad_impression', data);

    if (!isTest) {
      tracker.googleTrack('partner_ad_impression', data);
    }
  };

  useEffect(() => {
    onDisplayTrack();

    if (typeof onDisplay === 'function') {
      onDisplay(data);
    }
  }, [onDisplay]);

  const onClickBanner = async () => {
    await onClickTrack();
    if (url) {
      setShouldRedirect(true);
    }

    if (typeof onClick === 'function') {
      onClick(data);
    }
    setShouldRedirect(false);
  };

  return showBannerAd ? (
    <div>
      {
        <RedirectAndScrollTop
          when={shouldRedirect}
          to={url}
        />
      }
      <h6 style={{ textAlign: 'start', padding: '0px', margin: '0px', color: '#ff0098' }}>
        ☑️ Strikepay trusted partners
      </h6>
      <a onClick={() => (onClickBanner())}>
        <div style={{ backgroundColor: '#EFE6DD' }}>
          <AutoStack
            contentStart={<BannerAdStart imageUrl={imageUrl} />}
            contentMiddle={<BannerAdMiddle />}
            contentEnd={<BannerAdEnd />}
            style={{ marginTop: '2px', marginBottom: '2px', padding: '5px', border: '2px solid #ff0098' }}
          />
        </div>
        <h5 style={{ textAlign: 'end', padding: '0px', margin: '0px' }}>Click now to claim your refund</h5>
      </a>
    </div>
  ) : null;
};

export default BannerAd;
