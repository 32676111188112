import React, { useState, useEffect, useContext } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Link, useParams } from 'react-router-dom';
import { PageHeader } from 'antd';

import TeamProfileScreenBody from '../../../components/client/TeamProfileScreenBody';
import StrikeTeamModel from '../../../models/StrikeTeamModel';
import { useTeamDetails } from '../../../components/client/useTeamDetails';
import { useTeamMembers } from '../../../components/client/useTeamMembers';
import CenteredSpinner from '../../../components/CenteredSpinner';

const TeamProfileScreen = ({
  locStrings,
  isAdmin,
}) => {
  const { teamId, clientId } = useParams();
  const team = useTeamDetails(teamId);
  const teamMembers = useTeamMembers(teamId, team, clientId);
  const loading = !(team && teamId && clientId && teamMembers && teamMembers.length > 0);

  return <>
      {
        <PageHeader
          ghost={false}
          title={team ? team.teamName : 'Loading...'}
          onBack={(() => window.history.back())}
        />
      } 
      {!loading &&
        <>
          <TeamProfileScreenBody
            locStrings={locStrings}
            teamId={teamId}
            team={team}
            clientId={clientId}
            teamMembers={teamMembers}
            isAdmin={isAdmin}
          />
        </>
      }
      {<CenteredSpinner loading={loading} />}
    </>

};


export default TeamProfileScreen;

