import React, { Component } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import { Button, Card, Space , Table, Row } from 'antd';

import MoneyTitle from '../customer/MoneyTitle';
import { StrikeColors } from '../../utils/Colors';

function ActionButton(props) {
  return <Button {...props} style={{ ...props.style, background: StrikeColors.blue, borderColor: StrikeColors.blue, }} >{props.children}</Button>
};



export default class SkuCardView extends Component {

  /// Properties used by this component:
  /// shipping
  /// One of : pdp, finish, thanks
  /// Data source(s): skus, sku, skuIndex
  /// Call backs:
  ///  onSelectSku
  ///  onOpenPDP, onClickShipping, onConfirmOrder
  /// onReturnToDashoard

  constructor(props) {
    super(props);

    let isPDP = !!this.props.pdp;
    let isOrderComplete = !!this.props.finish;
    if(isOrderComplete) isPDP = false;
    const isThanks = !!this.props.thanks;
    if(isThanks) {
      isPDP = false;
      isOrderComplete = false;
    }

    this.state = {
      skus: props.skus,
      displayCard: false,
      isPDP,
      isOrderComplete,
      isThanks,
      sku: props.sku,
      skuIndex: props.skuIndex || 0,
      shipping: props.shipping,
      onOpenPDP: props.onOpenPDP,
      onClickShipping: props.onClickShipping,
      onSelectSku: props.onSelectSku,
      onConfirmOrder: props.onConfirmOrder,
      onReturnToDashoard: props.onReturnToDashoard,
    };
  }

  componentDidMount() {
    if (this.props.sku)
      this.setSelectedSku(this.props.sku)
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  setSelectedSku = (sku, index) => {
    if (this.props.onSelectSku) this.props.onSelectSku(sku, index);
    const orderState = sku.status; // GET ORDER STATUS FOR THIS SKU!

    if (sku && orderState !== 'completed') {
      const offer = sku.offer || {};
      const orderMessageByState = sku.orderMessageByState ? sku.orderMessageByState[orderState] : null;
      let title = offer.offerTitle || sku.title;
      let callToAction = offer.callToAction || orderMessageByState || "Order Now";
      let description = offer.description || sku.description || "";
      let price = offer.offerPrice || sku.price || "";
      let currency = sku.currency || 'EUR';

      this.setState(
        {
          displayCard: true,
          sku: sku,
          photoURL: sku.photos[sku.photoSelectedIndex],
          title,
          callToAction,
          description,
          price,
          currency,
        }
      );
    }
  }


  getPriceTable = () => {
    const dataSource = [
    ];


    const location = 'IE';
    const price = this.state.sku.price; // get originla price
    const delivery = this.state.sku.deliveryOptions[location];
    const currency = this.state.currency;
    const offerPrice = this.state.sku.offer.offerPrice;

    if (price > 0) {
      dataSource.push(
        {
          key: 'Price',
          description: 'Price: ',
          amount: { fullPrice: price, offerPrice, fontWeight: 'normal' }
        }
      );
    }

    if (delivery > 0) {
      dataSource.push(
        {
          key: 'Delivery',
          description: 'Delivery: ',
          amount: { fullPrice: delivery, offerPrice, fontWeight: 'normal' }
        }
      );
    }

    dataSource.push(
      {
        key: 'Total',
        description: 'Total: ',
        amount: { fullPrice: price + delivery, offerPrice, fontWeight: 'bold' }
      }
    );

    const columns = [
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render:
          amount => (
            <div style={{ textAlign: 'right' }}>
              <p style={{ margin: 0, fontStyle: 'normal', fontWeight: 'normal' }}>
                <MoneyTitle amount={amount.offerPrice} currency={currency} digits={2} />
              </p>
            </div>
          )

      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render:
          amount => (
            <div style={{ textAlign: 'right' }}>
              <p style={{ margin: 0, fontStyle: 'oblique', fontWeight: 'lighter', textDecoration: 'line-through' }}>
                <MoneyTitle amount={amount.fullPrice} currency={currency} digits={2} />
              </p>
            </div>
          )

      },
    ];

    // dataSource, columns

    return <Table dataSource={dataSource} showHeader={false} columns={columns} pagination={false} loading={false} />
  }


  renderAsOffer = () => {
    const sku = this.state.sku;
    return (sku && this.state.displayCard && !this.state.isPDP &&
      <Card
        align='center'
        title={this.state.title}
        style={{ marginBottom: '20px', width: '240px', marginLeft: 'auto', marginRight: 'auto' }}
        actions={[
          <Button onClick={this.state.onOpenPDP}>{this.state.callToAction}</Button>,
        ]}
        cover={<img src={this.state.photoURL} />}
      >

        <Card.Meta title={sku.title} description={sku.subtitle} />

        <Space direction='vertical'>
          {' '}
          {this.state.description}
        </Space>

      </Card>
    )
  }

  renderAsPDP = () => {
    const sku = this.state.sku;
    return (sku && this.state.displayCard && this.state.isPDP &&
      <div style={{ marginBottom: '20px' }}>
        <Row>
          <Card
            align='start'
            title={this.state.title}
            style={{ marginBottom: '5px', width: '300px', marginLeft: 'auto', marginRight: 'auto' }}
            cover={<img src={this.state.photoURL} />}
            actions={[
              <ActionButton type='primary' onClick={this.state.onClickShipping}>Add Delivery Address</ActionButton>,
            ]}
          >

            <Card.Meta title={sku.title} description={sku.subtitle} />

            <Space direction='vertical' >
              {' '}
              {this.state.description}
              {this.getPriceTable()}
            </Space>

          </Card>
        </Row>

        <Row>
          <Card
            align='start'
            style={{ margin: '5px', width: '240px', marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Space direction='vertical' >
              {this.state.sku.deliveryDescription}
            </Space>

          </Card>
        </Row>
      </div>
    )
  }


  renderAsOrderComplete = () => {
    const sku = this.state.sku;
    return (sku && this.state.isOrderComplete && this.state.shipping &&
      <div style={{ marginBottom: '20px' }}>
        <Row>
          <Card
            align='start'
            title={this.state.title}
            style={{ marginBottom: '5px', width: '300px', marginLeft: 'auto', marginRight: 'auto' }}
            cover={<img src={this.state.photoURL} />}
            actions={[
              <ActionButton type='primary' onClick={this.state.onConfirmOrder}>Confirm Order</ActionButton>,
            ]}
          >

            <Card.Meta title={sku.title} description={sku.subtitle} />

            <Space direction='vertical' >
              {' '}
              {this.state.description}
              {this.getPriceTable()}
              {' '}
              {this.state.shipping.name}
              {this.state.shipping.address["line1"]}
              {this.state.shipping.address["line2"]}
              {this.state.shipping.address["town"]}
              {this.state.shipping.address["county"]}
              {this.state.shipping.address["zip"]}
            </Space>

          </Card>
        </Row>

        <Row>
          <Card
            align='start'
            style={{ margin: '5px', width: '240px', marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Space direction='vertical' >
              {this.state.sku.deliveryDescription}
            </Space>

          </Card>
        </Row>
      </div>
    )
  }


  renderAsThanks = () => {
    const sku = this.state.sku;
    return (sku && this.state.displayCard && !this.state.isPDP &&
      <Card
        align='center'
        title={this.state.title}
        style={{ marginBottom: '20px', width: '240px', marginLeft: 'auto', marginRight: 'auto' }}
        cover={<img src={this.state.photoURL} />}
        actions={[
          <ActionButton type='primary' onClick={this.state.onReturnToDashoard}>Return to Account</ActionButton>,
        ]}
      >

        <Card.Meta title={sku.title} description={sku.subtitle} />

        <Space direction='vertical'>
          {' '}
          {"Thanks! Your order is on its way..."}
        </Space>

      </Card>
    )
  }


  render() {
    const sku = this.state.sku;
    return this.state.isPDP ? 
            this.renderAsPDP() : 
            ( this.state.isOrderComplete 
                  ? this.renderAsOrderComplete() 
                      : (this.state.isThanks ? this.renderAsThanks() : this.renderAsOffer()));
  }

}
