import React, { Component } from 'react';
import firebase from 'firebase/app';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Space, PageHeader, Card, Button } from 'antd';
import { Link } from 'react-router-dom';

import CenteredSpinner from '../../../components/CenteredSpinner';
import StrikeClientModel from '../../../models/StrikeClientModel';
import TrackerService from '../../../utils/TrackerService';

export default class TeamInviteScreen extends Component {
  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    const isAdmin = !!props.isAdmin;
    this.clientModel = new StrikeClientModel({ viewModel: this, clientId, isAdmin });

    this.state = {
      clientNotFound: false,
      teamId: props.match.params.teamId,
      clientId: clientId,
      route: null,
      client: null,
      loading: true,
      inviteAccepted: false,
      alreadyMember: false,
      team: null,
      isDeleted: false,
      inviteUuid: props.match.params.inviteUuid,
    };
  }

  async componentDidMount() {
    this.clientModel.waitForClientData();
  }

  componentWillUnmount() {
    if (this.clientModel) this.clientModel.stop();
  }

  componentDidUpdate() {
  }

  getTeam = async () => {
    const db = firebase.firestore();
    const teamDoc = await db.collection('teams').doc(this.state.teamId).get();
    const team = teamDoc ? teamDoc.data() : null;

    if (team && team.status === "live") {
        const teamMembersSnap = await db.collection('teams').doc(this.state.teamId).collection("members").get();

        let isAlreadyMember = false;
        teamMembersSnap.forEach(doc => {
            if (doc.id === this.state.clientId) {
                isAlreadyMember = true;
                return true;
            }
        });

        if (isAlreadyMember) {
          await this.isActiveTeamMember();

          this.setState({ 
            team: team,
            loading: false,
            invalidTeam: false,
            alreadyMember: true,
          });
        } else {
          this.setState({ 
            team: team,
            loading: false,
            invalidTeam: false,
          });
        }
    } else {
      this.setState({ 
        team: team,
        loading: false,
        invalidTeam: true,
      });
    }
  }

  isActiveTeamMember = async () => {
    const db = firebase.firestore();
    const teamMemberSnap = await db.collection('teams').doc(this.state.teamId).collection("members").doc(this.state.clientId).get();
    const teamMemberData = teamMemberSnap.data();
    const isDeleted = teamMemberData.isDeleted;
    this.setState({ isDeleted });
  }

  foundClient = async (client) => {
    this.setState(
      (state) => {
        return {
          client,
          loading: false,
          loadingTags: true,
        }
      }
    );

    await this.getTeam();
  }

  clientNotFound = () => {
    this.setState(
      (state) => {
        return {
          loading: false,
          clientNotFound: true,
        }
      }
    );
  }

  acceptInvite = async () => {
    const { alreadyMember, client, clientId, isDeleted, teamId, inviteUuid } = this.state;
    const acceptTeamInviteFunction = firebase.functions().httpsCallable('teamService-teamHandler-acceptTeamInvite');

    const payloadData = {
      teamId,
      clientId,
      clientDisplayName: client.displayName,
      clientEmail: client.email,
      clientAvatar: client.avatar,
      isReInvited: isDeleted && alreadyMember,
      inviteUuid,
    }

    try {
      await acceptTeamInviteFunction(payloadData);
      this.props.history.push(`/client/manage/${clientId}/teams/${teamId}`);
    } catch (error){
      window.alert('Error: Invite not valid for your account');
    }
  }

  onClickConfirm = async() => {
    const { team, teamId, client, clientId, isDeleted, alreadyMember, inviteUuid } = this.state;
    const isLocalHost = window.location.hostname === 'localhost';
    const isTest = isLocalHost;

    const tracker = new TrackerService({
      isTest,
    });

    const data = {
      teamId,
      teamName: team && team.teamName,
      teamClientId: team && team.teamClientId,
      teamMemberClientId: clientId,
      teamMemberDisplayName: client && client.displayName,
      isReInvited: isDeleted && alreadyMember,
      inviteUuid: inviteUuid,
    }

    tracker.mixpanelTrack("accept_team_invite", data);
    
    if (!isTest) {
      tracker.googleTrack(
        "accept_team_invite", data);
    }

    this.setState({
      loading: true
    });

    await this.acceptInvite();

    this.setState({
      loading: false
    }); 
  }
  
  render() {
    if (this.state.loading) {
      return <CenteredSpinner loading={this.state.loading} />
    } else if (!this.state.loading && !this.state.team) {
      return (
        <div>
          <PageHeader
            ghost={false}
            title={`Invalid join link`}
          />

          <Link to={`/client/manage/${this.state.clientId}`}><Button>Back to dashboard</Button></Link>
        </div>
      );
    } else if (!this.state.loading && this.state.invalidTeam) {
      return (
        <div>
          <PageHeader
            ghost={false}
            title={`Invalid join link`}
          />

          <Link to={`/client/manage/${this.state.clientId}`}><Button>Back to dashboard</Button></Link>
      </div>
      )
    } else if (!this.state.loading && !this.state.isDeleted && this.state.alreadyMember) {
      return (
        <div>
          <PageHeader
            ghost={false}
            title={`Already a member of this team`}
          />

          <p>You're already a member of the <strong>{this.state.team.teamName}</strong> team.</p>
          <Space>
            <Link to={`/client/manage/${this.state.clientId}/teams/${this.state.teamId}`}><Button type='primary' disabled={this.state.loading}>Go to team</Button></Link>
            <Link to={`/client/manage/${this.state.clientId}`}><Button>Back to dashboard</Button></Link>
          </Space>

      </div>
      )
    } else if (!this.state.loading && this.state.isDeleted) {
      return (
        <div>
          <PageHeader
            ghost={false}
            title={`Rejoin ${this.state.team.teamName} Team`}
          />

          <Card>
            { this.state.inviteAccepted
              ? (
                <>
                  <p>You've rejoined <strong>{this.state.team.teamName}</strong> team and now are a part of this team on Strikepay.</p>

                  <br/>
                  <Link to={`/client/manage/${this.state.clientId}`}><Button>Back to dashboard</Button></Link>
                </>
              )
              :
                (
                  <>
                    <h5>Rejoin the team <strong>{this.state.team.teamName}</strong> on Strikepay.</h5>
                    <h5>Your tips will be paid directly into your Strikepay account.</h5>

                    <br/>

                    <Space>
                      <Button type="primary" disabled={this.state.loading} onClick={this.onClickConfirm}>Join the Team</Button>
                      <Link to={`/client/manage/${this.state.clientId}`}><Button danger>Cancel</Button></Link>
                    </Space>
                  </>
                )
            }
          </Card>
        </div>
      )
    } else {
      return (
        <div>
          <PageHeader
            ghost={false}
            title={`Join the ${this.state.team.teamName} Team`}
          />

          <Card>
            { this.state.inviteAccepted
              ? (
                <>
                  <p>You've joined the <strong>{this.state.team.teamName}</strong> team and now are a part of this team on Strikepay.</p>

                  <br/>
                  <Link to={`/client/manage/${this.state.clientId}`}><Button>Back to dashboard</Button></Link>
                </>
              )
              :
                (
                  <>
                    <h5>Join the team <strong>{this.state.team.teamName}</strong>.</h5>
                    <h5>You will be paid directly into your Strikepay account.</h5>

                    <br/>

                    <Space>
                      <Button type="primary" disabled={this.state.loading} onClick={this.onClickConfirm}>Join</Button>
                      <Link to={`/client/manage/${this.state.clientId}`}><Button>Cancel</Button></Link>
                    </Space>
                  </>
              )
            }
          </Card>
        </div>
      );
    }
  }
}

