import { useState, useEffect } from 'react';
import firebase from 'firebase/app';

export function useWithdrawalDetails(clientId, withdrawalId) {
    const [withdrawal, setWithdrawal] = useState(null);

    useEffect(() => {
        // Subscribe to the withdrawals collection in Firestore
        const db = firebase.firestore();
        const ref = db.collection('clients').doc(clientId).collection('withdrawals').doc(withdrawalId);

        const unsubscribe = ref.onSnapshot((snapshot) => {
            const data = snapshot.data();
            const { amount, payoutAmountCents } = data;
            const amountToDisplay = amount || payoutAmountCents; // MARK: Handle withdrawals with no `amount`
            data.amount = amountToDisplay;
            data.id = snapshot.id;
            setWithdrawal(data);
        });

        // Clean up the subscription on component unmount
        return () => {
            unsubscribe();
        };
    },
        []
    );

    return withdrawal;
}
