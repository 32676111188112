import firebase from 'firebase/app';


export default class StrikeTeamMembersTapsModel {

  constructor({ viewModel, teamId, callback, limit }) {
    this.viewModel = viewModel;
    this.teamId = teamId;
    this.callback = callback;
    this.limit = limit;
    this.teamMembersTaps = [];
    this.teamMembersTapsById = {};
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if(this.observerTeamMembersTaps) {
      this.observerTeamMembersTaps();
      this.observerTeamMembersTaps = null;
    }
  }

  fetchAllTeamMembersTaps()  {
    if (this.teamId === null) return;

    const teamId = this.teamId;

    this.stop();

    const db = firebase.firestore();
    this.teamMembersTaps = [];

    // MARK: Team Member Taps Model (not Team Taps)
    const mainQuery = db
        .collectionGroup('taps')
        .where('teamId', '==', this.teamId)
        .where('status', 'in', ['pending', 'available', 'available_requested', 'refunded'])
        .orderBy('updatedAt', 'desc');

    const query =  this.limit ? mainQuery.limit(this.limit) : mainQuery;

    this.observerTeamMembersTaps = query.onSnapshot(querySnapshot => {
      let index = querySnapshot.size;
      this.teamMembersTaps = [];

      querySnapshot.docChanges().forEach(
          change => {
            if(change.type === 'added' || change.type === 'modified') {
              const doc = change.doc.data();
              const id = change.doc.id;
              this.teamMembersTapsById[id] = {...doc, id};
            }

            if(change.type === 'removed' )  {
              const id = change.doc.id;
              this.teamMembersTapsById[id] = null;
            }
          }
      );

      this.updateDataSource();

    }, err => {
      this.teamMembersTaps = [];
      this.teamMembersTapsById = {};
      this.updateDataSource();
      console.log(`Team Members Taps query error: ${err}`);
    });
  }

  async updateDataSource() {
    this.teamMembersTaps = [];

    for(let prop in this.teamMembersTapsById) {
      if(this.teamMembersTapsById[prop]) { 
        this.teamMembersTaps.push(this.teamMembersTapsById[prop]);
      }
    }

    this.teamMembersTaps = this.teamMembersTaps.filter(teamMemberTap => teamMemberTap && teamMemberTap.teamMemberId);

    this.teamMembersTaps.sort((a, b) => {
      const now = new Date();
      const nowString = now.toISOString();
      const aUpdated = (a && a.updatedAt) ? a.updatedAt : nowString;
      const bupdatedAt = (b && b.updatedAt) ? b.updatedAt : nowString;
      const aDate = aUpdated.length > 0 ? aUpdated : aUpdated.toDate().toISOString();
      const bDate = bupdatedAt.length > 0 ? bupdatedAt : bupdatedAt.toDate().toISOString();
      return (aDate < bDate) ? 1 : ( (aDate > bDate) ? -1 : 0 );
    });

    this.viewModel && this.viewModel.setTeamMembersTapsOnPage(this.teamMembersTaps);
    this.callback && this.callback(this.teamMembersTaps);
  }

}
