import React, { Component } from 'react';
import moment from 'moment';
import '../../App.scss';
import 'antd/dist/antd.css';
import {
  QrcodeOutlined,
  MobileTwoTone,
  WifiOutlined,
  MessageTwoTone,
  CreditCardTwoTone,
  ClockCircleTwoTone,
  CheckCircleFilled,
  RightCircleFilled,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Table, Tag, Space, Row, Col,
  Tooltip, Typography, Avatar, Badge,
} from 'antd';

import MoneyTitle from '../customer/MoneyTitle';
import timeSince from '../../utils/TimeSince';
import ProductSwitcher, { lookupStatusDetails, capitalize } from '../../utils/ProductSwitcher';

const { Text } = Typography;

export const AvatarBadge = ({ avatar, direction, isTeam, ...rest }) => {
  const offset = (direction === 'in') ? [-35, 35] : [-3, 35];
  const icon = !!isTeam ? <TeamOutlined /> : <UserOutlined />

  return (
      <Badge
          offset={offset}
          count={direction === 'in' ? <RightCircleFilled style={{ color: 'green' }} /> : <RightCircleFilled style={{ color: 'red' }} />}
      >
          <Avatar size='large' src={avatar} icon={icon} />
      </Badge>
  );
};

export const MoneyStatus = ({ currency, amount, filled, icon, ...rest }) => {
  const filledIcon = icon || <CheckCircleFilled style={{ color: 'green' }} />;

  return (
      <Space direction='horizontal'>
          <MoneyTitle amount={amount} currency={currency} digits={2} {...rest} />
          {filled ?
              <Tooltip title='Done'>
                  {filledIcon}
              </Tooltip>
              :
              <Tooltip title='Processing'>
                  <ClockCircleTwoTone />
              </Tooltip>
          }
      </Space>
  );
};

export const SourceIcon = ({ source, templateTagId, tagUid }) => {
  let sourceIcon = <QrcodeOutlined style={{ color: '#FF0098' }} />;
  const style = { marginLeft: '2px', marginRight: '2px' };
  let sourceText = 'Strikepay';

  switch (source) {
      case 'nfc':
          sourceIcon = <WifiOutlined style={{ ...style, color: '#FF0098' }} />;
          sourceText = 'Tapped NFC';
          break;
      case 'qr':
      case 'none':
          sourceIcon = <QrcodeOutlined style={{ ...style, color: '#FF0098' }} />;
          sourceText = 'Scanned QR Code';
          break;
      case 'payment-link':
          sourceIcon = <MessageTwoTone twoToneColor={'#52c41a'}  style={{ ...style }} />;
          sourceText = 'Clicked on link';
          break;
      case 'strike':
          switch (templateTagId) {
              case 'pax':
                  sourceIcon = <MobileTwoTone twoToneColor={'#eb2f96'} style={{ ...style }} />;
                  sourceText = 'Used Terminal';
                  break;
              case 'corevend':
                  sourceIcon = <MobileTwoTone twoToneColor={'orange'} style={{ ...style }} />;
                  sourceText = 'Used Terminal';
                  break;
              default:
                  sourceIcon = <CreditCardTwoTone twoToneColor={'#ff0098'} style={{ ...style }} />;
                  sourceText = 'Card Payment';
                  break;
          }
          break;
      default:
          sourceIcon = <CreditCardTwoTone twoToneColor={'#ff0098'} style={{ ...style }} />;
          sourceText = 'Card Payment';
          break;
  }

  if (!source) { // handle null or not set to be the default
      sourceIcon = <QrcodeOutlined style={{ ...style, color: '#FF0098' }} />;
      sourceText = 'Scanned QR Code';
  }

  return (
      <Tooltip title={`${sourceText}`}>
          {sourceIcon}
      </Tooltip>
  );
};

export const ActivityDescriptionView = ({ activityAt, clientType, activityType, direction, status, source, templateTagId, tagUid }) => {
  return <>
      <Tag color='default'>{moment(activityAt.toMillis()).format('LT')}</Tag>
      <Tag color={clientType === 'individual' ? 'gold' : 'blue'}>{clientType === 'individual' ? 'Member' : 'Team'}</Tag>
      {status === 'refunded' ? <Tag color={'red'}>{capitalize(status)}</Tag> :
          <Tag color={direction === 'in' ? 'green' : 'red'}>{activityType}</Tag>
      }
      { ['Tip', 'Pay', 'Give'].includes(activityType) &&
        <SourceIcon source={source} templateTagId={templateTagId} tagUid={tagUid} />
      }
  </>;
}

export const TapMoneyView = ({ amount, strikeFee, fee, currency, status, }) => {
  return <>
      <Space direction='vertical' align='end' size={0}>
          <MoneyStatus amount={amount} currency={currency}
              filled={['sent', 'available', 'paid'].includes(status)}
              strikethrough={(status && (status === 'refunded'))}
          />
          {fee === 0 && strikeFee > 0 &&
              <Space direction='horizontal' size={2}>
                  <Text type='warning'>fee:</Text>
                  <MoneyStatus amount={-strikeFee} currency={currency}
                      filled={['sent', 'available', 'paid'].includes(status)}
                      strikethrough={(status && (status === 'refunded'))}
                  />
              </Space>
          }
      </Space>
  </>;
}

export const TapDetailCard = ({ tap, key }) => {
  const { 
    id,
    amount,
    displayName,
    fee,
    tagUid,
    templateTagId,
    metadata, 
    customerName, 
    paymentInfo,
    messageText,
    paymentDetails,
    appliedRevenueFeeSlabId,
    revenueFee,
    status,
    product,
  } = tap || { };

  const { 
    nickname 
  } = metadata || { };

  const {
    currency
  } = paymentDetails || { currency: 'EUR' };

  let feeApplied = null;
  let base = null;
  let percentage = null;
  let netAmount = 0;

  const totalPaid = 0 + amount + fee;
  const customerPaidFee = fee > 0;
  let calculatedFeesTotal = 0;

  netAmount = 0 + amount + fee;

  if(revenueFee && appliedRevenueFeeSlabId) {
    feeApplied = revenueFee[appliedRevenueFeeSlabId];
    base = feeApplied.base;
    percentage = feeApplied.percentage;
    const calculatedPercentage = Math.round(amount * (percentage/10000), 0);
    calculatedFeesTotal = calculatedPercentage + base;
    netAmount = netAmount - calculatedFeesTotal;
  }

  const titleTextOptions = {
    tipping: 'Tip',
    giving: 'Donation',
    payment: 'Payment',
  };

  const statusTextOptions = {
    pending: 'Pending',
    available: 'Available',
  };

  const titleText = titleTextOptions[product];

  const statusText = statusTextOptions[status];
  const netAmountLabel = `Net ${titleText} ${statusText||""} `;

  const rowStyle = { margin: '3px', marginLeft: '8px' };
  const rowStyleTags = { marginTop: '12px', marginLeft: '8px' };

  return (  
    <Row >
      <Col key={`tap-detail-${key}`} >
        { nickname &&
          <Row style={rowStyle}>
            {"Location: "}
            {nickname}
          </Row>
        }
        { customerName &&
          <Row style={rowStyle}>
            {"Customer Name: "}
            {customerName}
          </Row>
        }
        { paymentInfo &&
          <Row style={rowStyle}>
            {"Payment Message: "}
            {paymentInfo}
          </Row>
        }
        { messageText &&
          <Row style={rowStyle}>
            {"Feedback Message: "}
            {messageText}
          </Row>
        }
        <Row style={rowStyle}>
          {`${netAmountLabel}: `}
          <MoneyTitle amount={netAmount} currency={currency} digits={2} />

        </Row>
        { !customerPaidFee && calculatedFeesTotal > 0 &&
        <Row style={rowStyle}>
          {`Fee Applied to ${titleText}: `}
          <MoneyTitle amount={calculatedFeesTotal} currency={currency} digits={2} />
        </Row>
        }
        <Row style={rowStyleTags}>
          <Tag color='#ff0098'>{templateTagId}</Tag>
          { tagUid &&
            <Tag >{tagUid}</Tag>
          }
          { displayName &&
            <Tag color='darkgreen'>{displayName}</Tag>
          }
        </Row>
      </Col>
    </Row>
  );
};

export const TapsTable = ({ condition, locStrings, client, accounts, taps, showName, ...rest }) => {

  if( !condition() ) return null;

  const dataSource = [
  ];

  const productSwitcher = new ProductSwitcher({ locStrings });

  taps.map( 
    (tap, i) => {
      const isString = tap.created ? tap.created.length > 0 : false;
      const dateTime = timeSince(tap.created ? (isString ? Date.parse(tap.created) : tap.created.toDate()) : null);
      const productMap = productSwitcher.getProductMap(tap.product);
      
      dataSource.push( 
        {
          key: `tap-${i}`,
          date:  dateTime,
          type: { 
              typeName: productMap.name,
              typeColor: productMap.color,
            },
          currency: tap.paymentDetails.currency,
          value: { currency: tap.paymentDetails.currency, amount: tap.amount, fee: { currency: tap.paymentDetails.currency, amount: tap.fee } },
          balance: 0,
          description: `${tap.customerName || tap.messageText || tap.templateTagId || ""} ${tap.paymentInfo ? (" for " + tap.paymentInfo) : ("(" + (tap.tagUid || "") + ")")} `,          
          tapData: { tap, index: i },
          status: {
              statusName: lookupStatusDetails(tap.status).statusName,
              statusColor: lookupStatusDetails(tap.status).statusColor,
              sourceName: tap.source,
              sourceColor: tap.source === 'nfc' ? 'blue' : 'black',
          },
          tapUid: tap.tapUid,
          displayName: tap.displayName
        }
      );
    });

  const columns = [
    {
      title: 'When',
      dataIndex: 'date',
      key: 'date',
      render: 
        date => (
              <>
                <Tag color='geekblue'>{date}</Tag>
              </>
            )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: 
        status => (
              <>
                <Tag color={status.statusColor}>{status.statusName}</Tag>
                {( (status.sourceName) &&
                <Tag color={status.sourceColor}>{status.sourceName}</Tag>
                )}
              </>
            )
    },
    {
      title: 'Amount',
      dataIndex: 'value',
      align: 'right',
      render:
          (value, activity) => (
              <div >
                <MoneyTitle amount={value.amount} currency={value.currency} digits={2} strikethrough={activity.type.typeName === 'tap' && (activity.status && (activity.status.statusName === 'refunded'))} />
                {( (value.fee.amount == 0) &&
                <Tag color={'red'}>Fee</Tag>
                )}
              </div>
            )
    },
  ];

  return condition() ?
            <Table 
              style={{ width : '100%'}}
                dataSource={dataSource}
                columns={columns}
                pagination={{ pageSize: 40 }} scroll={{ y: 300 }}
                expandable={{
                    expandedRowRender: record => <TapDetailCard tap={record.tapData.tap} index={record.tapData.index} />,
                    rowExpandable: record => record.tapData,
                }}       
            />
          : null;

};

export const TeamTransactionsTable = ({ condition, locStrings, client, accounts, teamActivitiesList, showName, ...rest }) => {

  if( !condition() ) return null;

  const dataSource = [
  ];

  const productSwitcher = new ProductSwitcher({ locStrings });

    teamActivitiesList.map(
        (teamActivity, i) => {
            if (teamActivity.type === 'tap') {
                const activityDate = teamActivity.accountsProcessingStartedAt ? teamActivity.accountsProcessingStartedAt.toDate() : null;
                const timeSinceString = activityDate && timeSince(activityDate);
                const showDateString = activityDate && activityDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
                const showTimeString = activityDate && activityDate.toLocaleTimeString('en-GB');

                const dateTimeAndSince = {
    //                showDates,
                    date: showDateString,
                    time: showTimeString,
                    since: timeSinceString,
                };

                const productMap = productSwitcher.getProductMap(teamActivity.product);
                dataSource.push(
                    {
                        key: `tap-${i}`,
                        date: dateTimeAndSince,
                        type: {
                            typeName: 'tap',
                            typeColor: productMap.color,
                        },
                        currency: teamActivity.paymentDetails.currency,
                        value: {
                            currency: teamActivity.paymentDetails.currency,
                            amount: teamActivity.amount,
                            fee: { currency: teamActivity.paymentDetails.currency, amount: teamActivity.fee }
                        },
                        balance: 0,
                        tapData: { tap: teamActivity, index: i },
                        description: `${teamActivity.customerName || teamActivity.messageText || teamActivity.templateTagId || ""} ${teamActivity.paymentInfo ? (" for " + teamActivity.paymentInfo) : ("(" + (teamActivity.tagUid || "") + ")")} `,
                        status: {
                            statusName: lookupStatusDetails(teamActivity.status).statusName,
                            statusColor: lookupStatusDetails(teamActivity.status).statusColor,
                            sourceName: teamActivity.source,
                            sourceColor: teamActivity.source === 'nfc' ? 'blue' : 'black',
                        },
                        tapUid: teamActivity.tapUid,
                        isRefunded: teamActivity.status && (teamActivity.status === 'refunded')
                    }
                );
            }
            if (teamActivity.type === 'transfer') {
                const isString = teamActivity.createdAt ? teamActivity.createdAt.length > 0 : false;
                const activityDate = teamActivity.createdAt ? (isString ? Date.parse(teamActivity.createdAt) : teamActivity.createdAt.toDate()) : null;
                const timeSinceString = activityDate && timeSince(activityDate);
                const showDateString = activityDate && activityDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
                const showTimeString = activityDate && activityDate.toLocaleTimeString('en-GB');
                let typeName = teamActivity.subType;

                if(teamActivity.transferType === 'team-to-member'){
                    typeName = 'team-transfer-out'
                }
                const dateTimeAndSince = {
                //    showDates,
                    date: showDateString,
                    time: showTimeString,
                    since: timeSinceString,
                };

                dataSource.push(
                    {
                        key: `tap-${i}`,
                        date: dateTimeAndSince,
                        type: {
                            typeName: typeName,
                            typeColor: teamActivity.type && teamActivity.subType && ['team-transfer-in', 'transfer-in'].includes(teamActivity.subType) ? 'cyan' : '#b94e48',
                        },
                        currency: teamActivity.currency,
                        value: {
                            currency: teamActivity.currency,
                            amount: +(teamActivity.payoutAmountCents)
                        },
                        balance: 0,
                        description: `${teamActivity.toClient.displayName}`,
                        status: {
                            statusName: lookupStatusDetails(teamActivity.status).statusName,
                            statusColor: lookupStatusDetails(teamActivity.status).statusColor
                        },
                        tapUid: teamActivity.tapUid,
                        memberName: teamActivity.toClient.displayName,
                        fromClientName: teamActivity.fromClient.displayName,
                        toClientName: teamActivity.toClient.displayName
                    }
                );
            }
        }
    );

  const columns = [
        {
            title: 'When',
            dataIndex: 'date',
            key: 'date',
            render:
                date => (
                    <>
                        {(date.showDates)
                            ?
                            <>
                                <Tag color='geekblue'>{date.date}</Tag>
                                <Tag color='geekblue'>{date.time}</Tag>
                            </>
                            : <Tag color='green'>{date.since}</Tag>
                        }
                    </>
                )
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render:
                (type, activity) => (
                    <>
                        <Tag color={type.typeColor}>{type.typeName}</Tag>
                        {(activity.type.typeName === 'team-transfer-out') &&
                        <Tag color={'lightgreen'} style={{ color: 'darkgreen' }}>{activity.memberName}</Tag>
                        }
                        {(activity.type.typeName === 'transfer-in') &&
                        <Tag color={'lightgreen'} style={{ color: 'darkgreen' }}>{activity.fromClientName}</Tag>
                        }
                        {(activity.type.typeName === 'transfer-out') &&
                        <Tag color={'lightgreen'} style={{ color: 'darkgreen' }}>{activity.toClientName}</Tag>
                        }
                    </>
                )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render:
                (status, activity) => (
                    <>
                        {(['transfer-in', 'transfer-out', 'team-transfer-out'].includes(activity.type.typeName)) &&
                        <Tag color={status.statusColor}>{status.statusName}</Tag>
                        }

                        {(activity.type.typeName === 'tap') &&
                        <Tag color={status.statusColor}>{status.statusName}</Tag>
                        }
                        {((status.sourceName) && (activity.type.typeName === 'tap') &&
                            <Tag color={status.sourceColor}>{status.sourceName}</Tag>
                        )}
                    </>
                )
        },
        {
            title: 'Amount',
            dataIndex: 'value',
            align: 'right',
            render:
                (value, activity) => (
                    <div>
                        <MoneyTitle amount={value.amount} currency={value.currency} digits={2}
                                    strikethrough={activity.type.typeName === 'tap' && (activity.status && (activity.status.statusName === 'refunded'))} />
                    </div>
                )
        },
    ];

  return condition() ?
            <Table
              style={{ width : '100%'}}
                dataSource={dataSource}
                columns={columns}
                pagination={{ pageSize: 40 }} scroll={{ y: 300 }}
                expandable={{
                    expandedRowRender: record => <TapDetailCard tap={record.tapData.tap} index={record.tapData.index} />,
                    rowExpandable: record => record.tapData,
                }}
            />
          : null;

};


