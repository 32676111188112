import firebase from 'firebase/app';
const isLocalHost = window.location.hostname === 'localhost';

const allStatuses = [
  'paid', 
  'upload_requested', 
  'processing', 
  'processed', 
  'paused', 
  'cancelled',
  'refunded',
];

export default class StrikeLinesGroupModel {

  constructor({ clientId, payoutId, withdrawalId, batchId, onUpdate, limit, statuses }) {
    this.onUpdate = onUpdate;
    this.data = [];
    this.dataById = {};
    this.limit = limit || 1000;
    this.statuses = statuses;

    this.clientId = clientId;
    this.payoutId = payoutId;
    this.withdrawalId = withdrawalId;
    this.batchId = batchId;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if (this.observer) {
      this.observer();
      this.observer = null;
    }
  }

  filterByStatus(status) {
    this.statuses = status ? [status] : allStatuses;
    if (this.observer) {
      this.observer();
      this.observer = null;
    }
    this.data = [];
    this.dataById = {};
    this.start();
  }

  start = () => {

    if (this.observer) {
      this.observer();
      this.observer = null;
    }

    const db = firebase.firestore();

    let query = db.collectionGroup('lines');

    if(this.clientId) query = query.where('clientId', '==', this.clientId);
    if(this.withdrawalId) query = query.where('withdrawalId', '==', this.withdrawalId);
    if(this.payoutId) query = query.where('payoutId', '==', this.payoutId);
    if(this.batchId) query = query.where('batchId', '==', this.batchId);
    if(this.statuses) query = query.where('status', 'in', this.statuses);      

    query = query
            .orderBy('createdAt', 'desc')
            .limit(this.limit);

    this.observer = query.onSnapshot(querySnapshot => {
      this.data = [];

      if(querySnapshot.empty && this.onUpdate) {
        if(this.onUpdate) this.onUpdate(this.data, null);
      }

      let docs = querySnapshot.docs;
      console.log(`Encountered a lines group model update: ${docs.length}`);

      querySnapshot.forEach(doc => {
        let element = doc.data();
        element.id = doc.id;
        this.dataById[element.id] = element;
        this.data = [ element, ...this.data ];
      });

      if(this.onUpdate) this.onUpdate(this.data, null);
    }, err => {
      console.log(`Encountered a lines group model error: ${err}`);
      this.data = [];
      if(this.onUpdate) this.onUpdate(this.data, null);  
    });

  }

  async updateElementWithId(id, element) {
    let lines = this.data.filter(e => e.id !== id);
    lines = [ element, ...lines ];
    this.data = lines;
  }

  async deleteElementWithId(id) {
    let lines = this.data.filter(e => e.id !== id);
    this.data = lines;
  }

  async updateWithNone() {
    if(this.onUpdate) this.onUpdate(this.data, null);
  }

}
