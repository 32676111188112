import React, { useState } from 'react';
import {
  Divider,
  Space,
  Typography,
} from 'antd';

import {
  WarningLineCard,
  CategoryPricingSlabsView,
  getDefaultRevenuePricing,
  convertPricingToArray,
} from './PricingSlabsView';

const { Text } = Typography;

export const ClientSpecificPricingView = ({ pricing, currency }) => {
  const isDefault = !pricing;
  const product = null;
  const defaultPricing = getDefaultRevenuePricing({ product });
  const pricingArray = convertPricingToArray({
    pricing: pricing || defaultPricing,
    currency,
  });
  return <>
    {
      <>
        <Divider>Admin Only</Divider>
        <Space direction='vertical' size={0}>
          <Text strong>{isDefault ? 'Using Default Pricing' : 'Client Specific Pricing is set'}</Text>
          <Text>Pricing at the tag level will overide any client level pricing.</Text>
          <WarningLineCard />
        </Space>
      </>
    }
    <CategoryPricingSlabsView tiers={pricingArray} />
  </>;
}
