import React, { Component } from 'react';
import firebase from 'firebase/app';
import qs from 'qs';
import { Col, Row, Space, Table, message } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from 'muicss/lib/react/button';

import ProductSwitcher from '../../utils/ProductSwitcher';
import MoneyTitle from '../../components/customer/MoneyTitle';
import FooterCard from '../../components/customer/FooterCard';
import PoweredByStrike from '../../components/customer/PoweredByStrike';

import '../../App.scss';
import 'antd/dist/antd.css';


export default class PaymentSuccessScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, topLogo, profileBio, displayName, avatar, dataSheetRow

  constructor(props) {
    super(props);

    this.state = {
      percent: 25,
    };

    document.title = "Payment success - Strikepay";
  }

  async componentDidMount() {
    const parsed = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const tagId = parsed.tagId;
    const tapId = this.props.location.pathname.split("/")[2];

    setTimeout(() => {
      this.setState((state) => { return { percent: 50 } });
    }, 0);

    let db = firebase.firestore();
    let doc = await db.collection('tags').doc(tagId).collection('taps').doc(tapId).get();

    const {
      amount,
      avatar,
      displayName,
      fee,
      is_test,
      paymentDetails,
      placeOfWork,
      product,
      topLogo,
      profileBio,
      templateTagId,
      teamMemberId,
    } = doc.data();

    let { currency } = paymentDetails;
    currency = (currency && currency.length > 0) ? currency : "eur";

    this.setState({
      amount,
      avatar,
      currency,
      displayName,
      fee,
      placeOfWork,
      product,
      tagId,
      tapId,
      topLogo,
      profileBio,
      templateTagId,
      teamMemberId,
      percent: 100
    });
    const totalAmount = ((amount + fee) / 100).toFixed(2);

    if (!is_test) {
      firebase.analytics().logEvent(
        "purchase",
        {
          currency: currency,
          items: [
            {
              brand: placeOfWork,
              category: product,
              id: tagId,
              name: displayName,
              price: totalAmount,
              quantity: 1,
            },
          ],
          value: totalAmount,
        }
      );
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  copySuccess = () => {
    message.success('Code copied, remember to redeem it in-app', 5);
  };

  render() {

    const dataSource = [
    ];

    const productSwitcher = new ProductSwitcher({ locStrings: this.props.locStrings });
    const productMap = productSwitcher.getProductMap(this.state.product);

    if (this.state.amount > 0) {
      const actionLine = productMap.label;

      dataSource.push(
        {
          key: 'Amount',
          description: actionLine,
          amount: this.state.amount
        }
      );
    }

    if (this.state.fee > 0) {
      dataSource.push(
        {
          key: 'fee',
          description: this.props.locStrings.fee_description,
          amount: this.state.fee
        }
      );
    }

    dataSource.push(
      {
        key: 'total',
        description: 'Total Paid',
        amount: this.state.amount + this.state.fee
      }
    );

    const columns = [
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render:
          amount => (
            <div style={{ textAlign: 'right' }}>
              <MoneyTitle amount={amount} currency={this.state.currency} digits={2} />
            </div>
          )

      },
    ];

    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out ' + this.props.transitionId;
    }
    if (!this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    const style_elBackground = {
      width: '100%',
      height: '100vh',
    };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
    };
    const style_elCard_outer = {
      backgroundColor: 'white',
      overflow: 'visible',
    };

    const style_elImage = {
      /*height: 'auto',*/
      marginLeft: 'auto',
      marginRight: 'auto'
    };

    if (!this.state.avatar) {
      style_elImage.maxHeight = "400px";
    }

    const style_elWelldoneText = {
      textAlign: 'center',
    };

    const style_elButtonCallToAction = {
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
    };

    const style_elRegisterButton = {
      fontSize: 20,
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      width: 'fit-content',
    };

    const style_elRoundimage = {
      backgroundImage: 'url(' + (this.state.avatar) + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      borderRadius: '50.0%',
      margin: 'auto',
    };

    const style_elSquareimage = {
      backgroundImage: 'url(' + (this.state.avatar) + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      borderRadius: '0',
      margin: 'auto',
    };

    const value_displayNameText = this.state.displayName;

    const style_elDisplayNameText = {
      fontSize: 17.1,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
    };

    const isLocalHost = window.location.hostname === 'localhost';
    const baseUrl = isLocalHost ? 'http://localhost:3000' : 'https://app.strikepay.co'

    const boltPromoBlock = (
      <div className="genericCardBlock sponsoredCardBlock">
        <img src="https://firebasestorage.googleapis.com/v0/b/strike-tips-demo.appspot.com/o/public-images%2Fpromotions%2Fbolt%2FBolt_logo.png?alt=media&amp;token=9f30a318-2f16-4ca1-a307-0857ffb1f4c7" alt="Bolt Logo" className="promoImage" />

        <p className="promoCopy">Get €5 off your first Bolt trip with the code below</p>

        <div className="copyableBlock">
          <span>Tap to copy code</span>
          <CopyToClipboard text={"DUBTIPS"}
            onCopy={() => this.copySuccess()}>
            <button className="promoCopyable">DUBTIPS</button>
          </CopyToClipboard>
        </div>

        <a href="https://invite.bolt.eu/DUBTIPS" target="_blank" rel="noopener noreferrer">
          <Button className="promoButton boltButton">Download Bolt Taxi</Button>
        </a>

        {/* In future this shouldn't be forgettable - perhaps an ::after or something in CSS */}
        <span className="sponsoredTag">Partner Offer</span>
      </div>
    );

    const strikePromoBlock = (
      <div className="genericCardBlock">
        <div className="elButtonCallToAction">
          <div className="systemFontRegular" style={style_elButtonCallToAction}>
            <span>{this.props.locStrings.paymentsuccess_cta_survey}</span>
          </div>
        </div>

        <a href="https://survey.typeform.com/to/aVOLgMak" target="_blank" rel="noopener noreferrer">
          <Button className="promoButton actionFont" style={style_elRegisterButton}>
            {this.props.locStrings.paymentsuccess_survey}
          </Button>
        </a>

        {productMap &&
          <>
            <div className="elButtonCallToAction">
              <div className="systemFontRegular" style={style_elButtonCallToAction}>
                <div>
                  {
                    productMap.again.label
                  }
                </div>
              </div>
            </div>

            <a href={this.state.teamMemberId ? `${baseUrl}/tags/${this.state.tagId}?tmid=${this.state.teamMemberId}` : `${baseUrl}/tags/${this.state.tagId}`}>
              <Button className="promoButton actionFont" style={style_elRegisterButton}>
                {
                  productMap.again.button
                }
              </Button>
            </a>
          </>
        }

      </div>
    );

    return (
      <div className="AppScreen PaymentSuccessScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground}>
              <div className="layoutFlow" style={layoutFlowStyle}>
                <div className="flowRow flowRow_PaymentSuccessScreen_elCard_227172">
                  <div className="elCard" style={style_elCard_outer}>
                    <div className="cardBg">
                      <div className="paymentSuccessBanner">
                        <div className="elGreenTick">
                          <Space align={'center'}>
                            <CheckCircleTwoTone twoToneColor="#ff0098" style={{ fontSize: '48px', border: '2px solid #fff', borderRadius: "100%" }} />
                          </Space>
                        </div>

                        {productMap && this.state.amount &&
                          <p className="paymentSuccessAmount">
                            <MoneyTitle amount={this.state.amount} currency={this.state.currency} digits={2} /> {productMap.label} Successful
                          </p>
                        }
                        {productMap && this.state.amount &&
                          <div className="elWelldoneText">
                            <div className="systemFontRegular" style={style_elWelldoneText}>
                              <div>
                                {this.state.product === "giving" ?
                                  this.props.locStrings.paymentsuccess_you_just_donated : this.props.locStrings.paymentsuccess_buttoncalltoactioncopy_20977
                                }
                              </div>
                            </div>
                          </div>
                        }

                      </div>

                      <div className="genericCardBlock">
                        <Row>
                          <Col flex={1}>
                            <div className="HeroProfile relativeProfile">
                              <div className="layoutFlow verticallyCentred">
                                {this.state.avatar &&
                                  <div className="elRoundimage">
                                    {
                                      this.state.product === "giving"
                                        ? (<div style={style_elSquareimage} className="receiptHeroImage" />)
                                        : (<div style={style_elRoundimage} className="receiptHeroImage" />)
                                    }
                                  </div>
                                }
                                <div className="elDisplayNameText">
                                  <div className="systemFontBold" style={style_elDisplayNameText}>
                                    <div>{value_displayNameText !== undefined ? value_displayNameText : (<span className="propValueMissing">{this.props.locStrings.heroprofile_text_886418}</span>)}</div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </Col>

                          <Col flex={4}>
                            <div className="elAmountFeesTotalText">
                              <Table dataSource={dataSource} showHeader={false} columns={columns} pagination={false} loading={this.state.percent < 100} />
                            </div>

                            {this.state.amount >= 0 && this.state.tapId &&
                              (<div style={{ marginTop: '10px', fontSize: 10, color: 'grey' }}>
                                <span>Reference: <strong>{this.state.tapId}</strong></span>
                              </div>
                              )
                            }

                            {this.state.amount >= 0 &&
                              (<div style={{ marginTop: '10px', fontSize: 10, color: 'green' }}>
                                <span>{this.props.locStrings.receipt}</span>
                              </div>
                              )
                            }
                          </Col>
                        </Row>
                      </div>

                      {
                        this.state.templateTagId === "bolttaxi" || this.state.templateTagId === "strikebolt"
                          ? boltPromoBlock
                          : strikePromoBlock
                      }

                      <PoweredByStrike locStrings={this.props.locStrings} />

                      <div className="hasNestedComps elFooterCard">
                        <div>
                          <FooterCard ref={(el) => this._elFooterCard = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}