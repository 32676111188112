import React, { Component } from 'react';
import firebase from 'firebase/app';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { Space, PageHeader, Card, Button, Form, Select, Tooltip, Modal, Radio, Row, Col, Divider, Typography } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import {
  QuestionCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';

import ProductSwitcher, { capitalize } from '../../../utils/ProductSwitcher';
import { CreateTeamFormMinimal } from '../../../components/client/CreateTeamFormMinimal';
import ClientAvatar from '../../../components/client/ClientAvatar';
import CenteredSpinner from '../../../components/CenteredSpinner';
import TrackerService from '../../../utils/TrackerService';
import StrikeTeamsModel from '../../../models/StrikeTeamsModel';
import { ClaimTagSuccessCard, PersonalTagClaimSuccessCard } from '../../../components/client/ClaimTagSuccessCard';
import { supportedCurrencies, defaultCurrencyByCountry } from '../../../utils/CurrencyUtils';
import { TeamsGrid } from '../../../components/client/TeamDashboardComponents';

const { Option } = Select;
const { Text, Title, Paragraph } = Typography;

const greenButtonStyle = { background: '#27AE60', color: 'white' };
const redButtonStyle = { color: '#bf2222' };

// /client/claim/:tagId
export default class ConfirmClaimTagScreen extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);

    this.currencies = {
      ...supportedCurrencies, // MARK: Upgrade to USD
    };

    this.state = {
      clientNotFound: false,
      route: null,
      clientId: null,
      client: null,
      tagId: this.props.match.params.tagId,
      loadingClient: true,
      selectedCancel: false,
      waitingOnRequestClaimTag: false,
      clientTeams: [],
      teamCurrency: null,
      foundClientTeams: false,
      claimTypeSelected: null,
      teamIdSelected: null,
      teamNameSelected: null,
      createTeamModalVisible: false,
      creatingTeam: false,
      currency: null,
      compact: true,
      sortBy: 'name',
    };

    this.productsSupportedForTeams = ['tipping'];

    this.defaultPaymentDetails = {
      currency: null,
    };
  }

  async componentDidMount() {
    this.waitOnLoginBeforeClientData();
    await this.getTag();
  }

  componentWillUnmount() {
    if (this.clientModel) this.clientModel.stop();
    if (this.observerClient) {
      this.observerClient();
      this.observerClient = null;
    }

    if (this.observerClientMemberDocs) {
      this.observerClientMemberDocs();
      this.observerClientMemberDocs = null;
    }
  }

  componentDidUpdate() {
  }

  getTag = async () => {
    const db = firebase.firestore();
    const tagDoc = await db.collection('tags').doc(this.state.tagId).get();
    const tag = tagDoc ? tagDoc.data() : null;
    const currency = tag && tag.paymentDetails ? tag.paymentDetails.currency : null;
    
    this.setState({
      tag: tagDoc ? tagDoc.data() : null,
      currency
    });
  }

  getCurrentUser = async (auth) => {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }


  waitOnLoginBeforeClientData = async () => {
    this.currentUser = await this.getCurrentUser(firebase.auth());
    this.fetchClientDataWithUserId(this.currentUser ? this.currentUser.uid : null);
  }

  fetchClientDataWithUserId = (ownerId) => {
    const db = firebase.firestore();
    const query = db.collection('clients').where("ownerId", "==", ownerId).limit(1);
    this.observerClient = query.onSnapshot(snap => {
      this.client = null;
      const size = snap.size;
      if (size > 0) {
        snap.forEach(
          doc => {
            this.client = doc.data();
            this.clientId = doc.id;
            this.foundClient(this.client, this.clientId);
          }
        );
      } else {
        this.clientNotFound();
      }
    });

  }

  isLoading = () => {
    return this.state.loadingClient || this.waitingOnRequestClaimTag;
  }

  foundClient = async (client, clientId) => {
    let currency = this.state.currency;
    if (client && client.country) {
      const country = client.country;
      const defaultCurrency = defaultCurrencyByCountry[country];
      currency = defaultCurrency || currency;
    }

    this.setState(
      (state) => {
        return {
          clientNotFound: false,
          client,
          clientId,
          loadingClient: false,
          currency,
        }
      }
    );
    await this.getTeamsForClient(clientId);
  }

  getTeamsForClient = async (clientId) => {
    const db = firebase.firestore();
    const query = db.collectionGroup('members').where('clientId', '==', clientId).where('isDeleted', '==', false).limit(50);
    let clientTeams = [];
    this.observerClientMemberDocs = query.onSnapshot(querySnapshot => {
      const size = querySnapshot.size;
      if (size > 0) {
        querySnapshot
          .docChanges()
          .forEach(change => {
            if (change.type === 'added') {
              let memberDocData = change.doc.data();
              clientTeams.push(memberDocData);
            } else if (change.type === 'removed') {
              let memberDocData = change.doc.data();
              const indexOfClientTeam = clientTeams.findIndex(clientTeam => clientTeam.teamId === memberDocData.teamId);
              clientTeams.splice(indexOfClientTeam, 1);
            }
          }
          );
        this.updateStateWithTeamsData(clientTeams);
      }
    });
  }

  async updateStateWithTeamsData(clientTeams) {
    const sortedByTeamName = clientTeams.sort((a, b) => a.teamName.localeCompare(b.teamName));
    this.setState({
      foundClientTeams: (sortedByTeamName.length > 0),
      clientTeams: sortedByTeamName
    });
  }
  clientNotFound = () => {
    this.setState({
      clientNotFound: true
    });
  }

  requestClaimTag = async () => {
    const clientId = this.clientId;

    const claimTagFunction = firebase.functions().httpsCallable('tagService-tagHandler-claimTag');

    const { tagUid } = this.state.tag;

    const payloadData = {
      tagId: this.state.tagId,
      clientId: this.state.clientId,
      displayName: this.state.client ? this.state.client.displayName : null,
      currency: this.state.currency,
      tagUid,
    }

    const isTest = this.state.tag.is_test;
    const templateTagId = this.state.tag.templateTagId || 'generic';

    const tracker = new TrackerService({
      tagId: this.state.tagId,
      isTest,
      object: this.state.tag,
    });

    try {
      const result = await claimTagFunction(payloadData);

      tracker.mixpanelTrack("confirm_claim_tag", {
        distinct_id: this.currentUser.uid,
        templateTagId: templateTagId,
        ...payloadData,
      });

      if (!isTest) {
        tracker.googleTrack(
          "confirm_claim_tag",
          {
            userId: this.currentUser.uid,
            templateTagId: templateTagId,
            ...payloadData,
          }
        );
      }

      this.setState(
        (state) => {
          return {
            route: `/client/manage/${clientId}`,
            waitingOnRequestClaimTag: false,
          }
        }
      );
    } catch (error) {
      // try again??
      console.log(error);
    }

  }

  executeClaimTeamTag = async (params) => {
    const {
      currency,
      teamId,
      teamName,
    } = params;

    const clientId = this.clientId;

    const claimTeamTagFunction = firebase.functions().httpsCallable('tagService-tagHandler-claimTeamTag');

    const { tagUid } = this.state.tag;

    const payloadData = {
      tagId: this.state.tagId,
      currency,
      userClientId: this.state.clientId,
      displayName: this.state.client ? this.state.client.displayName : null,
      teamId,
      teamName,
      tagUid,
    }

    const isTest = this.state.tag.is_test;
    const templateTagId = this.state.tag.templateTagId || 'generic';

    const tracker = new TrackerService({
      tagId: this.state.tagId,
      isTest,
      object: this.state.tag,
      teamId: this.state.teamId
    });

    try {
      const result = await claimTeamTagFunction(payloadData);

      tracker.mixpanelTrack("confirm_claim_tag", {
        distinct_id: this.currentUser.uid,
        templateTagId,
        ...payloadData,
      });

      if (!isTest) {
        tracker.googleTrack(
          "confirm_claim_tag",
          {
            userId: this.currentUser.uid,
            templateTagId: templateTagId,
            ...payloadData,
          }
        );
      }

      this.setState(
        (state) => {
          return {
            route: `/client/manage/${clientId}`,
            waitingOnRequestClaimTag: false,
          }
        }
      );
    } catch (error) {
      // try again??
      console.log(error);
    }
  }

  onClickConfirm = async () => {
    //start loading spinner
    this.setState(
      {
        waitingOnRequestClaimTag: true,
        loadingClient: true
      }
    );
    await this.requestClaimTag();

    //stop spinner
    this.setState(
      {
        loadingClient: false
      }
    );
  }

  onClickCancel = () => {
    this.setState({
      selectedCancel: true
    });
  }

  renderRedirectToTag = () => {
    const toRoute = `/tags/${this.state.tagId}`;
    return <Redirect to={toRoute} />
  }

  renderRedirectToLogin = () => {
    const toRoute = `/client/login?redirect=${this.props.location.pathname + this.props.location.search}`;
    return <Redirect to={toRoute} />
  }

  onCurrencyChange = (currency) => {
    this.setState({ currency: currency })
  }

  getTeamCurrency = (teamId) => {
    const team = this.state.clientTeams.find(team => team.teamId === teamId);
    return team ? team.defaultCurrency : null;
  }

  setSelectedOption = (optionObj) => {
    const { 
      option, teamIdSelected, teamNameSelected, defaultCurrency, 
    } = optionObj;

    switch (option) {
      case 'individual':
        this.setState({ claimTypeSelected: 'individual', teamIdSelected: null, teamNameSelected: null });
        break;
      case 'team':
        if (teamIdSelected) {
          this.setState({
              claimTypeSelected: 'team', teamIdSelected, teamNameSelected,
              teamCurrency: defaultCurrency,
              currency: defaultCurrency,
            });
        } else {
          this.setState({ claimTypeSelected: 'team', teamIdSelected: null, teamNameSelected: null });
        }
        break;
      default:
        break;
    }
  }

  setTeamSelection = async (team) => {
    const {
      teamId: teamIdSelected,
      teamName: teamNameSelected,
      defaultCurrency
     } = team;
    this.setSelectedOption({ option: 'team', teamIdSelected, teamNameSelected, defaultCurrency });
    this.setState(
      {
        waitingOnRequestClaimTag: true,
        loadingClient: true
      }
    );
    await this.executeClaimTeamTag({ currency: defaultCurrency, teamId: teamIdSelected, teamName: teamNameSelected });
    //stop spinner
    this.setState(
      {
        loadingClient: false
      }
    );
  }

  showCreateTeamModal = () => {
    this.setState({ createTeamModalVisible: true });
  }

  createTeam = async (data) => {
    const isLocalHost = window.location.hostname === 'localhost';
    const isTest = isLocalHost;

    this.setState({ creatingTeam: true });

    const teamName = data.teamName;
    const teamCurrency = data.defaultCurrency;

    const teamModel = new StrikeTeamsModel({ clientId: this.clientId });
    await teamModel.createTeamWithDefaultSettings({ teamName, teamCurrency });

    // const defaultPermissions = DefaultTeamPermissions;

    // const db = firebase.firestore();
    // await db.collection("teams").add({
    //   teamName,
    //   defaultCurrency: teamCurrency,
    //   teamOwnerClientId: this.clientId,
    //   teamDescription: null,
    //   defaultPermissions,
    //   teamTapBehaviour: "members",
    //   placeOfWork: (this.state.client && this.state.client.employerName) ? this.state.client.employerName : null,
    // });

    this.setState({
      createTeamModalVisible: false,
      creatingTeam: false,
      sortBy: 'date',
    });

    const tracker = new TrackerService({
      isTest,
    });

    const payloadData = {
      teamOwnerClientId: this.clientId,
      teamName,
      teamCurrency,
    }

    tracker.mixpanelTrack("create_team_while_claiming_tag", payloadData);

    if (!isTest) {
      tracker.googleTrack("create_team_while_claiming_tag", payloadData);
    }

  }

  handleCancel = () => {
    this.setState({ createTeamModalVisible: false });
  }

  render() {
    if (this.state.client && this.state.client.status && (this.state.client.status === 'paused')) {
      return <Redirect to='/' />
    }
    const avatar = <ClientAvatar client={this.state.client} />
    const avatarParams = { src: avatar };

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 8,
        },
        md: {
          span: 6,
        },
        lg: {
          span: 6,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 8,
        },
        md: {
          span: 6,
        },
        lg: {
          span: 4,
        },
      },
    };

    if (this.state.tag && this.state.tag.clientId) return this.renderRedirectToTag();
    if (this.state.clientNotFound || this.state.selectedCancel) return this.renderRedirectToLogin();

    let initialPaymentDetails = this.state.tag && this.state.tag.paymentDetails && this.state.tag.paymentDetails.currency ? this.state.tag.paymentDetails : null;
    initialPaymentDetails = initialPaymentDetails || this.defaultPaymentDetails;
    initialPaymentDetails = Object.keys(this.currencies).includes(initialPaymentDetails.currency) ? initialPaymentDetails : this.defaultPaymentDetails;

    if (this.state.client && this.state.client.country) {
      const country = this.state.client.country;
      const defaultCurrency = defaultCurrencyByCountry[country];
      initialPaymentDetails.currency = defaultCurrency || initialPaymentDetails.currency;
    }

    if (this.state.loadingClient) return <CenteredSpinner loading={this.state.loadingClient} />;

    let onClaimTypeChange = (e) => {
      let option = e.target.value;
      this.setSelectedOption({ option });
    }

    let createTeamBtnDisplay = () => {
      return (this.state.claimTypeSelected === 'team') ? 'block' : 'none';
    }

    const productSwitcher = new ProductSwitcher({ locStrings: this.props.locStrings });
    const product = this.state.tag && this.state.tag.product ? this.state.tag.product : null;
    const isTeamsAllowed = this.productsSupportedForTeams.includes(this.state.tag.product)
    const productMap = productSwitcher.getProductMap(product);
    const teamTitle = `Team ${capitalize(product)}`;
    const individualTitle = isTeamsAllowed ? `Individual ${capitalize(product)}` : `Activate ${capitalize(product)} Tag`;
    const onClickConfirmButtonText = isTeamsAllowed ? 'Activate Individual Tag' : individualTitle;
    const individualTagParapraph = isTeamsAllowed 
      ? 'Activates for Individual Only. To activate for a team, select an existing team or start a new one.'
      : 'Activate the tag.';

    return (
      <div style={{ height: '100%' }}>
        {(this.state.clientNotFound) &&
          (<PageHeader
            ghost={false}
            title={`Account Not Found`}
            subTitle={`Please login`}
          />)
        }

        {(this.state.client) &&
          (<PageHeader
            ghost={false}
            title={this.state.client.displayName ? `${this.state.client.displayName}` : this.state.client.email}
            subTitle={this.state.client.profileBio ? `${this.state.client.profileBio}` : null}
            avatar={avatarParams} />)
        }

        <Modal
          title="Start New Team"
          visible={this.state.createTeamModalVisible}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
          ]}
        >
          <p>You can edit and customise your team's appearance and settings from your account dashboard later.</p>

          <CreateTeamFormMinimal
            key={`createTeamMini-${Date.now()}`}
            callback={this.createTeam}
            edit={false}
            loading={this.state.creatingTeam}
            client={this.state.client}
          />
        </Modal>

        {!this.state.route && this.state.tag && this.state.tag.product && (this.productsSupportedForTeams.includes(this.state.tag.product)) &&
          <>
            <Title level={3}>{teamTitle}</Title>
            <Paragraph>
              {`To activate for a team${this.state.foundClientTeams ? ", select an existing team, or" : ""} start a new one.`}
            </Paragraph>
          </>
        }

        { !this.state.route && this.state.foundClientTeams && this.state.tag && this.state.tag.product && (this.productsSupportedForTeams.includes(this.state.tag.product)) &&
          <>
            <div style={{ textAlign: 'center' }}>

              <Divider>Select an Existing Team</Divider>

              <Space direction='vertical' style={{ marginBottom: '5px' }}>
                {this.state.clientTeams && this.state.clientTeams.length > 4 &&
                  <Space direction='horizontal' style={{ marginBottom: '5px' }}>
                    <Button type='ghost' onClick={() => this.setState({ compact: !this.state.compact })}>
                      {this.state.compact ? 'Show team detail...' : 'Show less detail..'}
                    </Button>
                    <Button type='ghost' onClick={() => this.setState({ sortBy: this.state.sortBy === 'name' ? 'date' : 'name' })}>
                      {this.state.sortBy === 'name' ? 'Sort by most recent' : 'Sort by name'}
                    </Button>
                  </Space>
                }

              </Space>

              <Row align='top' justify='center'>
                <Col style={{ width: '100%' }} span={24}>
                  <TeamsGrid
                    clientId={this.state.clientId}
                    client={this.state.client}
                    condition={true}
                    teams={this.state.clientTeams}
                    isAdmin={false}
                    sortBy={this.state.sortBy}
                    compact={this.state.compact}
                    clickText={'Use this Team'}
                    onClick={this.setTeamSelection}
                  />
                </Col>
              </Row>
            </div>
          </>
        }

        {!this.state.route && this.state.tag && this.state.tag.product && (this.productsSupportedForTeams.includes(this.state.tag.product)) &&
          <>
            <div style={{ textAlign: 'center' }}>
              <Divider>Start a New Team</Divider>
              <Space direction='vertical'>
                <Text level={5}>Tapping on `Start New Team` creates a new team.</Text>
                <Button
                  type="primary"
                  style={{ ...greenButtonStyle, height: '40px', borderRadius: '4px', border: 'none' }}
                  onClick={this.showCreateTeamModal}
                  icon={<PlusCircleOutlined />}
                >
                  Start New Team
                </Button>
              </Space>
              <Divider />
            </div>
          </>
        }

        {!this.state.route && this.state.tag &&
          <>
            <Title level={3}>{individualTitle}</Title>
            <Paragraph>
              {individualTagParapraph}
            </Paragraph>
          </>
        }
        {(this.state.client && !this.state.route && this.state.tag) &&
          (
            <>
              <Form
                {...formItemLayout}
                name="tagCurrency"
                initialValues={initialPaymentDetails}
              >
                <Form.Item
                  name="currency"
                  label={
                    <span>
                      Select your currency&nbsp;
                      <Tooltip title="Pick the currency you want to use. You cannot change it later.">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: 'Please input a currrency!',
                    },
                  ]}
                >
                  <Select
                    allowClear={false}
                    onChange={this.onCurrencyChange}
                    placeholder="Select your currency"
                  >
                    {Object.keys(this.currencies).map(
                      (i) => (
                        <Option key={i} value={i}>{this.currencies[i]}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Form>
              <Card>
                <Space>
                  <Button 
                    disabled={this.state.currency === null || this.state.waitingOnRequestClaimTag} 
                      key='confirm' type='primary' 
                    onClick={this.onClickConfirm}>{onClickConfirmButtonText}
                  </Button>
                  <Button 
                    disabled={this.state.waitingOnRequestClaimTag} 
                    key='cancel' 
                    type='dashed' 
                    onClick={this.onClickCancel}>{this.props.locStrings.confirm_claim_cancel_button}
                  </Button>
                </Space>
              </Card>
            </>
          )
        }

        {(this.state.client && !this.state.waitingOnRequestClaimTag && this.state.route) &&
          (
            (this.state.tag && this.state.tag.signupConfirmUrl && this.state.tag.partnerClientId) ?
              (
              <>
                <ClaimTagSuccessCard 
                  templateTagId={this.state.tag.templateTagId}
                  tagId={this.state.tagId}
                  tag={this.state.tag}
                  clientId={this.clientId}
                  locStrings={this.props.locStrings}
                />
              </>
              )
              :
              (
              <>
                <PersonalTagClaimSuccessCard
                  teamId={this.state.teamIdSelected}
                  teamName={this.state.teamNameSelected}
                  templateTagId={this.state.tag.templateTagId}
                  tagId={this.state.tagId}
                  tag={this.state.tag}
                  clientId={this.state.clientId}
                  client={this.state.client}
                  locStrings={this.props.locStrings}
                  waitingOnRequest={this.state.waitingOnRequestClaimTag}
                />
              </>
              )
          )
        }

        {(this.state.client === null) &&
          (null)
        }

      </div>
    );
  }
}

