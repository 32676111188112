import DataSheetBase from './DataSheetBase';

export default class DataSheet_tags extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Firebase (Cloud Firestore)'.
    
    item = {};
    this.items.push(item);
    item['status'] = "claimed";
    item['document_key'] = "90vIE9KLHkWQdxkMv5wj";
    item['ownerId'] = "19bmJj3dacP0OroZMNQy4kBwYF22";
    item['displayName'] = "Charles Dowd";
    item['profileBio'] = "Waiter. Busker. Student.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['rating'] = "4.0";
    item['profileBio'] = "Bolt Driver";
    item['averageRating'] = "4.3";
    item['paymentDetails'] = "{\"paymentMethods\":[\"Pays\",\"Card\"],\"shouldAddOnFees\":true,\"paymentMethodIndex\":0,\"currency\":\"EUR\"}";
    item['topLogo'] = "https://firebasestorage.googleapis.com/v0/b/strike-tips-demo.appspot.com/o/BOLT-LOGO-color-grey_200x200.png?alt=media&token=2e4a87d7-a300-4fdd-9092-9092e25d16de";
    item['ownerId'] = "19bmJj3dacP0OroZMNQy4kBwYF22";
    item['messageText'] = "You're my hero!";
    item['avatar'] = "https://firebasestorage.googleapis.com/v0/b/strike-tips-demo.appspot.com/o/0-8_200x200.jpeg?alt=media&token=49cf9413-5ba0-41c6-9f6d-e4e500ddb8e8";
    item['countOfTips'] = "300";
    item['placeOfWork'] = "Mulligans";
    item['document_key'] = "z06cMlunpBA8Cno73GTa";
    item['bottomLogo'] = "https://firebasestorage.googleapis.com/v0/b/strike-tips-demo.appspot.com/o/strike_logo_transparent.png?alt=media&token=d48593ab-aaf9-4b45-be37-b3cd14add0a3";
    item['selectedValueByIndex'] = "1";
    item['values'] = "[100,200,500,1000]";
    item['displayName'] = "Oli Cavanagh";
    item['status'] = "live";
    item.key = key++;
  }

  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  
  addItem(item, options) {
    // console.log("add to firebase: ", item);
      
    let isCollectionGroup = options.servicePath.startsWith("group:");
    if (isCollectionGroup) {
      console.log("unable to add to collection group: ", options.servicePath.substring(6));
      return;
    }
  
    const db = this.firebase.firestore();
    const collection = db.collection(options.servicePath);
  
    let addItemPromise;
    if (item.document_key && item.document_key.length > 0) {
      let parsedItem = (({ document_key, ...item }) => (item))( item );
      addItemPromise = collection.doc(item.document_key).set(parsedItem);
    } else {
      addItemPromise = collection.add(item);
    }
    addItemPromise
      .then((docRef) => {
        var addedKey="";
        if (docRef) {
          // console.log("Firebase document added with id: ", docRef.id);
          addedKey=docRef.id;
          item.document_key = docRef.id;
          item.document_path = docRef.path;
        } else {
          // console.log("Firebase document added with id: ", item.document_key);
          addedKey=item.document_key;
        }   
        if (this.onLastAddedDocumentKeyChanged != null) {
          this.onLastAddedDocumentKeyChanged(addedKey);
        }
        //super.addItem(item, options);
      
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        this._fetchComplete(error, options);
      });
  }
  
  removeItem(item, options) {
    //super.removeItem(item, options);
    
    console.log("delete from firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
  
    docRef.delete()
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  
  replaceItemByRowIndex(idx, item, options) {
    //super.replaceItemByRowIndex(idx, item, options);
    
    console.log("update in firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
      
    docRef.update((({ key, document_ref, document_key, ...item }) => (item))( item ))
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  

}
