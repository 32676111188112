// MARK: Helper functions for currency related constants

export const ccyIsoCodeToSymbolMap = {
    'EUR': '€',
    'USD': '$',
    'GBP': '£',
};

export const supportedCurrencies = {
    "EUR": "Euro (€)",
    "GBP": "British Pound (£)",
    "USD": "US Dollar ($)",
  };

export const defaultCurrencyByCountry = {
    "US" : "USD",
    "IE" : "EUR",
    "GB" : "GBP",
    "UK" : "GBP",
    "FR" : "EUR",
    "ES" : "EUR",
    "IT" : "EUR",
    "DE" : "EUR",
  };

  export const countryFlags = {
    "IE": "🇮🇪",
    "GB": "🇬🇧",
    "UK": "🇬🇧",
    "FR": "🇫🇷",
    "ES": "🇪🇸",
    "IT": "🇮🇹",
    "DE": "🇩🇪",
    "US": "🇺🇸",
    "LU": "🇱🇺",
    "LT": "🇱🇹",
  };
