import React, { useState } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import { BackwardFilled, CloseCircleFilled } from '@ant-design/icons';
import { Button, Card, Row, Col, Space } from 'antd';

import { PaymentsMaxAmounts } from '../../utils/Constants';

import MoneyTitle from './MoneyTitle';

const keyboardItems = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 'clear', 0, 'delete'
];

function onChangedValue(value, onChange) {
    if (onChange) onChange(1, value);
}

export const PaymentKeyboard = ({ initialValue, currency, locStrings, hidden, onChange, preview, previewTop, previewBottom }) => {
    let spanKeyboardKeys = 8;
    
    const [enteredValue, setEnteredValue] = useState(initialValue || 0);
    const [showPreviewTop, setShowPreviewTop] = useState(previewTop || preview || !previewBottom);
    const [showPreviewBottom, setShowPreviewBottom] = useState(previewBottom || false);
    const [maxValue, setMaxValue] = useState(PaymentsMaxAmounts.values[currency]);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState(null);

    const cardStyle = {
        width: '100%',
        textAlign: 'start',
    };

    const onClick = (e, currentValue, value) => {
        let newValue = 0;
        const isDeleteTapped = (value === 'delete');
        const isClearTapped = (value === 'clear');
        if (isDeleteTapped) {
            newValue = Math.floor(currentValue / 10.0);
            setEnteredValue(newValue);
            setErrorText(null);
            setIsError(false);
        } else if (isClearTapped) {
            setEnteredValue(0);
            setErrorText(null);
            setIsError(false);
        } else {
            newValue = Math.round(currentValue * 10 + value);
            if (newValue > maxValue) {
                newValue = currentValue;
                setIsError(true);
                setErrorText((
                    <>
                        {`Exceeded max value of `}
                        <MoneyTitle amount={maxValue} currency={currency} digits={2} />
                        {`, clear and re-try!`}
                    </>
                ));
                setTimeout(() => (setIsError(false)), 230);
            } else {
                setEnteredValue(newValue);
                setErrorText(null);
                setIsError(false);
            }
        }
        if (newValue !== currentValue) onChangedValue(newValue, onChange);
    };

    const displayKey = (value) => {
        const isDelete = (value === 'delete');
        const isClear = (value === 'clear');
        if (isDelete) {
            return <BackwardFilled />;
        } else if (isClear) {
            return <CloseCircleFilled />;
        } else {
            return <p>{`${value}`}</p>;
        }
    };

    const styleMoney = {
        width: '100%',
        textAlign: 'center',
        fontSize: '30px',
        color: isError ? 'red' : 'black'
    };

    const styleError = {
        width: '100%',
        textAlign: 'center',
        fontSize: '8px',
        color: 'red'
    };

    const styleInfo = {
        width: '100%',
        textAlign: 'center',
        fontSize: '8px',
        color: 'black'
    };

    return (
        <Space direction='vertical'>

            { showPreviewTop && (
                <div style={styleMoney}>
                    <MoneyTitle amount={enteredValue} currency={currency} digits={2} />
                </div>
            )}

            {( !hidden &&
                (<Row gutter={[0, 0]}>
                    {(keyboardItems.map(
                        (value, index) =>
                        (<Col key={`col-input-${index}`} span={spanKeyboardKeys}>
                            <Button
                                key={`button-${index}`}
                                type='ghost'
                                style={{ borderWidth: '1px', width: '100%', height: '60px', marginLeft: 'auto', marginRight: 'auto', borderColor: 'black', fontSize: '30px', fontWeight: 'lighter', borderRadius: 0 }}
                                onClick={(e) => (onClick(e, enteredValue, value))}
                            >
                                {displayKey(value)}
                            </Button>
                        </Col>)
                    )
                    )}
                </Row>)
            )}

            { showPreviewBottom && (
                <div style={styleMoney}>
                    <MoneyTitle amount={enteredValue} currency={currency} digits={2} />
                </div>
            )}

            {(
                (isError || errorText) &&
                <p style={styleError}>
                    {errorText}
                </p>
            )}

            {(
                !isError && errorText === null && !hidden &&
                <p style={styleInfo}>
                    {`Max value `}
                    <MoneyTitle amount={maxValue} currency={currency} digits={2} />
                </p>
            )}


        </Space>

    );
}

