import React from 'react';
import '../../App.scss';
import './CurrencyFormatter.css'; // Import the CSS file for styling
import { IntlProvider, FormattedNumber } from 'react-intl';

const CurrencyFormatter = ({ amountInPennies, currencyCode }) => {
    // Convert pennies to dollars
    const amountInDollars = amountInPennies / 100;

    // Format the amount using Intl.NumberFormat
    const formattedAmount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 2,
    }).format(amountInDollars);

    // Split the formatted amount into whole and decimal parts
    const [wholePart, decimalPart] = formattedAmount.split('.');

    return (
        <div className="currency-formatter">
            <span className="whole-part">{wholePart}</span>
            <span className="decimal-part">.{decimalPart}</span>
        </div>
    );
};

export default function MoneyTitle(props) {

    const totalValue100s = (((props.fees || 0)) * 1 + props.amount * 1);
    const totalValue100sString = totalValue100s.toString();
    const l = totalValue100sString.length;
    const decimalString = totalValue100sString.slice(-2);
    const unitsString = totalValue100sString.slice(0, (l - 2));
    const decimalValue = decimalString * 1;
    const unitsValue = unitsString * 1;
    const parts = props.strikethrough ? (parts => (
        <>
            <span style={{ textDecoration: 'line-through' }}>{parts}</span>
        </>
    )) : '';

    return (<IntlProvider locale='en'>
        <FormattedNumber
            value={(totalValue100s / 100) || 0}
            style="currency"
            currency={props.currency || 'EUR'}
            minimumFractionDigits={props.digits || (props.fees ? 2 : 0)}
            maximumFractionDigits={props.digits || (props.fees ? 2 : 0)} >
            {parts}
        </FormattedNumber>
    </IntlProvider>);
}
