import React, { Component } from 'react';
import firebase from 'firebase/app';
import { Typography, PageHeader, Tag, Upload , Button, Modal, Space, Divider } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ReactCrop from 'react-image-crop';
import qs from 'qs';

import StrikeClientModel from '../../../models/StrikeClientModel';
import { ProfileImage, VerifiedClientTag } from '../../../components/client/AccountProfileComponents';
import { EditClientProfileForm, AdminCheckBoxFormItems } from '../../../components/client/EditClientProfileForm';
import { getCategoryNameWithCompany } from '../../../components/client/ClientCategoryFormItem';
import getBase64 from '../../../utils/GetBase64';
import CenteredSpinner from '../../../components/CenteredSpinner';
import { defaultCurrencyByCountry } from '../../../utils/CurrencyUtils';
import 'antd/dist/antd.css';
import '../../../App.scss';
import 'react-image-crop/dist/ReactCrop.css';
import { ClientSpecificPricingView } from '../../../components/admin/ClientSpecificPricingView';


const { Paragraph, Text } = Typography;

// /client/manage/profile
export default class EditClientProfileScreen extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    const isAdmin = !!props.isAdmin;

    const parsed = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const mode = (parsed.mode) ? 'editing' : 'viewing';

    this.model = new StrikeClientModel({ viewModel: this, clientId, isAdmin });
    this.isAdmin = isAdmin;

    this.state = {
      user: null,
      client: null,
      clientId: clientId,
      percent: 0,
      loading: true,
      mode,
      crop: {
        unit: '%',
        width: 30,
        aspect: 1 / 1,
      },
    };
  }

  beforeUpload = (file) => {
    this.setState({ visible: true, src: URL.createObjectURL(file) });

    // return false to not have it trigger upload, we want the file but no the actual uploading
    return false;
  }

  componentDidMount() {
    this.model.waitForClientData();
  }

  componentWillUnmount() {
    if (this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || false;
  }

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ uploading: true });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          uploading: false,
        }),
      );
    }
  };

  uploadImage = async (file) => {
    const storageRef = firebase.storage().ref();

    const fileName = this.state.fileName;
    const clientImagesRef = storageRef.child(`public-images/client-images/${fileName}`);

    const resultSnapshot = await clientImagesRef.put(file);
    const imageUrl = await resultSnapshot.ref.getDownloadURL();

    const userId = this.state.client.ownerId;
    const db = firebase.firestore();
    const user = await firebase.auth().currentUser;

    const clientId = this.state.clientId;
    const imagePromises = [];

    imagePromises.push(
      db.collection('users').doc(userId).set({
        photoURL: imageUrl,
      }, { merge: true }),
      db.collection('clients').doc(clientId).update({
        avatar: imageUrl,
      }),
      user.updateProfile({
        photoURL: imageUrl,
      })
    );

    await Promise.all(imagePromises);
  }


  foundClient = async (client) => {
    this.setState(
      (state) => {
        return {
          client,
          loading: false,
        }
      }
    );
  }

  changeMode = (mode) => {
    if (mode === 'viewing') {
      return 'editing'
    } else if (mode === 'editing') {
      return 'saving'
    } else {
      return 'viewing';
    }
  }


  onClickEdit = () => {
    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    }
    );
  }

  doneSaving = () => {
    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    }
    );

    this.model.waitForClientData();
  }

  internalSave = async (data) => {
    await this.model.commit(data);
    this.doneSaving();
  }


  onClickSave = (data) => {
    // console.log(`onClickSave`, data);

    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode),
      }
    }
    );

    this.internalSave(data);
  }

  getButtons = () => {
    if (this.state.mode === 'viewing') {
      return [
        <Button key="1" type="primary" onClick={this.onClickEdit}>
          Edit
        </Button>,
      ]
    } else if (this.state.mode === 'editing') {
      return [];
    } else {
      return [];
    }
  }

  handleOk = () => {
    this.setState({ loading: true, visible: false });
    this.uploadImage(this.state.croppedImage);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImage = await this.getCroppedImg(
        this.imageRef,
        crop,
      );
      this.setState({ croppedImage });
    }
  }

  onImageLoaded = image => {
    this.imageRef = image;
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width;
    canvas.height = crop.height;

    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );


    const clientId = this.state.clientId;
    const timestamp = Date.now();
    const fileName = `${clientId}-${timestamp}.jpg`;
    this.setState({ fileName });

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg');
    });
  }

  formattedDob = () => {
    return this.state.client.dob ? this.state.client.dob.format("DD/MM/YYYY") : ""
  };

  formattedAgreedTermsAt = () => {
    return this.state.client.agreedTermsAt ? this.state.client.agreedTermsAt.format("DD/MM/YYYY") : ""
  };

  formattedMarketingOptInAt = () => {
    return this.state.client.marketingOptInAt ? this.state.client.marketingOptInAt.format("DD/MM/YYYY") : ""
  };

  render() {
    if (this.state.mode === 'viewing' && !this.state.loading && this.state.client) {
      let agreeTermsString = (this.state.client.agreeTerms) ? "Agreed Terms" : "Need to agree terms";
      agreeTermsString = (this.state.client.agreedTermsAt) ? agreeTermsString + ` on ${this.formattedAgreedTermsAt()}` : agreeTermsString;

      let marketingOptInString = (this.state.client.marketingOptIn) ? "Allowed Strikepay to send important communications" : "Strikepay may not send other communications";
      marketingOptInString = (this.state.client.marketingOptIn && this.state.client.marketingOptInAt) ? marketingOptInString + ` on ${this.formattedMarketingOptInAt()}` : marketingOptInString;

      const businessOrIndividual = (this.state.client.isCompany) ? "Business" : null;
      const defaultCurrency = defaultCurrencyByCountry[this.state.client.country] || 'EUR';

      return (
        <div style={{ height: '100%', textAlign: 'center' }}>
          <PageHeader
            ghost={false}
            title={`Your Profile`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
          <CenteredSpinner loading={this.isLoading()} noLayout={false} />
          <Space direction='vertical' size={0} style={{ width: '100%' }}>
            <ProfileImage
              condition={() => { return !this.isLoading() && this.state.client }}
              locStrings={this.props.locStrings}
              client={this.state.client}
            />
            <VerifiedClientTag client={this.state.client} style={{ fontSize: '15px' }} />
            <Text>
              {getCategoryNameWithCompany({ categoryCode: this.state.client.categoryCode, isCompany: this.state.client.isCompany })}
            </Text>
            {businessOrIndividual && <Text type='success'>{businessOrIndividual}</Text>}
          </Space>
          <Divider />
          <Paragraph>{this.state.client.email && ("Email: ")}{this.state.client.email || ""}</Paragraph>
          <Paragraph>{this.state.client.phoneNumber && ("Phone: ")}{this.state.client.phoneNumber || ""}</Paragraph>
          <Paragraph>{this.state.client.dob && (this.state.client.isCompany ? "Founded: " : "Date of Birth: ")}{this.formattedDob()}</Paragraph>
          <Paragraph>{this.state.client.employerName && ("Work: ")}{this.state.client.employerName || ""}</Paragraph>
          <Paragraph>{agreeTermsString}</Paragraph>
          <Paragraph>{marketingOptInString}</Paragraph>
          <AdminCheckBoxFormItems isAdmin={this.isAdmin} title="Admin Only Settings" isViewOnly={true} client={this.state.client} />
          { this.isAdmin &&
            <ClientSpecificPricingView pricing={this.state.client.clientSpecificPricing} currency={defaultCurrency} />
          }
        </div>
      );
    } else if (!this.state.loading) {
      const { uploading, imageUrl } = this.state;
      const avatarUrl = imageUrl ? imageUrl : this.state.client.avatar;

      const uploadButton = (
        <div>
          {uploading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      );

      const { visible, loading } = this.state;

      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`Your Profile`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />

          <div style={{ marginLeft: "auto", marginRight: 'auto', width: "intrinsic", marginBottom: "10px" }}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={this.uploadImage}
              beforeUpload={this.beforeUpload}
              onChange={this.handleChange}
            >
              {avatarUrl ? <img src={avatarUrl} alt="avatar" style={{ width: '104px', height: '104px', borderRadius: '100%' }} /> : uploadButton}
            </Upload>
          </div>

          <Modal
            visible={visible}
            title="Crop your image"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
                Upload
              </Button>,
            ]}
          >
            <div className="cropper">
              {this.state.src && (
                <ReactCrop
                  src={this.state.src}
                  crop={this.state.crop}
                  ruleOfThirds
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              )}
            </div>

          </Modal>

          <EditClientProfileForm
            client={this.state.client}
            callback={this.onClickSave}
            isAdmin={this.isAdmin}
          />
        </div>
      );
    } else {
      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`Your Profile`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
        </div>
      );
    }
  }
}

