import React, { useState  } from 'react';
import { Menu } from 'antd';
import { Link, useParams, useLocation } from 'react-router-dom';
import {
    LineChartOutlined,
    AppstoreOutlined,
    LinkOutlined,
    UserOutlined,
    HomeOutlined,
    BankOutlined,
    NotificationOutlined,
    GlobalOutlined,
    MailOutlined
} from '@ant-design/icons';

import img_elStrikeLogoImage_dark from '../../images/strikepay_Mark_RGB_White.png';
import img_elStrikeLogoImage_light from '../../images/strikepay_Mark_RGB_Black.png';

import '../../App.scss';
import 'antd/dist/antd.css';

const { SubMenu } = Menu;

export const AdminMenu = () => {
    const location = useLocation();
    const { pathname } = location;
    const paramsURL = useParams();
    const { clientId, issuerId, teamId, templateId } = paramsURL;
    const [mode, setMode] = useState('inline');
    const [theme, setTheme] = useState('light');
    const [current, setCurrent] = useState(pathname);

    const changeMode = value => {
        setMode(value ? 'vertical' : 'inline');
    };

    const changeTheme = value => {
        setTheme(value ? 'dark' : 'light');
    };

    const onClick = ({ key }) => {
        console.log('Clicked Menu ', key);
        setCurrent(key);
    };

    return (
        <>
            <Menu
                defaultSelectedKeys={[current]}
                defaultOpenKeys={['issuers']}
                mode={mode}
                theme={theme}
                onClick={onClick}
            >
                <Menu.Item key="/admin/logo" style={{ maxWidth: '100%', height: '50px'  }} >
                    <Link to='/admin/dashboard' style={{ textAlign: 'start' }} >
                        <img 
                                src={theme === 'dark' ? img_elStrikeLogoImage_dark : img_elStrikeLogoImage_light } 
                                style={{ maxWidth: '100%', margin: '8px' }} 
                                alt='Home' 
                            />
                    </Link>
                </Menu.Item>
                <Menu.Item key="/admin/dashboard" icon={<HomeOutlined />}>
                    <Link to='/admin/dashboard'>Home</Link>
                </Menu.Item>
                <Menu.Item key="/admin/clients" icon={<UserOutlined />}>
                    <Link to='/admin/clients'>Clients</Link>
                </Menu.Item>
                <Menu.Item key="/admin/balances" icon={<LineChartOutlined />}>
                    <Link to='/admin/balances'>Balances</Link>
                </Menu.Item>
                <SubMenu key="withdrawals" icon={<BankOutlined />} title="Payouts">
                    <Menu.Item key="/admin/withdrawals" icon={<BankOutlined />}>
                        <Link to='/admin/withdrawals'>Withdrawals</Link>
                    </Menu.Item>
                    <Menu.Item key="/admin/batches" icon={<BankOutlined />}>
                        <Link to='/admin/batches'>Batches</Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="issuers" icon={<AppstoreOutlined />} title="Templates">
                    <Menu.Item key="/admin/issuers/strike/templates">
                        <Link to='/admin/issuers/strike/templates'>Strike Templates</Link>
                    </Menu.Item>
                </SubMenu>
                <Menu.Item key="/admin/notifications" icon={<NotificationOutlined />}>
                    <Link to='/admin/notifications'>Notifications</Link>
                </Menu.Item>
                <Menu.Item key="/admin/webhooks" icon={<GlobalOutlined />}>
                    <Link to='/admin/webhooks'>Developers</Link>
                </Menu.Item>
                <Menu.Item key="link" icon={<LinkOutlined />}>
                    <a href="https://strikepay.co" target="_blank" rel="noopener noreferrer">
                        Strikepay.co
                    </a>
                </Menu.Item>
            </Menu>
        </>
    );
};